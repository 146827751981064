import { useEffect, useCallback } from "react"
import { useRecoilValue, selector, useRecoilState, atomFamily, useRecoilValueLoadable } from "recoil"
import { ajax, convertDataURIToBlob, Authorization } from "@tm/utils"
import { HubConnectionBuilder, HubConnection, HubConnectionState } from "@microsoft/signalr"
import { channel } from "@tm/models"
import { getBundleParams } from "../../utils"
import { AttachmentType } from "../../components/chats/components/input/attachment"
import { getChatToken } from ".."

export * from "./useCalendarInfos"
export * from "./useChatList"
export * from "./useCondensedChatInfos"
export * from "./useForeignUsersInChat"
export * from "./useIdStorage"
export * from "./useLastMessages"
export * from "./useLastReadInfo"
export * from "./useOpenFastCalc"
export * from "./useOwnUserId"
export * from "./useUserDisplayName"

export const chatAuthorizationRecoilSelector = selector<Authorization | undefined>({
    key: "notifications_chat_token",
    get: async () => {
        const response = await getChatToken().catch(() => undefined)

        if (response?.token) {
            channel("GLOBAL").publish("NOTIFICATIONS/CHAT_TOKEN_LOADED", response)
            return { type: response.token.schema, credentials: response.token.token }
        }
    },
})

export function useChatAuthorization() {
    return useRecoilValue(chatAuthorizationRecoilSelector)
}

export function useChatAuthorizationLoadable() {
    return useRecoilValueLoadable(chatAuthorizationRecoilSelector)
}

export type Message = {
    appMetaData?: string
    text: string
    chatId: string
    authorId: string
    attachmentId?: string
    attachment?: {
        id: string
        inserted: string // TODO: Date mapping
        mimeType: string
        modified: string // TODO: Date mapping
        uri: string
    }
    id: string
    inserted: string // TODO: Date mapping
    modified: string // TODO: Date mapping
}

let signalrConnection: HubConnection | undefined
let signalrStartPromise: Promise<void> | undefined
function getSignalrConnection(authorization: Authorization) {
    if (!signalrConnection) {
        const url = `${getBundleParams().chatApiUrl}/chatHub?access_token=${authorization.credentials}`
        signalrConnection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build()
        signalrStartPromise = signalrConnection.start().catch((e) => console.error(e))
    }

    return { connection: signalrConnection, startPromise: signalrStartPromise }
}

export const chatMessagesRecoilAtom = atomFamily<Array<Message> | undefined, string>({
    key: "notifications_chat_messages",
    default: undefined,
})

function objectKeysToLowerCamelCase(input: object) {
    const obj: any = {}
    Object.entries(input).forEach(([key, value]) => {
        obj[key.charAt(0).toLowerCase() + key.slice(1)] = value && typeof value === "object" ? objectKeysToLowerCamelCase(value) : value
    })
    return obj
}

function parseSignalrJson(json: string) {
    return objectKeysToLowerCamelCase(JSON.parse(json))
}

export function useChatMessages(chatId: string) {
    const authorization = useChatAuthorization()
    const [messages, setMessages] = useRecoilState(chatMessagesRecoilAtom(chatId))

    useEffect(() => {
        if (!authorization) {
            return
        }

        const url = `${getBundleParams().chatApiUrl}/api/v1/user/chats/messages/get/${chatId}`
        ajax({ url, method: "GET", authorization }).then((response) => {
            setMessages(response)
        })
    }, [authorization, chatId, setMessages])

    const messageHandler = useCallback(
        (message: Message) => {
            if (message.chatId === chatId) {
                setMessages((current) => (current ? [...current, message] : [message]))
            }
        },
        [chatId, setMessages]
    )

    useSignalRMessage(messageHandler)

    return messages
}

export function useChatMessagesReadonly(chatId: string) {
    const [messages] = useRecoilState(chatMessagesRecoilAtom(chatId))

    return messages
}

export function useSignalRMessage(callback: (message: Message) => void) {
    const authorization = useChatAuthorization()

    useEffect(() => {
        if (!authorization) {
            return
        }

        const handler = (messageString: string) => {
            const message: Message = parseSignalrJson(messageString)
            callback(message)
        }

        const { connection } = getSignalrConnection(authorization)
        connection.on("ReceiveMessage", handler)

        return () => connection.off("ReceiveMessage", handler)
    }, [authorization, callback])
}

type ChatMessageData = {
    chatId: string
    message?: string
    attachment?: AttachmentType
}

function sendChatMessage(authorization: Authorization | undefined, data: ChatMessageData) {
    if (!authorization) {
        return Promise.reject()
    }

    if (data.attachment && "file" in data.attachment && data.attachment.file) {
        const formData = new FormData()
        formData.append(
            "File",
            data.attachment.file instanceof Blob ? data.attachment.file : convertDataURIToBlob(data.attachment.file),
            data.attachment.metaData?.name
        )

        formData.append("ChatId", data.chatId)
        data.message && formData.append("Message", data.message)
        data.attachment.metaData && formData.append("AppMetaData", JSON.stringify(data.attachment.metaData))
        formData.append("MimeType", data.attachment.mimeType)

        return fetch(`${getBundleParams().chatApiUrl}/api/v1/user/chats/messages/uploadfile`, {
            method: "POST",
            body: formData,
            headers: { Authorization: `${authorization.type} ${authorization.credentials}` },
        })
    }

    const { connection, startPromise } = getSignalrConnection(authorization)

    function invoke() {
        return connection.invoke(
            "SendMessageAsync",
            data.chatId,
            data.message,
            data.attachment?.metaData ? JSON.stringify(data.attachment.metaData) : undefined
        )
    }

    if (connection.state === HubConnectionState.Connecting && startPromise) {
        return startPromise.then(invoke)
    }

    return invoke()
}

export function useSendChatMessage() {
    const authorization = useChatAuthorization()

    return useCallback((data: ChatMessageData) => sendChatMessage(authorization, data), [authorization])
}

export function useSendChatMessageLoadable() {
    const authorization = useChatAuthorizationLoadable().valueMaybe()

    return useCallback((data: ChatMessageData) => sendChatMessage(authorization, data), [authorization])
}
