import { LocalizationProps, withLocalization } from "@tm/localization"
import {
    withUserContext,
    WithUserContextProps,
    withUserSettings,
    WithUserSettingsProps,
    withWorkTask,
    WithWorkTaskProps,
} from "@tm/context-distribution"
import { ChangeItemsResponse, Article, channel, IMicros } from "@tm/models"
import { connectComponent, WithMicroProps } from "@tm/morpheus"
import { openDetailsState, selectedOrderOptionState, RouteComponentProps, withRouter } from "@tm/utils"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { BaseDetailsRouteProps } from "../../business"
import { DetailsHeadState } from "./business"
import DetailsHeadComponent from "./component"
import { Actions, IActions } from "./business/actions"

type DetailsHeadConfigProps = {
    state: DetailsHeadState
    actions: IActions
    showAdditionalPrices?: boolean
    showArticleWarehouseSelector?: boolean
    infoAvailabilityOverviewData?: Array<{ status: number; text: string }>
}

export type DetailsHeadProps = WithMicroProps<IMicros> &
    LocalizationProps &
    RouteComponentProps<BaseDetailsRouteProps> &
    WithUserContextProps &
    WithUserSettingsProps &
    WithWorkTaskProps &
    DetailsHeadConfigProps

const DetailsHeadComponentWrapper = (props: DetailsHeadProps) => {
    const setOpenDetailsState = useSetRecoilState(openDetailsState)
    const selectedOrderOption = useRecoilValue(selectedOrderOptionState)

    const onFinished = (data: ChangeItemsResponse) => {
        channel("GLOBAL").publish("ERP/TECCOM_ARTICLE_ADDED", data)
    }

    const updateArticle = (article?: Article) => {
        setOpenDetailsState({ article })
    }

    return <DetailsHeadComponent {...props} updateArticle={updateArticle} selectedOrderOption={selectedOrderOption} onFinished={onFinished} />
}

export default connectComponent(Actions, withLocalization(withWorkTask(withUserContext(withUserSettings(withRouter(DetailsHeadComponentWrapper))))))
