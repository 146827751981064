import * as React from "react"
import { DateField } from "@tm/controls"
import { CarModelDetails } from "@tm/models"
import { useStyle } from "@tm/context-distribution"
import { CalcInput } from "../../../data/model"
import { IMainActions, MainActions } from "../../main/business"
import { connector, constructionYearToDate } from "../../../data/helpers"
import { ECalcInputValueValidator, ECalcInputType } from "../../../data/enums"

type Props = {
    item: CalcInput
    actions: ActionType
    modelDetails?: CarModelDetails
    onChangeCallBack?: (data: any) => void
}

const CalcInputDate: React.FC<Props> = (props) => {
    const handleChange = (date: Date) => {
        const { actions, item } = props
        const value = new Date(date.setHours(12))

        if (date != item.value) {
            actions.handleInputSubmit({ ...item, value })
            props?.onChangeCallBack?.(date)
        }
    }

    const { item, modelDetails } = props
    const maxDateValidator = item.valueValidators.find((x) => (x.type = ECalcInputValueValidator.Max))?.value
    const openToDate =
        (item.type == ECalcInputType.RegDate && modelDetails?.constructionYearFrom && constructionYearToDate(modelDetails.constructionYearFrom)) ||
        undefined

    return (
        <div className={style.hiddenTitle}>
            <DateField
                floatingLabel
                label={item.label}
                disabled={item.isDisabled}
                value={(item.value && new Date(String(item.value))) || undefined}
                maxDate={maxDateValidator && new Date(maxDateValidator)}
                openToDate={openToDate}
                ignoreOnSelect
                onChange={handleChange}
            />
        </div>
    )
}

const style = useStyle({
    hiddenTitle: {
        $nest: {
            ".react-datepicker": {
                display: "flex",
                $nest: {
                    "&__current-month, &__navigation": {
                        display: "none",
                    },
                    "&__header": {
                        $nest: {
                            "&__dropdown": {
                                height: "2em",
                                display: "flex",
                                flex: 1,
                                fontSize: "1rem",
                                alignItems: "center",
                            },
                        },
                    },
                    "&__year-dropdown-container, &__month-dropdown-container": {
                        width: "50%",
                        display: "flex",
                    },
                    "&__year-dropdown-container": {
                        justifyContent: "flex-start",
                    },
                    "&__month-dropdown-container": {
                        justifyContent: "flex-end",
                    },
                },
            },
        },
    },
})(CalcInputDate)

type ActionType = Pick<IMainActions, "handleInputSubmit">

const actions: ActionType = {
    handleInputSubmit: MainActions.handleInputSubmit,
}

export default connector(CalcInputDate, undefined, actions)
