import { getStyleTheme } from "@tm/context-distribution"
import { style } from "typestyle"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return style({
        $nest: {
            "&__tooltip": {
                whiteSpace: "pre-wrap",
                $nest: {
                    ".tooltip__wrapper": {
                        minWidth: "15em",
                        maxWidth: "25em",
                    },
                },
            },
            "&__button": {
                position: "relative",
            },
            "&__icon": {
                objectFit: "contain",
            },
            "&__badge": {
                top: margin.s,
            },
        },
    })
}
