import { Suspense } from "react"
import { connectComponent } from "@tm/morpheus"
import { Loader } from "@tm/controls"
import { IMicros } from "@tm/models"
import { Box } from "@tm/components"
import AvailabilityWrapperComponent from "../../../_shared/availability-wrapper"
import { useErpInfoDetailsWithRedux } from "../../_helpers/details-business/useErpInfoDetailsWithRedux"
import { ErpInfoDetailsActions, IErpInfoDetailsActions } from "../../_helpers/details-business"
import { ErpWrapper } from "../../../_shared/ErpWrapper"
import { StyledBox } from "../StyledComponents"

type Props = IMicros["erp"]["details-erp-info-availability"] & {
    actions: IErpInfoDetailsActions
}

function DetailsErpInfoAvailabilityComponent(props: Props) {
    const { loading, erpInfo, error } = useErpInfoDetailsWithRedux(props)

    if (loading) {
        return <Loader />
    }

    return (
        <AvailabilityWrapperComponent
            hasReplacedArticles={!!erpInfo?.articles?.length}
            availability={erpInfo?.availability}
            tour={erpInfo?.tour}
            warehouses={erpInfo?.warehouses}
            error={error}
            requestedQuantity={erpInfo?.quantity?.requestedValue}
            specialProcurementInformation={erpInfo?.specialProcurementErpInformation}
            useSpecialProcurement={erpInfo?.useSpecialProcurement}
        />
    )
}

function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <Box className="tk-erp">
                <StyledBox>
                    <Suspense fallback={<Loader />}>
                        <Box>
                            <DetailsErpInfoAvailabilityComponent {...props} />
                        </Box>
                    </Suspense>
                </StyledBox>
            </Box>
        </ErpWrapper>
    )
}

export default connectComponent(ErpInfoDetailsActions, Wrapper)
