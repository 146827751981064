import { Box, styled } from "@tm/components"
import { useCisCustomer, useUser, useWorkTask } from "@tm/context-distribution"
import { IFrame, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel, TitleType, VehicleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { BrowserInfo, concat, createQueryString, encodeUniqueId, RouteComponentProps, useModelDetails, withRouter } from "@tm/utils"
import { Suspense, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { getBundleParams } from "../../utils"
import { LoginRequest } from "../../data"
import { GetMvcPlatformId, getCatalogNumber, getMvcSessionId } from "../../data/helpers/common"
import ImportAdditionalDetails from "../_shared/importAdditionalData"
import {
    deleteFromLocalStorage,
    getExpirationTimestampHours,
    getExpirationTimestampMinutes,
    getFromLocalStorage,
    saveToLocalStorage,
} from "./helpers/localstorage"
import { FeedbackPersistedData } from "./models"

type RouteProps = {
    workTaskId: string
    // ^article-feedback
    productGroupId?: string
    supplierId?: string
    supplierName?: string
    supplierArticleNo?: string
    traderArticleNo?: string
    productGroupName?: string
    // The following props are only filled in the part repairtimes context and ^negative-sale
    position?: string
    quantityValue?: string
    itemId?: string
    articleDescription?: string
    topicId?: string
}

type Props = RouteComponentProps<RouteProps> & {
    className?: string
    title: string
    id: string
    loginUrl: string
    loginRequest: LoginRequest
    externalSystemKey: string | number
    withoutVehicle?: boolean
    observeViewportIntersection?: boolean
    useNativeIFrame?: boolean
    refreshOnUrlChanged?: boolean
    importAdditionalData?: boolean
    extendedFeedback?: boolean
}

const FeedbackFrameComponent = (props: Props) => {
    const className = `module frame ${props.className || ""}`

    return (
        <Suspense
            fallback={
                <div className={className}>
                    <Loader />
                </div>
            }
        >
            <Component {...props} />
        </Suspense>
    )
}

const StyledWrapper = styled(Box)(() => ({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    width: "100%",
    margin: "1em",
}))

export default withRouter(FeedbackFrameComponent)

function Component(props: Props) {
    const { loginUrl, loginRequest, externalSystemKey, id, withoutVehicle, match, importAdditionalData, extendedFeedback } = props

    const [moduleLoading, setModuleLoading] = useState(true)
    const [iFrameLoading, setIFrameLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [showImportAdditionalData, setShowImportAdditionalData] = useState(importAdditionalData)
    const [sendAdditionalInfo, setSendAdditionalInfo] = useState<boolean>(false)
    const [showVehicleSection, setShowVehicleSection] = useState(true)

    const { sendCisCustomerEmail } = getBundleParams()
    const { cisCustomer, cisCustomerLoading } = useCisCustomer(sendCisCustomerEmail)

    const { translateText, languageId, date } = useLocalization()
    const { userContext, userSettings } = useUser() || {}
    const { vehicle, id: workTaskId, statusValue } = useWorkTask()?.workTask || {}
    const location = useLocation()

    const modelDetailsResponse = useModelDetails({ vehicleType: vehicle?.vehicleType, modelId: vehicle?.tecDocTypeId })

    useEffect(() => {
        if (!workTaskId) {
            return
        }

        const savedData = getFromLocalStorage<FeedbackPersistedData>(workTaskId)
        if (savedData?.vehicleSelectionValue != null) {
            // If we have a saved vehicle selection value don't show it again
            setShowVehicleSection(false)
            setSendAdditionalInfo(savedData.vehicleSelectionValue)
        }

        setModuleLoading(false)
    }, [workTaskId])

    useEffect(() => {
        const unsubscribeFeedbackResult = channel("GLOBAL").subscribe("MVC/FEEDBACK_RESULT", ({ getFeedbackResult }) => {
            if (getFeedbackResult) {
                deleteFromLocalStorage(workTaskId ?? "")
                Morpheus.closeView("1")
            } else {
                setErrorMessage(translateText(1706))
            }
        })

        return () => {
            unsubscribeFeedbackResult?.()
        }
    }, [])

    function getTitle(title: TitleType | undefined) {
        switch (title) {
            case TitleType.Mister:
                return translateText(145)
            case TitleType.Miss:
                return translateText(146)
            case 3:
                return translateText(108)
            default:
                return ""
        }
    }

    function getBaseRequestParameters(): LoginRequest {
        const catNr = getCatalogNumber(userContext)

        return {
            ...loginRequest,
            platformId: GetMvcPlatformId(userContext.system.systemType), // This is the id used by the MVC Application to identify NEXT
            sectionId: loginRequest?.sectionId,
            languageId, // MLE - 22.05.19: changed from "language" to "languageId"
            mdmSessionId: userContext?.id,
            h_session: getMvcSessionId(workTaskId),
            catNr,
            clientId: catNr,
        }
    }

    const getRequestParameter = (): LoginRequest | null => {
        if (!userContext) {
            return null
        }

        const queryParams = new URLSearchParams(location.search)

        const request: LoginRequest = {
            ...getBaseRequestParameters(),
            supplierNumber: queryParams.get("supplierId") ?? (match.params.supplierId || ""),
            supplierName: queryParams.get("supplierName") ?? (match.params.supplierName || ""),
            supplierArticleNumber: queryParams.get("supplierArticleNo") ?? (match.params.supplierArticleNo?.replace(/%252F/g, "/") || ""),
            wholesalerArticleNumber: queryParams.get("traderArticleNo") ?? (match.params.traderArticleNo || ""),
            productGroupName: queryParams.get("productGroupName") ?? (match.params.productGroupName || ""),
        }
        if (externalSystemKey) {
            request.externalSystemKey = externalSystemKey
        }

        if (!withoutVehicle && !(extendedFeedback && !sendAdditionalInfo) && vehicle && vehicle.tecDocTypeId) {
            request.kTypeId =
                vehicle.vehicleType === VehicleType.CommercialVehicle
                    ? vehicle.tecDocTypeId - getBundleParams().commercialVehicleOffset
                    : vehicle.tecDocTypeId
            request.engineCode = vehicle.engineCode
            request.typeArt = vehicle.vehicleType
            request.vinNumber = vehicle.vin
            request.regNo = vehicle.plateId
            request.registrationNo = vehicle.registrationNo
            request.mileage = vehicle.mileAge
            request.initialRegistration = vehicle.initialRegistration && date(vehicle.initialRegistration, "d")
            request.manufacturer = vehicle.manufacturer
            request.model = vehicle.model
            request.modelType = vehicle.modelSeries
        }

        request.customerNo = userContext.account?.customerNo

        if (modelDetailsResponse?.modelDetails?.registrationNos?.length && !getBundleParams().disableRegNos) {
            request.registrationNos = modelDetailsResponse.modelDetails.registrationNos.join(",")
        }

        if (importAdditionalData) {
            request.vehInfoEnabled = sendAdditionalInfo

            if (userSettings?.repairShop) {
                const { repairShop } = userSettings
                const addressArray = (repairShop.postalAddress && Object.values(repairShop.postalAddress)) || []
                const title = getTitle(repairShop.contactPerson?.title)
                const contactPersonName =
                    (repairShop.contactPerson && [title, repairShop.contactPerson.firstName, repairShop.contactPerson.lastName]) || []

                request.repairShopAddress = concat(", ", ...addressArray)
                request.repairShopPhone = repairShop.contactInfo?.phone
                request.repairShopEmail = repairShop.contactInfo?.email
                request.repairShopContact = contactPersonName.join(" ")
            }
        }

        if (cisCustomer) {
            request.customerNo = cisCustomer.customerNo
            request.companyName = cisCustomer.companyName
            request.companyAddName = cisCustomer.companyNameAddition1

            let companyAddress = ""
            const address = cisCustomer.addresses?.[0]

            if (address?.street) {
                companyAddress = companyAddress.concat(address.street, ",")
            }

            if (address?.zip) {
                companyAddress = companyAddress.concat(address.zip)
            }

            if (address?.city) {
                companyAddress = companyAddress.concat(" ", address.city)
            }

            request.companyAddress = companyAddress
        }

        if (sendCisCustomerEmail && !!cisCustomer) {
            const emails: string[] = []

            cisCustomer?.salesOutlets?.forEach((c) => {
                const tempEmails = c.contacts
                    ?.find((x) => x.jobDescription === "VKH_EMail_Fax")
                    ?.electronicAddresses?.filter((e) => e.type === 1 && !!e.value)
                    ?.flatMap((e) => e.value.toLowerCase())

                if (tempEmails?.length) {
                    emails.push(...tempEmails)
                }
            })

            if (emails.length) {
                request.emailRecipients = emails.join(",")
            }
        }

        if (extendedFeedback) {
            request.version = (window as any).__version
            request.workTaskId = encodeUniqueId(workTaskId ?? "")

            if (BrowserInfo) {
                const browser = BrowserInfo.getBrowser()
                const os = BrowserInfo.getOS()
                request.browser = browser.name
                request.browserVersion = browser.versionString
                request.opSystem = os.name
                request.opVersion = os.versionString
            }

            let requestApplicationInfo = userContext.system.traderDescription ?? "_"
            const applicationInformation = cisCustomer?.applicationInformation
            if (applicationInformation) {
                requestApplicationInfo = `${requestApplicationInfo};${applicationInformation.edition ?? "_"};${applicationInformation.mode ?? "_"}`
            }

            request.applicationInformation = requestApplicationInfo
            request.username = userContext.account?.username
        }

        return request
    }

    const handleVehicleAcceptance = (sendAdditionalInfo: boolean) => {
        setShowImportAdditionalData(false)
        setShowVehicleSection(false)
        setSendAdditionalInfo(sendAdditionalInfo)
        const dataToSave: FeedbackPersistedData = {
            vehicleSelectionValue: sendAdditionalInfo,
        }

        saveToLocalStorage(workTaskId ?? "", dataToSave, getExpirationTimestampHours(7 * 24))
    }

    if (moduleLoading) {
        return (
            <Box flex="1" alignItems="center" display="flex" justifyContent="center">
                <Loader />
            </Box>
        )
    }

    if (showVehicleSection && showImportAdditionalData && statusValue && vehicle) {
        return (
            <StyledWrapper>
                <ImportAdditionalDetails
                    vehicle={vehicle!}
                    modelDetails={modelDetailsResponse?.modelDetails}
                    onVehicleAcceptance={handleVehicleAcceptance}
                />
            </StyledWrapper>
        )
    }

    if (sendCisCustomerEmail && cisCustomerLoading) {
        return null
    }

    const request = getRequestParameter()

    if (!request) {
        return null
    }

    const url = `${loginUrl}${createQueryString(request)}`
    const className = `module frame ${props.className || ""}`

    if (errorMessage) {
        return (
            <Text modifiers="danger" size="l">
                {errorMessage}
            </Text>
        )
    }

    if (props.useNativeIFrame) {
        return (
            <>
                <iframe
                    style={{ ...(iFrameLoading && { display: "none" }) }}
                    src={url}
                    className={className}
                    id={`iframe_${id}`}
                    onLoad={() => setIFrameLoading(false)}
                />
                {iFrameLoading && (
                    <Box sx={{ position: "relative" }} className={className}>
                        <Loader />
                    </Box>
                )}
            </>
        )
    }

    return (
        <IFrame
            url={url}
            className={className}
            contextDependent
            id={`iframe_${id}`}
            observeViewportIntersection={props.observeViewportIntersection}
            refreshOnUrlChanged={props.refreshOnUrlChanged}
            onLoad={() => setIFrameLoading(false)}
        >
            {iFrameLoading ? <Loader /> : <></>}
        </IFrame>
    )
}
