import { Searchtree } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { CategoryType, channel, RequestArticleListPayload, TreeNode } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, renderRoute, TmaHelper } from "@tm/utils"
import { useEffect } from "react"
import useBreadCrumbHandler from "../../../hooks/breadCrumbhandler/useBreadCrumbHandler"
import { getBundleParams } from "../../../utils"
import { UniBestsellerSearchTree } from "../../_shared/uni-search-top-products/component"

type Props = {
    searchTreeType: CategoryType
}

export default function SearchTreeWidget(props: Props) {
    const user = useUser()
    const { workTask, workTaskId } = useWorkTask() ?? {}
    const { newBreadcrumbSelected, breadCrumbState, isLoading } = useBreadCrumbHandler(undefined, props.searchTreeType, workTask)
    const fastCalcModalRoute = getBundleParams().fastCalculatorModalRoute
    const nodeVariant = getBundleParams().searchtreeNodeVariant
    const showBestseller = !user?.userContext.parameter.hideBestsellers

    useEffect(() => {
        if (breadCrumbState.breadCrumbs?.length > 0) {
            newBreadcrumbSelected({ id: 0, name: "OVERVIEW", hasChildNodes: false })
        }
    }, [])

    const updateArticleList = (node: TreeNode, fastClick?: boolean) => {
        if (!breadCrumbState.treeId) {
            return
        }

        TmaHelper.Shared.ByArticleAndUniParts.SearchTreeNodeSelected({
            nodeId: node.id,
            treeId: breadCrumbState.treeId,
            mode: fastClick ? "fast-click-2.0" : "buttons",
            searchTreeType: props.searchTreeType,
        })

        const history = breadCrumbState.breadCrumbs?.map((b) => b.id) || []
        history.push(node.id)

        let request: RequestArticleListPayload = {
            node: { treeId: breadCrumbState.treeId, nodeId: node.id, history },
        }

        if (props.searchTreeType === "universalParts") {
            request = {
                uniNode: {
                    nodeId: node.id,
                    history,
                    treeId: breadCrumbState.treeId,
                },
            }
        }

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }

    const nodeClick = (node: TreeNode, fastClick?: boolean) => {
        newBreadcrumbSelected(node)
        if ((!node.hasChildNodes && breadCrumbState.treeId) || fastClick) {
            updateArticleList(node, fastClick)
        }
    }

    const onBreadCrumbClick = (node: TreeNode) => {
        newBreadcrumbSelected(node)
    }

    const applyProductGroups = (productGroupIds?: number[]) => {
        const history = breadCrumbState.breadCrumbs?.map((b) => b.id) || []
        const { searchTreeType } = props
        if (breadCrumbState.selectedNode && productGroupIds && productGroupIds?.length >= 1) {
            const request: RequestArticleListPayload = {
                [searchTreeType === "universalParts" ? "uniProductGroups" : "productGroups"]: {
                    ids: productGroupIds.orderBy((x) => x),
                    treeId: breadCrumbState.treeId,
                    nodeId: breadCrumbState.selectedNode.id,
                    history,
                },
            }

            TmaHelper.Shared.ByArticleAndUniParts.SearchTreeNodeSelected({
                nodeId: breadCrumbState.selectedNode.id,
                treeId: breadCrumbState.treeId,
                mode: "fast-click-2.0",
                searchTreeType,
            })

            channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
        }
    }

    const fastcalculation = (productGroupIds?: number[]) => {
        if (workTaskId && productGroupIds && fastCalcModalRoute) {
            Morpheus.showView(
                "1",
                renderRoute(fastCalcModalRoute, { workTaskId: encodeUniqueId(workTaskId), productGroupIds: productGroupIds.join(",") })
            )
        }
    }

    return (
        <>
            {props.searchTreeType === "universalParts" && <UniBestsellerSearchTree hide={breadCrumbState?.breadCrumbs?.length > 0} />}
            <Searchtree
                tree={breadCrumbState.initialTree}
                selectedNode={breadCrumbState?.selectedNode}
                breadCrumbs={breadCrumbState.breadCrumbs}
                onNodeClick={nodeClick}
                onBreadCrumbClick={onBreadCrumbClick}
                variant="full"
                loading={isLoading}
                showBestseller={showBestseller}
                applyProductGroups={applyProductGroups}
                fastCalculation={fastcalculation}
                hideFastCalcButton={!fastCalcModalRoute}
                maxSelectableProductGroups={getBundleParams().maxSelectedCategoriesForFastCalculation}
                hideSecondLevel={props.searchTreeType !== "vehicleParts"}
                nodeVariant={nodeVariant}
                hideTipIcon={nodeVariant === "fastClick"}
            />
        </>
    )
}
