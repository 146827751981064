import { Box, styled } from "@tm/components"
import { Checkbox, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FC, useState } from "react"
import { VehicleAndCustomer } from "."
import MuiCustomModal from "./modal/MuiCustomModal"
import { PrintOptions } from "../../data/models"

type Props = {
    open: boolean
    onOutsideClick: () => void
}

const CustomEndModal: FC<Props> = ({ open, onOutsideClick }) => {
    const [state, setState] = useState<PrintOptions>({
        inspectionTimes: false,
        remarks: false,
        images: false,
        tyres: false,
        nextMaintenance: false,
        showDefects: false,
    })
    const { translateText } = useLocalization()

    const handleCheck = (key: keyof typeof state) => {
        setState((prevState) => ({ ...prevState, [key]: !prevState[key] }))
    }

    return (
        <MuiCustomModal open={open} stateSelections={state} printButton onOutsideClick={onOutsideClick}>
            <VehicleAndCustomer headerText={translateText(12962)} showServiceText />
            <StyledText size="m">{translateText(12963)}</StyledText>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("inspectionTimes")} checked={state.inspectionTimes} label={translateText(12964)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("remarks")} checked={state.remarks} label={translateText(12965)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("images")} checked={state.images} label={translateText(12685)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("tyres")} checked={state.tyres} label={translateText(240)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("nextMaintenance")} checked={state.nextMaintenance} label={translateText(12966)} />
            </StyledCheckRow>
            <StyledCheckRow>
                <Checkbox size="xl" onToggle={() => handleCheck("showDefects")} checked={state.showDefects} label={translateText(12845)} />
            </StyledCheckRow>
        </MuiCustomModal>
    )
}
export default CustomEndModal

const StyledCheckRow = styled(Box)(({ theme }) => ({
    display: "flex",
    marginBottom: theme.margin?.l ?? ".75em",
}))

const StyledText = styled(Text)(({ theme }) => ({
    display: "block",
    paddingBottom: theme.margin?.xl ?? "1em",
}))
