import { getAttributeString } from "."
import { TyreArticle } from "../../../../data/models"
import { getBundleParams } from "../../../../utils"
import { AvailabilityFilterType } from "../model"
import { AvailabilityStatus } from "@tm/models"
import { useMemo } from "react"

export const filterList = (data: TyreArticle[], selectedAttr: string[], availability: AvailabilityFilterType) => {
    const { availabilityFilterItemsToShow, availabilityFilterItemsToShowSecondary } = getBundleParams()
    let items = [...data]
    return useMemo(() => {
        if (availability || selectedAttr.length) {
            items = items.filter(part => {
                let visible = true
                if (availability === AvailabilityFilterType.Primary && availabilityFilterItemsToShow?.length) {
                    const mod = part.erpInformation?.availability
                    if (mod && !availabilityFilterItemsToShow.includes(mod.type as AvailabilityStatus))
                        visible = false
                }
                else if (availability === AvailabilityFilterType.Secondary && availabilityFilterItemsToShowSecondary?.length) {
                    const mod = part.erpInformation?.availability
                    if (mod && !availabilityFilterItemsToShowSecondary.includes(mod.type as AvailabilityStatus))
                        visible = false
                }

                if (selectedAttr.length) {
                    const allAttr = [...part.attributes?.[0]?.topAttributes, ...part.attributes?.[0]?.articleAttributes]
                    if (!selectedAttr.length || selectedAttr.every(i => allAttr.some(att => i == getAttributeString(att))))
                        visible = visible
                    else
                        visible = false
                }

                return visible
            })
        }

        return [...items]
    }, [items, selectedAttr, availability]);
}