import { Icon, Stack, Tooltip, useTheme } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { GetProductGroupTopicIdsResponse, RepairTimeProvider, VehicleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, getUIA, renderRoute } from "@tm/utils"
import { memo } from "react"
import { useRouteMatch } from "react-router"
import { GroupHeaderIconButton } from "./GroupHeaderIconButton"
import { TechnicalDataButtons } from "./TechnicalDataButtons"
import { ToolRentalButton } from "./ToolRentalButton"

type Props = PropsWithToolRentalButton | PropsWithFastCalculatorButton | (PropsWithToolRentalButton & PropsWithFastCalculatorButton)

type PropsWithToolRentalButton = {
    productGroupId: number
    productGroupTopicIds: GetProductGroupTopicIdsResponse
}

type PropsWithFastCalculatorButton = {
    productGroupId: number
    calculatorRoute: string
}

function GroupHeaderButtonAreaComponent(props: Props) {
    const { productGroupId } = props

    const theme = useTheme()
    const { showButtonLables, buttonVariant } = theme.overwrites?.components?.partlist?.groupHeader ?? {}

    const { translateText } = useLocalization()
    const { params } = useRouteMatch()
    const { vehicleType } = useWorkTask()?.workTask?.vehicle ?? {}
    const { workTask } = useWorkTask() ?? {}

    const withCalculatorRoute = "calculatorRoute" in props
    const withTopicIds = "productGroupTopicIds" in props
    const mobilityMarketTopicIds = withTopicIds
        ? props.productGroupTopicIds[productGroupId]?.[RepairTimeProvider.MobilityMarketToolRental]
        : undefined

    const handleRequestCalculation = () => {
        if (!withCalculatorRoute) {
            return
        }

        Morpheus.showView(
            "1",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            renderRoute(props.calculatorRoute, { ...params, productGroupIds: productGroupId, workTaskId: encodeUniqueId(workTask!.id!) })
        )
    }

    return (
        <Stack ml={4} spacing={0.5} direction="row">
            {mobilityMarketTopicIds && <ToolRentalButton provider="1" topicIds={mobilityMarketTopicIds} />}

            {withTopicIds && <TechnicalDataButtons productGroupId={productGroupId} productGroupTopicIds={props.productGroupTopicIds} />}

            {withCalculatorRoute && vehicleType === VehicleType.PassengerCar && (
                <Tooltip title={translateText(1042)}>
                    <GroupHeaderIconButton
                        className="btn--calculate"
                        startIcon={<Icon name="fast-calculator" />}
                        variant={buttonVariant || "outlined"}
                        onClick={handleRequestCalculation}
                        {...getUIA("ArticelListHeaderFastcalc")}
                    >
                        {showButtonLables && translateText(1042)}
                    </GroupHeaderIconButton>
                </Tooltip>
            )}
        </Stack>
    )
}

export const GroupHeaderButtonArea = memo(GroupHeaderButtonAreaComponent)
