import { Fragment } from "react"
import { useLocalization } from "@tm/localization"
import { Collapsible } from "@tm/controls"
import { Box, Paper } from "@tm/components"
import { Container } from "@tm/nexus"
import { CisVoucherType, FittingPosition, IMicros, RegisteredModels, RepairTimeProvider, RepairTimesProvidersNames } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { createQueryString, encodeUniqueId, getRepairTimesProvider, renderRoute, useActiveVehicleDataProviders } from "@tm/utils"
import { WorkTaskInfo, useUser } from "@tm/context-distribution"
import { useHistory, useParams } from "react-router"
import { useWorkTaskBasketState } from "../../../../hooks/basketState/useWorkTaskBasketState"
import { BasketPart } from "../../../../models"
import TotalNumbers from "./totals/TotalNumbers"
import { VehicleImage } from "./VehicleImage"
import ErrorAlert from "../../../_shared/ErrorAlert"
import PartsList from "./parts-list"
import WorksList from "./works-list"
import { AllTotals } from "./totals/AllTotals"
import { useRepairTimeProviders } from "../../../../hooks/basketState/useRepairTimeProviders"

type Props = {
    cisVoucherUrl?: string
    creatingCostEstimationVoucher: boolean
    partRepairTimesRoute: string
    partsDirectListRoute: string
    partsVehicleListRoute: string
    showAllPrices?: boolean
    voucherTypeId?: CisVoucherType
    workTask: WorkTaskInfo
}

export function CostEstimationVehicleInfo(props: Props) {
    const {
        workTask,
        partRepairTimesRoute,
        partsDirectListRoute,
        partsVehicleListRoute,
        showAllPrices,
        voucherTypeId,
        cisVoucherUrl,
        creatingCostEstimationVoucher,
    } = props
    const { translateText } = useLocalization()
    const { userContext, userSettings } = useUser() ?? {}
    const history = useHistory()
    const { renderMicro } = useMicro<IMicros>()
    const { activeProviders } = useActiveVehicleDataProviders(workTask.id)
    const matchParams = useParams<{ workTaskId?: string; view?: string }>()

    const {
        costEstimation: costEstimationState,
        workTaskBasketCalculationLoading,
        workTaskBasketCalculation,
        workTaskBasketCalculationWithError,
    } = useWorkTaskBasketState(workTask.id)

    const { repairTimeProviders } = useRepairTimeProviders()

    const { calculatedCostEstimation } = workTaskBasketCalculation || {}

    const { costEstimation, currencyCode, showVehicleImage } = costEstimationState.state

    function handleOpenRepairTimes(part: BasketPart) {
        const { productGroupId } = part.partItem.articleInformation
        const { supplierId } = part.partItem.articleInformation
        const { articleNumber } = part.partItem.articleInformation
        if (!productGroupId || !supplierId || !articleNumber) {
            return
        }
        const container = Container.getInstance<{ key: string; value: { state: boolean } }>(RegisteredModels.ViewState)
        const subscribe = container.subscribe("COST_ESTIMATION_VISIBLE")
        subscribe.save && subscribe.save({ key: `${encodeUniqueId(workTask.id)}__COST_ESTIMATION_VISIBLE`, value: { state: true } })

        Morpheus.showView(
            "1",
            getRepairTimesUrlPerPart(
                productGroupId,
                supplierId,
                articleNumber,
                part.partItem.fittingSide ?? FittingPosition.None,
                part.repairTimeProviders
            ) as string
        )
    }

    function getRepairTimesUrlPerPart(
        productGroupId: number,
        supplierId: number,
        supplierArticleNo: string,
        fittingPosition: FittingPosition,
        repairTimeAvailabilities?: Array<RepairTimeProvider>
    ): string {
        if (repairTimeAvailabilities) {
            return decodeURIComponent(
                renderRoute(partRepairTimesRoute, {
                    ...matchParams,
                    provider: getArticleRelatedRepairTimesProviderName(repairTimeAvailabilities),
                    productGroupId,
                    supplierId,
                    supplierArticleNo: supplierArticleNo.replace(/\//g, "%252F"),
                    position: fittingPosition,
                })
            )
        }
        return ""
    }

    function openArticleDirectSearchUrl(query: string) {
        if (partsDirectListRoute) {
            const url = `${renderRoute(partsDirectListRoute, { ...matchParams })}/direct${createQueryString({ query })}`
            history.push(url)
        }
    }

    function getArticleRelatedRepairTimesProviderName(repairTimeAvailabilities: Array<RepairTimeProvider>): RepairTimesProvidersNames | undefined {
        let provider = activeProviders?.repairTimes
        if (!provider && userSettings) {
            provider = userSettings.activeVehicleDataProviders.repairTimes
        }

        if (repairTimeProviders) {
            const providers = Object.keys(repairTimeProviders)
                .map((key) => ({ ...repairTimeProviders[key] }))
                .filter((x) => !!x.id)
            if (repairTimeAvailabilities) {
                provider = getRepairTimesProvider(repairTimeAvailabilities, providers, provider)
            }
        }

        return provider
    }

    function renderTotalNumbers() {
        return (
            <Fragment key="vehicleTotals">
                <TotalNumbers
                    calculationLoading={workTaskBasketCalculationLoading}
                    costEstimationTotals={calculatedCostEstimation?.totalsB2B ?? calculatedCostEstimation?.totalsB2C}
                />
            </Fragment>
        )
    }

    return (
        <>
            <Paper>
                <Collapsible name={translateText(99)} initiallyOpened renderHeaderAppendix={renderTotalNumbers}>
                    <Box pb={1}>{renderMicro("crm", "vehicle-overview", {})}</Box>
                    {showVehicleImage && costEstimation?.vehicleImage && <VehicleImage base64Image={costEstimation.vehicleImage} />}
                    {workTaskBasketCalculationWithError && <ErrorAlert />}
                    <PartsList
                        cisVoucherUrl={cisVoucherUrl}
                        creatingCostEstimation={creatingCostEstimationVoucher}
                        partsDirectListRoute={partsDirectListRoute}
                        partsVehicleListRoute={partsVehicleListRoute}
                        showAllPrices={!!showAllPrices}
                        showPurchasePrice={userSettings?.showPurchasePrice}
                        voucherTypeId={voucherTypeId}
                        workTask={workTask}
                        onOpenArticleSearch={(query: string) => openArticleDirectSearchUrl(query)}
                        onOpenPartRepairTimes={handleOpenRepairTimes}
                    />
                    <WorksList
                        activeProviders={activeProviders}
                        creatingCostEstimation={creatingCostEstimationVoucher}
                        userContext={userContext}
                        userSettings={userSettings}
                        workTask={workTask}
                        vehicleId={workTask.vehicle?.id}
                    />
                </Collapsible>
            </Paper>
            <Box alignSelf="flex-end" pr={2}>
                <AllTotals
                    costEstimationOptions={costEstimation?.costEstimationOptions}
                    currencyCode={currencyCode}
                    totalsLoading={workTaskBasketCalculationLoading}
                    costEstimationCalculation={calculatedCostEstimation}
                />
            </Box>
        </>
    )
}
