import {
    Article,
    ArticleImage,
    ArticleAttribute,
    ArticleIdentifier,
    ArticleSearchHitInfo,
    GetAccessoryListRequest,
    GetArticlesRequest,
    GetArticlesResponse,
    GetPartsListRequest,
    SupplierArticle,
    GetSearchTreeInfoResponse,
    GetSearchTreeNodesInfoRequest,
    GetSearchTreeResponse,
    GetSearchTreeRequest,
    SearchTreeNode,
    GetSearchTreeNodesRequest,
    getCurrentWorkTaskId,
    OE,
} from "@tm/models"
import {
    ajax,
    AnalyticsData,
    createBufferedRequestFunction,
    decodeUniqueId,
    Dictionary,
    getStoredAuthorization,
    notUndefinedOrNull,
    TmaHelper,
} from "@tm/utils"
import { Models } from ".."

import { getBundleParams } from "../../utils"
import * as Mappers from "../mapper"
import {
    GetArticlesByBarcodeScannerRequest,
    GetArticlesByWholesalerArticleNosRequest,
    GetArticlesByWholesalerArticleNosResponse,
    GetArticleSearchHitInfoRequest,
    GetOeAttributesRequest,
    GetOeAttributesResponse,
    GetOeInformationResponse,
    GetSearchFiltersRequest,
    OeInformationRequest,
} from "../model"
import { GetIndustrialInformationRequest, GetIndustrialInformationResponse, IndustrialInformationRequest } from "../model/industrial-information"

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.partsServiceUrl
}

export function getSearchTreeNodes(request: GetSearchTreeNodesRequest): Promise<Array<SearchTreeNode>> {
    const url = `${getServiceUrl()}/SearchTreeNodes`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<Array<SearchTreeNode>>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => resolve(data?.searchTreeNodes || []), reject)
    )
}

export function getSearchTree(request: GetSearchTreeRequest): Promise<GetSearchTreeResponse> {
    const url = `${getServiceUrl()}/SearchTree`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<GetSearchTreeResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => resolve(Mappers.mapGetSearchTreeResponse(data)), reject)
    )
}

export function getSearchTreeNodeInfos(request: GetSearchTreeNodesInfoRequest) {
    const url = `${getServiceUrl()}/SearchTreeNodesInfo`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<GetSearchTreeInfoResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => {
            return resolve(data)
        }, reject)
    )
}

/**
 * @param data Data which needs to be tracked
 * @param source where does this track come from
 */
function trackFilterData(data: AnalyticsData, source: string) {
    TmaHelper.ArticleListFiltered.ArticleListFiltered.List.FilterChanged(data, source)
}

export function getFilters(request: Models.GetProductGroupAndSupplierFiltersRequest): Promise<Models.GetProductGroupAndSupplierFiltersResponse> {
    const url = `${getServiceUrl()}/Filters/ProductGroups`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<Models.GetProductGroupAndSupplierFiltersResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => {
            const mappedResponse = {
                productGroupFilters: data?.productGroupFilters || [],
                dataSupplierFilters: data?.dataSupplierFilters || [],
                constructionYearFilters: data?.constructionYearFilters || [],
                showExtendedAssortmentFilter: data ? data.showExtendedAssortmentFilter : false,
            }
            trackFilterData(
                {
                    filter: {
                        available: {
                            productGroupFilters: mappedResponse.productGroupFilters,
                            dataSupplierFilters: mappedResponse.dataSupplierFilters,
                        },
                    },
                },
                "getProductGroupAndSupplierFilters"
            )
            return resolve(mappedResponse)
        }, reject)
    )
}

const IndexQuery = 1
function sendTmaInfo(url: string, request: GetArticlesRequest, response: GetArticlesResponse | undefined, search: string) {
    setTimeout(() => {
        const match = /query=([\w\d]+)|nodeId=(\d+)/.exec(search)
        const searchedNodeId = match?.last()

        const m = /[\w\d]+/.exec(document.location.pathname)
        let worktaskId = ""

        if (m?.[0] && m[0] !== "parts") {
            worktaskId = decodeUniqueId(m[0]) ?? ""
        }
        const requestCopy = { ...request }
        if (match && !searchedNodeId && !request.query) {
            const query = match[IndexQuery]
            requestCopy.query = query
        }

        TmaHelper.ArticleListFiltered.ArticleListFiltered.List.Results(requestCopy, response, worktaskId, url, searchedNodeId)
    }, 200)
}

// This needs to be rethinked,
// this is a workaround to not refactor the request, response of the Results function and all events/functions that follows
function sendTmaInfoWholesalerArticleNos(url: string, _request: GetArticlesByWholesalerArticleNosRequest, _response: Dictionary<Array<Article>>) {
    setTimeout(() => {
        const articles = Object.keys(_response).flatMap((key) => _response[key])

        const response: GetArticlesResponse = {
            articles,
            pageIndex: 1,
            pageSize: articles?.length || 25,
            articleListCount: articles?.length,
        }

        const request: GetArticlesRequest = {
            pageIndex: response.pageIndex,
            pageSize: response.pageSize,
            // query must be filled in order for TMA to log!
            // eslint-disable-next-line no-nested-ternary
            query: _request.wholesalerArticleNumbers?.length
                ? _request.wholesalerArticleNumbers.join(",")
                : // eslint-disable-next-line no-nested-ternary
                _request.selectedProductGroupIds?.length
                ? _request.selectedProductGroupIds.join(",")
                : _request.selectedDataSupplierIds?.length
                ? _request.selectedDataSupplierIds.join(",")
                : "no query",
            supplierArticleNos: _request.wholesalerArticleNumbers,
            productGroupIds: _request.selectedProductGroupIds,
            supplierIds: _request.selectedDataSupplierIds,
            modelId: _request.modelId,
        }

        TmaHelper.ArticleListFiltered.ArticleListFiltered.List.Results(request, response, getCurrentWorkTaskId() ?? "", url)
    }, 1000)
}

// The new articlelist needs for the paging all informations from the response
export function getArticlesWithPageInfo(request: GetArticlesRequest): Promise<GetArticlesResponse> {
    const url = `${getServiceUrl()}/Articles`
    const authorization = getStoredAuthorization()
    const body = request
    const { search } = document.location

    return new Promise<GetArticlesResponse>((resolve, reject) =>
        ajax<GetArticlesResponse, GetArticlesRequest>({ url, body, authorization }).then((response) => {
            let mappedArticles: Article[] = []

            if (!response) {
                return resolve({ articles: [], pageIndex: request.pageIndex ?? 0, pageSize: request.pageSize ?? 0 })
            }

            mappedArticles = response.articles.map(Mappers.mapArticle).filter(notUndefinedOrNull) // do not reassign response.articles here!

            sendTmaInfo(url, request, response ? { ...response, articles: mappedArticles } : undefined, search)

            return resolve({ ...response, articles: mappedArticles })
        }, reject)
    )
}

// the Articlelist v1 needs only the raw articles not the pageinformations from the response
export function getArticles(request: GetArticlesRequest): Promise<Array<Article>> {
    return new Promise<Array<Article>>((resolve, reject) =>
        getArticlesWithPageInfo(request).then((response) => {
            return resolve(response.articles)
        }, reject)
    )
}

export function getArticlesByBarcodeScanner(query: string): Promise<Array<Article>> {
    const url = `${getServiceUrl()}/ArticlesByBarcodeScanner`
    const authorization = getStoredAuthorization()

    const request: GetArticlesByBarcodeScannerRequest = {
        query,
    }

    return ajax<GetArticlesResponse>({ url, body: request, authorization }).then((data) => {
        if (!data?.articles) {
            return []
        }
        return data.articles
    })
}

export function getArticlesBySupplierArticleNos(supplierArticles: Array<SupplierArticle>): Promise<Dictionary<Array<Article>>> {
    const url = `${getServiceUrl()}/ArticlesBySupplierArticleNos`
    const authorization = getStoredAuthorization()
    const body = { articles: supplierArticles }

    return new Promise<Dictionary<Array<Article>>>((resolve, reject) =>
        ajax({ url, body, authorization, method: "POST" }).then((data) => {
            const result: Dictionary<Array<Article>> = {}

            if (!data || !data.results) {
                resolve(result)
                return
            }

            Object.keys(data.results).forEach((traderArticleNo) => {
                let articles = data.results[traderArticleNo] || []
                articles = articles.map(Mappers.mapArticle).filter((x: Article | undefined) => !!x)
                result[traderArticleNo] = articles
            })

            resolve(result)
        }, reject)
    )
}

export const getArticlesBySupplierArticleNumber = createBufferedRequestFunction({
    callService: getArticlesBySupplierArticleNos,
    mapServiceResponseToResponse: (serviceResponse, request) => {
        return Object.values(serviceResponse).flatMap((articles) => {
            return articles.filter((article) => article.supplierArticleNo === request.supplierArticleNo && article.supplier.id === request.supplierId)
        })
    },
})

export function getArticlesByWholesalerArticleNosWithFilters(
    body: GetArticlesByWholesalerArticleNosRequest
): Promise<GetArticlesByWholesalerArticleNosResponse> {
    const url = `${getServiceUrl()}/ArticlesByWholesalerNos`
    const authorization = getStoredAuthorization()

    return ajax<GetArticlesByWholesalerArticleNosResponse>({ url, body, authorization }).then((data) => {
        const results: Dictionary<Array<Article>> = {}

        if (data?.results) {
            Object.keys(data.results).forEach((traderArticleNo) => {
                const articles = data.results[traderArticleNo] || []
                results[traderArticleNo] = articles.map(Mappers.mapArticle).filter(notUndefinedOrNull)
            })
        }

        sendTmaInfoWholesalerArticleNos(url, body, results)

        return {
            results,
            productGroupFilters: data?.productGroupFilters || [],
            dataSupplierFilters: data?.dataSupplierFilters || [],
        }
    })
}

export function getArticlesByWholesalerArticleNos(
    wholesalerArticleNumbers: Array<string>,
    modelId?: number,
    hideDuplicatesWithDifferentProductGroups?: boolean
): Promise<Dictionary<Array<Article>>> {
    return getArticlesByWholesalerArticleNosWithFilters({ wholesalerArticleNumbers, modelId, hideDuplicatesWithDifferentProductGroups }).then(
        (response) => response.results
    )
}

export const getArticlesByWholesalerArticleNumber = createBufferedRequestFunction({
    callService: getArticlesByWholesalerArticleNos,
    mapServiceResponseToResponse: (serviceResponse, request) => serviceResponse[request] || [],
})

export function getAccessoryList(request: GetAccessoryListRequest): Promise<Array<Article>> {
    const url = `${getServiceUrl()}/AccessoryList`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<Array<Article>>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => resolve(data && data.articles ? data.articles.map(Mappers.mapArticle) : []), reject)
    )
}

export function getPartsList(request: GetPartsListRequest): Promise<Models.GetPartsListResponse> {
    const url = `${getServiceUrl()}/PartsList`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<Models.GetPartsListResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => {
            const data = Mappers.mapPartsListResponse(response)
            if (data) {
                resolve(data)
            } else {
                reject()
            }
        }, reject)
    )
}

export function getArticleAttributeFilters(request: Models.GetArticleAttributeFiltersRequest): Promise<Array<ArticleAttribute>> {
    const url = `${getServiceUrl()}/Filters/ArticleAttributes`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise((resolve, reject) => ajax({ url, body, authorization }).then((data) => resolve(data?.articleAttributeFilters || []), reject))
}

export function getRelatedArticles(request: Models.GetRelatedArticlesRequest): Promise<Models.GetRelatedArticlesResponse> {
    const url = `${getServiceUrl()}/Related`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => {
            const data = Mappers.mapGetRelatedArticleResponse(response)
            if (data) {
                resolve(data)
            } else {
                reject()
            }
        }, reject)
    )
}

export function getArticleDetails(request: Models.DetailsRequest): Promise<Models.ArticleDetailsResponse> {
    const url = `${getServiceUrl()}/Details`
    const authorization = getStoredAuthorization()
    const body = { ...request }

    return new Promise<Models.ArticleDetailsResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => {
            const data = Mappers.mapArticleDetails(response)
            if (data) {
                resolve(data)
            } else {
                reject()
            }
        }, reject)
    )
}

export function getArticleImages(articleId: number, vehicleLinkageId?: number): Promise<ArticleImage[]> {
    const url = `${getServiceUrl()}/GetArticleImages`
    const authorization = getStoredAuthorization()
    const body = { articleId, vehicleLinkageId }

    return new Promise<ArticleImage[]>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => resolve((data ? data.images : null) || []), reject)
    )
}

export function getSearchFilters(request: GetSearchFiltersRequest): Promise<Array<Models.SearchFilter>> {
    const url = `${getServiceUrl()}/Filters/Search`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<Array<Models.SearchFilter>>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => resolve((data ? data.searchFilters : null) || []), reject)
    )
}

export function getArticlesByArticleNoWithOptVehicle(articleIdentifier: Array<ArticleIdentifier>) {
    const url = `${getServiceUrl()}/ArticlesByArticleNoWithOptVehicle`
    const authorization = getStoredAuthorization()
    const body = { articleIdentifier }

    return ajax<{ articles?: Array<Article> }>({ url, body, authorization, method: "POST" }).then(
        (data) => data?.articles?.map(Mappers.mapArticle).filter(notUndefinedOrNull) || []
    )
}

export const getArticlesByArticleNoWithOptVehicleBuffered = createBufferedRequestFunction<Array<ArticleIdentifier>, Array<Article>, Array<Article>>({
    callService: (requests) => getArticlesByArticleNoWithOptVehicle(requests.reduce((prev, cur) => [...prev, ...cur], [])),
    mapServiceResponseToResponse: (serviceResponse, request) => {
        const items = serviceResponse.filter((x) =>
            request.some(
                (y) => x.supplier.id === y.supplierId && x.supplierArticleNo === y.supplierArticleNo && x.productGroup.id === y.productGroupId
            )
        )
        return items.length ? items : undefined
    },
})

export function getSupplierInformationById(supplierId: number): Promise<Models.SupplierInfo> {
    const url = `${getServiceUrl()}/SupplierInformationById`
    const authorization = getStoredAuthorization()
    const body = { supplierId }

    return new Promise<Models.SupplierInfo>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => resolve(data && data.supplierInfo), reject)
    )
}

export function getArticleSearchHitInfo(request: GetArticleSearchHitInfoRequest): Promise<Array<ArticleSearchHitInfo>> {
    const url = `${getServiceUrl()}/GetArticleSearchHitInfo`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<Array<ArticleSearchHitInfo>>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => resolve(data && data.hitInfos), reject)
    )
}

export function getOeAttributes(body: GetOeAttributesRequest) {
    const url = `${getServiceUrl()}/GetOEArticleAttributes`
    const authorization = getStoredAuthorization()

    return ajax<GetOeAttributesResponse>({ url, body, authorization })
}

export function getOeInformation(request: OeInformationRequest) {
    const url = `${getServiceUrl()}/GetOeInformation`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax({ method: "POST", url, body, authorization }).then((data: GetOeInformationResponse) => {
        if (!data?.oeInformation || !Array.isArray(data?.oeInformation)) {
            throw new Error("No data")
        }

        data.oeInformation.forEach((oeInfo) => {
            oeInfo.oeArticles?.forEach((oeArticle) => {
                oeArticle.parts?.forEach((part) => {
                    // eslint-disable-next-line no-param-reassign
                    part.oeArticleOrigin = { module: OE.OeArticleModule.Undefined, provider: OE.OeArticleProvider.Topmotive }
                })
            })
        })

        return data.oeInformation
    })
}

function getIndustrialInformationMulti(requests: Array<IndustrialInformationRequest>) {
    const url = `${getServiceUrl()}/GetIndustrialInformation`
    const authorization = getStoredAuthorization()
    const body: GetIndustrialInformationRequest = { requests }

    return ajax<GetIndustrialInformationResponse>({ url, body, authorization, method: "POST" })
}

export const getIndustrialInformation = createBufferedRequestFunction({
    callService: getIndustrialInformationMulti,
    mapServiceResponseToResponse: (serviceResponse, request) =>
        serviceResponse?.information.find(
            (x) => x.request.supplierArticleNo === request.supplierArticleNo && x.request.supplierId === request.supplierId
        ),
})
