import { getStoredAuthorization, ajax } from "@tm/utils"
import { PrintModel } from "../../../models"
import { getBundleParams } from "../../../utils"

export function printMaintenance(data: PrintModel): Promise<{ pdfUrl: string }> {
    const url = `${getServiceUrl()}/fastservice/PrintMaintenancePlan`
    const authorization = getStoredAuthorization()
    const body = data

    return ajax({ url, body, authorization, method: "POST" }).then((res) => {
        return res
    })
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
