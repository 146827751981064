import { useQuery } from "react-query"
import * as Data from "../../.."
import { ShowWorkEstimationRequest } from "../../../model"
import { useWorkTaskBasketStore } from "../workflow/useWorkTaskBasketStore"

const KEY = "basket_useWorkEstimation"

export function useWorkEstimationData(workTaskId: string, request: ShowWorkEstimationRequest) {
    const workEstimationIndex = useWorkTaskBasketStore((store) => store.getWorkEstimationIndex(workTaskId))
    const QUERY_KEY = [KEY, workTaskId, workEstimationIndex]

    const { data, isLoading, isError } = useQuery(
        QUERY_KEY,
        () => {
            return Data.showWorkEstimation(request)
        },
        {
            enabled: !!request?.works?.length,
            staleTime: 10 * 60 * 1000, // 10 Minutes
        }
    )
    return { workEstimation: data, workEstimationLoading: isLoading, workEstimationQueryKey: QUERY_KEY, workEstimationWithError: isError }
}
