import { dataURLtoFile } from "../../../helpers"
import { WaveUploadRequestResponse } from "../../../models/image"
import { getBundleParams } from "../../../utils"
import { PrintImage } from "../../models"

export async function waveUpload(images: PrintImage[], uploadRequestId: string, partId: string, uploadKey: string, fileName: string) {
    for (let index = 0; index < images.length; index++) {
        const image = images[index]
        // eslint-disable-next-line no-await-in-loop
        await uploadSingleImage(image.image, index, uploadRequestId, partId, uploadKey, fileName)
    }

    return completeUploadRequest(uploadRequestId, uploadKey)
}

async function completeUploadRequest(uploadRequestId: string, uploadKey: string) {
    const completeUrl = `${getBundleParams().waveUploadUrl}/uploads/requests/${uploadRequestId}?key=${uploadKey}`
    const response = await fetch(completeUrl, { method: "POST" })
    if (response.ok) {
        return (await response.json()) as WaveUploadRequestResponse
    }
}

async function uploadSingleImage(image: string, fileIndex: number, uploadRequestId: string, partId: string, uploadKey: string, fileName: string) {
    const formData = new FormData()
    const imageBuffer = dataURLtoFile(image, `${fileName}.png`)

    if (imageBuffer) {
        formData.append("formFile", imageBuffer)

        const uploadUrl = `${getBundleParams().waveUploadUrl}/uploads/${uploadRequestId}/parts/${partId}/${fileIndex}?key=${uploadKey}`
        await fetch(uploadUrl, { method: "POST", body: formData })
    }
}
