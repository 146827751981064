import { useDisabledFunction } from "../../../../offers/src/hooks"
import { CatalogWidget, WidgetComponentConfigProps } from "./CatalogWidget"
import { DirectSearchWidget, DirectSearchWidgetConfigProps } from "./DirectSearchWidget"

export type WidgetProps =
    | ({ singleWidget: true; disabledFunction?: string | undefined } & DirectSearchWidgetConfigProps)
    | ({ singleWidget?: false | undefined; disabledFunction?: string | undefined } & WidgetComponentConfigProps)

export function Widget(props: WidgetProps) {
    const { disabledFunction, singleWidget, ...rest } = props

    const { isDisabled } = useDisabledFunction({ disabledFunction })

    if (isDisabled) {
        return null
    }

    if (singleWidget) {
        return <DirectSearchWidget {...rest} />
    }

    return <CatalogWidget {...props} />
}
