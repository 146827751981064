import { useRef } from "react"
import { useLocalization } from "@tm/localization"
import { Dialog } from "@tm/controls"
import { renderRoute, useExternalCatalogUrl } from "@tm/utils"
import { Icon, Typography, Card, Loader, Stack, Box, Button, useTheme, useMediaQuery } from "@tm/components"
import { useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { useHistory, useParams } from "react-router"
import { useTotalNumbers } from "../../data/hooks/useTotalNumbers"

type Props = {
    vouchersRoute: string
    target?: {
        url?: string
        externalSystemId?: number
    }
}

export default function OrderHistoryTotals({ vouchersRoute, target }: Props) {
    const { translateText } = useLocalization()
    const history = useHistory()
    const matchParams = useParams<{ workTaskId?: string }>()
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))

    const { userContext } = useUser() || {}
    const workTaskId = useWorkTask()?.workTaskId
    const { totalNumbers, totalsLoading } = useTotalNumbers(workTaskId)

    const { url, externalSystemId } = target || {}
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })
    const externalDialogRef = useRef<Dialog>(null)

    const numberOfPositions = totalNumbers?.numberOfPositions || 0
    const numberOfParts = totalNumbers?.numberOfParts || 0
    const disabled = !(numberOfPositions || numberOfParts || externalSystemId)

    function handleButtonClick() {
        const externalSystemParam = userContext?.externalModules
            ?.find((ext) => ext.id === externalSystemId)
            ?.parameter.find((param) => param.key.toLowerCase() === "target")

        // Modal
        if (externalCatalogUrl && externalSystemParam?.value === "1") {
            externalDialogRef.current?.show()
        }
        // New Window
        else if (externalCatalogUrl && externalSystemParam?.value === "2") {
            window.open(externalCatalogUrl, "_blank")
        }
        // Open Module
        else if (url) {
            history.push(
                renderRoute(url, {
                    ...matchParams,
                    workTaskId: matchParams.workTaskId,
                })
            )
        } else {
            history.push(renderRoute(vouchersRoute, { ...matchParams }))
        }
    }
    return (
        <>
            <Card sx={{ ml: 1 }}>
                <Stack direction={isBigScreen ? "row" : "column"} spacing={isBigScreen ? 4 : 1} px={1} py={isBigScreen ? 4 : 1} alignContent="center">
                    <Stack direction="row" spacing={2} alignContent="center">
                        <Icon name="order-history" />
                        <Stack>
                            <Typography variant="body3" whiteSpace="nowrap">
                                {translateText(814)}
                            </Typography>
                            {!externalSystemId && (
                                <Typography variant="body3">
                                    {numberOfPositions} {translateText(479)}, {numberOfParts} {translateText(90)}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                    <Box>
                        {totalsLoading ? (
                            <Loader />
                        ) : (
                            <Button
                                startIcon={<Icon name="arrow-right" />}
                                disabled={disabled}
                                onClick={handleButtonClick}
                                size={isBigScreen ? "medium" : "small"}
                            >
                                {translateText(815)}
                            </Button>
                        )}
                    </Box>
                </Stack>
            </Card>
            <Dialog className="form-confirmation" ref={externalDialogRef} text={translateText(143)} iconName="orders" layout="stretch">
                <iframe className="PDF-IFrame" src={externalCatalogUrl} title="External vouchers" />
            </Dialog>
        </>
    )
}
