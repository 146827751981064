import { Suspense, SyntheticEvent, useState } from "react"
import { WorkTaskInfo, useUser, useWorkTask } from "@tm/context-distribution"
import { Table } from "@tm/controls"
import { Box, Icon, Stack, Tab, TabPanel, Tabs, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CalculatedService, IMicros, PreparationWork, RepairTimeProvider, ServiceBase, SystemType } from "@tm/models"
import { connectComponent, useMicro, WithMicroProps } from "@tm/morpheus"
import { getCategoryOfWorkDescriptionTextId, mapPreparationWorksToAddRepairTimeListRequest } from "@tm/utils"
import { useParams } from "react-router"
import { RouteParams } from "../../business"
import AddToBasket from "../_shared/add-to-basket"
import { Actions } from "./business"
import { AssociatedRepairTimesState } from "./business/model"
import { useWorkTaskBasketState } from "../../../../basket/src/hooks/basketState/useWorkTaskBasketState"

type Props = WithMicroProps<IMicros> & {
    state: AssociatedRepairTimesState
    noSelectionTextId?: number
    provider: RepairTimeProvider
}

function AssociatedRepairTimesComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { provider, state, noSelectionTextId, workTask } = props
    const { vehicle, id } = workTask
    const { excludedRepairTimes, preRepairTimes, postRepairTimes, mainServiceSelected, repairTimeDivision, initialTab } = state
    const matchParmas = useParams<RouteParams>()
    const { renderMicro } = useMicro<IMicros>()
    const { number, translateText, translate } = useLocalization()
    const { userContext } = useUser() || {}
    const { costEstimation } = useWorkTaskBasketState(id)

    const [selectedTab, setSelectedTab] = useState<string>(initialTab)

    function handleTabChange(event: SyntheticEvent, newValue: any) {
        setSelectedTab(newValue)
    }

    function handleAddItemToBasket(item: PreparationWork) {
        if (vehicle && repairTimeDivision) {
            const work: PreparationWork = {
                ...item,
                description: item.description || translateText(getCategoryOfWorkDescriptionTextId(item.serviceType)),
                calculatedServices: item.calculatedServices?.map((calculatedService) => {
                    return {
                        ...calculatedService,
                        description:
                            calculatedService.description || translateText(getCategoryOfWorkDescriptionTextId(calculatedService.serviceType)),
                    }
                }),
            }
            const request = mapPreparationWorksToAddRepairTimeListRequest(id, provider, [work], vehicle.id, repairTimeDivision)
            costEstimation.actions.addRepairTimes(request)
        }
    }

    function handleRemoveRepairTime(workId: string) {
        costEstimation.actions.removeWorks([workId], true)
    }

    function renderNumber(work: CalculatedService | PreparationWork) {
        return (
            <Table.Cell>
                <Typography variant="body2">{work.repairTimeDisplayNo || work.repairTimeNo}</Typography>
            </Table.Cell>
        )
    }

    function renderDescription(work: CalculatedService | PreparationWork) {
        return (
            <Table.Cell>
                <Typography variant="body2">{work.description}</Typography>
            </Table.Cell>
        )
    }

    function renderTime(work: PreparationWork) {
        if (!work.repairTime) {
            return <Table.Cell />
        }
        return (
            <Table.Cell>
                <Typography variant="body2">{number(work.repairTime, 2)}</Typography>
            </Table.Cell>
        )
    }

    function renderActions(work: PreparationWork) {
        let content
        switch (userContext?.system.systemType) {
            case SystemType.Next:
                content = (
                    <AddToBasket<PreparationWork>
                        service={work}
                        provider={provider}
                        workTaskId={matchParmas.workTaskId}
                        onAddToBasket={handleAddItemToBasket}
                        onRemoveRepairTime={handleRemoveRepairTime}
                    />
                )
                break
            case SystemType.Redesign:
                const { position } = matchParmas

                content = renderMicro("standalone", "rd-add-rt-to-basket", {
                    provider,
                    repairTimeDivision,
                    position,
                    vehicle,
                    service: work,
                    isPreparationWork: true,
                })
                break
            case SystemType.Smartclient:
                content = renderMicro("standalone", "sc-add-rt-to-basket", { service: work as any })
                break
            default:
                break
        }

        return <Table.Cell>{content}</Table.Cell>
    }

    function renderTable(tableData: ServiceBase[]) {
        if (!excludedRepairTimes.length && !preRepairTimes.length && !postRepairTimes.length) {
            const textId = mainServiceSelected ? 804 : noSelectionTextId || 805

            return (
                <Stack direction="column" py={4} justifyContent="center" alignItems="center">
                    <Icon name="info" width="28px" height="28px" />
                    <Typography variant="h4">{translateText(textId)}</Typography>
                </Stack>
            )
        }

        const columns = [
            <Table.Column className="fancy-list__block--number" renderItemContent={renderNumber}>
                {translate(130)}
            </Table.Column>,
            <Table.Column className="fancy-list__block--description" renderItemContent={renderDescription}>
                {translate(131)}
            </Table.Column>,
            <Table.Column className="fancy-list__block--time" renderItemContent={renderTime}>
                {translate(repairTimeDivision === 1 ? 84 : 1550)}
            </Table.Column>,
            <Table.Column className="fancy-list__block--actions" renderItemContent={renderActions} />,
        ]

        return <Table data={tableData} columns={columns} />
    }

    return (
        <Box flex={1}>
            <Tabs value={selectedTab} onChange={handleTabChange} variant="standard">
                <Tab value="post" label={translateText(797)} disabled={!postRepairTimes.length} />
                <Tab value="excludes" label={translateText(799)} disabled={!excludedRepairTimes.length} />
                <Tab value="pre" label={translateText(798)} disabled={!preRepairTimes.length} />
            </Tabs>
            <TabPanel value="post" index={selectedTab} variant="standard">
                {renderTable(state.postRepairTimes)}
            </TabPanel>
            <TabPanel value="excludes" index={selectedTab} variant="standard">
                {renderTable(state.excludedRepairTimes)}
            </TabPanel>
            <TabPanel value="pre" index={selectedTab} variant="standard">
                {renderTable(state.preRepairTimes)}
            </TabPanel>
        </Box>
    )
}

function Wrapper(props: Props) {
    const workTask = useWorkTask()?.workTask
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <AssociatedRepairTimesComponent {...props} workTask={workTask} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
