import { AsyncAction } from "@tm/morpheus"
import { SearchTreeNode, SearchTreeProductGroup, TreeNode } from "@tm/models"
import { getBundleParams } from "../../../../utils"
import { Models, Repositories } from "../../../../data"
import { UniSearchWidgetState } from "./model"
import { TreeConfig } from "../../../widget/business"
import { BundleActionType } from "../../../../business"
import { getQueryHistory, removeQueryFromHistory } from "../../helper/queryhistory"

export * from "./model"

export type UniSearchWidgetActionType =
    | BundleActionType
    | { type: "NODES_LOADING" }
    | { type: "NODES_LOADED"; payload: SearchTreeNode[] }
    | { type: "INITIALIZE"; payload?: TreeConfig }
    | { type: "CHANGE_BREADCRUMBS"; payload: TreeNode[] }
    | { type: "SEARCH_TREE_LOADING" }
    | {
          type: "SEARCH_TREE_LOADED"
          payload: { nodes: TreeNode[]; topProductGroups: SearchTreeProductGroup[]; parentNode?: TreeNode }
      }
    | { type: "RESET_BREADCRUMBS" }

const DEFAULT_STATE: UniSearchWidgetState = {
    initialized: false,
    loading: false,
    nodes: [],
    queryHistory: [],
    tree: {
        showTip: false,
        treeId: 0,
        groups: [],
        loading: false,
        breadcrumbs: [],
    },
}

export function reduce(state = DEFAULT_STATE, action: UniSearchWidgetActionType): UniSearchWidgetState {
    switch (action.type) {
        case "INITIALIZE": {
            return {
                ...state,
                tree: {
                    ...state.tree,
                    ...((action.payload || {}) as any),
                    treeId: getBundleParams().universalTreeId || 0,
                },
            }
        }
        case "NODES_LOADING": {
            return {
                ...state,
                initialized: true,
                loading: true,
            }
        }
        case "NODES_LOADED": {
            return {
                ...state,
                loading: false,
                nodes: action.payload,
            }
        }
        case "QUERY_HISTORY_LOADED": {
            return {
                ...state,
                queryHistory: action.payload,
            }
        }
        case "SEARCH_TREE_LOADING": {
            return {
                ...state,
                tree: {
                    ...state.tree,
                    loading: true,
                },
            }
        }
        case "SEARCH_TREE_LOADED": {
            const { nodes, topProductGroups, parentNode } = action.payload

            const { tree } = state
            tree.loading = false

            if (parentNode) {
                // This also updates the groups array, since its the same object reference
                if (!parentNode.childNodes) {
                    parentNode.childNodes = nodes
                }
                if (!parentNode.topProductGroups) {
                    parentNode.topProductGroups = topProductGroups
                }
            }
            // Only do this for the first level (no parentNode)
            else {
                nodes.forEach((x) => (x.isGroup = true)) // Nodes from the first level are groups
                tree.groups = nodes
            }

            return {
                ...state,
                tree,
            }
        }

        case "CHANGE_BREADCRUMBS": {
            const { tree } = state

            let breadcrumbs = action.payload
            let lastBreadcrumb = breadcrumbs.last()

            if (lastBreadcrumb) {
                // If the last breadcrumb is no group and has no child nodes
                // it should not appear as breadcrumb
                if (!lastBreadcrumb.isGroup && !lastBreadcrumb.hasChildNodes) {
                    breadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1)
                } else {
                    // Only keep last breadcrumb if it really is the last one from the tree
                    lastBreadcrumb = undefined
                }
            }

            tree.breadcrumbs = breadcrumbs
            tree.selectedNode = lastBreadcrumb

            return {
                ...state,
                tree: { ...tree },
            }
        }

        case "RESET_BREADCRUMBS": {
            const { tree } = state

            return {
                ...state,
                tree: {
                    ...tree,
                    breadcrumbs: [],
                },
            }
        }
        default:
            break
    }

    return state
}

function initialize(tree?: TreeConfig): UniSearchWidgetActionType {
    return { type: "INITIALIZE", payload: tree }
}

function loadUniPartsNodes(
    treeId: number,
    parentNode?: TreeNode,
    loadSecondLevel?: boolean,
    onlyTopProductGroups?: boolean
): AsyncAction<UniSearchWidgetActionType, UniSearchWidgetState> {
    return (dispatch) => {
        const request: Models.UniParts.SearchTreeRequest = {
            treeId,
            nodeId: parentNode ? parentNode.id : undefined,
            loadSecondLevel,
            loadOnlyTopProductGroups: onlyTopProductGroups,
        }

        Repositories.UniParts.getSearchTree(request).then(
            (response) =>
                dispatch({
                    type: "SEARCH_TREE_LOADED",
                    payload: { nodes: response.nodes, topProductGroups: response.topProductGroups, parentNode },
                }),
            () => dispatch({ type: "SEARCH_TREE_LOADED", payload: { nodes: [], topProductGroups: [] } })
        )

        dispatch({ type: "SEARCH_TREE_LOADING" })
    }
}

function loadUniversalPartsTreeNodes(): AsyncAction<UniSearchWidgetActionType, UniSearchWidgetState> {
    return (dispatch, getState) => {
        const { tree } = getState()
        dispatch(loadUniPartsNodes(tree.treeId))
    }
}

function loadQueryHistory(): AsyncAction<UniSearchWidgetActionType> {
    return (dispatch) => {
        getQueryHistory().then((history) => dispatch({ type: "QUERY_HISTORY_LOADED", payload: history }))
    }
}

function removeFromQueryHistory(query: string, index: number): AsyncAction<UniSearchWidgetActionType> {
    return (dispatch) => {
        removeQueryFromHistory(query, index).then((history) => dispatch({ type: "QUERY_HISTORY_LOADED", payload: history }))
    }
}

function resetBreadcrumbs(): AsyncAction<UniSearchWidgetActionType> {
    return (dispatch) => {
        dispatch({ type: "RESET_BREADCRUMBS" })
    }
}

export type IActions = typeof Actions

export const Actions = {
    loadUniversalPartsTreeNodes,
    loadQueryHistory,
    removeFromQueryHistory,
    initialize,
    resetBreadcrumbs,
}
