import { useMemo } from "react"
import { Link } from "react-router-dom"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { TabWithImage, styled } from "@tm/components"
import { concat, useIsUrlAvailable } from "@tm/utils"

import { ErrorBoundary } from "./errorBoundary"
import { NavigationItem, DataType } from "../business"

type ComponentProps = {
    navItem?: NavigationItem
    isSelected: boolean
    dataType?: DataType
}

type TabInfo = {
    carInfoText: string
    carThumbnail: string
    customerInfoText: string
}

const StyledLink = styled(Link)(({ theme }) => ({
    margin: `0 ${theme.spacing(1)}`,
    textDecoration: "none",
}))

export function ItemWithCar(props: ComponentProps) {
    const { navItem, isSelected } = props
    const worktask = useWorkTask()
    const { translateText } = useLocalization()
    const { isAvailable } = useIsUrlAvailable(worktask?.workTask?.vehicle?.modelThumbnail)

    const tabInfo = useMemo<TabInfo>(() => {
        return {
            carThumbnail: isAvailable ? worktask?.workTask?.vehicle?.modelThumbnail! : worktask?.workTask?.vehicle?.modelSeriesImage || "",

            customerInfoText: concat(", ", worktask?.workTask?.customer?.customerNo, worktask?.workTask?.customer?.lastName),
            carInfoText: concat(
                " ",
                worktask?.workTask?.vehicle?.manufacturer,
                worktask?.workTask?.vehicle?.modelSeries,
                worktask?.workTask?.vehicle?.model
            ),
        }
    }, [worktask])

    return (
        <ErrorBoundary>
            <StyledLink to={navItem?.url || ""} key={navItem?.id || "empty"}>
                <TabWithImage
                    title={tabInfo.carInfoText}
                    emptyText={translateText(165)}
                    subtitle={tabInfo.customerInfoText}
                    img={tabInfo.carThumbnail}
                    isSelected={isSelected}
                />
            </StyledLink>
        </ErrorBoundary>
    )
}
