import { useEffect, useRef } from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Button, Demo, TabControl } from "@tm/controls"
import { Icon, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CategoryType, channel, ECounterType, ModuleOptionType, VehicleType } from "@tm/models"
import { TmaHelper, encodeUniqueId, renderRoute, uniqueId, useGetActivatableModules } from "@tm/utils"

const { Tab } = TabControl
export type NavigationLink = { title: string; route: string }

type Props = {
    activeCategory: CategoryType
    onChangeCategory(category: CategoryType): void
    disabledCategories: Array<CategoryType>
    availableCategories: Array<CategoryType>

    directSearchText: string
    universalSearchText: string
    tiresSearchText: string

    className?: string
    tabType?: "worktask" | "flat" | "scoped"

    showPartsIndicatorIcon?: boolean
    navigationLinks?: NavigationLink[]
}

// Use variables to have type check
const vehicleParts: CategoryType = "vehicleParts"
const universalParts: CategoryType = "universalParts"
const directSearch: CategoryType = "directSearch"
const tiresSearch: CategoryType = "tiresSearch"
const predictive: CategoryType = "predictive"
const eurotaxSearch: CategoryType = "eurotaxSearch"
const dtcSearch: CategoryType = "dtcSearch"

const getTabId = (id: CategoryType) => `parts__widget-tabs__${id}`

const NavigationContainer = styled("div")(({ theme }) => {
    return {
        display: "flex",
        columnGap: 3,
        alignItems: "flex-start",
        ".btn": {
            overflow: "hidden",
            borderWidth: 2,
            padding: "8px 8px 6px",
            backgroundColor: theme.overwrites?.components?.button?.highlight?.hover?.backgroundColor ?? theme.colors?.["highlight-hover"],
            "&:hover": {
                backgroundColor: theme.colors?.highlight,
            },
            ">.btn__content": {
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    }
})

export default function Navigation(props: Props) {
    const { workTask, workTaskId } = useWorkTask() ?? {}
    const { vehicleType } = workTask?.vehicle ?? {}
    const { translate, translateText } = useLocalization()
    const { navigationLinks = [] } = props
    const renderCount = useRef(0)

    useEffect(() => {
        return channel("WORKTASK", workTaskId).subscribe("PARTS/SHOW_PARTS_INDICATOR", () => {
            props.onChangeCategory(predictive)
        })
    }, [workTaskId])

    useEffect(() => {
        if (props?.activeCategory && renderCount.current >= 2) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            sendTmaGeneralCountEvent(props.activeCategory)
        } else {
            renderCount.current += 1
        }
    }, [props.activeCategory])

    const { disabledCategories, availableCategories, directSearchText, universalSearchText, tiresSearchText } = props

    const { userContext } = useUser() || {}
    const activatableModulesLoadable = useGetActivatableModules(userContext)
    const isGTIDemo = activatableModulesLoadable.response?.moduleGroups
        ?.find((x) => x.id === "gpi")
        ?.modules?.find((x) => x.id.toLocaleLowerCase() === "eurotax")
        ?.moduleOptions.find((moduleOption) => moduleOption.type === ModuleOptionType.Demo)?.active

    const handleGetTmaEvent = (identifier: string) => {
        switch (identifier) {
            case "vehicleParts":
                return ECounterType.PassengerCarPartsCatalog
            case "universalParts":
                return ECounterType.UniParts
            case "directSearch":
                return ECounterType.ArticleDirectSearch
            case "predictive":
                return ECounterType.PartsIndicator
            case "eurotaxSearch":
                return ECounterType.PassengerCarGpi
            case "dtcSearch":
                return ECounterType.FastDTC
            default:
                return ECounterType.Undefined
        }
    }

    const sendTmaGeneralCountEvent = (category: CategoryType) => {
        const identifier = handleGetTmaEvent(category)
        if (identifier !== ECounterType.Undefined) {
            TmaHelper.GeneralCountEvent.Call(identifier)
        }
    }

    return (
        <div className={`tk-parts category-selection ${props.className || ""}`}>
            <NavigationContainer>
                <TabControl selectedTabIdentifier={props.activeCategory} onTabSelect={props.onChangeCategory} type={props.tabType}>
                    {availableCategories.indexOf(vehicleParts) >= 0 && (
                        <Tab identifier={vehicleParts} htmlId={getTabId(vehicleParts)} disabled={disabledCategories.indexOf(vehicleParts) !== -1}>
                            {translate(449)}
                        </Tab>
                    )}
                    {availableCategories.indexOf(directSearch) >= 0 && (
                        <Tab identifier={directSearch} htmlId={getTabId(directSearch)} disabled={disabledCategories.indexOf(directSearch) !== -1}>
                            {directSearchText}
                        </Tab>
                    )}
                    {availableCategories.indexOf(universalParts) >= 0 && (
                        <Tab
                            identifier={universalParts}
                            htmlId={getTabId(universalParts)}
                            disabled={disabledCategories.indexOf(universalParts) !== -1}
                        >
                            {universalSearchText}
                        </Tab>
                    )}
                    {availableCategories.indexOf(tiresSearch) >= 0 && (
                        <Tab identifier={tiresSearch} htmlId={getTabId(tiresSearch)} disabled={disabledCategories.indexOf(tiresSearch) !== -1}>
                            {tiresSearchText}
                        </Tab>
                    )}
                    {availableCategories.indexOf(eurotaxSearch) >= 0 && (
                        <StyledGtiTab
                            identifier={eurotaxSearch}
                            htmlId={getTabId(eurotaxSearch)}
                            disabled={disabledCategories.includes(eurotaxSearch)}
                        >
                            {isGTIDemo && <StyledDemo displayMode="edge" />}
                            {translateText(571)}
                        </StyledGtiTab>
                    )}
                    {availableCategories.indexOf(predictive) >= 0 && vehicleType === VehicleType.PassengerCar && (
                        <Tab
                            identifier={predictive}
                            className="predictive-nodes-tab"
                            htmlId={getTabId(predictive)}
                            disabled={disabledCategories.indexOf(predictive) !== -1}
                        >
                            {props.showPartsIndicatorIcon && <Icon name="partsindicator" />}
                            {translate(1630)}
                        </Tab>
                    )}
                    {availableCategories.indexOf(dtcSearch) >= 0 && (
                        <Tab identifier={dtcSearch} className="none" htmlId={getTabId(dtcSearch)} disabled={disabledCategories.includes(dtcSearch)}>
                            {translateText(12926)}
                        </Tab>
                    )}
                </TabControl>
                {navigationLinks.map((link) => (
                    <Button key={link.title} linkTo={renderRoute(link.route, { workTaskId: encodeUniqueId(workTaskId ?? uniqueId()) })}>
                        {link.title}
                    </Button>
                ))}
            </NavigationContainer>
        </div>
    )
}

const StyledDemo = styled(Demo)({
    position: "absolute",
    top: "0",
    right: "0",
})

const StyledGtiTab = styled(Tab)({
    padding: "0 1em",
    position: "relative",
})
