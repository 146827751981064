import { useStyle, useUser } from "@tm/context-distribution";
import { Toolbar, Switch } from "@tm/controls";
import { useLocalization } from "@tm/localization";
import { classes } from "@tm/utils";
import { useMemo } from "react";

const PurchasePriceSwitch = () => {
    const user = useUser()
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    if (user?.userContext?.parameter.purchasePricesDisabled)
        return null

    const handleChange = (showPurchasePrice: boolean) => {
        if (user?.userSettings?.articleListSettings)
            user.setUserSetting("SHOW_PURCHASE_PRICE", showPurchasePrice)
    }

    const canShowSwitch = (): boolean => {
        const externalModules = user?.userContext?.externalModules

        let isInfoEnabled = false;
        if (externalModules) {
            externalModules.map(externalModule => {
                if (externalModule.isInfoEnabled) {
                    isInfoEnabled = true
                    return
                }
            })
        }
        return isInfoEnabled
    }

    return (
        <>
            {canShowSwitch() && <Toolbar className={classes("tk-parts purchase-price-switch", style.showPurchasePriceToolbar)} title={translateText(1132)}>
                    <Switch
                        status={user?.userSettings?.showPurchasePrice}
                        onChange={handleChange}
                    />
                </Toolbar>}
        </>
    )
}

function getStyle() {
	return useStyle({
        showPurchasePriceToolbar: {
            $nest: {
                "&:before": {
                    borderLeft: "transparent"
                }
            }
        }
    })(PurchasePriceSwitch)
}

export default PurchasePriceSwitch
