import { mapArticle } from "./list"
import { ArticleDetailsResponse, AdditionalArticleInfos, PdfDocumentReference, AdditionalInfoList } from "../model"

export function mapArticleDetails(data: any): ArticleDetailsResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        article: mapArticle(data.article),
        images: data.images || [],
        partsListMainArticles: data.partsListMainArticles ? data.partsListMainArticles.map(mapArticle) : [],
        accessoryListMainArticles: data.accessoryListMainArticles ? data.accessoryListMainArticles.map(mapArticle) : [],
        additionalInfos: mapAdditionalArticleInfo(data.additionalInfos),
        documents: data.documents ? data.documents.map(mapPdfDocumentReference) : [],
        externalDocuments: data.externalDocuments || [],
    }
}

function mapAdditionalArticleInfo(data: any): AdditionalArticleInfos | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        supplierInformations: data.supplierInformations ? data.supplierInformations.map(mapAdditionalInfoList) : [],
        traderInformations: data.traderInformations ? data.traderInformations.map(mapAdditionalInfoList) : [],
    }
}

function mapPdfDocumentReference(data: any): PdfDocumentReference | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        documentUrl: data.documentUrl ? unescape(data.documentUrl) : undefined,
    }
}

function mapAdditionalInfoList(data: any): AdditionalInfoList | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        informations: data.informations || [],
    }
}
