import { useEffect, useMemo, useState } from "react"
import { renderRoute } from "@tm/utils"
import { useHistory, useParams } from "react-router"
import { useCompilations } from "../../data/hooks/useCompilations"
import CompilationsListComponent from "./components/list"
import CompilationDetailsComponent from "./components/details"
import { NavigationComponent } from "./components/navigation"
import { getComponentStyles } from "./styles"
import { getBundleParams } from "../../utils"

type RouteProps = {
    compilationId?: string
    workTaskId: string
}

export default function CompilationsComponent() {
    const classNames = useMemo(() => getComponentStyles(), [])
    const [query, setQuery] = useState("")
    const { compilations, isLoading, error } = useCompilations(query || undefined)
    const [showCreator, setShowCreator] = useState(false)
    const matchParams = useParams<RouteProps>()
    const history = useHistory()
    const { compilationDetailsRoute, compilationsRoute } = getBundleParams()

    useEffect(() => {
        if (compilations && compilations.length === 0 && matchParams.compilationId) {
            openCompilations()
        }
    }, [compilations, matchParams.compilationId])

    const openCompilationDetails = (compilationId: string) => {
        history.push(renderRoute(compilationDetailsRoute, { ...matchParams, compilationId }))
    }

    const openCompilations = () => {
        history.push(renderRoute(compilationsRoute, matchParams))
    }

    const { compilationId } = matchParams

    return (
        <div className={classNames.main}>
            <NavigationComponent
                compilationId={compilationId}
                showCreator={showCreator}
                activeQuery={query}
                onShowCreator={() => setShowCreator(true)}
                searchCompilation={setQuery}
                openCompilationDetails={openCompilationDetails}
                openCompilations={openCompilations}
            />
            <div className={classNames.content}>
                <CompilationsListComponent
                    compilations={compilations}
                    loading={isLoading}
                    error={error as any}
                    showCreator={showCreator}
                    compact={!!compilationId}
                    onHideCreator={() => setShowCreator(false)}
                    openCompilationDetails={openCompilationDetails}
                    openCompilationList={openCompilations}
                />

                {compilationId && <CompilationDetailsComponent compilationId={compilationId} encodedWorkTaskId={matchParams.workTaskId} />}
            </div>
        </div>
    )
}
