import { percent } from "csx"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useUser} from "@tm/context-distribution"
import { Button, Card, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RimErpInfo, RimItem, SizeInfo } from "../../../data/model"
import { MainState } from "../../main"
import { styled } from "@tm/components"
import StockTable from "./stockTable"
import RimImage  from "./rimImage"
import SizeButton from "./sizeButton"
import { getBundleParams } from "../../../utils"

type Props = {
    className?: string
    item: RimItem
    onZoom: (item: RimItem) => void
    onSelect: (item: RimItem, sizeInfo?: SizeInfo) => void
}

const selectedSelector = createSelector((s: MainState) =>
    ({ selectedRimItem: s.wheelsList.base.selectedRimItem, selectedSize: s.wheelsList.base.selectedSize, availabilityFilter: s.wheelsList.base.availabilityFilter }),
    (_: unknown, item: RimItem) => item,
    ({ selectedRimItem, selectedSize, availabilityFilter }, item) => ({ isActive: selectedRimItem?.idRimDesign == item.idRimDesign, size: selectedSize, availabilityFilter: availabilityFilter }))

export default function RimArticleItem({ item, onSelect, onZoom }: Props) {
    const user = useUser()
    const { translateText } = useLocalization()
    const { isActive, size, availabilityFilter } = useSelector((s: MainState) => selectedSelector(s, item))
    const showAvailability = getBundleParams().priceAvailabilityInformation ? !user?.userSettings?.hideWheelsAvailability && item.rimErpInformations.length > 0 : false
    
    const sizeInfo = useMemo(() => 
        showAvailability && availabilityFilter ? item.availableDiameterSizes : item.sizeInfo
    , [showAvailability, availabilityFilter])

    const handleZoom = () => {
        onZoom(item)
    }

    const handleSelectItem = () => {
        onSelect(item)
    }

    const handleSelectSize = (info: RimErpInfo) => {
        const sizeInfo = item.sizeInfo.find(size => size.size == info.diameterSize)
        onSelect(item, sizeInfo)
    }

    return (
        <StyledCard isActive={isActive}>
            <Wrapper showAvailability={showAvailability}>
                <ZoomButton layout={["ghost"]} icon="no-results" onClick={handleZoom} />
                <WrapperTop>
                    <RimImage url={item.perspectivePicture} onClick={handleSelectItem}/>
                    <RimSizes>
                        {sizeInfo?.map((sizeInfo, index) => (
                            <SizeButton key={index} isActive={isActive} item={item} onSelect={onSelect} size={size} sizeInfo={sizeInfo} />
                        ))}
                    </RimSizes>
                </WrapperTop>
                <StyledTexts>
                    <Text modifiers={["block", "strong"]}>
                        <Text modifiers={["highlight"]}>{item.suplierName}</Text> - {item.modelCode}
                    </Text>
                    <Text modifiers={["block"]} size="s" >{translateText(944) + ": " + item.description}</Text>
                </StyledTexts>
                {showAvailability &&
                    <StockTable
                        informations={item.rimErpInformations}
                        onSelect={handleSelectSize}
                    />}
            </Wrapper>
        </StyledCard >
    )
}

const StyledCard = styled(Card)<{ isActive: boolean }>(({ theme, isActive }) => ({
    padding: theme.margin?.s,
    display: "flex",
    ".card__inner": {
        flex: 1,
        "&:hover": {
            borderColor: `rgba(${theme.colors?.primary}, 0.7)`
        },
        ...(isActive ? {
            borderColor: theme.colors?.primary
        } : {})
    },
    }
))

const Wrapper = styled("div")<{showAvailability: boolean}>(({ showAvailability}) => ({
    display: "flex",
    flexDirection: "column",
    ...(!showAvailability && { height: percent(100) })
}))

const ZoomButton = styled(Button)(({ theme }) => ({
    position: "absolute",
    top: theme.margin?.m,
    left: theme.margin?.m
}))

const WrapperTop = styled("div")(({ theme }) => ({
    display: "flex",
    paddingRight: theme.margin?.m,
    flex: 1,
}))

const RimSizes = styled("div")(({ theme }) => ({
    display: 'flex',
    justifyContent: "center",
    marginTop: theme.margin?.m,
    flexDirection: "column",
}))

const StyledTexts = styled("div")(({ theme }) => ({
    alignSelf: 'stretch',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.margin?.m
}))