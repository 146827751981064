import { useSelectedVehicleLookup, useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Button, DateField, DropdownField, Headline, LicensePlateField, PanelSection, SuggestionTextField, Switch, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CarModelDetails, MileType, RegistrationNoDetailsSimple, RegistrationNoType, TelematicsProvider, Vehicle } from "@tm/models"
import { getVehicleRegistrationNoLabelTextIds } from "@tm/utils"

import * as React from "react"

import { Typography } from "@tm/components"
import { VehicleDataProgress } from "../../_shared/vehicle-data-progress"
import { getBundleParams } from "../../../utils"
import { getVinSuggestions } from "../../../data/repositories/vin-picker"
import { isSpecialRegNoType, onlyDigits, uppercase } from "../../../helpers"
import { constructionYearToDate } from "../../../helpers/construction-year"
import { useServiceBookUrl } from "../business/hooks"

import { VinInputField } from "../../_shared/VinInput"

type Props = {
    vehicle: Vehicle
    modelDetails: CarModelDetails
    regNoDetails?: RegistrationNoDetailsSimple
    regNoType?: RegistrationNoType
    onChange(vehicle: Vehicle): void
    showServiceBook?: boolean
    onShowPartsIndicator?(): void
}

export default function PersonalDataComponent(props: Props) {
    const { modelDetails, vehicle, onChange } = props

    const { translateText, translate } = useLocalization()
    const serviceBookUrl = useServiceBookUrl(props.showServiceBook ? vehicle?.tecDocManufacturerId : undefined)

    const { selectedVehicleLookup } = useSelectedVehicleLookup()

    const handleGetRegistrationNoSuggestions = (): Array<string> => {
        return modelDetails?.registrationNos || (vehicle?.registrationNo ? [vehicle.registrationNo] : [])
    }

    const handleGetEngineCodeSuggestions = (): Array<string> => {
        if (!modelDetails && !props.regNoDetails) {
            return []
        }

        return props.regNoDetails?.engineCodes?.length ? props.regNoDetails.engineCodes : modelDetails.engineCodes || []
    }

    const handleGetVinSuggestions = (query: string): Promise<Array<string>> => {
        if (!vehicle?.tecDocTypeId) {
            return Promise.resolve([])
        }

        return getVinSuggestions(query, vehicle.tecDocTypeId)
    }

    const handleChangeVin = (vin: string) => {
        onChange({ ...vehicle, vin })
    }

    const handleChangeDate = (date: Date | null, key: keyof Vehicle) => {
        onChange({
            ...vehicle,
            [key]: date ?? undefined, // Fix "isDirty" being true due to "date" being "null" and not "undefined"
        })
    }

    const handleChangeInitialRegistration = (date: Date | null) => {
        handleChangeDate(date, "initialRegistration")
    }

    const handleChangeNextServiceDate = (date: Date | null) => {
        handleChangeDate(date, "nextServiceDate")
    }

    const handleChangeNextGeneralInspection = (date: Date | null) => {
        handleChangeDate(date, "nextGeneralInspection")
    }

    const handleChangeLastGeneralInspection = (date: Date | null) => {
        handleChangeDate(date, "lastGeneralInspection")
    }

    const handleChangeRegistrationNo = (registrationNo: string) => {
        const vehicle = { ...props.vehicle, registrationNo }

        if (registrationNo && !isSpecialRegNoType(vehicle.registrationTypeId)) {
            vehicle.registrationTypeId = props.regNoType
        }

        onChange(vehicle)
    }

    const handleChangeMileAge = (mileAge: string) => {
        let { vehicle } = props
        const parsedMileAge = parseInt(mileAge)

        vehicle = {
            ...vehicle,
            mileAge: !isNaN(parsedMileAge) ? parsedMileAge : undefined,
            mileType: vehicle.mileType || (!isNaN(parsedMileAge) ? MileType.Kilometer : undefined),
        }

        // Fix "isDirty" being true due to "mileAge" being "undefined" and not missing in vehicle
        if (vehicle.mileAge === undefined) {
            delete vehicle.mileAge
        }

        // Fix "isDirty" being true due to "mileType" being "undefined" and not missing in vehicle
        if (vehicle.mileType === undefined) {
            delete vehicle.mileType
        }

        onChange(vehicle)
    }

    const enableLonglife = (e: React.MouseEvent) => {
        e?.stopPropagation()
        e?.preventDefault()
        handleChangeLonglife(true)
    }

    const handleChangeLonglife = (longlife: boolean) => {
        onChange({ ...vehicle, longlife })
    }

    const handleChangeTelematicsProvider = (value: string) => {
        onChange({ ...vehicle, telematicsProviderId: parseInt(value) || undefined })
    }

    const renderRegNoField = () => {
        // fix this should be one getvehicleregnolabtexids aufruf sein
        let itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(props.regNoType)
        let value = vehicle.registrationNo
        if (!itemLabelTextIds?.label) {
            itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(selectedVehicleLookup.lookupTypeId)
            value = ""
        }

        if (!itemLabelTextIds?.label) {
            return
        }

        return (
            <SuggestionTextField
                floatingLabel
                label={translateText(itemLabelTextIds.label)}
                value={value}
                getSuggestionData={handleGetRegistrationNoSuggestions}
                onChange={handleChangeRegistrationNo}
                onChangeConfirm={handleChangeRegistrationNo}
                formatter={uppercase}
            />
        )
    }

    const renderLonglifeSwitch = () => {
        const disabled = vehicle.longlife === undefined

        let className = "switch-container"
        if (disabled) {
            className += " switch--no-status"
        }

        return (
            <div className={className} onClick={disabled ? enableLonglife : undefined}>
                <Switch alignLabel="left" disabled={disabled} status={vehicle.longlife} label="Longlife" onChange={handleChangeLonglife} />
            </div>
        )
    }

    const constructionYearFrom = modelDetails.constructionYearFrom ? constructionYearToDate(modelDetails.constructionYearFrom) : undefined
    const { carmunication } = getBundleParams()
    const engineCodesAvailable = (modelDetails.engineCodes || []).length > 0
    const { plateCode: shortCountryCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)

    return (
        <PanelSection className="personal">
            <div className="header">
                <Typography variant="h3">{translateText(322)}</Typography>
            </div>
            <div className="content">
                <div className="data">
                    <div className="col">
                        <LicensePlateField
                            shortCountryCode={shortCountryCode}
                            showCountryCode
                            size="xl"
                            model={vehicle}
                            path={["plateId"]}
                            onChange={onChange}
                            onChangeConfirm={onChange}
                            maxLength={17}
                        />
                    </div>
                    <div className="col">
                        <DateField
                            floatingLabel
                            label={translateText(124)}
                            value={vehicle.initialRegistration}
                            minDate={constructionYearFrom}
                            maxDate={new Date()}
                            openToDate={vehicle.initialRegistration ?? constructionYearFrom}
                            useUtc
                            onChange={handleChangeInitialRegistration}
                        />
                    </div>
                    <div className="col">
                        <TextField
                            floatingLabel
                            label={translateText(125)}
                            value={vehicle.mileAge?.toString()}
                            onChange={handleChangeMileAge}
                            maxLength={7}
                            formatter={onlyDigits} // ML 30.09.2020 - Removed dot formatting because it is shitty to edit the mileage then
                        />
                    </div>
                    <div className="col">
                        <VinInputField
                            showEdit
                            vehicleData={vehicle}
                            textFieldProps={{
                                floatingLabel: true,
                                label: translateText(101),
                            }}
                            getVinSuggestions={handleGetVinSuggestions}
                            onVinChange={handleChangeVin}
                            onVinChangeConfirm={handleChangeVin}
                        />
                    </div>
                    <div className="col">
                        <SuggestionTextField
                            floatingLabel
                            label={translateText(105)}
                            model={vehicle}
                            path={["engineCode"]}
                            getSuggestionData={handleGetEngineCodeSuggestions}
                            onChange={onChange}
                            onChangeConfirm={onChange}
                            formatter={(value) => value.toUpperCase()}
                        />
                    </div>
                    <div className="col">{renderRegNoField()}</div>
                    <div className="col">
                        <DateField
                            floatingLabel
                            label={translateText(896)}
                            value={vehicle.lastGeneralInspection}
                            onChange={handleChangeLastGeneralInspection}
                            maxDate={new Date()}
                            useUtc
                        />
                    </div>
                    <div className="col">
                        <DateField
                            floatingLabel
                            label={translateText(897)}
                            value={vehicle.nextGeneralInspection}
                            onChange={handleChangeNextGeneralInspection}
                            useUtc
                        />
                    </div>
                    <div className="col">
                        <DateField
                            floatingLabel
                            label={translateText(574)}
                            value={vehicle.nextServiceDate}
                            onChange={handleChangeNextServiceDate}
                            minDate={new Date()}
                            useUtc
                        />
                    </div>
                    <div className="col">
                        {carmunication && (
                            <DropdownField
                                floatingLabel
                                label={`Telematics ${translateText(278)}`}
                                value={vehicle.telematicsProviderId}
                                items={{ [TelematicsProvider.Geotab]: "Geotab" }}
                                onChange={handleChangeTelematicsProvider}
                            />
                        )}
                    </div>
                    <div className="col">
                        {carmunication && (
                            <TextField
                                floatingLabel
                                label={`Telematics ${translateText(1736)}`}
                                model={vehicle}
                                path={["telematicsRefId"]}
                                onChange={onChange}
                                onChangeConfirm={onChange}
                            />
                        )}
                    </div>
                    <div className="col">{renderLonglifeSwitch()}</div>
                </div>
                <div className="column-right">
                    {serviceBookUrl && (
                        <div className="servicebook">
                            <Headline size="xs">{translate(1856)}:</Headline>
                            <Button target="_blank" linkTo={serviceBookUrl}>
                                {translate(1857)}
                            </Button>
                        </div>
                    )}
                    <VehicleDataProgress vehicle={vehicle} onClick={props.onShowPartsIndicator} hasEngineCodes={engineCodesAvailable} />
                </div>
            </div>
        </PanelSection>
    )
}
