import { useLocalization } from "@tm/localization"
import { OrderOptions, WarehouseDispatchType } from "@tm/models"
import { SyntheticEvent, useMemo } from "react"
import { formatDate, getTitleTypeTextId } from "@tm/utils"
import { Toolbar } from "@tm/controls"
import { Box, Button, Icon, Typography } from "@tm/components"
import { EMPTY_OPTION, getAddressLabels } from "../../../../helpers"
import { getBundleParams } from "../../../../utils"
import { LoaderSmall, StackRow } from "../../../StyledComponents"

type Props = {
    options?: OrderOptions
    showLoader?: boolean
    wasSaved?: boolean
    optionsExpanded: boolean
    onToggle(event: SyntheticEvent): void
}

export default function Summary({ options, optionsExpanded, showLoader, wasSaved, onToggle }: Props) {
    const { translateText } = useLocalization()
    const { hideOrderOptionsContactData } = getBundleParams()

    const renderSaveInformation = useMemo(() => {
        if (!optionsExpanded) {
            return null
        }

        return (
            <StackRow alignContent="center" spacing={2}>
                {showLoader && <LoaderSmall />}
                <Box
                    visibility={`${wasSaved ? "visible" : "hidden"}`}
                    sx={{ animation: `${wasSaved ? "" : "fadeOut 1s linear"}`, transition: `${wasSaved ? "" : "visibility 1s linear"}` }}
                >
                    <Icon name="check" sx={{ marginRight: "5px", color: "green" }} height={15} width={15} />
                    <Typography color="#48ad4d" variant="label">
                        {translateText(360)}
                    </Typography>
                </Box>
            </StackRow>
        )
    }, [optionsExpanded, wasSaved, showLoader, translateText])

    if (!options) {
        return null
    }

    const paymentOption = options.paymentOptions?.paymentOptions.find((payment) => payment.isSelected)
    const payment = paymentOption?.description || EMPTY_OPTION

    const billingMode = options.billingModes?.billingModes.find((billing) => billing.isSelected)
    const billing = billingMode?.description || EMPTY_OPTION

    const shipmentMode = options.shipmentModes?.shipmentModes.find((shipment) => shipment.isSelected)
    const shipment = shipmentMode?.description || EMPTY_OPTION
    // const tour = shipmentMode?.tours?.tours.find(tour => tour.isSelected)
    // shipment += tour?.tourStart ? " " + formatDate(tour.tourStart, "g") : ""

    let distribution = EMPTY_OPTION
    const delivery = options.deliveryDistribution?.deliveryDistributionModes.find((distMode) => distMode.isSelected)
    distribution = delivery?.description || distribution

    const shipmentDispatchTypeId = shipmentMode?.dispatchType || undefined
    distribution = shipmentDispatchTypeId !== undefined && shipmentDispatchTypeId !== WarehouseDispatchType.Delivery ? "-" : distribution

    let deliveryTourDate = EMPTY_OPTION
    if (options.deliveryDate) {
        deliveryTourDate = formatDate(options.deliveryDate, "d")

        const tour = options.tours?.tours.find((t) => t.isSelected)
        if (tour?.tourStart) {
            deliveryTourDate = formatDate(tour.tourStart, "g")
        }
    }

    let orderprocessing = EMPTY_OPTION
    const orderProcessingOption = options.orderProcessingOptions?.orderProcessingOptions.find((oP) => oP.isSelected)
    if (orderProcessingOption) {
        orderprocessing = translateText(orderProcessingOption.description || orderProcessingOption.id === "1" ? 2003 : 2004)
    }

    let backorder = EMPTY_OPTION
    const backorderOption = options.backorderOptions?.backorderOptions.find((option) => option.isSelected)
    backorder = backorderOption?.description || backorder

    let deliveryAddress = [EMPTY_OPTION]
    const deliveryAddressOption = options.deliveryAddresses?.addresses.find((address) => address.isSelected)
    let titleText = ""
    if (deliveryAddressOption?.titleType) {
        const textId = getTitleTypeTextId(deliveryAddressOption.titleType)
        if (textId) {
            titleText = translateText(textId)
        }
    }

    if (deliveryAddressOption) {
        deliveryAddress = getAddressLabels(deliveryAddressOption, true, titleText, hideOrderOptionsContactData)
        if (deliveryAddress[0] === EMPTY_OPTION) {
            deliveryAddress = [deliveryAddressOption.description]
        }
    }

    const modifiers: Array<"no-border"> = ["no-border"]
    return (
        <>
            {renderSaveInformation}
            <Box display="flex" visibility={optionsExpanded ? "hidden" : undefined} flex="1">
                {options.paymentOptions && (
                    <Toolbar title={options.paymentOptions.description || translateText(440)} modifiers={modifiers}>
                        {payment}
                    </Toolbar>
                )}
                {options.shipmentModes && (
                    <Toolbar title={options.shipmentModes.description || translateText(1346)} modifiers={modifiers}>
                        {shipment}
                    </Toolbar>
                )}
                {options.billingModes && (
                    <Toolbar title={options.billingModes.description || translateText(1830)} modifiers={modifiers}>
                        {billing}
                    </Toolbar>
                )}
                {options.orderProcessingOptions && (
                    <Toolbar title={options.orderProcessingOptions.description || translateText(1199)} modifiers={modifiers}>
                        {orderprocessing}
                    </Toolbar>
                )}
                {options.deliveryDistribution && (
                    <Toolbar title={options.deliveryDistribution.description || translateText(1347)} modifiers={modifiers}>
                        {distribution}
                    </Toolbar>
                )}
                {options.backorderOptions && (
                    <Toolbar title={options.backorderOptions.description || translateText(1143)} modifiers={modifiers}>
                        {backorder}
                    </Toolbar>
                )}
                {options.deliveryDate && (
                    <Toolbar title={translateText(96)} modifiers={modifiers}>
                        {deliveryTourDate}
                    </Toolbar>
                )}
                {options.hasCustomerOrderNumber && (
                    <Toolbar title={translateText(1249)} modifiers={modifiers}>
                        {options.customerOrderNumber || "-"}
                    </Toolbar>
                )}
                {options.hasOrderComment && (
                    <Toolbar title={translateText(1248)} modifiers={modifiers}>
                        {options.orderComment || "-"}
                    </Toolbar>
                )}
                {options.deliveryAddresses && (
                    <Toolbar title={translateText(13221)} modifiers={modifiers}>
                        {deliveryAddress}
                    </Toolbar>
                )}
                <Button onClick={onToggle} title={translateText(122)} variant="text" startIcon={<Icon name="edit_change" />} />
            </Box>
        </>
    )
}
