import { Suspense } from "react"
import { Box, Loader, styled } from "@tm/components"
import { getComponentFromBundle } from "@tm/utils"
import { WidgetSizes } from "@tm/controls"

import { TyresSearchWidgetCompactBundle } from "../../../../../../tyres/src"
import { WidgetStartBundle as CrmWidgetStartBundle } from "../../../../../../crm/src"
import { SimpleSearchWidgetBundle } from "../../../../../../parts/src"
import WidgetCreditComponent from "../../../../../../cis/src/components/widget-credit"

import { MultiWidgets } from "../../components/MultiWidgets"
import ExternalCatalogsWidget from "../../../external-catalogs/component"
import ExternalIframe from "../../../external-catalogs-iframe"

import { useStartTemplateFive } from "./hooks/useStartTemplateFive"
import { startTemplateFiveChannel } from "./channel"
import { AllWidgetKeys, Overrides } from "./models"

const TyresSearchWidgetCompact = getComponentFromBundle(TyresSearchWidgetCompactBundle, startTemplateFiveChannel)
const ExternalIframeWidget = getComponentFromBundle(ExternalIframe, startTemplateFiveChannel)
const CrmWidgetStart = getComponentFromBundle(CrmWidgetStartBundle, startTemplateFiveChannel)
const SimpleSearchWidget = getComponentFromBundle(SimpleSearchWidgetBundle, startTemplateFiveChannel)
const CisCreditWidget = getComponentFromBundle(WidgetCreditComponent, startTemplateFiveChannel)

type Props = {
    // this is for customizing the order, visibility of the widgets
    overrides?: Overrides
}

export function StartTemplateFive(props: Props) {
    return (
        <Suspense fallback={<Loader />}>
            <StartTemplateFiveComponent {...props} />
        </Suspense>
    )
}

function StartTemplateFiveComponent(props: Props) {
    const { overrides } = props
    const {
        multiWidgetTabs,
        otherModules: { tyres, cisWidget, cisCreditWidget, hideCategories },
        finalOverrides,
    } = useStartTemplateFive(overrides)

    function getVal<T>(widgetKey: AllWidgetKeys, configKey: string, defaultVal?: T | undefined): T | undefined {
        return finalOverrides?.[widgetKey]?.custom?.[configKey] ?? defaultVal
    }

    const checkOrder = (key: AllWidgetKeys): number | undefined => {
        return finalOverrides?.[key]?.sort
    }

    return (
        <Box display="flex" flex="1" overflow="auto">
            <Box flex="0 0 33%" display="flex" flexDirection="column">
                <MultiWidgets
                    hideTabs={getVal<boolean>("multiWidget", "hideTabs")}
                    items={multiWidgetTabs}
                    channel={startTemplateFiveChannel}
                    sx={{ order: checkOrder("multiWidget"), height: "25em" }}
                />
                <StyledSimpleSearchWidget
                    sx={{ width: "100%", height: "40em", order: checkOrder("catalogSmallWidget") }}
                    universalSearchTreeLink="/:workTaskId/parts/universal/list"
                    universalSearchRoute="/:workTaskId/parts/universal/list/unisearch"
                    hideCategories={hideCategories}
                    directSearchRoute="/:workTaskId/parts/direct/list/direct"
                    universalSearchButton={getVal("catalogSmallWidget", "universalSearchButton")}
                />
                {cisCreditWidget && <CisCreditWidget sx={{ width: "100%", order: checkOrder("cisCreditWidget") }} />}
                {tyres && <TyresSearchWidgetCompact sx={{ width: "100%", order: checkOrder("tyresSearchWidget") }} />}
                {cisWidget && (
                    <CrmWidgetStart
                        sx={{ width: "100%", order: checkOrder("cisWidget") }}
                        listRoute="/:workTaskId/customer/list"
                        detailsRoute="/:workTaskId/customer/details/:customerId?"
                    />
                )}
                <Box display="flex" sx={{ order: checkOrder("externalCatalogsWidget") }}>
                    <ExternalCatalogsWidget
                        display="START"
                        itemClassName="widget"
                        listClassName="layout-vertical cols-4"
                        size={getVal<WidgetSizes>("externalCatalogsWidget", "size", "4x1")}
                        variableHeight={getVal<boolean>("externalCatalogsWidget", "variableHeight", false)}
                        showOnlyGroup={getVal<number>("externalCatalogsWidget", "showOnlyGroup")}
                        showGroupedItemsOnly={getVal<boolean>("externalCatalogsWidget", "showGroupedItemsOnly")}
                    />
                </Box>
            </Box>
            <Box display="flex" flex="1" width="100%" height="100%">
                <StyledExternalIframeWidget display="START" itemClassName="start-offers" />
            </Box>
        </Box>
    )
}

const StyledExternalIframeWidget = styled(ExternalIframeWidget)({
    "&:first-child": {
        width: "100%",
    },
    iframe: {
        width: "100%",
        height: "100%",
    },
})

const StyledSimpleSearchWidget = styled(SimpleSearchWidget)<{ universalSearchButton?: string }>(({ universalSearchButton }) => {
    switch (universalSearchButton) {
        case "smallLeft":
            return {
                ".search-content": {
                    padding: ".5em 0 .5em",
                },
                ".search-content+.btn": {
                    alignSelf: "flex-start",
                },
            }

        case "smallRight":
            return {
                ".search-content": {
                    padding: ".5em 0 .5em",
                },
                ".search-content+.btn": {
                    alignSelf: "flex-end",
                },
            }

        case "fullWidth":
        default:
            return {}
    }
})
