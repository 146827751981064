import { Alert } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    isItemAlert?: boolean
    additionalTextId?: number
}

export default function ErrorAlert({ isItemAlert, additionalTextId }: Props) {
    const { translateText } = useLocalization()
    let title = translateText(787)
    if (additionalTextId) {
        title += ` ${translateText(additionalTextId)}`
    }

    return <Alert size="small" severity="error" title={title} variant={isItemAlert ? "outlined" : "filled"} />
}
