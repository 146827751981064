import { Icon, Tooltip } from "@tm/controls"
import { Link } from "react-router-dom"
import { encodeUniqueId, renderRoute, RouteComponentProps, uniqueId, withRouter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"

type Props = RouteComponentProps<{ workTaskId?: string }> & {
    icon: string
    url: string
    tooltip?: string
}

function Component(props: Props) {
    const { workTaskId } = useWorkTask() || {}
    const { translateText, isTranslationId } = useLocalization()

    const url = renderRoute(props.url, {
        ...props.match.params,
        workTaskId: encodeUniqueId(workTaskId || uniqueId()),
    })

    const isSelected = window.location.pathname === url

    let content = (
        <Link className={`global-navigation tab tab--worktask ${isSelected ? "is-selected" : ""}`} to={url}>
            <div className="tab__content">
                <Icon name={props.icon} size="l" />
            </div>
        </Link>
    )

    if (props.tooltip) {
        const tooltip = isTranslationId(props.tooltip) ? translateText(props.tooltip) : props.tooltip
        content = <Tooltip content={tooltip}>{content}</Tooltip>
    }

    return content
}

export default withRouter(Component)
