import { VehicleLookupConfig, VehicleType } from "@tm/models"
import { Box, Stack, styled } from "@tm/components"
import Navigation from "./components/navigation"
import Search from "./components/search"
import SpecialLicensePlatesComponent from "../widget/components/special-license-plates/component"
import { VehicleLookupSelection } from "../_shared/VehicleLookupSelection"
import { TopManufacturer } from "../_shared/TopManufacturer"

type Props = {
    handleVehicleAttached: () => void
    hideVehicleTypeSwitch?: boolean
    showSpecialLicensePlateMicro?: boolean
    specialLicenseProps?: Record<string, any>
    placeholderPassengerCar?: string
    allConfiguredVehicleLookups: VehicleLookupConfig[]
    vehicleType: VehicleType
    setVehicleType: (type: VehicleType) => void
    setSelectedSearchConfig: (config: VehicleLookupConfig) => void
    selectedSearchConfig?: VehicleLookupConfig
    specializedSearchConfig?: VehicleLookupConfig
}

const StyledVehicleLookupSelection = styled(VehicleLookupSelection)({
    alignSelf: "flex-start",
    "& .MuiSelect-outlined": {
        padding: "0px 1em 0px 2em !important",
    },
})

function CarSearchCard(props: Props) {
    const {
        allConfiguredVehicleLookups,
        vehicleType,
        setVehicleType,
        setSelectedSearchConfig,
        handleVehicleAttached,
        selectedSearchConfig,
        specializedSearchConfig,
    } = props

    return (
        <Stack flex={1} justifyContent="space-between" height="16em">
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        ".lnt-widgets__content &": {
                            position: "absolute",
                            top: "-50px",
                            right: "-5em",
                        },
                    }}
                >
                    {!props.hideVehicleTypeSwitch && <Navigation activeVehicleType={vehicleType} setVehicleType={setVehicleType} />}
                    {allConfiguredVehicleLookups && (
                        <StyledVehicleLookupSelection
                            vehicleType={vehicleType}
                            className="vehicle_search-tree-widget_lookup-selection"
                            onSelectSearchOption={setSelectedSearchConfig}
                            selectedSearchConfig={selectedSearchConfig}
                        />
                    )}
                </Box>
                <SpecialLicensePlatesComponent
                    className="vehicle-widget__special-license-plates-micro"
                    vehicleType={vehicleType}
                    onVehicleAttached={handleVehicleAttached}
                    size="m"
                    {...props.specialLicenseProps}
                    registrationNoType={specializedSearchConfig?.lookupTypeId}
                    showSpecialLicensePlateMicro={props.showSpecialLicensePlateMicro}
                />

                <div className="search-container">
                    <Search
                        vehicleType={vehicleType}
                        placeholderPassengerCar={props.placeholderPassengerCar}
                        selectedSearchConfig={selectedSearchConfig}
                    />
                </div>
            </Box>
            <TopManufacturer vehicleType={vehicleType} />
        </Stack>
    )
}

export default CarSearchCard
