import { ChangeEvent, useMemo, useState } from "react"
import { Box, Button, Icon, ListItemIcon, TextField, styled } from "@tm/components"
import { CreateCustomArticleRequest } from "@tm/models"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { NumberField } from "@tm/controls"
import { StyledListItem } from "./shared"

type Props = {
    handleConsumableSave: (selected: CreateCustomArticleRequest | undefined) => void
    handleConsumableDiscard: () => void
    saveDisabled?: boolean
}

export default function ConsumableItemAdd({ handleConsumableSave, handleConsumableDiscard, saveDisabled }: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])

    const [description, setDescription] = useState<string | undefined>("")
    const [garagePrice, setGaragePrice] = useState<number>(0)
    const [isDefaultForCategory, setIsDefaultForCategory] = useState<boolean>(false)
    const articleSaveDisabled = !description || saveDisabled

    function handleDescriptionChange(event: ChangeEvent<HTMLInputElement>) {
        const newDescription = event?.target?.value
        setDescription(newDescription || "")
    }

    function handleGaragePriceChange(value: number | undefined) {
        setGaragePrice(value || 0)
    }

    function handleToggleDefaultForCategory() {
        setIsDefaultForCategory((prev) => !prev)
    }

    function handleSave() {
        if (!description) {
            return
        }

        const customArticle: CreateCustomArticleRequest = {
            description,
            garagePrice,
            isDefaultForCategory,
        }

        handleConsumableSave(customArticle)
    }

    return (
        <StyledListItem sx={{ "&:hover": { border: "none" } }} disablePadding>
            <Box alignItems="center" padding="0.5em 1em" width="100%" display="grid" gridTemplateColumns="1fr auto 1fr">
                <TextField
                    sx={{ margin: "0", marginRight: "1em" }}
                    placeholder={translateText(25)}
                    value={description}
                    onChange={handleDescriptionChange}
                />

                <ListItemIcon onClick={() => handleToggleDefaultForCategory()}>
                    <Icon name={isDefaultForCategory ? "filled-star" : "vote-star"} width="1.5em" height="1.5em" />
                </ListItemIcon>

                <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" gap="0.5em">
                    <StyledNumberField
                        placeholder={`${translateText(53)} ${currencyCode}`}
                        value={garagePrice}
                        onChangeConfirm={handleGaragePriceChange}
                        onChange={handleGaragePriceChange}
                        minimum={0.0}
                        maximum={9999999}
                        nullable
                        stepSize={0.01}
                    />

                    <Button size="small" onClick={() => handleConsumableDiscard()}>
                        <Icon name="close" />
                    </Button>
                    <Button disabled={articleSaveDisabled} size="small" color="success" onClick={() => handleSave()}>
                        <Icon name="check" />
                    </Button>
                </Box>
            </Box>
        </StyledListItem>
    )
}

const StyledNumberField = styled(NumberField)({
    margin: "0",
    marginRight: "0.5em",
})
