import { MouseEvent, useEffect, useMemo, useState } from "react"
import { Typography } from "@tm/components"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Checkbox, Icon, Table, Text } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { EPropertyType } from "../../data/enums"
import { getCalcStateIcon } from "../../data/helpers"
import { CalcSelectionItem } from "../../data/model"
import { MainActions } from "../main/business"
import CalcInputCheck from "./calcInputs/calcInputCheck"

type Props = {
    items: CalcSelectionItem[]
    disableNewSelection?: boolean
    isOverlayCalcState?: boolean
    isFromDialog?: boolean
    handleLonglifeSwitch?: (data: boolean) => void
}

export default function SelectionItems(props: Props) {
    const { disableNewSelection } = props
    const actions = useActions(MainActions, "handleSelectionItemClick")
    const style = useMemo(() => getStyle(), [])
    const [items, setItems] = useState<CalcSelectionItem[]>([])

    useEffect(() => {
        setItems([...props.items])
    }, [props.items])

    function handleSecondaryClickRow(e: MouseEvent) {
        e && e.stopPropagation()
    }

    function renderRow(item: CalcSelectionItem) {
        return (
            <Table.Cell>
                <div className={style.row}>
                    <Text>{item.label} </Text>
                    <Checkbox checked={item.isSelected} disabled={!item.isSelected && disableNewSelection} />
                </div>
                {item.subInputs && (
                    <div className={style.secondaryRow}>
                        {item.subInputs.map((subInput) => {
                            const icon = getCalcStateIcon(subInput.icon)

                            return (
                                <div className={style.secondaryRowItem} onClick={handleSecondaryClickRow} key={subInput.id}>
                                    {icon && <Icon name={icon} />}
                                    {subInput.title && <Typography variant="label">{subInput.title}</Typography>}
                                    <CalcInputCheck disableTitleLabel key={subInput.id} item={subInput} />
                                </div>
                            )
                        })}
                    </div>
                )}
            </Table.Cell>
        )
    }

    function handleCheck(item: CalcSelectionItem) {
        if (!item.isSelected && disableNewSelection) {
            return
        }

        const { isOverlayCalcState, isFromDialog } = props
        setItems((oldItems) =>
            oldItems.map(
                (x) =>
                    (x.id == item.id && {
                        ...x,
                        isSelected: !item.isSelected,
                    }) ||
                    x
            )
        )
        actions.handleSelectionItemClick(item, isOverlayCalcState, isFromDialog)

        if (item.properties && item.properties[0]?.type === EPropertyType.IsLonglife) {
            props.handleLonglifeSwitch?.(item.properties[0].value === "True")
        }
    }

    return (
        <div className={style.wrapper}>
            <Table
                data={items}
                onClickRow={handleCheck}
                columns={[<Table.Column className={style.fullWidth} renderItemContent={renderRow} />]}
                getRowClassName={(data) => `models__item ${data.isSelected ? "is-selected" : ""}`}
            />
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            $nest: {
                ".fancy-list__item": {
                    justifyContent: "space-between",
                    cursor: "pointer",
                },
                ".checkbox": {
                    opacity: theme.opacity.primary,
                },
                ".checkbox__icon": {
                    opacity: theme.opacity.primary,
                },
                ".checkbox--disabled": {
                    opacity: 0.38,
                },
            },
        },
        row: {
            display: "flex",
            justifyContent: "space-between",
        },
        fullWidth: {
            width: "100%",
        },
        secondaryRow: {
            borderTop: "1px solid rgb(226, 226, 226)",
            marginTop: "0.5em",
            paddingTop: "0.5em",
        },
        secondaryRowItem: {
            display: "flex",
            gap: "0.8em",
            alignItems: "center",
        },
    })(SelectionItems)
}
