import { WorkTaskInfo, useWorkTask } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { HasRepairTimesRequest, HasRepairTimesResponse, IMicros, RegisteredModels } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { encodeUniqueId, getRepairTimeProvidersByNames, renderRoute, mapOePart } from "@tm/utils"
import { useState, useEffect, Suspense } from "react"
import { getBundleParams } from "../../utils"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { createAddOePartListRequest } from "../../helpers"

type Props = IMicros["basket"]["add-to-cost-estimate-oe-part"]

function AddToCostEstimateOePartComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { part, quantity = 1, repairTimesProviders, workTask } = props
    const { vehicle, id } = workTask
    const { languageId } = useLocalization()
    const { basket } = useWorkTaskBasketState(id)
    const [hasRepairTimes, setHasRepairTimes] = useState(false)

    useEffect(() => {
        checkHasRepairTimes()
    }, [part.productGroupId, vehicle, repairTimesProviders])

    const checkHasRepairTimes = () => {
        if (!part.productGroupId) {
            return
        }
        if (!part.productGroupId) {
            return
        }

        const providers = getRepairTimeProvidersByNames(repairTimesProviders)

        const productGroupIds: number[] = [part.productGroupId]

        if (!vehicle || !providers.length) {
            return
        }
        if (!vehicle || !providers.length) {
            return
        }

        const request: HasRepairTimesRequest = {
            repairTimeProvider: providers,
            modelId: vehicle?.tecDocTypeId,
            productGroupIds,
            vehicleType: vehicle?.vehicleType,
        }

        Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes)
            .subscribe(request)
            .load()
            .then((response) => {
                if (response) {
                    if (part.productGroupId) {
                        setHasRepairTimes(!!response?.productGroups[part.productGroupId].length)
                    }
                }
            })
    }

    const handleCostEstimationButtonClick = () => {
        const request = createAddOePartListRequest(mapOePart(part, quantity), quantity, workTask)

        basket.actions.addOeParts(request)
        const route = getBundleParams().repairTimeModalUrl
        const url = renderRoute(route, {
            workTaskId: encodeUniqueId(id),
            manufacturerId: part.manufacturerId,
            partNumber: part.number,
            productGroupId: part.productGroupId,
        })
        Morpheus.showView("1", url)
    }

    return (
        <Button
            layout={["bordered"]}
            disabled={!hasRepairTimes}
            style={{ marginRight: "10em" }}
            className="cost-estimation-btn"
            icon={languageId === "1" ? "voucher-kva" : "voucher-kva-international"}
            onClick={handleCostEstimationButtonClick}
        />
    )
}

export default function Wrapper(props: Props) {
    const { workTask } = useWorkTask() ?? {}

    if (!workTask) {
        return null
    }
    return (
        <Suspense fallback={null}>
            <AddToCostEstimateOePartComponent {...props} workTask={workTask} />
        </Suspense>
    )
}
