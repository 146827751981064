import { FC, useCallback, useEffect, useState } from "react"
import { RegisteredModels, RegistrationNoType, ShowCarModelDetailsRequest, ShowCarModelDetailsResponse, Vehicle, VehicleType } from "@tm/models"
import { Container } from "@tm/nexus"
import { isCentralOrder } from "@tm/utils"
import { getImportedVehicleDetails, mergeImportedVehicleData } from "../../business"
import { DetailsError } from "../../data/hooks/modelDetailsWithoutCache"
import { useSelectedVehicleLookup } from "../../data/hooks/useSelectedLookupConfig"
import { mapModelDetailsToVehicle } from "../../data/mapper"
import { UnknownVinTextId, getDATErrorMessageTextId } from "../../helpers"
import DetailsComponent from "./Details"
import { useAdditionalModelIdentifier, useRegNoAndType } from "./business/hooks"
import { DetailsProps } from "./component"

type Props = DetailsProps & {
    vehicleType: VehicleType
    modelId: number
    workTaskId?: string
}

// TODO:  HIER MUSS IWIE DIE REG TYP ID BERÜCKSICHTIG WERDEN
export const DetailsByTecdocId: FC<Props> = (props) => {
    const { vehicleType, workTaskId } = props

    const { regNo, regNoType } = useRegNoAndType(vehicleType, undefined, props.location.search, props.availableVehicleSearches?.defaultVehicleLookup)
    const modelIdentifier = useAdditionalModelIdentifier(props.location.search)

    const [modelDetails, setModelDetails] = useState<ShowCarModelDetailsResponse>()
    const [vehicle, setVehicle] = useState<Vehicle>()
    const [error, setError] = useState<DetailsError>()

    const load = useCallback(
        (force = false, overwriteRegNo?: string, overwriteRegNoType?: RegistrationNoType, selectedLookupType?: RegistrationNoType) => {
            // Wait for workTaskId and regNoAndType to be loaded
            if (!workTaskId || !props.availableVehicleSearches) {
                return
            }

            const request: ShowCarModelDetailsRequest = {
                modelId: props.modelId,
                registrationNo: overwriteRegNo || regNo,
                registrationNoTypeId: overwriteRegNoType || regNoType,
                selectedLookupType,
                modelIdentifier,
                forceUpdateRegistrationNoDetails: force,
            }

            Container.getInstance<ShowCarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
                .subscribe(request, vehicleType)
                .load()
                .then((response: ShowCarModelDetailsResponse) => {
                    if (!response.modelDetails) {
                        setError({ text: response.error?.errorMessage ?? 568, type: "general" })
                        return
                    }

                    if (response.error) {
                        setError({ text: getDATErrorMessageTextId(response.error), type: "registrationNoDetails" })
                    } else if (!response.modelDetails.registrationNoDetails && force) {
                        setError({ text: UnknownVinTextId, type: "registrationNoDetails" })
                    }

                    setModelDetails(response)

                    let vehicle = mapModelDetailsToVehicle(response, response.modelDetails, vehicleType, request.registrationNoTypeId)

                    if (isCentralOrder()) {
                        setVehicle(vehicle)
                    } else {
                        // Imported data from GSI
                        getImportedVehicleDetails(workTaskId).then((data) => {
                            if (response.modelDetails) {
                                vehicle = mergeImportedVehicleData(vehicle, data, response.modelDetails)
                            }

                            setVehicle(vehicle)
                        })
                    }
                })
        },
        [vehicleType, props.modelId, workTaskId, regNo, regNoType, props.availableVehicleSearches]
    )

    const {
        selectedVehicleLookup: { lookupTypeId },
    } = useSelectedVehicleLookup(vehicleType)
    useEffect(() => {
        load(undefined, undefined, undefined, lookupTypeId)
    }, [load, lookupTypeId])

    function clearConflict(): void {
        setModelDetails((prev) => ({ ...prev, conflictReason: undefined, alternativeModels: undefined, error: undefined }))
        setError(undefined)
    }

    return (
        <DetailsComponent
            {...props}
            vehicle={vehicle}
            modelDetails={modelDetails}
            error={error}
            showServiceBook={false}
            reload={load}
            clearVehicleConflict={clearConflict}
        />
    )
}
