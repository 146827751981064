import { Vehicle } from "@tm/models"
import { getBundleParams } from "./utils"

export function hasFastCalculatorData(vehicle?: Vehicle): boolean {
    if (!vehicle) {
        return false
    }
    return !!vehicle?.dataAvailabilities?.fastCalculator.hasAny
}

export function getPoolingInterval(): number {
    const pollingInterval = +getBundleParams()?.pollingInterval

    return (Number.isInteger(pollingInterval) && pollingInterval > 0 ? pollingInterval : 5) * 1000
}
