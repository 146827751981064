import { Dispatch } from "redux"
import { AsyncAction } from "@tm/morpheus"
import { Article } from "@tm/models"
import { Repositories } from "../../../data"
import { BundleActionType, BundleActions } from "../../../business"
import { PartState } from "./model"
import { DetailsRequest } from "../../../data/model"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "CHANGE_ARTICLE_QUANTITY"; payload: { article: Article; quantity: number } }
    | { type: "LOADING" }
    | { type: "LOADED"; payload?: Article }

const DEFAULT_STATE: PartState = {
    initialized: false,
    loading: false,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): PartState {
    switch (action.type) {
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
        case "CUSTOMER_SET": {
            return {
                ...state,
                customer: action.payload,
            }
        }
        case "LOADING": {
            return {
                ...state,
                initialized: true,
                loading: true,
            }
        }
        case "LOADED": {
            return {
                ...state,
                loading: false,
                part: action.payload,
            }
        }
        case "CHANGE_ARTICLE_QUANTITY": {
            if (state.part) {
                return {
                    ...state,
                    part: {
                        ...state.part,
                        quantity: action.payload.quantity,
                    },
                }
            }
            break
        }
        default:
            break
    }

    return state
}

function load(
    supplierId: number,
    supplierArticleNo: string,
    productGroupId: number,
    initialQuantity: number
): AsyncAction<ComponentActionType, PartState> {
    return (dispatch, getState) => {
        const state = getState()

        const request: DetailsRequest = {
            productGroupId,
            supplierArticleNo,
            supplierId,
        }

        if (state.vehicle) {
            request.vehicleType = state.vehicle.vehicleType
            request.modelId = state.vehicle.tecDocTypeId
        }

        dispatch({ type: "LOADING" })

        Repositories.getArticleDetails(request).then((response) => {
            if (response.article) {
                response.article.quantity = initialQuantity
            }
            dispatch({ type: "LOADED", payload: response.article })
        })
    }
}

function changeQuantity(article: Article, quantity: number): ComponentActionType {
    return { type: "CHANGE_ARTICLE_QUANTITY", payload: { article, quantity } }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    changeQuantity,
    load,
}
