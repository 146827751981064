import { channel, RegistrationNoType, Vehicle, VrmLookupTextIds } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { batch } from "react-redux"
import { BundleActions, BundleActionTypes } from "../../../business"
import { getBundleParams } from "../../../utils"
import { DATVehicleVinSearchResponse } from "../../../data/repositories/datVehicleVinSearch/model"
import { MainState } from "../../main"
import { MainActionsType, MainActions } from "../../main/business"
import { CheckCustomerResponse, PrepareCalculationResponse, useContainer } from "../../../data/repositories"

import { createPrepareCalculationRequest, getDATErrorMessageTextId } from "./helpers"
import { SummaryState } from "./model"

export * from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "UPDATE_FIELD"; payload: { path: string; value: any; vehicle?: Vehicle } }
    | { type: "CHECK_CUSTOMER_LOADING" }
    | { type: "CHECK_CUSTOMER_LOADED"; payload: CheckCustomerResponse }
    | { type: "CHANGE_MODAL_DISPLAY"; payload?: boolean }
    | { type: "PREPARE_CALC_LOADING" }
    | { type: "PREPARE_CALC_LOADED"; payload: PrepareCalculationResponse }
    | { type: "PREPARE_CALC_ERROR" }
    | { type: "INIT_CUSTOMER"; payload: { country: string } }
    | { type: "CHECK_VIN"; payload: DATVehicleVinSearchResponse }
    | { type: "CHECK_VIN_LOADING"; payload: boolean }
    | { type: "CHECK_VIN_ERROR" }
    | { type: "VEHICLE_HAS_KEYNUMBER"; payload: boolean }

const DEFAULT_STATE: SummaryState = {
    fields: {
        keyNumbers: [],
        processName: "",
    },
    checkVinLoading: false,
    datVinSearchIsValid: false,
    isGermanOrAustrianVehicle: true,
}

export const reduce = (state = DEFAULT_STATE, action: MainActionsType): SummaryState => {
    switch (action.type) {
        case "UPDATE_FIELD": {
            const { path, value, vehicle } = action.payload
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [path]: value,
                },
                ...(path === "vin" && {
                    ...((value?.length < 17 || (value?.length === 17 && value !== vehicle?.vin)) && {
                        vinSearchInformalMessage: undefined,
                        datVinSearchIsValid: false,
                    }),
                    ...(value === vehicle?.vin && {
                        vinSearchInformalMessage:
                            state.vehicleHasDATInfo || state.vehicleHasDATInfoFromModule
                                ? VrmLookupTextIds.ResultSuccess
                                : VrmLookupTextIds.NoResultForVin,
                        datVinSearchIsValid: state.vehicleHasDATInfo || state.vehicleHasDATInfoFromModule,
                    }),
                }),
            }
        }
        case "CHECK_CUSTOMER_LOADED": {
            const { agbUrl, userIsRegistered } = action.payload
            return {
                ...state,
                acceptPdf: agbUrl,
                showAcceptModal: !userIsRegistered,
            }
        }
        case "CHANGE_MODAL_DISPLAY": {
            return {
                ...state,
                showAcceptModal: action.payload ?? !state.showAcceptModal,
            }
        }
        case "PREPARE_CALC_LOADING": {
            return {
                ...state,
                prepareCalculationLoading: true,
                prepareCalculationError: false,
            }
        }
        case "PREPARE_CALC_ERROR": {
            return {
                ...state,
                prepareCalculationError: true,
                prepareCalculationLoading: false,
            }
        }
        case "PREPARE_CALC_LOADED": {
            return {
                ...state,
                prepareCalculationError: false,
                prepareCalculationLoading: false,
            }
        }
        case "VEHICLE_SET": {
            const { registrationNo, initialRegistration, plateId, vin, mileAge } = action.payload

            const vinFieldValue = state.fields.vin ?? ""

            return {
                ...state,
                ...((vinFieldValue.length < 17 || (vinFieldValue.length === 17 && vinFieldValue !== vin)) && {
                    vinSearchInformalMessage: undefined,
                    datVinSearchIsValid: false,
                }),
                ...(vinFieldValue === vin && {
                    vinSearchInformalMessage: state.vehicleHasDATInfo ? VrmLookupTextIds.ResultSuccess : VrmLookupTextIds.NoResultForVin,
                    datVinSearchIsValid: state.vehicleHasDATInfo,
                }),
                fields: {
                    ...state.fields,
                    initialRegistration,
                    vin,
                    keyNumber: registrationNo,
                    plateNumber: plateId,
                    mileage: mileAge,
                },
            }
        }
        case "CUSTOMER_SET": {
            const { title, titleType, firstName, lastName, zip, city, country, email, phone, companyName } = action.payload

            const street = action.payload?.street && /^[^\d]*/gi.exec(action.payload.street)?.[0]?.trim()
            const streetNo = action.payload?.street && /[\d+](.*)/gi.exec(action.payload.street)?.[0]?.trim()

            return {
                ...state,
                fields: {
                    ...state.fields,
                    title,
                    titleType,
                    firstName,
                    lastName,
                    street,
                    streetNo,
                    zip,
                    city,
                    ...(country && { country }),
                    email,
                    phone,
                    companyName,
                },
            }
        }
        case "INIT_CUSTOMER": {
            const { country } = action.payload

            return {
                ...state,
                fields: {
                    ...state.fields,
                    country,
                },
            }
        }
        case "VEHICLE_HAS_KEYNUMBER": {
            return {
                ...state,
                isGermanOrAustrianVehicle: action.payload,
            }
        }
        case "DETAILS_LOADED": {
            const {
                data: { modelDetails },
                vehicle,
            } = action.payload
            const minDate =
                modelDetails?.constructionYearFrom && new Date(modelDetails.constructionYearFrom.year, modelDetails.constructionYearFrom.month, 0)
            const maxDate =
                modelDetails?.constructionYearTo && new Date(modelDetails.constructionYearTo.year, modelDetails.constructionYearTo.month, 0)
            const vehicleHasDatInfo = !!modelDetails?.registrationNoDetails && Object.keys(modelDetails?.registrationNoDetails).length > 1
            const { vehicleHasDATInfoFromModule } = state
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...(state.isGermanOrAustrianVehicle && {
                        keyNumbers: modelDetails?.registrationNos || [],
                    }),
                    minDate,
                    maxDate,
                },
                vehicleHasDATInfo: vehicleHasDatInfo || vehicleHasDATInfoFromModule,
                datVinSearchIsValid: vehicle?.registrationTypeId === RegistrationNoType.DatVin && (vehicleHasDatInfo || vehicleHasDATInfoFromModule),
                vinSearchInformalMessage:
                    vehicle?.registrationTypeId === RegistrationNoType.DatVin && (vehicleHasDatInfo || vehicleHasDATInfoFromModule)
                        ? VrmLookupTextIds.ResultSuccess
                        : VrmLookupTextIds.NoResultForVin,
                showVehicleModelDetailsLoading: false,
            }
        }
        case "CHECK_VIN_LOADING": {
            return {
                ...state,
                checkVinLoading: action.payload,
            }
        }
        case "CHECK_VIN": {
            const { error, vehicles } = action.payload
            const vehicleHasDATInfoFromModule = !!vehicles?.some((obj) => Object.prototype.hasOwnProperty.call(obj, "registrationNoDetails"))
            return {
                ...state,
                datVinSearchIsValid: vehicles?.length > 0 || false,
                vehicleHasDATInfoFromModule,
                checkVinLoading: false,
                ...(error?.errorType && {
                    vinSearchInformalMessage: getDATErrorMessageTextId(error),
                }),
                ...(vehicles?.length > 0 && {
                    vinSearchInformalMessage: VrmLookupTextIds.ResultSuccess,
                }),
                ...(vehicles?.length === 0 && {
                    vinSearchInformalMessage: VrmLookupTextIds.NoResultForVin,
                }),
            }
        }
        case "CHECK_VIN_ERROR": {
            return {
                ...state,
                checkVinLoading: false,
            }
        }
        case "SHOW_VEHICLE_MODELS_LOADING": {
            return {
                ...state,
                showVehicleModelDetailsLoading: true,
            }
        }
        default: {
            return state
        }
    }
}

const updateFieldValue =
    (path: string, value: any): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        const state = getState().manager
        const { vehicle } = state
        dispatch({ type: "UPDATE_FIELD", payload: { path, value, vehicle } })
    }

const changeModalDisplay = (value?: boolean): ComponentActionType => ({ type: "CHANGE_MODAL_DISPLAY", payload: value })

const checkCustomer =
    (translateText?: (key: string | number) => string): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        dispatch({ type: "CHECK_CUSTOMER_LOADING" })

        if (!translateText) {
            return
        }

        useContainer()
            .action("checkCustomer")()
            .then(
                (response) => dispatch({ type: "CHECK_CUSTOMER_LOADED", payload: response }),
                () => {
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12591), closeDelay: 3000, skin: "warning" })
                    dispatch({ type: "PREPARE_CALC_ERROR" })
                }
            )
    }

const startCalculation =
    (translateText?: (key: string | number) => string): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        // alert("TO BE IMPLEMENTED")
        const req = createPrepareCalculationRequest(getState())
        if (!req || !translateText) {
            return
        }

        const container = useContainer()
        dispatch({ type: "PREPARE_CALC_LOADING" })
        container
            .action("prepareCalculation")(req)
            .then(
                (res) => {
                    if (res?.baseInfo?.errorMessage) {
                        dispatch({ type: "PREPARE_CALC_ERROR" })
                        channel("APP").publish("TOAST_MESSAGE/SHOW", {
                            message: translateText(res.baseInfo.errorMessage),
                            closeDelay: 3000,
                            skin: "warning",
                        })
                        return
                    }

                    batch(() => {
                        dispatch({ type: "PREPARE_CALC_LOADED", payload: res })
                        dispatch(MainActions.changeStep("extern", {}))
                    })
                },
                () => {
                    dispatch({ type: "PREPARE_CALC_ERROR" })
                }
            )
    }

const initCustomer =
    (country: string): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        dispatch({ type: "INIT_CUSTOMER", payload: { country } })
    }

const initVehicle =
    (countryCode: string): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        const isGermanOrAustrianVehicle =
            countryCode.toLowerCase() === "d" ||
            countryCode.toLowerCase() === "de" ||
            countryCode.toLowerCase() === "at" ||
            countryCode.toLowerCase() === "a"
        dispatch({ type: "VEHICLE_HAS_KEYNUMBER", payload: isGermanOrAustrianVehicle })
    }

const validateVin =
    (
        attachToWorkTask?: (data: { vehicle: Vehicle }) => Promise<void>,
        translateText?: (key: string | number) => string
    ): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        const state = getState()
        const container = useContainer()
        const {
            manager: { vehicle },
            summary: { fields },
        } = state
        const { useAttachToWorktask } = getBundleParams()

        dispatch({ type: "CHECK_VIN_LOADING", payload: true })

        if (!vehicle || (useAttachToWorktask && !attachToWorkTask) || !translateText) {
            return
        }

        container
            .action("datVehicleVinSearch")(state.summary.fields.vin!)
            .then(
                (res) => {
                    if (res.vehicles?.length > 0) {
                        attachToWorkTask!({
                            vehicle: {
                                ...vehicle,
                                vin: fields.vin,
                                initialRegistration: fields.initialRegistration,
                                registrationNo: fields.keyNumber,
                                plateId: fields.plateNumber,
                                mileAge: fields.mileage,
                                ...(vehicle.registrationTypeId !== RegistrationNoType.DatVin && {
                                    registrationTypeId: RegistrationNoType.DatVin,
                                }),
                            },
                        }).then(() => {
                            dispatch({ type: "CHECK_VIN", payload: res })
                        })
                    } else {
                        dispatch({ type: "CHECK_VIN", payload: res })
                    }
                },
                () => {
                    dispatch({ type: "CHECK_VIN_ERROR" })
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12496), closeDelay: 3000, skin: "warning" })
                }
            )
    }

export const Actions = {
    ...BundleActions,
    updateFieldValue,
    checkCustomer,
    changeModalDisplay,
    startCalculation,
    initCustomer,
    validateVin,
    initVehicle,
}
