import { useMemo } from "react"
import {
    Article,
    ArticleReference,
    AdditionalInformationLink,
    ArticleReferenceParms,
    DisplayArticle,
    ModificationState,
    ArticleReferenceType,
    ListFilter,
} from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { toPairs } from "lodash"
import { getBundleParams } from "../../../../utils"
import { useVehicleRecordsData } from "../useVehicleRecords"
import { getIconFromMediaType, mapArticleReferences, mapAttributes } from "./mapper"
import { usePartsModuleState } from "../../PartsModuleState"

export function useDisplayArticle(article: Article, articleAttributes: ListFilter[]): DisplayArticle {
    const partsUiConfig = getBundleParams()
    const { attributes: vehicleRecordsAttributes } = useVehicleRecordsData()
    const hideDealerPartNumber = useUser()?.userContext.parameter.hideDealerPartNumber

    const supplierFilters = usePartsModuleState((x) => x.filters.dataSupplierFilters)
    const suplierSelected = useMemo(
        () => supplierFilters.find((x) => x[0].id === article.supplier.id)?.[1] ?? false,
        [article.supplier.id, supplierFilters]
    )

    const supplierLogos = usePartsModuleState((x) => x.supplierLogos?.supplierLogos)
    const supplierLogo = useMemo(() => supplierLogos?.[article.supplier.id], [supplierLogos, article.supplier.id])

    // Hersteller infos
    const supplier = useMemo<DisplayArticle["supplier"]>(
        () => ({
            supplier: article.supplier,
            supplierLogo,
            selected: suplierSelected,
        }),
        [article.supplier, supplierLogo, suplierSelected]
    )

    return useMemo(() => {
        // Artikelbilder
        const image: DisplayArticle["image"] = {
            thumbnailUrl: article.thumbnail,
            thumbnailDescription: undefined,
            thumbnailFallbackUrl: undefined,
            enableLargeView: false,
            customThumbnailComponent: undefined,
            startIndex: undefined,
        }

        // Bekommt man sicher auch noch hübscher hin, weiß grade nicht wie :D
        let copyButtonTrader = false
        let copyButtonSupplier = false
        if (typeof partsUiConfig.showCopyNumberButton === "object" || Array.isArray(partsUiConfig.showCopyNumberButton)) {
            copyButtonTrader = partsUiConfig.showCopyNumberButton.find((val) => val === "article-list--trader")
            copyButtonSupplier = partsUiConfig.showCopyNumberButton.find((val) => val === "article-list--supplier")
        }

        // Artikelnummer
        const numbers: DisplayArticle["numbers"] = {
            hideDealerPartNumber,
            traderArticleNo: article.traderArticleNo,
            supplierArticleNo: article.supplierArticleNo,
            copyButtonTrader,
            copyButtonSupplier,
        }

        // Bonussystem
        const bonus: DisplayArticle["bonus"] = {
            id: article.availableBonusSystems?.[0].id,
            name: article.availableBonusSystems?.[0].name,
            imageUrl: article.availableBonusSystems?.[0].imageUrl,
            value: article.availableBonusSystems?.[0].value,
        }

        // Artikelbeschreibung
        const description: DisplayArticle["description"] = {
            position: "bottom",
            traderDescriptopnPosition: "bottom",
            additionalDescriptions: article.additionalReferenceArticleInformation?.headInformation,
            missingVehicleConnectionTitle: undefined,
            description: article.description,
        }

        // Artikelattribute TOP
        const attributes: DisplayArticle["attributes"] = article.attributes.map((attributes) => {
            return {
                topArticleAttributes: mapAttributes(
                    [...attributes.topAttributes],
                    article.productGroup.id,
                    vehicleRecordsAttributes,
                    articleAttributes
                ),
                additionalAttributes: mapAttributes(
                    [...attributes.vehicleAttributes, ...attributes.partsListAttributes, ...attributes.articleAttributes],
                    article.productGroup.id,
                    vehicleRecordsAttributes,
                    articleAttributes
                ),
            }
        })

        // split References to IMS Informations and default References, should be changed in Service if it is possible
        const imsReferences: ArticleReferenceParms[] = []
        const rawArticleReferences: ArticleReference[] = []

        article.references.forEach((ref) => {
            if (ref?.modificationState === ModificationState.Removed || ref?.referenceType === ArticleReferenceType.OeReferenceNo) {
                // imsReferences will be shown in the additional information
                imsReferences.push(mapArticleReferences(ref))
            } else {
                // will be shown in it's own block above the addtional information
                rawArticleReferences.push(ref)
            }
        })

        // Alle weiteren Referzenzen
        const references: DisplayArticle["references"] = rawArticleReferences.map(mapArticleReferences)

        const additionalInformation: DisplayArticle["additionalInformation"] = {
            references: imsReferences,
            generalInformation: toPairs(article.information.groupBy((x) => x.description)).map(([title, infos]) => {
                return [title, infos.map((x) => x.content)]
            }),
            textInformation: article.additionalReferenceArticleInformation?.textInformation.map((group) => [
                group.name,
                group.member.map((x) => x.description),
            ]),
            headInformation: article.additionalReferenceArticleInformation?.headInformation,
            links: article.additionalReferenceArticleInformation?.links.reduce<AdditionalInformationLink[]>((p, c) => {
                return [
                    ...p,
                    ...c.member.map<AdditionalInformationLink>((member) => ({
                        href: member.content,
                        name: member.description,
                        icon: getIconFromMediaType(member.mediaType),
                    })),
                ]
            }, []),
        }

        return {
            numbers,
            image,
            supplier,
            bonus,
            description,
            attributes,
            references,
            additionalInformation,
            modificationState: article.articleModificationState,
        }
    }, [article, partsUiConfig.showCopyNumberButton, supplier, vehicleRecordsAttributes, hideDealerPartNumber])
}
