import { FC, useEffect, useState } from "react"
import { Text, Widget, Button, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { connectComponent } from "@tm/morpheus"
import { renderRoute, encodeUniqueId, uniqueId } from "@tm/utils"
import { CategoryType, VehicleType, channel } from "@tm/models"
import { useParams } from "react-router"
import { getBundleParams } from "../../utils"
import CategorySelection from "../_shared/category-selection"
import DirectSearchWidgetContent from "../_shared/direct-search-widget-content"
import UniSearchWidgetContent from "../_shared/uni-search-widget-content"
import { UniSearchWidgetState, IActions, Actions } from "../_shared/uni-search-widget-content/business"
import { SearchTree } from "../widget/business"
import { useUniSearchStatus } from "../../helper"

type ConfigParams = {
    universalSearchRoute: string
    directSearchRoute: string
    universalSearchText: string
    directSearchText: string
    tiresSearchText: string
    size?: string
    catalogName?: string
    tree?: SearchTree
    hideBestseller?: boolean
    hideTree?: boolean
}

type Props = ConfigParams & {
    state: UniSearchWidgetState
    actions: IActions
    className?: string
}

const SearchWidgetComponent: FC<Props> = (props: Props) => {
    const [activeCategory, setActiveCategory] = useState<CategoryType>("directSearch")
    const { translateText } = useLocalization()
    const matchParams = useParams<{ workTaskId: string }>()
    const { state, actions, hideTree, hideBestseller } = props

    useEffect(() => {
        actions.initialize(props.tree)
        actions.resetBreadcrumbs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!hideTree && state.tree.treeId && activeCategory === "universalParts") {
            actions.loadUniversalPartsTreeNodes()
        }
    }, [hideTree, state.tree.treeId, activeCategory])

    function handleTabSelect(category: CategoryType) {
        setActiveCategory(category)
    }

    function handleApplyProductGroups(productGroups: number[]) {
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            uniProductGroups: { ids: productGroups.map((x) => x).orderBy((x) => x) },
        })
    }

    function renderVehiclePartsSearch() {
        const url = renderRoute(getBundleParams().vehiclesRoute, {
            ...matchParams,
            workTaskId: matchParams.workTaskId || encodeUniqueId(uniqueId()),
            vehicleType: VehicleType.PassengerCar, // TODO
        })

        return (
            <div className="vehicle-parts">
                <Text modifiers="block">{translateText(1002)}</Text>
                <Button linkTo={url} size="l" skin="highlight">
                    {translateText(862)}
                </Button>
            </div>
        )
    }

    function renderContent() {
        const {
            directSearchRoute,
            universalSearchRoute,
            state: { tree, queryHistory },
        } = props

        switch (activeCategory) {
            case "vehicleParts":
                return renderVehiclePartsSearch()
            case "universalParts":
                const treeId = getBundleParams().universalTreeId
                if (!treeId) {
                    return
                }

                return (
                    <UniSearchWidgetContent
                        universalSearchRoute={universalSearchRoute}
                        onApplyProductGroups={handleApplyProductGroups}
                        hideBestseller={hideBestseller}
                        tree={tree}
                    />
                )
            case "directSearch":
                return <DirectSearchWidgetContent directSearchRoute={directSearchRoute} queryHistory={queryHistory} />
            default:
                break
        }
    }

    const getSize = (): WidgetSizes => {
        const { size } = props
        const hasSizeFormat = /\d+x\d+/
        if (size && hasSizeFormat.test(size)) {
            return size as WidgetSizes
        }

        return "6x3"
    }

    const className = `tk-parts search-widget ${props.className || ""}`
    const { catalogName } = props

    const text = catalogName ? translateText(997).replace("NEXT™", catalogName) : translateText(997)
    const uniSearchIsActive = useUniSearchStatus()
    const availableCategories: Array<CategoryType> = ["vehicleParts", "directSearch"]

    if (uniSearchIsActive) {
        availableCategories.push("universalParts")
    }

    return (
        <Widget id="parts__search-widget" className={className} iconName="catalog" title={translateText(993)} size={getSize()} active>
            <Text className="intro">{text}</Text>

            <CategorySelection
                activeCategory={activeCategory}
                onChangeCategory={handleTabSelect}
                disabledCategories={[]}
                availableCategories={availableCategories}
                directSearchText={translateText(props.directSearchText || 389)}
                universalSearchText={translateText(props.universalSearchText || 1009)}
                tiresSearchText={translateText(props.tiresSearchText || 240)}
            />

            {renderContent()}
        </Widget>
    )
}

export default connectComponent(Actions, SearchWidgetComponent)
