import { useEffect, useMemo } from "react"
import { WorkTaskInfo } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { TeccomErpInformation } from "@tm/models"
import { isCentralOrder, useErpConfig } from "@tm/utils"
import { Box } from "@tm/components"
import WorkTaskOrderButtonComponent from "../../order-button/worktask"
import { SelectedOrderOptions, WorkTask } from "../../../../data/model"
import { useAdditionalOrderInformation } from "../../../../hooks/useAdditionalOrderInformation"
import { BasketMemo } from "../../../../hooks/useBasketMemo"
import { OrderOptionsComponent } from "../../order-options/workTask"
import { useGlobalOrderOptionsState } from "../../../../hooks/useGlobalOrderOptionsState"
import PartsTable from "./PartsTable"
import PartsSelection from "./part-list-item/components/parts-selection"
import { BasketErpIndicator, BasketOrderGroup, BasketPart } from "../../../../models"
import { BonusInformationState } from "../../../../data/hooks/useBonusInformation"

type Props = {
    workTask: WorkTask | WorkTaskInfo
    telesalesCustomerNo: string | undefined
    basketMemo: BasketMemo
    basketOrderGroup: BasketOrderGroup
    workTaskBasketLoading: boolean
    workTaskBasketCalculationLoading?: boolean
    orderSending?: boolean
    basketErpInfosIndicator: BasketErpIndicator
    // For Order Options
    orderOptionsInitiallyExpanded: boolean | undefined
    // For List Items
    showPurchasePrice?: boolean
    bonusInformationState?: BonusInformationState
    hideAddToBasketButtonInArticleComparison?: boolean
    bonusPointsLoading?: boolean
    bonusPointsWithErrors: boolean
    hideVehicleLogos?: boolean
    onChangePartOrderOptions?(part: BasketPart, orderOptions: SelectedOrderOptions): void
    onChangeQuantity(part: BasketPart, quantityValue: number): void
    onExpandNote(id: string): void
    onExpandOrderOptions(id: string): void
    onIncludeExcludeParts(parts: BasketPart[]): void
    onOpenAlternativeArticlesToReplacePart(part: BasketPart): void
    onOpenArticleDetails(productGroupId?: number, supplierArticleNumber?: string, supplierId?: number, subPage?: string, scrollTo?: string): void
    onOpenArticleSearch(query: string): void
    onOpenDirectSearchToReplacePart(part: BasketPart): void
    onOpenUniversalSearchToReplacePart(part: BasketPart): void
    onTeccomInfoLoaded(partId: string, teccom: TeccomErpInformation): void
    onRemovePartNote(part: BasketPart): void
    onRemoveParts(partIds: string[]): void
    onSavePartNote(part: BasketPart, note: string, isNewNote: boolean): void
    onSelectAll(basketOrderGroup: BasketOrderGroup): void
    onSelectPart(id: string): void
    onUnselectAll(basketOrderGroup: BasketOrderGroup): void
}

export default function OrderGroup(props: Props) {
    const {
        workTask,
        basketMemo,
        basketOrderGroup,
        telesalesCustomerNo,
        workTaskBasketLoading,
        workTaskBasketCalculationLoading,
        showPurchasePrice,
        basketErpInfosIndicator,
        bonusInformationState,
        orderSending,
        hideAddToBasketButtonInArticleComparison,
        bonusPointsLoading,
        bonusPointsWithErrors,
    } = props
    const workTaskId = "workTaskId" in workTask ? workTask.workTaskId : workTask.id
    const { basketParts, orderGroup, completeDeliveryTourStart, orderOptionsGroup } = basketOrderGroup
    const { distributorId, distributorName, warehouseId, warehouseName } = orderGroup
    const { orderOptions, isLoading: orderOptionsLoading, isFetching: orderOptionsFetching, hasErrors } = orderOptionsGroup || {}
    const { translateText } = useLocalization()
    const { useOrderByDistributor } = useErpConfig()
    const { globalOrderOptionsEnabled } = useGlobalOrderOptionsState()

    const [additionalInformation, setAdditionalInformation] = useAdditionalOrderInformation(workTaskId, {
        distributorId,
        warehouseId,
    })

    const [bonusInformation, bonusInformationLoading] = useMemo(() => {
        return [
            bonusInformationState?.groups.find((g) => g.distributorId === basketOrderGroup.orderGroup.distributorId)?.response,
            bonusInformationState?.loading,
        ]
    }, [bonusInformationState, basketOrderGroup])

    const [selectedParts = [], areAllPartsSelected] = useMemo(() => {
        const selected = basketParts?.filter((item) => item.states.isSelected)
        return [selected, selected?.length === basketParts?.length]
    }, [basketParts])

    useEffect(() => {
        if (!orderOptionsLoading && !additionalInformation?.isDirty) {
            setAdditionalInformation({
                customerOrderComment: orderOptions?.orderComment || basketMemo.order,
                customerOrderNumber: orderOptions?.customerOrderNumber || "",
                isDirty: false,
                warehouseId: orderGroup.warehouseId,
                distributorId: orderGroup.distributorId,
                workTaskId,
            })
        }
    }, [
        orderOptions?.orderComment,
        orderOptions?.customerOrderNumber,
        orderOptionsLoading,
        additionalInformation?.isDirty,
        basketMemo.order,
        orderGroup,
        workTaskId,
        setAdditionalInformation,
    ])

    const warehouseDispatchType = orderOptions?.shipmentModes?.shipmentModes.find((s) => s.isSelected)?.dispatchType
    function renderPartsSelection() {
        if (selectedParts && selectedParts?.length > 0) {
            return (
                <PartsSelection
                    workTaskId={workTaskId}
                    selectedParts={selectedParts}
                    allSelected={areAllPartsSelected}
                    disableControls={workTaskBasketLoading || workTaskBasketCalculationLoading || basketErpInfosIndicator.isLoading || orderSending}
                    onSelectAll={() => props.onSelectAll(basketOrderGroup)}
                    onUnselectAll={() => props.onUnselectAll(basketOrderGroup)}
                    onIncludeExcludeParts={props.onIncludeExcludeParts}
                    onRemoveParts={props.onRemoveParts}
                    onUnselectBasketPart={props.onSelectPart}
                    hideAddToBasketButtonInArticleComparison={hideAddToBasketButtonInArticleComparison}
                />
            )
        }
    }

    function renderTable() {
        return basketParts ? (
            <PartsTable
                parts={basketParts}
                workTaskId={workTaskId}
                basketErpIndicator={basketErpInfosIndicator}
                showPurchasePrice={showPurchasePrice}
                warehouseDispatchType={warehouseDispatchType}
                bonusInformationLoading={bonusInformationLoading}
                bonusInformation={bonusInformation}
                bonusPointsWithErrors={bonusPointsWithErrors}
                bonusPointsLoading={bonusPointsLoading}
                calculationLoading={workTaskBasketCalculationLoading}
                hideVehicleLogos={props.hideVehicleLogos}
                orderOptions={orderOptions}
                orderSending={orderSending}
                onChangeQuantity={props.onChangeQuantity}
                onChangePartOrderOptions={props.onChangePartOrderOptions}
                onExpandNote={props.onExpandNote}
                onExpandOrderOptions={props.onExpandOrderOptions}
                onIncludeExcludePart={(part) => props.onIncludeExcludeParts?.([part])}
                onOpenAlternativeArticlesToReplacePart={props.onOpenAlternativeArticlesToReplacePart}
                onOpenArticleSearch={props.onOpenArticleSearch}
                onOpenArticleDetails={props.onOpenArticleDetails}
                onOpenDirectSearchToReplacePart={props.onOpenDirectSearchToReplacePart}
                onOpenUniversalSearchToReplacePart={props.onOpenUniversalSearchToReplacePart}
                onTeccomInfoLoaded={props.onTeccomInfoLoaded}
                onSelectPart={props.onSelectPart}
                onRemoveParts={props.onRemoveParts}
                onRemoveNote={props.onRemovePartNote}
                onSaveNote={props.onSavePartNote}
            />
        ) : undefined
    }

    return (
        <>
            {(!globalOrderOptionsEnabled || !isCentralOrder()) && (
                <OrderOptionsComponent
                    completeDeliveryTour={completeDeliveryTourStart}
                    isInitiallyExpanded={props.orderOptionsInitiallyExpanded}
                    telesalesCustomerNo={telesalesCustomerNo}
                    workTask={workTask}
                    warehouseId={warehouseId}
                    warehouseName={warehouseName}
                    distributorId={distributorId}
                    distributorName={distributorName}
                    orderOptions={orderOptions}
                    loading={orderOptionsLoading ?? false}
                    fetching={orderOptionsFetching ?? false}
                    disabled={(basketErpInfosIndicator.isLoading || orderSending) ?? false}
                    hasErrors={hasErrors}
                    headerAppendix={
                        useOrderByDistributor && distributorId ? (
                            <WorkTaskOrderButtonComponent
                                workTaskId={workTaskId}
                                orderGroups={[basketOrderGroup]}
                                orderGroupsLoading={workTaskBasketLoading}
                                distributorId={distributorId}
                                useTextAsTitle
                                textId={translateText(12445).replace("{0}", distributorName || "")}
                            />
                        ) : undefined
                    }
                />
            )}
            <Box position="relative" pb={2}>
                {renderPartsSelection()}
                {renderTable()}
            </Box>
        </>
    )
}
