import { useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Button, WarningPrompt, Checkbox, Loader } from "@tm/controls"
import { CompilationShowViewModelItem } from "../../../../../data"
import { useDeleteArticles } from "../../../../../data/hooks/useCompilations"
import { isWM } from "../../../../../utils"

type Props = {
    item: CompilationShowViewModelItem
    compilationId: string
    isEditing: boolean
    isSelected: boolean
    updatingArticle: boolean
    onShowHideEditMode(item: CompilationShowViewModelItem): void
    onSelectPart(partId: string, moveMode?: boolean): void
    onUpdatePart(): void
}

export default function PartActions(props: Props) {
    const { translateText } = useLocalization()
    const dialogRef = useRef<WarningPrompt>(null)
    const [partWillBeRemoved, setPartWillBeRemoved] = useState(false)
    const { deleteArticles } = useDeleteArticles()

    const handleRemovePart = () => {
        setPartWillBeRemoved(true)
        dialogRef.current?.show()
    }

    const handleWarningClose = () => {
        setPartWillBeRemoved(false)
    }

    const handleWarningConfirm = () => {
        const { item, compilationId } = props
        deleteArticles({ compilationId, articleIds: [item.id] })
    }

    const handlePartCheck = () => {
        props.onSelectPart(props.item.id)
    }

    const handleEditModeToggle = () => {
        props.onShowHideEditMode(props.item)
    }

    const handleMoveClick = () => {
        props.onSelectPart(props.item.id, true)
    }

    const { isEditing } = props

    if (props.updatingArticle) {
        return <Loader />
    }

    return (
        <>
            {isEditing ? (
                <>
                    <Button title={translateText(70)} onClick={handleEditModeToggle} size="s" icon="close" className="custom-part__button" />
                    <Button
                        title={translateText(9)}
                        onClick={props.onUpdatePart}
                        size="s"
                        icon="check"
                        skin="success"
                        className="custom-part__button"
                    />
                </>
            ) : (
                <>
                    {isWM() ? (
                        <Button
                            layout={["ghost"]}
                            title={translateText(122)}
                            onClick={handleEditModeToggle}
                            icon="edit_change"
                            size="s"
                            // disabled={item.updatingNote}
                        />
                    ) : (
                        <Button
                            className="move-position__button"
                            title={translateText(12794)}
                            onClick={handleMoveClick}
                            icon="move"
                            size="s"
                            layout={["ghost"]}
                        />
                    )}
                    <Button
                        title={translateText(624)}
                        onClick={handleRemovePart}
                        icon={!partWillBeRemoved ? "delete" : undefined}
                        size="s"
                        layout={["ghost"]}
                    >
                        {partWillBeRemoved && <Loader delay={0} />}
                    </Button>
                    <WarningPrompt
                        text={translateText(833)}
                        confirmationButtonText={translateText(585)}
                        cancelButtonText={translateText(584)}
                        ref={dialogRef}
                        onClose={handleWarningClose}
                        onConfirm={handleWarningConfirm}
                    />
                </>
            )}
            <Checkbox checked={props.isSelected} onToggle={handlePartCheck} />
        </>
    )
}
