import { useLocalization } from "@tm/localization"
import { Button, Box, Icon, Paper, Stack, styled, Typography } from "@tm/components"
import { useMicro } from "@tm/morpheus"
import { Article, IMicros } from "@tm/models"

import { useDefaultErpSystem, useGetDetailedErpInfo } from "@tm/utils"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { ArticleSelectionItem } from "../../list/business"
import { getBundleParams } from "../../../utils"

type Props = {
    articleSelectionItem: ArticleSelectionItem
    showArticleComparision: boolean
    hideBasketButton?: boolean
    maxArticleNumberToCompareReached: boolean
    onToggleSelectAll(selectAll: boolean): void
    onCompareArticles(articles: Array<Article>): void
}

const StyledPaper = styled(Paper)(({ theme }) => {
    const articleSelectionTop = theme.overwrites?.components?.articleSelection?.top

    return {
        position: "absolute",
        zIndex: "100",
        padding: theme.spacing(1, 2),
        top: articleSelectionTop || 0,
        right: "1.5em",
        border: "1px solid transparent",

        "&:hover": {
            border: `1px solid ${theme.colors?.primary}`,
        },
    }
})

export default function ArticleSelection(props: Props) {
    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { erpSystemConfig } = useDefaultErpSystem()
    const { articleSelectionItem, showArticleComparision, hideBasketButton, maxArticleNumberToCompareReached, onToggleSelectAll, onCompareArticles } =
        props
    const { selectedArticles, vehicleId, customerId, foundBySearchTerm } = articleSelectionItem
    const { isLoading, data: erpInfos = [] } = useGetDetailedErpInfo({
        articles: selectedArticles,
        type: "list",
        distributorId: erpSystemConfig?.id || undefined,
        telesalesCustomerNo,
        vehicleId,
        searchQuery: foundBySearchTerm,
    })

    function handleCancelButtonClick() {
        onToggleSelectAll(false)
    }

    function handleCompareArticlesClick() {
        const selectedWithAvailability = selectedArticles.map((article) => {
            return { ...article, availability: erpInfos.find((erp) => article.id === erp.itemId)?.availability.type }
        })
        onCompareArticles(selectedWithAvailability)
    }

    function renderShoppingBasket() {
        return (
            <Box mr={1}>
                {renderMicro("basket", "add-to-basket", {
                    data: selectedArticles,
                    vehicleId,
                    customerId,
                    foundBySearchTerm,
                    disabled: selectedArticles.some((part) => part.isPartsListAvailable && !part.showAddToBasket),
                    erpType: "list",
                })}
            </Box>
        )
    }

    return (
        <StyledPaper elevation={3}>
            <Stack direction="row" spacing={0.5}>
                <Stack direction="row" spacing={1.5} alignItems="center">
                    <Typography
                        variant="label"
                        fontWeight={maxArticleNumberToCompareReached ? "bold" : undefined}
                        color={maxArticleNumberToCompareReached ? "highlight" : undefined}
                    >
                        {translateText(1835).replace("{0}", getBundleParams().maximumPartsToCompare.toString())}
                    </Typography>
                    <Typography variant="label">
                        {selectedArticles.length} {translateText(479)}
                    </Typography>
                    {showArticleComparision && (
                        <Button onClick={handleCompareArticlesClick} disabled={selectedArticles.length <= 1 || isLoading}>
                            {translateText(1817)}
                        </Button>
                    )}
                </Stack>
                {!hideBasketButton && renderShoppingBasket()}
                <Button startIcon={<Icon name="cancel-x" />} title={translateText(317)} onClick={handleCancelButtonClick} />
            </Stack>
        </StyledPaper>
    )
}
