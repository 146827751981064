import { Checkbox } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { AvailabilityFilterType } from "../../../business"
import { getBundleParams } from "../../../utils"
import Filter from "./filter"

type Props = {
    onChange: (filterTyp: AvailabilityFilterType) => void
    onChangeOpen: (open: boolean) => void
    onToggleClip: () => void
    selected: AvailabilityFilterType
    isLoading: boolean
    initiallyOpened: boolean
    showAllFiltersAsClipped: boolean | undefined
}

export function AvailabilityFilter(props: Props) {
    const localization = useLocalization()
    const { availabilityFilterItemsToShow, availabilityFilterItemsToShowSecondary } = getBundleParams()

    return (
        <Filter
            localization={localization}
            name={localization.translateText(412)}
            className="filter--availability"
            onChangeOpen={props.onChangeOpen}
            onToggleClip={props.showAllFiltersAsClipped ? undefined : props.onToggleClip}
            initiallyOpened={props.initiallyOpened}
            loading={props.isLoading}
            key="availability-filter"
        >
            <div className="selection-list  selection-list--s ">
                {!!availabilityFilterItemsToShow?.length && (
                    <Checkbox
                        size="s"
                        label={localization.translateText(1623)}
                        checked={props.selected === AvailabilityFilterType.Primary}
                        onToggle={() => props.onChange(AvailabilityFilterType.Primary)}
                    />
                )}
                {!!availabilityFilterItemsToShowSecondary?.length && (
                    <Checkbox
                        size="s"
                        label={localization.translateText(12860)}
                        checked={props.selected === AvailabilityFilterType.Secondary}
                        onToggle={() => props.onChange(AvailabilityFilterType.Secondary)}
                    />
                )}
            </div>
        </Filter>
    )
}
