import { FC, useState, MouseEvent } from "react"
import { styled, Box, Accordion, MuiAccordionSummary, AccordionDetails } from "@tm/components"
import { Headline, Text, Icon, Checkbox, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { addOrRemoveItem } from "../../../helpers/addOrRemoveItem"
import MuiCustomModal from "./MuiCustomModal"
import { useFastServiceStore } from "../../../data"
import { DefectGenart } from "../../../data/models"

type Props = {
    open: boolean
    onOutsideClick(): void
    onCalculation(ids: number[]): void
}

const FastCalcSelection: FC<Props> = ({ open, onOutsideClick, onCalculation }) => {
    const { translateText } = useLocalization()
    const defectGenarts = useFastServiceStore((state) => state.defectGenarts.groupBy((x) => x.id))

    const [selectedGenarts, setSelectedGenarts] = useState<{ id: string; genartId: number }[]>([])

    const handleSelectGenart = (id: string, genartId: number) => {
        const arr = addOrRemoveItem([...selectedGenarts], { id, genartId })
        setSelectedGenarts(arr)
    }

    const handleSelectGenarts = (ev: MouseEvent<HTMLElement>, genarts: DefectGenart[]) => {
        ev.stopPropagation()
        let arr = [...selectedGenarts]
        if (genarts.every((x) => selectedGenarts.some((y) => y.id === x.id && y.genartId === x.genArtNr))) {
            arr = arr.filter((x) => !genarts.some((y) => y.id === x.id && y.genArtNr === x.genartId))
        } else {
            genarts
                .filter((x) => !arr.some((y) => y.id === x.id && y.genartId === x.genArtNr))
                .forEach((item) => arr.push({ id: item.id, genartId: item.genArtNr }))
        }

        setSelectedGenarts(arr)
    }

    const renderGenarts = (item: DefectGenart, idx: number) => {
        return (
            <StyledDefectsWrapper sx={{ backgroundColor: idx & 1 ? "#dadada" : "#e4e4e4" }} key={idx}>
                <CenterBox>
                    <ReversedIcon name="arrow-long-left-up" size="l" />
                    <Text size="l">{item.label}</Text>
                </CenterBox>
                <StyledCheckBox
                    size="xl"
                    checked={selectedGenarts.some((x) => x.id === item.id && x.genartId === item.genArtNr)}
                    onToggle={() => {
                        handleSelectGenart(item.id, item.genArtNr)
                    }}
                />
            </StyledDefectsWrapper>
        )
    }

    const renderIcon = (status: number) => {
        switch (status) {
            case 0:
                return <StyledIcon className="margin-right" name="info-filled" size="l" />
            case 1:
                return <Image className="margin-right" style={{ width: "1.25em", height: "1.25em" }} url="/styles/base/images/warning-triangle.svg" />
            case 2:
                return <Image className="margin-right" style={{ width: "1.25em", height: "1.25em" }} url="/styles/base/images/no-entry-sign.svg" />
            default:
                return null
        }
    }

    const handleCalculation = () => {
        onCalculation(selectedGenarts.map((x) => x.genartId).distinct())
    }

    const renderSubText = (genart: DefectGenart) => {
        return (
            <Text modifiers="strong" size="l">
                {genart.label}
            </Text>
        )
    }

    const renderAccordion = ([id, genarts]: [string, DefectGenart[]], idx: number) => {
        return (
            <Accordion sx={{ backgroundColor: idx & 1 ? "#ebebeb" : "#e0e0e0" }} key={idx}>
                <AccordtionTitle aria-controls="panel1a-content" id="panel1a-header">
                    <>
                        {renderIcon(genarts[0].status)}
                        <StyledTexts>
                            <Text size="l">{id}</Text>
                            {genarts.map(renderSubText)}
                        </StyledTexts>
                        <StyledCheckBox
                            size="xl"
                            checked={genarts.every((x) => selectedGenarts.some((y) => y.id === x.id && y.genartId === x.genArtNr))}
                            onToggle={(e: MouseEvent<HTMLElement>) => handleSelectGenarts(e, genarts)}
                        />
                    </>
                </AccordtionTitle>
                <AccordionDetails sx={{ padding: 0 }} classes={{ root: 'padding: "1em"' }}>
                    {genarts.map(renderGenarts)}
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <MuiCustomModal open={open} onOutsideClick={onOutsideClick} disabledOkButon={!selectedGenarts.length} handleOkButton={handleCalculation}>
            <StyledModalContent>
                <PaddingBox>
                    <Headline size="m">{translateText(13036)}</Headline>
                    <Text size="m">{translateText(13037)}</Text>
                </PaddingBox>
                <StyledAccordtionWrapper>{Object.entries(defectGenarts).map(renderAccordion)}</StyledAccordtionWrapper>
            </StyledModalContent>
        </MuiCustomModal>
    )
}

export default FastCalcSelection

const StyledModalContent = styled(Box)({
    width: "100%",
    height: "100%",
})

const StyledCheckBox = styled(Checkbox)({
    padding: 0,
})

const PaddingBox = styled(Box)(({ theme }) => ({
    padding: theme.margin?.m ?? ".5em",
}))

const StyledAccordtionWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.margin?.xl ?? "1.5em",
    padding: theme.margin?.m ?? ".5em",
    marginRight: "5em",
}))

const StyledDefectsWrapper = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    padding: "1em",
    paddingLeft: "3.5em",
})

const StyledTexts = styled(Box)({
    display: "flex",
    flex: 1,
    flexDirection: "column",
})

const CenterBox = styled(Box)({
    display: "flex",
    alignItems: "center",
})

// TODO: Check if it can be migrated to the existing customized `AccordionSummary` from @tm/components
const AccordtionTitle = styled((props: any) => <MuiAccordionSummary expandIcon={<Icon name="down" />} {...props} />)(({ theme }) => ({
    flexDirection: "row-reverse",
    gap: "1em",
    "& .MuiAccordionSummary-content": {
        marginLeft: `${theme.margin?.s} !important`,
        justifyContent: "space-between",
        alignItems: "center",

        ".margin-right": {
            marginRight: ".5em",
        },
    },
}))

const ReversedIcon = styled((props: any) => <Icon {...props} />)(() => ({
    opacity: 1,
    transform: "rotate(90deg) scaleX(-1)",
    width: "1.75em",
    height: "1.75em",
}))

const StyledIcon = styled((props: any) => <Icon {...props} />)(({ theme }) => ({
    fill: theme.colors.primary,
}))
