import { Suspense } from "react"

import { Box, Loader } from "@tm/components"
import { getComponentFromBundle } from "@tm/utils"
import { WidgetSizes } from "@tm/controls"

import { WidgetBundle as CatalogWidgetBundle } from "../../../../../../parts/src"
import { WidgetFrame as OffersWidgetFrame } from "../../../../../../offers/src"

import { DisplayType } from "../../../external-catalogs/utils"
import { WidgetNavigationVariant } from "./components/WidgetNavigationVariant"
import ExternalCatalogsWidget from "../../../external-catalogs/component"
import { MultiWidgets } from "../../components/MultiWidgets"

import { AllWidgetKeys, Overrides } from "./models"
import { dashboardTemplateSixChannel } from "./channel"
import { useDashboardTemplateSix } from "./hooks/useDashboardTemplateSix"

const CatalogWidget = getComponentFromBundle(CatalogWidgetBundle, dashboardTemplateSixChannel)
const offersWidgetFrameParams = { async: true, mode: 1, useslider: 1 }

type Props = {
    // this is for customizing the order, visibility of the widgets
    overrides?: Overrides
}

export function DashboardTemplateSix(props: Props) {
    return (
        <Suspense fallback={<Loader />}>
            <DashboardTemplateSixComponent {...props} />
        </Suspense>
    )
}

function DashboardTemplateSixComponent(props: Props) {
    const { overrides } = props

    const {
        widgetModuleList,
        multiWidgetTabs,
        otherModules: { catalogWidgetTree, offersWidget },
        finalOverrides,
    } = useDashboardTemplateSix(overrides)

    const checkOrder = (key: AllWidgetKeys): number | undefined => {
        return finalOverrides?.[key]?.sort
    }

    function getVal<T>(widgetKey: AllWidgetKeys, configKey: string, defaultVal?: T | undefined): T | undefined {
        return finalOverrides?.[widgetKey]?.custom?.[configKey] ?? defaultVal
    }

    return (
        <>
            <Box display="flex" flex="0 0 33.33%" flexDirection="column">
                <MultiWidgets sx={{ order: checkOrder("multiWidget") }} items={multiWidgetTabs} channel={dashboardTemplateSixChannel} />
                {offersWidget && (
                    <OffersWidgetFrame
                        showNormalOffers
                        params={offersWidgetFrameParams}
                        widgetSize="12x3"
                        sx={{ width: "100%", order: checkOrder("offersWidget") }}
                    />
                )}
            </Box>
            <Box display="flex" flex="1" flexWrap="wrap">
                <CatalogWidget
                    singleWidget={false}
                    trees={catalogWidgetTree}
                    sx={{
                        width: "100%",
                        height: "40em",
                        order: checkOrder("catalogWidget"),
                    }}
                />
                <ExternalCatalogsWidget
                    sx={{ order: checkOrder("externalCatalogsWidget") }}
                    size={getVal<WidgetSizes>("externalCatalogsWidget", "size", "12x4")}
                    variableHeight={getVal<boolean>("externalCatalogsWidget", "variableHeight", true)}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    display={getVal<DisplayType>("externalCatalogsWidget", "display", "DASHBOARD")!}
                    itemClassName="widget"
                    listClassName="layout-vertical cols-4"
                    showOnlyGroup={getVal<number>("externalCatalogsWidget", "showOnlyGroup")}
                    showGroupedItemsOnly={getVal<boolean>("externalCatalogsWidget", "showGroupedItemsOnly")}
                />
            </Box>
            <Box display="flex" flexDirection="column">
                {widgetModuleList.map((widgetModule) => (
                    <WidgetNavigationVariant key={widgetModule.moduleKey} overrides={finalOverrides} {...widgetModule} />
                ))}
            </Box>
        </>
    )
}
