import { Fragment } from "react"
import { Divider } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { AdditionalMenuItemComponent, MenuItem, isLinkMenuItem } from "./AdditionalMenuItem"

export type AdditionalMenuItem = MenuItem &
    Partial<MenuItem> & {
        children?: MenuItem[]
    }

type Props = {
    items: AdditionalMenuItem[]
    onClick?(): void
}

function generateKey(item: MenuItem): string {
    if (isLinkMenuItem(item)) {
        return item.path
    }

    if (item.externalModuleConfiguration) {
        return `${item.externalModuleConfiguration.typeId}-${item.externalModuleConfiguration.documentTypeId}`
    }

    return ""
}

function showMenuItem(item: MenuItem, userContext: UserContext): boolean {
    const { parameter, modules } = userContext

    // check if menu item "Prices" should be displayed
    if (
        parameter.purchasePricesDisabled &&
        !modules?.some((x) => x.type === UserModuleType.MarginTool) &&
        isLinkMenuItem(item) &&
        item.path === "/usersettings/prices"
    ) {
        return false
    }

    return true
}

export function AdditionalMenuItems({ items, onClick }: Props) {
    const { userContext } = useUser() ?? {}

    return (
        <>
            {items.map((parent) => (
                <Fragment key={generateKey(parent)}>
                    <AdditionalMenuItemComponent item={parent} onClick={onClick} />
                    {parent.children?.map(
                        (x) =>
                            showMenuItem(x, userContext) && (
                                <AdditionalMenuItemComponent key={generateKey(x)} item={x} isSubMenuItem onClick={onClick} />
                            )
                    )}
                    <Divider />
                </Fragment>
            ))}
        </>
    )
}
