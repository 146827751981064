import { Button, Icon, Text, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"

import { TelematicsDataResponse } from "../../../../data"
import { getBatteryVoltageIconName, getBatteryVoltageIconSkin, VOLTAGE_THRESHOLD_WARNING } from "../../../../data/helper"

type Props = {
    data?: TelematicsDataResponse
    batteryCalculationUrl?: string
    canRequestCalculation?: boolean
}

export default function MiscComponent(props: Props) {
    const { translateText, date, language } = useLocalization()
    const { data, batteryCalculationUrl } = props

    if (!data) {
        return null
    }

    const handleOpenCalculation = () => {
        if (batteryCalculationUrl) {
            Morpheus.showView("1", batteryCalculationUrl)
        }
    }

    const { mileage, nextBrakeFluidChangeDate, batteryVoltage } = data

    return (
        <div className="misc">
            <div className="list">
                {mileage && (
                    <div className="list__cell">
                        <Icon className="list__cell__icon" name="cylinder-raceflag" size="xl" />
                        <Text size="s">{mileage.labelTextMap[language.toLowerCase()]}</Text>
                        <Text modifiers={["strong"]}>{`${mileage.value.format(0)} ${mileage.unit}`}</Text>
                    </div>
                )}
                {nextBrakeFluidChangeDate && (
                    <div className="list__cell">
                        <Icon className="list__cell__icon" name="oil-can" size="xl" />
                        <Text size="s">{nextBrakeFluidChangeDate.labelTextMap[language.toLowerCase()]}</Text>
                        <Text modifiers={["strong"]}>{date(nextBrakeFluidChangeDate.value, "d")}</Text>
                    </div>
                )}
                {batteryVoltage && (
                    <div className="list__cell">
                        <Tooltip position="top" content={batteryVoltage.value < VOLTAGE_THRESHOLD_WARNING ? translateText(934) : ""}>
                            <div>
                                <Icon name="battery" className="list__cell__icon battery-icon" size="xl" />
                                <Icon
                                    className="list__cell__icon"
                                    size="xl"
                                    name={getBatteryVoltageIconName(batteryVoltage.value)}
                                    skin={getBatteryVoltageIconSkin(batteryVoltage.value)}
                                />
                            </div>
                        </Tooltip>
                        <Text size="s">{batteryVoltage.labelTextMap[language.toLowerCase()]}</Text>
                        <Text modifiers={["strong"]}>{`${batteryVoltage.value.format(batteryVoltage.decimalPlaces)} ${batteryVoltage.unit}`}</Text>
                        {batteryVoltage.value < VOLTAGE_THRESHOLD_WARNING && !!batteryCalculationUrl && (
                            <Button icon="fastclick" onClick={handleOpenCalculation} disabled={!props.canRequestCalculation}>
                                {translateText(935)}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
