import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useParams } from "react-router"
import { parseInt } from "lodash"
import { ArticleAttribute, FittingPosition, ListFilter } from "@tm/models"
import { equals } from "@tm/utils"
import { useRecoilState } from "recoil"
import { useArticleListSorting } from "../../hooks/useArticleListSorting"
import { useArticleSelectionActions } from "../../hooks/useArticleSelection"
import { VehiclePartsListParams, VehiclePartsStartParams } from "../../models"
import { mapArticleAttribute } from "../../helpers"
import { AttributeFiltersAtom } from "../../states"
import { useFilterStorageKey } from "../../hooks/useFilterStorageKey"

type AttributeChangeHandlerType = (attribute: ArticleAttribute, selected: boolean) => void

function getStartParamsFromUrl(url: string, searchType: VehiclePartsStartParams["type"]): VehiclePartsStartParams {
    const searchParams = new URLSearchParams(url)

    switch (searchType) {
        case "synonym": {
            const query = searchParams.get("query")

            return {
                type: "synonym",
                query: query || "",
            }
        }
        case "productgroups": {
            const treeId = searchParams.get("treeId")
            const nodeId = searchParams.get("nodeId")
            const productGroupIds = searchParams.get("productGroupIds")

            return {
                type: "productgroups",
                productGroupIds: productGroupIds ? productGroupIds.split(",").map((id) => parseInt(id)) : [],
                treeId: (treeId && parseInt(treeId)) || undefined,
                nodeId: (nodeId && parseInt(nodeId)) || undefined,
            }
        }
        case "node":
        default: {
            const treeId = searchParams.get("treeId")
            const nodeId = searchParams.get("nodeId")

            return {
                type: "node",
                treeId: treeId ? parseInt(treeId) : NaN,
                nodeId: nodeId ? parseInt(nodeId) : NaN,
            }
        }
    }
}

/**
 * Get information about the current article list parameters, status and filter selection and some helper methods to change them.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useListParams(): VehiclePartsListParams {
    const location = useLocation()
    const { searchType } = useParams<{ searchType: VehiclePartsStartParams["type"] }>()

    const sorting = useArticleListSorting()
    const { clear: clearSorting } = sorting

    const [startParams, setStartParams] = useState<VehiclePartsStartParams>(() => getStartParamsFromUrl(location.search, searchType))

    const storageKey = useFilterStorageKey(startParams)
    const [attributeFilters, setAttributeFilters] = useRecoilState(AttributeFiltersAtom(storageKey))

    const [productGroups, setProductGroups] = useState<ListFilter[]>([])
    const [suppliers, setSuppliers] = useState<ListFilter[]>([])
    // const [attributes, setAttributes] = useState<ListFilter[]>([])
    const [extendedAssortment, setExtendedAssortment] = useState(false)
    const [showAvailable, setShowAvailable] = useState(false)
    const [showAvailableSecondary, setShowAvailableSecondary] = useState(false)
    const attributeChangeHandler = useRef<AttributeChangeHandlerType[]>([])
    const [pageIndex, setPageIndex] = useState(0)
    const [noResult, setNoResult] = useState(false)
    const [isFiltersLoading, setIsFiltersLoading] = useState(false)
    const [fittingPosition, setFittingPosition] = useState<FittingPosition>(FittingPosition.None)

    // Relevant for Multiselect, resets on reload, list change or new search
    const { deselectAll } = useArticleSelectionActions()

    useEffect(
        function resetOnStartParamsChanged() {
            const newStartParams = getStartParamsFromUrl(location.search, searchType)
            deselectAll()

            if (!equals(newStartParams, startParams)) {
                setStartParams(newStartParams)
                // setAttributes((prev) => (prev.length ? [] : prev))
                setProductGroups((prev) => (prev.length ? [] : prev))
                setSuppliers((prev) => (prev.length ? [] : prev))
                setExtendedAssortment(false)
                clearSorting()
            }
        },
        [deselectAll, location, searchType, startParams, clearSorting]
    )

    const toggleProductGroup = useCallback((filter: ListFilter) => {
        setProductGroups((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)

            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleSupplier = useCallback((filter: ListFilter) => {
        setSuppliers((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)

            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleAttribute = useCallback(
        (attribute: ArticleAttribute) => {
            setAttributeFilters((state) => {
                const attrFilter = mapArticleAttribute(attribute)
                const existingIndex = state.findIndex((attr) => attr.query === attrFilter.query)

                if (existingIndex === -1) {
                    attributeChangeHandler.current.forEach((x) => x(attribute, true))
                    return [...state, attrFilter]
                }

                attributeChangeHandler.current.forEach((x) => x(attribute, false))

                return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
            })
        },
        [setAttributeFilters]
    )

    const handleChangeExtendedAssortment = useCallback((checked: boolean) => setExtendedAssortment(checked), [])
    const setAvailability = useCallback((checked: boolean) => setShowAvailable(checked), [])
    const setAvailabilitySecondary = useCallback((checked: boolean) => setShowAvailableSecondary(checked), [])

    const nextPage = useCallback(() => {
        setPageIndex((index) => index + 1)
    }, [])

    return {
        startParams,
        pageIndex,
        productGroups,
        suppliers,
        attributes: attributeFilters,
        fittingPosition,
        extendedAssortment,
        showAvailable,
        showAvailableSecondary,
        noResult,
        isFiltersLoading,
        setProductGroups,
        setSuppliers,
        setAttributes: setAttributeFilters,
        toggleProductGroup,
        toggleSupplier,
        toggleAttribute,
        setExtendedAssortment: handleChangeExtendedAssortment,
        setAvailability,
        setAvailabilitySecondary,
        setNoResult,
        setIsFiltersLoading,
        setFittingPosition,
        nextPage,
        sorting,
    }
}
