import { ReactNode, useEffect } from "react"
import { Link, matchPath } from "react-router-dom"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { Container, IModelContainer, ContainerAction } from "@tm/nexus"
import { RegisteredModels, CategoryType, ViewStateContainer, ConfigParams, channel, PartsRoutes } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { decodeUniqueId, encodeUniqueId, renderRoute, RouteComponentProps, TmaHelper, uniqueId, withRouter, getCategoryTypeFromUrl } from "@tm/utils"
import { Button, Toolbar, Headline, Dropdown, Text, Switch, ButtonLayout } from "@tm/controls"
import { useUser, useWorkTask } from "@tm/context-distribution"

import { LinkTab, Tabs } from "@tm/components"
import { SectionType } from "../../business"
import { useUniSearchStatus } from "../../helper"
import { NavigationState, IActions, Actions, SectionUrl } from "./business"
import { getBundleParams } from "../../utils"

type Props = ConfigProps &
    RouteComponentProps<RouteParams> & {
        actions: IActions
        state: NavigationState
    }

type RouteParams = {
    workTaskId: string
    view?: string
}

type ConfigProps = {
    areasAsDropdown?: boolean
    areasAsTabs?: boolean
    alwaysListRoute?: boolean
    hideAreas?: boolean
    hidePageButtons?: boolean

    routes: PartsRoutes
}

type NavigationItem = {
    categoryType: CategoryType
    text: ReactNode
    disabled?: boolean
}

function NavigationComponent(props: Props) {
    const { userContext, userSettings, setUserSetting } = useUser()
    const { workTask } = useWorkTask() || {}
    const { translateText, translate } = useLocalization()
    const uniSearchIsActive = useUniSearchStatus()

    const {
        areasAsDropdown,
        areasAsTabs,
        hideAreas,
        alwaysListRoute,
        routes,
        state: { urls },
        match,
        location,
        hidePageButtons,
    } = props

    const { workTaskId } = props.match.params

    const ViewState: IModelContainer<ViewStateContainer, ContainerAction> = Container.getInstance<ViewStateContainer>(RegisteredModels.ViewState)

    useEffect(() => {
        if (!props.hideAreas && (!props.routes || !props.routes.vehicleParts || !props.routes.universalParts || !props.routes.directSearch)) {
            console.error(`Parts-Navigation: 'routes' was not passed as property in the app config or doesn't contain valid routes`)
        }

        props.actions.setUrls({})

        ViewState.action("loadViewState")(`${workTaskId}__ACTIVE_URLS`).then((response) => {
            let responseUrls: Record<string, Record<string, SectionUrl>> = {}
            if (response?.value) {
                responseUrls = response.value
            }

            const { view } = match.params

            if (view) {
                const activeCategoryType = getCategoryTypeFromUrl(match.url)

                if (!responseUrls[activeCategoryType]) {
                    responseUrls[activeCategoryType] = {}
                }

                // Die aktuelle URL sollte die gespeicherte überschreiben
                responseUrls[activeCategoryType][view] = {
                    isActive: true,
                    url: `${location.pathname}${location.search || ""}`,
                }
            }
            props.actions.setUrls(responseUrls)
        })
    }, [])

    useEffect(() => {
        if (urls && workTaskId) {
            ViewState.action("saveViewState")({ key: `${workTaskId}__ACTIVE_URLS`, value: urls })
        }
    }, [ViewState, urls, workTaskId])

    const getCategoryUrl = (categoryType: CategoryType, fallbackSectionType: SectionType): string => {
        const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
        const categoryUrls = urls[categoryType]

        if (categoryUrls) {
            let activeSectionUrl: string | undefined

            Object.keys(categoryUrls)
                .map((key) => {
                    return { type: key, url: categoryUrls[key] }
                })
                .forEach((x) => {
                    if (alwaysListRoute || articleDetailsInModal) {
                        if (x.type === "list") {
                            activeSectionUrl = x.url.url
                        }
                        return
                    }
                    if (x.url.isActive) {
                        activeSectionUrl = x.url.url
                    }
                })

            if (activeSectionUrl) {
                // check if the saved url has the same worktask id as current worktask, if not use the initial url
                // a quick fix for https://jira.dvse.de/browse/NEXT-25560
                // it needs more time to find out on which point the state breaks
                // also we don't need to save the worktask id in the state, but this is also a bigger refactoring!
                const currentWorktaskId = encodeUniqueId(workTask?.id || uniqueId())

                const [activeSectionUrlWithoutQuery, activeSectionUrlQuery] = activeSectionUrl.split("?")

                const baseRoute = `${routes[categoryType][fallbackSectionType]}/:searchType?`

                const savedMatch = matchPath<{ workTaskId?: string }>(activeSectionUrlWithoutQuery, {
                    path: baseRoute,
                    exact: true,
                    strict: false,
                })

                if (savedMatch?.params?.workTaskId !== currentWorktaskId) {
                    activeSectionUrl = renderRoute(baseRoute, match.params)

                    if (activeSectionUrlQuery) {
                        activeSectionUrl += `?${activeSectionUrlQuery}`
                    }
                }

                return activeSectionUrl

                // different soulution, replace the worktaskid with the current and append the querys, more logic
                // let urlWithCorrectWorktaskId = renderRoute(routes[categoryType][fallbackSectionType], {
                //     ...props.match.params,
                //     workTaskId: currentWorktaskId,
                // })

                // if (activeSectionUrl.length > urlWithCorrectWorktaskId.length) {
                //     const indexOfLastBracket = activeSectionUrl.lastIndexOf("/")
                //     const additionals = activeSectionUrl.substring(indexOfLastBracket, activeSectionUrl.length)
                //     urlWithCorrectWorktaskId += additionals
                // }

                // return urlWithCorrectWorktaskId
            }
        }
        return renderRoute(routes[categoryType][fallbackSectionType], match.params)
    }

    const getSectionUrl = (categoryType: CategoryType, sectionType: SectionType, useFallback?: boolean): string | undefined => {
        const categoryUrls = urls[categoryType]

        if (categoryUrls) {
            const sectionUrl = categoryUrls[sectionType]

            if (sectionUrl) {
                return sectionUrl.url
            }
        }

        if (useFallback) {
            return renderRoute(routes[categoryType][sectionType], match.params)
        }
    }

    const logTmaCategoryChange = (categoryType: CategoryType) => {
        TmaHelper.Shared.ByArticleAndUniParts.CategoryChanged(categoryType)
    }

    const handleSwitchPurchasePrice = (showPurchasePrice: boolean) => {
        setUserSetting("SHOW_PURCHASE_PRICE", showPurchasePrice)
    }

    const canShowSwitch = (): boolean => {
        const externalModules = userContext?.externalModules

        let isInfoEnabled = false
        if (externalModules) {
            externalModules.forEach((externalModule) => {
                if (externalModule.isInfoEnabled) {
                    isInfoEnabled = true
                }
            })
        }
        return isInfoEnabled
    }

    const renderTextItem = (item: NavigationItem) => {
        return <Text>{item.text}</Text>
    }

    const renderLinkItem = (item: NavigationItem) => {
        if (item.disabled) {
            return renderTextItem(item)
        }
        const activeCategoryType = getCategoryTypeFromUrl(match.url)

        const onClickHandler = () => {
            logTmaCategoryChange(item.categoryType)
            channel("WORKTASK", decodeUniqueId(workTaskId)).publish("PARTS/CATEGORY_CHANGED", {
                category: item.categoryType,
            })
        }

        return (
            <Link to={getCategoryUrl(item.categoryType, "list")} onClick={onClickHandler}>
                <Text modifiers={item.categoryType === activeCategoryType ? "highlight" : undefined}>{item.text}</Text>
            </Link>
        )
    }

    const renderAreaButtons = () => {
        const { areaButtonLayout } = getBundleParams()

        if (hideAreas) {
            return
        }

        const activeCategoryType = getCategoryTypeFromUrl(match.url)

        if (areasAsDropdown !== false && !areasAsTabs) {
            const items: Array<NavigationItem> = [
                { categoryType: "vehicleParts", text: translate(449), disabled: !workTask?.vehicle || userContext?.parameter?.catalogLight },
                { categoryType: "directSearch", text: translate(389) },
            ]

            if (uniSearchIsActive) {
                items.push({
                    categoryType: "universalParts",
                    text: translate(1009),
                } as NavigationItem)
            }

            return (
                <Dropdown<NavigationItem>
                    isActive
                    items={items}
                    layout={["holo"]}
                    value={items.find((x) => x.categoryType === activeCategoryType)}
                    itemView={renderLinkItem}
                    displayView={renderTextItem}
                />
            )
        }

        if (areasAsTabs) {
            return (
                <Tabs value={activeCategoryType}>
                    <LinkTab
                        component={Link}
                        value="vehicleParts"
                        label={translateText(449)}
                        to={getCategoryUrl("vehicleParts", "list")}
                        disableRipple
                    />
                    <LinkTab
                        component={Link}
                        value="directSearch"
                        label={translateText(389)}
                        to={getCategoryUrl("directSearch", "list")}
                        disableRipple
                    />
                    {uniSearchIsActive && (
                        <LinkTab
                            component={Link}
                            value="universalParts"
                            label={translateText(1009)}
                            to={getCategoryUrl("universalParts", "list")}
                            disableRipple
                        />
                    )}
                </Tabs>
            )
        }

        const buttonLayout: ButtonLayout = areaButtonLayout || "ghost"

        return (
            <div className={`area-buttons area-buttons--${buttonLayout}`}>
                <Button
                    linkTo={getCategoryUrl("vehicleParts", "list")}
                    disabled={!workTask?.vehicle || userContext?.parameter?.catalogLight}
                    layout={[buttonLayout]}
                    className={activeCategoryType === "vehicleParts" ? "is-active" : ""}
                    skin={activeCategoryType === "vehicleParts" ? "primary" : undefined}
                    onClick={() => logTmaCategoryChange("vehicleParts")}
                >
                    {translate(449)}
                </Button>
                <Button
                    linkTo={getCategoryUrl("directSearch", "list")}
                    layout={[buttonLayout]}
                    className={activeCategoryType === "directSearch" ? "is-active" : ""}
                    skin={activeCategoryType === "directSearch" ? "primary" : undefined}
                    onClick={() => logTmaCategoryChange("directSearch")}
                >
                    {translate(389)}
                </Button>
                {uniSearchIsActive && (
                    <Button
                        linkTo={getCategoryUrl("universalParts", "list")}
                        layout={[buttonLayout]}
                        className={activeCategoryType === "universalParts" ? "is-active" : ""}
                        skin={activeCategoryType === "universalParts" ? "primary" : undefined}
                        onClick={() => logTmaCategoryChange("universalParts")}
                    >
                        {translate(1009)}
                    </Button>
                )}
            </div>
        )
    }

    const renderPageButtons = () => {
        const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
        if (hidePageButtons || articleDetailsInModal) {
            return
        }

        const activeCategoryType = getCategoryTypeFromUrl(match.url)

        const listUrl = getSectionUrl(activeCategoryType, "list", true)
        const detailsUrl = getSectionUrl(activeCategoryType, "details")

        let listIsActive = false
        let detailsIsActive = false

        if (match.params.view) {
            listIsActive = match.params.view === "list"
            detailsIsActive = match.params.view === "details"
        }

        return (
            <div className="page-buttons">
                <Button linkTo={listUrl} className={listIsActive ? "is-active" : ""} skin={listIsActive ? "primary" : undefined} icon="menu">
                    {translate(6)}
                </Button>
                <Button
                    linkTo={detailsUrl}
                    disabled={!detailsUrl}
                    className={detailsIsActive ? "is-active" : ""}
                    skin={detailsIsActive ? "primary" : undefined}
                    icon="details"
                >
                    {translate(43)}
                </Button>
            </div>
        )
    }

    let className = "tk-parts navigation"
    if (areasAsDropdown !== false) {
        className += " navigation--dropdown"
    }

    const inDetails = !!window.location.pathname.includes("details")

    const categoryType = getCategoryTypeFromUrl(props.match.url)
    return (
        <div className={className}>
            <Headline size="s">{translate(categoryType !== "offerParts" ? 993 : 1276)}</Headline>
            {!hideAreas && (
                <Toolbar title={translateText(176)}>
                    {renderAreaButtons()}
                    {renderPageButtons()}
                </Toolbar>
            )}
            {inDetails && canShowSwitch() && !userContext?.parameter.purchasePricesDisabled ? (
                <Toolbar title={translateText(1132)}>
                    <Switch status={userSettings?.showPurchasePrice} onChange={handleSwitchPurchasePrice} />
                </Toolbar>
            ) : null}
        </div>
    )
}

export default connectComponent(Actions, withRouter(NavigationComponent))
