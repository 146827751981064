import { Box, List, ListItem, ListItemButton, ListSubheader, styled, listSubheaderClasses, listItemButtonClasses } from "@tm/components"
import { AttributeFilterModel } from "@tm/models"
import { useMemo } from "react"
import { useGetFittingSideText } from "../../../../../../helper"
import FilterItem from "../FilterItem"

const GroupList = styled(List, { shouldForwardProp: (prop) => prop !== "hasSubheader" })<{ hasSubheader: boolean }>(({ theme, hasSubheader }) => ({
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: hasSubheader ? 0 : undefined,
    "&:first-of-type": {
        borderLeft: "none",
    },
    [`.${listSubheaderClasses.root}`]: {
        fontSize: theme.typography.body3.fontSize,
        fontFamily: "Roboto Condensed",
        lineHeight: 1.2,
        padding: `${theme.spacing()} ${theme.spacing(2)}`,
        textTransform: "uppercase",
        textAlign: "center",
    },
    [`.${listItemButtonClasses.root}`]: {
        padding: `${theme.spacing()} ${theme.spacing(2)}`,
        display: "block",
        textAlign: "center",
    },
}))

type GroupedSelectOptionsProps = {
    options: AttributeFilterModel[]
    onSelect(filter: AttributeFilterModel): void
}

export default function GroupedSelectOptions(props: GroupedSelectOptionsProps) {
    const { options, onSelect } = props

    const getFittingSideText = useGetFittingSideText()

    const groupedOptions = useMemo(() => {
        return options.orderBy((o) => o.fittingSide).groupBy((o) => getFittingSideText(o.fittingSide))
    }, [options, getFittingSideText])

    const groups = Object.entries(groupedOptions)

    return (
        <Box display="grid" gridTemplateColumns={Array(groups.length).fill("1fr").join(" ")}>
            {groups.map(([group, opts]) => (
                <GroupList key={`group_${group}`} hasSubheader={!!group}>
                    {!!group && <ListSubheader>{group}</ListSubheader>}
                    {opts.map((item) => (
                        <ListItem key={item.value} disablePadding>
                            <ListItemButton onClick={() => onSelect(item)}>
                                <FilterItem filter={item} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </GroupList>
            ))}
        </Box>
    )
}
