import { AddCustomPartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useEnableCostEstimation } from "../../../../hooks/basketState/useEnableCostEstimation"

export function useAddCustomPartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { enableCostEstimation } = useEnableCostEstimation()

    const { mutateAsync: addCustomPartList } = useMutation(
        (request: { addCustomPartListRequest: AddCustomPartListRequest }) => Data.addCustomPartList(request.addCustomPartListRequest),
        {
            onSuccess: async (response, request) => {
                if (response) {
                    await enableCostEstimation(request.addCustomPartListRequest.workTaskId)

                    handleBasketUpdateWorkflow(request.addCustomPartListRequest.workTaskId, response)
                }
                // Save work task in the DB
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
            },
        }
    )

    const addCustomParts = useCallback(
        (request: AddCustomPartListRequest) => {
            return addCustomPartList({ addCustomPartListRequest: request })
        },
        [addCustomPartList]
    )

    return addCustomParts
}
