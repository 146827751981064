import { useLocalization } from "@tm/localization"
import { Toolbar, Button } from "@tm/controls"
import { bundleChannel } from "../../business"
import { useApplyButtonState } from "../../data/hooks/useApplyButtonState"

const TransitionsComponent = () => {
    const { translate } = useLocalization()
    const { dataChanged, valid } = useApplyButtonState()

    const handleAttach = () => {
        bundleChannel().publish("APPLY_CHANGES", {})
    }

    return (
        <Toolbar className="tk-crm transitions">
            <Button id="crm__apply-button" skin="success" size="l" onClick={handleAttach} disabled={!valid}>
                {dataChanged ? translate(52) : translate(22)}
            </Button>
        </Toolbar>
    )
}

export default TransitionsComponent
