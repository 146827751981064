import { Box, Stack, styled, SxProps, Theme, Typography } from "@tm/components"

function getBackgroundColor(theme: Theme, lightMode: boolean): string {
    return lightMode ? theme.palette.common.white : theme.palette.common.black
}

function getForegroundColor(theme: Theme, lightMode: boolean): string {
    return theme.palette.getContrastText(getBackgroundColor(theme, lightMode))
}

function getOverwrites(theme: Theme, colorOverwrites: "background" | "foreground") {
    switch (colorOverwrites) {
        case "background":
            return theme?.overwrites?.components?.erpTours?.backgroundColor
        case "foreground":
            return theme?.overwrites?.components?.erpTours?.foregroundColor
        default:
    }
}

// Styled Components for Tour.tsx
export const TourIdContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "sx" && prop !== "lightMode" })<{
    sx?: SxProps
    lightMode: boolean
}>(({ lightMode, theme }) => {
    return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: getOverwrites(theme, "background") ?? getBackgroundColor(theme, lightMode),
        borderRadius: "3px",
        margin: "0 2px",
        padding: "1px 3px",
    }
})

export const TourIdTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== "isNextDay" && prop !== "lightMode" })<{
    isNextDay: boolean
    lightMode: boolean
}>(({ isNextDay, lightMode, theme }) => ({
    fontWeight: 700,
    color:
        getOverwrites(theme, "foreground") ??
        (isNextDay ? theme.overwrites?.components?.erpTours?.warningColor ?? theme.palette.warning.main : getForegroundColor(theme, lightMode)),
}))

// Styled Components for NextTours.tsx
export const ToursContainerStack = styled(Stack, { shouldForwardProp: (prop) => prop !== "lightMode" })<{ lightMode: boolean }>(
    ({ lightMode, theme }) => {
        return {
            flexDirection: "row",
            border: `1px solid ${getOverwrites(theme, "background") ?? getBackgroundColor(theme, lightMode)}`,
            borderRadius: "3px",
            maxHeight: "50px",
            opacity: "0.87",
        }
    }
)

export const FirstDateContainer = styled(Stack, { shouldForwardProp: (prop) => prop !== "lightMode" })<{ lightMode: boolean }>(
    ({ lightMode, theme }) => {
        return {
            backgroundColor: getOverwrites(theme, "background") ?? getBackgroundColor(theme, lightMode),
            flexDirection: "row",
            padding: "2px",
        }
    }
)

export const FirstDateTriangle = styled(Box, { shouldForwardProp: (prop) => prop !== "lightMode" })<{ lightMode: boolean }>(
    ({ lightMode, theme }) => {
        return {
            backgroundColor: getOverwrites(theme, "background") ?? getBackgroundColor(theme, lightMode),
            clipPath: "polygon(0 0, 0% 100%, 100% 50%)",
            height: "49px",
            width: "23px",

            /* Fix for strange Line on the left side of the triangle:
        https://stackoverflow.com/questions/51542919/clip-path-on-chrome-leaves-a-strange-line-on-the-edge */
            transform: "skewY(0.001deg)",
        }
    }
)

export const FirstDateDay = styled(Typography, { shouldForwardProp: (prop) => prop !== "lightMode" })(({ lightMode, theme }) => {
    return {
        color: getOverwrites(theme, "foreground") ?? getForegroundColor(theme, lightMode),
        fontSize: "10px",
        alignSelf: "flex-end",
    }
})
FirstDateDay.defaultProps = { fontWeight: "bold" }

export const TourTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== "lightMode" })(({ lightMode, theme }) => {
    return {
        // we use the background color as text color, since this text is displayed directly on the header
        color: getOverwrites(theme, "foreground") ?? getBackgroundColor(theme, lightMode),
        margin: "0 2px",
    }
})
TourTypography.defaultProps = { fontWeight: "bold", variant: "body3" }
