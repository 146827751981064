import { RepairTimeProviderConfig } from "@tm/models"
import { atom, useRecoilState } from "recoil"

const repairTimeProvidersAtom = atom<{ [key: string]: RepairTimeProviderConfig }>({
    key: "basket_repairTimeProvidersAtom",
    default: {},
})

export function useRepairTimeProviders() {
    const [state, setState] = useRecoilState(repairTimeProvidersAtom)
    return { repairTimeProviders: state, setRepairTimeProviders: setState }
}
