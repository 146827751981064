import { memo, useCallback, useState } from "react"
import { Box, DefaultIcon as MuiIcon, styled } from "@tm/components"
import { Button, FileDropField, FileSelectError, Icon, Loader, Text, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getAdjustedValueFromDataUri } from "@tm/utils"
import { useImportNote } from "../../../data/hooks/useImportNote"
import UserSettingsArea from "./UserSettingsArea"

const FileBox = styled(Box)(() => ({
    width: "450px",
}))

const ClickableIcon = styled(MuiIcon, {
    shouldForwardProp: (prop) => prop !== "canBeClicked",
})<{ canBeClicked: boolean }>(({ canBeClicked }) => ({
    cursor: canBeClicked ? "pointer" : "inherit",
}))

type UploadErrorType = FileSelectError | "GENERIC"
const MAX_FILE_SIZE = 10000

export const NoteUpload = memo(() => {
    const [file, setFile] = useState<{ data: string; name: string } | null>()
    const [uploadError, setUploadError] = useState<UploadErrorType | null>()
    const { importFile, importInProgress, importError, isSuccess } = useImportNote()
    const { translateText } = useLocalization()

    const onFileLoad = useCallback((data: string, name: string) => {
        setUploadError(null)
        setFile({
            data,
            name,
        })
    }, [])

    const onFileLoadError = useCallback((type: FileSelectError) => {
        switch (type) {
            case "FILE_TOO_BIG":
            case "WRONG_FILE_TYPE": {
                setUploadError(type)
                break
            }
            default: {
                setUploadError("GENERIC")
                break
            }
        }
    }, [])

    const handleStartUpload = useCallback(async () => {
        if (file && !importInProgress) {
            await importFile(getAdjustedValueFromDataUri(file.data))
        }
    }, [file, importFile, importInProgress])

    const onCloseIconClick = useCallback(() => {
        if (isSuccess) {
            return
        }

        setFile(null)
        setUploadError(null)
    }, [isSuccess])

    const translateErrormessage = useCallback((): string => {
        if (!uploadError) {
            return ""
        }

        switch (uploadError) {
            case "WRONG_FILE_TYPE": {
                return translateText(12877)
            }
            case "FILE_TOO_BIG": {
                return translateText(12798).replace("{0}", `${MAX_FILE_SIZE / 1000}`)
            }
            case "GENERIC": {
                return translateText(787)
            }
            default: {
                return ""
            }
        }
    }, [uploadError, translateText])

    const renderFileIcon = useCallback(() => {
        if (!file) {
            return
        }

        if (file && !isSuccess && !importError) {
            return <Icon name="close" size="s" />
        }
        if (importError) {
            return <Icon skin="danger" name="alert" size="s" />
        }
        if (file && !importInProgress && isSuccess) {
            return <Icon skin="success" name="check" size="s" />
        }
    }, [file, isSuccess, importError, importInProgress])

    return (
        <UserSettingsArea id="user-settings__area__article-note" title={translateText(12879)}>
            <Text>{translateText(12880)}</Text>
            <Box className="user-settings__area__row" flexDirection="column" pt={2}>
                <FileDropField allowPaste allowedFileTypes={["blobBin"]} maxFileSize={MAX_FILE_SIZE} onError={onFileLoadError} onLoad={onFileLoad}>
                    <Box margin="0 auto" textAlign="center">
                        <Button fakeButton>{translateText(1163)}</Button>
                        <Box>
                            <Text size="s">{translateText(12789)}</Text>
                            {uploadError && (
                                <Box pt={1}>
                                    <Text size="s" modifiers={["danger"]}>
                                        {translateErrormessage()}
                                    </Text>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </FileDropField>
                {file && (
                    <FileBox display="flex" justifyContent="space-between" p={1}>
                        <Box>
                            <Text size="s" modifiers={["serif"]}>
                                {file.name}
                            </Text>
                        </Box>
                        <Box>
                            <ClickableIcon canBeClicked={!isSuccess} onClick={onCloseIconClick}>
                                <Tooltip content={isSuccess ? translateText(360) : translateText(624)} style="light" event="click">
                                    {renderFileIcon()}
                                </Tooltip>
                            </ClickableIcon>
                        </Box>
                    </FileBox>
                )}
            </Box>
            <Box pt={1}>
                <Button
                    icon="upload"
                    className="user-settings__dms-settings__download__button"
                    onClick={handleStartUpload}
                    disabled={!file || importInProgress}
                >
                    <Box display="flex">
                        {translateText(12876)}{" "}
                        {importInProgress && (
                            <Box pl={1}>
                                <Loader />
                            </Box>
                        )}
                    </Box>
                </Button>
                {importError && file && (
                    <Box pl={1}>
                        <Text size="s" modifiers={["danger"]}>
                            {translateText(787)}
                        </Text>
                    </Box>
                )}
                {file && (
                    <Box>
                        <Text>{translateText(12878)}</Text>
                    </Box>
                )}
            </Box>
        </UserSettingsArea>
    )
})
