import { HasRepairTimesResponse, RepairTimeProvider, VehicleType } from "@tm/models"
import { getRepairTimeProvidersByNames } from "@tm/utils"
import { useMemo } from "react"
import { QueryFunctionContext, useQuery } from "react-query"
import * as RepairTimesRepository from "../../../../../repair-times/src/data/repositories/repair-times"
import { getBundleParams } from "../../../utils"
import { ProductGroupRepairTimesData } from "../models"
import { useVehicle } from "./useVehicle"

const QUERY_KEY = "useProductGroupRepairTimes"

type QueryKey = [typeof QUERY_KEY, VehicleType | undefined, number | undefined, number[], RepairTimeProvider[]]

/**
 * Get a dictionary containg each repair time provider that has data for the current product group.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useProductGroupRepairTimes(productGroupIds: number[], isEnabled: boolean): ProductGroupRepairTimesData {
    const vehicle = useVehicle()

    const providers = useMemo(() => getRepairTimeProvidersByNames(getBundleParams().awProviders.map((x) => x.id)), [])

    const queryResult = useQuery({
        enabled: isEnabled && !!vehicle && !!providers.length && !!productGroupIds.length,
        queryKey: [QUERY_KEY, vehicle?.vehicleType, vehicle?.tecDocTypeId, productGroupIds, providers],
        queryFn: fetchProductGroupRepairTimes,
    })

    return {
        isLoading: queryResult.isFetching,
        productGroupRepairTimes: queryResult.data,
    }
}

function fetchProductGroupRepairTimes({
    queryKey: [, vehicleType, modelId, productGroupIds, providers],
}: QueryFunctionContext<QueryKey>): Promise<HasRepairTimesResponse | undefined> {
    if (!vehicleType || !modelId) {
        return Promise.reject()
    }

    return RepairTimesRepository.hasRepairTimes({
        vehicleType,
        modelId,
        productGroupIds,
        repairTimeProvider: providers,
    })
}
