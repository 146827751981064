import { NotesButton, NumberSelect } from "@tm/components"
import { Loader, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ArticleInfoType, IMicros, NoteTypes } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { useOpenArticleDetails } from "@tm/utils"
import { useCallback, useMemo, useState } from "react"
import { CompilationShowViewModelItem } from "../../../../../data"
import { useArticleInfos } from "../../../../../data/hooks/useArticleInfos"
import { useUpdateArticle } from "../../../../../data/hooks/useCompilations"
import { mapCompilationShowViewModelItemToArticle } from "../../../../../data/mapper"
import { getBundleParams, isWM } from "../../../../../utils"
import ArticleNumbers from "./ArticleNumbers"
import PartActions from "./part-actions"
import { PartListItemImage } from "./part-list-item-image"
import PartsNote from "./parts-note"

type Props = {
    partItem: CompilationShowViewModelItem
    compilationId: string
    isEditing: boolean
    isSelected: boolean
    onSelectPart(partId: string, moveMode?: boolean): void
    onShowHideEditMode(item: CompilationShowViewModelItem): void
}

export default function PartsListItemComponent({ partItem, isEditing, compilationId, onShowHideEditMode, onSelectPart, isSelected }: Props) {
    const { id, description, wholesalerArticleNumber, supplierArticleNumber, note, quantity, minOrderQuantity, productGroupId, supplierId } = partItem
    const { partsDetailsModalUrl } = getBundleParams()

    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const { updateArticle, updatingArticle } = useUpdateArticle()
    const articleInfo = useArticleInfos(partItem)

    const [noteExpanded, setNoteExpanded] = useState(false)
    const [customerArticleNumber, setCustomerArticleNumber] = useState(partItem.customerArticleNumber)

    const article = useMemo(() => mapCompilationShowViewModelItemToArticle(partItem) as Article, [partItem])
    const basketArticles = useMemo(() => (article ? [article] : []), [article])

    const handleOpenArticleDetails = useOpenArticleDetails({
        productGroupId,
        supplierId,
        supplierArticleNumber,
        partsDetailsUrl: partsDetailsModalUrl,
        openModal: Morpheus.showView,
    })

    const handleOpenStocks = useCallback(() => {
        handleOpenArticleDetails("stocks")
    }, [handleOpenArticleDetails])

    function handleChangeCustomerArticleNumber(value: string | undefined) {
        setCustomerArticleNumber(value)
    }

    function handleChangeQuantity(quantity: number) {
        updateArticle({
            compilationId,
            articleId: id,
            quantity,
            description,
            wholesalerArticleNumber,
            supplierArticleNumber,
            note,
            customerArticleNumber,
        })
    }

    function handleSaveNote(note: string) {
        updateArticle({
            compilationId,
            articleId: id,
            quantity: quantity.value,
            description,
            wholesalerArticleNumber,
            supplierArticleNumber,
            note,
            customerArticleNumber,
        })

        setNoteExpanded(false)
    }

    function handleUpdateCustomerArticleNumber() {
        updateArticle({
            compilationId,
            articleId: id,
            quantity: quantity.value,
            description,
            wholesalerArticleNumber,
            supplierArticleNumber,
            note,
            customerArticleNumber,
        })

        onShowHideEditMode(partItem)
    }

    function handleSelectNote() {
        setNoteExpanded(!noteExpanded)
    }

    function renderDescription() {
        return (
            <div className="description">
                <Text size="s">{articleInfo?.productGroup?.name || description || ""}</Text>
                <Text size="s">{articleInfo?.description || ""}</Text>
            </div>
        )
    }

    function renderCustomerArticleNumber() {
        let content

        if (updatingArticle) {
            content = <Loader />
        } else if (isEditing) {
            content = (
                <TextField
                    maxLength={50}
                    className="part-editor__articlenumber"
                    label={translateText(1305).toUpperCase()}
                    floatingLabel
                    value={customerArticleNumber}
                    onChange={handleChangeCustomerArticleNumber}
                />
            )
        } else {
            content = (
                <>
                    <Text modifiers={["sub"]} size="xs" className="price__type">
                        {translateText(1305)}
                    </Text>
                    <Text>{customerArticleNumber}</Text>
                </>
            )
        }

        return <div className="customer-number">{content}</div>
    }

    function renderNote() {
        if (updatingArticle) {
            return <Loader />
        }

        return (
            <NotesButton
                articleNotes={[
                    {
                        type: NoteTypes.ARTICLE,
                        message: note,
                        title: translateText(12874),
                    },
                ]}
                iconOnly
                hasNote={!!note}
                onClick={handleSelectNote}
            />
        )
    }

    function renderPartsNote() {
        if (!noteExpanded) {
            return null
        }

        return <PartsNote note={note || ""} itemCommentMaxLength={30} onSaveNote={handleSaveNote} />
    }

    function renderQuantity() {
        return (
            <NumberSelect
                value={quantity?.value}
                classNameWrapper="add-to-basket__quantity"
                onValueChange={handleChangeQuantity}
                minAmount={minOrderQuantity || quantity?.minValue || 1}
                maxAmount={quantity?.maxValue || 9999}
                steps={quantity?.division || undefined}
                disabled={updatingArticle}
            />
        )
    }

    function renderAvailability() {
        return renderMicro("erp", "compilation-availability", {
            data: article,
            onClick: handleOpenStocks,
        })
    }

    function renderPurchasePrice() {
        return renderMicro("erp", "compilation-prices", {
            data: article,
            hideTotalPrice: !isWM(),
        })
    }

    function renderAddToBasket() {
        return renderMicro("basket", "add-to-basket", {
            data: basketArticles,
            articleInfoType: getArticleInfoType(article),
            hideQuantityField: true,
        })
    }

    function getArticleInfoType(article: Partial<Article>): ArticleInfoType | undefined {
        if (!article.supplier?.id || isWM()) {
            return ArticleInfoType.WholesalerArticle
        }

        if (!article.traderArticleNo) {
            return ArticleInfoType.CustomArticle
        }
    }

    function renderActions() {
        return (
            <PartActions
                item={partItem}
                compilationId={compilationId}
                isEditing={isEditing}
                isSelected={isSelected}
                updatingArticle={updatingArticle}
                onUpdatePart={handleUpdateCustomerArticleNumber}
                onSelectPart={onSelectPart}
                onShowHideEditMode={onShowHideEditMode}
            />
        )
    }

    const renderPartsForWM = () => {
        return (
            <>
                {renderAvailability()}
                <div className="quantity">{renderQuantity()}</div>
                {renderPurchasePrice()}
                <div className="add-to-basket no-separator">{renderAddToBasket()}</div>
                <div className="note no-separator">{renderNote()}</div>
            </>
        )
    }

    const renderPartsDefault = () => {
        return (
            <>
                {renderAvailability()}
                {renderPurchasePrice()}
                <div className="quantity">{renderQuantity()}</div>
                <div className="add-to-basket no-separator">{renderAddToBasket()}</div>
                <div className="note no-separator">{renderNote()}</div>
            </>
        )
    }

    return (
        <div className="list__item part-list__item">
            <div className="part-list__properties article-list">
                <PartListItemImage articleInfo={articleInfo} />
                <div className="manufacturer">
                    <Text>{articleInfo?.supplier?.name || ""}</Text>
                </div>
                <ArticleNumbers
                    wholesalerArticleNumber={wholesalerArticleNumber}
                    productGroupId={productGroupId}
                    supplierArticleNumber={supplierArticleNumber}
                    supplierId={supplierId}
                />
                {isWM() && renderCustomerArticleNumber()}
                {renderDescription()}
                {!isEditing && (isWM() ? renderPartsForWM() : renderPartsDefault())}
                <div className="actions no-separator">{renderActions()}</div>
            </div>
            {renderPartsNote()}
        </div>
    )
}
