import { Icon, Loader, Image } from "@tm/controls"
import { concat } from "@tm/utils"
import * as React from "react"
import { Link } from "react-router-dom"

type Props = {
    image?: string
    imageFallbackType?: "manufacturer" | "car" | "bike"
    description?: string
    content?: string
    isLoading?: boolean
    isCurrent?: boolean
    isLast?: boolean
    resetUrl?: string
}

export default function SelectionsCard(props: React.PropsWithChildren<Props>) {
    function renderContent() {
        if (props.isLoading) {
            return <Loader />
        }

        return (
            <>
                {props.image && <Image className="selections__image" url={props.image} type={props.imageFallbackType} />}
                {props.description && <div className="selections__title subtitle">{props.description}</div>}
                {props.content && <div className="selections__headline">{props.content}</div>}
                {props.resetUrl && (
                    <Link to={props.resetUrl} className="btn btn--ghost">
                        <Icon name="close" size="s" className="icon--reset" />
                    </Link>
                )}
            </>
        )
    }

    return (
        <li className={concat(" ", "card selections__card", props.isCurrent ? "card--current" : "card--decent")}>
            <div className="selections__content">{renderContent()}</div>
            {!props.isLast && <div className="card__arrow" />}
        </li>
    )
}
