import { Button, Checkbox, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../../../utils"

type Props = {
    selected: boolean
    onToggle: () => void
    onToggleClipped: () => void
    clipped?: boolean
}

function ExtendedAssortmentFilter(props: Props) {
    const { selected, onToggle, clipped, onToggleClipped } = props
    const { translateText } = useLocalization()

    const { hideFilterPaperClip } = getBundleParams()

    return (
        <div key="collapsible-filter--clipped-extended-assortment" className="collapsible filter filter--clipped filter--extended-assortment">
            <div className="collapsible__header">
                <div className="collapsible__caption">
                    <Checkbox size="s" label={translateText(227)} checked={selected} onToggle={onToggle} />
                </div>
                {!clipped && !hideFilterPaperClip && (
                    <Tooltip content={translateText(1899)}>
                        <Button layout={["ghost"]} className="multi-btn__icon" icon="paperclip" onClick={onToggleClipped} />
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

export default ExtendedAssortmentFilter
