import { ChangeItemsResponse, UserInteractionLog } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"

export function useRemovePartList(
    handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>,
    onSucces?: (response: ChangeItemsResponse | undefined, request: { partIds: string[]; isExternalCall?: boolean }) => void
) {
    const { mutateAsync: removeItemList } = useMutation(
        (request: { workTaskId: string; partIds: string[]; isExternalCall?: boolean; log?: UserInteractionLog }) =>
            Data.removeItemList(request.workTaskId, request.partIds, undefined, request.log),
        {
            onSuccess:
                onSucces ??
                ((response, request) => {
                    if (response) {
                        handleBasketUpdateWorkflow(request.workTaskId, response)
                    }
                }),
        }
    )

    const removeParts = useCallback(
        (request: { workTaskId: string; partIds: string[]; isExternalCall?: boolean; log?: UserInteractionLog }) => {
            return removeItemList(request)
        },
        [removeItemList]
    )

    return removeParts
}
