import { useCallback, Suspense } from "react"
import { useWorkTask, WorkTaskInfo } from "@tm/context-distribution"
import { RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { copyToClipboard, renderRoute } from "@tm/utils"
import { useHistory, useLocation, useParams } from "react-router"
import { bundleChannel } from "../../business"
import BasketNavigation from "./components/BasketNavigation"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { BasketPart } from "../../models"
import { useShowWorkTaskOrderPdf } from "../../data/hooks/useShowWorkTaskOrderPdf"
import { useInvalidateWorkTaskBasketQueries } from "../../data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"
import { useCostEstimationVisible } from "../../hooks/basketState/useCostEstimationVisible"
import ExportSlot from "../_shared/export-slot"

type Props = {
    costEstimationRoute: string
    exportTarget?: string
    partsBasketRoute?: string
    partsDetailBasketRoute: string
    showCopyToClipboardButton?: boolean
    showExportVoucherButton?: boolean
}

function getNumbersAndQuantity(part: BasketPart) {
    const articleNumber = part.partItem.articleInformation.wholesalerArticleNumber ?? part.partItem.articleInformation.articleNumber
    return `${articleNumber || ""};${part.partItem.quantity.value}`
}

function NavigationComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { partsDetailBasketRoute, showCopyToClipboardButton, partsBasketRoute, costEstimationRoute, workTask, exportTarget } = props

    const matchParams = useParams<{ workTaskId: string; view?: string }>()
    const location = useLocation()
    const history = useHistory()
    const { invalidateAllWorkTaskBasketRequests } = useInvalidateWorkTaskBasketQueries()

    const { basket } = useWorkTaskBasketState(workTask.id)
    const { costEstimationVisible } = useCostEstimationVisible(workTask.id)

    const { showWorkTaskOrderPdf, loadingWorkTaskOrderPdf } = useShowWorkTaskOrderPdf(workTask.id)

    const partsUrl = renderRoute(partsBasketRoute!, matchParams)
    const partsIsActive = location.pathname === partsUrl
    const detailsIsActive = matchParams.view === partsDetailBasketRoute

    const copyPartsNumberAndQuantityToClipboard = useCallback(() => {
        const parts: Array<string> = []
        const basketParts = basket.state.basketOrderGroups?.flatMap((basketOrderGroup) => basketOrderGroup.basketParts)
        basketParts?.forEach((part) => {
            parts.push(getNumbersAndQuantity(part))
            part.linkedItems?.forEach((linkedItem) => {
                parts.push(getNumbersAndQuantity(linkedItem))
            })
        })

        if (parts.length) {
            copyToClipboard(parts.join("\n"))
        }
    }, [basket.state.basketOrderGroups])

    function handleCostEstimationButtonClick() {
        Container.getInstance<{ key: string; value: { state: boolean } }>(RegisteredModels.ViewState)
            .subscribe("COST_ESTIMATION_VISIBLE")
            .save?.({ key: `${matchParams.workTaskId}__COST_ESTIMATION_VISIBLE`, value: { state: true } })

        bundleChannel().publish("ENABLE_COST_ESTIMATION", {})
        const url = renderRoute(costEstimationRoute, { ...matchParams })
        history.push(url)

        // When the KVA is opened for the first time, we need to invalidate both queries.
        if (costEstimationVisible === false) {
            invalidateAllWorkTaskBasketRequests(workTask.id)
        }
    }

    return (
        <>
            <BasketNavigation
                costEstimationRoute={costEstimationRoute}
                detailsIsActive={detailsIsActive}
                hasParts={basket.state.hasItems ?? false}
                loadingWorkTaskOrderPdf={loadingWorkTaskOrderPdf}
                basketLoading={basket.state.basketLoading}
                partsDetailBasketRoute={partsDetailBasketRoute}
                partsIsActive={partsIsActive}
                partsUrl={partsUrl}
                showCopyToClipboardButton={showCopyToClipboardButton}
                onCopyPartsNumberAndQuantityToClipboard={copyPartsNumberAndQuantityToClipboard}
                onCostEstimationButtonClick={handleCostEstimationButtonClick}
                onPartsWarningConfirm={() => basket.actions.removeAllParts()}
                onPrintButtonClick={() => showWorkTaskOrderPdf()}
                onAddWholesalerArticlesToBasket={basket.actions.addWholesalerParts}
            />
            <ExportSlot exportTarget={exportTarget} showExportVoucherButton={props.showExportVoucherButton} showToolbar />
        </>
    )
}

export default function Wrapper(props: Props) {
    const { workTask } = useWorkTask() ?? {}
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <NavigationComponent {...props} workTask={workTask} />
        </Suspense>
    )
}
