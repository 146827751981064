import { Suspense, memo, useMemo } from "react"
import { IMicros } from "@tm/models"
import { mapArticleToCompilationArticle } from "../../data/mapper"
import { MicroWrapper } from "../MicroWrapper"
import { useErpInfo } from "../shared-components/business/useErpInfo"
import { AddToCompilation } from "../shared-components/component"

type Props = IMicros["compilations"]["add-to-compilation"]

const AddToCompilationComponent = memo<Props>(({ disabled, wholesalerPart, article, variant }) => {
    const erpState = useErpInfo(wholesalerPart)

    const mappedArticles = useMemo(() => {
        return mapArticleToCompilationArticle({
            articles: article ? [article] : undefined,
            wholesaler: wholesalerPart
                ? {
                      ...wholesalerPart,
                      ...(erpState.erpInfo?.additionalInformation ? { additionalInformation: erpState.erpInfo?.additionalInformation } : undefined),
                  }
                : undefined,
        })
    }, [article, erpState, wholesalerPart])

    return (
        <AddToCompilation
            articles={mappedArticles}
            disabled={disabled || erpState.loading}
            highlightListsIfItContainsArticle
            enableRemoveFromList
            variant={variant}
        />
    )
})

export function Wrapper(props: Props) {
    return (
        <MicroWrapper>
            <Suspense fallback={null}>
                <AddToCompilationComponent {...props} />
            </Suspense>
        </MicroWrapper>
    )
}
