import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { StatusSwitch } from "@tm/controls"
import { Button, Icon, Paper, Stack, Typography } from "@tm/components"
import { useCentralOrderWorkTasks } from "../../../data/hooks/useCentralOrderWorkTasks"

export default function WorkTasksSelectionComponent() {
    const { translateText } = useLocalization()
    const {
        state: { workTasks },
        selectAll,
        setWorkTaskStatusByList,
    } = useCentralOrderWorkTasks()

    const { selected, includedInOrder, expanded, disabled, allSelected } = useMemo(() => {
        const selected: Array<string> = []
        const includedInOrder: Array<string> = []
        const expanded: Array<string> = []
        const disabled: Array<string> = []
        workTasks.forEach((w) => {
            if (w.selected) {
                selected.push(w.workTask.workTaskId)
                if (w.includedInOrder) {
                    includedInOrder.push(w.workTask.workTaskId)
                }
                if (w.expanded) {
                    expanded.push(w.workTask.workTaskId)
                }
                if (w.disabled) {
                    disabled.push(w.workTask.workTaskId)
                }
            }
        })
        return { selected, includedInOrder, expanded, disabled, allSelected: selected.length === workTasks.length }
    }, [workTasks])

    if (!selected.length) {
        return null
    }

    function handleOrderStatusSwitchChange() {
        const includeInOrder = !selected.some((id) => includedInOrder.includes(id))
        setWorkTaskStatusByList(selected, "includedInOrder", includeInOrder)
    }

    function handleCompcatViewSwitchChange() {
        const expand = !selected.some((id) => expanded.includes(id))
        setWorkTaskStatusByList(selected, "expanded", expand)
    }

    function handleSelectButtonClick() {
        selectAll(!allSelected)
    }

    function handleCancelButtonClick() {
        selectAll(false)
    }

    function renderSelectButton() {
        let buttonText = translateText(702)

        if (allSelected) {
            buttonText = translateText(703)
        }

        return <Button onClick={handleSelectButtonClick}>{buttonText}</Button>
    }

    function renderUpdateOrderStatusSwitch() {
        const workTasksIncludedInOrder = selected.some((id) => includedInOrder.includes(id))
        const equalStatus = !(selected.some((id) => includedInOrder.includes(id)) && selected.some((id) => !includedInOrder.includes(id)))

        let status: "checked" | "checked-not-equal" | "unchecked" | "unchecked-not-equal" = "unchecked"
        if (workTasksIncludedInOrder && equalStatus) {
            status = "checked"
        } else if (workTasksIncludedInOrder && !equalStatus) {
            status = "checked-not-equal"
        } else if (!workTasksIncludedInOrder && equalStatus) {
            status = "unchecked"
        } else if (!workTasksIncludedInOrder && !equalStatus) {
            status = "unchecked-not-equal"
        }

        return (
            <StatusSwitch
                label={translateText(772)}
                alignLabel="left"
                status={status}
                disabled={disabled.length > 0}
                onChange={handleOrderStatusSwitchChange}
            />
        )
    }

    function renderCompactModeSwitch() {
        let workTasksExpanded = false
        let equalStatus = true
        if (expanded.length) {
            workTasksExpanded = true
            selected.forEach((id) => {
                if (!expanded.includes(id)) {
                    equalStatus = false
                }
            })
        }

        let status: "checked" | "checked-not-equal" | "unchecked" | "unchecked-not-equal" = "unchecked"
        if (workTasksExpanded && equalStatus) {
            status = "unchecked"
        } else if (workTasksExpanded && !equalStatus) {
            status = "unchecked-not-equal"
        } else if (!workTasksExpanded && equalStatus) {
            status = "checked"
        } else if (!workTasksExpanded && !equalStatus) {
            status = "checked-not-equal"
        }

        return <StatusSwitch label={translateText(3009)} alignLabel="left" status={status} onChange={handleCompcatViewSwitchChange} />
    }

    return (
        <Paper elevation={3} sx={{ padding: "0 0 0 1rem" }}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="label">{translateText(701).toUpperCase()}: </Typography>
                <Typography variant="label">
                    {selected.length} {translateText(1142)}
                </Typography>
                {renderSelectButton()}
                {renderCompactModeSwitch()}
                {renderUpdateOrderStatusSwitch()}
                <Button
                    size="large"
                    color="primary"
                    startIcon={<Icon name="cancel-x" />}
                    title={translateText(317)}
                    onClick={handleCancelButtonClick}
                />
            </Stack>
        </Paper>
    )
}
