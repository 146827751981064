import { Box, Loader } from "@tm/components"
import { Suspense, useEffect } from "react"
import { ArticleListConfigurationProvider } from "../ArticleListConfiguration"
import { ProductGroupsModuleStateProvider, useProductGroupsModuleState } from "./ProductGroupsModuleState"
import ProductGroupsList from "./ProductGroupsList"

function ProductGroupsComponent() {
    const start = useProductGroupsModuleState((x) => x.start)

    useEffect(start, [start])

    return <ProductGroupsList />
}

export default function ProductGroups() {
    return (
        <Suspense
            fallback={
                <Box alignContent="center">
                    <Loader />
                </Box>
            }
        >
            <ArticleListConfigurationProvider>
                <ProductGroupsModuleStateProvider>
                    <ProductGroupsComponent />
                </ProductGroupsModuleStateProvider>
            </ArticleListConfigurationProvider>
        </Suspense>
    )
}
