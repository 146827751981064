import { Box, Loader, styled, Typography } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { Tag, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes, getCurrencyFromUserContext } from "@tm/utils"
import { forwardRef, memo, ReactNode, Ref, RefAttributes, useMemo } from "react"
import { useSelector } from "react-redux"
import { calculationSelector } from "../../selectors"

type PanelDataModel = {
    type: string
    total: string
    title: ReactNode
    priceFontWeight?: string | number
    priceFontColor?: string
    additionalInfo?: ReactNode
}

type Props = {
    showInHeader?: boolean
    showAsTooltip?: boolean
} & RefAttributes<HTMLElement>

const Totals = memo(
    forwardRef<HTMLElement, Props>(({ showInHeader, showAsTooltip }: Props, ref: Ref<HTMLElement>) => {
        const { translateText, currency } = useLocalization()
        const user = useUser()
        const currencyString = getCurrencyFromUserContext(user?.userContext)
        const { totals, items, works, updateTotalsInProgress, initialSparePartsPrice } = useSelector(calculationSelector)
        const showInitialSparePartsPrice = items?.filter((x) => !!x.selectedPart)?.length > 0

        const panels: Array<PanelDataModel> = [
            {
                type: "parts",
                total: currency(totals.totalSparePartsPrice, currencyString),
                title: <Typography fontSize="small">{`${items?.length ?? 0} ${translateText(90)}`}</Typography>,
                priceFontWeight: 600,
                additionalInfo: showInitialSparePartsPrice && (
                    <Typography
                        fontSize={showInHeader ? "small" : "medium"}
                        sx={{ alignSelf: "flex-end", padding: (theme) => theme.spacing(0, ".5em") }}
                    >
                        ({`${translateText(12882)}: ${currency(initialSparePartsPrice, currencyString)}`})
                    </Typography>
                ),
            },
            {
                type: "repair-times",
                total: currency(totals.totalWorksPrice, currencyString),
                title: <Typography fontSize="small">{`${works?.length ?? 0} ${translateText(83)}`}</Typography>,
                priceFontWeight: 600,
            },
            {
                type: "total-vat",
                total: currency(totals.totalGrossPrice, currencyString),
                title: <Typography fontSize="small">{translateText(263)}</Typography>,
                priceFontWeight: 600,
                priceFontColor: "primary",
                additionalInfo: (
                    <Typography
                        fontSize={showInHeader ? "1em" : "small"}
                        sx={{ alignSelf: "flex-end", padding: (theme) => theme.spacing(0, ".5em") }}
                    >
                        ({`${translateText(694)}: ${currency(totals.totalNetPrice, currencyString)}`})
                    </Typography>
                ),
            },
        ]

        const renderTotalsInHeader = () => {
            return (
                <StyledTotals ref={ref} showInHeader={showInHeader}>
                    {content}
                </StyledTotals>
            )
        }

        const renderPanelSection = (panel: PanelDataModel, key: number) =>
            useMemo(() => {
                return (
                    <StyledPanel showInHeader={showInHeader} className={`panel ${classes(panel.type)}`} key={key}>
                        <Typography>{panel.title}</Typography>
                        <StyledPanelContent
                            updating={updateTotalsInProgress}
                            showInHeader={showInHeader}
                            className={classes(updateTotalsInProgress && "updating")}
                        >
                            <Typography
                                fontWeight={panel.priceFontWeight}
                                color={panel.priceFontColor}
                                fontSize={!showInHeader ? "1.5rem" : "1.2rem"}
                            >
                                {panel.total}
                            </Typography>

                            {panel.additionalInfo}

                            {totals.discount && panel.type === "total" && (
                                <Typography>({`${translateText(3112)}: ${currency(totals.discount, currencyString)}`})</Typography>
                            )}

                            {totals.discountTotal && panel.type === "total-vat" && (
                                <Typography>({`${translateText(3112)}: ${currency(totals.discountTotal, currencyString)}`})</Typography>
                            )}
                        </StyledPanelContent>

                        {updateTotalsInProgress && (
                            <Box position="absolute" top="50%" left="50%">
                                <Loader size="extrasmall" />
                            </Box>
                        )}
                    </StyledPanel>
                )
            }, [totals, updateTotalsInProgress])

        let content = (
            <Box flex="1" display="flex" justifyContent="space-evenly">
                {panels.map(renderPanelSection)}
            </Box>
        )

        if (updateTotalsInProgress && showInHeader) {
            content = <Loader size="extrasmall" />
        }

        if (showAsTooltip) {
            return (
                <Tooltip style="primary" content={renderTotalsInHeader()} position="right">
                    <StyledTag label={translateText(13004)} size="m" layout="bordered" />
                </Tooltip>
            )
        }

        return renderTotalsInHeader()
    })
)

const StyledTag = styled(Tag)(() => ({
    alignSelf: "flex-end",
}))

const StyledTotals = styled(Box)<{ showInHeader?: boolean }>(({ showInHeader, theme }) => ({
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing("1.8em", "5em"),
    ...(showInHeader && {
        margin: 0,
        ".panel": {
            "&__content": {
                padding: 0,
            },
            ".total-vat": {
                borderColor: theme.colors?.highlight,
            },
        },
    }),
    ...(!showInHeader && {
        ".loader": {
            position: "absolute",
        },
    }),
}))

const StyledPanel = styled(Box)<{ showInHeader?: boolean }>(({ showInHeader, theme }) => ({
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none !important",
    borderBottom: ".3em solid #c3c3c3",
    padding: 0,
    margin: theme.spacing(0, 1),
    ...(showInHeader && {
        margin: theme.spacing(0, ".5em"),
    }),
}))

const StyledPanelContent = styled(Box)<{ updating?: boolean; showInHeader?: boolean }>(({ updating }) => ({
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    ...(updating && {
        opacity: 0.3,
    }),
}))

export default Totals
