import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { RegisteredModels, ShowGlobalOptionsResponse, ShowGlobalOptionsRequest } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"
import { useFavoriteOrderOptions } from "../../hooks/useFavoriteOrderOptions"

export const GLOBAL_ORDER_OPTIONS_KEY = "basket____GlobalOrderOptions"
export function useGlobalOrderOptions(enabled: boolean, useShipmentModeForKey?: boolean, selectedShipmentModeId?: string) {
    const { favoriteOrderOptions } = useFavoriteOrderOptions()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const mappedRequest: ShowGlobalOptionsRequest = {
        distributorId: undefined,
        telesalesCustomerNo,
        favoriteOrderOptions,
        selectedShipmentModeId,
    }

    const initialKey = [GLOBAL_ORDER_OPTIONS_KEY, useShipmentModeForKey ? selectedShipmentModeId : undefined]

    const { data, isLoading } = useQuery(
        initialKey,
        () => Container.getInstance<ShowGlobalOptionsResponse>(RegisteredModels.ERP_GlobalOrderOptions).subscribe(mappedRequest).load(),
        {
            staleTime: 30 * 60 * 1000, // 30 minutes
            enabled,
            // don't use queryClient.setQueryData in useQuery to set data with the same key, it triggers an endless rerendering
        }
    )

    return { globalOrderOptionsResponse: data, globalOrderOptionsLoading: isLoading }
}
