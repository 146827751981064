import { Select, SelectItem, Image, SxProps, Theme, Icon, styled, Box, Tooltip, SelectChangeEvent, selectClasses } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType, Vehicle, VehicleLookupConfig, VehicleType } from "@tm/models"
import { forwardRef, useMemo, useState } from "react"
import { useSelectedVehicleLookup } from "../../data/hooks/useSelectedLookupConfig"

export const VehicleLookupSelection = forwardRef<
    HTMLDivElement,
    {
        vehicleType: VehicleType
        selectedSearchConfig?: VehicleLookupConfig
        onSelectSearchOption: (config: VehicleLookupConfig) => void
        onOpen?: () => void
        sx?: SxProps<Theme>
        variant?: "outlined" | "standard" | "filled"
        tooltipTitle?: string
        className?: string
        selectedVehicle?: Vehicle
    }
>((props, ref) => {
    const { onSelectSearchOption, className, selectedVehicle, vehicleType } = props
    const { selectableWithoutDuplicates, selectedVehicleLookup, setSelectedVehicleLookup } = useSelectedVehicleLookup(
        selectedVehicle?.vehicleType ?? vehicleType
    )
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const { translateText } = useLocalization()

    const selectedSearchConfig = useMemo(() => {
        if (!!selectedVehicle && !selectedVehicle.countryCode) {
            return
        }
        const selected = selectableWithoutDuplicates.find(
            (x) => x.lookupTypeId === selectedVehicleLookup.lookupTypeId && x.countryCode === selectedVehicleLookup.countryCode
        )
        return selected || props.selectedSearchConfig
    }, [props.selectedSearchConfig, selectableWithoutDuplicates, selectedVehicleLookup, selectedVehicle])

    if (hideVehicleLookupSelection(selectableWithoutDuplicates, selectedSearchConfig?.lookupTypeId)) {
        return null
    }

    function countryCodeChangedHandler(e: SelectChangeEvent<unknown>) {
        if (e.target && (e.target.value as number) >= 0) {
            const selectedLookup = selectableWithoutDuplicates.find((value) => value.lookupTypeId === e.target.value)
            selectedLookup && onSelectSearchOption(selectedLookup)
            selectedLookup &&
                setSelectedVehicleLookup({
                    countryCode: selectedLookup?.countryCode,
                    lookupTypeId: selectedLookup?.lookupTypeId,
                })
        }
    }

    const defaultSelectValue = selectedSearchConfig && selectedSearchConfig.lookupTypeId >= 0 ? selectedSearchConfig.lookupTypeId : -1
    const { tooltipTitle } = props
    return selectableWithoutDuplicates.length > 0 ? (
        <Tooltip open={tooltipVisible} arrow placement="top" hidden={!tooltipTitle} title={tooltipTitle || ""} sx={{ width: "215px" }}>
            <StyledSelect
                ref={ref}
                onOpen={() => {
                    props.onOpen && props.onOpen()
                    setTooltipVisible(false)
                }}
                onMouseLeave={() => {
                    setTooltipVisible(false)
                }}
                onMouseEnter={() => {
                    setTooltipVisible(true)
                }}
                onClose={() => {
                    setTooltipVisible(false)
                }}
                variant={props.variant || "outlined"}
                sx={props.sx}
                className={className}
                SelectDisplayProps={{ style: { display: "flex", paddingBottom: 0, paddingTop: 0, paddingRight: 16 } }}
                value={defaultSelectValue}
                onChange={countryCodeChangedHandler}
                renderValue={(value) => {
                    if (value === false || value === undefined) {
                        return <Image type="flag" src="/styles/base/images/flags/unknown.png" />
                    }
                    const config = selectableWithoutDuplicates.find((selectableConfig) => selectableConfig.lookupTypeId === value)
                    return config?.countryCode ? (
                        <Image type="flag" src={`/styles/base/images/flags/${config.countryCode.toLowerCase()}.png`} />
                    ) : null
                }}
            >
                {selectableWithoutDuplicates.map((config) => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    const label = lookupToCountryTextIds[config.lookupTypeId] || config.description

                    return (
                        <SelectItem key={`key-${config.lookupTypeId}`} value={config.lookupTypeId} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            <Image
                                src={`/styles/base/images/flags/${config?.countryCode?.toLowerCase() || "unknown"}.png`}
                                alt={config.countryCode?.toLowerCase()}
                                type="flag"
                                height={32}
                                width={32}
                            />{" "}
                            <span style={{ paddingLeft: ".2em" }}>{translateText(label)}</span>
                        </SelectItem>
                    )
                })}
                <Unselectable>
                    <Icon name="info" sx={{ marginLeft: "6px" }} />
                    <Box sx={{ fontSize: "12px", paddingLeft: "10px" }}>{translateText(13341)}</Box>
                </Unselectable>
            </StyledSelect>
        </Tooltip>
    ) : null
})

const Unselectable = styled(Box)(() => ({
    display: "inline-flex",
    justifyContent: "center",
    padding: "0 16px",
    alignItems: "center",
    wordBreak: "break-word",
    cursor: "default",
    width: "215px",
}))

const StyledSelect = styled(Select)(({ theme }) => {
    const { select, icon } = theme.overwrites?.toolkits?.vehicle?.widget?.countrySelection ?? {}
    const { border: themedBorder } = select || {}
    const { color: themedHoveredIconColor } = icon?.hover || {}
    const { color: themedIconColor } = icon || {}

    return {
        [`.${selectClasses.select}`]: {
            padding: "0px 0.5em 0px 2em!important",
            ".MuiSelect-icon": {
                color: themedIconColor || theme.palette.getContrastText(theme.palette.primary.light),
            },
        },
        [`.${selectClasses.iconOpen}`]: {
            color: themedIconColor,
        },
        [`.MuiOutlinedInput-notchedOutline`]: {
            border: themedBorder || undefined,
        },
        ".widget__collapse+.widget__inner &": {
            right: "3.5em",
            pointerEvents: "all",
        },
        ".multiwidget__content .vehicle-widget &": {
            position: "absolute",
            top: 0,
            right: 0,
        },
        img: {
            width: 32,
            height: 32,
        },
        "&:hover .MuiSelect-icon": {
            color: themedHoveredIconColor,
        },
    }
})

const lookupToCountryTextIds: { [id: number]: number | string } = {
    [RegistrationNoType.Kba]: 3147,
    [RegistrationNoType.KennzeichenNiederlande]: 13284,
    [RegistrationNoType.KennzeichenNiederlandeCarat]: 1762,
    [RegistrationNoType.NationalcodeAustria]: 12705,
    [RegistrationNoType.LicensePlateDenmarkDriveRight]: 13285,
    94: 13285,
    [RegistrationNoType.NationalcodeFrankreich]: 3115,
    [RegistrationNoType.NationalCodeFrankreichCarat]: 3115,
    [RegistrationNoType.TypenscheinSchweizEurotax]: 12706,
    [RegistrationNoType.TypenscheinSchweizCarat]: 12706,
    [RegistrationNoType.TypeApprovalNumberSwitzerlandRhiag]: 12706,
    [RegistrationNoType.KennzeichenPortugal]: 13290,
    95: 13285,
    [RegistrationNoType.VINFranceInforauto]: 3115,
    [RegistrationNoType.VrmVINSpanienInforauto]: 12710,
    [RegistrationNoType.VrmKennzeichenSpanienDGT]: 12710,
    [RegistrationNoType.ItalienEdidomusWsvc]: 3114,
    [RegistrationNoType.KennzeichenEstland]: 13350,
    [RegistrationNoType.CarwebVrmWsvc]: 12708,
    [RegistrationNoType.CartellIrlandKennzeichenWsvc]: 13428,
}

function hideVehicleLookupSelection(selectableVehicleLookups: VehicleLookupConfig[], currentlySelectedLookupId?: number) {
    if (selectableVehicleLookups.length <= 1) {
        return true
    }
    if (currentlySelectedLookupId === undefined) {
        return false
    }
    if (selectableVehicleLookups.filter((lookup) => lookup.isSelectable).length === 2) {
        const selectedLookup = selectableVehicleLookups.find((lookup) => lookup.lookupTypeId === currentlySelectedLookupId)
        if (selectedLookup) {
            return !!selectableVehicleLookups.find(
                (lookup) =>
                    lookup.lookupTypeId !== currentlySelectedLookupId &&
                    selectedLookup.countryCode &&
                    lookup.countryCode === selectedLookup.countryCode
            )
        }
    }
    return false
}
