import { GetCustomArticlesResponse } from "@tm/models"
import { ajax, createQueryString, getStoredAuthorization } from "@tm/utils"
import { useQuery } from "react-query"
import { getBundleParams } from "../../utils"

function getReplaceArticles(categoryTextId: number | undefined) {
    const url = getBundleParams().customArticlesServiceUrl
    const queryString = createQueryString({ CategoriesFilter: categoryTextId })
    return ajax<GetCustomArticlesResponse>({ url: `${url}/Get${queryString || ""}`, authorization: getStoredAuthorization() }).then(
        (data) => data?.articles
    )
}

export function useGetCustomArticles(categoryTextId: number | undefined, enabled: boolean) {
    const query = useQuery(["CustomArticlesGet", { categoryTextId, enabled }], () => getReplaceArticles(categoryTextId), {
        enabled,
    })

    return query
}
