import { Box, Grid } from "@tm/components"
import { Route, Switch } from "react-router"
import { Suspense } from "react"
import { ArticleListConfigurationProvider } from "./ArticleListConfiguration"
import { DirectSearch } from "./DirectSearch"
import { UniversalParts } from "./UniversalParts"
import { VehicleParts } from "./VehicleParts"
import { useActiveVehicleDataProvider } from "./hooks/useActiveVehicleDataProvider"

export function ListV2Component() {
    return (
        <Suspense fallback={null}>
            <ListV2Wrapper />
        </Suspense>
    )
}

function ListV2Wrapper() {
    useActiveVehicleDataProvider()
    return (
        <Suspense fallback={<Box alignContent="center">Loading...</Box>}>
            <ArticleListConfigurationProvider>
                <Grid display="grid" gridTemplateRows="auto 1fr" width="100%" zIndex={0}>
                    <Switch>
                        <Route path="/:workTaskId/parts/vehicles/list/:searchType?" component={VehicleParts} />
                        <Route path="/:workTaskId/parts/direct/list/" component={DirectSearch} />
                        <Route path="/:workTaskId/parts/universal/list/:searchType?" component={UniversalParts} />
                    </Switch>
                </Grid>
            </ArticleListConfigurationProvider>
        </Suspense>
    )
}
