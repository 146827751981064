import { OrderVoucherSupplierItem, Article, ECalcOrigin, Vehicle, CarModelDetailsResponse } from "@tm/models"
import { BundleActions, BundleActionTypes } from "../../../business"
import { ECalcInputType, ECalcState } from "../../../data/enums"
import { CalcSelection, CalcSelectionItem, Services, SummaryContext, FastCalculatorData, CalcState } from "../../../data/model"
import { getSelectedServicesFromWS } from "../../../data/helpers"

import { MainActionType } from "../../main/business"

export type FastCalcState = {
    vehicle?: Vehicle
    vehicleIdUsed?: string
    loading?: boolean
    error?: boolean
    fastCalculatorData: FastCalculatorData
    selectedCalcSelection?: CalcSelection
    selectedServices: Array<CalcSelectionItem>
    unsavedServices: Array<CalcSelectionItem>
    selectedCalcState?: CalcState
    selectedDataCalcState?: CalcState
    selectedDialogCalcState?: CalcState
    selectedOverlayCalcState?: CalcState
    alternativeCalcArticles?: CalcState
    carModel?: CarModelDetailsResponse
    costEstimationUrl?: string

    engineCode?: string
    initialRegistration?: Date
    mileage?: number
    longLife?: boolean

    additionalData: {
        loading: boolean
        articles: Array<Article>
        orderHistory: Array<OrderVoucherSupplierItem>
    }
    vehicleId?: string
    isQueued?: boolean
    requestQueueId?: string
    checkStatusUrl?: string
    calcOrigin: ECalcOrigin
}

export type ComponentActionType =
    | BundleActionTypes
    | { type: "SELECT_CATEGORY"; payload: CalcSelection }
    | { type: "RESET_ADDITIONAL_DATA" }
    | { type: "ARTICLES_LOADING" }
    | { type: "ARTICLES_EMPTY_RESPONSE" }
    | { type: "ARTICLES_LOADED"; payload: Array<Article> }
    | {
          type: "ORDER_HISTORY_LOADED"
          payload: {
              orderHistory: Array<OrderVoucherSupplierItem>
              appendData: boolean
          }
      }
    | { type: "ADD_SELECTED_SERVICE"; payload: { item: CalcSelectionItem; existsInWS?: boolean } }
    | { type: "RESET_SELECTED_SERVICE" }
    | { type: "SET_CALC_ORIGIN"; payload: { calcOrigin: ECalcOrigin } }

const DEFAULT_STATE: FastCalcState = {
    fastCalculatorData: {
        activeCalcState: ECalcState.None,
        availableCalcStates: ECalcState.None,
        calcStates: [],
    },
    unsavedServices: [],
    selectedServices: [],
    loading: true,
    additionalData: {
        loading: false,
        articles: [],
        orderHistory: [],
    },
    isQueued: false,
    calcOrigin: ECalcOrigin.Next,
}

export function reduce(state = { ...DEFAULT_STATE }, action: MainActionType): FastCalcState {
    switch (action.type) {
        case "SET_CONFIG_PROPS": {
            return {
                ...state,
                costEstimationUrl: action.payload.costEstimationUrl,
            }
        }
        case "SET_CALC_ORIGIN": {
            return {
                ...state,
                calcOrigin: action.payload.calcOrigin,
            }
        }
        case "QUEUE_START": {
            return {
                ...state,
                requestQueueId: action.payload.requestQueueId,
                checkStatusUrl: action.payload.checkStatusUrl,
                isQueued: true,
                loading: false,
            }
        }
        case "QUEUE_FINISHED": {
            return {
                ...state,
                isQueued: false,
            }
        }
        case "QUEUE_CANCELLED": {
            return {
                ...state,
                isQueued: false,
                requestQueueId: "",
            }
        }
        case "FASTCALCULATOR_LOADING": {
            return {
                ...state,
                loading: true,
                ...(action.payload && { tecDocUsed: action.payload }),
            }
        }
        case "FASTCALCULATOR_LOADED": {
            const {
                selectedCalcState,
                fastCalculatorData,
                selectedDataCalcState,
                selectedDialogCalcState,
                selectedOverlayCalcState,
                alternativeCalcArticles,
            } = action.payload

            const initialRegistration = selectedCalcState?.context?.inputs?.find((x: any) => x.type == ECalcInputType.RegDate)?.value as string
            const engineCode = selectedCalcState?.context?.inputs?.find((x: any) => x.type == ECalcInputType.EngineCode)?.value as string
            const mileage = selectedCalcState?.context?.inputs?.find((x: any) => x.type == ECalcInputType.Mileage)?.value as number | undefined
            const longLife = selectedCalcState?.context?.inputs?.find((x: any) => x.type == ECalcInputType.Longlife)?.value

            const selectedServicesFromWS = getSelectedServicesFromWS(selectedCalcState?.context as SummaryContext)
            const unsavedDeselections = state.unsavedServices.filter((s) => s.isSelected === true)
            const unsavedSelections = state.unsavedServices.filter((s) => s.isSelected === false)
            const serviceList: CalcSelectionItem[] = []

            selectedServicesFromWS.forEach((service) => {
                const contains = unsavedDeselections.filter((s) => s.id === service.id)
                if (contains.length === 0) {
                    serviceList.push(service)
                }
            })

            return {
                ...state,
                loading: false,
                selectedCalcState,
                selectedDataCalcState,
                selectedDialogCalcState,
                fastCalculatorData,
                selectedOverlayCalcState,
                alternativeCalcArticles,
                error: false,
                engineCode,
                initialRegistration: (initialRegistration && new Date(initialRegistration)) || undefined,
                mileage,
                longLife,
                selectedServices: serviceList.length > 0 ? serviceList.concat(unsavedSelections) : unsavedSelections,
                isQueued: false,
            }
        }
        case "FASTCALCULATOR_ERROR": {
            return {
                ...state,
                error: true,
                loading: false,
                isQueued: false,
            }
        }
        case "RESET_ADDITIONAL_DATA": {
            return {
                ...state,
                additionalData: {
                    loading: false,
                    articles: [],
                    orderHistory: [],
                },
            }
        }
        case "ARTICLES_LOADING": {
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    loading: true,
                },
            }
        }
        case "ARTICLES_EMPTY_RESPONSE": {
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    loading: false,
                },
            }
        }
        case "ARTICLES_LOADED": {
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    loading: false,
                    articles: [...state.additionalData.articles, ...action.payload],
                },
            }
        }
        case "ORDER_HISTORY_LOADED": {
            const { orderHistory, appendData } = action.payload

            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    orderHistory: !appendData ? orderHistory : state.additionalData.orderHistory.concat(orderHistory),
                },
            }
        }
        case "SELECT_CATEGORY": {
            return {
                ...state,
                selectedCalcSelection: action.payload,
            }
        }
        case "SELECTION_DIALOG_CLOSE": {
            return {
                ...state,
                selectedOverlayCalcState: undefined,
            }
        }
        case "DETAILS_LOADED": {
            const { modelDetails, vehicleId } = action.payload
            return {
                ...state,
                carModel: modelDetails,
                vehicleIdUsed: vehicleId,
            }
        }
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
        case "ADD_SELECTED_SERVICE": {
            const validService: Services = {
                selectedServices: [...state.selectedServices],
                unsavedServices: [...state.unsavedServices],
            }
            const newService = action.payload.item

            const calcType = state.selectedCalcState?.type
            if (calcType === ECalcState.FastCockpitNext || calcType === ECalcState.CalculationNext) {
                handleChecboxToggles(validService, newService, action.payload.existsInWS)
            }

            return {
                ...state,
                unsavedServices: validService.unsavedServices,
                selectedServices: validService.selectedServices,
            }
        }
        case "RESET_SELECTED_SERVICE": {
            return {
                ...state,
                selectedServices: [],
                unsavedServices: [],
                isQueued: false,
                requestQueueId: "",
            }
        }

        default:
            break
    }

    return state
}

function handleChecboxToggles(validServices: Services, newService: CalcSelectionItem, existsInWS?: boolean) {
    if (existsInWS && newService.isSelected) {
        // case where we uncheck the one that is checked on the server
        // we need to add it to a unsavedServices list so it will toggle on the server
        validServices.unsavedServices.push(newService)

        // remove it from selectedServices so the button will go disbaled if needed
        validServices.selectedServices = validServices.selectedServices.filter((s) => s.id !== newService.id)
    } else if (existsInWS && !newService.isSelected) {
        // case where we check the one that is checked on the server
        // we need to remove it from the unsavedServices list so it will not toggle on the server
        validServices.unsavedServices = validServices.unsavedServices.filter((s) => s.id !== newService.id)

        // add it back to the selectedServices so the button will go enabled
        validServices.selectedServices.push(newService)
    } else if (newService.isSelected) {
        // case where we uncheck one that is NOT YET on the server
        // remove from the unsavedServices list and from the selectedService to disable button
        validServices.unsavedServices = validServices.unsavedServices.filter((s) => s.id !== newService.id)
        validServices.selectedServices = validServices.selectedServices.filter((s) => s.id !== newService.id)
    } else if (!newService.isSelected) {
        // case where we check one that is NOT YET on the server
        // add itt to the ewlySelected list and selectedService to enable button
        validServices.unsavedServices.push(newService)
        validServices.selectedServices.push(newService)
    }
}

function selectCategory(category: CalcSelection): MainActionType {
    return { type: "SELECT_CATEGORY", payload: category }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    selectCategory,
}
