import { CollapsibleInfo, SelectedFilters, WheelsAndTyresTab, WheelsAndTyresTabNames } from "."

export const defaultSelectedFilters: SelectedFilters = {
    width: "",
    height: "",
    inch: "",
    loadIndex: "",
    speedIndex: "",
    size: "",
    manufacturerId: "",
    manufacturer: "",
    tyreModel: "",
    season: "",
    rimSize: "",
    offsetValue: "",
    isWinter: undefined,
    isSummer: undefined,
}

export const collapsibleData: CollapsibleInfo[] = [
    { name: "general", translateTextId: 380, completed: false, infoData: {} },
    { name: "tyrePressure", translateTextId: 12497, completed: false, infoData: {} },
    { name: "tyreDot", translateTextId: 13069, completed: false, infoData: {} },
    { name: "rimCondition", translateTextId: 13070, completed: false, infoData: {} },
]

export const spareWheelCollapsibleData: CollapsibleInfo[] = [{ name: "brakeSystem", translateTextId: 13089, completed: false, infoData: {} }]

export const tabItems: WheelsAndTyresTab[] = [
    {
        index: 0,
        name: WheelsAndTyresTabNames.FrontLeft,
        type: "front",
        translation: 13064,
        isComplete: false,
        collapsibleItems: collapsibleData,
        selectedFilters: defaultSelectedFilters,
    },
    {
        index: 1,
        name: WheelsAndTyresTabNames.FrontRight,
        type: "front",
        translation: 13065,
        isComplete: false,
        collapsibleItems: collapsibleData,
        selectedFilters: defaultSelectedFilters,
    },
    {
        index: 2,
        name: WheelsAndTyresTabNames.RearLeft,
        type: "rear",
        translation: 13066,
        isComplete: false,
        collapsibleItems: collapsibleData,
        selectedFilters: defaultSelectedFilters,
    },
    {
        index: 3,
        name: WheelsAndTyresTabNames.RearRight,
        type: "rear",
        translation: 13067,
        isComplete: false,
        collapsibleItems: collapsibleData,
        selectedFilters: defaultSelectedFilters,
    },
    {
        index: 4,
        name: WheelsAndTyresTabNames.SpareWheel,
        type: "spareWheel",
        translation: 13068,
        isComplete: false,
        collapsibleItems: [...collapsibleData, ...spareWheelCollapsibleData],
        selectedFilters: defaultSelectedFilters,
    },
]
