import { useEffect, Suspense, useState, useMemo, useCallback } from "react"
import { useReloadCisCustomer, useTelesalesCustomerNumber, useUser, useWorkTask, WorkTaskInfo } from "@tm/context-distribution"
import { Box, Button, Card, Checkbox, Dialog, Divider, Icon, LinkButton, Loader, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { channel, ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { createQueryString, encodeUniqueId, renderRoute, uniqueId, useLocalStorage } from "@tm/utils"
import { useHistory, useParams } from "react-router"

import { useBonusInformation } from "../../../data/hooks/useBonusInformation"
import { ESendCostEstimationMode, WorkTask } from "../../../data/model"
import { getBundleParams } from "../../../utils"
import OrderCompleted from "./components/OrderCompleted"
import OrderGroup from "./components/OrderGroup"
import TransferCompleted from "../transfer-completed"
import { useTransferCostEstimation } from "../../../data/hooks/useTransferCostEstimation"
import { BasketPart } from "../../../models"
import { useWorkTaskBasketState } from "../../../hooks/basketState/useWorkTaskBasketState"
import { useBasketMemo } from "../../../hooks/useBasketMemo"
import { useOpenBasketArticleDetails } from "../../../hooks/useOpenBasketArticleDetails"
import ErrorAlert from "../ErrorAlert"
import { useOrderResult } from "../order-button/hooks/useOrderResult"
import { AutoCloseInfo } from "./components/AutoCloseInfo"

export type PartsListConfigProps = {
    cisVoucherUrl?: string
    externalSystemId?: number
    hideVehicleLogos?: boolean
    orderOptionsInitiallyExpanded?: boolean
    requestCisCustomerOnOpen?: boolean
    showOrderConfirmationPdf?: boolean
    hideAddToBasketButtonInArticleComparison?: boolean
}

type Props = PartsListConfigProps & {
    workTask: WorkTask | WorkTaskInfo
}

function PartsListComponent(props: Props) {
    const {
        cisVoucherUrl,
        hideAddToBasketButtonInArticleComparison,
        externalSystemId,
        hideVehicleLogos,
        orderOptionsInitiallyExpanded,
        requestCisCustomerOnOpen,
        showOrderConfirmationPdf,
        workTask,
    } = props

    const { translateText } = useLocalization()
    const { userSettings, userContext } = useUser()

    const { localStorageData, setLocalStorageData } = useLocalStorage<{ hide?: boolean }>("confirmationDialog")
    const [hideConfirmationDialog, setHideConfirmationDialog] = useState(false)

    const { articleSearchUrl, replacePartAlternativesRoute } = getBundleParams()
    const { replacePartDirectSearchUrl, replacePartUniversalSearchUrl } = Morpheus.getParams<ConfigParams>()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { invalidateCisCustomer } = useReloadCisCustomer()
    const history = useHistory()
    const matchParams = useParams()
    const { createWorkTask } = useWorkTask() || {}
    const workTaskId = "workTaskId" in workTask ? workTask.workTaskId : workTask.id
    const { response, resetState } = useTransferCostEstimation(workTaskId, telesalesCustomerNo, ESendCostEstimationMode.basket)
    const basketMemo = useBasketMemo(workTask)
    const handleOpenArticleDetails = useOpenBasketArticleDetails(workTaskId)
    const { basket, workTaskBasketLoading, erp, workTaskBasketCalculationLoading, workTaskBasketWithError, workTaskBasketCalculationWithError } =
        useWorkTaskBasketState(workTaskId)
    const { state, actions } = basket
    const { basketOrderGroups, orderSending, bonusPointsWithErrors, bonusPointsLoading } = state
    const { basketErpInfosIndicator } = erp
    const {
        changePartOrderOptions,
        changePartQuantity,
        mergeTecComInfo,
        removePartNote,
        removeParts,
        savePartNote,
        selectAllBasketParts,
        toggleBasketPartNoteExpand,
        toggleBasketPartOrderOptionsExpand,
        toggleBasketPartSelect,
        toggleIncludeBasketParts,
        unselectAllBasketParts,
    } = actions

    const bonusInformationState = useBonusInformation(
        workTaskId,
        basketErpInfosIndicator.showBonusInformation,
        telesalesCustomerNo,
        basketOrderGroups
    )
    const { results, technicalErrors } = useOrderResult(workTaskId)
    const [showOrderCompletedDialog, setShowOrderCompletedDialog] = useState(false)

    useEffect(() => {
        if (requestCisCustomerOnOpen) {
            invalidateCisCustomer()
        }
    }, [workTaskId, requestCisCustomerOnOpen, invalidateCisCustomer])

    useEffect(() => {
        return channel("WORKTASK", workTaskId).subscribe("WORKTASK/CLOSE", () => {
            if (userContext.hasTelesales && results.every((orderResult) => orderResult.result.allHaveSucceeded)) {
                // auto navigate to dashboard if the dialog is not visible and all is succeeded
                if (!localStorageData?.hide) {
                    setShowOrderCompletedDialog(true)
                }
            }
        })
    }, [workTaskId, userContext.hasTelesales, results, localStorageData?.hide])

    const newWorkTaskId = useMemo(() => {
        return uniqueId()
    }, [])

    const displayAutoCloseTimer = useMemo(() => {
        return localStorageData?.hide && results.length > 0 && userContext.hasTelesales
    }, [localStorageData?.hide, results.length, userContext.hasTelesales])

    const openArticleDirectSearchUrl = useCallback(
        (query: string) => {
            const url = renderRoute(articleSearchUrl, { ...matchParams, workTaskId: encodeUniqueId(workTaskId) }) + createQueryString({ query })
            history.push(url)
        },
        [articleSearchUrl, history, matchParams, workTaskId]
    )

    const getSearchQueryString = useCallback((part: BasketPart) => {
        const { partItem } = part
        return {
            query: partItem.articleInformation.articleNumber || partItem.articleInformation?.wholesalerArticleNumber,
            partToReplaceId: partItem.id,
            partToReplaceQuantity: partItem.quantity.value,
        }
    }, [])

    const openAlternativeArticlesToReplacePart = useCallback(
        (part: BasketPart) => {
            const url =
                renderRoute(replacePartAlternativesRoute, { ...matchParams, workTaskId: encodeUniqueId(workTaskId) }) +
                createQueryString({
                    ...getSearchQueryString(part),
                    productGroupIds: part.partItem.articleInformation.productGroupId,
                })

            Morpheus.showView("1", url)
        },
        [getSearchQueryString, matchParams, replacePartAlternativesRoute, workTaskId]
    )

    const openDirectSearchToReplacePart = useCallback(
        (part: BasketPart) => {
            const url =
                renderRoute(replacePartDirectSearchUrl, { ...matchParams, workTaskId: encodeUniqueId(workTaskId) }) +
                createQueryString(getSearchQueryString(part))
            Morpheus.showView("1", url)
        },
        [getSearchQueryString, matchParams, replacePartDirectSearchUrl, workTaskId]
    )

    const openUniversalSearchToReplacePart = useCallback(
        (part: BasketPart) => {
            if (replacePartUniversalSearchUrl) {
                const { partItem } = part
                const url =
                    renderRoute(replacePartUniversalSearchUrl, { ...matchParams, workTaskId: encodeUniqueId(workTaskId) }) +
                    createQueryString({
                        query: partItem.articleInformation.description,
                        partToReplaceId: partItem.id,
                        partToReplaceQuantity: partItem.quantity.value,
                    })
                Morpheus.showView("1", url)
            }
        },
        [matchParams, replacePartUniversalSearchUrl, workTaskId]
    )

    const handleClearOrderSent = useCallback(() => {
        channel("WORKTASK", workTaskId).publish("BASKET/CLEAR_ORDER_SENT_CONTAINER", {})
    }, [workTaskId])

    const createNewWorkTask = useCallback(() => {
        setLocalStorageData({ hide: hideConfirmationDialog })
        setShowOrderCompletedDialog(false)
        const customer = "workTaskId" in workTask ? workTask.customerInfo?.id : workTask.customer

        createWorkTask?.({
            workTaskId: newWorkTaskId,
            customer,
            skipRedirect: true,
        })
    }, [createWorkTask, hideConfirmationDialog, newWorkTaskId, setLocalStorageData, workTask])

    const handleCloseWorkTaskClick = useCallback(() => {
        setLocalStorageData({ hide: hideConfirmationDialog })
        setShowOrderCompletedDialog(false)
        history.push("/")
    }, [hideConfirmationDialog, history, setLocalStorageData])

    const handleOpenNewWorkTaskClick = useCallback(() => {
        createNewWorkTask()
        history.push(`/${encodeUniqueId(newWorkTaskId)}`)
    }, [createNewWorkTask, history, newWorkTaskId])

    const handleHideConfirmationDialogChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setHideConfirmationDialog(event.target.checked)
    }, [])

    function renderEmpty() {
        return (
            <Card>
                <Stack direction="row" py={4} justifyContent="center" spacing={2} alignItems="center">
                    <Icon name="cart" />
                    <Typography>{translateText(843)}</Typography>
                    <LinkButton startIcon={<Icon name="dashboard" />} to={`/${encodeUniqueId(workTaskId)}`}>
                        {translateText(760)}
                    </LinkButton>
                </Stack>
            </Card>
        )
    }

    function renderOrderGroups() {
        if (workTaskBasketWithError) {
            return <ErrorAlert />
        }

        if (!basketOrderGroups?.length || basketOrderGroups?.every((group) => !group.basketParts.length)) {
            return renderEmpty()
        }

        return basketOrderGroups.map((basketOrderGroup) => {
            return (
                <div key={`${basketOrderGroup.orderGroup.warehouseId}_${basketOrderGroup.orderGroup.distributorId}`}>
                    <Divider />
                    <OrderGroup
                        hideAddToBasketButtonInArticleComparison={hideAddToBasketButtonInArticleComparison}
                        workTask={workTask}
                        basketMemo={basketMemo}
                        telesalesCustomerNo={telesalesCustomerNo}
                        basketOrderGroup={basketOrderGroup}
                        workTaskBasketLoading={workTaskBasketLoading}
                        orderSending={orderSending}
                        basketErpInfosIndicator={basketErpInfosIndicator}
                        orderOptionsInitiallyExpanded={orderOptionsInitiallyExpanded}
                        bonusInformationState={bonusInformationState}
                        bonusPointsWithErrors={bonusPointsWithErrors}
                        bonusPointsLoading={bonusPointsLoading}
                        showPurchasePrice={userSettings?.showPurchasePrice}
                        workTaskBasketCalculationLoading={workTaskBasketCalculationLoading}
                        hideVehicleLogos={hideVehicleLogos}
                        onOpenArticleSearch={openArticleDirectSearchUrl}
                        onOpenArticleDetails={handleOpenArticleDetails}
                        onOpenAlternativeArticlesToReplacePart={openAlternativeArticlesToReplacePart}
                        onOpenDirectSearchToReplacePart={openDirectSearchToReplacePart}
                        onOpenUniversalSearchToReplacePart={openUniversalSearchToReplacePart}
                        onChangePartOrderOptions={changePartOrderOptions}
                        onChangeQuantity={changePartQuantity}
                        onExpandNote={(id) => toggleBasketPartNoteExpand([id])}
                        onExpandOrderOptions={(id) => toggleBasketPartOrderOptionsExpand([id])}
                        onIncludeExcludeParts={toggleIncludeBasketParts}
                        onSelectPart={(id) => toggleBasketPartSelect([id])}
                        onTeccomInfoLoaded={mergeTecComInfo}
                        onRemoveParts={removeParts}
                        onRemovePartNote={removePartNote}
                        onSavePartNote={savePartNote}
                        onSelectAll={selectAllBasketParts}
                        onUnselectAll={unselectAllBasketParts}
                    />
                </div>
            )
        })
    }

    return (
        <Stack spacing={1}>
            <Dialog open={showOrderCompletedDialog} skin="warning" position="top" PaperProps={{ sx: { maxWidth: 1000 } }}>
                <Stack spacing={2} pr="30px">
                    <Typography>{translateText(333)}</Typography>
                    <OrderCompleted
                        cisVoucherUrl={cisVoucherUrl}
                        externalSystemId={externalSystemId}
                        results={results}
                        isOpenedAsDialog
                        workTaskId={newWorkTaskId}
                        createNewWorkTaskOnClick
                    />
                    <Stack direction="row">
                        <Button variant="contained" color="success" onClick={handleCloseWorkTaskClick} size="extralarge">
                            {translateText(13622)}
                        </Button>
                        <Button variant="contained" sx={{ marginLeft: 1 }} color="primary" onClick={handleOpenNewWorkTaskClick} size="extralarge">
                            {translateText(13623)}
                        </Button>
                    </Stack>
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            sx={{ p: "4px 8px 4px 0" }}
                            size="small"
                            checked={hideConfirmationDialog}
                            onChange={handleHideConfirmationDialogChanged}
                        />
                        <Typography variant="label">{translateText(13631)}</Typography>
                    </Box>
                </Stack>
            </Dialog>
            {!showOrderCompletedDialog && (
                <Stack spacing={1}>
                    <OrderCompleted
                        showOrderConfirmationPdf={showOrderConfirmationPdf}
                        cisVoucherUrl={cisVoucherUrl}
                        externalSystemId={externalSystemId}
                        workTaskId={workTaskId}
                        results={results}
                        technicalErrors={technicalErrors}
                        onClearOrderSent={handleClearOrderSent}
                    />
                    {displayAutoCloseTimer && <AutoCloseInfo seconds={5} onClose={() => history.push("/")} />}
                </Stack>
            )}
            {response && (
                <TransferCompleted
                    workTaskId={workTaskId}
                    telesalesCustomerNo={telesalesCustomerNo}
                    mode={ESendCostEstimationMode.basket}
                    onClearCostEstimationSent={() => resetState()}
                />
            )}
            {workTaskBasketCalculationWithError && <ErrorAlert />}
            {workTaskBasketLoading ? <Loader justifySelf="center" py={2} /> : renderOrderGroups()}
        </Stack>
    )
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <PartsListComponent {...props} />
        </Suspense>
    )
}
