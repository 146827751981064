import { Box, Icon, Loader, Stack, Typography, styled } from "@tm/components"
import { NumberField } from "@tm/controls"

export const BoxWithAlignContent = styled(Box)({
    display: "flex",
    alignContent: "center",
})

export const StackRow = styled(Stack)({
    alignItems: "center",
})
StackRow.defaultProps = { direction: "row" }

export const LoaderSmall = styled(Loader)({})
LoaderSmall.defaultProps = { size: "extrasmall" }

export const OverflowTypography = styled(Typography)({
    overflow: "hidden",
    textOverflow: "ellipsis",
})

// TODO: Use NumberField from components
export const StyledNumberField = styled(NumberField)({
    position: "relative",
    width: 80,
    whiteSpace: "nowrap",
})

//
type StyledBoxProps = {
    backgroundColor?: string
    boxWidth?: number
    color?: string
    padding?: string
}

export const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "backgroundColor" && prop !== "boxWidth" && prop !== "color" && prop !== "padding",
})<StyledBoxProps>(({ theme, backgroundColor, boxWidth, color, padding }) => ({
    backgroundColor: backgroundColor ?? theme.palette.highlight.main,
    width: boxWidth ?? 450,
    color: color ?? "",
    padding: padding ?? "",
}))

export const SmallIcon = styled(Icon)({
    width: "16px",
    height: "16px",
})

export const TotalStack = styled(Stack)({
    alignSelf: "flex-end",
    minWidth: "500px",
})
TotalStack.defaultProps = { px: 2, spacing: 0.5 }

export const HighlightContrastTypography = styled(Typography)(({ theme }) => ({
    color:
        theme.overwrites?.components?.basketSummary?.costEstimationTotals?.color ||
        theme.palette.getContrastText(
            theme.overwrites?.components?.basketSummary?.costEstimationTotals?.backgroundColor ||
                theme.overwrites?.components?.basketSummary?.component?.backgroundColor ||
                theme.palette.highlight.main
        ),
    opacity: 1,
}))
