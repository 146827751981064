import { useMessage } from "@tm/utils"
import { useCallback, useEffect, useState } from "react"
import { useMicro } from "@tm/morpheus"
import { CustomArticle, IMicros } from "@tm/models"
import { Box, Button, Icon, Modal, styled } from "@tm/components"
import { useGetCustomArticles } from "../../../data/hooks"
import { useGetCustomArticlesCategory } from "../../../data/hooks/useCustomArticlesCategories"
import { CustomArticleGenartList, CustomMvcArticle } from "../../../model"

const percentWidth = 45

type Props = {
    title: string
}

export default function ReplaceArticleModal({ title }: Props) {
    const [messageWindow, setMessageWindow] = useState<Window | undefined>(undefined)
    const [genartNr, setGenartNr] = useState<string>("")
    const [openModal, setOpenModal] = useState(false)
    const [apiCallEnabled, setApiCallEnabled] = useState(false)
    const [enableMvcMessageSend, setEnableMvcMessageSend] = useState(false)
    const [lubricantName, setLubricantName] = useState("")
    const [selectedConsumable, setSelectedConsumable] = useState<CustomArticle | undefined>(undefined)
    const { renderMicro } = useMicro<IMicros>()

    const {
        categoryTextId: genartTextId,
        data: categoryTextIds,
        isError: customCategoryError,
    } = useGetCustomArticlesCategory(genartNr, apiCallEnabled)
    const { data: customArticles, isLoading, isError, isFetched, refetch: refetchCustomArticles } = useGetCustomArticles(genartTextId, apiCallEnabled)

    const sendCustomArticleToMvc = useCallback(
        (customArticleList: CustomArticleGenartList[] | undefined) => {
            if (messageWindow) {
                messageWindow.postMessage({ customArticles: customArticleList }, "*")
                setEnableMvcMessageSend(false)
            }
        },
        [messageWindow]
    )

    function onOutsideClick() {
        setOpenModal(false)
    }

    function onConsumableSelect(selected: CustomArticle) {
        setSelectedConsumable(selected)
    }

    function onConsumableSaved() {
        refetchCustomArticles()
    }

    function onConsumableConfirm() {
        const customMvcArticle = { ...selectedConsumable, lubricantName } as CustomMvcArticle
        sendCustomArticleToMvc([{ genart: +genartNr, customArticle: customMvcArticle }])
        onOutsideClick()
    }

    function getDefaultOrFirstArticle(articles: CustomArticle[] | undefined) {
        return articles?.find((customArticle) => customArticle.isDefaultForCategory) ?? articles?.[0]
    }

    function mapArticlesToList(articles: CustomArticle[] | undefined) {
        const articleListGroupArray: CustomArticleGenartList[] = []

        const grouppedArticles = articles?.reduce((acc, curr) => {
            const genartList = categoryTextIds?.find((x) => x.textId === curr.category)?.genArt
            genartList?.forEach((genart) => {
                if (!genart) {
                    return
                }

                const customArticleEntry = acc.get(genart)
                if (customArticleEntry) {
                    acc.set(genart, [...customArticleEntry, curr])
                } else {
                    acc.set(genart, [curr])
                }
            })
            return acc
        }, new Map<number, CustomArticle[]>())

        grouppedArticles?.forEach((groupArticle, genArt) => {
            articleListGroupArray.push({ genart: genArt, customArticle: getDefaultOrFirstArticle(groupArticle) })
        })

        return articleListGroupArray
    }

    useEffect(() => {
        if (isLoading || (!isFetched && isLoading === false) || !enableMvcMessageSend) {
            return
        }

        if (isError || customCategoryError) {
            sendCustomArticleToMvc(undefined)
            return
        }

        sendCustomArticleToMvc(mapArticlesToList(customArticles))
        // This dep array should remain like this!!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customArticles, isLoading, isError, isFetched, sendCustomArticleToMvc, customCategoryError, enableMvcMessageSend])

    useMessage((data, source) => {
        if (data.getCustomArticle) {
            const genartNrRequest = data.getCustomArticle.genartNr
            const openModalRequest = data.getCustomArticle.openModal
            setLubricantName(data.getCustomArticle.lubricantName)
            setEnableMvcMessageSend(!openModalRequest)
            setMessageWindow(source)
            setGenartNr(genartNrRequest)
            setOpenModal(openModalRequest)
            setApiCallEnabled(true)
        }
    })

    if (openModal && customArticles) {
        return (
            <Modal
                open={openModal}
                percentWidth={percentWidth}
                onOutsideClick={onOutsideClick}
                hideCloseButton
                sx={{ "&&  .MuiPaper-root>.MuiBox-root": { flex: 1, display: "flex" } }}
            >
                {renderMicro("parts", "consumable-replace", { title, consumables: customArticles, onConsumableSelect, onConsumableSaved })}
                <StyledButtonWrapper>
                    <Button size="large" onClick={onOutsideClick}>
                        <Icon name="close" />
                    </Button>
                    <Button size="large" color="highlight" onClick={onConsumableConfirm} disabled={!selectedConsumable}>
                        <Icon name="check" />
                    </Button>
                </StyledButtonWrapper>
            </Modal>
        )
    }

    return null
}

const StyledButtonWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.margin?.s ?? "0.5em",
    gap: "0.5em",
}))
