import {
    Article,
    RegisteredModels,
    OrderWarehouseInfo,
    GetArticlesRequest,
    SearchFilters,
    AddCatalogPartListRequest,
    ChangeItemsResponse,
} from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { mapArticleToAddCatalogPartListRequest } from "@tm/utils"
import { ComponentActionType, AddingToolsState } from "."

function placeArticleIntoBasket(
    articles: Article[],
    quantity: number,
    workTaskId: string,
    errorText: string,
    addToBasketSuccessText: string,
    memo: string | undefined,
    vehicleId: string | undefined,
    customerId: string | undefined,
    orderWarehouseInfo: OrderWarehouseInfo | undefined,
    distributorId: number | undefined,
    distributorName: string | undefined,
    useEqualArticlesFilter: boolean | undefined,
    addCatalogPartList: (request: AddCatalogPartListRequest) => Promise<ChangeItemsResponse | undefined>
): AsyncAction<ComponentActionType, AddingToolsState> {
    return (dispatch) => {
        dispatch({ type: "ARTICLE_LOADED", payload: articles })
        const firstArticle = articles.length === 1 || useEqualArticlesFilter ? articles[0] : undefined
        if (firstArticle) {
            //  TODO: The Service should return the property: isPseudoArticle
            // checking for the status 7 is not enough, instead isPartsListAvailable and showAddToBasket are used in the catalog's part list
            if (firstArticle.isPartsListAvailable && !firstArticle.showAddToBasket) {
                dispatch({ type: "ARTICLE_WITH_PARTS_LIST", payload: firstArticle })
            } else {
                dispatch({ type: "ADDING_ARTICLE_TO_BASKET" })

                addCatalogPartList(
                    mapArticleToAddCatalogPartListRequest(
                        [{ ...articles[0], quantity }],
                        workTaskId,
                        vehicleId,
                        customerId,
                        undefined,
                        memo,
                        undefined,
                        orderWarehouseInfo,
                        undefined,
                        distributorId,
                        distributorName
                    )
                )
                    .then(() => {
                        dispatch({ type: "ARTICLE_ADDED_TO_BASKET", payload: addToBasketSuccessText })
                    })
                    .catch(() => {
                        dispatch({ type: "ARTICLE_ADD_TO_BASKET_ERROR", payload: errorText })
                    })
            }
        } else {
            dispatch({ type: "MULTIPLE_ARTICLES_FOUND" })
        }
    }
}

function addArticleToBasket(
    wholesalerArticleNo: string,
    quantity: number,
    contextId: string,
    addToBasketErrorText: string,
    invalidWholesalerArticleNumberText: string,
    useEqualArticlesFilter: boolean,
    eanNumberSearch: boolean,
    memo: string | undefined,
    vehicleId: string | undefined,
    customerId: string | undefined,
    traderOnly: boolean | undefined,
    orderWarehouseInfo: OrderWarehouseInfo | undefined,
    distributorId: number | undefined,
    distributorName: string | undefined,
    addCatalogPartList: (request: AddCatalogPartListRequest) => Promise<ChangeItemsResponse | undefined>
): AsyncAction<ComponentActionType, AddingToolsState> {
    return (dispatch) => {
        if (wholesalerArticleNo && quantity && quantity > 0) {
            dispatch({ type: "ARTICLE_LOADING" })

            if (traderOnly) {
                const request = wholesalerArticleNo
                Container.getInstance<Array<Article>>(RegisteredModels.Articles_ByWholesalerArticleNumber)
                    .subscribe(request)
                    .load()
                    .then(
                        (articles) => {
                            const articlesCount = articles && articles.length
                            if (articlesCount) {
                                dispatch(
                                    placeArticleIntoBasket(
                                        articles,
                                        quantity,
                                        contextId,
                                        "",
                                        "",
                                        memo,
                                        vehicleId,
                                        customerId,
                                        orderWarehouseInfo,
                                        distributorId,
                                        distributorName,
                                        useEqualArticlesFilter,
                                        addCatalogPartList
                                    )
                                )
                            } else {
                                dispatch({ type: "ARTICLE_ADD_TO_BASKET_ERROR", payload: addToBasketErrorText })
                            }
                        },
                        (error) => {
                            dispatch({ type: "ARTICLE_ADD_TO_BASKET_ERROR", payload: error.modelState ? error.modelState[""] : error.message })
                        }
                    )
                    .catch((error) => {
                        dispatch({ type: "ARTICLE_ADD_TO_BASKET_ERROR", payload: error.modelState ? error.modelState[""] : error.message })
                    })
            } else {
                const request: GetArticlesRequest = {
                    query: wholesalerArticleNo,
                    searchFilter: eanNumberSearch ? SearchFilters.EuropeanArticleNumber : SearchFilters.SupplierArticleNoAndTradeReference,
                    useEqualArticlesFilter,
                }
                dispatch(
                    searchArticle(
                        request,
                        false,
                        quantity,
                        contextId,
                        addToBasketErrorText,
                        memo,
                        vehicleId,
                        customerId,
                        orderWarehouseInfo,
                        distributorId,
                        distributorName,
                        eanNumberSearch,
                        addCatalogPartList
                    )
                )
            }
        } else {
            dispatch({ type: "ARTICLE_ADD_TO_BASKET_ERROR", payload: invalidWholesalerArticleNumberText })
        }
    }
}

function searchArticle(
    request: GetArticlesRequest,
    extendedSearch: boolean,
    quantity: number,
    contextId: string,
    addToBasketErrorText: string,
    memo: string | undefined,
    vehicleId: string | undefined,
    customerId: string | undefined,
    orderWarehouseInfo: OrderWarehouseInfo | undefined,
    distributorId: number | undefined,
    distributorName: string | undefined,
    isEanNumberSearch: boolean,
    addCatalogPartList: (request: AddCatalogPartListRequest) => Promise<ChangeItemsResponse | undefined>
): AsyncAction<ComponentActionType, AddingToolsState> {
    return (dispatch) => {
        Container.getInstance<Array<Article>>(RegisteredModels.Articles)
            .subscribe(request)
            .load()
            .then(
                (articles) => {
                    const articlesCount = articles && articles.length
                    if (articlesCount) {
                        dispatch(
                            placeArticleIntoBasket(
                                articles,
                                quantity,
                                contextId,
                                "",
                                "",
                                memo,
                                vehicleId,
                                customerId,
                                orderWarehouseInfo,
                                distributorId,
                                distributorName,
                                request.useEqualArticlesFilter,
                                addCatalogPartList
                            )
                        )
                    } else if (!extendedSearch) {
                        const extendedRequest = {
                            ...request,
                            extendedAssortment: true,
                        }
                        dispatch(
                            searchArticle(
                                extendedRequest,
                                true,
                                quantity,
                                contextId,
                                addToBasketErrorText,
                                memo,
                                vehicleId,
                                customerId,
                                orderWarehouseInfo,
                                distributorId,
                                distributorName,
                                isEanNumberSearch,
                                addCatalogPartList
                            )
                        )
                    } else if (!articlesCount && isEanNumberSearch) {
                        const modifiedRequest = {
                            ...request,
                            searchFilter: SearchFilters.SupplierArticleNoAndTradeReference,
                            extendedAssortment: false,
                        }
                        dispatch(
                            searchArticle(
                                modifiedRequest,
                                false,
                                quantity,
                                contextId,
                                addToBasketErrorText,
                                memo,
                                vehicleId,
                                customerId,
                                orderWarehouseInfo,
                                distributorId,
                                distributorName,
                                false,
                                addCatalogPartList
                            )
                        )
                    } else {
                        dispatch({ type: "ARTICLE_ADD_TO_BASKET_ERROR", payload: addToBasketErrorText })
                    }
                },
                (error) => {
                    dispatch({ type: "ARTICLE_ADD_TO_BASKET_ERROR", payload: error.message })
                }
            )
            .catch((error) => {
                dispatch({ type: "ARTICLE_ADD_TO_BASKET_ERROR", payload: error.modelState ? error.modelState[""] : error.message })
            })
    }
}

// TODO Basket V2: addArticleFromTraderToBasket and  addArticleFromTraderAndSupplierToBasket can be merged
function addArticleFromTraderToBasket(
    wholesalerArticleNo: string,
    quantity: number,
    contextId: string,
    addToBasketErrorText: string,
    invalidWholesalerArticleNumberText: string,
    useEqualArticlesFilter: boolean,
    eanNumberSearch: boolean,
    memo: string | undefined,
    vehicleId: string | undefined,
    customerId: string | undefined,
    orderWarehouseInfo: OrderWarehouseInfo | undefined,
    distributorId: number | undefined,
    distributorName: string | undefined,
    addCatalogPartList: (request: AddCatalogPartListRequest) => Promise<ChangeItemsResponse | undefined>
): AsyncAction<ComponentActionType, AddingToolsState> {
    return addArticleToBasket(
        wholesalerArticleNo,
        quantity,
        contextId,
        addToBasketErrorText,
        invalidWholesalerArticleNumberText,
        useEqualArticlesFilter,
        eanNumberSearch,
        memo,
        vehicleId,
        customerId,
        true,
        orderWarehouseInfo,
        distributorId,
        distributorName,
        addCatalogPartList
    )
}

function addArticleFromTraderAndSupplierToBasket(
    wholesalerArticleNo: string,
    quantity: number,
    contextId: string,
    addToBasketErrorText: string,
    invalidWholesalerArticleNumberText: string,
    useEqualArticlesFilter: boolean,
    eanNumberSearch: boolean,
    memo: string | undefined,
    vehicleId: string | undefined,
    customerId: string | undefined,
    orderWarehouseInfo: OrderWarehouseInfo | undefined,
    distributorId: number | undefined,
    distributorName: string | undefined,
    addCatalogPartList: (request: AddCatalogPartListRequest) => Promise<ChangeItemsResponse | undefined>
): AsyncAction<ComponentActionType, AddingToolsState> {
    return addArticleToBasket(
        wholesalerArticleNo,
        quantity,
        contextId,
        addToBasketErrorText,
        invalidWholesalerArticleNumberText,
        useEqualArticlesFilter,
        eanNumberSearch,
        memo,
        vehicleId,
        customerId,
        false,
        orderWarehouseInfo,
        distributorId,
        distributorName,
        addCatalogPartList
    )
}

function selectionModalClosed(): ComponentActionType {
    return { type: "SELECTION_MODAL_CLOSED" }
}

export const Actions = {
    addArticleFromTraderToBasket,
    addArticleFromTraderAndSupplierToBasket,
    selectionModalClosed,
}
