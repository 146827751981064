import { CarModelDetailsResponse, channel, Customer, RegisteredModels, Vehicle } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { BundleActions, BundleActionTypes } from "../../../business"
import { CheckCustomerResponse } from "../../../data/repositories"
import { MainActionsType } from "../../main/business"

export type ModuleManagerState = {
    vehicle?: Vehicle
    customer?: Customer
    userIsRegistered?: boolean
    checkCustomerLoading?: boolean
}

export type ComponentActionType =
    | BundleActionTypes
    | { type: "CHECK_CUSTOMER_LOADED"; payload: CheckCustomerResponse }
    | { type: "SHOW_VEHICLE_MODELS_LOADING" }

const DEFAULT_STATE: ModuleManagerState = {
    userIsRegistered: false,
}

export const reduce = (state = DEFAULT_STATE, action: MainActionsType): ModuleManagerState => {
    switch (action.type) {
        case "VEHICLE_SET": {
            return { ...state, vehicle: action.payload }
        }
        case "CUSTOMER_SET": {
            return { ...state, customer: action.payload }
        }
        case "CHECK_CUSTOMER_LOADING": {
            return {
                ...state,
                checkCustomerLoading: true,
            }
        }
        case "PREPARE_CALC_ERROR": {
            return {
                ...state,
                checkCustomerLoading: false,
            }
        }
        case "CHECK_CUSTOMER_LOADED": {
            const { userIsRegistered } = action.payload
            return {
                ...state,
                userIsRegistered,
                checkCustomerLoading: false,
            }
        }
        default: {
            return state
        }
    }
}

function loadVehicleDetails(
    vehicle: Vehicle,
    translateText?: (key: string | number) => string
): AsyncAction<ComponentActionType, ModuleManagerState> {
    return (dispatch) => {
        dispatch({ type: "SHOW_VEHICLE_MODELS_LOADING" })

        const container = Container.getInstance<CarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
        container
            .subscribe({
                modelId: vehicle.tecDocTypeId,
                // selectedLookupType: vehicle.registrationTypeId,
                registrationNoTypeId: vehicle.registrationTypeId,
                registrationNo: vehicle.vin,
                modelIdentifier: {
                    plateId: vehicle.plateId,
                    vin: vehicle.vin,
                    initialRegistration: vehicle.initialRegistration,
                },
                forceUpdateRegistrationNoDetails: false,
            })
            .load()
            .then(
                (response) => {
                    dispatch({ type: "DETAILS_LOADED", payload: { data: response, vehicle } })
                },
                () => {
                    translateText &&
                        channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12589), closeDelay: 3000, skin: "warning" })
                }
            )
    }
}

const setVehicle = (vehicle: Vehicle): ComponentActionType => ({ type: "VEHICLE_SET", payload: vehicle })

const setCustomer = (customer: Customer): ComponentActionType => ({ type: "CUSTOMER_SET", payload: customer })

const resetStore = (): ComponentActionType => ({ type: "@@redux/INIT" })

const customerRegistered = (): ComponentActionType => ({ type: "CHECK_CUSTOMER_LOADED", payload: { userIsRegistered: true, agbUrl: "" } })

export type IActions = typeof Actions

export const Actions = { ...BundleActions, setVehicle, loadVehicleDetails, setCustomer, resetStore, customerRegistered }
