import { Box, Button, Loader, Typography, Divider, styled, Tip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useMemo, useState } from "react"
import { setValue } from "@tm/utils"
import { EFilterNames, ETyresSeason, TyreFilter, TyresSeason } from "@tm/models"
import { DRTire } from "../../../../../models"
import DropDown from "./dropDown"
import SizeDropDownWrapper from "./sizeDropDownWrapper"
import { tyresWheelsActions, useFastServiceStore } from "../../../../../data"
import { loadTiresCrits, loadTiresData } from "../../../../tyres-wheels/business"
import { ExtendEFilterNames, SelectedFilters, TireType } from "../../../../../data/state/tyres-wheels"
import { TyreAxles, TyreSpecification } from "../../../../../data/models"

type Props = {
    isFront: boolean
    selectedTabName: string
    tabType: string
    selectedFilters: SelectedFilters
    setSelectedTire: (tire?: TireType) => void
    setSelectedFiltersTemp: (filters?: SelectedFilters) => void
}

export default function GeneralDataFields(props: Props) {
    const { isFront, selectedTabName, tabType, setSelectedTire, setSelectedFiltersTemp, selectedFilters } = props
    const { translateText } = useLocalization()
    const {
        tiresData: { frontTires, rearTires, loading: loadingTyresData },
        tecDocUsed,
        loadingCrits,
        tyresCrits,
    } = useFastServiceStore((state) => state.tyresWheels)

    const { width, height, inch, loadIndex, speedIndex, size, manufacturer, tyreModel, season, vehicleLabel, sizeFromSpecs } = selectedFilters
    const stateVehicle = useFastServiceStore((state) => state.maintenancePlan.stateVehicle)
    const selectedTyreSpecification = useFastServiceStore((state) => state.tyresWheels.selectedTyreSpecification)
    const tireSpecifications = useFastServiceStore((state) => state.maintenancePlan.tireSpecifications)

    const [tyreAxles, setTyreAxles] = useState<TyreAxles | undefined>(selectedTyreSpecification)
    const [isUpdateAfterFilterChange, setIsUpdateAfterFilterChange] = useState(false)
    const [lastFilters, setLastFilters] = useState<SelectedFilters>(selectedFilters)
    const [freeInputMode, setFreeInputMode] = useState(false)

    const tires = useMemo(() => (isFront ? frontTires : rearTires), [frontTires, rearTires, isFront])
    const tireSizeFromSpecs = useMemo(() => {
        if (selectedFilters.vehicleLabel) {
            const axleSpecification = filteredTyreSizes?.find((specification) => specification.label === selectedFilters.vehicleLabel)
            if (axleSpecification?.specifications?.length === 1) {
                return `${axleSpecification?.specifications[0].frontAxle?.size} ${axleSpecification?.specifications[0].frontAxle?.wheelSize}`
            }
        }

        return ""
    }, [selectedFilters.vehicleLabel])

    const isWheelSizeSpec = useMemo(() => {
        const noWheelSizeSpec = tireSpecifications?.find((tyreSpec) => tyreSpec.specifications?.some((spec) => spec.frontAxle?.wheelSize))
        return !!noWheelSizeSpec
    }, [tireSpecifications])

    const isCritSet = useMemo(
        () =>
            !!(
                tyresCrits.width?.length &&
                tyresCrits.height?.length &&
                tyresCrits.inch?.length &&
                tyresCrits.loadIndex?.length &&
                tyresCrits.speedIndex?.length &&
                tyresCrits.tyreManufacturer?.length &&
                tyresCrits.tyreModel?.length
            ),
        [tyresCrits]
    )

    const sizeText = useMemo(
        () => `${selectedFilters.width}/${selectedFilters.height} R${selectedFilters.inch} ${selectedFilters.loadIndex}${selectedFilters.speedIndex}`,
        [selectedFilters.width, selectedFilters.height, selectedFilters.inch, selectedFilters.loadIndex, selectedFilters.speedIndex]
    )

    const selectedSizes = useMemo(
        () => selectedFilters.width !== "" && selectedFilters.height !== "" && selectedFilters.inch !== "",
        [selectedFilters.width, selectedFilters.height, selectedFilters.inch]
    )

    const selectedSizesExtended = useMemo(
        () => selectedSizes && selectedFilters.loadIndex !== "" && selectedFilters.speedIndex !== "",
        [selectedSizes, selectedFilters.loadIndex, selectedFilters.speedIndex]
    )

    const filterTyreSizes = () => {
        return tireSpecifications
            ?.map((tyreSpec) => {
                const primarySpecFilter = tyreSpec.specifications?.filter((spec) => spec.frontAxle?.size?.includes(sizeText)) ?? []

                return {
                    ...tyreSpec,
                    specifications:
                        primarySpecFilter.length > 0
                            ? primarySpecFilter
                            : tyreSpec.specifications?.filter((spec) =>
                                  spec.frontAxle?.size?.includes(`${selectedFilters.width}/${selectedFilters.height} R${selectedFilters.inch}`)
                              ),
                }
            })
            .filter((tyreSpec) => (tyreSpec.specifications?.length ?? 0) > 0)
    }

    const filteredTyreSizes = useMemo(() => {
        if (selectedSizesExtended) {
            return filterTyreSizes()
        }
        return tireSpecifications
    }, [selectedSizes, selectedFilters.loadIndex, selectedFilters.speedIndex])

    function updateCriterias(criterias: SelectedFilters) {
        if (criterias.tyreModel !== "" || freeInputMode) {
            return
        }

        loadTiresCrits(criterias)
    }

    function updatedTyreDimensionAndData() {
        if (!loadingTyresData && !loadingCrits) {
            setFreeInputMode(
                !tyresCrits.width ||
                    tires.length === 0 ||
                    (selectedFilters.width !== "" && !tyresCrits.width.some((selectedWidth) => selectedWidth.value === selectedFilters.width))
            )
        }

        if (freeInputMode) {
            return
        }

        const newSelectedFilters: SelectedFilters = { ...selectedFilters }
        const newLastFilters: SelectedFilters = lastFilters
        handleEmptyFields(newSelectedFilters, [ExtendEFilterNames.season])

        if (isUpdateAfterFilterChange || newSelectedFilters.manufacturer !== "") {
            handleFieldsCompletion(newSelectedFilters, newLastFilters)

            if (isCritSet) {
                setIsUpdateAfterFilterChange(false)
            }
            setSelectedFiltersTemp(newSelectedFilters)
        }
    }

    useEffect(() => {
        updatedTyreDimensionAndData()
    }, [tyresCrits, tires, freeInputMode, tireSizeFromSpecs])

    useEffect(() => {
        if (tecDocUsed !== stateVehicle?.tecDocTypeId && !frontTires.length && !freeInputMode) {
            loadTiresData()
            loadTiresCrits(selectedFilters)
        }
    }, [])

    const updateRearAndSpareTire = (passedTabType: string) => {
        if (passedTabType === "rear") {
            setSelectedTire(TireType.RearTire)
        }

        if (passedTabType === "spareWheel") {
            setSelectedTire(TireType.SpareTire)
        }
    }

    const isValidSpecification = (specification: TyreAxles) => {
        return (
            !!specification.frontAxle &&
            !!specification.rearAxle &&
            !!specification.frontAxle.size &&
            !!specification.rearAxle.size &&
            !!specification.frontAxle.wheelSize &&
            !!specification.rearAxle.wheelSize
        )
    }

    const findTyreSpecification = (filter: string) => {
        let destructuredSizes: SelectedFilters = { ...selectedFilters }
        if (destructuredSizes.width === "" && destructuredSizes.height === "" && destructuredSizes.inch === "") {
            destructuredSizes = handleSizeFromSpecs(destructuredSizes, filter)
        }

        const shortSize = `${destructuredSizes.width}/${destructuredSizes.height} R${destructuredSizes.inch}`
        let tyreSpecification: TyreAxles | undefined

        if (selectedFilters.vehicleLabel) {
            const tyreSpecificationsByLabel = filteredTyreSizes?.find((axleSpecification) => axleSpecification.label === selectedFilters.vehicleLabel)

            tyreSpecification = tyreSpecificationsByLabel?.specifications?.find((specification) => {
                if (isValidSpecification(specification)) {
                    const frontAxleSizeIncludesShortSize = specification.frontAxle!.size?.includes(shortSize)
                    const rearAxleSizeIncludesShortSize = specification.rearAxle!.size?.includes(shortSize)
                    const filterIncludesFrontWheelSize = filter.includes(specification.frontAxle!.wheelSize!)
                    const filterIncludesRearWheelSize = filter.includes(specification.rearAxle!.wheelSize!)

                    const frontOrRearWheelSizeIncluded = filterIncludesFrontWheelSize || filterIncludesRearWheelSize
                    const frontOrRearSizeIncluded = filter.includes(specification.frontAxle!.size!) || filter.includes(specification.rearAxle!.size!)

                    if (frontAxleSizeIncludesShortSize || rearAxleSizeIncludesShortSize) {
                        return frontOrRearWheelSizeIncluded && filter.includes(shortSize)
                    }
                    return frontOrRearWheelSizeIncluded && frontOrRearSizeIncluded
                }

                return !!specification.frontAxle!.pressure
            })
            return tyreSpecification
        }

        filteredTyreSizes?.forEach((axleSpecifications) => {
            tyreSpecification = axleSpecifications?.specifications?.find(
                (specification) =>
                    isValidSpecification(specification) &&
                    (specification.frontAxle!.size!.length === shortSize.length
                        ? (filter.includes(specification.frontAxle!.wheelSize!) || filter.includes(specification.rearAxle!.wheelSize!)) &&
                          filter.includes(shortSize)
                        : (filter.includes(specification.frontAxle!.wheelSize!) || filter.includes(specification.rearAxle!.wheelSize!)) &&
                          (filter.includes(specification.frontAxle!.size!) || filter.includes(specification.rearAxle!.size!)))
            )
        })

        return tyreSpecification
    }

    const extractLabelsFromTyreSpecification = () => {
        const vehicleLabels: string[] = []

        filteredTyreSizes?.forEach((specification) => {
            if (specification.label) {
                vehicleLabels.push(specification.label)
            }
        })

        return vehicleLabels
    }

    const handleTyreAxlesSelection = (filter: string) => {
        const tyreSpecification = findTyreSpecification(filter)
        setTyreAxles(tyreSpecification)

        if (tyreSpecification) {
            tyresWheelsActions.setTyreSpecification(tyreSpecification)
        }
    }

    const handleFindInputCrit = (crits: TyreFilter[] | undefined, filter: string) => {
        return crits?.find((crit) => crit.value === filter)
    }

    const handleFreeInputModeEnable = (path: string, filter: string) => {
        switch (path) {
            case ExtendEFilterNames.width:
                return handleFindInputCrit(tyresCrits.width, filter)
            case ExtendEFilterNames.height:
                return handleFindInputCrit(tyresCrits.height, filter)
            case ExtendEFilterNames.inch:
                return handleFindInputCrit(tyresCrits.inch, filter)
            case ExtendEFilterNames.loadIndex:
                return handleFindInputCrit(tyresCrits.loadIndex, filter)
            case ExtendEFilterNames.speedIndex:
                return handleFindInputCrit(tyresCrits.speedIndex, filter)
            case ExtendEFilterNames.size:
                return true
            case ExtendEFilterNames.manufacturer:
                return handleFindInputCrit(tyresCrits.tyreManufacturer, filter)
            case ExtendEFilterNames.tyreModel:
                return handleFindInputCrit(tyresCrits.tyreModel, filter)
            case ExtendEFilterNames.season:
                return true
            default:
        }
    }

    const handleFilterSelect = (path: string, filter: string) => {
        let newSelectedFilters: SelectedFilters = setValue({ ...selectedFilters }, [path], filter)

        if (!handleFreeInputModeEnable(path, filter)) {
            setFreeInputMode(true)
        }

        if (
            [
                ExtendEFilterNames.width,
                ExtendEFilterNames.height,
                ExtendEFilterNames.inch,
                ExtendEFilterNames.loadIndex,
                ExtendEFilterNames.speedIndex,
                ExtendEFilterNames.manufacturer,
            ].includes(path as EFilterNames) ||
            path === ExtendEFilterNames.vehicleLabel ||
            path === ExtendEFilterNames.size
        ) {
            if (path === ExtendEFilterNames.manufacturer) {
                const tyreManufacturerId = tyresCrits.tyreManufacturer?.find(
                    (tyreManufacturer) => tyreManufacturer.value === newSelectedFilters.manufacturer
                )?.query

                if (!tyreManufacturerId) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.manufacturerId], filter)
                    newSelectedFilters = emptyFieldsOnValueChange(newSelectedFilters, path)
                    setLastFilters(selectedFilters)
                    setSelectedFiltersTemp(newSelectedFilters)
                    updateRearAndSpareTire(tabType)
                    return
                }

                setValue(newSelectedFilters, [ExtendEFilterNames.manufacturerId], tyreManufacturerId)
            }

            if (path === ExtendEFilterNames.size) {
                setValue(newSelectedFilters, [ExtendEFilterNames.size], sizeText)
                setValue(newSelectedFilters, [ExtendEFilterNames.sizeFromSpecs], filter)
                handleTyreAxlesSelection(filter)
                if (!selectedSizesExtended) {
                    newSelectedFilters = handleSizeFromSpecs(newSelectedFilters, filter)
                }
            }

            newSelectedFilters = emptyFieldsOnValueChange(newSelectedFilters, path)
            updateCriterias(newSelectedFilters)
            setLastFilters(selectedFilters)
            setIsUpdateAfterFilterChange(true)
        }

        setSelectedFiltersTemp(newSelectedFilters)
        updateRearAndSpareTire(tabType)
    }

    const handleSizeFilterSelect = (filter: DRTire) => {
        const newFilters = { ...selectedFilters, ...filter }
        newFilters.vehicleLabel = ""
        newFilters.sizeFromSpecs = ""
        newFilters.manufacturer = ""
        newFilters.tyreModel = ""
        newFilters.season = ""
        setTyreAxles(undefined)
        setSelectedFiltersTemp(newFilters)
        updateCriterias(newFilters)
        updateRearAndSpareTire(props.tabType)
    }

    const handleFieldsCompletion = (newSelectedFilters: SelectedFilters, newLastFilters: SelectedFilters) => {
        switch (true) {
            case newSelectedFilters.width === "":
                if (tyresCrits.width?.length === 1 && !tyresCrits.loadIndex?.length) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.width], tyresCrits.width[0]?.value)
                }
            // eslint-disable-next-line no-fallthrough
            case newSelectedFilters.height === "":
                if (tyresCrits.height?.length === 1 && !tyresCrits.loadIndex?.length) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.height], tyresCrits.height[0]?.value)
                    updateCriterias(newSelectedFilters)
                } else if (tyresCrits.height?.some((x) => x.value === lastFilters?.height)) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.height], lastFilters?.height)
                    setValue(newLastFilters, [ExtendEFilterNames.height], "")
                    setLastFilters(newLastFilters)
                    updateCriterias(newSelectedFilters)
                }
            // eslint-disable-next-line no-fallthrough
            case newSelectedFilters.inch === "":
                if (tyresCrits.inch?.length === 1 && !tyresCrits.loadIndex?.length) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.inch], tyresCrits.inch[0]?.value)
                    updateCriterias(newSelectedFilters)
                } else if (tyresCrits.inch?.some((x) => x.value === lastFilters?.inch)) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.inch], lastFilters?.inch)
                    setValue(newLastFilters, [ExtendEFilterNames.inch], "")
                    setLastFilters(newLastFilters)
                    updateCriterias(newSelectedFilters)
                }
            // eslint-disable-next-line no-fallthrough
            case newSelectedFilters.loadIndex === "":
                if (tyresCrits.loadIndex?.length === 1 && selectedSizes) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.loadIndex], tyresCrits.loadIndex[0]?.value)
                    updateCriterias(newSelectedFilters)
                } else if (tyresCrits.loadIndex?.some((x) => x.value === lastFilters?.loadIndex)) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.loadIndex], lastFilters?.loadIndex)
                    setValue(newLastFilters, [ExtendEFilterNames.loadIndex], "")
                    setLastFilters(newLastFilters)
                    updateCriterias(newSelectedFilters)
                }
            // eslint-disable-next-line no-fallthrough
            case newSelectedFilters.speedIndex === "":
                if (tyresCrits.speedIndex?.length === 1 && selectedSizes) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.speedIndex], tyresCrits.speedIndex[0]?.value)
                    updateCriterias(newSelectedFilters)
                } else if (tyresCrits.speedIndex?.some((x) => x.value === lastFilters?.speedIndex)) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.speedIndex], lastFilters?.speedIndex)
                    setValue(newLastFilters, [ExtendEFilterNames.speedIndex], "")
                    setLastFilters(newLastFilters)
                    updateCriterias(newSelectedFilters)
                }
            // eslint-disable-next-line no-fallthrough
            case newSelectedFilters.vehicleLabel === "":
                const vehicleLabels = extractLabelsFromTyreSpecification()
                if (vehicleLabels?.length === 1) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.vehicleLabel], vehicleLabels[0])
                    updateCriterias(newSelectedFilters)
                }
            // eslint-disable-next-line no-fallthrough
            case newSelectedFilters.manufacturer === "":
                if (tyresCrits.tyreManufacturer?.length === 1 && selectedSizes) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.manufacturer], tyresCrits.tyreManufacturer[0]?.value)
                    setValue(newSelectedFilters, [ExtendEFilterNames.manufacturerId], tyresCrits.tyreManufacturer[0]?.query)
                    updateCriterias(newSelectedFilters)
                } else if (tyresCrits.tyreManufacturer?.some((x) => x.value === lastFilters?.manufacturer)) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.manufacturer], lastFilters?.manufacturer)
                    setValue(newSelectedFilters, [ExtendEFilterNames.manufacturerId], lastFilters?.manufacturerId)
                    setValue(newLastFilters, [ExtendEFilterNames.manufacturer], "")
                    setValue(newLastFilters, [ExtendEFilterNames.manufacturerId], "")
                    setLastFilters(newLastFilters)
                    updateCriterias(newSelectedFilters)
                }
            // eslint-disable-next-line no-fallthrough
            case newSelectedFilters.tyreModel === "":
                if (tyresCrits.tyreModel?.length === 1 && selectedSizes) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.tyreModel], tyresCrits.tyreModel[0]?.value)
                    updateCriterias(newSelectedFilters)
                } else if (tyresCrits.tyreModel?.some((x) => x.value === lastFilters?.tyreModel)) {
                    setValue(newSelectedFilters, [ExtendEFilterNames.tyreModel], lastFilters?.tyreModel)
                    setValue(newLastFilters, [ExtendEFilterNames.tyreModel], "")
                    setLastFilters(newLastFilters)
                    updateCriterias(newSelectedFilters)
                }
                break
            default:
        }
    }

    const handleEmptyFields = (selections: SelectedFilters, fields: string[]) => {
        fields.forEach((field) => {
            setValue(selections, [field], "")
        })
    }

    const emptyFieldsOnValueChange = (selections: SelectedFilters, path: string) => {
        if (freeInputMode) {
            return selections
        }

        switch (path) {
            case ExtendEFilterNames.vehicleLabel: {
                if (selectedFilters.vehicleLabel !== selections.vehicleLabel && !selectedSizesExtended) {
                    handleEmptyFields(selections, [
                        ExtendEFilterNames.width,
                        ExtendEFilterNames.height,
                        ExtendEFilterNames.inch,
                        ExtendEFilterNames.loadIndex,
                        ExtendEFilterNames.speedIndex,
                        ExtendEFilterNames.manufacturerId,
                        ExtendEFilterNames.manufacturer,
                        ExtendEFilterNames.tyreModel,
                        ExtendEFilterNames.size,
                        ExtendEFilterNames.rimSize,
                        ExtendEFilterNames.season,
                        ExtendEFilterNames.sizeFromSpecs,
                    ])
                }
                setTyreAxles(undefined)
                break
            }
            case ExtendEFilterNames.width: {
                if (selectedFilters.width !== selections.width) {
                    handleEmptyFields(selections, [
                        ExtendEFilterNames.height,
                        ExtendEFilterNames.inch,
                        ExtendEFilterNames.loadIndex,
                        ExtendEFilterNames.speedIndex,
                        ExtendEFilterNames.manufacturerId,
                        ExtendEFilterNames.manufacturer,
                        ExtendEFilterNames.tyreModel,
                        ExtendEFilterNames.size,
                        ExtendEFilterNames.rimSize,
                        ExtendEFilterNames.season,
                        ExtendEFilterNames.vehicleLabel,
                        ExtendEFilterNames.sizeFromSpecs,
                    ])
                }
                setTyreAxles(undefined)
                break
            }
            case ExtendEFilterNames.height: {
                if (selectedFilters.height !== selections.height) {
                    handleEmptyFields(selections, [
                        ExtendEFilterNames.inch,
                        ExtendEFilterNames.loadIndex,
                        ExtendEFilterNames.speedIndex,
                        ExtendEFilterNames.manufacturerId,
                        ExtendEFilterNames.manufacturer,
                        ExtendEFilterNames.tyreModel,
                        ExtendEFilterNames.size,
                        ExtendEFilterNames.rimSize,
                        ExtendEFilterNames.season,
                    ])
                }
                break
            }
            case ExtendEFilterNames.inch: {
                if (selectedFilters.inch !== selections.inch) {
                    handleEmptyFields(selections, [
                        ExtendEFilterNames.loadIndex,
                        ExtendEFilterNames.speedIndex,
                        ExtendEFilterNames.manufacturerId,
                        ExtendEFilterNames.manufacturer,
                        ExtendEFilterNames.tyreModel,
                        ExtendEFilterNames.size,
                        ExtendEFilterNames.rimSize,
                        ExtendEFilterNames.season,
                    ])
                }
                setTyreAxles(undefined)
                break
            }
            case ExtendEFilterNames.loadIndex: {
                if (selectedFilters.loadIndex !== selections.loadIndex) {
                    handleEmptyFields(selections, [
                        ExtendEFilterNames.speedIndex,
                        ExtendEFilterNames.manufacturerId,
                        ExtendEFilterNames.manufacturer,
                        ExtendEFilterNames.tyreModel,
                        ExtendEFilterNames.size,
                        ExtendEFilterNames.rimSize,
                        ExtendEFilterNames.season,
                    ])
                }
                setTyreAxles(undefined)
                break
            }
            case ExtendEFilterNames.speedIndex: {
                if (selectedFilters.speedIndex !== selections.speedIndex) {
                    handleEmptyFields(selections, [
                        ExtendEFilterNames.manufacturerId,
                        ExtendEFilterNames.manufacturer,
                        ExtendEFilterNames.tyreModel,
                        ExtendEFilterNames.size,
                        ExtendEFilterNames.rimSize,
                        ExtendEFilterNames.season,
                    ])
                }
                setTyreAxles(undefined)
                break
            }
            case ExtendEFilterNames.manufacturer: {
                if (selectedFilters.manufacturer !== selections.manufacturer) {
                    handleEmptyFields(selections, [ExtendEFilterNames.tyreModel, ExtendEFilterNames.season])
                }
                break
            }
            default:
                break
        }

        return selections
    }

    const filteredTyreSpecificationValues = () => {
        const specsValues: string[] = []
        const shortSize =
            selectedFilters.width && selectedFilters.height && selectedFilters.inch
                ? `${selectedFilters.width}/${selectedFilters.height} R${selectedFilters.inch}`
                : ""

        if (selectedFilters.vehicleLabel) {
            const tyreSpecs: TyreSpecification | undefined = filteredTyreSizes?.find(
                (specification) => specification.label === selectedFilters.vehicleLabel
            )
            tyreSpecs?.specifications?.forEach((specs) => {
                if (specs.frontAxle?.size) {
                    if (specs.frontAxle?.wheelSize) {
                        specsValues.push(`${specs.frontAxle.size} ${specs.frontAxle.wheelSize}`)
                    } else {
                        specsValues.push(specs.frontAxle.size)
                    }
                }
            })

            return specsValues.distinct()
        }

        let filteredSpecs: TyreAxles[] | undefined
        filteredTyreSizes?.forEach((axleSpecifications) => {
            filteredSpecs = axleSpecifications?.specifications?.filter(
                (tireSize) =>
                    isValidSpecification(tireSize) &&
                    (tireSize.frontAxle!.size!.length >= sizeText.length
                        ? tireSize.frontAxle!.size!.includes(sizeText) || tireSize.rearAxle!.size!.includes(sizeText)
                        : tireSize.frontAxle!.size!.includes(shortSize) || tireSize.rearAxle!.size!.includes(shortSize))
            )
        })

        filteredSpecs?.forEach((specs) => {
            specsValues.push(`${sizeText} ${specs.frontAxle?.wheelSize}`)
        })

        return specsValues.distinct()
    }

    const singleTyreInformation: TyreAxles | undefined = useMemo(() => {
        if (selectedSizes && selectedFilters.speedIndex && selectedFilters.loadIndex) {
            const tyreSizeValue = filteredTyreSpecificationValues()

            if (tyreSizeValue.length === 1) {
                const tyreSpecification = findTyreSpecification(tyreSizeValue[0])
                return tyreSpecification
            }
        }
    }, [selectedSizes, selectedFilters.speedIndex, selectedFilters.loadIndex])

    const handleSizeFromSpecs = (newSelectedFilters: SelectedFilters, sizeData: string) => {
        const sizeParts = sizeData?.split(" ")
        const dimensions = sizeParts[0]?.split("/")
        setValue(newSelectedFilters, [ExtendEFilterNames.width], dimensions[0])
        setValue(newSelectedFilters, [ExtendEFilterNames.height], dimensions[1])
        const inchIndex = sizeParts?.findIndex((part) => part?.includes("R"))
        setValue(newSelectedFilters, [ExtendEFilterNames.inch], sizeParts[inchIndex]?.replace("R", ""))
        let findLoadIndex: string[] | null = null
        let findSpeedIndex: string[] | null = null

        for (let i = inchIndex + 1; i < sizeParts.length; i++) {
            const loadSpeedIndexMatch = sizeParts[2]?.match(".*\\d$")
            if (loadSpeedIndexMatch) {
                break
            }

            const loadMatch = sizeParts[i]?.match(/^\d+/)
            if (loadMatch) {
                findLoadIndex = loadMatch
                const speedMatch = sizeParts[i]?.match(/[A-Z]+/)
                if (speedMatch) {
                    findSpeedIndex = speedMatch
                }
                break
            }
        }

        if (findLoadIndex) {
            setValue(newSelectedFilters, [ExtendEFilterNames.loadIndex], findLoadIndex[0])
        }

        if (findSpeedIndex) {
            setValue(newSelectedFilters, [ExtendEFilterNames.speedIndex], findSpeedIndex[0])
        }

        return newSelectedFilters
    }

    const renderVehicleLabelValue = () => {
        if (tireSpecifications?.length === 1) {
            return tireSpecifications[0].label
        }

        return ""
    }

    const renderTyreSizeValue = () => {
        const tyreSizeValue = filteredTyreSpecificationValues()

        if (tyreSizeValue.length === 0 && selectedSizesExtended && !selectedTyreSpecification) {
            handleTyreAxlesSelection(sizeText)
            return sizeText
        }

        if (tyreSizeValue.length === 1) {
            !selectedTyreSpecification && handleTyreAxlesSelection(tyreSizeValue[0])
            return tyreSizeValue[0]
        }

        return selectedSizesExtended ? `${sizeText} ${tyreAxles?.frontAxle?.wheelSize ?? ""}` : ""
    }

    const renderExtraInformation = () => {
        if (singleTyreInformation) {
            return displayExtraInformation(sizeText, singleTyreInformation.frontAxle?.wheelSize, singleTyreInformation.frontAxle?.wheelOffset)
        }

        if (selectedSizes && tyreAxles?.frontAxle?.wheelSize) {
            return displayExtraInformation(sizeText, tyreAxles?.frontAxle?.wheelSize, tyreAxles?.frontAxle?.wheelOffset)
        }

        return <></>
    }

    const displayExtraInformation = (tyreSelectedSize: string, wheelSize?: string, wheelOffset?: string) => {
        return (
            <Box marginLeft="1em" flexBasis="25%" minWidth="fit-content">
                <FlexDiv>
                    {`${translateText(967)}:`}
                    <Box paddingLeft="0.75em" fontWeight="bold">
                        {tyreSelectedSize}
                    </Box>
                </FlexDiv>
                <Typography />
                <FlexDiv>
                    {`${translateText(1029)}:`}
                    <Box paddingLeft="0.75em" fontWeight="bold">
                        {wheelSize}
                    </Box>
                </FlexDiv>
                {wheelOffset && (
                    <FlexDiv>
                        {`${translateText(1135)}:`}
                        <Box paddingLeft="0.75em" fontWeight="bold">
                            {wheelOffset}
                        </Box>
                    </FlexDiv>
                )}
            </Box>
        )
    }

    return (
        <Box>
            {loadingCrits && <Loader sx={{ position: "absolute", top: "50%", left: "50%" }} />}
            <Box sx={loadingCrits ? { opacity: "0.54", pointerEvents: "none" } : {}}>
                <Box>
                    <Typography marginBottom="0.5em" variant="h3">
                        {translateText(13071)}
                    </Typography>
                    <Typography marginBottom="1em" variant="h4">
                        {translateText(13072)}
                    </Typography>
                    <StyledBox>
                        <SizeDropDownWrapper
                            items={tires}
                            onChange={handleSizeFilterSelect}
                            selectedValue={(!!size && !!loadIndex && `${size} ${loadIndex ?? ""}${speedIndex ?? ""}`) || undefined}
                            isLoading={loadingTyresData}
                        />
                        <DropDown
                            items={tyresCrits.width?.map((x) => x.value) ?? []}
                            name={translateText(713)}
                            path={ExtendEFilterNames.width}
                            selectedValue={(!!width && width) || ""}
                            onChange={handleFilterSelect}
                            focusInput={!selectedFilters.width && !selectedFilters.vehicleLabel && !loadingCrits}
                            isLoading={!selectedFilters.width ? loadingCrits : false}
                            disableDropDown={freeInputMode ? false : loadingCrits}
                            inputMode="numeric"
                        />
                        <Typography variant="h3">/</Typography>
                        <DropDown
                            items={tyresCrits.height?.map((x) => x.value) ?? []}
                            name={translateText(714)}
                            path={ExtendEFilterNames.height}
                            selectedValue={(!!height && height) || ""}
                            onChange={handleFilterSelect}
                            focusInput={!!(selectedFilters.width && !selectedFilters.height) && !loadingCrits}
                            isLoading={freeInputMode ? false : loadingCrits}
                            disableDropDown={freeInputMode ? false : loadingCrits}
                            inputMode="numeric"
                        />
                        <Typography variant="h3">R</Typography>
                        <DropDown
                            items={tyresCrits.inch?.map((x) => x.value) ?? []}
                            name={translateText(743)}
                            path={ExtendEFilterNames.inch}
                            selectedValue={(!!inch && inch) || ""}
                            onChange={handleFilterSelect}
                            focusInput={!!(selectedFilters.height && !selectedFilters.inch) && !loadingCrits}
                            isLoading={freeInputMode ? false : loadingCrits}
                            disableDropDown={freeInputMode ? false : loadingCrits}
                            inputMode="numeric"
                        />
                    </StyledBox>
                    <StyledBox>
                        <DropDown
                            items={tyresCrits.loadIndex?.map((x) => x.value) ?? []}
                            name={translateText(13074)}
                            path={ExtendEFilterNames.loadIndex}
                            selectedValue={(!!loadIndex && loadIndex) || ""}
                            onChange={handleFilterSelect}
                            focusInput={!!(selectedSizes && !selectedFilters.loadIndex) && !loadingCrits}
                            isLoading={freeInputMode ? false : loadingCrits}
                            disableDropDown={freeInputMode ? false : loadingCrits}
                            inputMode="numeric"
                        />
                        <DropDown
                            items={tyresCrits.speedIndex?.map((x) => x.value) ?? []}
                            name={translateText(719)}
                            path={ExtendEFilterNames.speedIndex}
                            selectedValue={(!!speedIndex && speedIndex) || ""}
                            onChange={handleFilterSelect}
                            focusInput={!!(selectedSizes && selectedFilters.loadIndex && !selectedFilters.speedIndex) && !loadingCrits}
                            isLoading={freeInputMode ? false : loadingCrits}
                            disableDropDown={freeInputMode ? false : loadingCrits}
                        />
                    </StyledBox>

                    <Divider light sx={{ margin: "1.5em 0" }} />

                    <StyledBox>
                        <Typography flexBasis="20%" minWidth="fit-content">
                            {translateText(13702)}
                        </Typography>
                        <Tip text={translateText(13701)} />
                    </StyledBox>
                    <StyledBox>
                        <Box sx={{ width: "100%" }}>
                            {filteredTyreSizes && (
                                <StyledBox>
                                    <DropDown
                                        name={translateText(12838)}
                                        path={ExtendEFilterNames.vehicleLabel}
                                        items={extractLabelsFromTyreSpecification()}
                                        onChange={handleFilterSelect}
                                        selectedValue={
                                            (!!vehicleLabel && vehicleLabel) || (selectedFilters.speedIndex && renderVehicleLabelValue()) || ""
                                        }
                                        disableDropDown={freeInputMode ? false : loadingCrits}
                                        focusInput={
                                            !!(
                                                !selectedFilters.vehicleLabel &&
                                                selectedSizes &&
                                                selectedFilters.speedIndex &&
                                                !tyreAxles &&
                                                !loadingCrits
                                            )
                                        }
                                    />
                                </StyledBox>
                            )}
                            <StyledBox>
                                <DropDown
                                    name={translateText(712)}
                                    path={ExtendEFilterNames.size}
                                    items={filteredTyreSpecificationValues()}
                                    onChange={handleFilterSelect}
                                    selectedValue={
                                        (!!sizeFromSpecs && sizeFromSpecs) ||
                                        (selectedFilters.vehicleLabel && renderTyreSizeValue()) ||
                                        (selectedSizesExtended && sizeText) ||
                                        ""
                                    }
                                    disableDropDown={
                                        freeInputMode || vehicleLabel
                                            ? false
                                            : !selectedSizes || selectedFilters.loadIndex === "" || selectedFilters.speedIndex === ""
                                    }
                                    focusInput={
                                        (!!(selectedSizes && selectedFilters.speedIndex && !tyreAxles && !loadingCrits) ||
                                            (!!selectedFilters.vehicleLabel && !selectedFilters.sizeFromSpecs && !loadingCrits)) &&
                                        filteredTyreSpecificationValues().length > 1
                                    }
                                />
                            </StyledBox>
                        </Box>
                        {renderExtraInformation()}
                    </StyledBox>
                </Box>

                <Divider light sx={{ margin: "1.5em 0" }} />

                <Box display="flex" flexDirection="column">
                    <Typography marginBottom="1em" variant="h4">
                        {translateText(13076)}
                    </Typography>
                    <StyledBox>
                        <DropDown
                            items={tyresCrits.tyreManufacturer?.map((x) => x.value) ?? []}
                            name={translateText(71)}
                            path={ExtendEFilterNames.manufacturer}
                            selectedValue={(!!manufacturer && manufacturer) || ""}
                            onChange={handleFilterSelect}
                            focusInput={
                                filteredTyreSizes
                                    ? !!(
                                          selectedFilters.speedIndex &&
                                          (selectedTyreSpecification || !isWheelSizeSpec) &&
                                          !selectedFilters.manufacturer &&
                                          (selectedFilters.vehicleLabel || !isWheelSizeSpec) &&
                                          selectedFilters.size
                                      ) && !loadingCrits
                                    : !!(
                                          selectedFilters.speedIndex &&
                                          (tyreAxles || singleTyreInformation || selectedFilters.size) &&
                                          !selectedFilters.manufacturer
                                      ) && !loadingCrits
                            }
                            isLoading={freeInputMode ? false : loadingCrits}
                            disableDropDown={freeInputMode ? false : loadingCrits}
                        />
                        <DropDown
                            items={
                                tyresCrits.tyreManufacturer?.some((x) => x.value === selectedFilters.manufacturer)
                                    ? tyresCrits.tyreModel?.map((x) => x.value) ?? []
                                    : []
                            }
                            name={translateText(13077)}
                            path={ExtendEFilterNames.tyreModel}
                            selectedValue={(!!tyreModel && tyreModel) || ""}
                            onChange={handleFilterSelect}
                            focusInput={!!(selectedFilters.manufacturer && !selectedFilters.tyreModel) && !loadingCrits}
                            isLoading={freeInputMode ? false : loadingCrits}
                            disableDropDown={freeInputMode ? false : selectedFilters.manufacturer === ""}
                        />
                    </StyledBox>

                    <StyledBox>
                        <Typography marginRight=".5rem">{translateText(13078)}</Typography>
                        {[ETyresSeason.summer, ETyresSeason.winter, ETyresSeason.allSeason].map((seasonKey) => {
                            const defaultVariant: "text" | "contained" | "outlined" = seasonKey === season ? "outlined" : "contained"
                            if (
                                selectedFilters.width &&
                                tyresCrits.season?.length === 1 &&
                                tyresCrits.season[0].value === seasonKey &&
                                !selectedFilters.season
                            ) {
                                handleFilterSelect(ExtendEFilterNames.season, seasonKey)
                            }
                            return (
                                <Button
                                    key={seasonKey}
                                    disabled={
                                        freeInputMode || (selectedSizes && tyresCrits.season?.length === 0)
                                            ? false
                                            : !selectedFilters.width || !tyresCrits.season?.some((x) => x.value === seasonKey)
                                    }
                                    onClick={() => handleFilterSelect(ExtendEFilterNames.season, seasonKey)}
                                    variant={
                                        !!selectedFilters.width && tyresCrits.season?.length === 1 && tyresCrits.season[0].value === seasonKey
                                            ? "outlined"
                                            : defaultVariant
                                    }
                                    size="large"
                                >
                                    {translateText(getSeasonTitle(seasonKey))}
                                </Button>
                            )
                        })}
                    </StyledBox>
                </Box>
            </Box>
        </Box>
    )
}

const getSeasonTitle = (value: TyresSeason) => {
    switch (value) {
        case ETyresSeason.summer:
            return 800
        case ETyresSeason.winter:
            return 801
        case ETyresSeason.allSeason:
            return 13079
        default:
            return 0
    }
}

const StyledBox = styled(Box)(({ theme }) => ({
    gap: ".5em",
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(1),
}))

const FlexDiv = styled(Box)({
    display: "flex",
})
