import { useState, useRef } from "react"
import { Tooltip, Button } from "@tm/controls"
import { ConstructionYearFilter } from "../../../data/model"

type Props = {
    items: Array<ConstructionYearFilter>
    activeItemId?: number
    onChange(id: number): void
}

export default function ConstructionYearFilterComponent(props: Props) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const ref = useRef<Tooltip>(null)
    const activeItem = props.activeItemId && props.items.find((x) => x.id === props.activeItemId)

    function handleChange(id: number) {
        props.onChange(id)
        if (ref.current) {
            ref.current.hide()
        }
    }

    return (
        <div className="selection-list selection-list--s">
            <Tooltip
                ref={ref}
                content={
                    <div>
                        {props.items.map((item) => {
                            return (
                                <div className="input__list-item filter--construction-year__item" key={item.id} onClick={() => handleChange(item.id)}>
                                    {item.name}
                                </div>
                            )
                        })}
                    </div>
                }
                event="click"
                style="primary"
                position="top"
                onChangeVisibility={setIsOpen}
            >
                <Button className="filter--construction-year__button" icon={isOpen ? "up" : "down"}>
                    {activeItem && activeItem.name}
                </Button>
            </Tooltip>
        </div>
    )
}
