import { useUser, useWorkTask } from "@tm/context-distribution"
import { ArticleNumber, Stack, styled } from "@tm/components"
import { useOpenBasketArticleDetails } from "../../hooks/useOpenBasketArticleDetails"

/* flexBase can be overwritten by theme.json (Styles Projekt) */
const StyledStack = styled(Stack)(({ theme }) => {
    const flexBase = theme.overwrites?.toolkits?.basket?.articleNumbersComponentFlexBase || "17em"
    return {
        flex: `0 0 ${flexBase}`,
        minWidth: "100px",
        alignItems: "flex-start !important",
    }
})

type Props = {
    articleNumber?: string
    isLinkedItem?: boolean
    oeArticleNumber?: string
    productGroupId?: number
    supplierArticleNumber?: string
    supplierId?: number
    wholesalerArticleNumber?: string
    workTaskId?: string // Required for the central ordering
}

export default function ArticleNumbersComponent(props: Props) {
    const { supplierId, supplierArticleNumber, wholesalerArticleNumber, productGroupId, articleNumber, oeArticleNumber, isLinkedItem, workTaskId } =
        props
    const userContext = useUser()?.userContext
    const workTask = useWorkTask()
    const handleOpenArticleDetails = useOpenBasketArticleDetails(workTaskId ?? workTask?.workTaskId)

    if (articleNumber) {
        return (
            <StyledStack>
                <ArticleNumber articleNumber={articleNumber} articleNumberType="custom" />
            </StyledStack>
        )
    }

    if (oeArticleNumber) {
        return (
            <StyledStack direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
                {wholesalerArticleNumber && !userContext?.parameter.hideDealerPartNumber && (
                    <ArticleNumber
                        articleNumber={wholesalerArticleNumber}
                        articleNumberType="wholesaler"
                        omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    />
                )}
                <ArticleNumber
                    articleNumber={oeArticleNumber}
                    articleNumberType="oe"
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                />
            </StyledStack>
        )
    }

    return (
        <StyledStack direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
            {wholesalerArticleNumber && !userContext?.parameter.hideDealerPartNumber && (
                <ArticleNumber
                    articleNumber={wholesalerArticleNumber}
                    articleNumberType={isLinkedItem ? "linked" : "wholesaler"}
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    onClick={() => handleOpenArticleDetails(productGroupId, supplierArticleNumber, supplierId)}
                />
            )}
            {supplierArticleNumber && (
                <ArticleNumber
                    articleNumber={supplierArticleNumber}
                    articleNumberType={isLinkedItem ? "linked" : "supplier"}
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    onClick={() => handleOpenArticleDetails(productGroupId, supplierArticleNumber, supplierId)}
                />
            )}
        </StyledStack>
    )
}
