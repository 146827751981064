import {
    OePart,
    RepairTimeProvider,
    OE,
    ImportRepairEstimationRequest,
    CatalogPart,
    CustomPart,
    RepairEstimationMainWork,
    RepairEstimationIncludedWork,
} from "@tm/models"
import { getBundleParams } from "../../../utils"
import { GetCalculationDataRequest } from "../../../data/repositories/getCalculationData/model"
import { MainState } from "../../main"
import { IncludedWork } from "../../../data/models"

export const createEurotaxGetCalculationDataRequest = (historyId: number, state: MainState, sendEreSession?: boolean): GetCalculationDataRequest => {
    const { vehicle } = state.manager
    return {
        contReturnUrl: getBundleParams().redirectURL,
        ...(sendEreSession && {
            ereSession: state.extern.externalSessionId,
        }),
        historyId,
        vehicleManufacturerId: vehicle?.tecDocManufacturerId,
        vehicleManufacturerName: vehicle?.manufacturer,
    }
}

function mapCustomPart(oePart: OePart): CustomPart {
    return {
        articleNumber: oePart.oeArticleNumber,
        description: oePart.description,
        quantityValue: oePart.quantityValue,
        garagePrice: oePart.oePriceValue ?? undefined,
    }
}

function mapIncludedWork(includedWork: IncludedWork): RepairEstimationIncludedWork {
    return {
        categoryOfWork: includedWork.categoryOfWork,
        description: includedWork.description,
        providerWorkId: includedWork.providerWorkId,
        time: includedWork.time,
        sortNo: includedWork.sortNo,
    }
}

export const createCostEstimationRequest = (
    state: MainState,
    repairTimeDivision: number,
    workTaskId?: string,
    memo?: string
): ImportRepairEstimationRequest | undefined => {
    if (!workTaskId) {
        return
    }

    const { items, works } = state.calculation

    const selectedItems = items?.filter((x) => x.isSelected)
    const selectedWorks = works?.filter((x) => x.isSelected)

    const parts: CatalogPart[] = []
    const oeParts: OePart[] = []
    const customParts: CustomPart[] = []
    const mainWorks: RepairEstimationMainWork[] = []

    selectedItems?.forEach((item) => {
        if (item.selectedPart) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { warehouseId, ...rest } = item.selectedPart

            parts.push({
                ...rest,
                quantityValue: item.oeArticle.quantityValue,
                memo,
            })
        } else if (!item.oeArticle.oeArticleNumber || !item.oeArticle.oePriceValue) {
            customParts.push(mapCustomPart({ ...item.oeArticle, memo }))
        } else {
            oeParts.push({
                ...item.oeArticle,
                memo,
                oePriceValue: item.oeArticle.oePriceValue || undefined,
                oeArticleOrigin: {
                    module: OE.OeArticleModule.RepairEstimation,
                    provider: OE.OeArticleProvider.Eurotax,
                },
            })
        }
    })

    selectedWorks?.forEach((work) => {
        mainWorks.push({
            providerWorkId: work.providerWorkId,
            hourlyRate: work.hourlyRate,
            categoryOfWork: work.categoryOfWork,
            description: work.description,
            time: work.time,
            sortNo: work.sortNo,
            includedWorks: work.includedWorks?.map(mapIncludedWork),
        })
    })

    return {
        workTaskId,
        customParts,
        vehicleId: state.manager.vehicle?.id ?? "",
        oeParts,
        works: mainWorks,
        parts,
        repairTimesProvider: RepairTimeProvider.Eurotax,
        repairTimeDivision,
    }
}
