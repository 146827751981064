import { Suspense, useMemo, useRef } from "react"
import { RepairTimeOptions, WorkTaskInfo, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { Badge, WarningPrompt } from "@tm/controls"
import { Box, Button, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Article, GetMainServicesRequest, GetMainServicesResponse, IMicros, RegisteredModels, RepairTimeProvider, Vehicle } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import {
    encodeUniqueId,
    getRepairTimesProvider,
    getRepairTimesProviderEnumByString,
    mapArticleToAddCatalogPartListRequest,
    mapMainServicesToAddRepairTimeListRequest,
    showWarehouseDataMissingError,
    useActiveVehicleDataProviders,
    useDefaultErpSystem,
    useExternalCatalogUrl,
} from "@tm/utils"

import { useErpInfoMulti } from "../../data/hooks/useErpInfoMulti"
import { useHasRepairTimesForProductGroup } from "../../data/hooks/useHasRepairTimesForProductGroup"
import { useSupplierArticleQuantities } from "../../data/hooks/useSupplierArticleQuantities"
import { useWarehouse } from "../../data/hooks/useWarehouse"
import { useAddArticleExternalUrl } from "../../helpers"
import { useBasketMemo } from "../../hooks/useBasketMemo"
import { getBundleParams } from "../../utils"
import { ApprovalWarningPrompt } from "../_shared/ApprovalWarningPrompt"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { useProductGroupRepairTimes } from "../../../../parts/src/components/ListV2/hooks/useProductGroupRepairTimes"

type Props = IMicros["basket"]["add-to-cost-estimate"]

function AddToCostEstimateComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { data: articles, disabled, variant, vehicleId, foundBySearchTerm, workTask, erpType, vehicleImageBase64, customerId } = props
    const workTaskId = workTask.id
    const { addToBasketExternalSystemId, enableAddingButtonsAfterErp, highlightCostEstimationButton, hideCostEstimationButton, nextLight } =
        getBundleParams()

    const { translateText, languageId } = useLocalization()
    const {
        userContext: {
            parameter: { catalogLight },
        },
        userSettings,
    } = useUser() ?? {}
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { costEstimation, basket } = useWorkTaskBasketState(workTaskId)

    const { activeProviders } = useActiveVehicleDataProviders(workTaskId)

    const { erpSystemConfig } = useDefaultErpSystem()
    const { erpInfos, erpInfoLoaded } = useErpInfoMulti(articles, erpType, erpSystemConfig?.id, telesalesCustomerNo, vehicleId, foundBySearchTerm)
    const warehouseData = useWarehouse(articles[0]?.id, erpSystemConfig?.id) // Note: currently only working correctly for only one article

    const basketMemo = useBasketMemo(workTask)
    const supplierArticleQuantities = useSupplierArticleQuantities(workTaskId, articles[0], disabled) // Note: currently only working correctly for only one article
    const { fittingGroup } = useHasRepairTimesForProductGroup(workTaskId, articles[0]) // Note: currently only working correctly for only one article
    const repairTimeProviders = useProductGroupRepairTimes([articles[0].productGroup.id], true).productGroupRepairTimes?.productGroups[
        articles[0].productGroup.id
    ]

    const { loadingExternalCatalogUrl, externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId: addToBasketExternalSystemId,
        telesalesCustomerNo,
    })
    const externalBasketUrl = useAddArticleExternalUrl(
        // Note: currently only working correctly for only one article
        externalCatalogUrl,
        languageId,
        articles[0].quantity,
        articles[0].traderArticleNo,
        articles[0].supplierArticleNo,
        articles[0].supplier.id,
        articles[0].productGroup.id
    )

    const dialogRef = useRef<WarningPrompt>(null)

    const buttonMinWidth = useMemo(() => {
        if (variant === "large") {
            return "6.25em"
        }

        if (highlightCostEstimationButton) {
            return "5.5rem"
        }

        return "3em"
    }, [variant, highlightCostEstimationButton])

    const buttonDisabled = useMemo(() => {
        return (
            catalogLight ||
            disabled ||
            articles.some((x) => !x.showAddToBasket) ||
            (enableAddingButtonsAfterErp && !erpInfoLoaded) ||
            loadingExternalCatalogUrl
        )
    }, [catalogLight, disabled, articles, enableAddingButtonsAfterErp, erpInfoLoaded, loadingExternalCatalogUrl])

    function addArticlesToBasket() {
        const container = Container.getInstance<{ key: string; value: { state: boolean } }>(RegisteredModels.ViewState)
        const subscribe = container.subscribe("COST_ESTIMATION_VISIBLE") // TODO: create and use container action
        subscribe.save?.({ key: `${encodeUniqueId(workTaskId)}__COST_ESTIMATION_VISIBLE`, value: { state: true } })

        basket.actions
            .addCatalogParts(
                mapArticleToAddCatalogPartListRequest(
                    articles,
                    workTaskId,
                    vehicleId,
                    customerId,
                    foundBySearchTerm,
                    basketMemo.position,
                    erpInfos,
                    warehouseData.warehouse,
                    undefined,
                    erpSystemConfig?.id,
                    erpSystemConfig?.description
                )
            )
            .then(() => props.onAddCatalogArticleToBasketFinished?.(articles))

        props.onAddToBasket?.(articles)
    }

    function addRepairTimeToBasket(vehicle: Vehicle) {
        const { awProviders, getRepairTimesUrl } = props

        if (!repairTimeProviders?.length || !awProviders.length) {
            return
        }

        const repairTimeProvider = getRepairTimesProviderEnumByString(
            getRepairTimesProvider(
                repairTimeProviders,
                awProviders,
                activeProviders?.repairTimes ?? userSettings?.activeVehicleDataProviders?.repairTimes
            )
        )

        if (!repairTimeProvider || !userSettings?.repairTimeOptions) {
            showModal(getRepairTimesUrl(articles[0], repairTimeProviders[0]))
            return
        }

        addRepairTimesToBasket(
            articles[0], // Note: currently only working correctly for only one article
            vehicle,
            repairTimeProvider,
            userSettings.repairTimeOptions
        ).then(({ showRepairTimesModal }) => {
            if (showRepairTimesModal) {
                showModal(getRepairTimesUrl(articles[0], repairTimeProvider))
            }
        })
    }

    function addToBasket() {
        // Only add article if it is not already in basket
        if (!supplierArticleQuantities?.isInOrder) {
            if (externalBasketUrl) {
                showModal(externalBasketUrl)
            } else {
                addArticlesToBasket()
            }
        }

        if (vehicleImageBase64) {
            costEstimation.actions.attachVehicleImage(vehicleImageBase64)
        }

        if (workTask.vehicle) {
            addRepairTimeToBasket(workTask.vehicle)
        }
    }

    function addRepairTimesToBasket(
        article: Article,
        vehicle: Vehicle,
        repairTimeProvider: RepairTimeProvider,
        repairTimeOptions: RepairTimeOptions
    ): Promise<{ showRepairTimesModal: boolean }> {
        const request: GetMainServicesRequest = {
            repairTimeProvider,
            modelId: vehicle.tecDocTypeId,
            productGroupId: article.productGroup.id.toString(),
            vehicleType: vehicle.vehicleType,
            fittingSideFilter: article.fittingSide,
            manufacturerId: vehicle.tecDocManufacturerId,
            defaultRepairTimeDivision: repairTimeOptions.division,
            useManufacturerRepairTimeDivision: repairTimeOptions.useManufacturerRepairTimeDivision,
        }

        return new Promise((resolve, reject) => {
            // TODO: create and use container action
            Container.getInstance<GetMainServicesResponse>(RegisteredModels.RepairTimes_MainServices)
                .subscribe(request)
                .load()
                .then(({ mainServices, repairTimeDivision }) => {
                    if (!mainServices.length) {
                        reject()
                        return
                    }

                    if (mainServices.length > 1 || getBundleParams().alwaysShowRepairTimesModalAfterCostEstimationButtonClick) {
                        resolve({ showRepairTimesModal: true })
                        return
                    }

                    const service = mainServices[0]
                    costEstimation.actions.addRepairTimes(
                        mapMainServicesToAddRepairTimeListRequest(
                            workTaskId,
                            repairTimeProvider,
                            [
                                {
                                    ...service,
                                    productGroupIds:
                                        !service.productGroupIds || service.productGroupIds.length // TODO: check if "service.productGroupIds.length" shouldn't rather be negotiaded
                                            ? [article.productGroup.id]
                                            : service.productGroupIds,
                                    fittingSide: article.fittingSide,
                                },
                            ],
                            vehicle.id,
                            repairTimeDivision
                        )
                    )

                    resolve({ showRepairTimesModal: false })
                })
        })
    }

    function handleButtonClick() {
        if (warehouseData.hasErrors) {
            showWarehouseDataMissingError(translateText)
            warehouseData.refetchWarehouseData()
            return
        }

        if (erpInfos.some((erpData) => erpData.itemOrderableInformation)) {
            dialogRef.current?.show()
        } else {
            addToBasket()
        }
    }

    // TODO: check if these can be moved to the Wrapper to prevent all hooks being called etc.
    if (nextLight || hideCostEstimationButton) {
        return null
    }

    return (
        <Box className="tk-basket add-to-cost-estimate">
            <Box zIndex={1} position="relative">
                {fittingGroup?.hasWorks && <Badge skin="dark" value={<Icon name="check" width="12px" height="12px" />} title={translateText(1298)} />}
            </Box>
            <Box minWidth={buttonMinWidth}>
                <Button
                    className="btn btn--bordered"
                    startIcon={
                        <Icon
                            name={languageId === "1" ? "voucher-kva" : "voucher-kva-international"}
                            width={highlightCostEstimationButton ? "38px !important" : undefined} // Currently only used for Create-business
                        />
                    }
                    title={translateText(917)}
                    variant="bordered"
                    color={highlightCostEstimationButton ? "highlight" : undefined}
                    onClick={handleButtonClick}
                    disabled={buttonDisabled}
                    fullWidth
                />
            </Box>
            <ApprovalWarningPrompt ref={dialogRef} erpInfos={erpInfos} onConfirm={addToBasket} />
        </Box>
    )
}

function showModal(url: string | undefined) {
    if (url) {
        Morpheus.showView("1", url)
    }
}

export default function Wrapper(props: Props) {
    const { workTask } = useWorkTask() ?? {}

    if (!workTask) {
        return null
    }
    return (
        <Suspense fallback={null}>
            <AddToCostEstimateComponent {...props} workTask={workTask} />
        </Suspense>
    )
}
