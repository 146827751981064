import { useUser, useWorkTask } from "@tm/context-distribution"
import { Button, Icon, PanelSection, SeparatorList, SeparatorListItem, Tag, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article } from "@tm/models"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { FC, useEffect, useMemo, useState } from "react"

import { getDateFormat } from "../../business"
import { Stocks } from "../../business/gateway"
import { PriceType } from "../../business/gateway/parts"
import { connectionChannel } from "../../business/messaging"
import { useDmsPermissions, useGsiConnectVersion } from "../../components/hooks"
import { getBundleParams } from "../../utils"
import { mapStockItemsFromOldGsiConnectService, showAvailabilityBuffered } from "./business"
import { AvailabilityIcon } from "./components/availability-icon"
import { mapArticleToLookupItem } from "./mapArticleToLookupItem"
import { getComponentStyles } from "./styles"

type Props = {
    part: Article
}

export const DmsArticleInfo: FC<Props> = (props) => {
    const className = useMemo(() => getComponentStyles(), [])
    const { permittedOperations } = useDmsPermissions()
    const gsiConnectVersion = useGsiConnectVersion()
    const { translate, translateText, currency, date, languageId, number } = useLocalization()
    const { workTask, workTaskId } = useWorkTask() ?? {}
    const { userSettings } = useUser() ?? {}
    const vehicleReferenceId = workTask?.vehicle?.refId

    const [stockItem, setStockItem] = useState<Stocks.StockItem>()

    useEffect(() => {
        ;(async () => {
            const isConnected = connectionChannel().last(1, "CONNECTION_STATUS_RECEIVED")[0]?.content?.isConnected

            if (isConnected && permittedOperations.includes("showAvailability")) {
                try {
                    const stockItems = await showAvailabilityBuffered({ vehicleReferenceId, items: [mapArticleToLookupItem(props.part)] })
                    const firstItem = mapStockItemsFromOldGsiConnectService(stockItems.first(), gsiConnectVersion)
                    setStockItem(firstItem)
                } catch {}
            }
        })()
    }, [vehicleReferenceId, props.part, permittedOperations, gsiConnectVersion])

    const voucherInfoItems = useMemo(() => {
        const voucher = stockItem?.vouchers?.[0]

        if (!voucher) {
            return
        }

        const items: Array<SeparatorListItem> = []

        if (voucher.plateId) {
            items.push({
                label: translateText(21),
                value: voucher.plateId,
            })
        }

        if (voucher.creationDate) {
            items.push({
                label: translateText(98),
                value: date(voucher.creationDate, getDateFormat(languageId)),
            })
        }

        if (voucher.mileage !== undefined) {
            items.push({
                label: translateText(125),
                value: number(voucher.mileage, 0),
            })
        }

        return items
    }, [stockItem?.vouchers, translateText, date, languageId, number])

    const stockInfoItems = useMemo(() => {
        if (!stockItem) {
            return
        }

        const items: Array<SeparatorListItem> = []

        stockItem.warehouses
            .filter((x) => x.name)
            .forEach((x) =>
                items.push({
                    label: translateText(1983),
                    value: x.name,
                })
            )

        if (stockItem.quantity.value !== undefined) {
            items.push({
                label: translateText(1984),
                value: `${stockItem.quantity.value}`,
            })
        }

        stockItem.prices
            .filter((x) => x.value !== undefined && (userSettings?.showPurchasePrice || x.type !== PriceType.Purchase))
            .forEach((x) =>
                items.push({
                    label: x.description,
                    value: currency(x.value ?? 0, x.currencySymbol ?? ""),
                })
            )

        if (stockItem.description) {
            items.push({
                label: translateText(25),
                value: stockItem.description,
            })
        }

        return items
    }, [currency, stockItem, translateText, userSettings?.showPurchasePrice])

    if (!stockItem || (!stockItem.invoiced && (stockItem.availability === undefined || stockItem.availability === Stocks.Availability.Undefined))) {
        return null
    }

    const renderStatus = () => {
        const tooltip = []

        if (stockItem.invoiced) {
            tooltip.push(translateText(1985))
        }

        if (stockItem.availability === Stocks.Availability.Available) {
            tooltip.push(translateText(1986))
        }

        return (
            <Tooltip className={`${className}__tooltip`} content={tooltip.join("\n\n")} textSize="s">
                <div>
                    <AvailabilityIcon
                        className={`icon icon--xl ${className}__status`}
                        availability={stockItem.availability}
                        invoiced={stockItem.invoiced}
                    />
                </div>
            </Tooltip>
        )
    }

    const renderInfoItem = (item: SeparatorListItem) => {
        return <Tag layout="ghost" value={item.value} label={item.label} />
    }

    const renderVoucherInfo = () => {
        if (!stockItem.invoiced) {
            return
        }

        return (
            <>
                <Icon className={`${className}__icon`} name="vehicle-front" size="s" />
                <SeparatorList items={voucherInfoItems ?? []} renderItem={renderInfoItem} />
            </>
        )
    }

    const renderStockInfo = () => {
        return (
            <>
                <Icon className={`${className}__icon`} name="stock" size="l" />
                <SeparatorList items={stockInfoItems ?? []} renderItem={renderInfoItem} />
            </>
        )
    }

    const renderVoucherButton = () => {
        const voucher = stockItem.vouchers?.[0]

        if (!stockItem.invoiced || !voucher || !permittedOperations.includes("showVoucher")) {
            return
        }

        const url = renderRoute(getBundleParams().dmsModuleUrl, {
            workTaskId: encodeUniqueId(workTaskId ?? uniqueId()),
            voucherReferenceId: voucher.referenceId,
        })

        return (
            <Button className={`${className}__button`} linkTo={url} size="s">
                {translate(2008)}
            </Button>
        )
    }

    return (
        <PanelSection className={className}>
            {renderStatus()}
            {renderVoucherInfo()}
            {renderStockInfo()}
            {renderVoucherButton()}
        </PanelSection>
    )
}
