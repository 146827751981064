import { useCallback, useState } from "react"
import { useLocalization } from "@tm/localization"
import { DmsSettings as DmsSettingsModel, useUser } from "@tm/context-distribution"
import { TextField } from "@tm/controls"
import { Container } from "@tm/nexus"
import { Alert, Stack, Loader, Button, Typography, LinkButton, Icon } from "@tm/components"
import { validateDmsApiUrl } from "@tm/utils"

type Props = {
    dmsName: string
    settings: DmsSettingsModel
}

export default function DmsSettings(props: Props) {
    const {
        dmsName,
        settings: { apiUrl, apiKey, certificateUrl },
    } = props

    const { translate, translateText } = useLocalization()
    const { setUserSetting } = useUser()

    const [isSaving, setIsSaving] = useState(false)
    const [_apiUrl, setApiUrl] = useState<string>()
    const [_apiKey, setApiKey] = useState<string>()
    const [error, setError] = useState<string>()

    const handleReset = useCallback(() => {
        setError(undefined)
        setApiUrl(undefined)
        setApiKey(undefined)
    }, [])

    const downloadCertificateInstaller = useCallback(async () => {
        const { filename, installScript } =
            (await Container.getInstance("DMS").callAction<{ filename: string; installScript: string } | undefined>(
                "getClientInstallationScript",
                certificateUrl
            )) ?? {}

        if (filename && installScript) {
            downloadFile(filename, installScript)
        }
    }, [certificateUrl])

    async function handleSave() {
        const url = (_apiUrl ?? apiUrl)?.trim()

        if (url && !validateDmsApiUrl(url)) {
            setError(translateText(13407))
            return
        }

        setError(undefined)

        setIsSaving(true)

        try {
            await setUserSetting?.("DMS_SETTINGS", {
                ...props.settings,
                // Empty string values "" should not be saved, hence the use of "|| undefined"
                apiUrl: url || undefined,
                apiKey: (_apiKey ?? apiKey) || undefined,
            })

            handleReset()
        } finally {
            setIsSaving(false)
        }
    }

    function renderError() {
        if (!error) {
            return
        }

        return (
            <Alert severity="error" sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
                {error}
            </Alert>
        )
    }

    function renderCertificate() {
        if (!certificateUrl) {
            return
        }

        return (
            <Stack gap={1}>
                <Typography variant="h3">{translate(1987)}</Typography>
                <Typography variant="label">{translateText(1988).replace("{0}", dmsName)}</Typography>
                <Stack direction="row" spacing={1}>
                    <Button onClick={downloadCertificateInstaller} startIcon={<Icon name="download" />}>
                        {translate(1989)}
                    </Button>
                    <LinkButton to={certificateUrl} startIcon={<Icon name="download" />}>
                        {translate(1987)}
                    </LinkButton>
                </Stack>
            </Stack>
        )
    }

    const isDirty: boolean = _apiUrl !== undefined || _apiKey !== undefined

    return (
        <Stack gap={1}>
            <Stack direction="row" spacing={0.5}>
                <Button onClick={handleSave} disabled={!isDirty || isSaving} color="success">
                    {translateText(9)}
                </Button>
                <Button onClick={handleReset} disabled={!isDirty || isSaving}>
                    {translateText(48)}
                </Button>

                {isSaving && <Loader size="small" />}
            </Stack>

            {renderError()}

            <Stack direction="row" spacing={0.5} className="user-settings__area__row">
                <TextField value={_apiUrl ?? apiUrl} onChange={setApiUrl} disabled={isSaving} label={translateText(1876)} floatingLabel />
                <TextField value={_apiKey ?? apiKey} onChange={setApiKey} disabled={isSaving} label={translateText(1877)} floatingLabel />
            </Stack>

            {renderCertificate()}
        </Stack>
    )
}

function downloadFile(filename: string, text: string) {
    const element = document.createElement("a")

    element.setAttribute("href", `data:text/plaincharset=utf-8,${encodeURIComponent(text)}`)
    element.setAttribute("download", filename)
    element.style.display = "none"

    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}
