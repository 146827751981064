import { useCallback, useEffect, MouseEvent } from "react"
import { useCountryCodeToLicensePlate, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel, PriceType, IMicros } from "@tm/models"
import { concat, encodeUniqueId, useWorkTaskTruckData } from "@tm/utils"
import { useMicro } from "@tm/morpheus"
import { Switch, Checkbox, Typography, LinkButton, Icon, LicensePlate } from "@tm/components"
import { CentralOrderWorkTaskState, useCentralOrderStateByWorkTask } from "../../../data/hooks/useCentralOrderWorkTasks"
import WorkTaskOrderButtonComponent, { WorkTaskOrderButtonConfigProps } from "../../_shared/order-button/worktask"
import { useGlobalOrderOptionsState } from "../../../hooks/useGlobalOrderOptionsState"
import { LoaderSmall, StackRow } from "../../StyledComponents"
import { useCentralOrderBasketState } from "../../../hooks/basketState/useCentralOrderBasketState"

type Props = WorkTaskOrderButtonConfigProps & {
    workTaskState: CentralOrderWorkTaskState
    onHideWorkTask(workTaskId: string): void
}

export default function WorkTaskInfoComponent(props: Props) {
    const { workTaskState, onHideWorkTask } = props
    const { includedInOrder, selected, disabled, orderGroups, loading, workTask } = workTaskState

    const { toggleIncludedInOrder, toggleSelected, toggleDisabled, setSucceededOrderGroups } = useCentralOrderStateByWorkTask(workTask.workTaskId)
    const { translateText, translate, currency } = useLocalization()

    const { userSettings } = useUser() || {}
    const { workTaskBasketCalculationLoading, workTaskBasketCalculation, basket, erp } = useCentralOrderBasketState(workTask.workTaskId)

    const { workTaskTruckData } = useWorkTaskTruckData(workTask.workTaskId)
    const { globalOrderOptionsEnabled } = useGlobalOrderOptionsState()
    const { renderMicro } = useMicro<IMicros>()
    const customer = concat(" ", workTask.customerInfo?.firstName, workTask.customerInfo?.lastName) || workTaskTruckData?.customerName
    const vehicle = concat(" ", workTask.vehicleInfo?.manufacturer, workTask.vehicleInfo?.modelSeries, workTask.vehicleInfo?.model)
    const plateId = workTask.vehicleInfo ? workTask.vehicleInfo.plateId : workTaskTruckData?.plateNr
    const { plateCode } = useCountryCodeToLicensePlate(workTask?.vehicleInfo?.countryCode)
    const handleCompleteWorkTask = useCallback(() => {
        onHideWorkTask(workTask.workTaskId)
    }, [workTask.workTaskId, onHideWorkTask])

    useEffect(() => {
        if (orderGroups && !orderGroups.some((group) => group.orderItems.some((item) => item.isIncludedInOrder))) {
            toggleDisabled(true)
            toggleIncludedInOrder(false)
        } else if (orderGroups && orderGroups.some((group) => group.orderItems.some((item) => item.isIncludedInOrder))) {
            toggleDisabled(false)
        }
    }, [orderGroups, toggleDisabled, toggleIncludedInOrder])

    useEffect(() => {
        if (erp.basketErpInfosIndicator.isLoading) {
            toggleDisabled(true)
        }
    }, [erp.basketErpInfosIndicator.isLoading, toggleDisabled])

    useEffect(() => {
        if (!erp.basketErpInformation.some((erpinfo) => erpinfo.erpInfos?.items?.length)) {
            return
        }

        if (!erp.basketErpInformation.some((erpInfo) => erpInfo.erpInfos?.items?.some((part) => part.isOrderable) && !erpInfo.erpInfos?.hasErrors)) {
            toggleDisabled(true)
            toggleIncludedInOrder(false)
        } else {
            toggleDisabled(false)
            toggleIncludedInOrder(true)
        }
    }, [JSON.stringify(erp.basketErpInformation), toggleDisabled, toggleIncludedInOrder])

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []

        unsubscriptions.push(
            channel("WORKTASK", workTask.workTaskId).subscribe("BASKET/ORDER_SENDING", () => {
                toggleDisabled(true)
            })
        )

        unsubscriptions.push(
            channel("WORKTASK", workTask.workTaskId).subscribe("BASKET/ORDER_SENT", ({ result }) => {
                if (result?.workTaskId === workTask.workTaskId) {
                    if (result.allHaveSucceeded) {
                        setSucceededOrderGroups(result.orders)
                    }

                    toggleDisabled(true)
                    toggleIncludedInOrder(false)
                }
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [workTask.workTaskId, toggleDisabled, toggleIncludedInOrder, setSucceededOrderGroups])

    function handleSelectWorktaskToggle(e: MouseEvent<HTMLElement>) {
        e?.stopPropagation()
        toggleSelected()
    }

    function handleProcessingStatusChange(e: MouseEvent<HTMLElement>): void {
        e?.stopPropagation()
        toggleIncludedInOrder()
    }

    function renderTotalPrice() {
        if (!workTaskBasketCalculation?.calculatedOrder?.netTotalsInOrder) {
            return null
        }

        const calculatedPrices = Object.values(workTaskBasketCalculation?.calculatedOrder?.grossTotalsInOrder)
        const purchasePrice = calculatedPrices.find((price) => price?.type === PriceType.Purchase)
        const retailPrice = calculatedPrices.find((price) => price?.type === PriceType.Retail)
        let totalPrice = purchasePrice ? currency(purchasePrice.value, purchasePrice.currencyCode_Iso_4217 || purchasePrice.currencySymbol) : "-"
        if (userSettings && !userSettings.showPurchasePrice) {
            totalPrice = retailPrice ? currency(retailPrice.value, retailPrice.currencyCode_Iso_4217 || retailPrice.currencySymbol) : "-"
        }
        return (
            <StackRow>
                {workTaskBasketCalculationLoading && <LoaderSmall />}
                {!workTaskBasketCalculationLoading && workTaskBasketCalculation?.calculatedOrder?.grossTotalsInOrder && (
                    <>
                        <Typography color="highlight">{totalPrice}</Typography>
                        <Typography variant="label">{` *${translate(694)}`}</Typography>
                    </>
                )}
            </StackRow>
        )
    }
    return (
        <StackRow justifyContent="space-between" flex={1}>
            <StackRow spacing={1}>
                <LinkButton startIcon={<Icon name="partslink24" />} to={`/${encodeUniqueId(workTask.workTaskId)}`}>
                    {translate(2014)}
                </LinkButton>
                <Typography variant="h4" fontWeight="bold">
                    {!customer && !vehicle && translateText(29)}
                    {concat(" - ", customer, vehicle)}
                </Typography>
                {plateId && <LicensePlate countryCode={plateCode} value={plateId} />}
                {loading && <LoaderSmall />}
            </StackRow>
            <StackRow spacing={16}>
                <StackRow spacing={4}>
                    <Typography variant="body2">
                        {workTaskBasketCalculation?.calculatedOrder?.numberOfPositionsInOrder ?? ""} {translate(479)},{" "}
                        {workTaskBasketCalculation?.calculatedOrder?.numberOfItemsInOrder ?? ""} {translate(90)}
                    </Typography>
                    {renderTotalPrice()}
                </StackRow>
                <StackRow spacing={1}>
                    {renderMicro("worktask", "complete-work-task-button", {
                        workTaskId: workTask.workTaskId,
                        onWorkTaskCompleted: handleCompleteWorkTask,
                    })}
                    {!globalOrderOptionsEnabled && (
                        <WorkTaskOrderButtonComponent
                            {...props}
                            workTaskId={workTask.workTaskId}
                            orderGroupsLoading={loading}
                            orderGroups={basket.state.basketOrderGroups}
                        />
                    )}
                    <Switch
                        label={
                            <Typography variant="label" className="basket-centralOrder-worktaskInfo-includeOrder">
                                {translateText(772)}
                            </Typography>
                        }
                        checked={includedInOrder}
                        disabled={disabled}
                        labelPlacement="start"
                        size="small"
                        onClick={handleProcessingStatusChange}
                    />
                    <Checkbox checked={selected} onClick={handleSelectWorktaskToggle} disabled={workTaskBasketCalculationLoading} />
                </StackRow>
            </StackRow>
        </StackRow>
    )
}
