import { ArticleInfoType, ErpPrice, PriceType } from "@tm/models"
import { Stack } from "@tm/components"
import PartOrderOptions from "./PartOrderOptions"
import PartsNote from "./PartsNote"
import PartAdditionalPrices from "./PartAdditionalPrices"
import PartsTable from "../../PartsTable"
import { BasketErpIndicator, BasketPart } from "../../../../../../models"
import { SelectedOrderOptions } from "../../../../../../data/model"
import ErrorAlert from "../../../../ErrorAlert"
import { RowStack } from "../../../../order-options/components/StyledComponents"

type Props = {
    basketErpIndicator: BasketErpIndicator
    calculationLoading?: boolean
    itemCommentMaxLength?: number
    orderSending?: boolean
    part: BasketPart
    showPurchasePrice?: boolean
    workTaskId: string
    onChangeOrderOptions(part: BasketPart, orderOptions: SelectedOrderOptions): void
    onRemoveNote(part: BasketPart): void
    onSaveNote(part: BasketPart, note: string, isNewNote: boolean): void
}

export default function PartFooterComponent(props: Props) {
    const { part, basketErpIndicator, workTaskId, itemCommentMaxLength, showPurchasePrice, calculationLoading, orderSending } = props
    const { partItem, erpInfoResponse: erpInfo, linkedItems, states } = part
    const { isNoteExpanded, isBeingUpdated, isOrderOptionsCollapsed, isOrderOptionsBeingUpdated } = states
    const procurementPrice: ErpPrice | undefined =
        erpInfo?.prices && Object.values(erpInfo?.prices)?.find((price) => price?.type === PriceType.ProcurementCosts)
    const pricesExpanded = erpInfo?.orderOptions?.shipmentModes?.shipmentModes.find((e) => e.isSelected)?.costs?.value || procurementPrice
    const optionsExpanded =
        !isOrderOptionsCollapsed && (!erpInfo?.orderOptions?.billingModes?.isNotVisible || !erpInfo?.orderOptions?.shipmentModes?.isNotVisible)

    if (!optionsExpanded && !isNoteExpanded && !pricesExpanded) {
        return null
    }

    return (
        <Stack p={0.5} spacing={1.5} flex={1}>
            {(basketErpIndicator.hasErrors || (part.erpInfoResponse?.hasErrors && part.erpInfoResponse.isOrderable)) &&
                part.partItem.articleInformation.articleInfoType !== ArticleInfoType.CustomArticle && (
                    <ErrorAlert isItemAlert additionalTextId={13663} />
                )}
            <RowStack justifyContent={pricesExpanded && (!erpInfo?.orderOptions || !optionsExpanded) ? "flex-end" : "space-between"}>
                {erpInfo?.orderOptions && optionsExpanded && (
                    <PartOrderOptions
                        orderOptions={erpInfo.orderOptions}
                        workTaskId={workTaskId}
                        onChangeOrderOptions={(orderOptions) => props.onChangeOrderOptions(part, orderOptions)}
                        loading={basketErpIndicator.isLoading || !!isOrderOptionsBeingUpdated || !!states.isErpInfoBeingLoaded || !!orderSending}
                    />
                )}
                {pricesExpanded && (
                    <PartAdditionalPrices
                        orderOptions={erpInfo?.orderOptions}
                        procurementPrice={procurementPrice}
                        useSpecialProcurement={erpInfo?.useSpecialProcurement}
                    />
                )}
            </RowStack>

            {isNoteExpanded && (
                <PartsNote
                    disableControls={isBeingUpdated || orderSending}
                    partId={partItem.id}
                    note={partItem.orderItem?.memo}
                    itemCommentMaxLength={itemCommentMaxLength}
                    onSaveNote={(partId, memo) => props.onSaveNote(part, memo, !partItem.orderItem?.memo)}
                    onRemoveNote={() => props.onRemoveNote(part)}
                />
            )}
            {!!linkedItems?.length && (
                <PartsTable
                    parts={linkedItems}
                    workTaskId={workTaskId}
                    basketErpIndicator={basketErpIndicator}
                    showPurchasePrice={showPurchasePrice}
                    calculationLoading={calculationLoading}
                />
            )}
        </Stack>
    )
}
