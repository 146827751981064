import { ClientType, Memo, Paged } from "."
import { VehicleType } from "./vehicles"

export type FindPartsRequest = Paged & {
    query?: string
    orderBy?: string
    orderByDescending?: boolean
}

export type FindPartsResponse = Paged & {
    parts: Array<Part>
}

export type Part = {
    id?: string
    referenceId: string

    wholesalerArticleId?: string
    dataSupplierArticleId: string
    oeArticleId: OeArticleId
    additionalArticleIds: Array<AdditionalArticleId>

    dataSupplier?: DataSupplier
    productGroups?: Array<ProductGroup>

    vehicleType: VehicleType

    description: string
    additionalDescription: string

    prices: Array<Price>

    quantity: number
    unit: string

    memos: Array<Memo>
    orderInformation: OrderInfo

    isInOrder: boolean
    isChangeable: boolean
    isChanged: boolean
    isInCostEstimation?: boolean
    isReplacementPart: boolean

    replacedPartInfo: string
    replacedReferenceId: string
}

export type OeArticleId = {
    id: string
    manufacturer: string
    manufacturerId: string
}

export type AdditionalArticleId = {
    id: string
    type: AdditionalArticleIdType
    description: string
}

export enum AdditionalArticleIdType {
    Undefined = 0,
    OE = 1,
    EAN = 2,
    DMS = 3,
}

export type DataSupplier = {
    id: number
    clientId: ClientType
    description: string
}

export type ProductGroup = {
    id: number
    clientId: ClientType
    description: string
}

export type Price = {
    description: string
    type: PriceType

    value?: number
    unit: string

    currencyCode: string
    currencySymbol: string

    vat: number
    isTaxIncluded: boolean

    rebateValue: number
    rebate: number
}

export enum PriceType {
    Undefined = 0, // 0 - Undefiniert
    RecommendedRetail = 1, // 1 – Unverbindliche Preisempfehlung (UVP) / recommended sales price, recommended retail price
    UsedPart = 2, // 2 – Altteilpreis
    Pledge = 3, // 3 – Pfand
    Purchase = 4, // 4 – Einkaufspreis
    Retail = 5, // 5 – Verkaufspreis / Sales price
    Net = 6, // 6 – Netto
    Gross = 7, // 7 – Brutto
    ProcurementCosts = 8, // 8 – Beschaffungskosten
    Garage = 9, // 9 – Werkstattpreis
    CalculatedWithoutVat = 10, // 10 – Kalkuliert (ohne Mwst.)
    CalculatedWithVat = 11, // 11 – Kalkuliert (mit Mwst.)
    Strikethrough = 12, // 12 – Streichpreis
    GraduatedPurchase = 13, // 13 - EK-Staffelpreis
}

export type OrderInfo = {
    orderId: string
    state: number
    memos: Array<Memo>
    timestamp?: Date
}
