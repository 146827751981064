import { useTelesalesCustomerNumber, useWorkTask } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import {
    Article,
    ArticleIdentifier,
    AttributeComparisonModel,
    FittingPosition,
    IMicros,
    OrderVouchersShowSupplierArticleByVehicleRequest,
    OrderVouchersShowSupplierArticleByVehicleResponse,
    RegisteredModels,
    RequestArticleListPayload,
    SearchFilters,
    VehicleRecordsContainer,
} from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { Container, ContainerAction, IModelContainer } from "@tm/nexus"
import { useDefaultErpSystem, useGetDetailedErpInfo } from "@tm/utils"
import * as React from "react"
import { useParams } from "react-router"
import ArticleItem from "../../components/_shared/article-item"
import { getArticleErpInformation } from "../../components/list/components/utils"
import * as Mappers from "../../data/mapper"
import { shouldCompareWithVehicleRecords } from "../../helper"
import { getBundleParams } from "../../utils"
import { openPartsSearch } from "../../utils/modalHelper"

type PartToLoad = ArticleIdentifier & { quantity: number; fittingPosition?: FittingPosition }

type Props = IMicros["parts"]["part-item"]

const PartItem: React.FC<Props> = (props) => {
    const {
        part: propPart,
        partToLoad,
        hideLoader,
        vehicleId,
        vehicleRecordsComparisons: propVehicleRecords,
        previouslyOrdered: propAlreadyOrdered,
        shouldLoadVehicleRecords,
        ...rest
    } = props

    const [loadedPart, setPart] = React.useState<Article | undefined>()
    const [partToLoadRequest, setPartToLoadRequest] = React.useState<PartToLoad>()
    const [loading, setLoading] = React.useState<boolean>(true)
    const [loadedVehicleRecords, setVehicleRecords] = React.useState<Array<AttributeComparisonModel> | undefined>()
    const [loadedAlreadyOrdered, setAlreadyOrdered] = React.useState<boolean | undefined>()
    const [loadingVehicleRecords, setLoadingVehicleRecords] = React.useState<boolean>(false)
    const workTaskContext = useWorkTask()
    const routerParams = useParams()

    const { erpSystemConfig } = useDefaultErpSystem(props.erpSystemConfig)
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()

    const part = propPart ?? loadedPart
    const vehicleRecords = shouldLoadVehicleRecords ? propVehicleRecords ?? loadedVehicleRecords : []
    const alreadyOrdered = propAlreadyOrdered ?? loadedAlreadyOrdered

    const erpInfo = useGetDetailedErpInfo({
        articles: props?.part ? [props.part] : [],
        type: props?.erpDetailRequestType || "list",
        vehicleId,
        distributorId: erpSystemConfig?.id,
        telesalesCustomerNo,
    })

    // TODO: this part can be moved, is used only for damage-calc toolkits
    React.useEffect(() => {
        // No part to load
        if (!partToLoad) {
            return
        }

        // Part is already loaded
        if (
            part &&
            part.productGroup.id == partToLoad.productGroupId &&
            part.supplierArticleNo == partToLoad.supplierArticleNo &&
            part.supplier.id == partToLoad.supplierId
        ) {
            return
        }

        // Part is already loading
        if (
            partToLoadRequest &&
            partToLoadRequest.productGroupId == partToLoad.productGroupId &&
            partToLoadRequest.supplierArticleNo == partToLoad.supplierArticleNo &&
            partToLoadRequest.supplierId == partToLoad.supplierId &&
            partToLoadRequest.tecdocTypeNo == partToLoad.tecdocTypeNo
        ) {
            return
        }

        // Store old request to prevent loading twice
        setPartToLoadRequest(partToLoad)

        Container.getInstance<Array<Article>>(RegisteredModels.Articles_ByArticleNumbersWithOptionalVehicleBuffered)
            .subscribe([partToLoad])
            .load()
            .then((articles) => {
                if (articles?.length) {
                    setPart({
                        ...articles[0],
                        fittingSide: articles[0].fittingSide ?? partToLoad.fittingPosition,
                        quantity: partToLoad.quantity,
                        initialQuantity: partToLoad.quantity,
                    })
                }
            })
            .finally(() => setLoading(false))
    }, [partToLoad])

    const handleRequestArticleDirectSearch = (query: string, searchFilter?: SearchFilters) => {
        const payload: RequestArticleListPayload = {
            forceReload: true,
            partToReplaceId: undefined,
            direct: { query, searchFilter },
            inModal: true,
            isOeReplacement: false,
        }

        const { partsRoutes } = getBundleParams()
        openPartsSearch(payload, routerParams, partsRoutes)
    }

    React.useEffect(() => {
        if (!part || !vehicleId) {
            return
        }

        if (alreadyOrdered == undefined) {
            const request: OrderVouchersShowSupplierArticleByVehicleRequest = {
                vehicleId,
                productGroupFilterIds: `${part.productGroup.id}`,
            }
            Container.getInstance<OrderVouchersShowSupplierArticleByVehicleResponse>(RegisteredModels.Vouchers_ShowSupplierArticleByVehicle)
                .subscribe(request)
                .load()
                .then((response) => {
                    if (response?.orderedSupplierArticles?.length) {
                        setAlreadyOrdered(
                            response.orderedSupplierArticles.some(
                                (x) => x.supplierId == part.supplier.id && x.supplierArticleNumber == part.supplierArticleNo
                            )
                        )
                    }
                })
        }

        const vehicle = workTaskContext?.workTask?.vehicle
        if (shouldCompareWithVehicleRecords(vehicle) && !vehicleRecords && !loadingVehicleRecords) {
            const request = Mappers.VehicleRecords.mapCompareWithVehicleRecordsRequest(vehicleId, [part])
            if (request.attributes.length) {
                setLoadingVehicleRecords(true)
                const container: IModelContainer<VehicleRecordsContainer, ContainerAction> = Container.getInstance<VehicleRecordsContainer>(
                    RegisteredModels.VehicleRecords
                )
                container
                    .action("compareWithVehicleRecords")(request)
                    .then((response) => {
                        if (response?.attributes?.length) {
                            setVehicleRecords(response.attributes)
                            setLoadingVehicleRecords(false)
                        }
                    })
            }
        }
    }, [part, vehicleId])

    // TODO: this can be removed, mostly used only for fast-calc
    if (!part) {
        if (loading && !hideLoader) {
            return (
                <div className="article-list__item article-list__panel">
                    <div style={{ margin: "2em auto" }}>
                        <Loader className="article-list__item__loader" delay={0} />
                    </div>
                </div>
            )
        }

        return null
    }
    //

    return (
        <ArticleItem
            {...rest}
            id={part.id}
            hideCostEstimationButton={!getBundleParams().awProviders.length}
            article={part}
            vehicleRecordsComparisons={vehicleRecords}
            previouslyOrdered={alreadyOrdered}
            onRequestArticleDirectSearch={handleRequestArticleDirectSearch}
            partErpData={getArticleErpInformation(part.id, erpInfo.data)}
        />
    )
}

const PartItemWrapper: React.FC<Props> = (props) => {
    return (
        <React.Suspense fallback={null}>
            <PartItem {...props} />
        </React.Suspense>
    )
}

export default connectComponent({}, PartItemWrapper)
