import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { Text, Button, Card, Headline, Scrollbar, Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { classes, getCurrencyFromUserContext, renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { Article, ECalcOrigin, channel } from "@tm/models"
import { Badge, styled } from "@tm/components"
import { getComponentStyles } from "../_shared/styles"
import CustomEndModal from "../_shared/customEndModal"
import FastCalcSelection from "../_shared/modal/fastCalcSelection"
import { getLabourRate } from "../../helpers/userSettingsContextMethods"
import { createMemo } from "../../helpers"
import { createImportCalculationRequest } from "../../helpers/createImportCalculationRequest"
import { getBundleParams } from "../../utils"
import { stepNavigationActions, useFastServiceStore } from "../../data"
import { useBasketImports } from "../../../../basket/src/hooks/basketState/useBasketImports"
import { useRepairTimes } from "../../helpers/hooks/useRepairTimes"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = RouteComponentProps<any>

const End: FC<Props> = ({ match }) => {
    const currentStepName = "end"
    const { translateText, languageId } = useLocalization()
    const { userSettings, userContext } = useUser()
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])
    const [showPrintModal, setShowPrintModal] = useState(false)
    const [showFastCalcModal, setShowFastCalcModal] = useState(false)
    const calculationDone = useFastServiceStore((state) => state.calculationDone)
    const selectedArticles = useFastServiceStore((state) => state.selectedArticles)
    const selectedItems = useFastServiceStore((state) => state.worksState.selectedItems)
    const works = useFastServiceStore((state) => state.worksState.works)
    const workTimes = useFastServiceStore((state) => state.maintenancePlan.workTimes)
    const { workTask } = useWorkTask() || {}
    const { importFastCalculation } = useBasketImports()
    const classNames = getComponentStyles()
    const { totalRepairTimesFormatted } = useRepairTimes()

    useEffect(() => {
        stepNavigationActions.completeStep(currentStepName)

        const unsubscriptions: Array<() => void> = []
        const worktaskChannel = channel("WORKTASK", workTask?.id)
        unsubscriptions.push(
            worktaskChannel.subscribe("FAST-CALCULATOR/CALCULATION_DONE", (fastCalc) => {
                fastCalc.isFastCalcDone && useFastServiceStore.getState().setCalculationDone()
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [])

    const handleRequestCalculation = useCallback((ids: number[]) => {
        if (!getBundleParams().calculatorRoute) {
            return
        }

        const route = renderRoute(getBundleParams().calculatorRoute!, {
            ...match.params,
            productGroupIds: ids.join(","),
            origin: ECalcOrigin.FastService,
        })

        handleCloseFastCalcModal()

        Morpheus.showView("1", route)
    }, [])

    const handleClosePrintModal = () => {
        setShowPrintModal(false)
    }

    const handleCloseFastCalcModal = () => {
        setShowFastCalcModal(false)
    }

    const handleOpenPrintModal = () => {
        setShowPrintModal(true)
    }

    const handleOpenFastCalcModal = () => {
        setShowFastCalcModal(true)
    }

    const handleAddToCostEstimate = () => {
        if (!workTask?.id) {
            return
        }

        const memo = userSettings?.orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ? createMemo(workTask) : undefined

        const selectedRepairTimes = Object.entries(selectedItems)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([_, value]) =>
                Object.values(value)
                    .map((x) => x.selectedRepairTimes!)
                    .flat()
                    .filter((x) => !!x)
            )
            ?.flat()

        const repairTimes = Object.values(workTimes).flat().concat(selectedRepairTimes)

        const consumables = Object.values(works)
            .flatMap((x) => x)
            .filter((y) => !!y.consumables.length && y.consumables.some((c) => c.article && c.isSelected))
            .map((z) => z.consumables)
            .flatMap((item) => item)

        const oeArticles = Object.entries(selectedItems)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([_, value]) =>
                Object.values(value)
                    .filter((x) => x.selectedGenArts?.every((gA) => !gA.oeReplace))
                    .map((x) => x.selectedGenArts)
                    .flat()
                    .filter((x) => !!x)
            )
            ?.flat()

        const selectedItemsValues = Object.entries(selectedItems)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .map(([_, value]) => Object.values(value))
            .flat()

        const replacedArticles: Array<Article> = []

        selectedItemsValues.forEach((selectedItems) => {
            selectedItems.selectedGenArts?.forEach((gA) => {
                if (gA.oeReplace && gA.article) {
                    replacedArticles.push(gA.article)
                }
            })
        })

        const articles = [...replacedArticles, ...selectedArticles.filter((article) => article.supplier && article.productGroup)]

        const request = createImportCalculationRequest(
            articles,
            consumables,
            repairTimes,
            works,
            getLabourRate(userSettings),
            workTask,
            memo,
            currencyCode,
            oeArticles
        )

        if (!request) {
            return
        }

        importFastCalculation(request).then(
            () => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12998),
                    icon: "check",
                    skin: "success",
                    closeDelay: 3000,
                })
            },
            () => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12997),
                    icon: "error",
                    skin: "warning",
                    closeDelay: 3000,
                })
            }
        )
    }

    return (
        <>
            <div className={style.textWraper}>
                <Headline size="m">{translateText(13030)}</Headline>
                <Text size="m">{translateText(13031)}</Text>
            </div>
            <CustomEndModal open={showPrintModal} onOutsideClick={handleClosePrintModal} />
            <FastCalcSelection open={showFastCalcModal} onOutsideClick={handleCloseFastCalcModal} onCalculation={handleRequestCalculation} />
            <Scrollbar>
                <div className={classes(style.cardWrapper, classNames.wrapperMargins)}>
                    <div className={style.wrapper}>
                        <Card className={style.card}>
                            <Text className={style.mainTitle} size="xl">
                                {translateText(13034)}
                            </Text>
                            <Text size="m" modifiers="sub">
                                {translateText(13035)}
                            </Text>
                            <Button className={classNames.buttonPadding} size="xl" icon="fast-calculator" onClick={handleOpenFastCalcModal}>
                                {translateText(1042)}
                            </Button>
                            {calculationDone && <StyledBadge badgeContent={<Icon skin="success" name="check" size="xl" />} />}
                        </Card>
                        <Card className={style.card}>
                            <Text className={style.mainTitle} size="xl">
                                {translateText(13687)}
                            </Text>
                            <Text size="m" modifiers="sub">
                                {translateText(12960)}
                            </Text>
                            <Button
                                className={classNames.buttonPadding}
                                size="xl"
                                icon={languageId === "1" ? "voucher-kva" : "voucher-kva-international"}
                                disabled={!totalRepairTimesFormatted}
                                onClick={handleAddToCostEstimate}
                            >
                                {translateText(1607)}
                            </Button>
                        </Card>
                        <Card className={classes(style.card, style.lastCard)}>
                            <Text className={style.mainTitle} size="xl">
                                {translateText(13688)}
                            </Text>
                            <Text size="m" modifiers="sub">
                                {translateText(12961)}
                            </Text>
                            <Button className={classNames.buttonPadding} size="xl" icon="print" onClick={handleOpenPrintModal}>
                                {translateText(13033)}
                            </Button>
                        </Card>
                    </div>
                </div>
            </Scrollbar>
        </>
    )
}

export default withRouter(End)

const StyledBadge = styled(Badge)(({ theme }) => ({
    position: "absolute",
    top: "1px",
    right: "25px",
    "& .MuiBadge-badge": {
        padding: ".25rem",
        background: `${theme.colors?.success}`,
        height: "unset",
        borderRadius: "1rem",
        borderColor: "white !important",
        border: "1px solid",
        "& .icon": {
            fill: "white !important",
        },
    },
}))

const style = useStyle({
    wrapper: {
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    card: {
        $nest: {
            ".card__inner": {
                display: "flex",
                flexDirection: "column",
                gap: "3em",
                padding: "3em",
                alignItems: "baseline",
                justifyContent: "space-between",
                height: "100%",
            },
        },
    },
    lastCard: {
        gridColumn: "span 2",
        justifySelf: "center",
    },
    mainTitle: {
        fontWeight: 600,
    },
    textWraper: {
        display: "flex",
        padding: "1em 1em 2em 2em",
        gap: ".5em",
        flexDirection: "column",
    },
    cardWrapper: {
        display: "flex",
        justifyContent: "center",
    },
})(End)
