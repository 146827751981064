import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, FormControlLabel, DatePicker, Typography } from "@tm/components"
import { Item, OrderOptions, ItemTour } from "@tm/models"
import { BoxWithAlignItems, BoxWithColumnPaddingRight, IconWithMargin, RadioButtonSmallWithMargin, RadioGroupGrid } from "./StyledComponents"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onSetTour(selectedTour: ItemTour, selectedOptionsItem?: Item, updateErpInformation?: boolean, updateOrderOptions?: boolean): void
    onUpdateDeliveryDate(deliveryDate: Date, selectedOptionsItem?: Item): void
}

const today = new Date()
export default function DeliveryDateOptionsComponent(props: Props) {
    const { translateText, translate, language } = useLocalization()
    const {
        options: { tours, deliveryDate, selectedOptionsItem },
        disableFields,
        onSetTour,
        onUpdateDeliveryDate,
    } = props

    const [selectedDeliveryDate, setSelectedDeliverydate] = useState<Date>()
    const [selectedTourId, setSelectedTourId] = useState<string>()

    useEffect(() => {
        if (deliveryDate) {
            setSelectedDeliverydate(deliveryDate)
        }
    }, [deliveryDate])

    useEffect(() => {
        if (tours && tours.tours.length) {
            const defaultTour = tours.tours.find((tour) => tour.isSelected)
            if (defaultTour) {
                const tourId = defaultTour.id
                setSelectedTourId(tourId)
            } else {
                setSelectedTourId(undefined)
            }
        }
    }, [tours, deliveryDate])

    function handleTourChange(_: unknown, id: string) {
        if (tours?.tours.length) {
            setSelectedTourId(id)
            const firstTourMatch = tours.tours.find((tourOrder) => tourOrder.id === id)
            if (firstTourMatch) {
                onSetTour({ ...firstTourMatch }, selectedOptionsItem, tours.updateErpInformationOnChange, tours.updateOrderOptionsOnChange)
            }
        }
    }

    function handleDeliveryDateChange(value: Date | null) {
        const date = value || today
        setSelectedDeliverydate(date)
        onUpdateDeliveryDate(value || today, selectedOptionsItem)
    }

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="delivery-time" />
                <Typography variant="h4">{translate(96)}</Typography>
            </BoxWithAlignItems>
            {selectedDeliveryDate && (
                <Box ml="35px">
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onChange={() => {
                            /* do nothing */
                        }}
                        ignoreInvalidInputs
                        onAccept={handleDeliveryDateChange}
                        label={translateText(98)}
                        value={selectedDeliveryDate}
                        disabled={disableFields}
                        minDate={today}
                    />
                </Box>
            )}
            <RadioGroupGrid onChange={handleTourChange}>
                {tours?.tours.map((tour) => {
                    return (
                        <Box key={tour.id} display="flex">
                            <FormControlLabel
                                value={tour.id}
                                control={<RadioButtonSmallWithMargin disabled={disableFields} checked={tour.id === selectedTourId} />}
                                label={<Typography variant="body2">{tour.description}</Typography>}
                            />
                        </Box>
                    )
                })}
            </RadioGroupGrid>
        </BoxWithColumnPaddingRight>
    )
}
