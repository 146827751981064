import { RequestArticleListPayload, LinkedItemsRole, ErpInformation, Article, WholesalerArticle, ArticleIdentifier, ConfigParams } from "@tm/models"
import Morpheus, { AsyncAction } from "@tm/morpheus"

export type LoadArticleListActionType = {
    type: "LOAD_ARTICLE_LIST"
    payload: RequestArticleListPayload
    targetComponentId: string
}

const ROLES_TO_REQUEST = [
    LinkedItemsRole.Obligated,
    LinkedItemsRole.LinkedItems,
    LinkedItemsRole.Alternative,
    LinkedItemsRole.Accessory,
    LinkedItemsRole.Replacement,
    LinkedItemsRole.PawnItems,
]

export function showArticleLists(
    articles: Array<ErpInformation> | undefined,
    foundBySearchTerm?: string,
    foundByVehicleId?: string,
    requestedArticle?: Article
): AsyncAction<LoadArticleListActionType> {
    return (dispatch) => {
        ROLES_TO_REQUEST.forEach((role) => {
            const articlesForRole = articles?.filter((a) => !!a.itemsRole && a.itemsRole === role)
            dispatch(loadArticleList(role, articlesForRole, foundBySearchTerm, foundByVehicleId, requestedArticle))
        })
    }
}

function loadArticleList(
    role: LinkedItemsRole,
    articles: Array<ErpInformation> | undefined,
    foundBySearchTerm?: string,
    foundByVehicleId?: string,
    requestedArticle?: Article
): AsyncAction<LoadArticleListActionType> {
    return (dispatch) => {
        let targetComponentId = ""
        let hideArticleListWithoutResults = false

        const payload: RequestArticleListPayload = {
            foundBySearchTerm,
            foundByVehicleId,
            dontResetStateOnUnload: true,
        }

        switch (role) {
            case LinkedItemsRole.Obligated:
                targetComponentId = "details-erp-obligated-articles"
                hideArticleListWithoutResults = true
                break
            case LinkedItemsRole.LinkedItems:
                targetComponentId = "details-erp-linked-articles"
                break
            case LinkedItemsRole.Alternative:
                targetComponentId = "details-erp-alternative-articles"

                if (Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp) {
                    payload.articleForDbAlternatives = requestedArticle
                }

                break
            case LinkedItemsRole.Accessory:
                targetComponentId = "details-erp-accessory-articles"
                hideArticleListWithoutResults = true
                break
            case LinkedItemsRole.Replacement:
                targetComponentId = "details-erp-replacement-articles"
                break
            case LinkedItemsRole.PawnItems:
                targetComponentId = "details-erp-pawn-articles"
                break
            default:
                return
        }

        if (articles?.length) {
            if (articles.some((x) => x.wholesalerArticleNumber)) {
                payload.traderArticleNos = {
                    wholesalerParts: articles.map<WholesalerArticle>((x) => ({
                        wholesalerArticleNumber: x.wholesalerArticleNumber,
                        quantity: x.quantity,
                        additionalInformationExtended: x.additionalInformationExtended,
                        availability: x.availability,
                        dataSupplierArticleNumber: x.dataSupplierArticleNumber,
                        dataSupplierId: x.dataSupplierId,
                        prices: x.prices,
                        itemId: x.itemId,
                        warehouses: x.warehouses,
                        tour: x.tour,
                        itemRole: x.itemsRole,
                    })),
                    showNotFoundArticles: true,
                    hideDuplicatesWithDifferentProductGroups: true,
                    hideArticleListWithoutResults,
                }
            }

            if (requestedArticle?.productGroup.id) {
                payload.articleIdentifier = articles
                    .filter((x) => x.dataSupplierId && x.dataSupplierArticleNumber)
                    .map<ArticleIdentifier>((x) => ({
                        supplierId: x.dataSupplierId,
                        supplierArticleNo: x.dataSupplierArticleNumber,
                        productGroupId: requestedArticle.productGroup.id,
                    }))

                payload.retryArticleIdentifierSearchWithoutProductGroup = true
            }
        } else {
            payload.traderArticleNos = {
                wholesalerParts: [],
            }
        }

        return dispatch({
            type: "LOAD_ARTICLE_LIST",
            payload,
            targetComponentId,
        })
    }
}
