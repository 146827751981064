import { memo, useCallback, useEffect, useState } from "react"
import { Box, Button, Icon, Modal, Tooltip, Typography, styled } from "@tm/components"
import {
    AllowedFileSelectionTypes,
    FileSelectButton,
    FileSelectError,
    Headline,
    InputGroup,
    Button as OldButton,
    TextField,
    Toolbar,
} from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { convertDataURIToDecodedString, usePrevious } from "@tm/utils"
import { useImportArticles, useTotals } from "../../../../data/hooks/useCompilations"
import { getBundleParams, isWM } from "../../../../utils"
import { CompilationAddNavigation } from "./add/add"
import CompilationWMNavigation from "./components/details_wm"

type Props = {
    compilationId: string | undefined
    showCreator: boolean
    activeQuery: string
    onShowCreator(): void
    searchCompilation(query: string): void
    openCompilationDetails(compilationId: string): void
    openCompilations(): void
}

const SmallCloseIcon = styled(Box)(({ theme }) => ({
    opacity: 0.54,
    cursor: "pointer",
    marginRight: theme.spacing(0.2),
    paddingTop: theme.spacing(0.5),

    ">svg": {
        width: "1em",
        height: "1em",
    },
}))

const MAXFILESIZE = 1000
const FILETYPES: AllowedFileSelectionTypes = ["textTxt"]

export const NavigationComponent = memo((props: Props) => {
    const [searchText, setSearchText] = useState<string>("")
    const [fileToUpload, setFileToUpload] = useState<string>("")
    const [error, setError] = useState<string>("")
    const [openLayer, setOpenLayer] = useState<boolean>(false)

    const { totals } = useTotals(props.compilationId)
    const { importFile } = useImportArticles()
    const prevSearchText = usePrevious(searchText)
    const { translateText } = useLocalization()

    const { enableListImport } = getBundleParams()
    const detailsIsActive = !!props.compilationId

    const handleButtonClick = () => {
        props.searchCompilation(searchText)
    }

    useEffect(() => {
        if (prevSearchText && !searchText) {
            handleButtonClick()
        }
    }, [prevSearchText, handleButtonClick])

    const uploadImportArticleStream = useCallback(
        (deleteExisting: boolean) => {
            setOpenLayer(false)
            if (!props.compilationId || !fileToUpload) {
                return
            }

            importFile({ stream: fileToUpload, deleteExisting, compilationId: props.compilationId })
        },
        [props.compilationId, fileToUpload]
    )

    const openModal = useCallback(() => {
        setOpenLayer(true)
    }, [])

    const handleArticleImport = useCallback(
        (fileData: string) => {
            setFileToUpload(convertDataURIToDecodedString(fileData))
            if (totals?.positionCount) {
                openModal()
            }
        },
        [openModal, uploadImportArticleStream, totals?.positionCount]
    )

    const handleArticleImportError = useCallback((type: FileSelectError) => {
        if (type === "FILE_TOO_BIG") {
            setError(translateText(12797).replace("{0}", (MAXFILESIZE / 1000).toString()))
        }
        if (type === "WRONG_FILE_TYPE") {
            setError(translateText(12798).replace("{0}", FILETYPES.toString()))
        }
    }, [])

    const renderModalOkButton = useCallback(
        () => (
            <Box flex={3}>
                <Button fullWidth onClick={() => uploadImportArticleStream(true)} variant="contained">
                    {translateText(585)}
                </Button>
            </Box>
        ),
        [translateText, uploadImportArticleStream]
    )

    const renderModalCancelButton = useCallback(
        () => (
            <Box flex={3} pr={2}>
                <Button fullWidth onClick={() => uploadImportArticleStream(false)} variant="contained">
                    {translateText(584)}
                </Button>
            </Box>
        ),
        [translateText, uploadImportArticleStream]
    )

    const renderArticleImport = useCallback(() => {
        if (!enableListImport || !detailsIsActive) {
            return null
        }

        return (
            <Box ml={2} display="flex">
                <FileSelectButton
                    allowedFileTypes={FILETYPES}
                    maxFileSize={MAXFILESIZE}
                    onLoad={handleArticleImport}
                    onError={handleArticleImportError}
                    buttonProps={{
                        icon: "upload",
                        fakeButton: true,
                    }}
                    onOpenFileDialog={() => setError("")}
                >
                    {translateText(46)}
                </FileSelectButton>
                <Modal maxWidth="sm" sx={{ minWidth: 5 }} onOutsideClick={() => setOpenLayer(false)} open={openLayer}>
                    <Box p={3}>{translateText(13008)}</Box>
                    <Box pb={3} px={3} display="flex" justifyContent="space-between" textAlign="center">
                        {renderModalCancelButton()}
                        {renderModalOkButton()}
                    </Box>
                </Modal>
                {error && (
                    <Box my="auto" pl={1}>
                        <Tooltip title={<Typography>{error}</Typography>} variant="light">
                            <div>
                                <Icon name="alert" color="error" />
                            </div>
                        </Tooltip>
                    </Box>
                )}
            </Box>
        )
    }, [enableListImport, detailsIsActive, error, handleArticleImport])

    const renderOptions = () => {
        if (isWM()) {
            return (
                <>
                    <SmallCloseIcon />
                    <Toolbar title={translateText(177)}>
                        <InputGroup>
                            <CompilationWMNavigation
                                onShowCreator={props.onShowCreator}
                                showCreator={props.showCreator}
                                compilationId={props.compilationId}
                                openCompilations={props.openCompilations}
                            />
                        </InputGroup>
                        {renderArticleImport()}
                    </Toolbar>
                    {!detailsIsActive && (
                        <Toolbar title={translateText(135)}>
                            <InputGroup>
                                <TextField
                                    maxLength={100}
                                    placeholder={translateText(1770)}
                                    onChange={setSearchText}
                                    onChangeConfirm={handleButtonClick}
                                    preventConfirmOnBlur
                                    showClear
                                />
                                <OldButton icon="search" onClick={handleButtonClick} />
                            </InputGroup>
                        </Toolbar>
                    )}
                </>
            )
        }

        return (
            <>
                <Toolbar title={translateText(135)}>
                    <InputGroup>
                        <TextField
                            maxLength={100}
                            placeholder={translateText(1770)}
                            onChange={setSearchText}
                            onChangeConfirm={handleButtonClick}
                            preventConfirmOnBlur
                            showClear
                        />
                        <OldButton icon="search" onClick={handleButtonClick} />
                    </InputGroup>
                    {renderArticleImport()}
                </Toolbar>

                <Toolbar title={translateText(177)}>
                    <InputGroup>
                        <CompilationAddNavigation onShowCreator={props.onShowCreator} showCreator={props.showCreator} />
                    </InputGroup>
                </Toolbar>
            </>
        )
    }

    return (
        <div className="tk-compilations navigation">
            <Box mt={3}>
                <Headline size="s">{translateText(1240)}</Headline>
            </Box>
            {isWM() && (
                <Toolbar title={translateText(176)}>
                    <Button
                        onClick={props.openCompilations}
                        className={!detailsIsActive ? "is-active" : ""}
                        color={!detailsIsActive ? "primary" : undefined}
                        startIcon={<Icon name="menu" />}
                        variant="contained"
                    >
                        {translateText(6)}
                    </Button>
                    <Button
                        disabled={!detailsIsActive}
                        className={detailsIsActive ? "is-active" : ""}
                        color={detailsIsActive ? "primary" : undefined}
                        startIcon={<Icon name="details" />}
                    >
                        {translateText(43)}
                    </Button>
                </Toolbar>
            )}
            {renderOptions()}
        </div>
    )
})
