import { useDefaultErpSystem } from "@tm/utils"
import { ReactNode, useEffect, useState } from "react"
import { Article, ErpSystemConfigMode } from "@tm/models"
import { useErpInfo } from "../../../../../erp/src/micros/_helpers/useErpInfo"
import { AvailabilityFilterType } from "../../../business"
import { getBundleParams } from "../../../utils"

type Props = {
    children: ReactNode | ReactNode[]
    article: Article
    availability?: AvailabilityFilterType
}

export function AvailibilityHideWrapper(props: Props) {
    const { availabilityFilterItemsToShow, availabilityFilterItemsToShowSecondary } = getBundleParams()
    const defaultErp = useErpInfo(props.article, "list")

    const { erpConfig } = useDefaultErpSystem()
    const partnerConfig =
        erpConfig.mode === ErpSystemConfigMode.Partnersystems && erpConfig.erpSystemConfigs && erpConfig.erpSystemConfigs.length > 1
            ? erpConfig.erpSystemConfigs[1]
            : undefined

    const partnerErp = useErpInfo(props.article, "list", undefined, partnerConfig)

    const [hide, setHide] = useState(false)

    useEffect(() => {
        if (props.availability) {
            if (!defaultErp.loading) {
                if (availabilityFilterItemsToShow?.find((showType) => showType === defaultErp.erpInfo?.availability.type)) {
                    setHide(false)
                } else {
                    setHide(true)
                }
            }
            if (!partnerErp.loading && partnerErp.erpInfo && availabilityFilterItemsToShowSecondary) {
                if (availabilityFilterItemsToShowSecondary?.find((showType) => showType === partnerErp.erpInfo?.availability.type)) {
                    setHide(false)
                } else {
                    setHide(true)
                }
            }
        } else {
            setHide(false)
        }
    }, [defaultErp.loading, partnerErp.loading, props.availability])

    if (hide) {
        return null
    }

    return <>{props.children}</>
}
