import { useMemo } from "react"
import { Button, Icon, Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ETransferMode, ErpSystemConfig } from "@tm/models"
import { useErpConfig } from "@tm/utils"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { EGsiContext, useGsiContext } from "../../../data/hooks/useGsiContext"
import { useTransferCostEstimation } from "../../../data/hooks/useTransferCostEstimation"
import { ESendCostEstimationMode } from "../../../data/model"

type Props = {
    workTaskId: string
    disabled?: boolean
    mode: ESendCostEstimationMode
}
export default function TransferButtonsComponent({ workTaskId, disabled, mode }: Props) {
    const { translateText, languageId } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { erpSystemConfigs, useSendCostEstimation } = useErpConfig()
    const gsiContextId = useGsiContext(workTaskId)
    const { sendCostEstimation, loading } = useTransferCostEstimation(workTaskId, telesalesCustomerNo, mode)

    const erpSystemsWithSendCostEstimation = useMemo(() => {
        const buttons: Array<ErpSystemConfig> = []
        if (useSendCostEstimation) {
            erpSystemConfigs?.forEach((erpSystem) => {
                if (erpSystem.useForWorkTaskSendCostEstimation) {
                    const isContextBoschOs3 = erpSystem.transferMode === ETransferMode.BasketTransferBoschOs3 && gsiContextId === EGsiContext.BoschOs3
                    if (
                        (mode === ESendCostEstimationMode.costEstitmation &&
                            (isContextBoschOs3 || erpSystem.transferMode !== ETransferMode.BasketTransferBoschOs3)) ||
                        (mode === ESendCostEstimationMode.basket && isContextBoschOs3)
                    ) {
                        buttons.push(erpSystem)
                    }
                }
            })
        }
        return buttons
    }, [erpSystemConfigs, useSendCostEstimation, gsiContextId, mode])

    return (
        <>
            {loading && <Loader size="small" />}
            {!loading &&
                erpSystemsWithSendCostEstimation?.map((erpSystem) => {
                    return (
                        <Button
                            key={erpSystem.id}
                            color="highlight"
                            endIcon={<Icon name={languageId === "1" ? "voucher-kva" : "voucher-kva-international"} />}
                            onClick={() => sendCostEstimation(erpSystem.id)}
                            disabled={disabled}
                        >
                            {translateText(1209)} {erpSystem.description}
                        </Button>
                    )
                })}
        </>
    )
}
