import { useState, useCallback } from "react"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { Icon } from "@tm/controls"
import { Tooltip } from "@tm/components"
import PopoverMenu from "./components/menu"

type Props = RouteComponentProps<void> & {
    url: string
}

function UsersettingsMenuComponent({ url, match, history }: Props) {
    const [showMenu, setShowMenu] = useState<boolean>(false)

    function handleButtonClick() {
        if (match.url === url) {
            history.goBack()
        } else {
            setShowMenu(true)
        }
    }

    const handleTooltipHide = useCallback(() => {
        setShowMenu(false)
    }, [setShowMenu])

    let className = "tab tab--worktask"

    if (match.url === url) {
        className += " is-selected"
    }

    return (
        <div className="tk-wm usersettings-menu">
            <Tooltip
                arrow
                placement="bottom"
                onClose={handleTooltipHide}
                onClickAway={handleTooltipHide}
                open={showMenu}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                    <div className="user-settings__popover">
                        <PopoverMenu handleClose={handleTooltipHide} />
                    </div>
                }
                variant="light"
                color="primary"
            >
                <div id="user-settings-tab" className={className} onClick={handleButtonClick}>
                    <div className="tab__content">
                        <Icon size="l" className="user-info" name="user" />
                    </div>
                </div>
            </Tooltip>
        </div>
    )
}

export default withRouter(UsersettingsMenuComponent)
