import { useState, useEffect, useCallback } from "react"
import { Button, MenuItem, Select, SelectChangeEvent, Stack, styled } from "@tm/components"
import { Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ArticleAttributes, IMicros, ManufacturerBase, OE } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { mapOePart } from "@tm/utils"
import { getBundleParams } from "../../../../utils"
import ArticleCell from "../../article-cell"
import ArticleImageComponent from "../../article-image"
import ArticleAttributeGroupComponent from "../../article-item/attribute-group"
import Prices from "./components/prices"
import ArticleNumbers from "../../ArticleNumbers"

type Props = {
    parts: Array<OE.OePart>
    selectedManufacturer?: ManufacturerBase
    forceHideOePrice?: boolean
    showOePrice?: boolean
    displayVehicleOeCriteria?: boolean
    transferToShoppingCart?: boolean
}

const StyledButton = styled(Button)(() => ({}))
StyledButton.defaultProps = { variant: "outlined" }

const oePartImage = "/styles/base/images/oe-part-logo.svg"

export default function OeInformationItem(props: Props) {
    const { parts, selectedManufacturer, forceHideOePrice, showOePrice, displayVehicleOeCriteria, transferToShoppingCart } = props
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const [part, setPart] = useState<OE.OePart | undefined>()
    const [quantity, setQuantity] = useState<number>(1)

    useEffect(() => {
        const selectedPart = parts?.first()
        if (!selectedPart) {
            return
        }
        setPart(selectedPart)
        setQuantity(selectedPart.quantity && selectedPart.quantity > 0 ? selectedPart.quantity : 1)
    }, [parts])

    const handlePartChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const value = event.target.value as string

            if (!value) {
                return
            }

            const selectedPart = parts?.find((p) => p.id === value)

            if (selectedPart) {
                setPart(selectedPart)
            }
        },
        [setPart, parts]
    )

    if (!parts?.length || !part) {
        return null
    }

    const getAttributesToDisplay = (): ArticleAttributes[] | undefined => {
        if (!part.attributes) {
            return undefined
        }

        if (displayVehicleOeCriteria) {
            return part.attributes
        }

        return part.attributes.map((attribute) => {
            return {
                ...attribute,
                vehicleAttributes: [],
            }
        })
    }

    const renderOeInformationThumbnail = () => {
        return (
            <ArticleCell className="oe-item-thumbnail">
                <ArticleImageComponent
                    thumbnailClassName="article__thumbnail"
                    thumbnailUrl={selectedManufacturer?.thumbnail ?? oePartImage}
                    thumbnailFallbackUrl={part.thumbnailUrl ? oePartImage : undefined}
                    internalArticleId={0}
                    enableLargeView={false}
                />
                <Text>{part.manufacturerName}</Text>
            </ArticleCell>
        )
    }

    const renderOeInformationNumbers = () => {
        return (
            <ArticleCell bemModifier="numbers">
                <Stack direction="row" spacing={1}>
                    <StyledButton>
                        <ArticleNumbers oeArticleNumber={part.number} />
                    </StyledButton>
                    <StyledButton color="highlight">{translateText(803)}</StyledButton>
                </Stack>
            </ArticleCell>
        )
    }

    const renderBuyActions = () => {
        if (!transferToShoppingCart || !renderMicro) {
            return null
        }

        return (
            <Stack direction="row" spacing={0.5}>
                {renderMicro("basket", "add-to-basket-oe-part", {
                    part: mapOePart(part, quantity, !showOePrice),
                    quantity,
                    onChangeQuantity: setQuantity,
                })}
                {renderMicro("basket", "add-to-cost-estimate-oe-part", {
                    // The part must not be mapped since the mapping is performed in the micro
                    part,
                    quantity,
                    repairTimesProviders: getBundleParams().awProviders.map((x) => x?.id),
                })}
            </Stack>
        )
    }

    function renderOeInformationActions() {
        return <ArticleCell className="oe-item-actions">{renderBuyActions()}</ArticleCell>
    }

    const renderDescription = () => {
        return (
            <ArticleCell bemModifier="description">
                {parts.length > 1 && (
                    <Select onChange={handlePartChange} size="medium" value={part.id} variant="filled">
                        {parts.map((p) => (
                            <MenuItem key={p.id!} value={p.id}>
                                {p.description}
                            </MenuItem>
                        ))}
                    </Select>
                )}

                {parts.length === 1 && (
                    <Text className="article__description" title={part.description}>
                        {part.description}
                    </Text>
                )}
            </ArticleCell>
        )
    }

    const renderOeInformationPrice = () => {
        if (forceHideOePrice) {
            return <ArticleCell bemModifier="erp-information" />
        }

        return (
            <ArticleCell bemModifier="erp-information">
                {showOePrice && part.prices && <Prices prices={part.prices} quantity={quantity} />}
            </ArticleCell>
        )
    }

    const reanderAttributeCell = () => {
        const attributes = getAttributesToDisplay()

        return (
            <ArticleCell bemModifier="attributes" style={{ marginLeft: 0 }}>
                {attributes && (
                    <ArticleAttributeGroupComponent
                        isCompact
                        attributes={attributes}
                        selected={[]}
                        productGroupId={part.productGroupId ?? -1}
                        vehicleRecordsComparisons={[]}
                        canFilterArticleAttributes={false}
                        isOeInformationItem
                    />
                )}
            </ArticleCell>
        )
    }

    return (
        <div className="article-list__item article-list__panel article-list__item--oe article-list__panel">
            {renderOeInformationThumbnail()}
            {renderOeInformationNumbers()}
            {renderDescription()}
            {renderOeInformationPrice()}
            {renderOeInformationActions()}
            {reanderAttributeCell()}
        </div>
    )
}
