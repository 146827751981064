import { IWithLocalization } from "@tm/localization"
import { clone, uniqueId } from "@tm/utils"
import { StatusCodes } from "http-status-codes"
import { Dispatch } from "react"

import { Customers, MessageType as DmsMessageType, Vehicles, Vouchers } from "../../business/gateway"
import { messageChannel } from "../../business/messaging"

type MessageType = "status" | "error" | "auth-error"

type Message = {
    type: MessageType
    text: string
    exceptionMessage?: string
}

export type SearchType = "vehicle" | "voucher" | "customer"

export type Vehicle = Vehicles.Vehicle & {
    customer?: Customers.Customer
}

export type OverviewState = {
    message?: Message

    searchType?: SearchType

    filters: {
        voucherType?: Vouchers.VoucherType
    }

    customers: {
        loading: boolean
        endOfList: boolean
        processId?: string
        request?: Customers.FindCustomersRequest
        response?: Array<Customers.Customer>
        selected?: Customers.Customer
    }

    vehicles: {
        loading: boolean
        endOfList: boolean
        processId?: string
        request?: Vehicles.FindVehiclesRequest
        response?: Array<Vehicles.Vehicle>
        selected?: Vehicles.Vehicle
    }

    vouchers: {
        loading: boolean
        endOfList: boolean
        processId?: string
        request?: Vouchers.FindVouchersRequest
        response?: Array<Vouchers.Voucher>
        detailsLoading: boolean
        detailsProcessId?: string
        selected?: Vouchers.Voucher
    }
}

export type ActionType =
    | { type: "MESSAGE_SET"; payload: Message | undefined }
    | { type: "SEARCHTYPE_SET"; payload: SearchType | undefined }
    | { type: "VOUCHERTYPE_SET"; payload: Vouchers.VoucherType | undefined }
    | { type: "CUSTOMERS_LOADING"; payload: { processId: string; request: Customers.FindCustomersRequest } }
    | {
          type: "CUSTOMERS_LOADED"
          payload: { processId: string; request: Customers.FindCustomersRequest; response: Customers.FindCustomersResponse | undefined }
      }
    | { type: "VEHICLES_LOADING"; payload: { processId: string; request: Vehicles.FindVehiclesRequest } }
    | {
          type: "VEHICLES_LOADED"
          payload: { processId: string; request: Vehicles.FindVehiclesRequest; response: Vehicles.FindVehiclesResponse | undefined }
      }
    | { type: "VOUCHERS_LOADING"; payload: { processId: string; request: Vouchers.FindVouchersRequest } }
    | {
          type: "VOUCHERS_LOADED"
          payload: { processId: string; request: Vouchers.FindVouchersRequest; response: Vouchers.FindVouchersResponse | undefined }
      }
    | { type: "VOUCHER_LOADING"; payload: { processId: string } }
    | { type: "VOUCHER_LOADED"; payload: { processId: string; response: Vouchers.ShowVoucherResponse | undefined } }
    | { type: "CUSTOMER_SELECTED"; payload: Customers.Customer | undefined }
    | { type: "VEHICLE_SELECTED"; payload: Vehicles.Vehicle | undefined }
    | { type: "VOUCHER_SELECTED"; payload: Vouchers.Voucher | undefined }

export const INITIAL_STATE: OverviewState = {
    filters: {},

    customers: {
        loading: false,
        endOfList: false,
    },

    vehicles: {
        loading: false,
        endOfList: false,
    },

    vouchers: {
        loading: false,
        endOfList: false,
        detailsLoading: false,
    },
}

export function reduce(state: OverviewState, action: ActionType): OverviewState {
    switch (action.type) {
        case "MESSAGE_SET": {
            return {
                ...state,
                message: action.payload,
            }
        }

        case "SEARCHTYPE_SET": {
            return {
                ...clone(INITIAL_STATE),
                searchType: action.payload,
                filters: state.filters, // Keep current filters
            }
        }

        case "VOUCHERTYPE_SET": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    voucherType: action.payload,
                },
            }
        }

        case "CUSTOMERS_LOADING": {
            const { processId, request } = action.payload

            return {
                ...state,
                customers: {
                    ...state.customers,
                    loading: true,
                    processId,
                    request,
                },
            }
        }
        case "CUSTOMERS_LOADED": {
            let response = action.payload.response?.customers ?? []
            const endOfList = !response.length

            if (state.searchType === "vehicle") {
                if (state.vehicles.processId !== action.payload.processId) {
                    break
                }

                let vehicles: Array<Vehicle> = []

                response.forEach((customer) => {
                    if (customer.vehicles) {
                        vehicles.push(
                            ...customer.vehicles.map((vehicle) => ({
                                ...vehicle,
                                customer,
                            }))
                        )
                    }
                })

                if (state.vehicles.response && (action.payload.request.pageIndex ?? 1) > 1) {
                    vehicles = [...state.vehicles.response, ...vehicles]
                }

                return {
                    ...state,
                    vehicles: {
                        ...state.vehicles,
                        loading: false,
                        endOfList,
                        processId: undefined,
                        response: vehicles,
                    },
                }
            }

            if (state.customers.processId !== action.payload.processId) {
                break
            }

            if (state.customers.response && (action.payload.request.pageIndex ?? 1) > 1) {
                response = [...state.customers.response, ...response]
            }

            return {
                ...state,
                customers: {
                    ...state.customers,
                    loading: false,
                    endOfList,
                    processId: undefined,
                    response,
                },
            }
        }

        case "VEHICLES_LOADING": {
            const { processId, request } = action.payload

            return {
                ...state,
                vehicles: {
                    ...state.vehicles,
                    loading: true,
                    processId,
                    request,
                },
            }
        }
        case "VEHICLES_LOADED": {
            let response = action.payload.response?.vehicles ?? []
            const endOfList = !response.length

            if (state.vehicles.processId !== action.payload.processId) {
                break
            }

            if (state.vehicles.response && (action.payload.request.pageIndex ?? 1) > 1) {
                response = [...state.vehicles.response, ...response]
            }

            return {
                ...state,
                vehicles: {
                    ...state.vehicles,
                    loading: false,
                    endOfList,
                    processId: undefined,
                    response,
                },
            }
        }

        case "VOUCHERS_LOADING": {
            const { processId, request } = action.payload

            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    loading: true,
                    processId,
                    request,
                },
            }
        }
        case "VOUCHERS_LOADED": {
            let response = action.payload.response?.vouchers ?? []
            const endOfList = !response.length

            if (state.vouchers.processId !== action.payload.processId) {
                break
            }

            if (state.vouchers.response && (action.payload.request.pageIndex ?? 1) > 1) {
                response = [...state.vouchers.response, ...response]
            }

            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    loading: false,
                    endOfList,
                    processId: undefined,
                    response,
                },
            }
        }
        case "VOUCHER_LOADING": {
            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    detailsLoading: true,
                    detailsProcessId: action.payload.processId,
                },
            }
        }
        case "VOUCHER_LOADED": {
            if (state.vouchers.detailsProcessId !== action.payload.processId) {
                break
            }

            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    detailsLoading: false,
                    detailsProcessId: undefined,
                    selected: action.payload.response?.voucher ?? state.vouchers.selected,
                },
            }
        }

        case "CUSTOMER_SELECTED": {
            return {
                ...state,
                customers: {
                    ...state.customers,
                    selected: action.payload,
                },
                vehicles: {
                    ...state.vehicles,
                    response: undefined,
                    selected: undefined,
                },
                vouchers: {
                    ...state.vouchers,
                    response: undefined,
                    selected: undefined,
                },
            }
        }
        case "VEHICLE_SELECTED": {
            return {
                ...state,
                vehicles: {
                    ...state.vehicles,
                    selected: action.payload,
                },
                vouchers: {
                    ...state.vouchers,
                    response: undefined,
                    selected: undefined,
                },
            }
        }
        case "VOUCHER_SELECTED": {
            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    selected: action.payload,
                },
            }
        }
        default:
            break
    }

    return state
}

export function selectVoucher(dispatch: Dispatch<ActionType>, voucher: Vouchers.Voucher | undefined) {
    dispatch({ type: "VOUCHER_SELECTED", payload: voucher })
}

export function setMessage(dispatch: Dispatch<ActionType>, text: string | undefined, type: MessageType = "status", exceptionMessage?: string) {
    dispatch({ type: "MESSAGE_SET", payload: text !== undefined ? { type, text, exceptionMessage } : undefined })
}

export function registerGatewayResponseListener(dispatch: Dispatch<ActionType>, localization: IWithLocalization): () => void {
    const { translateText } = localization

    return messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (e) => {
        const { status, processId } = e

        if (status === StatusCodes.UNAUTHORIZED) {
            setMessage(dispatch, translateText(2002), "auth-error")
            return
        }

        switch (e.type) {
            case "findCustomers": {
                if (status === StatusCodes.OK) {
                    dispatch({ type: "CUSTOMERS_LOADED", payload: { processId, request: e.request, response: e.response } })
                } else {
                    setMessage(dispatch, translateText(1948), "error", e.response?.exceptionMessage)
                }
                break
            }
            case "findVehicles": {
                if (status === StatusCodes.OK) {
                    dispatch({ type: "VEHICLES_LOADED", payload: { processId, request: e.request, response: e.response } })
                } else {
                    setMessage(dispatch, translateText(1949), "error", e.response?.exceptionMessage)
                }
                break
            }
            case "findVouchers": {
                if (status === StatusCodes.OK) {
                    dispatch({ type: "VOUCHERS_LOADED", payload: { processId, request: e.request, response: e.response } })

                    // Automatically select voucher if only one was found
                    if (e.response?.vouchers.length === 1) {
                        selectVoucher(dispatch, e.response.vouchers[0])
                    }
                } else {
                    setMessage(dispatch, translateText(1950), "error", e.response?.exceptionMessage)
                }
                break
            }
            case "showVoucher": {
                if (status === StatusCodes.OK) {
                    dispatch({ type: "VOUCHER_LOADED", payload: { processId, response: e.response } })
                } else {
                    setMessage(dispatch, translateText(1951), "error", e.response?.exceptionMessage)
                }
                break
            }
            default:
                break
        }
    })
}

export function setSearchType(dispatch: Dispatch<ActionType>, searchType: SearchType | undefined) {
    dispatch({ type: "SEARCHTYPE_SET", payload: searchType })
}

export function setVoucherType(dispatch: Dispatch<ActionType>, voucherType: Vouchers.VoucherType | undefined) {
    dispatch({ type: "VOUCHERTYPE_SET", payload: voucherType })
}

export function searchCustomer(dispatch: Dispatch<ActionType>, request: Customers.FindCustomersRequest) {
    const processId = request.query ?? uniqueId()
    dispatch({ type: "CUSTOMERS_LOADING", payload: { processId, request } })
    messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "findCustomers", request })
}

export function searchVehicles(dispatch: Dispatch<ActionType>, request: Vehicles.FindVehiclesRequest) {
    const processId = request.query ?? uniqueId()
    dispatch({ type: "VEHICLES_LOADING", payload: { processId, request } })
    messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "findCustomers", request })
}

export function searchCustomerVehicles(dispatch: Dispatch<ActionType>, request: Vehicles.FindVehiclesRequest) {
    const processId = uniqueId()
    dispatch({ type: "VEHICLES_LOADING", payload: { processId, request } })
    messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "findVehicles", request })
}

export function searchVouchers(dispatch: Dispatch<ActionType>, request: Vouchers.FindVouchersRequest) {
    const processId = request.query ?? uniqueId()
    dispatch({ type: "VOUCHERS_LOADING", payload: { processId, request } })
    messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "findVouchers", request })
}

export function selectVehicle(dispatch: Dispatch<ActionType>, vehicle: Vehicles.Vehicle | undefined, voucherTypeFilter?: Vouchers.VoucherType) {
    dispatch({ type: "VEHICLE_SELECTED", payload: vehicle })

    if (vehicle) {
        searchVouchers(dispatch, {
            vehicleReferenceId: vehicle.referenceId,
            orderBy: "modifiedDate",
            orderByDescending: true,
            voucherTypeReferenceId: voucherTypeFilter?.referenceId,
        })
    }
}

export function selectCustomer(
    dispatch: Dispatch<ActionType>,
    customer: Customers.Customer | undefined,
    voucherTypeFilter?: Vouchers.VoucherType,
    permittedOperations?: Array<DmsMessageType>
) {
    dispatch({ type: "CUSTOMER_SELECTED", payload: customer })

    if (customer) {
        searchCustomerVehicles(dispatch, { customerReferenceId: customer.referenceId, orderBy: "modifiedDate", orderByDescending: true })

        if (customer.vehicles?.[0] && (!permittedOperations || permittedOperations.includes("findVouchers"))) {
            selectVehicle(dispatch, customer.vehicles[0], voucherTypeFilter)
        }
    }
}

export function showVoucher(dispatch: Dispatch<ActionType>, referenceId: string) {
    const processId = referenceId
    dispatch({ type: "VOUCHER_LOADING", payload: { processId } })
    messageChannel().publish("SEND_DMS_REQUEST", { processId, type: "showVoucher", request: { referenceId } })
}
