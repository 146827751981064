import {
    ArticleAttributeParams,
    ArticleAttributesParams,
    ArticlePriceParms,
    ArticleReferenceParms,
    ListFilter,
    ArticleAttribute,
    ArticleReference,
    AttributeComparisonModel,
    CompareWithVehicleRecordsAttributeVisualization,
    EMediaType,
    ModificationState,
    OE,
    PriceType,
} from "@tm/models"
import { TypographyProps } from "@tm/components"
import { ArticleAttributeFilter } from "../../../../data/model"
import { MultipleValueAttribute } from "../../../_shared/article-item/attribute-group"

export function mapPriceVariant(type: PriceType): TypographyProps["variant"] {
    switch (type) {
        case PriceType.Strikethrough:
            return "label"
        default:
            return "body2"
    }
}

export function mapPriceColor(type: PriceType): TypographyProps["color"] {
    switch (type) {
        case PriceType.Strikethrough:
            return "primary"
        case PriceType.Purchase:
            return "primary"
        default:
            return ""
    }
}

export function mapPriceFontWeight(type: PriceType): TypographyProps["fontWeight"] {
    switch (type) {
        case PriceType.Purchase:
            return "bold"
        default:
            return 600
    }
}

export function mapArticleAttributeFilter(attribute: ArticleAttribute): ArticleAttributeFilter {
    const query = `${attribute.id}|${attribute.id || attribute}`
    return {
        id: attribute.id,
        group: "",
        description: attribute.description,
        isSelected: true,
        name: attribute.abbreviation,
        query,
        unit: attribute.unit,
        priority: 0,
        text: attribute.text || attribute.value,
    }
}

export function mapArticleAttributeParms(attribute: MultipleValueAttribute): ArticleAttributeParams {
    return {
        id: attribute.id,
        key: attribute.key,
        label: attribute.abbreviation,
        values: attribute.values.map((value) => ({
            id: value.id,
            isSelected: false,
            value: value.value,
            displayValue: `${value.value} ${value.unit || ""}`.trim(),
            modificationState: value.modificationState ?? ModificationState.NotModified,
            isHighlighted: value.highlight,
            isOptimized: value.isOptimized,
            alternatives:
                value.alternatives?.map((alternative) => `${alternative.abbreviation}: ${`${alternative.value} ${alternative.unit || ""}`.trim()}`) ??
                [],
        })),
    }
}

export function mapAttributes(
    attributes: Array<ArticleAttribute>,
    productGroupId?: number,
    vehicleRecordAttributes?: AttributeComparisonModel[],
    selectedAttributes?: ListFilter[]
): Array<ArticleAttributeParams> {
    const mapped: Array<ArticleAttributeParams> = []

    attributes.forEach((attribute) => {
        let found = mapped.find(
            (attr) =>
                attr.id === attribute.id && // check if the attribute is already existing
                !!attribute.abbreviation // don't combine attributes without an abbrevation/label
        )

        if (!found) {
            found = {
                id: attribute.id,
                key: attribute.key,
                label: attribute.abbreviation,
                values: [],
            }
            mapped.push(found)
        }

        const isHighlighted =
            attribute.highlight ||
            vehicleRecordAttributes?.some(
                (x) =>
                    attribute.id === x.attribute.id &&
                    attribute.key === x.attribute.value &&
                    productGroupId === x.attribute.productGroupId &&
                    (x.visualization === CompareWithVehicleRecordsAttributeVisualization.Suggested ||
                        x.visualization === CompareWithVehicleRecordsAttributeVisualization.Confirmed)
            )

        const foundDuplicatedValue = found.values?.some((value) => value.value === attribute.value)

        if (!foundDuplicatedValue) {
            found.values = [
                ...(found.values ?? []),
                {
                    id: attribute.id,
                    isSelected: selectedAttributes?.some((x) => x.id === attribute.id && x.value === attribute.value),
                    value: attribute.value,
                    displayValue: `${attribute.value} ${attribute.unit || ""}`.trim(),
                    modificationState: attribute.modificationState ?? ModificationState.NotModified,
                    isHighlighted,
                    isOptimized: attribute.isOptimized,
                    alternatives:
                        attribute.alternatives?.map(
                            (alternative) => `${alternative.abbreviation}: ${`${alternative.value} ${alternative.unit || ""}`.trim()}`
                        ) ?? [],
                },
            ]
        }
    })

    return mapped
}

export function mapArticleReferences(reference: ArticleReference): ArticleReferenceParms {
    return {
        label: reference.referenceNo,
        referenceType: reference.referenceType,
        modificationState: reference.modificationState,
        description: reference.description,
        manufacturer: reference.manufacturer,
    }
}

export function getIconFromMediaType(mediaType: EMediaType) {
    switch (mediaType) {
        case EMediaType.Video:
        case EMediaType.Audio:
            return "arrow-right"
        case EMediaType.Pdf:
            return "file-pdf"
        case EMediaType.Website:
        case EMediaType.YoutubeChannel:
            return "external-link"
        default:
    }
}

export function mapArticleAttributes(part: OE.OePart, displayVehicleOeCriteria?: boolean): ArticleAttributesParams[] {
    return (
        part?.attributes
            ?.map((attributes) => {
                const additionalAttributes = [...attributes.partsListAttributes, ...attributes.articleAttributes]
                if (displayVehicleOeCriteria) {
                    additionalAttributes.push(...attributes.vehicleAttributes)
                }

                const result: ArticleAttributesParams = {
                    topArticleAttributes: mapAttributes([...attributes.topAttributes]),
                    additionalAttributes: mapAttributes(additionalAttributes),
                }
                return result
            })
            ?.filter((x) => x.topArticleAttributes?.length || x.additionalAttributes?.length) ?? []
    )
}

export function mapOePrices(part: OE.OePart, showOePrice?: boolean) {
    const oePrice: ArticlePriceParms[] = !showOePrice
        ? []
        : part.prices?.map((price, idx) => {
              return { modifier: "none", order: idx, price: `VK ${price.value} ${price.currency}`, title: price.description || "VK" }
          }) || []

    return oePrice
}
