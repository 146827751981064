import { Box, Grid } from "@tm/components"
import { Suspense, useEffect, useMemo, useRef } from "react"
import { Route } from "react-router"
import { SearchTreeRefType } from "../../searchtreeV2/component"
import { ArticleListWrapper, ArticleListWrapperRefType } from "../components/ArticleListWrapper"
import { ModuleHeader } from "../components/ModuleHeader"
import { SearchTree } from "../components/Searchtree"
import { UniversalPartsList } from "./UniversalPartsList"
import { UniversalPartsModuleStateProvider, useUniversalPartsModuleState } from "./UniversalPartsModuleState"

function UniversalPartsComponent() {
    const start = useUniversalPartsModuleState((x) => x.start)
    const startParams = useUniversalPartsModuleState((x) => x.params.startParams)
    const showAvailable = useUniversalPartsModuleState((x) => x.params.showAvailable)
    const productGroups = useUniversalPartsModuleState((x) => x.params.productGroups)
    const suppliers = useUniversalPartsModuleState((x) => x.params.suppliers)
    const attributes = useUniversalPartsModuleState((x) => x.params.attributes)

    const searchTreeRef = useRef<SearchTreeRefType>(null)
    const articleListWrapperRef = useRef<ArticleListWrapperRefType>(null)

    const hasMissingParams = useMemo(() => {
        switch (startParams.type) {
            case "uninode":
                return !startParams.treeId || !startParams.nodeId
            case "uniproductgroups":
                return !startParams.productGroupIds
            case "unisearch":
                return !startParams.query
            default:
                return true
        }
    }, [startParams])

    useEffect(start, [start])

    useEffect(
        function showSearchTreeWhenSearchCannotStart() {
            if (hasMissingParams) {
                searchTreeRef.current?.open()
            }
        },
        [hasMissingParams]
    )

    useEffect(
        function scrollToTopOnListChanged() {
            articleListWrapperRef.current?.scrollToTop()
        },
        [startParams, showAvailable, productGroups, suppliers, attributes]
    )

    return (
        <>
            <Route path="/:workTaskId/parts/:section/:view/:searchType?" component={ModuleHeader} />
            <Grid display="grid" gridTemplateRows="auto 1fr" overflow="hidden">
                <SearchTree treeType="universalParts" ref={searchTreeRef} />
                {!hasMissingParams && (
                    <ArticleListWrapper ref={articleListWrapperRef}>
                        <UniversalPartsList />
                    </ArticleListWrapper>
                )}
            </Grid>
        </>
    )
}

export function UniversalParts() {
    return (
        <Suspense fallback={<Box alignContent="center">Loading...</Box>}>
            <UniversalPartsModuleStateProvider>
                <UniversalPartsComponent />
            </UniversalPartsModuleStateProvider>
        </Suspense>
    )
}
