import { ManufacturerBase, VehicleType } from "@tm/models"
import { selectorFamily, useRecoilValue } from "recoil"

import { RequestWithVehicleType } from "."
import { CarBodyType } from "../model/filters"
import * as Bikes from "../repositories/bikes/showManufacturers"
import * as Cars from "../repositories/cars/showManufacturers"
import * as Trucks from "../repositories/trucks/showManufacturers"
import { getBundleParams } from "../../utils"

export type ManufacturersResponse =
    | undefined
    | {
          topManufacturers: Array<ManufacturerBase>
          primaryManufacturers: Array<ManufacturerBase>
          secondaryManufacturers: Array<ManufacturerBase>
          filters: ManufacturerFilters
      }

type ManufacturerFilters = {
    /** Only used for `VehicleType.PassengerCar` */
    bodyTypes: Array<CarBodyType>
}

type SelectedManufacturerFilters = {
    /** Only used for `VehicleType.PassengerCar` */
    bodyTypeId?: number
}

type TopManufacturersRequest = RequestWithVehicleType & {
    selectedFilters: SelectedManufacturerFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
}

type TopManufacturersResponse =
    | undefined
    | {
          topManufacturers: Array<ManufacturerBase>
      }

const topManufacturers = selectorFamily<TopManufacturersResponse, TopManufacturersRequest>({
    key: "vehicle_topManufacturers",
    get:
        ({ vehicleType, selectedFilters }) =>
        () => {
            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    return Cars.showTopManufacturers({ selectedFilters })
                case VehicleType.Motorcycle:
                    return Bikes.showTopManufacturers()
                case VehicleType.CommercialVehicle:
                    return Trucks.showTopManufacturers()
            }
        },
})

export function useTopManufacturers(request: TopManufacturersRequest) {
    return useRecoilValue(topManufacturers(request))
}

type AllManufacturersRequest = RequestWithVehicleType & {
    selectedFilters: SelectedManufacturerFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
}

const allManufacturers = selectorFamily<ManufacturersResponse, AllManufacturersRequest>({
    key: "vehicle_allManufacturers",
    get:
        ({ vehicleType, selectedFilters }) =>
        () => {
            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    return Cars.showAllManufacturers({ selectedFilters })
                case VehicleType.Motorcycle:
                    return Bikes.showAllManufacturers().then(mapBikeManufacturersResponse)
                case VehicleType.CommercialVehicle:
                    return Trucks.showAllManufacturers(getBundleParams()?.nkwPriority).then(mapTruckManufacturersResponse)
            }
        },
})

export function useAllManufacturers(request: AllManufacturersRequest) {
    return useRecoilValue(allManufacturers(request))
}

type ManufacturersByQueryRequest = RequestWithVehicleType & {
    query: string
    selectedFilters: SelectedManufacturerFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
}

const manufacturersByQuery = selectorFamily<ManufacturersResponse, ManufacturersByQueryRequest>({
    key: "vehicle_manufacturersByQuery",
    get:
        ({ vehicleType, query, selectedFilters }) =>
        () => {
            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    return Cars.showManufacturersByQuery({ query, selectedFilters })
                case VehicleType.Motorcycle:
                    return Bikes.showManufacturersByQuery({ query }).then(mapBikeManufacturersResponse)
            }
        },
})

export function useManufacturersByQuery(request: ManufacturersByQueryRequest) {
    return useRecoilValue(manufacturersByQuery(request))
}

type ManufacturersByArticleRequest = RequestWithVehicleType & {
    articleId: number
    selectedFilters: SelectedManufacturerFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
}

const manufacturersByArticle = selectorFamily<ManufacturersResponse, ManufacturersByArticleRequest>({
    key: "vehicle_manufacturersByArticle",
    get:
        ({ vehicleType, articleId, selectedFilters }) =>
        () => {
            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    return Cars.showManufacturersByArticle({ articleId, selectedFilters })
                case VehicleType.Motorcycle:
                    return Bikes.showManufacturersByArticle({ articleId }).then(mapBikeManufacturersResponse)
                case VehicleType.CommercialVehicle:
                    return Trucks.showManufacturersByArticle({ articleId }).then(mapTruckManufacturersResponse)
            }
        },
})

export function useManufacturersByArticle(request: ManufacturersByArticleRequest) {
    return useRecoilValue(manufacturersByArticle(request))
}

type ManufacturerDetailsRequest = RequestWithVehicleType & {
    manufacturerId?: number
}

type ManufacturerDetailsResponse = ManufacturerBase | undefined

const manufacturerDetailsRecoilSelector = selectorFamily<ManufacturerDetailsResponse, ManufacturerDetailsRequest>({
    key: "vehicle_manufacturerDetails",
    get:
        ({ vehicleType, manufacturerId }) =>
        () => {
            if (!manufacturerId) {
                return
            }

            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    return Cars.showManufacturerDetails({ manufacturerId }).then((response) => response?.manufacturerDetails)
                case VehicleType.Motorcycle:
                    return Bikes.showManufacturerDetails({ manufacturerId }).then((response) => response?.manufacturerDetails)
                case VehicleType.CommercialVehicle:
                    return Trucks.showManufacturerDetails({ manufacturerId }).then((response) => response?.manufacturerDetails)
            }
        },
})

export function useManufacturerDetails(request: ManufacturerDetailsRequest) {
    return useRecoilValue(manufacturerDetailsRecoilSelector(request))
}

function mapBikeManufacturersResponse(response: Bikes.ManufacturersResponse | undefined): ManufacturersResponse {
    return {
        topManufacturers: response?.topManufacturers ?? [],
        primaryManufacturers: response?.primaryManufacturers ?? [],
        secondaryManufacturers: response?.secondaryManufacturers ?? [],
        filters: {
            bodyTypes: [],
        },
    }
}

function mapTruckManufacturersResponse(response: Trucks.ManufacturersResponse | undefined): ManufacturersResponse {
    const result: ManufacturersResponse = {
        topManufacturers: response?.topManufacturers ?? [],
        primaryManufacturers: [],
        secondaryManufacturers: [],
        filters: {
            bodyTypes: [],
        },
    }

    response?.manufacturers?.forEach((manufacturer) => {
        if (manufacturer.priority === 1) {
            result.primaryManufacturers.push(manufacturer)
        } else {
            result.secondaryManufacturers.push(manufacturer)
        }
    })

    return result
}
