import { UserSettingsKeys, useUser } from "@tm/context-distribution"
import { TmaHelper } from "@tm/utils"
import { isEqual } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { getBundleParams } from "../../../utils"
import { PartsViewSettings } from "../models"
import { PartsViewSettingsState, ShowPurchasePriceState } from "../states"

export function usePartsViewOptions() {
    const { userSettings, setUserSetting } = useUser()
    const [partsViewSettings, setPartsViewSettingsInternal] = useRecoilState(PartsViewSettingsState)
    const [showPurchasePrice, setShowPurchasePriceInternal] = useRecoilState(ShowPurchasePriceState)
    const [isLoading, setIsLoading] = useState(false)
    const { compactViewAsDefault } = getBundleParams()

    const saveSettings = useCallback(
        async (key: UserSettingsKeys, value: unknown) => {
            setIsLoading(true)
            await setUserSetting(key, value)
            setIsLoading(false)
        },
        [setUserSetting]
    )

    useEffect(() => {
        const viewOptions: PartsViewSettings = {
            compactView: userSettings?.articleListSettings?.viewOptions?.compactView ?? compactViewAsDefault ?? true,
            showArticleImages: userSettings?.articleListSettings?.viewOptions?.showArticleImages ?? true,
            showVehicleRecordsFilters: userSettings?.articleListSettings?.viewOptions?.showVehicleRecordsFilters ?? true,
            quantitySuggestionEnabled: userSettings?.articleListSettings?.viewOptions?.quantitySuggestionEnabled ?? true,
        }

        setPartsViewSettingsInternal((prev) => {
            if (isEqual(prev, viewOptions)) {
                return prev
            }
            TmaHelper.ArticleListFiltered.ArticleListFiltered.List.ViewOptions(viewOptions)
            return viewOptions
        })

        if (userSettings?.showPurchasePrice !== undefined) {
            setShowPurchasePriceInternal(userSettings.showPurchasePrice)
        }
    }, [userSettings?.articleListSettings?.viewOptions, userSettings?.showPurchasePrice, setPartsViewSettingsInternal, setShowPurchasePriceInternal])

    const setPartsViewSettings = useCallback(
        (settings: PartsViewSettings) => {
            const changeViewOptions = {
                ...(userSettings?.articleListSettings?.viewOptions ?? {
                    compactView: compactViewAsDefault ?? true,
                    showArticleImages: true,
                    showVehicleRecordsFilters: true,
                    quantitySuggestionEnabled: true,
                }),
                ...settings,
            }

            if (!isEqual(changeViewOptions, userSettings?.articleListSettings?.viewOptions)) {
                setPartsViewSettingsInternal(settings)
                saveSettings("ARTICLE_LIST_SETTINGS", {
                    ...userSettings?.articleListSettings,
                    viewOptions: changeViewOptions,
                })
            }
        },
        [saveSettings, setPartsViewSettingsInternal, userSettings?.articleListSettings]
    )

    const setShowPurchasePrice = (value: boolean) => {
        if (userSettings?.showPurchasePrice !== value) {
            saveSettings("SHOW_PURCHASE_PRICE", value)
            setShowPurchasePriceInternal(value)
        }
    }

    return { partsViewSettings, setPartsViewSettings, showPurchasePrice, setShowPurchasePrice, isLoading }
}
