import { ActiveVehicleDataProviders, ModuleGroup, VehicleType } from "@tm/models"

export const getCorrectModuleGroup = (
    dataProvider: ActiveVehicleDataProviders,
    vehicleType: VehicleType | undefined,
    moduleGroups: ModuleGroup[]
): { moduleGroup: ModuleGroup | undefined; providerName: string | undefined } | undefined => {
    let groupProvider = Object.keys(dataProvider)?.[0]

    if (!groupProvider) {
        return undefined
    }

    const providerName = dataProvider[groupProvider as keyof ActiveVehicleDataProviders]?.toLowerCase()

    if (groupProvider === "inspectionData") {
        groupProvider = "technicalData"
    }

    if (vehicleType === VehicleType.CommercialVehicle && groupProvider === "technicalData") {
        groupProvider = "technicalDataTruck"
    }

    if (!groupProvider) {
        return undefined
    }

    const moduleGroup = moduleGroups.find((group) => group.id === groupProvider)

    return { moduleGroup, providerName }
}
