import { CreateCustomArticleRequest } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"

function getServiceUrl() {
    return getBundleParams().customArticlesServiceUrl
}

export function saveCustomArticle(body: CreateCustomArticleRequest) {
    return ajax<{ id: string }>({ url: `${getServiceUrl()}/Save`, body, authorization: getStoredAuthorization(), method: "POST" })
        .then((response) => {
            return response?.id
        })
        .catch(() => {
            return undefined
        })
}
