import { Paged, Memo } from "."
import { VehicleType } from "./vehicles"

export type FindRepairTimesRequest = Paged & {
    query?: string
    orderBy?: string
    orderByDescending?: boolean
}

export type FindRepairTimesResponse = Paged & {
    repairTimes: Array<RepairTime>
}

export type ShowRepairTimeRequest = {
    referenceId: string
}

export type ShowRepairTimeResponse = {
    repairTime?: RepairTime
}

export type RepairTime = {
    referenceId: string
    repairTimeId: string
    referencedRepairTimeId: string
    provider: Provider

    description: string
    type: RepairTimeType
    typeOfWork: WorkCategory
    typeOfWorkShortCode: string
    vehicleType: VehicleType
    calculation: RepairTimeCalculation

    isChangeable: boolean
    isChanged: boolean
    isExpanded: boolean

    memos: Array<Memo>
}

export enum Provider {
    Undefined = 0,
    AwDoc = 1,
    Haynes = 2,
    Autodata = 3,
    TecRMI = 4,
    Eurotax = 5,
    DAT = 6,
    Custom = 7,
}

export enum RepairTimeType {
    Undefined = 0,
    MainWork = 1,
    IncludesWork = 2,
    PreliminaryWork = 3,
    PreparatoryWork = 4,
}

export enum WorkCategory {
    NotCategorized = 0,
    WorkshopWork = 1, // WS - Werkstattarbeiten
    BodyWork = 2, // KA - Karosseriearbeiten,
    AccessoryWork = 3, // ZB - Zubehörarbeiten
    PaintWork = 4, // LA - Lackierarbeien
    ElectricalWork = 5, // EL - Elektrikarbeiten
    ElectronicWork = 6, // EK - Elektronikarbeiten
    SaddlerWork = 7, // SA - Sattlerarbeiten (Ere)
    SmartRepair = 8, // SR - SmartRepair (Ere)
}

export type RepairTimeCalculation = {
    hourlyRate?: number

    priceValue: number
    priceType: string
    priceDescription: string
    isFixedPrice: boolean

    currencyCode: string
    currencySymbol: string

    rebateValue: number
    rebate: number

    vat: number
    isTaxIncluded: boolean

    timeValue: number
    calculatedTimeValue?: number
    division?: number
}

export type HourlyRate = {
    description: string
    shortCode: string

    category: WorkCategory

    value: number
    vat: number
}
