import { useCallback, useState } from "react"
import { atom, selectorFamily, useRecoilState, useResetRecoilState, useSetRecoilState, DefaultValue } from "recoil"
import { OrderResponseGroup } from "@tm/models"
import { useQuery } from "react-query"
import { OrderItemGroup, ShowWorkTasksRequest, WorkTask } from "../model"
import * as Data from ".."
import { getNextOrderGroupsAndCurrentlyUpdatingPartIds, useSharedWorkTaskOrderReloadListeners } from "./shared/useSharedWorkTaskOrderReloadListeners"
import { useGlobalOrderOptionsState } from "../../hooks/useGlobalOrderOptionsState"

type State = {
    pageIndex: number
    loading: boolean
    hasMorePages: boolean
    loadingNextPage: boolean
    error?: string

    dateTo?: Date
    dateFrom?: Date

    showOnlyUserWorkTasks?: boolean

    workTasks: Array<CentralOrderWorkTaskState>
}

export type CentralOrderWorkTaskState = {
    workTask: WorkTask
    loading: boolean
    includedInOrder: boolean
    expanded: boolean
    selected: boolean
    disabled: boolean
    succeededOrderGroups?: Array<OrderResponseGroup>
    orderGroups?: Array<OrderItemGroup>
    currentlyUpdatingPartIds: Set<string>
}

const centralOrderWorkTasksAtom = atom<State>({
    key: "basket_centralOrderWorkTasksAtom",
    default: {
        pageIndex: 1,
        loading: true,
        hasMorePages: false,
        loadingNextPage: false,
        workTasks: [],
    },
})

export function useCentralOrderWorkTasks() {
    const [state, setState] = useRecoilState(centralOrderWorkTasksAtom)
    const [request, setRequest] = useState<ShowWorkTasksRequest>()
    const reset = useResetRecoilState(centralOrderWorkTasksAtom)
    const { centralOrderParameters } = useGlobalOrderOptionsState()
    const pageSize = centralOrderParameters.orderPagingSize

    const load = useCallback(
        async (req?: ShowWorkTasksRequest, pageIndex?: number) => {
            if (!req) {
                return
            }
            if (pageIndex) {
                setState((prev) => ({
                    ...prev,
                    loading: true,
                    workTasks: prev.loadingNextPage ? prev.workTasks : [],
                    pageIndex,
                }))
            }
            try {
                const response = await Data.showWorkTasks(req)
                let workTasks: CentralOrderWorkTaskState[] = []
                if (response?.workTaskInfos) {
                    const mappedResult = response?.workTaskInfos?.map<CentralOrderWorkTaskState>((workTask) => ({
                        workTask,
                        loading: false,
                        includedInOrder: true,
                        expanded: true,
                        selected: false,
                        disabled: false,
                        currentlyUpdatingPartIds: new Set(),
                    }))
                    if (state.loadingNextPage) {
                        workTasks = state.workTasks.concat(mappedResult)
                    } else {
                        workTasks = mappedResult
                    }
                }

                const workTaskIds = workTasks.map((workTask) => workTask.workTask.workTaskId)
                if (workTaskIds?.length) {
                    const { workTaskOrderGroups } = await Data.showCentralOrder({ workTaskIds })
                    workTasks = workTasks.map((workTask) => {
                        const found = workTaskOrderGroups.find((w) => w.workTaskId === workTask.workTask.workTaskId)
                        if (found) {
                            return { ...workTask, loading: false, orderGroups: found.orderGroups }
                        }
                        return workTask
                    })
                }

                setState((prev) => ({
                    ...prev,
                    loading: false,
                    hasMorePages: response?.workTaskInfos?.length === pageSize,
                    workTasks,
                    loadingNextPage: false,
                }))
            } catch (error: any) {
                setState((prev) => ({ ...prev, error: error?.message }))
            }
        },
        [setState, state, pageSize]
    )

    useQuery({
        queryKey: ["basket_centralOrderWorkTasks", request],
        queryFn: () => load(request),
    })

    const search = useCallback(
        (dateTo?: Date, dateFrom?: Date, showOnlyUserWorkTasks?: boolean) => {
            const pageIndex = 1
            setRequest({ pageIndex, pageSize, dateTo, dateFrom, showOnlyUserWorkTasks: showOnlyUserWorkTasks ?? false })
        },
        [pageSize]
    )

    const loadNextPage = useCallback(() => {
        const pageIndex = state.pageIndex + 1
        setRequest((prev) => ({ ...prev, pageSize, pageIndex }))
        setState((prev) => ({ ...prev, loadNextPage: true }))
    }, [state.pageIndex, setState, pageSize])

    const selectAll = useCallback(
        (selected: boolean) => {
            setState((prev) => ({ ...prev, workTasks: prev.workTasks.map((w) => ({ ...w, selected })) }))
        },
        [setState]
    )

    const setWorkTaskStatusByList = useCallback(
        (workTaskIds: Array<string>, property: "includedInOrder" | "expanded", value: boolean) => {
            setState((prev) => ({
                ...prev,
                workTasks: prev.workTasks.map((w) => {
                    if (workTaskIds.includes(w.workTask.workTaskId)) {
                        return { ...w, [property]: value }
                    }
                    return w
                }),
            }))
        },
        [setState]
    )

    const toggleExpanded = useCallback(
        (workTaskId: string) => {
            setState((prev) => ({
                ...prev,
                workTasks: prev.workTasks.map((w) => {
                    if (workTaskId === w.workTask.workTaskId) {
                        return { ...w, expanded: !w.expanded }
                    }
                    return w
                }),
            }))
        },
        [setState]
    )

    const hideWorkTask = useCallback(
        (workTaskId: string) => {
            setState((prev) => ({
                ...prev,
                workTasks: prev.workTasks.filter((w) => w.workTask.workTaskId !== workTaskId),
            }))
        },

        [setState]
    )
    return { state, search, loadNextPage, reset, selectAll, setWorkTaskStatusByList, toggleExpanded, hideWorkTask }
}

const centralOrderStateByWorkTaskSelector = selectorFamily<CentralOrderWorkTaskState | undefined, string>({
    key: "basket_centralOrderStateByWorkTaskSelector",
    get:
        (workTaskId) =>
        ({ get }) =>
            get(centralOrderWorkTasksAtom).workTasks.find((w) => w.workTask.workTaskId === workTaskId),
    set:
        (workTaskId) =>
        ({ set }, newValue) => {
            if (!newValue || newValue instanceof DefaultValue) {
                return
            } // TODO?

            set(centralOrderWorkTasksAtom, (prevState) => {
                const foundState = prevState.workTasks.find((w) => w.workTask.workTaskId === workTaskId)
                if (foundState) {
                    return {
                        ...prevState,
                        workTasks: prevState.workTasks.map((w) => {
                            if (w.workTask.workTaskId === workTaskId) {
                                return newValue
                            }
                            return w
                        }),
                    }
                }

                console.warn("basket_centralOrderStateByWorkTaskSelector: tried to set state of an unknown workTask")
                return prevState
            })
        },
})

export function useCentralOrderStateByWorkTask(workTaskId: string) {
    const setState = useSetRecoilState(centralOrderStateByWorkTaskSelector(workTaskId))

    const toggleIncludedInOrder = useCallback(
        (value?: boolean) => {
            setState((prev) => prev && { ...prev, includedInOrder: value ?? !prev?.includedInOrder })
        },
        [setState]
    )

    const toggleSelected = useCallback(
        (value?: boolean) => {
            setState((prev) => prev && { ...prev, selected: value ?? !prev?.selected })
        },
        [setState]
    )

    const toggleDisabled = useCallback(
        (value?: boolean) => {
            setState((prev) => prev && { ...prev, disabled: value ?? !prev?.disabled })
        },
        [setState]
    )

    const setSucceededOrderGroups = useCallback(
        (succeededOrderGroups?: Array<OrderResponseGroup>) => {
            setState((prev) => prev && { ...prev, succeededOrderGroups })
        },
        [setState]
    )

    const setOrderGroupsAndCurrentlyUpdatingPartIds = useCallback(
        (getNext: getNextOrderGroupsAndCurrentlyUpdatingPartIds) => {
            setState(
                (prev) => prev && { ...prev, ...getNext({ orderGroups: prev.orderGroups, currentlyUpdatingPartIds: prev.currentlyUpdatingPartIds }) }
            )
        },
        [setState]
    )

    useSharedWorkTaskOrderReloadListeners(workTaskId, setOrderGroupsAndCurrentlyUpdatingPartIds)

    return { toggleIncludedInOrder, toggleSelected, toggleDisabled, setSucceededOrderGroups }
}
