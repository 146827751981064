import { useWorkTask } from "@tm/context-distribution"
import { SuggestionFieldButtonGroup } from "@tm/controls"
import { BikeModel, CarModel, RegistrationNoType, TruckModel, VehicleLookupConfig, VehicleShortInfo, VehicleType } from "@tm/models"
import { RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { useRef, useEffect, useCallback } from "react"

import VehicleSearchField from "../../_shared/VehicleSearchField"
import { useResetFilters } from "../../../data/hooks"
import { getVehicleSearchUrl } from "../../../helpers/routing"
import { handleUniqueVehicleFound } from "../../../helpers/vrm"

type RouteParams = {
    workTaskId?: string
}

type SearchProps = RouteComponentProps<RouteParams> & {
    vehicleType: VehicleType
    placeholderPassengerCar?: string
    selectedSearchConfig?: VehicleLookupConfig
}

function Search(props: SearchProps) {
    const { vehicleType, history, match, selectedSearchConfig } = props
    const { attachToWorkTask, workTask } = useWorkTask() ?? {}
    const searchFieldRef = useRef<SuggestionFieldButtonGroup<any>>(null)
    const resetFilters = useResetFilters(vehicleType)

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            searchFieldRef.current?.focus()
        }, 25)
        return () => window.clearTimeout(timeout)
    }, [])

    const handleStartSearch = useCallback(
        (query: string, registrationNoType?: RegistrationNoType, selectedVehicleLookupConfig?: VehicleLookupConfig) => {
            resetFilters() // Reset filters before opening search page
            history.push(
                getVehicleSearchUrl(match.params, {
                    vehicleType,
                    query,
                    registrationNoType,
                })
            )
        },
        [resetFilters, history, match, vehicleType]
    )

    const handleUniqueVehicle = useCallback(
        (model: CarModel | BikeModel | TruckModel | VehicleShortInfo, query: string) => {
            if (attachToWorkTask) {
                handleUniqueVehicleFound(
                    model,
                    vehicleType,
                    query,
                    attachToWorkTask,
                    undefined,
                    selectedSearchConfig?.countryCode,
                    selectedSearchConfig?.lookupTypeId
                )
            }
        },
        [attachToWorkTask, vehicleType, selectedSearchConfig]
    )

    const handleAttachVehicleId = useCallback(
        (vehicleId: string, query: string) => {
            if (!attachToWorkTask) {
                return
            }
            TmaHelper.VehicleSelection.Search.FromWidget({ dataSourceId: RegistrationNoType.VehicleBase, query })
            attachToWorkTask({ vehicle: vehicleId })
        },
        [attachToWorkTask]
    )

    return (
        <VehicleSearchField
            {...props}
            searchFieldRef={searchFieldRef}
            onStartSearch={handleStartSearch}
            onUniqueVehicleFound={attachToWorkTask ? handleUniqueVehicle : undefined}
            onAttachVehicleId={handleAttachVehicleId}
            showVrcScanButton={!workTask?.vehicle}
        />
    )
}

export default withRouter(Search)
