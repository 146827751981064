import { useLocalization } from "@tm/localization"
import { Widget } from "@tm/controls"
import { Box, Image } from "@tm/components"
import { TextBody1Bold, TypographyTitle } from "../_shared/StyledComponents"
import EmailComponent from "../_shared/customer/Email"
import PhoneComponent from "../_shared/customer/Phone"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"

type Props = {
    description: string
    imageUrl: string
    phone: string
    eMail: string
    widgetSize?: "2x2" | "4x2"
    titleTextId?: string
}

export default function WidgetContactsComponent({ imageUrl, eMail, titleTextId, widgetSize, description, phone }: Props) {
    const { translate } = useLocalization()

    return (
        <Widget
            size={widgetSize || "2x2"}
            active
            title={translate(titleTextId || 1120)}
            iconName="catalog"
            header={<WidgetHeaderTitleComponent iconName="catalog" titleTextId={titleTextId || 1120} />}
        >
            <Box display="flex" flexDirection="column">
                <TypographyTitle>{translate(823)}</TypographyTitle>
                {!!imageUrl && (
                    <Box>
                        <Image height={60} src={imageUrl} />
                    </Box>
                )}
                <TextBody1Bold>{description}</TextBody1Bold>
                {phone && <PhoneComponent phone={phone} />}
                {eMail && <EmailComponent email={eMail} />}
            </Box>
        </Widget>
    )
}
