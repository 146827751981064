import { useStyle } from "@tm/context-distribution"
import { Divider } from "@tm/components"
import { Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FC, ReactNode, memo } from "react"
import { CollapsibleWrapper, SecutiryStatus, UploadPic } from "."
import { PrintImage } from "../../data/models"

type Props = {
    onSavePicture(img: PrintImage, replaceImage: boolean): void
    path: string
    additionalContent?: ReactNode
    collapsibleName?: string
    onCommentSave(comm: string): void
    onSelect?: (value: number) => void
    selectedData?: { safetyStatus?: number; description?: string }
}

const DefectDescription: FC<Props> = memo(({ onSavePicture, path, additionalContent, collapsibleName, onCommentSave, onSelect, selectedData }) => {
    const { translateText } = useLocalization()

    return (
        <div>
            <Text className={style.padding} size="l">
                {translateText(12767)}
            </Text>
            <CollapsibleWrapper textId={617}>
                <Text className={style.padding} size="m">
                    {translateText(12751)}
                </Text>
                <TextField
                    size="xl"
                    onChangeConfirm={onCommentSave}
                    className={style.marginTop}
                    placeholder={translateText(12752)}
                    value={selectedData?.description}
                />
                <Divider light className={style.marginTop} />
            </CollapsibleWrapper>

            {additionalContent && additionalContent}

            <CollapsibleWrapper textId={12753}>
                <Text className={style.padding} size="m">
                    {translateText(12754)}
                </Text>
                <UploadPic onSavePicture={onSavePicture} path={path} collapsibleName={collapsibleName} />
            </CollapsibleWrapper>
            {path === "tyresAndWheels" && <SecutiryStatus onSelect={onSelect || (() => {})} value={selectedData?.safetyStatus || 0} />}
        </div>
    )
})

export default DefectDescription

const style = useStyle({
    padding: {
        padding: ".5em .5em .5em 0",
    },
    marginTop: {
        marginTop: "1em !important",
    },
})(DefectDescription)
