import { Box, Grid } from "@tm/components"
import { Suspense, useEffect, useMemo, useRef } from "react"
import { Route } from "react-router"
import { ArticleListWrapper, ArticleListWrapperRefType } from "../components/ArticleListWrapper"
import { ModuleHeader } from "../components/ModuleHeader"
import { DirectSearchList } from "./DirectSearchList"
import { DirectSearchModuleStateProvider, useDirectSearchModuleState } from "./DirectSearchModuleState"

function DirectSearchComponent() {
    const start = useDirectSearchModuleState((x) => x.start)
    const startParams = useDirectSearchModuleState((x) => x.params.startParams)
    const showAvailable = useDirectSearchModuleState((x) => x.params.showAvailable)
    const productGroups = useDirectSearchModuleState((x) => x.params.productGroups)
    const suppliers = useDirectSearchModuleState((x) => x.params.suppliers)

    const articleListWrapperRef = useRef<ArticleListWrapperRefType>(null)

    const hasMissingParams = useMemo(() => {
        switch (startParams.type) {
            case "direct":
                return !startParams.query
            default:
                return true
        }
    }, [startParams])

    useEffect(start, [start])

    useEffect(
        function scrollToTopOnListChanged() {
            articleListWrapperRef.current?.scrollToTop()
        },
        [startParams, showAvailable, productGroups, suppliers]
    )

    return (
        <>
            <Route path="/:workTaskId/parts/:section/:view/:searchType?" component={ModuleHeader} />
            <Grid display="grid" gridTemplateRows="1fr" overflow="hidden">
                {!hasMissingParams && (
                    <ArticleListWrapper ref={articleListWrapperRef}>
                        <DirectSearchList />
                    </ArticleListWrapper>
                )}
            </Grid>
        </>
    )
}

export function DirectSearch() {
    return (
        <Suspense fallback={<Box alignContent="center">Loading...</Box>}>
            <DirectSearchModuleStateProvider>
                <DirectSearchComponent />
            </DirectSearchModuleStateProvider>
        </Suspense>
    )
}
