import { ajax, getStoredAuthorization, Required } from "@tm/utils"

import { getServiceUrl } from "."
import { Customers, Parts, RepairTimes, Vehicles, Vouchers } from "../gateway"

export type ImportVoucherHistoryRequest = {
    workTaskId: string
    parts: Array<Parts.Part>
}

export function importVoucherHistory(request: ImportVoucherHistoryRequest): Promise<void> {
    return ajax({
        method: "POST",
        url: `${getServiceUrl()}/connect/ImportVoucherHistory`,
        authorization: getStoredAuthorization(),
        body: request,
    })
}

export type ImportVoucherRequest = Required<Vouchers.Voucher, "workTaskId"> & {
    controlIndicators?: Array<Indicator>
}

type Indicator = {
    type: number
    parameters: Array<IndicatorParameter>
}

type IndicatorParameter = {
    id: string
    value: string
}

export type ImportVoucherResponse = {
    portalUrl: string
}

export function importVoucher(request: ImportVoucherRequest) {
    return ajax<ImportVoucherResponse>({
        method: "POST",
        url: `${getServiceUrl()}/connect/ImportVoucher`,
        authorization: getStoredAuthorization(),
        body: request,
    })
}

export type ExportVoucherRequest = {
    workTaskId: string
    referenceId?: string
}

export type ExportVoucherResponse = Required<Vouchers.Voucher, "workTaskId">

export function exportVoucher(request: ExportVoucherRequest) {
    return ajax<ExportVoucherResponse>({
        method: "POST",
        url: `${getServiceUrl()}/connect/ExportVoucher`,
        authorization: getStoredAuthorization(),
        body: request,
    })
}

export type ExtendVoucherRequest = {
    workTaskId: string
    customer?: Customers.Customer
    vehicle?: Vehicles.Vehicle
    parts: Array<Parts.Part>
    repairTimes: Array<RepairTimes.RepairTime>
}

export type ExtendVoucherResponse = {
    portalUrl: string
}

export function extendVoucher(request: ExtendVoucherRequest) {
    return ajax<ExtendVoucherResponse>({
        method: "POST",
        url: `${getServiceUrl()}/connect/ExtendVoucher`,
        authorization: getStoredAuthorization(),
        body: request,
    })
}
