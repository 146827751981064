import { useStyle, useWorkTask } from "@tm/context-distribution"
import { Button, ButtonProps, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId } from "@tm/utils"
import { em } from "csx"
import * as React from "react"
import { PdfDocumentReference } from "../../../data/model"

type Props = {
    document: PdfDocumentReference
    openDocumentsAsModal?: boolean
}

const DocumentComponent: React.FC<Props> = ({ document, openDocumentsAsModal }) => {
    const { translate } = useLocalization()
    const { workTaskId } = useWorkTask() || {}

    const handleDocumentClick = React.useCallback(() => {
        if (workTaskId) {
            Morpheus.showView("1", `/${encodeUniqueId(workTaskId)}^/modal/external/?url=${document.documentUrl}`)
        }
    }, [workTaskId, document.documentUrl])

    if (document.documentType == 97) {
        return (
            <Tooltip content={<iframe src={document.documentUrl} />} event="click" position="bottom" style="primary" resizable>
                <Button className={style.docButtons}>{document.description || translate(891)}</Button>
            </Tooltip>
        )
    }

    const buttonProps: ButtonProps = {}
    if (openDocumentsAsModal) {
        buttonProps.onClick = handleDocumentClick
    } else {
        buttonProps.linkTo = document.documentUrl
        buttonProps.target = "_blank"
    }

    if (document.documentType === 4) {
        buttonProps.icon = "external-link"
    }

    return (
        <Button className={style.docButtons} {...buttonProps}>
            {document.description || "PDF"}
        </Button>
    )
}

export default DocumentComponent

const style = useStyle({
    docButtons: {
        marginBottom: em(0.2),
        marginLeft: em(0.2),
    },
})(DocumentComponent)
