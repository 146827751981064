
import { Availability, ErpInformationRequestItem } from "@tm/models"
import * as htmlToImage from "html-to-image"
import { getBundleParams } from "../../../utils"
import { FilterCriterias } from "../../../data/enums"
import { ImagePathsRequest } from "../../../data/repositories/wheels-load2DImages/model"
import { _2DRimSizesRequest } from "../../../data/repositories/wheels-load2DRimSizes/model"
import { RimFiltersRequest } from "../../../data/repositories/wheels-loadRimFIlters/model"
import { RimItemsRequest } from "../../../data/repositories/wheels-loadRimItems/model"
import { SelectedFilters } from "./model"
import { RimErpInfo } from "../../../data/model"

export function createRimSizesRequest(carparkId: number, isNewImage?: boolean, size?: string): _2DRimSizesRequest {
    return {
        carparkId,
        diameterTyre: size || "",
        isNewImage: isNewImage || false
    }
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}



export function createRimArticlesRequest(carparkId: number, selectedFilters: SelectedFilters, isAdditionalManufacturerSelected: boolean, pageIndex?: number): RimItemsRequest {
    return {
        carparkId,
        rimfilter: mapSelectedFiltersForRequest(selectedFilters),
        pageIndex: pageIndex ?? 1,
        pageSize: getBundleParams().wheelsPagingSize,
        isAdditionalManufacturerSelected
    }
}

// TODO too much hardcoded stuff
export function mapSelectedFiltersForRequest(selectedFilters: Partial<SelectedFilters>) {
    let request = ''

    if (selectedFilters["-100"]?.length)
        selectedFilters["-100"].split(";").forEach(item => {
            request += FilterCriterias.rimSupplier + "," + item + ";"
        })

    if (selectedFilters[74])
        request += FilterCriterias.rimWidth + "," + selectedFilters[74] + ";"

    if (selectedFilters[73])
        request += FilterCriterias.rimOffset + "," + selectedFilters[73] + ";"

    if (selectedFilters[2217])
        request += FilterCriterias.rimDiameter + "," + selectedFilters[2217] + ";"

    if (selectedFilters["-104"])
        selectedFilters["-104"].split(";").forEach(item => {
            request += FilterCriterias.rimColor + "," + item + ";"
        })
    if (selectedFilters["-105"])
        selectedFilters["-105"].split(";").forEach(item => {
            request += FilterCriterias.rimSort + "," + item + ";"
        })
    if (selectedFilters["9184"])
        request += FilterCriterias.rimAttribute1 + "," + selectedFilters["9184"] + ";"

    if (selectedFilters["-102"])
        request += FilterCriterias.rimAttribute2 + "," + selectedFilters["-102"] + ";"

    if (selectedFilters["-103"])
        request += FilterCriterias.rimAttribute3 + "," + selectedFilters["-103"] + ";"

    if (selectedFilters["-106"])
        request += FilterCriterias.rimAttribute4 + "," + selectedFilters["-106"] + ";"

    if (selectedFilters["-111"])
        request += FilterCriterias.OE + "," + selectedFilters["-111"] + ";"

    if (selectedFilters["-112"])
        request += FilterCriterias.VSA + "," + selectedFilters["-112"] + ";"

    return request
}

export function addOrRemoveFilter(value: string, selectedFilter?: string): string {
    //Add value to string if does'n exist or remove it if exist
    if (!value)
        return ""

    let items = selectedFilter ? selectedFilter.split(";") : []
    if (selectedFilter && selectedFilter.includes(value)) {
        items = items.filter((item) => item !== value)
    }
    else
        items.push(value)
    return items.join(";")
}



export function createImagePathsRequest(colorCode: string, imageId: string): ImagePathsRequest {
    return {
        colorCode,
        idImage: parseInt(imageId) || 0,
        degree: 90
    }
}

export function createRimFiltersRequest(carparkId: number, isAdditionalManufacturerSelected: boolean): RimFiltersRequest {
    return {
        carparkId,
        isAdditionalManufacturerSelected
    }
}

export function generateConfiguratorImage(has2DConfig: boolean, has3DConfig: boolean, show3d: boolean, rimPositions?: any) {
    return new Promise<string | undefined>((resolve, reject) => {
        if (!!(document as any).documentMode) //IF IE
            resolve(undefined)

        if (has2DConfig && !show3d) {
            // let element = document.getElementById('2D-configurator') as HTMLDivElement
            let element = document.getElementById('2d-configurator-container') as HTMLDivElement


            if (element) {
                const invScale = rimPositions.naturalHeight / element.clientHeight * 1.74 
                const options = {
                    width: element.clientWidth * invScale,
                    height: element.clientHeight * invScale,
                }

                htmlToImage.toCanvas(element, options).then((canvas: HTMLCanvasElement) => {
                    canvas = trimCanvas(canvas)
                    canvas.toBlob((blob: Blob | null) => {
                        if (blob) {
                            var reader = new FileReader()
                            reader.readAsDataURL(blob)
                            reader.onloadend = () => resolve(reader.result as string)
                            reader.onerror = () => reject()
                        }
                        else {
                            reject()
                        }
                    })

                }).catch(() => reject())
            }
        }
        else if (has3DConfig && show3d) {
            let wrapper = document.getElementById('viewer') as HTMLDivElement
            let element = document.getElementById('ConfigurationRendererViewer-viewer') as HTMLCanvasElement
            wrapper.setAttribute("angle", "0")
            // PreyssConfigurator.changeRotation(0)
            sleep(300).then(() => {
                if (element) {
                    try {
                        element = trimCanvas(element)
                        element.toBlob((blob: Blob | null) => {
                            const context = element.getContext("2d")
                            context && context.clearRect(0, 0, element.width, element.height)
                            if (blob) {
                                var reader = new FileReader()
                                reader.readAsDataURL(blob)
                                reader.onloadend = () => resolve(reader.result as string)
                                reader.onerror = () => reject()
                            } else {
                                reject()
                            }
                        })
                    } catch (e) {
                        reject()
                    }
                }
            })
        } else {
            resolve(undefined)
        }
    })
}

export function trimCanvas(c: HTMLCanvasElement) {
    let ctx = c.getContext('2d')
    let copy = document.createElement('canvas').getContext('2d')
    if (!ctx || !copy) return c

    let pixels = ctx.getImageData(0, 0, c.width, c.height)
    let l = pixels.data.length
    let i
    let bound: any = {
        top: null,
        left: null,
        right: null,
        bottom: null
    }
    let x, y

    for (i = 0; i < l; i += 4) {
        if (pixels.data[i + 3] !== 0) {
            x = (i / 4) % c.width
            y = ~~((i / 4) / c.width)

            if (bound.top === null) {
                bound.top = y
            }

            if (bound.left === null) {
                bound.left = x
            } else if (x < bound.left) {
                bound.left = x
            }

            if (bound.right === null) {
                bound.right = x
            } else if (bound.right < x) {
                bound.right = x
            }

            if (bound.bottom === null) {
                bound.bottom = y
            } else if (bound.bottom < y) {
                bound.bottom = y
            }
        }
    }
    var trimHeight = bound.bottom - bound.top,
        trimWidth = bound.right - bound.left,
        trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight)

    copy.canvas.width = trimWidth
    copy.canvas.height = trimHeight
    copy.putImageData(trimmed, 0, 0)

    // Return trimmed canvas
    return copy.canvas
}

export function createErpInformationRequestItem(info: RimErpInfo, showIndustrialAvailability?: boolean): ErpInformationRequestItem {
    return {
        itemId: info.itemId,
        articleDescription: info.description,
        wholesalerArticleNumber: info.wholesalerArticleNumber,
        dataSupplierArticleNumber: info?.dataSupplierArticleNumber,
        quantityValue: 4,
        ...(info.stock > -1 && showIndustrialAvailability) && {
            productGroupId: info.productGroupId,
            dataSupplierId: info.supplierId,
            dataSupplierName: info.supplierName
        }
    }
}