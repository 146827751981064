import { Article } from "@tm/models"
import { ClientType, Stocks } from "../../business/gateway"

export function mapArticleToLookupItem(part: Article): Stocks.LookupItem {
    return {
        id: part.id,
        referenceId: "",

        wholesalerArticleId: part.traderArticleNo,
        dataSupplierArticleId: part.supplierArticleNo,

        description: "",

        dataSupplier: {
            id: part.supplier.id,
            clientId: ClientType.TecDoc,
            description: part.supplier.name,
        },
        productGroup: {
            id: part.productGroup.id,
            clientId: ClientType.TecDoc,
            description: part.productGroup.name,
        },

        quantity: part.quantity,
    }
}
