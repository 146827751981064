import { useMemo, useState } from "react"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Button, Icon, LicensePlateField, Text, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { isSameDay } from "@tm/utils"
import { openWorkTask } from "../../../../../bundle-channel"
import { MergedVehicle } from "../../../../../data/hooks"
import { getBundleParams } from "../../../../../utils"
import VehicleData from "../../../../_shared/vehicle-data"
import WideButton from "../wide-button"
import VehicleAppointmentInfo, { getDateFromMetadata } from "./appointment-info"
import VehicleCostEstimateInfo from "./cost-estimate-info"
import VehicleFastcheckInfo from "./fastcheck-info"
import VehicleInvoiceInfo from "./invoice-info"
import { getComponentStyles } from "./styles"
import VehicleTelematics from "./telematics"

type Props = {
    vehicle: MergedVehicle
}

export default function VehicleInfo({ vehicle }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const [compact, setCompact] = useState(false)
    const { translateText, languageId } = useLocalization()
    const { plateCode: shortCountryCode } = useCountryCodeToLicensePlate(vehicle?.vehicleInfo.countryCode)

    const statusInfo = getStatusInfo(vehicle, languageId)

    return (
        <div className={classNames.main}>
            <div className={classNames.header}>
                <div className={classNames.headerIcon}>
                    <Icon name="vehicle-front" />
                </div>
                <Tooltip content={vehicle.vehicleInfo.displayName}>
                    <Text modifiers={["strong"]} className={classNames.headerText}>
                        {vehicle.vehicleInfo.displayName}
                    </Text>
                </Tooltip>
                <Button icon={compact ? "down" : "up"} layout={["ghost"]} onClick={() => setCompact(!compact)} />
            </div>

            <LicensePlateField
                size="s"
                readonly
                shortCountryCode={shortCountryCode}
                value={vehicle.vehicleInfo.plateId}
                className={classNames.licensePlateField}
            />

            {!compact && (
                <div className={classNames.content}>
                    <VehicleData vehicleInfo={vehicle.vehicleInfo} hideLicensePlate />

                    <Button
                        size="s"
                        onClick={() =>
                            openWorkTask({ selectedChatVehicle: vehicle, subRoute: getBundleParams().vehicleDetailsRouteWithoutWorkTaskId })
                        }
                    >
                        {translateText(43)}
                    </Button>

                    {statusInfo ? (
                        <div className={classNames.status}>
                            <Text size="s" modifiers={["block", "sub"]}>
                                STATUS
                            </Text>
                            <div className={classNames.statusContent}>
                                {statusInfo.icon && <Icon name={statusInfo.icon} className={classNames.statusIcon} />}
                                <Text modifiers="block">{translateText(statusInfo.textId)}</Text>
                            </div>
                        </div>
                    ) : (
                        <div className={classNames.separator} />
                    )}

                    <WideButton text={translateText(3081)} onClick={() => openWorkTask({ selectedChatVehicle: vehicle })} />

                    <VehicleAppointmentInfo appointment={vehicle.appointment} />
                    <VehicleTelematics vehicle={vehicle} />
                    <VehicleFastcheckInfo vehicle={vehicle} />
                    <VehicleCostEstimateInfo vehicle={vehicle} />
                    <VehicleInvoiceInfo vehicle={vehicle} />
                </div>
            )}
        </div>
    )
}

function getStatusInfo(vehicle: MergedVehicle, languageId: string) {
    const appointmentDate = vehicle.appointment && new Date(getDateFromMetadata(vehicle.appointment))

    switch (vehicle.lastStatusRelevantAction) {
        case "fastCheck":
            const fastCheckDate = vehicle.fastCheck && new Date(vehicle.fastCheck.inserted)
            if (fastCheckDate && appointmentDate && isSameDay(fastCheckDate, appointmentDate)) {
                if (new Date().getTime() - fastCheckDate.getTime() > 10 * 60 * 1000) {
                    // Fahrzeug in Prüfung: 10 Minuten nach dem FastCheck
                    return { textId: 12723, icon: "vehicle-check" }
                }

                // Fahrzeug angenommen: Am Tage der Reparatur nach dem versenden des FastChecks
                return { textId: 12726, icon: "vehicle-accepted" }
            }
            break
        case "invoice":
            const invoiceDate = vehicle.invoice && new Date(vehicle.invoice.inserted)
            if (invoiceDate && appointmentDate && isSameDay(invoiceDate, appointmentDate)) {
                // Fahrzeug abholbereit: Wenn die Rechnung am selben Tag versendet wurde
                return { textId: 12722, icon: "vehicle-ready" }
            }
            break
        case "costEstimateSent":
            return { textId: 12724, icon: languageId === "1" ? "voucher-kva" : "voucher-kva-international" }
        case "costEstimateConfirmation":
            return { textId: 12725, icon: languageId === "1" ? "voucher-kva-confirm" : "voucher-kva-confirm-international" }
        case "costEstimateRejection":
            return { textId: 12727, icon: languageId === "1" ? "voucher-kva-rejected" : "voucher-kva-rejected-international" }
        default:
            break
    }
}
