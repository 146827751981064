import TextLink from "./text-link"

export type TriProp = {
    name: string
    className: string
    to: string
    closeMenu: () => void
}

export default function TriangledLabel(props: TriProp) {
    return (
        <TextLink to={props.to} closeMenu={props.closeMenu} className={props.className}>
            <div className="triangle__text--symbol">&#9654;</div>
            <div className="triangle__text">{props.name}</div>
        </TextLink>
    )
}
