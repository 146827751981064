import { Box, Loader, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { CustomArticle, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import MuiCustomModal from "./MuiCustomModal"
import { useGetCustomArticlesCategory } from "../../../helpers/hooks/useGetCustomArticlesCategory"
import { useGetCustomArticles } from "../../../helpers/hooks/useCustomArticles"

type Props = {
    open: boolean
    onClose(open: boolean): void
    genArtNr: number
    replaceConsumable: (consumable: CustomArticle) => void
}

export default function ReplaceConsumable({ open, onClose, genArtNr, replaceConsumable }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const [selectedConsumable, setSelectedConsumable] = useState<CustomArticle | undefined>(undefined)

    const { categoryTextId: genartTextId, isError: customCategoryError } = useGetCustomArticlesCategory(genArtNr, true)
    const { data: customArticles, isError, refetch: refetchCustomArticles } = useGetCustomArticles(genartTextId, !!genartTextId)

    const handleClose = () => {
        onClose(false)
    }

    function onConsumableSelect(selected: CustomArticle) {
        setSelectedConsumable(selected)
    }

    function onConsumableSaved() {
        refetchCustomArticles()
    }

    function onConsumableConfirm() {
        if (selectedConsumable) {
            replaceConsumable(selectedConsumable)
            handleClose()
        }
    }

    let content = (
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyItems="center">
            <Loader />
        </Box>
    )

    if (isError || customCategoryError) {
        content = <Typography>{translateText(12775)}</Typography>
    }

    if (customArticles) {
        content = (
            <>
                {renderMicro("parts", "consumable-replace", {
                    title: translateText(616),
                    consumables: customArticles,
                    onConsumableSelect,
                    onConsumableSaved,
                })}
            </>
        )
    }

    if (open) {
        return (
            <MuiCustomModal open onOutsideClick={handleClose} handleOkButton={onConsumableConfirm}>
                {content}
            </MuiCustomModal>
        )
    }

    return null
}
