import { AdditionalModelIdentifier, RegistrationNoType, Vehicle, VehicleLookupConfig, VehicleType } from "@tm/models"
import { useState, useEffect, useMemo } from "react"

import { isLicensePlateType, isVinType } from "@tm/utils"
import { OrderedPartByVehicle } from "../../../data/model/vouchers"
import { getServiceBook } from "../../../data/repositories/service-book"
import { showOrderedPartsByVehicle } from "../../../data/repositories/vouchers"
import { VehicleParams } from "../../search/component"
import { useSelectedVehicleLookup } from "../../../data/hooks/useSelectedLookupConfig"

export function useServiceBookUrl(tecDocManufacturerId?: number) {
    const [serviceBookUrl, setServiceBookUrl] = useState<string>()

    useEffect(() => {
        if (!tecDocManufacturerId) {
            setServiceBookUrl(undefined)
        } else {
            getServiceBook(tecDocManufacturerId).then(setServiceBookUrl)
        }
    }, [tecDocManufacturerId])

    return serviceBookUrl
}

export function useOrderedPartsByVehicle(vehicleId?: string) {
    const [orderedParts, setOrderedParts] = useState<Array<OrderedPartByVehicle>>()
    useEffect(() => {
        if (orderedParts) {
            setOrderedParts(undefined)
        }

        if (!vehicleId) {
            return
        }

        let cancelled = false
        showOrderedPartsByVehicle(vehicleId).then((response) => {
            if (cancelled) {
                return
            }

            if (response && response.orderedParts && response.orderedParts.length) {
                setOrderedParts(response.orderedParts)
            }
        })

        return () => {
            cancelled = true
        }
    }, [])

    return orderedParts
}

type RegNoAndType = {
    regNoType?: RegistrationNoType
    regNo?: string
}

function getRegNoAndTypeFromQuery(query: string): RegNoAndType {
    const searchParams = new URLSearchParams(query)
    const regNo = searchParams.get(VehicleParams.RegNo) || undefined
    const regNoTypeSearch = searchParams.get(VehicleParams.RegNoType) || undefined

    if (regNoTypeSearch !== undefined && /\d+/.test(regNoTypeSearch)) {
        return {
            regNoType: parseInt(regNoTypeSearch),
            regNo,
        }
    }

    return { regNo }
}

export function useRegNoAndType(
    vehicleType: VehicleType,
    vehicle: Vehicle | undefined,
    query: string,
    defaultLookup?: VehicleLookupConfig
): RegNoAndType {
    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicle?.vehicleType ?? vehicleType)

    return useMemo(() => {
        const queryParams = getRegNoAndTypeFromQuery(query)

        let regNo = vehicle?.registrationNo
        let regNoType = vehicle?.registrationTypeId

        // If the vehicle has a registration number but no type treat it as KBA number
        if (regNo && regNoType === undefined) {
            regNoType = RegistrationNoType.Kba
        }

        // Check the url query parameter
        if (regNoType === undefined && queryParams.regNoType) {
            regNoType = queryParams.regNoType
        }

        if (regNoType === undefined && selectedVehicleLookup.countryCode !== undefined && selectedVehicleLookup.lookupTypeId !== undefined) {
            regNoType = selectedVehicleLookup.lookupTypeId
        }

        // When the vehicle wasn't found with a specific vehicle lookup search
        // use the default configured vehicle search
        if (regNoType === undefined && defaultLookup) {
            regNoType = defaultLookup.lookupTypeId
        }

        if (isVinType(regNoType, regNo)) {
            regNo = vehicle?.vin
        } else if (isLicensePlateType(regNoType, regNo)) {
            regNo = vehicle?.plateId
        }

        if (!regNo && queryParams.regNo) {
            regNo = queryParams.regNo
        }

        return { regNoType, regNo }
    }, [vehicle, query, defaultLookup, selectedVehicleLookup])
}

export function useAdditionalModelIdentifier(query: string): AdditionalModelIdentifier {
    return useMemo(() => {
        const searchParams = new URLSearchParams(query)
        const plateId = searchParams.get(VehicleParams.PlateId) || undefined
        const vin = searchParams.get(VehicleParams.Vin) || undefined

        let initialRegistration: Date | undefined
        const initialRegistrationString = searchParams.get(VehicleParams.InitialRegistration)

        if (initialRegistrationString) {
            initialRegistration = new Date(initialRegistrationString)

            if (Number.isNaN(initialRegistration.getTime())) {
                initialRegistration = undefined
            }
        }

        return { plateId, vin, initialRegistration }
    }, [query])
}
