import { Suspense, useEffect, useMemo } from "react"
import { Box, Loader } from "@tm/components"
import { OePartsList } from "./OePartsList"
import { OePartsModuleStateProvider, useOePartsModuleState } from "./OePartsModuleState"
import { ArticleListConfigurationProvider } from "../ArticleListConfiguration"

function OePartsComponent() {
    const start = useOePartsModuleState((x) => x.start)
    const startParams = useOePartsModuleState((x) => x.params.startParams)
    const oePositions = useOePartsModuleState((x) => x.params.oePositions)
    const replaceButtonBundle = useOePartsModuleState((x) => x.params.startParams.replaceButtonBundle)

    const hasMissingParams = useMemo(() => {
        if (replaceButtonBundle) {
            return false
        }

        switch (startParams.type) {
            case "oe":
                return !oePositions.length
            default:
                return true
        }
    }, [startParams, oePositions, replaceButtonBundle])

    useEffect(start, [start])

    return (
        <Box display="grid" overflow="hidden" margin={1}>
            {!hasMissingParams && <OePartsList />}
        </Box>
    )
}

export function OeParts() {
    return (
        <Suspense
            fallback={
                <Box alignContent="center">
                    <Loader />
                </Box>
            }
        >
            <ArticleListConfigurationProvider>
                <OePartsModuleStateProvider>
                    <OePartsComponent />
                </OePartsModuleStateProvider>
            </ArticleListConfigurationProvider>
        </Suspense>
    )
}
