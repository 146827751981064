import * as React from "react"
import { MuiTableCell, makeStyles, withStyles } from "@tm/components"
import { Tooltip } from "@tm/controls"

const OverflowAtPx = 310

const StyledTableCell = withStyles(() => ({
    head: {
        fontSize: 11,
        color: "black",
        padding: "5px 4px 5px 6px",
        border: "none",
        whiteSpace: "nowrap",
        fontFamily: "'Open Sans'",
    },
    body: {
        color: "black",
        whiteSpace: "nowrap",
        padding: "8px 4px 8px 6px",
        fontFamily: "'Open Sans'",
        fontSize: 12,
    },
}))(MuiTableCell)

const useStyle = makeStyles({
    tooltip: {
        maxWidth: `${OverflowAtPx}px`,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
})

export function OverflowTooltipTableCell(props: { children: React.ReactNode; tooltipContent?: string }) {
    const styles = useStyle()
    return (
        <StyledTableCell>
            <Tooltip content={props.tooltipContent} style="light">
                <div className={styles.tooltip}>{props.children}</div>
            </Tooltip>
        </StyledTableCell>
    )
}

export function StyledCell(props: { children: React.ReactNode }) {
    return <StyledTableCell>{props.children}</StyledTableCell>
}
