import { OrderOptions, SendOrderOptions } from "@tm/models"
import { OrderOptionGroup, SelectedOrderOptions } from "../model"
import { StoredOrderOptions } from "../hooks/useOrderOptions"
import { OrderOptionsAtomType, findCurrentActiveOption } from "../../hooks/useAdditionalOrderInformation"

export function mapOrderOptionsToSelectedOrderOptions(options: OrderOptions, workTaskId?: string): SelectedOrderOptions {
    const billingMode = options.billingModes?.billingModes.find((mode) => mode.isSelected)
    const deliveryDistributionMode = options.deliveryDistribution?.deliveryDistributionModes.find(
        (deliveryDistribution) => deliveryDistribution.isSelected
    )
    const paymentOption = options.paymentOptions?.paymentOptions.find((payment) => payment.isSelected)
    const shipmentMode = options.shipmentModes?.shipmentModes.find((mode) => mode.isSelected)
    const tour = options.tours?.tours.find((tour) => tour.isSelected)
    const orderProcessingMode = options.orderProcessingOptions?.orderProcessingOptions.find((orderProcessing) => orderProcessing.isSelected)
    const backorderOption = options.backorderOptions?.backorderOptions.find((backorder) => backorder.isSelected)
    const billingAddress = options.billingAddresses?.addresses.find((address) => address.isSelected)
    const deliveryAddress = options.deliveryAddresses?.addresses.find((address) => address.isSelected)

    return {
        workTaskId,
        warehouseId: options.warehouseId,
        orderComment: options.orderComment,
        submitOrderItemComment: options.hasItemComment,
        orderCommentMaxLength: options.orderCommentMaxLength,
        customerOrderNumber: options.customerOrderNumber,
        hasCustomerOrderNumber: options.hasCustomerOrderNumber,
        customerOrderNumberMaxLength: options.customerOrderNumberMaxLength,
        hasOrderComment: options.hasOrderComment,
        hasItemComment: options.hasItemComment,
        itemCommentMaxLength: options.itemCommentMaxLength,
        selectedBillingAddress: billingAddress,
        selectedBillingOption: billingMode,
        selectedDeliveryAddress: deliveryAddress,
        selectedDeliveryDistributionMode: deliveryDistributionMode,
        selectedPaymentOption: paymentOption,
        selectedShipmentMode: shipmentMode,
        selectedTour: tour,
        selectedBackorderOption: backorderOption,
        deliveryDate: options.deliveryDate,
        wholesalerOrderConfirmation: false,
        selectedOrderProcessingOption: orderProcessingMode,
    }
}

export function mapOrderOptionsToSendOrderOptions(
    options: OrderOptions,
    wholesalerOrderConfirmation?: boolean,
    orderConfirmationEmail?: string
): SendOrderOptions {
    const billingMode = options.billingModes?.billingModes.find((mode) => mode.isSelected)
    const deliveryDistributionMode = options.deliveryDistribution?.deliveryDistributionModes.find(
        (deliveryDistribution) => deliveryDistribution.isSelected
    )
    const paymentOption = options.paymentOptions?.paymentOptions.find((payment) => payment.isSelected)
    const shipmentMode = options.shipmentModes?.shipmentModes.find((mode) => mode.isSelected)
    const tour = options.tours?.tours.find((tour) => tour.isSelected)
    const orderProcessingOption = options.orderProcessingOptions?.orderProcessingOptions.find((orderProcessing) => orderProcessing.isSelected)
    const backorderOption = options.backorderOptions?.backorderOptions.find((backorder) => backorder.isSelected)
    const billingAddress = options.billingAddresses?.addresses.find((address) => address.isSelected)
    const deliveryAddress = options.deliveryAddresses?.addresses.find((address) => address.isSelected)

    return {
        warehouseId: options.warehouseId,
        billingAddress,
        deliveryAddress,
        billingMode,
        paymentOption,
        shipmentMode,
        deliveryDistributionMode,
        tour,
        orderProcessingOption,
        backorderOption,
        deliveryDate: options.deliveryDate,
        customerOrderNumber: options.customerOrderNumber,
        orderComment: options.orderComment,
        hasCustomerOrderNumber: options.hasCustomerOrderNumber,
        customerOrderNumberMaxLength: options.customerOrderNumberMaxLength,
        hasOrderComment: options.hasOrderComment,
        orderCommentMaxLength: options.orderCommentMaxLength,
        hasItemComment: options.hasItemComment,
        itemCommentMaxLength: options.itemCommentMaxLength,
        wholesalerOrderConfirmation: options ? wholesalerOrderConfirmation : undefined,
        orderConfirmationEmail,
    }
}

function mergeCustomOrderOptions(storedOrderOptions: StoredOrderOptions, workTaskId: string, additionalOrderOptions: OrderOptionsAtomType[]) {
    const { distributorId } = storedOrderOptions
    const orderOptions = storedOrderOptions.orderOptions as OrderOptions

    const customOrderInformation = additionalOrderOptions.find(
        findCurrentActiveOption(workTaskId, {
            distributorId,
            warehouseId: orderOptions.warehouseId,
        })
    )

    return {
        ...orderOptions,
        customerOrderNumber: customOrderInformation?.customerOrderNumber || orderOptions.customerOrderNumber,
        hasOrderNumber: !!(customOrderInformation?.customerOrderNumber || orderOptions.customerOrderNumber),
        orderComment: customOrderInformation?.customerOrderComment || orderOptions.orderComment,
        hasOrderComment: !!(customOrderInformation?.customerOrderComment || orderOptions.orderComment),
    }
}

export function mapStoredOrderOptions(
    storedOrderOptions: StoredOrderOptions[],
    workTaskId: string,
    additionalOrderOptions: OrderOptionsAtomType[]
): OrderOptionGroup[] {
    const groups = storedOrderOptions.groupBy((o) => o.distributorId || "")

    return Object.values(groups).map((group) => {
        const generalOrderOptionsGroup = group.find((o) => !(o.orderOptions as OrderOptions).warehouseId)

        return {
            distributorId: group[0].distributorId,
            orderOptions:
                generalOrderOptionsGroup?.orderOptions &&
                mapOrderOptionsToSelectedOrderOptions(
                    mergeCustomOrderOptions(generalOrderOptionsGroup, workTaskId, additionalOrderOptions),
                    workTaskId
                ),
            warehouseOrderOptions: !generalOrderOptionsGroup?.orderOptions
                ? group.map((o) => mapOrderOptionsToSelectedOrderOptions(mergeCustomOrderOptions(o, workTaskId, additionalOrderOptions), workTaskId))
                : undefined,
        }
    })
}
