import { AsyncAction } from "@tm/morpheus"
import { Article, HasRepairTimesRequest, HasRepairTimesResponse, RegisteredModels, CategoryType, Vehicle } from "@tm/models"
import { Container } from "@tm/nexus"
import { getRepairTimeProvidersByNames } from "@tm/utils"
import { Models, Repositories } from "../../../data"
import { DetailsHeadState } from "./model"
import { ComponentActionType } from "."
import { getBundleParams } from "../../../utils"
import { BundleActions } from "../../../business"

function setDetailsUrl(categoryType: CategoryType, url: string): ComponentActionType {
    return { type: "SET_SECTION_URL", payload: { categoryType, sectionType: "details", url } }
}

function resetDetailsHead(): ComponentActionType {
    return { type: "DETAILSHEAD_RESET" }
}

type LoadDetailsRequest = Omit<Models.DetailsRequest, "vehicleType" | "modelId"> & {
    vehicle?: Vehicle
    foundBySearchTerm?: string
    initialQuantity?: number
    overwriteInitialQuantity: boolean
    disableAddToBasket: boolean
}

function loadDetailsHead(request: LoadDetailsRequest): AsyncAction<ComponentActionType, DetailsHeadState> {
    return (dispatch) => {
        dispatch(resetDetailsHead())

        const detailsRequest: Models.DetailsRequest = {
            productGroupId: request.productGroupId,
            supplierId: request.supplierId,
            supplierArticleNo: request.supplierArticleNo,
            vehicleLinkageId: request.vehicleLinkageId,
            vehicleType: request.vehicle?.vehicleType,
            modelId: request.vehicle?.tecDocTypeId,
        }

        Repositories.getArticleDetails(detailsRequest).then(
            (response) => {
                if (request.initialQuantity && response.article) {
                    response.article.quantity = request.initialQuantity
                    if (request.overwriteInitialQuantity) {
                        response.article.initialQuantity = request.initialQuantity
                    }
                }

                if (response.article && request.disableAddToBasket) {
                    response.article.showAddToBasket = false
                }

                dispatch({ type: "DETAILSHEAD_LOADED", payload: response })
                dispatch(showArticleLists(response, detailsRequest, request.foundBySearchTerm))

                if (response.article && request.vehicle) {
                    dispatch(loadProductGroupRepairTimes(response.article, request.vehicle))
                }
            },
            () => dispatch(resetDetailsHead())
        )
    }
}

function loadProductGroupRepairTimes(article: Article, vehicle: Vehicle): AsyncAction<ComponentActionType, DetailsHeadState> {
    return (dispatch) => {
        const productGroupId: number | undefined = article.productGroup?.id

        if (!productGroupId) {
            return
        }

        const rtProviders = getBundleParams().awProviders.map((x) => x.id)
        const providers = getRepairTimeProvidersByNames(rtProviders)

        if (!providers.length) {
            return
        }

        const request: HasRepairTimesRequest = {
            vehicleType: vehicle.vehicleType,
            modelId: vehicle.tecDocTypeId,
            repairTimeProvider: providers,
            productGroupIds: [productGroupId],
        }

        Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes)
            .subscribe(request)
            .load()
            .then((response) => {
                if (response?.productGroups[productGroupId]) {
                    dispatch({ type: "SET_PRODUCTGROUP_REPAIRTIMES", payload: response.productGroups[productGroupId] })
                }
            })
    }
}

function showArticleLists(
    response: Models.ArticleDetailsResponse,
    request: Models.DetailsRequest,
    foundBySearchTerm?: string
): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        if (!response?.article) {
            return
        }

        if (response.article.isPartsListAvailable === true) {
            dispatch({
                type: "LOAD_ARTICLE_LIST",
                payload: {
                    partsList: {
                        articleId: response.article.internalId,
                        modelId: request.modelId,
                        vehicleType: request.vehicleType,
                        vehicleLinkageId: request.vehicleLinkageId,
                    },
                    foundBySearchTerm,
                    dontResetStateOnUnload: true,
                },
                targetComponentId: "details-partslist",
            })
        }

        if (response.article.isAccessoryListAvailable === true) {
            dispatch({
                type: "LOAD_ARTICLE_LIST",
                payload: {
                    accessoryList: {
                        articleId: response.article.internalId,
                        modelId: request.modelId,
                        vehicleType: request.vehicleType,
                    },
                    foundBySearchTerm,
                    dontResetStateOnUnload: true,
                },
                targetComponentId: "details-accessories",
            })
        }

        if (response.partsListMainArticles && response.partsListMainArticles.length) {
            dispatch({
                type: "LOAD_ARTICLE_LIST",
                payload: {
                    articles: response.partsListMainArticles,
                    foundBySearchTerm,
                    dontResetStateOnUnload: true,
                },
                targetComponentId: "details-mainpartslist",
            })
        }

        if (response.accessoryListMainArticles && response.accessoryListMainArticles.length) {
            dispatch({
                type: "LOAD_ARTICLE_LIST",
                payload: {
                    articles: response.accessoryListMainArticles,
                    foundBySearchTerm,
                    dontResetStateOnUnload: true,
                },
                targetComponentId: "details-mainaccessories",
            })
        }
    }
}

function changeQuantity(article: Article, quantity: number): ComponentActionType {
    return { type: "CHANGE_ARTICLE_QUANTITY", payload: { article, quantity } }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    setDetailsUrl,
    resetDetailsHead,
    loadDetailsHead,
    changeQuantity,
}
