import { FC, ReactNode, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { useStyle } from "@tm/context-distribution"
import { Button, Toolbar, Grid, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { EFilterNames } from "@tm/models"
import { classes } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { FilterType } from "../../../data/enums"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { getIconByGroup, getTitleByGroup } from "../../../data/helpers"
import { CustomRangeSlider, Filter, SelectionCheckbox, SelectionItems } from "../../_shared"
import { AvailabilityFilterType, IListFilters, IListMultiFilters } from "../business/model"

type Props = {
	className?: string
	inModal?: boolean
}

type CollapsibleFilters = IListFilters | "availability"

type State = {
	filtersState: {
		[key in CollapsibleFilters]?: {
			searchValue?: string
			opened?: boolean
			searchEnabled?: boolean
		}
	}
}

const DEFAULT_MODAL_STATE: State["filtersState"] = {
	season: { opened: true },
	availability: { opened: true },
	externalRolling: { opened: true },
	fuelEfficiency: { opened: true },
	loadIndex: { opened: true },
	speedIndex: { opened: true },
	manufacturer: { opened: true },
	oeIdentifier: { opened: true },
	extras: { opened: true },
	weight: { opened: true },
	wetGripClass: { opened: true }
}

const AttributeFilters: FC<Props> = ({ inModal, className }) => {
    const { translateText } = useLocalization()

	const dispatch = useDispatch()
	const { filters, selectedFilters, clippedFilters } = useSelector((s: MainState) => ({ filters: s.list.filters, selectedFilters: s.list.selectedFilters, lastSearch: s.list.lastSearch, clippedFilters: s.list.clippedFilters, summaryFilters: s.summary.selectedFilters }))

	const [filtersState, setFiltersState] = useState(inModal && DEFAULT_MODAL_STATE || {
		season: { opened: true },
		availability: { opened: true },
		manufacturer: { opened: true },
		loadIndex: { opened: true },
		speedIndex: { opened: true },
		oeIdentifier: { opened: true },
		extras: { opened: true }
	})

	const handleSeasonChange = (value: any) => {
		batch(() => {
			dispatch(MainActions.updateAttributeFilter(EFilterNames.season, value))
			dispatch(MainActions.updateUsedProdGroups())
			dispatch(MainActions.loadTiresList(undefined, true))
		})
	}

	const handleFilterChange = (path: IListFilters, value: any) => {
		batch(() => {
			dispatch(MainActions.updateAttributeFilter(path, value))
			dispatch(MainActions.loadTiresList(undefined, true))
		})
	}

	const handleSearchTextChange = (path: CollapsibleFilters, value: string) => {
		setFiltersState({
			...filtersState,
			[path]: {
				...filtersState[path],
				searchValue: value
			}
		})
	}

	const handleSearchVisibility = (path: CollapsibleFilters) => {
		setFiltersState({
			...filtersState,
			[path]: {
				...filtersState[path],
				searchEnabled: !filtersState[path]?.searchEnabled,
				opened: true
			}
		})
	}

	const handleCollapsibleChange = (path: CollapsibleFilters) => {
		setFiltersState({
			...filtersState,
			[path]: {
				...filtersState[path],
				opened: !filtersState[path]?.opened
			}
		})
	}

	const handleFilterReset = (path: IListFilters) => {
		handleSearchTextChange(path, "")
		batch(() => {
			dispatch(MainActions.resetAttributeFilter(path))
			dispatch(MainActions.loadTiresList(undefined, true))
		})
	}

	const handleToggleClip = (filterType: FilterType) => {
		dispatch(MainActions.toggleFilterClip(filterType))
	}

	// TODO get rid of FilterType and use EFilterNames
	const getEnumByPath = (path: IListMultiFilters) => {
		switch (path) {
			case EFilterNames.season: return FilterType.Season
			case EFilterNames.loadIndex: return FilterType.LoadIndex
			case EFilterNames.manufacturer: return FilterType.Manufacturer
			case EFilterNames.oeIdentifier: return FilterType.OeIdentifier
			case EFilterNames.extras: return FilterType.Extras
			case EFilterNames.speedIndex: return FilterType.SpeedIndex
			default: return FilterType.None
		}
	}

	const renderSelectionFilter = (path: IListMultiFilters, title: string) => {
		const currentFilter = filters[path]

		if (!currentFilter?.length) {
			return null
		}

		return (
			<Filter
				clippedFilters={clippedFilters}
				inModal={inModal}
				filterId={getEnumByPath(path)}
				title={title}
				loading={filters.leftLoading}
				onReset={handleFilterReset.bind(this, path)}
				disabled={!currentFilter.length}
				onSearchChange={handleSearchTextChange.bind(this, path)}
				onSearchVisibility={handleSearchVisibility.bind(this, path)}
				searchValue={filtersState[path]?.searchValue}
				active={(selectedFilters[path] != null || filtersState[path]?.searchEnabled) ? filtersState[path]?.opened : false}
				searchEnabled={filtersState[path]?.searchEnabled}
				resetBtnDisabled={!selectedFilters[path]?.length}
				onToggle={handleToggleClip}
				onCollapsibleChange={handleCollapsibleChange.bind(this, path)} >

				<SelectionItems
					searchValue={filtersState[path]?.searchValue}
					items={currentFilter}
					selectedValues={selectedFilters?.[path]}
					onChange={handleFilterChange.bind(this, path)}
					onReset={handleFilterReset.bind(this, path)} />

			</Filter>
		)
	}

	const handleAvailabilityChange = (value: AvailabilityFilterType) => {
		dispatch(MainActions.changeAvailabilityFilter(value))

		if (value) {
			return
		}

		// TODO check this !!! why selections are supposedly lost ?
		
		//const byMatchCode = !lastSearch.carType?.length && !lastSearch.seasons?.length
			// if (!byMatchCode) {
			// 	//if is by match code we have to be sure that we use the previous selections
			// 	const lastSeason = summaryFilters.season != lastSearch.season && Statics.seasons.find(x => x.query == lastSearch.season)
			// 	lastSeason && dispatch(MainActions.updateSummaryFilters(EFilterNames.season, lastSeason))
			// 	if (!equals(summaryFilters.carType, lastSearch.carType))
			// 		batch(() => {
			// 			summaryFilters?.carType?.forEach(x => {
			// 				const filter = Statics.getCarTypes().find(y => x.query == y.query)
			// 				filter && dispatch(MainActions.updateSummaryFilters(EFilterNames.carType, filter))
			// 			})
			// 		})
			// }
		dispatch(MainActions.loadTiresList(false, true))
	}

	const renderAvailability = () => {
		const { availabilityFilterItemsToShow, availabilityFilterItemsToShowSecondary } = getBundleParams()

        if (!availabilityFilterItemsToShow?.length && !availabilityFilterItemsToShowSecondary?.length) {
			return null
        }

		const primarySelected = selectedFilters.availability === AvailabilityFilterType.Primary
		const secondarySelected = selectedFilters.availability === AvailabilityFilterType.Secondary

		return (
			<Filter
				inModal={inModal}
				clippedFilters={clippedFilters}
				filterId={FilterType.Availability}
				title={translateText(412)}
				onCollapsibleChange={handleCollapsibleChange.bind(this, "availability")}
				active={filtersState.availability?.opened}
				onReset={() => handleAvailabilityChange(AvailabilityFilterType.None)}
				resetBtnDisabled={!selectedFilters.availability}
				loading={filters.leftLoading}
				onToggle={handleToggleClip}
				disabled={!filters.manufacturer.length && !filters.loadIndex.length || filters.leftLoading}
			>
				{
					!!availabilityFilterItemsToShow?.length &&
					<SelectionCheckbox
						label={translateText(1623)}
						selected={primarySelected}
						onChange={() => handleAvailabilityChange(primarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Primary)}
					/>
				}
				{
					!!availabilityFilterItemsToShowSecondary?.length &&
					<SelectionCheckbox
						label={translateText(12860)}
						selected={secondarySelected}
						onChange={() => handleAvailabilityChange(secondarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Secondary)}
					/>
				}
			</Filter>
		)
	}

	const renderSeason = () => {
		return (
			<Filter title={translateText(1235)}
				inModal={inModal}
				clippedFilters={clippedFilters}
				onCollapsibleChange={handleCollapsibleChange.bind(this, EFilterNames.season)}
				filterId={FilterType.Season}
				active={filtersState.season?.opened}
				onReset={handleFilterReset.bind(this, EFilterNames.season)}
				resetBtnDisabled={!selectedFilters.season?.length}
				loading={filters.leftLoading}
				onToggle={handleToggleClip}
				disabled={!filters.season.length || !filters.manufacturer.length}>
				<Toolbar>
					{filters.season?.map((s) => (
						<Tooltip content={translateText(getTitleByGroup(s.query))} key={s.query}>
							<Button
								icon={getIconByGroup(s.query)}
								key={s.query}
								fakeButton
								isActive={!!selectedFilters.season?.find(x => x.query == s?.query)}
								onClick={() => handleSeasonChange(s)}
								className="filter"
							/>
						</Tooltip>
					))}
				</Toolbar>
			</Filter>
		)
	}

	const renderRangeSliders = () => {
		return (
			<Filter
				inModal={inModal}
				filterId={FilterType.RangeSliders}
				clippedFilters={clippedFilters}
				active={filtersState.fuelEfficiency?.opened}
				title={translateText(12404)}
				onReset={() => {
					dispatch(MainActions.resetAttributeFilter(EFilterNames.fuelEfficiency))
					dispatch(MainActions.resetAttributeFilter(EFilterNames.wetGripClass))
					dispatch(MainActions.resetAttributeFilter(EFilterNames.externalRolling))
					dispatch(MainActions.loadTiresList())
				}}
				resetBtnDisabled={!selectedFilters.fuelEfficiency && !selectedFilters.wetGripClass && !selectedFilters.externalRolling}
				disabled={filters.leftLoading || !filters.fuelEfficiency.length && !filters.wetGripClass.length && !filters.externalRolling.length}
				loading={filters.leftLoading}
				onToggle={handleToggleClip}
				onCollapsibleChange={handleCollapsibleChange.bind(this, EFilterNames.fuelEfficiency)}>

				<CustomRangeSlider
					title={translateText(722)}
					items={filters.fuelEfficiency}
					icon="gas-station"
					onChange={handleFilterChange.bind(this, EFilterNames.fuelEfficiency)}
					onReset={handleFilterReset.bind(this, EFilterNames.fuelEfficiency)}
					selectedItem={selectedFilters.fuelEfficiency}
				/>
				<CustomRangeSlider
					title={translateText(13279)}
					items={filters.wetGripClass}
					icon="rain"
					onChange={handleFilterChange.bind(this, EFilterNames.wetGripClass)}
					onReset={handleFilterReset.bind(this, EFilterNames.wetGripClass)}
					selectedItem={selectedFilters.wetGripClass}
				/>
				<CustomRangeSlider
					title={translateText(13280)}
					items={filters.externalRolling}
					icon="sound"
					minimized
					onChange={handleFilterChange.bind(this, EFilterNames.externalRolling)}
					onReset={handleFilterReset.bind(this, EFilterNames.externalRolling)}
					selectedItem={selectedFilters.externalRolling}
				/>

			</Filter>
		)
	}

	const wrapContent = (content: ReactNode) => {
		if (inModal)
			return (
				<Grid container>
					{content}
				</Grid>
			)

		return (
			<div className={classes(className, style.wrapper)}>
				{content}
			</div >
		)
	}

	return (
		<>
			{wrapContent(<>
				{renderAvailability()}
				{renderSeason()}
				{renderSelectionFilter(EFilterNames.manufacturer, translateText(71))}
				{renderSelectionFilter(EFilterNames.loadIndex, translateText(12403))}
				{renderSelectionFilter(EFilterNames.speedIndex, translateText(719))}
				{renderSelectionFilter(EFilterNames.oeIdentifier, translateText(720))}
				{renderSelectionFilter(EFilterNames.extras, translateText(744))}
				{/* // TODO check extra unused keys */}
				{/* renderSelectionFilter("studed", translateText(12532))}
				{renderSelectionFilter("studdable", translateText(12533))}
				{renderSelectionFilter("silent", translateText(12534))} */}
				{renderRangeSliders()}
			</>
			)}
		</>
	)
}

export default AttributeFilters

const style = useStyle({
	wrapper: {
		$nest: {
			"&--inModal": {
				flex: 1,
			}
		}
	}
})(AttributeFilters)
