import { useMemo } from "react"
import { OrderGroup } from "../../data/model/WorkTaskBasket"
import { useOrderOptionsMulti } from "../../data/hooks/useOrderOptions"
import { OrderOptionsGroup } from "../../models"
import { findCurrentActiveOption, useMultiAdditionalOrderOption } from "../useAdditionalOrderInformation"

export function useBasketOrderOptions(workTaskId: string, orderGroups?: OrderGroup[], telesalesCustomerNo?: string) {
    const additionalOrderOptions = useMultiAdditionalOrderOption()

    const allOrderOptions = useOrderOptionsMulti(
        orderGroups
            ?.filter((orderGroup) => orderGroup.partIds.length) // OrderOptions should not be invoked if there are no parts
            .map((group) => {
                return {
                    workTaskId,
                    telesalesCustomerNo,
                    distributorId: group.distributorId,
                    warehouseId: group.warehouseId,
                }
            }) || []
    )

    const [orderOptionsGroups, orderOptionsLoading, orderOptionsFetching] = useMemo<[OrderOptionsGroup[], boolean, boolean]>(() => {
        let loading = false
        let fetching = false
        return [
            allOrderOptions
                .filter((e) => (e.orderOptions as any) !== "reject")
                .map<OrderOptionsGroup>((option) => {
                    loading ||= option.loading
                    fetching ||= option.fetching
                    const optionalAdditionalInformation = additionalOrderOptions.find(
                        findCurrentActiveOption(workTaskId, { warehouseId: option.request.warehouseId, distributorId: option.request.distributorId })
                    )

                    return {
                        distributorId: option.request.distributorId,
                        warehouseId: option.request.warehouseId,
                        orderOptions: option.orderOptions
                            ? {
                                  ...option.orderOptions,
                                  orderComment: optionalAdditionalInformation?.customerOrderComment || option.orderOptions?.orderComment,
                                  customerOrderNumber: optionalAdditionalInformation?.customerOrderNumber || option.orderOptions?.customerOrderNumber,
                              }
                            : undefined,
                        isLoading: option.loading,
                        isFetching: option.fetching,
                        hasErrors: option.isError,
                    }
                }),
            loading,
            fetching,
        ]
    }, [allOrderOptions, additionalOrderOptions, workTaskId])

    return {
        orderOptionsGroups,
        orderOptionsLoading: orderOptionsLoading || orderOptionsFetching,
        orderOptionsLoaded: !!allOrderOptions.length && allOrderOptions.every((option) => option.loaded),
        orderOptionsWithError: allOrderOptions.some((option) => option.isError),
    }
}
