import { ShowConfigResponse, Vehicle, VehicleType } from "@tm/models"
import * as React from "react"

import { showGsiVehicle } from "../../data/repositories/gsi"
import { DetailsProps } from "./component"
import { DetailsByVehicle } from "./DetailsByVehicle"

type Props = DetailsProps & {
    vehicleType: VehicleType
    vehicleId: string
    workTaskId?: string
    availableVehicleSearches?: ShowConfigResponse
}

export const DetailsByVehicleId: React.FC<Props> = (props) => {
    const [vehicle, setVehicle] = React.useState<Vehicle>()

    React.useEffect(() => {
        showGsiVehicle(props.vehicleId).then(setVehicle)
    }, [props.vehicleId])

    return <DetailsByVehicle {...props} vehicle={vehicle} />
}
