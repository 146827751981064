import { AmountItem } from "@tm/controls"
import { Article } from "@tm/models"
import { Box, Checkbox, ListItemIcon, Typography } from "@tm/components"
import { useFastServiceStore } from "../../../data"
import { Genart } from "../../../data/models"
import { FSArticle } from "../../../data/repositories/fastcalculator-calculation/mapper"
import ArticleComponent from "./shared/ArticleComponent"

type Props = {
    genArt: Genart
    fsArticle?: FSArticle
    article: Article
    selectedGenArts?: Genart[]
    onQuantityArticleChange: (articleId: string, quantity: number) => void
    showPartAlternatives: (genArt: Genart, article: FSArticle, oeArticle?: boolean) => void
    disableCheckToggle?: boolean
    enableOeReplace?: boolean
}

export default function PartItem({
    genArt,
    fsArticle,
    article,
    onQuantityArticleChange,
    showPartAlternatives,
    disableCheckToggle,
    enableOeReplace,
    selectedGenArts,
}: Props) {
    const stateVehicle = useFastServiceStore((state) => state.maintenancePlan.stateVehicle)
    const selectedArticles = useFastServiceStore((state) => state.selectedArticles)
    const updateArticles = useFastServiceStore((state) => state.setUpdateSelectedArticles)
    const updateArticleQuantity = useFastServiceStore((state) => state.setUpdateArticleQuantity)

    const handleOnSelect = () => {
        updateArticles(article)
    }

    const handleQuantityChange = (item: AmountItem) => {
        onQuantityArticleChange(article.id, item.value)
        updateArticleQuantity(article, item.value)
    }

    const handleShowPatAlternative = () => {
        if (!fsArticle) {
            return
        }

        showPartAlternatives(genArt, fsArticle, enableOeReplace)
    }

    const getContent = () => {
        if (genArt.oeReplace) {
            return (
                <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex" alignItems="center">
                        <ListItemIcon>
                            <Checkbox
                                size="medium"
                                onChange={handleOnSelect}
                                checked={
                                    selectedGenArts?.some((x) => x.label === genArt.label && x.genArtNr === genArt.genArtNr) || genArt.isMandatory
                                }
                                disabled={genArt.isMandatory}
                            />
                        </ListItemIcon>
                        <Typography variant="h4" id={genArt.label}>
                            {genArt.label}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="0.5em">
                        <ArticleComponent
                            article={article}
                            vehicleId={stateVehicle?.id}
                            disableReplace={!fsArticle}
                            handleQuantityChange={handleQuantityChange}
                            handleShowPartAlternative={handleShowPatAlternative}
                        />
                    </Box>
                </Box>
            )
        }

        return (
            <>
                <Checkbox
                    size="medium"
                    onChange={handleOnSelect}
                    checked={selectedArticles.some((x) => x.internalId === article.internalId && x.id === article.id) || false}
                    disabled={disableCheckToggle}
                />
                <ArticleComponent
                    article={article}
                    vehicleId={stateVehicle?.id}
                    disableReplace={!fsArticle}
                    handleQuantityChange={handleQuantityChange}
                    handleShowPartAlternative={handleShowPatAlternative}
                />
            </>
        )
    }

    return (
        <Box paddingLeft="2px" className="tk-parts">
            <Box display="flex" gap="1em" className="article-list list">
                {getContent()}
            </Box>
        </Box>
    )
}
