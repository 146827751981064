import { PropsWithChildren, useCallback, useState } from "react"
import { ArticleListActionsProvider } from "../ArticleListActions"
import { PartsModuleStateProvider, usePartsModuleState } from "../PartsModuleState"
import { useErpInfos } from "../hooks/useErpInfos"
import { useListOptions } from "../hooks/useListOptions"
import { useShowArticleDetails } from "../hooks/useShowArticleDetails"
import { useSupplierLogos } from "../hooks/useSupplierLogos"
import { useWatchListData } from "../hooks/useWatchListData"
import { ArticleListActions, UniversalPartsModuleState } from "../models"
import { useArticles } from "./hooks/useArticles"
import { useFilters } from "./hooks/useFilters"
import { useListParams } from "./hooks/useListParams"
import { useBasketQuantities } from "../hooks/useBasketQuantities"
import { useNotes } from "../hooks/useNotes"
import { useHasLoadingEnded } from "../hooks/useHasLoadingEnded"
import { useTradeReferences } from "../hooks/useTradeReferences"

export function UniversalPartsModuleStateProvider({ children }: PropsWithChildren<unknown>) {
    const [isEnabled, setIsEnabled] = useState(false)
    const options = useListOptions("universal")
    const params = useListParams()
    const filtersData = useFilters(params, isEnabled)
    const articlesData = useArticles(params, isEnabled)
    const tradeReferences = useTradeReferences(articlesData.articles, isEnabled)
    const erpIsReady = useErpInfos(articlesData.articles, isEnabled, params.startParams, tradeReferences)
    const supplierLogosData = useSupplierLogos(articlesData.supplierIds, options.showDataSupplierLogos && isEnabled)
    const watchListData = useWatchListData(articlesData.articles, options.hasWatchList && isEnabled && erpIsReady)
    const showArticleDetails = useShowArticleDetails()
    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(articlesData.articles, isEnabled && erpIsReady)
    const notes = useNotes(articlesData.articles, false, isEnabled && erpIsReady)

    const hasLoadingEnded = useHasLoadingEnded(supplierLogosData.isLoading, watchListData?.isLoading)

    /**
     * Do not wrap it with `useMemo` here but threat it as not reference stable and therefor not use it as any dependency for other hooks.
     */
    const state: UniversalPartsModuleState = {
        type: "universal",
        start: useCallback(() => setIsEnabled(true), []),
        options,
        params,
        filters: filtersData,
        articles: articlesData,
        supplierLogos: hasLoadingEnded ? supplierLogosData : undefined,
        watchListData: hasLoadingEnded ? watchListData : undefined,
        productGroupRepairTimes: undefined,
        activeVehicleDataProviders: undefined,
        basketQuantities,
        notes,
        tradeReferences,
    }

    const articleActionsValue: ArticleListActions = {
        toggleSupplier: filtersData.toggleSupplier,
        showArticleDetails,
        updateBasketQuantities,
    }

    return (
        <PartsModuleStateProvider value={state}>
            <ArticleListActionsProvider value={articleActionsValue}>{children}</ArticleListActionsProvider>
        </PartsModuleStateProvider>
    )
}

export function useUniversalPartsModuleState(): UniversalPartsModuleState
export function useUniversalPartsModuleState<TReturnType>(selector: (value: UniversalPartsModuleState) => TReturnType): TReturnType
export function useUniversalPartsModuleState<TReturnType>(
    selector?: (value: UniversalPartsModuleState) => TReturnType
): UniversalPartsModuleState | TReturnType {
    return usePartsModuleState<UniversalPartsModuleState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
