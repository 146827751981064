import { Grid, lighten, useTheme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CostEstimationOptions } from "@tm/models"
import { CostEstimationCalculation } from "../../../../data/model"
import { HighlightContrastTypography, StyledBox } from "../../../StyledComponents"
import CostEstimationDetails from "./CostEstimationDetails"
import Totals from "./Totals"

type Props = {
    costEstimationCalculation?: CostEstimationCalculation
    costEstimationOptions?: CostEstimationOptions
    currencyCode: string
    showRepairTimesInHours: boolean
}

export default function CostEstimationTotalsTable({ costEstimationCalculation, currencyCode, showRepairTimesInHours, costEstimationOptions }: Props) {
    const { priceVatMode } = costEstimationOptions || {}
    const { translateText } = useLocalization()

    const theme = useTheme()
    const styledBoxBackgroundColor =
        theme.overwrites?.components?.basketSummary?.costEstimationDetails?.backgroundColor || lighten(theme.palette.common.black, 0.3)
    const styledBoxPadding = theme.spacing(1.5, 2)
    const basketSummaryTheme = theme.overwrites?.components?.basketSummary
    const highlightColor =
        basketSummaryTheme?.costEstimationTotals?.backgroundColor || basketSummaryTheme?.component?.backgroundColor || theme.palette.highlight.main

    return (
        <>
            <StyledBox backgroundColor={styledBoxBackgroundColor} padding={styledBoxPadding}>
                <CostEstimationDetails
                    costEstimationCalculation={costEstimationCalculation}
                    currencyCode={currencyCode}
                    showRepairTimesInHours={showRepairTimesInHours}
                    priceVatMode={priceVatMode}
                />
            </StyledBox>
            <StyledBox backgroundColor={highlightColor} padding={styledBoxPadding}>
                <Grid container spacing={1} alignItems="center" sx={{ rowGap: 0.5 }}>
                    <Totals
                        costEstimationCalculation={costEstimationCalculation}
                        currencyCode={currencyCode}
                        costEstimationOptions={costEstimationOptions}
                    />
                    <Grid item xs={6} />
                    <Grid item xs={6} textAlign="right">
                        <HighlightContrastTypography variant="label">*{translateText(802)}</HighlightContrastTypography>
                    </Grid>
                </Grid>
            </StyledBox>
        </>
    )
}
