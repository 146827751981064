import * as React from "react"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { concat } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { getStyleTheme } from "@tm/context-distribution"
import { Text, Button } from "@tm/controls"

import { Customers } from "../../../business/gateway"
import { SeparatorList } from "../../_shared/separator-list"
import { getCustomerNameAndCompany } from "../helpers"

function getComponentStyles(): NestedCSSProperties {
    const { margin } = getStyleTheme()

    return {
        display: "flex",
        alignItems: "center",
        margin: `${margin.l} 0`,
        $nest: {
            "&__label": {
                marginRight: margin.l,
            },
            "&__actions": {
                marginLeft: "auto",
            },
        },
    }
}

type Props = {
    customer: Customers.Customer
    onDeselect(): void
}

export const SelectedCustomer: React.FC<Props> = ({ customer, onDeselect }) => {
    const className = style(getComponentStyles())
    const { translate } = useLocalization()

    const deselect = React.useCallback(() => onDeselect(), [onDeselect])

    const renderNameAndCompany = () => {
        return <Text>{getCustomerNameAndCompany(customer)}</Text>
    }

    const renderStreet = () => {
        return <Text>{customer.generalAddress?.street}</Text>
    }

    const renderCity = () => {
        return <Text>{customer.generalAddress && concat(" ", customer.generalAddress.zip, customer.generalAddress.city)}</Text>
    }

    return (
        <div className={className}>
            <Text size="l" className={`${className}__label`}>
                {translate(107)}
            </Text>

            <SeparatorList>
                {renderNameAndCompany()}
                {renderStreet()}
                {renderCity()}
            </SeparatorList>

            <div className={`${className}__actions`}>
                <Button icon="cancel-x" layout={["ghost"]} onClick={deselect} />
            </div>
        </div>
    )
}
