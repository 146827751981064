import { Box, Theme, Typography } from "@tm/components"
import { Price } from "@tm/models"
import { useLocalization } from "@tm/localization"

type Props = {
    price: Price
    priceLabel: string
    primaryColor?: boolean
    theme: Theme
}

export default function SmallScreenPrice(props: Props) {
    const { price, priceLabel, primaryColor, theme } = props
    const fontFamilyOverwrite = theme?.overwrites?.components?.basketPrices?.fontFamily?.replaceAll("'", "") || theme.typography.fontFamily

    const { currency } = useLocalization()

    return (
        <>
            {/* first row */}
            <Typography variant="label" textAlign="end" noWrap>
                {priceLabel}
            </Typography>
            <Box />

            {/* second row */}
            <Typography
                color={primaryColor ? "primary" : undefined}
                fontFamily={fontFamilyOverwrite || theme.typography.fontFamily}
                fontWeight="bold"
                noWrap
                textAlign="end"
            >
                {currency(price.value, price.currencySymbol || price.currencyCode)}
            </Typography>
            <Box />
        </>
    )
}
