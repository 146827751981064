import { equals } from "@tm/utils"
import { parseInt } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { ReplaceButtonBundle, ListFilter, SearchFilters } from "@tm/models"
import { useArticleListSorting } from "../../hooks/useArticleListSorting"
import { useArticleSelectionActions } from "../../hooks/useArticleSelection"
import { DirectSearchListParams, DirectSearchStartParams } from "../../models"

function getStartParamsFromUrl(url: string): DirectSearchStartParams {
    const searchParams = new URLSearchParams(url)

    let searchFilter = SearchFilters.All
    const searchFilterValue = searchParams.get("searchFilter")
    if (searchFilterValue) {
        searchFilter = parseInt(searchFilterValue)
    }

    let fittingSideFilter = 0
    const fittingSideFilterValue = searchParams.get("fittingSideFilter")
    if (fittingSideFilterValue) {
        fittingSideFilter = parseInt(fittingSideFilterValue)
    }

    const isOeReplacement = searchParams.get("isOeReplacement") === "1"

    const replaceButtonBundle = searchParams.get("replaceButtonMicro") as ReplaceButtonBundle | null

    return {
        type: "direct",
        query: searchParams.get("query") ?? "",
        searchFilter,
        fittingSideFilter,
        isOeReplacement,
        replaceButtonBundle,
    }
}

/**
 * Get information about the current article list parameters, status and filter selection and some helper methods to change them.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useListParams(): DirectSearchListParams {
    const location = useLocation()

    const sorting = useArticleListSorting()
    const { clear: clearSorting } = sorting

    const [startParams, setStartParams] = useState<DirectSearchStartParams>(() => getStartParamsFromUrl(location.search))

    const [productGroups, setProductGroups] = useState<ListFilter[]>([])
    const [suppliers, setSuppliers] = useState<ListFilter[]>([])
    const [attributes, setAttributes] = useState<ListFilter[]>([])
    const [extendedAssortment, setExtendedAssortment] = useState(false)
    const [showAvailable, setShowAvailable] = useState(false)
    const [showAvailableSecondary, setShowAvailableSecondary] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [noResult, setNoResult] = useState(false)
    const [isFiltersLoading, setIsFiltersLoading] = useState(false)

    // Relevant for Multiselect, resets on reload, list change or new search
    const { deselectAll } = useArticleSelectionActions()

    useEffect(
        function resetOnStartParamsChanged() {
            const newStartParams = getStartParamsFromUrl(location.search)
            deselectAll()

            if (!equals(newStartParams, startParams)) {
                setStartParams(newStartParams)
                setAttributes((prev) => (prev.length ? [] : prev))
                setProductGroups((prev) => (prev.length ? [] : prev))
                setSuppliers((prev) => (prev.length ? [] : prev))
                setExtendedAssortment(false)
                clearSorting()
            }
        },
        [deselectAll, location, startParams, clearSorting]
    )

    const toggleProductGroup = useCallback((filter: ListFilter) => {
        setProductGroups((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)

            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleSupplier = useCallback((filter: ListFilter) => {
        setSuppliers((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)

            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleAttribute = useCallback((attribute: ListFilter) => {
        setAttributes((state) => {
            const existingIndex = state.findIndex((attr) => attr.id === attribute.id && attr.value === attribute.value)

            if (existingIndex === -1) {
                return [...state, attribute]
            }

            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const handleChangeExtendedAssortment = useCallback((checked: boolean) => setExtendedAssortment(checked), [])
    const setAvailability = useCallback((checked: boolean) => setShowAvailable(checked), [])
    const setAvailabilitySecondary = useCallback((checked: boolean) => setShowAvailableSecondary(checked), [])

    const nextPage = useCallback(() => {
        setPageIndex((index) => index + 1)
    }, [])

    return {
        startParams,
        pageIndex,
        productGroups,
        suppliers,
        attributes,
        extendedAssortment,
        showAvailable,
        showAvailableSecondary,
        noResult,
        isFiltersLoading,
        setProductGroups,
        setSuppliers,
        setAttributes,
        toggleProductGroup,
        toggleSupplier,
        toggleAttribute,
        setExtendedAssortment: handleChangeExtendedAssortment,
        setAvailability,
        setAvailabilitySecondary,
        setNoResult,
        setIsFiltersLoading,
        nextPage,
        sorting,
    }
}
