import { Box, Button, Icon, Popover, ToggleSwitch, Tooltip, Typography, styled, switchClasses } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { TmaHelper, getUIA } from "@tm/utils"
import { useCallback, useState, MouseEvent, PropsWithChildren } from "react"
import { ECounterType, SelectedCustomerGroupMode, SelectedPriceTypeMode, SelectedPriceVatMode } from "@tm/models"
import { CostEstimation } from "../../../../data/model"

type Props = PropsWithChildren<{
    disabled: boolean
    costEstimation?: CostEstimation
    hasWheelsModule: boolean
    printVehicleImage: boolean
    showManufacturer: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    onToggleShowManufacturers(state: boolean): void
    onToggleShowArticleNumbers(state: boolean): void
    onToggleShowWholesalerArticleNumbers(state: boolean): void
    onTogglePrintVehicleImage(state: boolean): void
    onChangeRepairTimeCalculation(useRepairTimeCalculation: boolean): void
    onChangePriceTypeMode(priceTypeMode?: SelectedPriceTypeMode): void
    onChangePriceVatMode(priceVatMode?: SelectedPriceVatMode): void
    onChangeCustomerGroupMode(customerGroupMode?: SelectedCustomerGroupMode): void
}>

const GridWrapper = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    alignItems: "center",
    ".ToggleSwitch-root": {
        display: "contents",
    },
    ".ToggleSwitchLabel": {
        fontSize: theme.typography.body2.fontSize,
    },
    ".ToggleSwitchLabel-left": {
        justifySelf: "right",
    },
    ".MuiSwitch-root": {
        justifySelf: "center",
    },
    [`.oneway:not(.Mui-checked) .${switchClasses.track}`]: {
        backgroundColor: theme.palette.grey[500],
    },
}))

export function OptionsButton(props: Props) {
    const {
        hasWheelsModule,
        showSupplierArticleNumbers,
        showWholesalerArticleNumbers,
        showManufacturer,
        printVehicleImage,
        disabled,
        costEstimation,
    } = props
    const { costEstimationOptions, showCustomerGroupSelection, showPriceTypeModeSelection, showPriceVatModeSelection } = costEstimation ?? {}
    const { customerGroupMode, priceTypeMode, priceVatMode } = costEstimationOptions ?? {}
    const { translateText } = useLocalization()
    const [viewOptionsAnchorElement, setViewOptionsAnchorElement] = useState<HTMLElement>()

    const handleOpenViewOptions = useCallback((event: MouseEvent<HTMLElement>) => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateViewOptions)
        setViewOptionsAnchorElement(event.currentTarget)
    }, [])

    const handleCloseViewOptions = useCallback(() => setViewOptionsAnchorElement(undefined), [])

    return (
        <Tooltip title={translateText(844)} placement="top">
            <Box>
                <Button
                    onClick={handleOpenViewOptions}
                    disabled={disabled}
                    {...getUIA("CostestimationNavbarDisplayoptions")}
                    startIcon={<Icon name="settings" />}
                    variant="outlined"
                />
                <Popover
                    open={!!viewOptionsAnchorElement}
                    anchorEl={viewOptionsAnchorElement}
                    onClose={handleCloseViewOptions}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    {...getUIA("CostestimationNavbarDisplayoptionsContainer")}
                >
                    <GridWrapper py={1} px={2}>
                        <Box gridColumn="span  3">
                            <Typography textTransform="uppercase" variant="body2">
                                {translateText(222)}
                            </Typography>
                        </Box>
                        <ToggleSwitch
                            className="oneway"
                            right={{ value: translateText(87), key: true }}
                            selected={showSupplierArticleNumbers}
                            onChange={(item) => props.onToggleShowArticleNumbers(!!item?.key)}
                            {...getUIA("CostestimationNavbarDisplayoptionsPartnumber")}
                        />
                        <ToggleSwitch
                            className="oneway"
                            right={{ value: translateText(91), key: true }}
                            selected={showWholesalerArticleNumbers}
                            onChange={(item) => props.onToggleShowWholesalerArticleNumbers(!!item?.key)}
                            {...getUIA("CostestimationNavbarDisplayoptionsDealerPartNumber")}
                        />
                        <ToggleSwitch
                            className="oneway"
                            right={{ value: translateText(71), key: true }}
                            selected={showManufacturer}
                            onChange={(item) => props.onToggleShowManufacturers(!!item?.key)}
                            {...getUIA("CostestimationNavbarDisplayoptionsManufacturer")}
                        />
                        {hasWheelsModule && (
                            <ToggleSwitch
                                className="oneway"
                                right={{ value: translateText(1632), key: true }}
                                selected={printVehicleImage}
                                onChange={(item) => props.onTogglePrintVehicleImage(!!item?.key)}
                                {...getUIA("CostestimationNavbarDisplayoptionsPrintImage")}
                            />
                        )}
                        <ToggleSwitch
                            disabled={disabled}
                            className="oneway"
                            right={{ value: translateText(3161), key: true }}
                            selected={costEstimation?.useRepairTimeCalculation}
                            onChange={(item) => props.onChangeRepairTimeCalculation(!!item?.key)}
                            {...getUIA("CostestimationNavbarDisplayoptionsCalculateLaborVal")}
                        />

                        {(showPriceVatModeSelection || showPriceTypeModeSelection || showCustomerGroupSelection) && (
                            <Box gridColumn="span  3" mt={1}>
                                <Typography textTransform="uppercase" variant="body2">
                                    {translateText(13743)}
                                </Typography>
                            </Box>
                        )}
                        {showPriceVatModeSelection !== undefined && (
                            <ToggleSwitch
                                left={{ value: translateText(13657), key: SelectedPriceVatMode.Net }}
                                right={{ value: translateText(13658), key: SelectedPriceVatMode.Gross }}
                                disabled={disabled || !showPriceVatModeSelection}
                                selected={costEstimationOptions?.priceVatMode}
                                onChange={() => props.onChangePriceVatMode(priceVatMode ? SelectedPriceVatMode.Net : SelectedPriceVatMode.Gross)}
                            />
                        )}
                        {showPriceTypeModeSelection !== undefined && (
                            <ToggleSwitch
                                left={{ value: translateText(1620), key: SelectedPriceTypeMode.Retail }}
                                right={{ value: translateText(55), key: SelectedPriceTypeMode.Purchase }}
                                disabled={disabled || !showPriceTypeModeSelection}
                                selected={priceTypeMode}
                                onChange={(item) =>
                                    props.onChangePriceTypeMode(item?.key ? SelectedPriceTypeMode.Retail : SelectedPriceTypeMode.Purchase)
                                }
                            />
                        )}
                        {showCustomerGroupSelection !== undefined && (
                            <ToggleSwitch
                                left={{ value: translateText(13660), key: SelectedCustomerGroupMode.B2C }}
                                right={{ value: translateText(13659), key: SelectedCustomerGroupMode.B2B }}
                                selected={customerGroupMode}
                                disabled={disabled || !showCustomerGroupSelection}
                                onChange={() =>
                                    props.onChangeCustomerGroupMode(customerGroupMode ? SelectedCustomerGroupMode.B2C : SelectedCustomerGroupMode.B2B)
                                }
                            />
                        )}
                    </GridWrapper>
                </Popover>
            </Box>
        </Tooltip>
    )
}
