import { VehicleLookupConfig, VehicleType } from "@tm/models"
import { Box } from "@tm/components"
import VehicleSearchField from "../_shared/VehicleSearchField"
import { TopManufacturer } from "../_shared/TopManufacturer"
import SpecialLicensePlatesComponent from "../widget/components/special-license-plates/component"

type Props = {
    handleStartSearch: (query: string) => void
    specializedSearchConfig?: VehicleLookupConfig
}

function BikeCardComponent(props: Props) {
    const { specializedSearchConfig } = props

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <SpecialLicensePlatesComponent
                className="vehicle-widget__special-license-plates-micro"
                vehicleType={VehicleType.Motorcycle}
                size="m"
                registrationNoType={specializedSearchConfig?.lookupTypeId}
            />
            <VehicleSearchField {...props} onStartSearch={props.handleStartSearch} vehicleType={VehicleType.Motorcycle} />
            <TopManufacturer vehicleType={VehicleType.Motorcycle} />
        </Box>
    )
}

export default BikeCardComponent
