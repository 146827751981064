import { styled } from "@tm/components"
import { Button, Icon, List, Loader, Text, PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import * as React from "react"
import { useSelector } from "react-redux"
import { MainState } from "../../../main"
import { ValueItemsDefinition } from "../../business"

type Props = {
    isLoading: boolean
    hidePrintButton?: boolean
}

const items = [
    { id: 0, description: 713, value: "aspectRatio" },
    { id: 1, description: 715, value: "wheelSize" },
    { id: 2, description: 1133, value: "boltCircle" },
    { id: 3, description: 1135, value: "offset" },
    { id: 4, description: 1820, value: "hubbore" },
    { id: 5, description: 71, value: "supplierName" },
    { id: 6, description: 387, value: "eanCode" },
    { id: 7, description: 1823, value: "artNr" },
    { id: 8, description: 1136, value: "winterFlag" }
]

const TechnicalData: React.FC<Props> = ({ isLoading, hidePrintButton }) => {
    const { translateText } = useLocalization()
    const { selectedTireSize, selectedRimDetailsArticle, hostettlerCombinations, isSSW } = useSelector((s: MainState) => ({
        selectedTireSize: s.wheelsDetails.selectedTireSize,
        selectedRimDetailsArticle: s.wheelsDetails.selectedRimDetailsArticle,
        hostettlerCombinations: s.wheelsDetails.rimItem?.hostettlerCombinations,
        sswCombinations: s.wheelsDetails.rimItem?.sswCombinations,
        isSSW: s.wheelsDetails.rimItem?.isSSW
    }))
    const loading = useSelector<MainState, boolean | undefined>(s => s.wheelsDetails.rimDetailsArticlesResponse.loading)

    let HSCombinationInfo : { information?: string, montageauflagen?: string} | undefined

    if (isSSW) {
        const selectedRimSize = selectedRimDetailsArticle?.rimDetails?.find(rim => rim.reifen == selectedTireSize?.tyreSize?.split(" ")?.[0])
        HSCombinationInfo = React.useMemo(() =>
        ({
            information: selectedRimSize?.information,
            montageauflagen: selectedRimSize?.montageauflagen
        }), [selectedRimDetailsArticle, selectedTireSize])
    } else {
        HSCombinationInfo = React.useMemo(() => {
            const selectedRimArticle = (selectedRimDetailsArticle?.wheelSize ? hostettlerCombinations?.[selectedRimDetailsArticle.wheelSize] : [])?.find((y) => y.articleNumber == selectedRimDetailsArticle?.artNr)
            return ({
                information: selectedRimArticle?.information,
                montageauflagen: selectedRimArticle?.montageauflagen
            })
        }, [selectedRimDetailsArticle]
        )
    }

    const renderItem = (item: ValueItemsDefinition) => {
        return (
            <>
                <Text modifiers="strong">{translateText(item.description)}</Text>
                {item.id != 8 && (selectedRimDetailsArticle && <Text>{selectedRimDetailsArticle[item.value]}</Text> || "")}
                {item.id == 8 && <Icon name={item.value && "snowflake" || "close"} />}
            </>
        )
    }

    const handleCertificateUrl = React.useCallback(() => {
        const certificateUrl = selectedTireSize?.pathToCertificate
        certificateUrl && window.open(certificateUrl, '_blank')
    }, [selectedTireSize])

    const renderCertificateReport = () => {
        if (!selectedTireSize?.pathToCertificate) return null

        return (
            <>
                <Text className="title" modifiers="block">{translateText(1022)}</Text>
                <Button disabled={isLoading} className="pdf-btn" icon="download" size="s" onClick={handleCertificateUrl}>{translateText(1021)}</Button>
            </>
        )
    }

    const renderHSInfo = () => {
        return (
            <StyledPanelSection size="xs" title={translateText(12965)}>
                {HSCombinationInfo?.information && <Text size="m">{HSCombinationInfo.information}</Text>}
                {HSCombinationInfo?.montageauflagen && <StyledText size="l">{HSCombinationInfo.montageauflagen.charAt(0).toUpperCase() + HSCombinationInfo.montageauflagen.slice(1)}</StyledText>}
            </StyledPanelSection>
        )
    }

    if (loading)
        return (
            <div className="article-list__panel article-list__status">
                <Loader />
            </div >
        )

    if (!selectedRimDetailsArticle)
        return (
            <div className="article-list__panel article-list__status">
                <Text>{translateText(323)}</Text>
            </div>
        )

    return (
        <div className="wheels-technical-data article-details">
            {!hidePrintButton && renderCertificateReport()}
            <hr />
            <List className="key-value-list" items={items} view={renderItem} />
            {(HSCombinationInfo?.information || HSCombinationInfo?.montageauflagen) && renderHSInfo()}
        </div >
    )
}

export default TechnicalData

const StyledPanelSection = styled(PanelSection)(({ theme }) => ({
    margin: theme.margin?.s ?? ".25em",
    '.panel__title': {
        padding: 0,
        border: 0
    },
    '.panel__content': {
        marginTop: theme.margin?.m ?? ".5em"
    }
}))

const StyledText = styled(Text)(({ theme }) => ({
    color: theme.colors?.danger ?? "red",
    fontWeight: theme.font?.boldWeight ?? "bold",
    paddingTop: theme.margin?.s ?? ".25em"
}))