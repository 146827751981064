import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Icon, Tooltip, Widget } from "@tm/controls"
import { carMunValidate } from "@tm/utils"
import * as React from "react"
import Content from "./components/content"

type Props = {
    detailsRoute: string
    hideDrivingStyleAnalysis?: boolean
}

const WidgetComponent: React.FC<Props> = () => {
    const worktaskVehicle = useWorkTask()?.workTask?.vehicle
    const isCarMunValidate = carMunValidate(worktaskVehicle?.telematicsRefId, worktaskVehicle?.vin)

    const renderWidgetCover = () => (
        <div className="widget__cover">
            <Icon className="widget__cover-icon" name="telematics" />
            <div className="widget__cover-text">
                {"Carmunication"}
            </div>
        </div>
    )

    return (
        <>
            {isCarMunValidate && 
                <Widget
                    size="1x1"
                    title={"Carmunication"}
                    highlight cover={
                        <Tooltip
                            content={<Content />}
                            event="click"
                            style="primary"
                            position="top"
                        >
                            {renderWidgetCover()}
                        </Tooltip>
                    } />
            }
        </>
    )
}
export default WidgetComponent
