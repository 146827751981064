import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Dialog, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel, getCurrentWorkTaskId } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, EventListenerManager, barcodeToastState, RouteComponentProps, uniqueId, withRouter } from "@tm/utils"
import * as React from "react"
import { useSetRecoilState } from "recoil"
import { getBundleParams } from "../../utils"

type Shortcut = {
    text: number | string
    usable: boolean
}

function getStyle() {
    const theme = getStyleTheme()
    return useStyle({
        dialog: {
            $nest: {
                ".dialog-prompt__content": {
                    paddingTop: "1em",
                },
                ".dialog-prompt__additional-content": {
                    height: "91%",
                    padding: "0 2em 1.5em 2em",
                },
                ".dialog-prompt__inner": {
                    height: "max-content",
                    width: "max-content",
                    top: "50% !important",
                    transform: "translateY(-50%)",
                    borderRadius: "3px",
                },
                ".text--xl": {
                    fontSize: "27px !important",
                },
                ".shortcut": {
                    borderTop: "1px solid #d3d3d3",
                    marginLeft: "3em",
                    marginRight: "3em",
                    padding: theme.margin.l,
                    $nest: {
                        ".disabled": {
                            opacity: theme.opacity.disabled,
                        },
                    },
                },
            },
        },
    })(Shortcuts)
}

type Props = RouteComponentProps<void>

const Shortcuts: React.FC<Props> = ({ history }) => {
    const style = React.useMemo(getStyle, [])
    const unregisterOutsideClick = React.useRef<(() => void) | undefined>(undefined)
    const dialogRef = React.useRef<Dialog>(null)
    const hasVehicle = !!useWorkTask()?.workTask?.vehicle?.id
    const { translateText } = useLocalization()
    const { hasCommercialVehicles } = Morpheus.getParams("vehicle").moduleAvailabilities
    const [showDialog, setShowDialog] = React.useState(false)
    const setBarcodeToast = useSetRecoilState(barcodeToastState)

    const renderShortcutText = (item: string | number): string => (typeof item === "string" ? item : translateText(item))

    const getDmsName = (): string => {
        const isDMSConnected = (window as any)?.__DMS_MESSAGEBUS__?.channel("CONNECTION").last(1, "CONNECTION_STATUS_RECEIVED")[0]
            ?.content?.isConnected
        if (!isDMSConnected) {
            return "DMS"
        }
        return (window as any).__dmsInfo?.name ?? JSON.parse(localStorage.getItem("DMS_INFO") ?? "{}")?.name ?? translateText(1875)
    }

    const includesPath = (c_path: string): boolean => {
        return location.pathname.includes(c_path)
    }

    const dmsText = translateText(12922).replace("DMS", getDmsName())

    const infos: Shortcut[] = [
        { text: 12574, usable: true },
        { text: dmsText, usable: true },
        { text: 12576, usable: true },
        { text: 12577, usable: !hasVehicle && hasCommercialVehicles },
        { text: 12578, usable: true },
        { text: 12579, usable: true },
        { text: 12580, usable: true },
        { text: 12581, usable: true },
        { text: 12582, usable: true },
    ]

    if (!(!getCurrentWorkTaskId() || typeof tmJSEvents === "undefined" || !tmJSEvents || !tmJSEvents.onExportShoppingBasket)) {
        infos.push({ text: 13171, usable: true })
    }

    if (includesPath("/parts/universal") || includesPath("/parts/direct") || includesPath("/parts/vehicles")) {
        infos.push({ text: 13002, usable: true })
    }

    const handlehideDialog = React.useCallback(() => {
        dialogRef.current?.hide()
        unregisterOutsideClick.current?.()
        setShowDialog(false)
    }, [])

    const redirectTo = (url?: string) => {
        var urlToPush = `/${encodeUniqueId(getCurrentWorkTaskId() || uniqueId())}/`
        if (url) urlToPush += url
        history.push(urlToPush)
    }
    React.useEffect(() => {
        const handleshowDialog = () => {
            dialogRef.current?.show()
            setShowDialog(true)
        }
        const handleKey = (key: string) => {

            const isDMSConnected = (window as any)?.__DMS_MESSAGEBUS__?.channel("CONNECTION").last(1, "CONNECTION_STATUS_RECEIVED")[0]
                ?.content?.isConnected

            switch (key) {
                case "i": // info
                    dialogRef.current?.state.open ? handlehideDialog() : handleshowDialog()
                    break
                case "Escape":
                    dialogRef.current?.state.open && handlehideDialog()
                    break
                case "0": // Alt + 0 this shortcut is also used in parts for purchase-price-switch
                    break
                case "1": // new tab
                    history.push(`/${encodeUniqueId(uniqueId())}`) // includes new tab
                    break
                case "2": // open dms(StakisProfi) if available
                    if (isDMSConnected) {
                        redirectTo("dms")
                    } else {
                        history.push(`/usersettings/dms`)
                    }
                    break
                case "3": // dashboard
                    redirectTo()
                    channel("APP").publish("SHORTCUTS", { key: "PKW" })
                    break
                case "4": // dashboard focus nkw
                    if (hasCommercialVehicles && !hasVehicle) {
                        redirectTo()
                        channel("APP").publish("SHORTCUTS", { key: "NKW" })
                    }
                    break
                case "5": // dashboard focus universal
                    redirectTo()
                    channel("APP").publish("SHORTCUTS", { key: "UNI" })
                    break
                case "6": // dashboard focus article search
                    redirectTo()
                    channel("APP").publish("SHORTCUTS", { key: "DIRECT" })
                    break
                case "7": // basket
                    redirectTo("basket")
                    break
                case "8": // kva
                    !getBundleParams().nextLight && redirectTo("cost-estimation")
                    break
                case "9": // delete basket
                    if (!window.location.href.includes("basket")) {
                        redirectTo("basket")
                    }
                    channel("APP").publish("SHORTCUTS", { key: "delete" })
                    break
                case "x":
                    channel("APP").publish("SHORTCUTS", { key: "dms-voucher" })
                    break
                default:
                    break
            }
        }

        const onkeydown = (ev: KeyboardEvent) => {
            if (ev.key === "Escape" && dialogRef.current?.state.open) {
                handlehideDialog()
            }

            if (ev.altKey) {
                ev.preventDefault()
                handleKey(ev.key)
            }

            if (ev.ctrlKey && ev.key === "b") {
                ev.preventDefault()
                setBarcodeToast((prev) => ({ show: !prev.show }))
            }
        }

        const disableEmoticons = (event: KeyboardEvent) => {
            // this symbols should not be displayed
            if ((event.code === "AltLeft" || event.code === "AltRight") && ["☺", "☻", "♥", "♦", "♣", "♠", "•", "◘", "○"].includes(event.key)) {
                event.preventDefault()
                event.stopPropagation()
            }
        }

        const handleMvcShortcuts = (event: MessageEvent) => {
            const {
                data: { sendShortCutKeys },
            } = event
            if (sendShortCutKeys) {
                handleKey(sendShortCutKeys)
            }
        }

        document.addEventListener("keydown", onkeydown)
        document.addEventListener("keypress", disableEmoticons)
        window.addEventListener("message", handleMvcShortcuts) // get shortcuts from mvc as well
        return () => {
            document.removeEventListener("keydown", onkeydown)
            document.removeEventListener("keypress", disableEmoticons)
            window.removeEventListener("message", handleMvcShortcuts)
        }
    }, [handlehideDialog])

    React.useLayoutEffect(() => {
        if (showDialog && dialogRef.current?.innerRef.current) {
            unregisterOutsideClick.current = EventListenerManager.registerEventListener(
                "outsideClick",
                dialogRef.current.innerRef.current,
                handlehideDialog,
                true
            )
        }
        return () => {
            unregisterOutsideClick.current?.()
        }
    }, [showDialog, handlehideDialog])

    return (
        <Dialog
            ref={dialogRef}
            text="Keyboard Shortcuts"
            layout="stretch"
            iconName="info"
            skin="primary"
            doNotCloseOnConfirm
            className={style.dialog}
        >
            {infos.map((info: Shortcut) => {
                return (
                    <div className="shortcut" key={info.text}>
                        <Text className={info.usable ? "" : "disabled"} size="l">
                            {renderShortcutText(info.text)}
                        </Text>
                    </div>
                )
            })}
        </Dialog>
    )
}
export default withRouter(Shortcuts)
