import { channel, ModuleInfo, PageInfo, UserContext, WorkTaskDetailsReadModel } from "@tm/models"
import { History } from "history"
import { ActionDispatch, AsyncAction } from "@tm/morpheus"
import { equals, WorkTaskTruckData } from "@tm/utils"
import { BundleActionType } from "../../../business"
import { subscribe } from "./subscribe"
import * as ViewStateData from "../../../data/repositories/viewState"
import { mapWorkTaskIdToNavigationItem, mapWorkTaskToNavigationItem, mapPageToNavigationItem } from "./mapper"

const STATE_SERVICE_KEY = "__page-navigation-v2__"

export const actionsAfterLoaded: ((dispatch: ActionDispatch) => void)[] = []

export type ComponentActionType =
    | BundleActionType
    | { type: "INIT"; payload?: { maxTabs: number } }
    | { type: "PAGE/LOADED" }
    | { type: "CHANGE_WORKTASK_ID"; payload: { id: string; history: History } }
    | { type: "CHANGE_URL"; payload: string }
    | { type: "CHANGE_MODULE_INFO"; payload: ModuleInfo }
    | { type: "CHANGE_ITEM"; payload: NavigationItem }
    | { type: "SET_SPLIT_POSITION"; payload: number }
    | { type: "REMOVE_ITEMS"; payload: Array<string> }
    | { type: "STATE_LOADING" }
    | { type: "STATE_LOADED"; payload: { state: StorageLoadState; history: History } }
    | { type: "SET_AFTER_LOAD_HOOK"; payload?: AfterLoadHook }
    | { type: "SET_WORKTASK_TRUCK_DATA"; payload: { workTaskId: string; workTaskTruckData: WorkTaskTruckData } }

type AfterLoadHook = {
    hook(state: NavigationState): void
    once: boolean
}

export type CustomerInfo = {
    displayName: string
    customerNo: string | null
    refCustomerNo: string | null
    displayCustomerNo: string | null
}

export type VehicleInfo = {
    displayName: string
    plateId: string | null
    countryCode?: string
}

export type VoucherInfo = {
    type?: string
    number: string
}

export type NavigationItem = {
    id: string
    baseUrl: string
    url: string
    customer?: CustomerInfo | null
    vehicle?: VehicleInfo | null
    page?: PageInfo
    module?: ModuleInfo
    voucher?: VoucherInfo | null
    workTaskTruckData?: WorkTaskTruckData
    countryCode?: string
}

export type ComponentTemplate = {
    name: string
    props: any
}
export type NavigationState = {
    initialized: boolean
    loading: boolean
    items: NavigationItem[]
    splitPosition: number
    activeItem?: string
    afterLoad?: AfterLoadHook
    maxTabs: number
    itemsToRemove: string[]
}

const DEFAULT_STATE: NavigationState = {
    initialized: false,
    loading: false,
    splitPosition: -1,
    items: [],
    maxTabs: 10,
    itemsToRemove: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): NavigationState {
    switch (action.type) {
        case "INIT": {
            return {
                ...state,
                maxTabs: action.payload?.maxTabs || state.maxTabs,
            }
        }
        case "SET_AFTER_LOAD_HOOK": {
            return {
                ...state,
                afterLoad: action.payload,
            }
        }
        case "CHANGE_ITEM": {
            const activeItemIndex = state.items.findIndex((x) => x.id === action.payload.id)
            const activeItem = { ...(state.items[activeItemIndex] || {}), ...action.payload }

            const items = insertIntoArray(state.items, activeItemIndex, activeItem)

            state = ensureActiveInVisibleRange({
                ...state,
                items,
                activeItem: activeItem.id,
            })

            const itemsToRemove = state.items.splice(state.maxTabs).map((x) => x.id)
            state = {
                ...state,
                itemsToRemove,
            }

            saveState({
                [activeItem.id]: activeItem,
                __order__: state.items.map((x) => x.id).join(",") as any,
            })

            window.__NEXT_PAGE_NAVIGATION__ = state.items.map((x) => x.url)
            return state
        }
        case "CHANGE_MODULE_INFO": {
            const activeItemIndex = state.items.findIndex((x) => x.id === state.activeItem)
            const activeItem = state.items[activeItemIndex]
            if (activeItem) {
                const item = {
                    ...activeItem,
                    module: {
                        ...action.payload,
                        icon: action.payload.icon || (false as const),
                        view: action.payload.view || (false as const),
                        info: action.payload.info || (false as const),
                    },
                }
                const items = insertIntoArray(state.items, activeItemIndex, item)
                const itemsToRemove = items.splice(state.maxTabs).map((x) => x.id)
                state = {
                    ...state,
                    items,
                    itemsToRemove,
                }
                saveState({
                    [activeItem.id]: item,
                    __order__: state.items.map((x) => x.id).join(",") as any,
                })
            }

            window.__NEXT_PAGE_NAVIGATION__ = state.items.map((x) => x.url)
            return state
        }
        case "STATE_LOADING": {
            return {
                ...state,
                loading: true,
                initialized: true,
            }
        }
        case "STATE_LOADED": {
            const loadedItems = Object.keys(action.payload.state)
                .filter((x) => x !== "__order__")
                .map((key) => action.payload.state[key])
                .filter((x) => typeof x === "object") // Somehow the loaded items contained some keys with the value of: false
            const items = [...state.items.filter((x) => !loadedItems.some((y) => y.id === x.id)), ...loadedItems]
            const orderString: string = action.payload.state.__order__ as any
            const order: string[] = orderString?.split(",") || []
            items.sort((a, b) => {
                return order.indexOf(a.id) - order.indexOf(b.id)
            })
            const activeUrl = `${action.payload.history.location.pathname}${action.payload.history.location.search}`
            const activeItem = items.find((x) => activeUrl.indexOf(x.baseUrl) === 0)

            const itemsToRemove = items.splice(state.maxTabs).map((x) => x.id)

            window.__NEXT_PAGE_NAVIGATION__ = items.map((x) => x.url)
            return {
                ...state,
                loading: false,
                activeItem: activeItem && activeItem.id,
                items,
                itemsToRemove,
            }
        }
        case "REMOVE_ITEMS": {
            const closedItemsState = action.payload.reduce((previous, current) => {
                return {
                    ...previous,
                    [current]: false,
                }
            }, {})
            const items = state.items.filter((x) => !action.payload.some((y) => x.id === y))
            saveState({
                ...closedItemsState,
                __order__: items.map((x) => x.id).join(",") as any,
            })

            window.__NEXT_PAGE_NAVIGATION__ = items.map((x) => x.url)
            return {
                ...state,
                items,
                itemsToRemove: state.itemsToRemove.filter((x) => !action.payload.some((y) => y === x)),
            }
        }
        case "CHANGE_WORKTASK_ID": {
            const activeItemIndex = state.items.findIndex((x) => x.id === action.payload.id)
            const activeItem = state.items[activeItemIndex] || mapWorkTaskIdToNavigationItem(action.payload.id, action.payload.history)

            state = ensureActiveInVisibleRange({
                ...state,
                items: insertIntoArray(state.items, activeItemIndex, activeItem),
                activeItem: activeItem.id,
            })

            const itemsToRemove = state.items.splice(state.maxTabs).map((x) => x.id)

            window.__NEXT_PAGE_NAVIGATION__ = state.items.map((x) => x.url)
            return {
                ...state,
                itemsToRemove,
            }
        }
        case "CHANGE_URL": {
            const activeItemIndex = state.items.findIndex((x) => action.payload.indexOf(x.baseUrl) === 0)
            const activeItem = state.items[activeItemIndex]
            if (activeItem) {
                const items = insertIntoArray(state.items, activeItemIndex, {
                    ...activeItem,
                    url: action.payload,
                })
                state = ensureActiveInVisibleRange({
                    ...state,
                    activeItem: activeItem.id,
                    items,
                })
                const itemsToRemove = items.splice(state.maxTabs).map((x) => x.id)

                window.__NEXT_PAGE_NAVIGATION__ = state.items.map((x) => x.url)
                return {
                    ...state,
                    itemsToRemove,
                }
            }
            window.__NEXT_PAGE_NAVIGATION__ = state.items.map((x) => x.url)
            return {
                ...state,
                activeItem: undefined,
            }
        }
        case "SET_SPLIT_POSITION": {
            let items = [...state.items]
            let itemsToRemove = [...state.itemsToRemove]
            const activeItemIndex = items.findIndex((x) => x.id === state.activeItem)
            const activeItem = state.items[activeItemIndex]
            if (activeItem && action.payload !== -1 && activeItemIndex >= action.payload) {
                items = [activeItem, ...items.filter((x) => x.id !== activeItem.id)]
                itemsToRemove = items.splice(state.maxTabs).map((x) => x.id)
            }

            window.__NEXT_PAGE_NAVIGATION__ = items.map((x) => x.url)
            return {
                ...state,
                items,
                itemsToRemove,
                splitPosition: action.payload,
            }
        }
        case "SET_WORKTASK_TRUCK_DATA": {
            const foundItemIndex = state.items.findIndex((item) => item.id === action.payload.workTaskId)
            if (foundItemIndex >= 0) {
                const foundItem = state.items[foundItemIndex]
                if (foundItem && (!foundItem.workTaskTruckData || !equals(foundItem.workTaskTruckData, action.payload.workTaskTruckData))) {
                    const item: NavigationItem = {
                        ...foundItem,
                        workTaskTruckData: action.payload.workTaskTruckData,
                    }
                    saveState({
                        [item.id]: item,
                        // __order__: state.items.map(x => x.id).join(",") as any
                    })
                    return {
                        ...state,
                        items: state.items.map((x) => (x.id === item.id ? item : x)),
                    }
                }
            }
            break
        }
        default:
            return state
    }
    return state
}

export type IActions = typeof Actions

export const Actions = {
    init,
    subscribe,
    loadNavigation,
    setSplitPosition,
    closeTabs,
    setAfterLoadHook,
    setWorkTaskTruckData,
}

function loadNavigation(history: History): AsyncAction<ComponentActionType, NavigationState> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.initialized) {
            return
        }
        dispatch({ type: "STATE_LOADING" })
        ViewStateData.loadState<StorageLoadState>(STATE_SERVICE_KEY).then((viewState) => {
            dispatch({
                type: "STATE_LOADED",
                payload: {
                    state: viewState.value || {},
                    history,
                },
            })
            while (actionsAfterLoaded.length > 0) {
                actionsAfterLoaded.shift()!(dispatch)
            }
        })
    }
}

function ensureActiveInVisibleRange(state: NavigationState): NavigationState {
    const activeItemIndex = state.items.findIndex((x) => x.id === state.activeItem)
    const activeItem = state.items[activeItemIndex]
    if (state.splitPosition !== -1 && activeItem && (activeItemIndex >= state.splitPosition || activeItemIndex >= state.maxTabs)) {
        const items = [activeItem, ...state.items.filter((x) => x.id !== activeItem.id)]
        return {
            ...state,
            items,
        }
    }
    return state
}

function setSplitPosition(pos: number): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({
            type: "SET_SPLIT_POSITION",
            payload: pos,
        })
    }
}

function closeTabs(ids: Array<string>): ComponentActionType {
    channel("GLOBAL").publish("WORKTASK/CLOSED", { ids })
    return {
        type: "REMOVE_ITEMS",
        payload: ids,
    }
}

type StorageSaveState = {
    [id: string]: NavigationItem | false
}

type StorageLoadState = {
    [id: string]: NavigationItem
}

function saveState(value: StorageSaveState) {
    ViewStateData.saveState({ key: STATE_SERVICE_KEY, value }, true)
}

export function init(options?: { maxTabs?: number }) {
    return { type: "INIT", payload: options }
}

export function doWorkTaskLoaded(worktask: WorkTaskDetailsReadModel, history: History): ComponentActionType {
    return { type: "CHANGE_ITEM", payload: mapWorkTaskToNavigationItem(worktask, history) }
}

export function doModuleChanged(moduleInfo: ModuleInfo): ComponentActionType {
    return { type: "CHANGE_MODULE_INFO", payload: moduleInfo }
}

export function doWorkTaskIdChanged(id: string, history: History): ComponentActionType {
    return { type: "CHANGE_WORKTASK_ID", payload: { id, history } }
}

export function doPageOpened(page: PageInfo, history: History): ComponentActionType {
    return { type: "CHANGE_ITEM", payload: mapPageToNavigationItem(page, history) }
}

export function setAfterLoadHook(hook?: AfterLoadHook): ComponentActionType {
    return { type: "SET_AFTER_LOAD_HOOK", payload: hook }
}

export function setWorkTaskTruckData(payload: { workTaskId: string; workTaskTruckData: WorkTaskTruckData }): ComponentActionType {
    return { type: "SET_WORKTASK_TRUCK_DATA", payload }
}

export type DataType = "DEFAULT" | "TELESALES"

function insertIntoArray<T>(items: T[], index: number, insert: T) {
    return [...(index === -1 ? [] : items.slice(0, index)), insert, ...items.slice(index + 1)]
}
