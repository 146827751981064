import { ajax, getStoredAuthorization } from "@tm/utils"
import { getServiceUrl } from "."
import { Customers, Vehicles } from "../gateway"

export type ImportCustomerRequest = {
    workTaskId: string
    customer: Customers.Customer
    vehicle?: Vehicles.Vehicle
}

export function importCustomer(request: ImportCustomerRequest): Promise<void> {
    return ajax({
        method: "POST",
        url: `${getServiceUrl()}/connect/ImportCustomer`,
        authorization: getStoredAuthorization(),
        body: request,
    })
}
