import { Vehicle, VehicleLookupConfig, VehicleType } from "@tm/models"
import { Box, Image, selectClasses, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { VehicleLookupSelection } from "../../_shared/VehicleLookupSelection"
import { useSelectedVehicleLookup } from "../../../data/hooks/useSelectedLookupConfig"

const StyledVehicleLookupSelection = styled(VehicleLookupSelection)(({ theme }) => {
    const { palette, overwrites } = theme
    const { toolkits, components } = overwrites || {}
    const { top: themedTop } = theme.overwrites?.toolkits?.vehicle?.widget?.countrySelection?.select || {}
    const themedIconColor = toolkits?.vehicle?.widget?.countrySelection?.icon?.color || components?.button?.primary?.fontColor || palette.text.primary

    const lkqStyle = theme.name === "lkq" && {
        right: "0.2em",
        [`.${selectClasses.icon}`]: {
            color: themedIconColor,
        },
        ":hover": {
            ".MuiSelect-icon": {
                color: themedIconColor,
            },
        },
    }

    return {
        position: lkqStyle ? "absolute" : "relative",
        marginLeft: "5px",
        top: themedTop || undefined,
        ".multiwidget .vehicle-widget &": {
            position: "absolute",
            top: 0,
            right: 0,
        },
        ".multiwidget__content .vehicle-widget &": {
            position: "absolute",
            top: 0,
            right: 0,
        },
        ...lkqStyle,
    }
})

const StyledFlagBox = styled(Box)(({ theme }) => {
    const lkqStyle = theme.name === "lkq" && {
        right: "0.2em",
        top: "3px",
    }

    return {
        position: lkqStyle ? "absolute" : "relative",

        ".widget__collapse+.widget__inner &": {
            right: "3em",
        },
        ".multiwidget .vehicle-widget &": {
            position: "absolute",
            top: 0,
            right: 0,
        },
        ".multiwidget__content .vehicle-widget &": {
            position: "absolute",
            top: 0,
            right: 0,
        },
        ...lkqStyle,
    }
})

type CountrySelectorOrFlagProps = {
    vehicleType: VehicleType
    selectedVehicle?: Vehicle
    vehicleLookupConfigs?: VehicleLookupConfig[]
    selectedSearchConfig?: VehicleLookupConfig
    onSelectSearchConfig(config: VehicleLookupConfig): void
}

export function CountrySelectorOrFlag(props: CountrySelectorOrFlagProps) {
    const { vehicleType, selectedVehicle, vehicleLookupConfigs, onSelectSearchConfig, selectedSearchConfig } = props
    const { translateText } = useLocalization()
    const { selectableWithoutDuplicates } = useSelectedVehicleLookup(selectedVehicle?.vehicleType ?? vehicleType)
    const { countryCode } = selectedVehicle ?? {}

    function searchConfigSelectionHandler(vehicleSearchConfig: VehicleLookupConfig) {
        if (vehicleSearchConfig) {
            onSelectSearchConfig(vehicleSearchConfig)
        }
    }

    if (countryCode) {
        // Only if a vehicle is selected
        if (selectableWithoutDuplicates.length <= 1) {
            return null
        }

        return (
            <StyledFlagBox>
                <Image src={`/styles/base/images/flags/${countryCode.toLowerCase()}.png`} alt={countryCode} type="flag" />
            </StyledFlagBox>
        )
    }

    if (!vehicleLookupConfigs?.length) {
        return null
    }

    return (
        // For Vehicle LookupSelection Dropdown
        <StyledVehicleLookupSelection
            vehicleType={vehicleType}
            tooltipTitle={!countryCode ? translateText(13341) : undefined}
            onSelectSearchOption={searchConfigSelectionHandler}
            selectedSearchConfig={selectedSearchConfig}
            selectedVehicle={selectedVehicle}
        />
    )
}
