import { AvailabilityFilterType } from "../../../../business"
import { RimItem } from "../../../../data/model"
import { getBundleParams } from "../../../../utils"

export const filterRimList = (data: RimItem[], availability: AvailabilityFilterType) => {
    const { availabilityFilterItemsToShow, availabilityFilterItemsToShowSecondary } = getBundleParams()
    const rims = [...data]
    return React.useMemo(() => {
        if (availability) {
            const availableRims = data.flatMap(rim => {
                const availabilities = rim.rimErpInformations?.filter(info => {
                    let visible = false
                        if (availability === AvailabilityFilterType.Primary && availabilityFilterItemsToShow?.length) {
                            const mod = info.availability
                            if (mod && mod.type && availabilityFilterItemsToShow.includes(mod.type))
                                visible = true
                        }
                        else if (availability === AvailabilityFilterType.Secondary && availabilityFilterItemsToShowSecondary?.length) {
                            const mod = info.availability
                            if (mod && mod.type && availabilityFilterItemsToShowSecondary.includes(mod.type))
                                visible = true
                        }
                        return visible
                })
    
                const availableDiameterSizes = rim.sizeInfo.filter(sizeInfo => {
                    return availabilities.flatMap(item => item.diameterSize).includes(sizeInfo.size.toString())
                })
                return {...rim, rimErpInformations: availabilities, availableDiameterSizes: availableDiameterSizes}
            })
    
            return availableRims.filter(item => item.rimErpInformations.length > 0)
        }
        return rims
    }, [rims, availability])
}