import * as React from "react"
import { withRouter, renderRoute, createQueryString, parseQueryString, RouteComponentProps } from "@tm/utils"
import WheelsSelectionStepsComponent from "./components/wheels-selection-steps"
import { getMatchParamFromActiveStep } from "./business/helper"
import { WheelSelectionSteps } from "../../data/enums"
import { bundleChannel } from "../.."
import { PurchasePriceSwitch } from "../_shared"
import { useDispatch, useSelector } from "react-redux"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import { useLocalization } from "@tm/localization"
import ApplyButton from "../_shared/applyBtn"
import { Actions } from "./business"
import MountingSwitch from "../_shared/mountingSwitch"
import { Switch, Toolbar, Tooltip } from "@tm/controls"
import { useUser } from "@tm/context-distribution"
import OptionsSwitch from "../_shared/optionsSwitch"
import { Button } from "@tm/components"
import { getBundleParams } from "../../utils"

type RouteParams = {
    workTaskId: string,
    view: string
}

type NavigationComponentProps = RouteComponentProps<RouteParams> & {
    wheelsRoute: string
}

const NavigationComponent: React.FC<NavigationComponentProps> = ({ wheelsRoute, match, history }) => {

    const dispatch = useDispatch()
    const user = useUser()

    const { translateText } = useLocalization()
    const isHistoryActivated = false

    const { selectedTabs, activeStep } = useSelector((state: MainState) => ({
        selectedTabs: state.navigation.selectedTabs,
        activeStep: state.navigation.activeStep
    }))

    React.useEffect(() => {
        if (match.params.view != getMatchParamFromActiveStep(activeStep) && isHistoryActivated) { // onRefresh 
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.NONE, disableNextSteps: true }, false))
        }

        const unsubscribeSendTireToOverview = bundleChannel().subscribe("SEND_TIRE_ARTICLE_TO_OVERVIEW", ({ article }) => {
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.OVERVIEW }, true))
            dispatch(Actions.sendTireToOverview(article))
        })

        const unsubscribeSendRdksToTires = bundleChannel().subscribe("SEND_RDKS_ARTICLE_TO_OVERVIEW", ({ article }) => {
            dispatch(MainActions.sendSensorToOverview(article))
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.TIRESLIST }, true))
        })

        return () => {
            unsubscribeSendTireToOverview?.()
            unsubscribeSendRdksToTires?.()
        }

    }, [])

    React.useEffect(() => {
        if (activeStep in WheelSelectionSteps) {
            const routeParams = activeStep == WheelSelectionSteps.NONE ? { workTaskId: match.params.workTaskId, ...selectedTabs[activeStep]?.params, view: getMatchParamFromActiveStep(activeStep) } : { ...match.params, ...selectedTabs[activeStep]?.params, view: getMatchParamFromActiveStep(activeStep) }
            let url = renderRoute(wheelsRoute, routeParams)
            let queryParams: Record<string, any> = parseQueryString(location.search) ?? {}
            delete queryParams["initialQuantity"]

            if (url[url.length - 1] == "/")  // if last char is / it has to be removed -> Need for Module-Broadcast 
                url = url.substr(0, url.length - 1)

            if (activeStep == WheelSelectionSteps.NONE)
                history.replace(url + createQueryString(queryParams))
            else if (activeStep == WheelSelectionSteps.TIREDETAILS || activeStep == WheelSelectionSteps.RDKSDETAILS && selectedTabs[activeStep]?.quantity) {
                queryParams["initialQuantity"] = selectedTabs[activeStep]?.quantity
                history.push(url + createQueryString(queryParams))
            }
            else
                history.push(url + createQueryString(queryParams))
        }
    }, [activeStep])

    const handleNavigateTo = (step: WheelSelectionSteps) => {
        let quantity = undefined
        if (step == WheelSelectionSteps.TIREDETAILS) { quantity = selectedTabs[WheelSelectionSteps.TIREDETAILS]?.quantity }
        dispatch(MainActions.changeStep({ step, quantity }, false))
    }

    const handleTireDetailsSkip = () => {
        dispatch(MainActions.changeStep({ step: activeStep + 1 }, false))
    }
    const handleChangeCompactView = (compactView: boolean) => {
        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    compactView
                }
            })
        }
    }

    const handleChangeShowArticleImages = (showArticleImages: boolean) => {
        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    showArticleImages
                }
            })
        }
    }
    const renderArtListSettings = () => {
        return (
            <>
                <Toolbar className="tk-parts switch" title={translateText(794)}>
                    <Switch
                        status={user?.userSettings?.articleListSettings?.viewOptions?.compactView}
                        onChange={handleChangeCompactView}
                        alignLabel="left"
                    />
                </Toolbar>
                <Toolbar className="tk-parts switch" title={translateText(795)}>
                    <Switch
                        status={user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages}
                        onChange={handleChangeShowArticleImages}
                        alignLabel="left"
                    />
                </Toolbar>
            </>
        )
    }

    const renderOptions = () => {
        if (getBundleParams()?.priceAvailabilityInformation && ["wheels-details", "wheels-list"].includes(match.params.view)) {
            return (
                <Toolbar title={translateText(222)}>
                    <Tooltip style="light" event="click" content={<OptionsSwitch />}>
                        <Button variant="outlined" style={{color: "gray"}}> {translateText(361)} </Button>
                    </Tooltip>
                </Toolbar>
            )
        }

        if(!["vehicle-selection"].includes(match.params.view)){
            return (
                <PurchasePriceSwitch />
            )
        }
    }

    if (!match.params.view)
        return null

    return (
        <div className="tk-wheels__navigation" id="wheels-navigation" style={{ minHeight:"3em" }}>
            <WheelsSelectionStepsComponent
                key={"wheels_steps"}
                onChange={handleNavigateTo}
            />
            <DetailsSkipBtn
                onStepSkip={handleTireDetailsSkip}
            />
            {renderOptions()}
            {["tyres-list", "rdks-list"].includes(match.params.view) &&
                <>
                    {renderArtListSettings()}
                    <MountingSwitch />
                </>
            }
        </div>
    )
}

export default withRouter(NavigationComponent)

const DetailsSkipBtn: React.FC<{ onStepSkip: () => void }> = ({ onStepSkip: handleSkip }) => {

    const { activeStep } = useSelector((s: MainState) => ({
        activeStep: s.navigation.activeStep
    }))

    const { translateText } = useLocalization()

    return (
        <>
            {activeStep == WheelSelectionSteps.TIREDETAILS &&
                <ApplyButton
                    showSkipBtn
                    onSkip={handleSkip}
                />
            }
            {activeStep == WheelSelectionSteps.RDKSDETAILS &&
                <ApplyButton
                    showSkipBtn
                    onSkip={handleSkip}
                    skipBtnText={translateText(1117)}
                />
            }
        </>
    )
}