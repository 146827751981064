import { Box, Icon, List, ListItemButton, Loader, Typography, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getModuleGroupIconName, getModuleGroupTextId, useGetActivatableModules } from "@tm/utils"
import { useMemo } from "react"
import { useHistory } from "react-router"

type Props = {
    height?: number
    size?: string
    className?: string
    showWidget?: boolean
}

export default function DemoModuleWidget(props: Props) {
    const { height, size, className, showWidget } = props
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const history = useHistory()
    const activatableModules = useGetActivatableModules(userContext)

    const loading = useMemo(() => !userContext || activatableModules.isLoading, [userContext, activatableModules])

    if (!showWidget) {
        return null
    }

    function handleClick() {
        history.push("/usersettings/module")
    }

    const renderContent = () => {
        if (loading) {
            return (
                <Box display="flex" alignItems="center" width="100%" height="100%">
                    <Loader />
                </Box>
            )
        }

        return (
            <Box display="flex" width="100%" height="100%" gap="0.5em" flexDirection="column">
                {!activatableModules.response && <Typography>{translateText(286)}</Typography>}
                {activatableModules.response && <Typography variant="body3">{`*${translateText(12873)}`}</Typography>}
                <List sx={{ display: "flex", gap: "0.2em", flexDirection: "column" }}>
                    {activatableModules.response?.moduleGroups?.map((moduleGroup) => (
                        <ListItemButton key={moduleGroup.id} sx={{ padding: 0 }}>
                            <StyledBox>
                                <Icon height="1em" width="1em" name={getModuleGroupIconName(moduleGroup.id)} />
                                <Typography variant="h4">{getModuleGroupTextId(moduleGroup.id, translateText)}</Typography>
                            </StyledBox>
                        </ListItemButton>
                    )) ?? null}
                </List>
            </Box>
        )
    }

    return (
        <Widget size={size as any} height={height} className={className} active iconName="modules" title={translateText(1832)} onClick={handleClick}>
            {renderContent()}
        </Widget>
    )
}

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "0.5em",
    flex: "1",
    padding: "1em",
    border: "1px solid #b4b4b4",
    borderRadius: "3px",
})
