import { ShowConfigResponse, Vehicle, VehicleType } from "@tm/models"
import { FC, useEffect, useMemo } from "react"

import { useModelDetailsWithoutCache } from "../../data/hooks/modelDetailsWithoutCache"
import { enhanceVehicle } from "../../data/mapper"
import { useOrderedPartsByVehicle, useRegNoAndType } from "./business/hooks"
import { DetailsProps } from "./component"
import DetailsComponent from "./Details"

type Props = DetailsProps & {
    vehicleType: VehicleType
    vehicle?: Vehicle
    workTaskId?: string
    isWorkTaskVehicle?: boolean
    availableVehicleSearches?: ShowConfigResponse
}

export const DetailsByVehicle: FC<Props> = (props) => {
    const { vehicle, location, availableVehicleSearches, vehicleType, actions } = props

    const { regNo, regNoType } = useRegNoAndType(
        vehicle?.vehicleType ?? vehicleType,
        vehicle,
        location.search,
        availableVehicleSearches?.defaultVehicleLookup
    )
    const { modelDetails, error, loadModelDetails, clearConflict } = useModelDetailsWithoutCache(vehicle, vehicleType, regNoType, regNo)
    const orderedParts = useOrderedPartsByVehicle(vehicle?.id)

    useEffect(() => {
        actions.loadArticleList(orderedParts, vehicle?.tecDocTypeId)
    }, [orderedParts, vehicle?.tecDocTypeId])

    const enhancedVehicle = useMemo(() => {
        if (!vehicle || !modelDetails) {
            return vehicle
        }

        return enhanceVehicle(vehicle, modelDetails.modelDetails?.registrationNoDetails, regNoType || vehicle.registrationTypeId)
    }, [vehicle, modelDetails, regNoType])

    return (
        <DetailsComponent
            {...props}
            vehicle={enhancedVehicle}
            modelDetails={modelDetails}
            isGsiVehicle
            error={error}
            orderedParts={orderedParts}
            reload={loadModelDetails}
            clearVehicleConflict={clearConflict}
        />
    )
}
