import { create } from "zustand"
import { devtools, persist, createJSONStorage } from "zustand/middleware"
import _ from "lodash"
import { isDevtoolEnabled } from "@tm/utils"
import { zustandSessionStorage } from "./sessionStorage"
import { replacer, reviver } from "./sessionStorage/helper"
import * as Repositories from "./repositories"
import { createManagerSlice, createStepNavigationSlice, ManagerActions, ManagerSlice, StepNavigationActions, StepNavigationSlice } from "./state"
import { createEndPageSlice, EndPageActions, EndPageSlice } from "./state/end-page"
import { createInspectionWorkSlice, InspectionWorkActions, InspectionWorkSlice } from "./state/inspection-work"
import { createMaintenancePlanSlice, MaintenancePlanActions, MaintenancePlanSlice } from "./state/maintenance-plan"
import { createNavigationSlice, NavigationActions, NavigationSlice } from "./state/navigation"
import { createTechnicianSlice, TechnicianActions, TechnicianSlice } from "./state/technician"
import { createTestDriveSlice, TestDriveActions, TestDriveSlice } from "./state/test-drive"
import { createTesterSlice, TesterActions, TesterSlice } from "./state/tester"
import { createWorksSlice, WorksActions, WorksSlice } from "./state/works"
import { createMaintenanceReviewSlice, MaintenanceReviewSlice } from "./state/maintenance-review"
import { createTyresWheelsSlice, TyresWheelsActions, TyresWheelsSlice } from "./state/tyres-wheels"

export { Repositories }
export * from "./stateHelpers"

export type FastServiceStore = MaintenancePlanSlice &
    StepNavigationSlice &
    ManagerSlice &
    EndPageSlice &
    InspectionWorkSlice &
    TestDriveSlice &
    TesterSlice &
    NavigationSlice &
    TechnicianSlice &
    WorksSlice &
    MaintenanceReviewSlice &
    TyresWheelsSlice & {
        setStateFromHistory: (historyState: FastServiceStoreState) => void
    }

export type FastServiceStoreActions = StepNavigationActions &
    MaintenancePlanActions &
    ManagerActions &
    EndPageActions &
    InspectionWorkActions &
    TestDriveActions &
    TesterActions &
    NavigationActions &
    TechnicianActions &
    WorksActions &
    MaintenancePlanActions &
    TyresWheelsActions

type ExcludeActions<T> = { [K in keyof T]: T[K] extends FastServiceStoreActions ? never : K }[keyof T]

export type FastServiceStoreState = Omit<FastServiceStore, ExcludeActions<FastServiceStoreActions>>

export const useFastServiceStore = create<FastServiceStore>()(
    devtools(
        persist(
            (set, get, ...a) => ({
                ...createStepNavigationSlice(set, get, ...a),
                ...createMaintenancePlanSlice(set, get, ...a),
                ...createManagerSlice(set, get, ...a),
                ...createEndPageSlice(set, get, ...a),
                ...createInspectionWorkSlice(set, get, ...a),
                ...createTestDriveSlice(set, get, ...a),
                ...createTesterSlice(set, get, ...a),
                ...createNavigationSlice(set, get, ...a),
                ...createTechnicianSlice(set, get, ...a),
                ...createWorksSlice(set, get, ...a),
                ...createMaintenanceReviewSlice(set, get, ...a),
                ...createTyresWheelsSlice(set, get, ...a),
                setStateFromHistory: (historyState: FastServiceStoreState) => {
                    if (historyState.version === get().version) {
                        set(
                            (state) => {
                                return { ..._.merge(getPureFastServiceState("actions", state), historyState) }
                            },
                            false,
                            "Set state from history"
                        )
                    }
                },
            }),
            {
                name: "FastServiceStore",
                skipHydration: true,
                storage: createJSONStorage(() => zustandSessionStorage, { replacer, reviver }),
                partialize: (state) => getPureFastServiceState("state", state),
                merge: (persistedState, currentState) => _.merge(currentState, persistedState),
            }
        ),
        { name: "Fast Service Store", enabled: isDevtoolEnabled() }
    )
)

export function getPureFastServiceState<T extends "state" | "actions">(
    mode: T,
    storeState?: FastServiceStore
): T extends "state" ? FastServiceStoreState : FastServiceStoreActions {
    const wholeApplicationState = storeState ?? useFastServiceStore.getState()

    if (mode === "state") {
        const stateKeys = Object.keys(wholeApplicationState).filter((k) => !k.endsWith("Actions"))
        return _.pick(wholeApplicationState, stateKeys || !_.isFunction)
    }

    const stateKeys = Object.keys(wholeApplicationState).filter((k) => k.endsWith("Actions"))
    return _.pick(wholeApplicationState, stateKeys || _.isFunction)
}

export const {
    maintenancePlanActions,
    maintenanceReviewActions,
    tyresWheelsActions,
    worksActions,
    stepNavigationActions,
    testDriveActions,
    navigationActions,
    testerActions,
    technicianActions,
} = useFastServiceStore.getState()
