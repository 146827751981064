import { Article, ErpInformation, SupplierArticleDto, DefaultArticleItemState, channel } from "@tm/models"
import { isEqual } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import AvailabilityComponent from "../../../../../../erp/src/_shared/availability-wrapper"
import { useArticleListActions } from "../../ArticleListActions"
import { usePartsModuleState } from "../../PartsModuleState"
import { useLoadErpInfos } from "../useErpInfos"
import { useHandleClickAttribute } from "../useHandleClickAttribute"
import { useHandleClickReference } from "../useHandleClickReference"
import { useArticleActions } from "./useArticleActions"
import { useArticleNotes } from "./useArticleNotes"
import { useArticleNotifications } from "./useArticleNotifications"
import { useArticleOptions } from "./useArticleOptions"
import { useArticleTradeReferences } from "./useArticleTradeReferences"
import { useArticleErpInfos } from "./useArticlesErpInfos"
import { useDisplayArticle } from "./useDisplayArticle"
import { useHandleAddToBasket } from "./useHandleAddToBasket"
import { useHandleAddToCostEstimation } from "./useHandleAddToCostEstimation"
import { useHandleClickDetails } from "./useHandleClickDetails"
import { useHandleClickImage } from "./useHandleClickImage"
import { useHandleRemoveFromBasket } from "./useHandleRemoveFromBasket"
import { useTraderErpInfos } from "./useTraderErpInfos"
import { useWatchlist } from "./useWatchlist"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { useArticleFoundBy } from "./useArticleFoundBy"
import { useArticleSelection } from "../useArticleSelection"
import { useArticleExtendedDeliveryInfo } from "./useArticleExtendedDeliveryInfo"
import { useVehicle } from "../useVehicle"
import { useArticleImages } from "../useArticleImages"

export function useArticleItem(article: Article): DefaultArticleItemState {
    const toggleAttribute = usePartsModuleState((x) => x.params.toggleAttribute)
    const attributes = usePartsModuleState((x) => x.params.attributes)

    const options = useArticleOptions(article)

    const [expanded, setExpanded] = useState(!options.compactView)
    const [traderSelection, setTraderSelection] = useState(false)

    useEffect(() => {
        setExpanded(!options.compactView)
    }, [options.compactView])

    const toggleExpand = useCallback(() => {
        setExpanded((prev) => !prev)
    }, [])

    const toggleTraderSelection = useCallback(() => {
        setTraderSelection((prev) => !prev)
    }, [])

    const positionChangeEArtNrHArtNr = useArticleListConfiguration((x) => x.positionChangeEArtNrHArtNr)
    const imsIcon = useArticleListConfiguration((x) => x.imsIcon)
    const listType = usePartsModuleState((x) => x.type)
    const replaceButtonBundle = usePartsModuleState((x) => x.params.startParams.replaceButtonBundle)
    const basketQuantities = usePartsModuleState((x) => x.basketQuantities)
    const vehicleRecords = usePartsModuleState((x) => (x.type === "vehicle" ? x.vehicleRecords : undefined))
    const vehicle = useVehicle()
    const { updateBasketQuantities, toggleSupplier: toggleArticleNotes } = useArticleListActions()
    const loadErpInfos = useLoadErpInfos()
    const displayArticle = useDisplayArticle(article, attributes)
    const [articleQuantity, setArticleQuantity] = useState(article.quantity)
    const [originalQuantity, setOriginalQuantity] = useState(article.quantity)
    const articleErpInfos = useArticleErpInfos(article, articleQuantity)
    const defaultArticleErpInfoResponse = articleErpInfos.default?.state === "success" ? articleErpInfos.default.response : undefined
    const handleClickDetails = useHandleClickDetails(article, articleQuantity)
    const { handleClickImage, handleCloseImage, showImageViewer } = useHandleClickImage(article)
    const handleClickAttribute = useHandleClickAttribute(article, handleClickDetails, toggleAttribute)
    const handleClickReference = useHandleClickReference(article, articleQuantity)
    const addToBasket = useHandleAddToBasket(article)
    const addToKva = useHandleAddToCostEstimation(article, addToBasket)
    const removeFromBasket = useHandleRemoveFromBasket()
    const articleImages = useArticleImages(article)
    const notes = useArticleNotes(article)
    const articleActions = useArticleActions(article, handleClickDetails, articleErpInfos.default, notes.notes, notes.toggle, toggleExpand)
    const tradeReferences = useArticleTradeReferences(article)
    const traderErpInfos = useTraderErpInfos(article, tradeReferences)
    const notifications = useArticleNotifications(articleErpInfos.default)
    const watchList = useWatchlist(article)
    const foundBy = useArticleFoundBy(article)
    const selection = useArticleSelection()
    const extendedDeliveryInfo = useArticleExtendedDeliveryInfo(articleErpInfos.default)

    const basketQuantity = useMemo(
        () =>
            basketQuantities.find((q) =>
                isEqual(q.article, {
                    dataSupplierArticleNumber: article.supplierArticleNo,
                    dataSupplierId: article.supplier.id,
                    productGroupId: article.productGroup.id,
                } as SupplierArticleDto)
            ),
        [basketQuantities, article]
    )

    const postAddToBasket = useCallback(async () => {
        updateBasketQuantities([article])
        await vehicleRecords?.handleArticleAddedToBasket(article)
        vehicleRecords?.refresh()
    }, [article, vehicleRecords, updateBasketQuantities])

    const handleAddToBasket = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            if (!erpSystemId && (tradeReferences?.length ?? 0) > 1) {
                toggleTraderSelection()
                return
            }

            await addToBasket(quantity ?? articleQuantity, erpInformation ?? defaultArticleErpInfoResponse, erpSystemId)
            postAddToBasket()
        },
        [addToBasket, articleQuantity, defaultArticleErpInfoResponse, postAddToBasket, toggleTraderSelection, tradeReferences?.length]
    )

    const handleReplaceArticle = useCallback(() => {
        if (!replaceButtonBundle) {
            return
        }
        channel("GLOBAL").publish("PARTS/REPLACE_PART", { part: article, isNewList: true, bundle: replaceButtonBundle })
    }, [article, replaceButtonBundle])

    const handleAddToCostEstimation = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            if (!erpSystemId && (tradeReferences?.length ?? 0) > 1) {
                toggleTraderSelection()
                return
            }
            await addToKva(quantity ?? articleQuantity, erpInformation ?? defaultArticleErpInfoResponse, erpSystemId)
            postAddToBasket()
        },
        [addToKva, articleQuantity, defaultArticleErpInfoResponse, postAddToBasket, toggleTraderSelection, tradeReferences]
    )

    const handleRemoveFromBasket = useCallback(
        async (itemIds?: string[]) => {
            const ids = itemIds ?? basketQuantity?.articleQuantities?.allPartItemIds
            if (ids?.length) {
                await removeFromBasket(ids)
                updateBasketQuantities([article])
            }
        },
        [basketQuantity, removeFromBasket, updateBasketQuantities, article]
    )

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setArticleQuantity(value)

            if (loadErpInfo) {
                setOriginalQuantity(value)
                loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.default?.erpSystem })
                if (articleErpInfos.alternative) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }
        },
        [article, loadErpInfos, articleErpInfos]
    )

    const toggleSupplierFilter = useCallback(
        (exclusive?: boolean) => {
            toggleArticleNotes(article.supplier.id, exclusive)
        },
        [toggleArticleNotes, article.supplier.id]
    )

    return {
        type: "default",
        isVehicleDependent: listType === "vehicle",
        options,
        article,
        displayArticle,
        quantity: articleQuantity,
        originalQuantity,
        articleActions,
        articleErpInfos,
        basketQuantity,
        notes,
        tradeReferences,
        traderErpInfos,
        notifications,
        expanded,
        traderSelection,
        watchList,
        showReplaceButton: !!replaceButtonBundle,
        positionChangeEArtNrHArtNr,
        foundBy,
        selection,
        extendedDeliveryInfo,
        vehicle,
        showImageViewer,
        articleImages,
        imsIcon,

        handleClickImage,
        handleCloseImage,
        handleClickDetails,
        handleChangeQuantity,
        handleClickReference,
        handleClickAttribute: listType !== "direct" ? handleClickAttribute : undefined,
        handleAddToBasket,
        handleAddToCostEstimation,
        handleRemoveFromBasket,
        handleReplaceArticle,
        toggleExpand,
        toggleTraderSelection,
        toggleSupplierFilter,

        AvailabilityComponent,
    }
}
