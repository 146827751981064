import { encodeUniqueId, getShortCountryCodeByUserContext } from "@tm/utils"
import { History } from "history"
import { PageInfo, WorkTaskDetailsReadModel, VehicleDetailsReadModel, CustomerDetailsReadModel } from "@tm/models"
import { getLocalization } from "@tm/localization"
import { NavigationItem, VehicleInfo, ComponentTemplate, DataType, CustomerInfo, VoucherInfo } from "."

export function mapWorkTaskIdToNavigationItem(id: string, history: History): NavigationItem {
    return {
        id,
        baseUrl: `/${encodeUniqueId(id)}`,
        url: history.location.pathname + history.location.search,
        // title: "",
    }
}

export function mapWorkTaskToNavigationItem(worktask: WorkTaskDetailsReadModel, history: History): NavigationItem {
    // Fallback null, because undefined won't be included in the json sent to the service.
    // Because the old and new json is merged in the service, the values wouldn't be updated.
    const vehicle: VehicleInfo | null = worktask.vehicle
        ? {
              plateId: worktask.vehicle.plateId || null,
              countryCode: worktask.vehicle.countryCode,
              displayName: getVehicleDisplayName(worktask.vehicle),
          }
        : null
    const customer: CustomerInfo | null = worktask.customer
        ? {
              displayName: getCustomerDisplayName(worktask.customer),
              customerNo: worktask.customer.customerNo || null,
              refCustomerNo: worktask.customer.refCustomerNo || null,
              displayCustomerNo: worktask.customer.displayCustomerNo || null,
          }
        : null
    const voucher: VoucherInfo | null = worktask.voucherNo
        ? {
              type: worktask.voucherType?.description,
              number: worktask.voucherNo,
          }
        : null

    return {
        id: worktask.workTaskId,
        baseUrl: `/${encodeUniqueId(worktask.workTaskId)}`,
        url: history.location.pathname + history.location.search,
        vehicle,
        customer,
        voucher,
    }
}

export function mapPageToNavigationItem(page: PageInfo, history: History): NavigationItem {
    const id = page.id || history.location.pathname.replace("/", "")
    const url = page.url || `${history.location.pathname}${history.location.search}`
    return {
        id,
        baseUrl: url,
        url,
        page,
    }
}

function getVehicleDisplayName(vehicle: VehicleDetailsReadModel) {
    const tokens = [vehicle.manufacturer, removeBrackets(vehicle.modelSeries), removeBrackets(vehicle.model)]
    return tokens.join(" ")
}

function getCustomerDisplayName(customer: CustomerDetailsReadModel) {
    const tokens = []
    if (customer.companyName?.trim()) {
        return customer.companyName
    }
    if (customer.firstName && customer.firstName.length) {
        tokens.push(`${customer.firstName.substr(0, 1)}.`)
    }
    if (customer.lastName && customer.lastName.length) {
        tokens.push(customer.lastName)
    }
    if (tokens.length) {
        return tokens.join(" ")
    }
    return ""
}

function removeBrackets(value?: string): string | undefined {
    if (!value) {
        return
    }
    const regex = /^(.*?)(?:\(.*?\))?$/
    const match = regex.exec(value)
    if (!match) {
        return value
    }
    return match[SUBTITLE_KEY]
}

export function parseAndTranslateText(text: string) {
    const { translate } = getLocalization()
    return text.replace(/\{\{(.*?)\}\}/g, (sub) => translate(sub) as string)
}

const TITLE_KEY = 0
const SUBTITLE_KEY = 1

type NavigationItemContent = {
    content: string
    template?: ComponentTemplate
}

export function getContentComponentsFromItem(item: NavigationItem, dataType: DataType = "DEFAULT"): [NavigationItemContent, NavigationItemContent] {
    if (dataType == "TELESALES") {
        return [{ content: item.customer?.displayName || "-" }, { content: item.customer?.displayCustomerNo || item.customer?.refCustomerNo || "" }]
    }
    if (item.page) {
        return [{ content: item.page.title }, { content: item.page.subtitle || "" }]
    }
    if (item.voucher) {
        return [
            { content: `${item.voucher.type} ${item.voucher.number}` },
            handleChain(item, [getCustomerContent, getVehicleContent, getModuleViewContent]),
        ]
    }
    if (item.vehicle?.plateId) {
        return [
            { content: item.vehicle.plateId, template: getPlateTemplate(item) },
            handleChain(item, [getCustomerContent, getVehicleContent, getModuleViewContent]),
        ]
    }
    if (item.customer?.displayName) {
        return [{ content: item.customer.displayName }, handleChain(item, [getVehicleContent, getVoucherContent, getModuleViewContent])]
    }
    if (item.vehicle) {
        return [{ content: item.vehicle.displayName }, handleChain(item, [getVoucherContent, getModuleViewContent])]
    }
    if (item.workTaskTruckData?.plateNr) {
        return [{ content: item.workTaskTruckData.plateNr, template: getPlateTemplate(item) }, { content: item.workTaskTruckData.customerName || "" }]
    }
    if (item.module?.view) {
        return [{ content: parseAndTranslateText(item.module.view) }, handleChain(item, [getModuleInfoContent])]
    }
    return [{ content: getLocalization().translate(165, false) as string }, { content: "" }]
}

function handleChain(item: NavigationItem, chain: ChainHandler[]) {
    for (let i = 0; i < chain.length; i++) {
        const handler = chain[i]
        const result = handler(item)
        if (result) {
            return result
        }
    }
    return { content: "" }
}

type ChainHandler = (item: NavigationItem) => NavigationItemContent | undefined
function getCustomerContent(item: NavigationItem) {
    if (item.customer?.displayName) {
        return { content: item.customer.displayName }
    }
}

function getVehicleContent(item: NavigationItem) {
    if (item.vehicle) {
        return { content: item.vehicle.displayName }
    }
}
function getModuleViewContent(item: NavigationItem) {
    if (item.module?.view) {
        return { content: parseAndTranslateText(item.module.view) }
    }
}
function getModuleInfoContent(item: NavigationItem) {
    if (item.module?.info) {
        return { content: parseAndTranslateText(item.module.info) }
    }
}
function getVoucherContent(item: NavigationItem) {
    if (item.voucher) {
        return { content: `${item.voucher.type} ${item.voucher.number}` }
    }
}

function getPlateTemplate(item: NavigationItem): ComponentTemplate {
    const countryCode = item.vehicle?.countryCode || item.countryCode || ""
    return { name: "PlateId", props: { countryCode } }
}
