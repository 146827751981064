import { useEffect, useMemo } from "react"
import { UniversalPartsListParams, UniversalPartsModuleState } from "../../../models"
import { getArticleGroups } from "../../../helpers"
import { useArticlesByNodeOrProductGroups } from "./useArticlesByNodeOrProductGroups"
import { useArticlesByQuery } from "./useArticlesByQuery"
import { useDisplayFilter } from "../../../hooks/useDisplayFilter"

/**
 * Get information about the current article data of the universal parts module.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useArticles(params: UniversalPartsListParams, isEnabled: boolean): UniversalPartsModuleState["articles"] {
    const {
        startParams,
        sorting: { activateDefault: activateDefaultSorting },
    } = params

    const articlesByNodeOrProductGroups = useArticlesByNodeOrProductGroups(params, isEnabled)
    const articlesByQuery = useArticlesByQuery(params, isEnabled)

    const articlesBase = useMemo(() => {
        switch (startParams.type) {
            case "uninode":
            case "uniproductgroups":
                return articlesByNodeOrProductGroups
            case "unisearch":
            default:
                return articlesByQuery
        }
    }, [articlesByNodeOrProductGroups, articlesByQuery, startParams.type])

    const { isSuccess, articles, articleCount, pageCount, hasNextPage } = articlesBase

    const articleGroups = useMemo(() => getArticleGroups(articles, hasNextPage), [articles, hasNextPage])

    useEffect(
        function setNoResult() {
            if (isSuccess && !articleCount && pageCount === 1) {
                params.setNoResult(true)
            }
        },
        [isSuccess]
    )

    useEffect(() => {
        if (isSuccess) {
            activateDefaultSorting(articleCount, pageCount, articleGroups)
        }
    }, [activateDefaultSorting, articleCount, articleGroups, isSuccess, pageCount])

    const displayFilter = useDisplayFilter(articleGroups, params)

    return {
        ...articlesBase,
        isStalling: displayFilter.stalling && pageCount === 1,
        isFrontendFiltered: displayFilter.isFrontendFiltered,
        articleGroups: displayFilter.articleGroups,
    }
}
