import { IMicros, OePart } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { equals } from "@tm/utils"
import { useSelector } from "react-redux"
import { Box } from "@tm/components"
import { ReplaceButton } from "@tm/controls"
import { Part } from "../../../data/models"
import { vehicleSelector } from "../../main/business"

type Props = {
    alternativePart: Part
    oeArticle: OePart
    onSelect(): void
    onDetailsClick(article: Part): void
    onAccesoriesClick(article: Part): void
    selectedPart?: Part
}

export default function PartItem({ alternativePart, oeArticle, selectedPart, onSelect, onDetailsClick }: Props) {
    const vehicle = useSelector(vehicleSelector)
    const { renderMicro } = useMicro<IMicros>()

    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    const renderBasketButton = () => (
        <Box margin=".1725em" marginLeft="0" marginRight=".25em">
            <Box className="add-to-basket" sx={{ marginRight: "1em" }}>
                <ReplaceButton isWm={isWm} onClick={onSelect} />
            </Box>
        </Box>
    )

    const renderOeArticle = () => (
        <>
            {renderMicro("parts", "oe-part-item", {
                oePart: {
                    description: oeArticle.description,
                    manufacturerName: vehicle?.manufacturer,
                    manufacturerId: vehicle?.tecDocManufacturerId,
                    number: oeArticle.oeArticleNumber,
                    prices: [
                        {
                            value: oeArticle.oePriceValue ?? 0,
                            currency: "EUR",
                        },
                    ],
                },
                renderBasketButton,
                hiddenFakeActions: { costEstimation: true, selector: true, moreMenu: true, details: true },
            })}
        </>
    )

    const renderPart = () => (
        <>
            {renderMicro("parts", "part-item", {
                partToLoad: {
                    productGroupId: alternativePart.article?.productGroup.id ?? alternativePart.productGroupId,
                    supplierArticleNo: alternativePart.article?.supplierArticleNo ?? alternativePart.dataSupplierArticleNumber,
                    supplierId: alternativePart.article?.supplier.id ?? alternativePart.dataSupplierId,
                    tecdocTypeNo: vehicle?.tecDocTypeId,
                    quantity: alternativePart.quantityValue,
                },
                renderBuyActions: renderBasketButton,
                onRequestArticleDetails: () => onDetailsClick(alternativePart),
                showActions: true,
                showArticleImage: true,
                canFilterArticleAttributes: false,
            })}
        </>
    )

    if (equals(selectedPart, alternativePart)) {
        return renderOeArticle()
    }

    return renderPart()
}
