import { RimErpInfo } from "../../../data/model"
import { Box, Loader, styled } from "@tm/components"
import { rem } from "csx"
import { useActions, useMicro } from "@tm/morpheus"
import { Actions } from "../business"
import { createSelector } from "reselect"
import { MainState } from "../../main"
import { useSelector } from "react-redux"
import { useUser } from "@tm/context-distribution"
import { IMicros } from "@tm/models"
import { getBundleParams } from "../../../utils"

type Props = {
    informations: RimErpInfo[]
    onSelect: (rimErpInfo: RimErpInfo) => void
}

const selector = createSelector((s: MainState) => ({
    selectedRimSize: s.wheelsList.base.selectedRimSize
}), x => x)

export default function StockTable({ informations, onSelect }: Props) {
    const actions = useActions(Actions, "saveSelectedRimSize")
    const { selectedRimSize } = useSelector(selector)
    const showPurchasePrice = useUser().userSettings?.showPurchasePrice
    const { renderMicro } = useMicro<IMicros>()
    
    const renderAvailabilityIcon = React.useCallback((info: RimErpInfo) => {
        if (!info.availability?.type) return

        return renderMicro("erp", "display-only-availability", { availability: info.availability, warehouses: info.warehouses, showSmallIcons: true })
    }, [informations.length])

    const renderPrice = React.useCallback((info: RimErpInfo) => {
        const purchasePrice = info.prices?.[0]
        const retailPrice = info.prices?.[1]

        if (!purchasePrice && !retailPrice) {
            return
        }

        const price = showPurchasePrice ? purchasePrice : retailPrice
        return <div>{price.value?.toFixed(2) + " " + price.currencySymbol}</div>

    }, [informations, showPurchasePrice])

    const handleSelect = (info: RimErpInfo) => {
        onSelect(info)
        actions.saveSelectedRimSize(info)
    }
    
    return (
        <Box pl={0.5} pr={0.5}>
            {informations.map((info: RimErpInfo, index: number) => {
                return (
                    <StyledRow key={index} isSelected={info == selectedRimSize} onClick={() => handleSelect(info)}>
                        <Box> {info.description} </Box>
                        {info.loading && <Loader size="small" sx={{justifyContent: "end"}}/>}
                        <StyledBox> {renderAvailabilityIcon(info)} </StyledBox>
                        <Box textAlign={"right"}> {renderPrice(info)} </Box>
                    </StyledRow> 
                )
            })}
        </Box>
    )
}

const StyledRow = styled(Box)<{isSelected: boolean}>(({ theme, isSelected }) => ({
    display: "grid",
    gridTemplateColumns: "1.8fr 0.8fr 1.4fr",
    justifyContent: "space-between",
    border: `solid ${theme.margin?.xs} transparent`,
    padding: theme.margin?.s,
    fontSize: theme.font?.textSize.s,
    height: rem(1.8),
    ":hover": {
        border: `solid ${theme.margin?.xs} ${theme.colors?.primary}`,
        borderRadius: theme.radius?.default
    },
   ...isSelected && {
        border: `solid ${theme.margin?.xs} ${theme.colors?.primary}`,
        borderRadius: theme.radius?.default
    },
    ":nth-of-type(even)": {
        background: "#efefef"
    }
}))

const StyledBox = styled(Box)(() => ({
    textAlign: "center",
    "& .image":{
        maxHeight: rem(1.3)
    },
    ".availability--has-image .availability__image": {
        maxHeight: rem(1.3)
    }
}))