import { clone, equals } from "@tm/utils"
import { useSelector } from "react-redux"
import { Box } from "@tm/components"
import { ICalculationItem, Part } from "../../../data/models"
import { MainState } from "../../main"
import { OeArticleItem, PartItem } from "."

type Props = {
    item: ICalculationItem
    onOeArticleSelect(): void
    onOeArticleQuantity(quantity: number): void
    onPartSelect(part: Part): void
    onDetailsClick(article: Part): void
    onAccesoriesClick(article: Part): void
    onAlternativeClick(item: ICalculationItem): void
}

export default function CalculationItem({
    item,
    onOeArticleSelect,
    onOeArticleQuantity,
    onPartSelect,
    onDetailsClick,
    onAccesoriesClick,
    onAlternativeClick,
}: Props) {
    const collapsableItems = useSelector((s: MainState) => s.calculation.collapsableItems)

    let alternatives = clone(item.alternativeParts)
    const index = alternatives?.findIndex((x) => equals(x, item.selectedPart))

    if (item.selectedPart && index > 0) {
        alternatives = alternatives.filter((x) => !equals(x, item.selectedPart))
        alternatives.unshift(item.selectedPart)
    }

    const isActive = collapsableItems[item.uniqueId]

    return (
        <Box margin-bottom="1rem">
            <OeArticleItem
                item={item}
                showAlternativeBtn={alternatives && !!alternatives.length}
                onQuantityChange={onOeArticleQuantity}
                onSelect={onOeArticleSelect}
                onDetailsClick={onDetailsClick}
                onAlternativeClick={() => onAlternativeClick(item)}
            />

            {isActive && (
                <Box className="tk-parts">
                    <Box className="list">
                        <Box className="article-list alternative-part__list">
                            {alternatives.map((part, idx) => (
                                <PartItem
                                    key={idx}
                                    alternativePart={part}
                                    oeArticle={item.oeArticle}
                                    selectedPart={item.selectedPart}
                                    onSelect={() => onPartSelect(part)}
                                    onDetailsClick={onDetailsClick}
                                    onAccesoriesClick={onAccesoriesClick}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    )
}
