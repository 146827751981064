import { Widget } from "@tm/controls"
import { RouteComponentProps, useAvailableVehicleSearchOptions, useMultiWidgetState, withRouter } from "@tm/utils"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { VehicleLookupConfig, VehicleType } from "@tm/models"
import { useEffect, useState } from "react"
import VehicleSearchField from "../_shared/VehicleSearchField"
import { getVehicleSearchUrl } from "../../helpers/routing"
import { useResetFilters } from "../../data/hooks"
import CommercialCard from "./CommercialCard"

type Props = RouteComponentProps<{ workTaskId?: string }> &
    LocalizationProps & {
        showComponentKey?: string
        storeId?: string
        contentOnly?: boolean
    }

function CommercialWidgetComponent(props: Props) {
    const resetFilters = useResetFilters(VehicleType.CommercialVehicle)
    const [selectedMultiWidget] = useMultiWidgetState({ storeId: props.storeId })
    const [selectedSearchConfig, setSelectedSearchConfig] = useState<VehicleLookupConfig>()
    const [specializedSearchConfig, setSpecializedSearchConfig] = useState<VehicleLookupConfig>()
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const allConfiguredVehicleLookups = availableVehicleSearches?.configuredVehicleLookups

    useEffect(() => {
        if (!selectedSearchConfig && allConfiguredVehicleLookups) {
            const defaultVehicleLookup = allConfiguredVehicleLookups.find((x) => x.isDefault)
            defaultVehicleLookup && setSelectedSearchConfig(defaultVehicleLookup)
        }
    }, [availableVehicleSearches])

    useEffect(() => {
        if (availableVehicleSearches) {
            const { configuredVehicleLookups } = availableVehicleSearches // if there is an selectedSearchConfig, availableVehicleSearches is maybe set
            if (availableVehicleSearches?.configuredVehicleLookups && selectedSearchConfig) {
                const vrmLookupConfig = configuredVehicleLookups.find(
                    (lookup) => !lookup.isUsedInDefaultSearch && selectedSearchConfig.countryCode === lookup.countryCode
                )
                setSpecializedSearchConfig(vrmLookupConfig)
            }
        }
    }, [selectedSearchConfig])

    if (props.showComponentKey && selectedMultiWidget !== props.showComponentKey) {
        return null
    }

    const handleStartSearch = (query: string) => {
        resetFilters() // Reset filters before opening search page
        // TODO:  ADD selectedVehicleLookup ?
        props.history.push(getVehicleSearchUrl(props.match.params, { vehicleType: VehicleType.CommercialVehicle, query }))
    }

    if (props.contentOnly) {
        return <CommercialCard handleStartSearch={handleStartSearch} specializedSearchConfig={specializedSearchConfig} />
    }

    return (
        <Widget active className="tk-vehicle commercial-widget-start" size="4x4">
            <div className="search-container">
                <VehicleSearchField {...props} onStartSearch={handleStartSearch} vehicleType={VehicleType.CommercialVehicle} />
            </div>
        </Widget>
    )
}

export default withLocalization(withRouter(CommercialWidgetComponent))
