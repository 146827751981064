import { useEffect, useState } from "react"
import { Box, Icon, FormControlLabel, Typography, Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { AddressSelectionItem, Item, OrderOptions } from "@tm/models"
import { getTitleTypeTextId } from "@tm/utils"
import { getAddressLabels } from "../../../../helpers"
import {
    IconWithMargin,
    RadioButtonSmallWithMargin,
    RadioGroupGrid,
    BoxWithColumnPaddingRight,
    BoxWithAlignItems,
    ButtonWithIcon,
} from "./StyledComponents"
import { getBundleParams } from "../../../../utils"
import DeliveryAddressesInformationTextFields from "./DeliveryAddressesInformationTextFields"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onSetDeliveryAddress(
        address: AddressSelectionItem,
        selectedOptionsItem?: Item,
        updateErpInformation?: boolean,
        updateOrderOptions?: boolean,
        updateOrderOnChange?: boolean
    ): void
}

export default function DeliveryAddressesInformationComponent({ options, onSetDeliveryAddress, disableFields }: Props) {
    const { translate, translateText } = useLocalization()
    const { deliveryAddresses, selectedOptionsItem } = options

    const { hideOrderOptionsContactData } = getBundleParams()
    const [selectedDeliveryAddressId, setSelectedDeliveryAdressId] = useState<string>()
    const [isSaving, setIsSaving] = useState(false)
    const [addressBeingEdited, setAddressBeingEdited] = useState<AddressSelectionItem | undefined>(undefined)

    useEffect(() => {
        setIsSaving(false)
    }, [deliveryAddresses])

    useEffect(() => {
        if (deliveryAddresses?.addresses.length) {
            const defaultDeliveryAddress = deliveryAddresses.addresses.find((deliveryAddress) => deliveryAddress.isSelected)
            const deliveryAddressId = defaultDeliveryAddress ? defaultDeliveryAddress.id : deliveryAddresses.addresses[0].id
            setSelectedDeliveryAdressId(deliveryAddressId)
        }
    }, [deliveryAddresses])

    function handleDeliveryAddressChange(_: unknown, id: string) {
        if (deliveryAddresses?.addresses.length) {
            setSelectedDeliveryAdressId(id)
            const firstDeliveryAddressMatch = deliveryAddresses.addresses.find((address) => address.id === id)
            if (firstDeliveryAddressMatch) {
                setIsSaving(true)
                onSetDeliveryAddress(
                    firstDeliveryAddressMatch,
                    selectedOptionsItem,
                    deliveryAddresses.updateErpInformationOnChange,
                    deliveryAddresses.updateOrderOptionsOnChange,
                    deliveryAddresses.updateOrderOnChange
                )
            }
        }
    }

    function renderDeliveryAdressSelection() {
        return (
            <RadioGroupGrid onChange={handleDeliveryAddressChange} rowcap="8px" minwidth="420px">
                {deliveryAddresses?.addresses.map((address) => {
                    let titleText = ""
                    if (address.titleType) {
                        const textId = getTitleTypeTextId(address.titleType)
                        if (textId) {
                            titleText = translateText(textId)
                        }
                    }
                    const labels = getAddressLabels(address, false, titleText, hideOrderOptionsContactData)
                    return (
                        <Box key={address.id} display="flex" alignItems="flex-start">
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    value={address.id}
                                    control={
                                        <RadioButtonSmallWithMargin disabled={disableFields} checked={address.id === selectedDeliveryAddressId} />
                                    }
                                    sx={{ whiteSpace: "pre" }}
                                    label={address.description}
                                />
                                {!!labels.length && (
                                    <Stack>
                                        {labels.map((x) => {
                                            return (
                                                <Typography key={x} pl={4} variant="body2">
                                                    {x}
                                                </Typography>
                                            )
                                        })}
                                    </Stack>
                                )}
                            </Stack>
                            {address.isSelected && address.isEditable && (
                                <ButtonWithIcon
                                    disabled={isSaving}
                                    startIcon={<Icon name="edit_change" />}
                                    title={translateText(122)}
                                    onClick={() => setAddressBeingEdited(address)}
                                />
                            )}
                        </Box>
                    )
                })}
            </RadioGroupGrid>
        )
    }

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="user" />
                <Typography variant="h4">{translate(13221)}</Typography>
            </BoxWithAlignItems>
            {addressBeingEdited ? (
                <DeliveryAddressesInformationTextFields
                    addressToEdit={addressBeingEdited}
                    options={options}
                    onSetDeliveryAddress={onSetDeliveryAddress}
                    onCloseEditMode={() => setAddressBeingEdited(undefined)}
                />
            ) : (
                renderDeliveryAdressSelection()
            )}
        </BoxWithColumnPaddingRight>
    )
}
