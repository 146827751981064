import { AttributeFilterModel, FittingPosition } from "@tm/models"
import { useCallback, useState } from "react"
import { useBetween } from "use-between"
import { ArticleGroupFilterAreaProps } from "."
import { XorSwitchPositions } from "../../../_shared/Switch"

const useSharedSelectedFiltersState = () => {
    const [selectedFilters, setSelectedFilters] = useState<Array<AttributeFilterModel>>([])
    return {
        selectedFilters,
        setSelectedFilters,
    }
}

export function useComponentBehavior(props: ArticleGroupFilterAreaProps) {
    const { onSelectProductGroupFilter, onDeselectProductGroupFilter, onDeselectAllProductGroupFilter } = props
    const [selectedFittingSide, setSelectedFittingSide] = useState<FittingPosition>(FittingPosition.FrontAxle)
    const { selectedFilters, setSelectedFilters } = useBetween(useSharedSelectedFiltersState)

    const handleDeselectAllFilters = useCallback(() => {
        onDeselectAllProductGroupFilter?.(selectedFilters)
        if (selectedFilters.length) {
            setSelectedFilters([])
        }
    }, [selectedFilters, setSelectedFilters, onDeselectAllProductGroupFilter])

    const handleSetFrontAxle = useCallback(() => {
        setSelectedFittingSide(FittingPosition.FrontAxle)
    }, [setSelectedFittingSide])

    const handleSetRearAxle = useCallback(() => {
        setSelectedFittingSide(FittingPosition.RearAxle)
    }, [setSelectedFittingSide])

    const handleSelectionClick = useCallback(
        (selectedFilter: AttributeFilterModel) => {
            const notSelected =
                !selectedFilters.length || !selectedFilters.some((filter) => filter.id === selectedFilter.id && filter.value === selectedFilter.value)

            if (notSelected) {
                setSelectedFilters([...selectedFilters, selectedFilter])
                onSelectProductGroupFilter?.(selectedFilter)
            }
        },
        [selectedFilters, setSelectedFilters, onSelectProductGroupFilter]
    )

    const handleDeselectFilter = useCallback(
        (deselectedFilter: AttributeFilterModel) => {
            const filtered = selectedFilters.filter((filter) => {
                return deselectedFilter.id !== filter.id && deselectedFilter.productGroupId !== filter.productGroupId
            })
            setSelectedFilters(filtered)
            onDeselectProductGroupFilter?.(deselectedFilter)
        },
        [setSelectedFilters, selectedFilters, onDeselectProductGroupFilter]
    )

    const handleFittingSideChange = useCallback(
        (side: XorSwitchPositions) => {
            let position = FittingPosition.None
            if (side === "left") {
                position |= FittingPosition.FrontAxle
            } else {
                position |= FittingPosition.RearAxle
            }

            setSelectedFittingSide(position)
            handleDeselectAllFilters()
        },
        [handleDeselectAllFilters]
    )

    return {
        handleDeselectAllFilters,
        handleDeselectFilter,
        handleFittingSideChange,
        handleSelectionClick,
        handleSetFrontAxle,
        handleSetRearAxle,
        selectedFilters,
        selectedFittingSide,
        setSelectedFilters,
        setSelectedFittingSide,
    }
}
