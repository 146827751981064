import { useStyle, useUser } from '@tm/context-distribution'
import { Loader, Scrollbar, Text } from '@tm/controls'
import { Article, RequestArticleDetailsPayload, SystemType } from '@tm/models'
import { useActions } from '@tm/morpheus'
import { classes, RouteComponentProps, withRouter } from '@tm/utils'
import { em } from 'csx'
import * as React from 'react'
import { batch, useSelector } from 'react-redux'
import { getBundleParams } from '../../../utils'
import { WheelSelectionSteps } from '../../../data/enums'
import { addOrRemoveItem, getErpQuantityModifiers, vehicleSelector } from '../../../data/helpers'
import { IAttributesItem, TyreArticle } from '../../../data/models'
import { MainState } from '../../main'
import { TireArticle } from '../../_shared'
import { Actions } from '../business'
import { filterList, getAttributeString } from '../business/helpers'
import { TiresError } from './tiresError'
import { useDetailsInModal } from '../../../data/hooks'

type Props = RouteComponentProps<any> & {
	detailsModalRoute?: string
	repairTimesRoute: string
	className?: string
}

const TyresListComponent: React.FC<Props> = ({ repairTimesRoute, className, detailsModalRoute, match }) => {
	let scrollTop = 0
	let blockScroll = false

	const { articles, availability, selectedItem } = useSelector((s: MainState) => ({
		articles: s.tyresList.articles,
		availability: s.tyresList.selectedFilters.availability,
		selectedItem: s.tyresList.selectedItem

	}))

	const vehicle = useSelector(vehicleSelector)
	const [selectedAttributes, setSelectedAttr] = React.useState<string[]>([])

	const { error, loading, loadingNextItems, loadingAutoItems, data, nextArticlesError, count, pageIndex } = articles
	const actions = useActions(Actions, "saveTyresListTab", "changeStep", "loadNextTiresList", "changeQuantity", "selectItem", "sendTireToOverview")
	const user = useUser()
	const { articleDetailsInModal, handleDetailsPage } = useDetailsInModal(match)

	const handleScroll = (e: React.UIEvent<HTMLElement> | UIEvent) => {
		const el = e.target as HTMLElement
		if ((el.scrollHeight - el.scrollTop) <= (el.clientHeight + 100) && el.scrollTop > scrollTop && !blockScroll) {
			actions.loadNextTiresList()
			blockScroll = true
			scrollTop = el.scrollTop
		}
	}

	const handleAddToBasketBtn = (item: Article, isItemSelected: boolean) => {
		batch(() => {
			!isItemSelected && actions.selectItem(item)
			actions.sendTireToOverview()
			actions.changeStep({ step: WheelSelectionSteps.OVERVIEW }, true)
			actions.saveTyresListTab(item)
		})
	}

	const handleSelectedAttribute = (_: TyreArticle, item: IAttributesItem) => {
		setSelectedAttr(addOrRemoveItem(selectedAttributes, getAttributeString(item)))
	}

	const handelArticleDetails = (article: TyreArticle, request: RequestArticleDetailsPayload) => {
		const params = {
			productGroupId: article.productGroup.id.toString(),
			supplierId: article.supplier.id.toString(),
			supplierArticleNo: article.supplierArticleNo.toString(),
			partsDetailsSubPage: request.subPage || "overview"
		}

		if (articleDetailsInModal && user?.userContext?.system.systemType == SystemType.Next && detailsModalRoute) {
			handleDetailsPage(article.quantity, params, detailsModalRoute)
		}
		else
			actions.changeStep({ step: WheelSelectionSteps.TIREDETAILS, params, quantity: article.quantity }, false)
	}

	const items = filterList(data, selectedAttributes, availability)

	const { maxAutoRequest, minAvailableItems, tyresPageSize: pageSize } = getBundleParams()

	React.useEffect(() => {
		if (availability &&
			items.length < minAvailableItems
			&& pageIndex < count / pageSize
			&& !nextArticlesError
			&& articles.autoNextCount < maxAutoRequest) {

			actions.loadNextTiresList(true)
		}

	}, [availability, items])

	if (error)
		return (
			<div className={className}>
				<TiresError />
			</div>
		)

	const renderRow = (article: TyreArticle, idx: number) => {
		return (<TireArticle
			onSelect={actions.selectItem}
			key={article.id + idx}
			onArticleAttributeSelect={handleSelectedAttribute}
			selectedArticleAttributes={selectedAttributes}
			repairTimesRoute={repairTimesRoute}
			hidden={loadingAutoItems && !article.erpInformation}
			onAddToBasket={handleAddToBasketBtn}
			isSelected={article.internalId == selectedItem?.internalId}
			vehicle={vehicle}
			quantityModifiers={article.erpInformation && getErpQuantityModifiers(article.erpInformation)}
			onArticleDetailsClick={handelArticleDetails}
			onQuantityChange={actions.changeQuantity}
			item={article} 
			isDisabled/>)
	}

	return (
		<div className={className}>
			<Scrollbar onScroll={handleScroll} >

				<div className="tk-parts">
					{(!loading && !loadingAutoItems && !items.length) && <TiresError />}
					{loading && <div className="article-list__panel article-list__status">
						<Loader />
					</div>}

					<div className={classes(style.articleList, "article-list")}>

						{items.map(renderRow)}
					</div>

				</div>
				{loadingAutoItems && articles.autoNextCount != 0 &&
					<div className={classes("article-list__panel", "article-list__status", style.loadWrapper)}>
						<Text>Try to load more data</Text>
						<Loader />
					</div>}

				{loadingNextItems && < div className="article-list__panel article-list__status">	<Loader /></div>}


			</Scrollbar>
		</ div >
	)
}

const style = useStyle({
	articleList: {
		$nest: {
			".article__cell--description": {
				minWidth: em(3)
			}
		}
	},
	loadWrapper: {
		display: "flex",
		flexDirection: "column",
		height: em(5)
	}
})(TyresListComponent)

export default withRouter(TyresListComponent)