import { useCallback } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { useHistory } from "react-router"
import { SearchFilters } from "@tm/models"
import { TmaHelper, encodeUniqueId, renderRoute } from "@tm/utils"
import { usePartsRoutes } from "./usePartsRoutes"

export function useHandleStartDirectSearch() {
    const history = useHistory()
    const { workTaskId } = useWorkTask() ?? {}
    const routes = usePartsRoutes()

    return useCallback(
        (query: string, searchFilter: SearchFilters) => {
            if (!workTaskId) {
                return
            }
            if (routes.directSearch?.list) {
                const url = renderRoute(routes.directSearch?.list, { workTaskId: encodeUniqueId(workTaskId) })
                const searchParams = new URLSearchParams()
                searchParams.append("query", query)
                searchParams.append("searchFilter", String(searchFilter))
                history.push(`${url}?${searchParams.toString()}`)
            }
        },
        [history, routes.directSearch?.list, workTaskId]
    )
}
