import {
    Vehicle,
    RegisteredModels,
    CarModelDetailsResponse,
    Article,
    VehicleRecordsContainer,
    OrderVouchersShowSupplierArticleByVehicleRequest,
    OrderVouchersShowSupplierArticleByVehicleResponse,
    ECalcOrigin,
    FastCalculation,
} from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { batch } from "react-redux"
import { MappedFastCalculatorData, CalcStateButton, CalcInput, CalcSelectionItem, CalcDropDownItem, CalculationContext } from "./data/model"
import { MainActionType } from "./components/main/business"
import {
    createButtonInputRequest,
    createImportCalculationRequest,
    createSubmitInputRequest,
    createSelectionItemClickRequest,
    createInitCalculationRequest,
    createSubmitDropdownItemClickRequest,
    createInitialDataRequest,
    createCalcProductGroupsRequest,
    createAddPartFromArticleListRequest,
    createAddCustomPartToConsumableRequest,
} from "./components/main/business/helper"
import { ArticleData } from "./data/repositories/fastcalculator-addPartFromArticleList/model"
import { Repositories } from "./data"
import { ECalcState, ESearchLink, ECalcInputValue, ECalcArticle, ECalcButtonState } from "./data/enums"
import {
    mapCalcArticleToArticleIdentifier,
    createArticleComparer,
    ArticleIdentifierExt,
    bundleChannel,
    getLastModelDetails,
    findServiceStatusOnServer,
} from "./data/helpers"
import { mapVehicleRecordArticleAddedToBasketEventRequest } from "./data/helpers/vehicle-records"
import { MainState } from "./components/main"

export type BundleActionTypes =
    | { type: "SET_CONFIG_PROPS"; payload: { costEstimationUrl: string } }
    | { type: "FASTCALCULATOR_LOADING"; payload?: number }
    | { type: "FASTCALCULATOR_LOADED"; payload: MappedFastCalculatorData }
    | { type: "QUEUE_START"; payload: { requestQueueId?: string; checkStatusUrl?: string } }
    | { type: "QUEUE_FINISHED" }
    | { type: "QUEUE_CANCELLED"; payload: { requestQueueId?: string } }
    | { type: "FASTCALCULATOR_ERROR" }
    | { type: "VEHICLE_SET"; payload: Vehicle }
    | { type: "DETAILS_LOADED"; payload: { modelDetails: CarModelDetailsResponse; vehicleId: string } }
    | { type: "SELECTION_DIALOG_CLOSE"; payload: { overlayCalcStateType: ECalcState } }

function setConfigProps(configProps: { costEstimationUrl: string }): MainActionType {
    return {
        type: "SET_CONFIG_PROPS",
        payload: configProps,
    }
}

function handleButtonClick(
    calcButton: CalcStateButton,
    languageId: string,
    importFastCalculation: (importFastCalculationRequest: FastCalculation.ImportFastCalculationRequest) => Promise<any>,
    defaultHourlyRate?: number,
    workTask?: WorkTaskInfo,
    showCostEstimation?: () => void,
    isOverlayCalcState?: boolean,
    changeFCRoute?: (calcStateType?: number) => void,
    memo?: string
): AsyncAction<MainActionType, MainState> {
    return async (dispatch, getState) => {
        const { selectedCalcState, selectedOverlayCalcState, vehicle, carModel, unsavedServices, requestQueueId, calcOrigin } =
            getState().fastCalculator
        const parentCalcState = isOverlayCalcState ? selectedOverlayCalcState : selectedCalcState
        if (!parentCalcState || !vehicle || !selectedCalcState) {
            return
        }

        if (unsavedServices?.length > 0 && (calcButton.type === ECalcButtonState.StartCalculation || calcButton.type === ECalcButtonState.Submit)) {
            await dispatch(
                callService(
                    Repositories.selectSelectionItem,
                    createSelectionItemClickRequest(unsavedServices, parentCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin),
                    undefined,
                    true
                )
            )
            dispatch({ type: "RESET_SELECTED_SERVICE" })
        }

        if (calcButton.type === ECalcButtonState.Reset) {
            dispatch({ type: "RESET_SELECTED_SERVICE" })
        }

        if (calcButton.type === ECalcButtonState.StartNewCalculation) {
            dispatch({ type: "SKIP_RELOAD", payload: { skipReload: true } })
            dispatch({ type: "RESET_SELECTED_SERVICE" })
        }

        dispatch(
            callService(
                Repositories.clickButton,
                createButtonInputRequest(
                    calcButton,
                    parentCalcState,
                    vehicle,
                    vehicle.tecDocTypeId,
                    selectedCalcState,
                    languageId,
                    calcOrigin,
                    carModel,
                    defaultHourlyRate,
                    requestQueueId
                ),
                undefined,
                undefined,
                changeFCRoute
            )
        ).then(() => {
            const newState = getState().fastCalculator

            if (newState.selectedDataCalcState?.type === ECalcState.BasketNext || newState.selectedDataCalcState?.type === ECalcState.QuoteNext) {
                const request = createImportCalculationRequest(newState.selectedDataCalcState, defaultHourlyRate, workTask, memo)
                if (request) {
                    importFastCalculation(request).then(() => {
                        if (request.parts?.length && newState.vehicle) {
                            const container: VehicleRecordsContainer = Container.getInstance(RegisteredModels.VehicleRecords)

                            request.parts.forEach((x) => {
                                const article = newState.additionalData.articles.find(
                                    (y) =>
                                        y.productGroup.id == x.productGroupId &&
                                        y.supplier.id == x.dataSupplierId &&
                                        y.supplierArticleNo == x.dataSupplierArticleNumber
                                )
                                if (article) {
                                    // TODO: this action should be extended to support multiple articles
                                    container.action("articleAddedToBasket")(
                                        mapVehicleRecordArticleAddedToBasketEventRequest(newState.vehicle!.id, article)
                                    )
                                }
                            })
                        }

                        if (newState.selectedDataCalcState?.type == ECalcState.QuoteNext) {
                            showCostEstimation?.()
                        }
                    })
                }
            }
        })
    }
}

function handleInputSubmit(calcInput: CalcInput): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedCalcState, vehicle, calcOrigin } = getState().fastCalculator
        if (!selectedCalcState || !vehicle) {
            return
        }

        dispatch(
            callService(
                Repositories.submitInput,
                createSubmitInputRequest(calcInput, selectedCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin)
            )
        )
    }
}

function handleSelectionItemClick(
    item: CalcSelectionItem,
    isOverlayCalcState?: boolean,
    isFromDialog?: boolean
): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedCalcState, selectedOverlayCalcState, vehicle, calcOrigin } = getState().fastCalculator
        const parentCalcState = isOverlayCalcState ? selectedOverlayCalcState : selectedCalcState
        if (!parentCalcState || !vehicle) {
            return
        }

        if (isFromDialog) {
            dispatch(
                callService(
                    Repositories.selectSelectionItem,
                    createSelectionItemClickRequest([item], parentCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin),
                    undefined,
                    true
                )
            )
            return
        }

        const existsInWS = findServiceStatusOnServer(isOverlayCalcState ? selectedOverlayCalcState : selectedCalcState, isOverlayCalcState, item.id)

        dispatch({ type: "ADD_SELECTED_SERVICE", payload: { item, existsInWS } })
    }
}

function setCalcOrigin(origin: ECalcOrigin): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_CALC_ORIGIN",
            payload: { calcOrigin: origin },
        })
    }
}

function initFastCalculator(fromWidget: boolean, calcId?: string, isWorkshop?: boolean): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { vehicle, vehicleIdUsed, calcOrigin } = getState().fastCalculator
        if (!vehicle) {
            return
        }

        if (vehicle.id != vehicleIdUsed) {
            dispatch(loadCarModel(vehicle.tecDocTypeId))
        }
        dispatch(
            callService(
                Repositories.initFastCalc,
                createInitCalculationRequest(vehicle, fromWidget, calcOrigin, calcId, isWorkshop),
                vehicle.tecDocTypeId
            )
        )
    }
}

function initialData(languageId: string, defaultHourlyRate?: number, fromWidget = false): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { vehicle, carModel, selectedCalcState, calcOrigin } = getState().fastCalculator
        if (!vehicle || !selectedCalcState) {
            return
        }

        dispatch(
            callService(
                Repositories.submitInitialData,
                createInitialDataRequest(selectedCalcState, vehicle, languageId, calcOrigin, carModel, defaultHourlyRate, fromWidget)
            )
        )
    }
}

function startCalculationWithProductGroups(
    productGroupIds: Array<number>,
    languageId: string,
    defaultHourlyRate?: number,
    origin?: string
): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { vehicle, carModel, calcOrigin } = getState().fastCalculator
        if (!vehicle) {
            return
        }

        let modelDetails

        if (!carModel) {
            modelDetails = getLastModelDetails()
        }

        dispatch(
            callService(
                Repositories.calcProductGroups,
                createCalcProductGroupsRequest(productGroupIds, vehicle, languageId, calcOrigin, modelDetails, defaultHourlyRate, origin),
                vehicle.tecDocTypeId
            )
        )
    }
}

function loadCarModel(tecDocTypeId: number): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { vehicle } = getState().fastCalculator
        if (!vehicle) {
            return
        }

        const container = Container.getInstance<CarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
        container
            .subscribe({ modelId: tecDocTypeId })
            .load()
            .then((response) => {
                dispatch({
                    type: "DETAILS_LOADED",
                    payload: { modelDetails: response, vehicleId: vehicle.id },
                })
                bundleChannel().publish("SHARE_MODEL_DETAILS", response)
            })
    }
}

function handleDropdownItemClick(
    calcInput: CalcInput,
    dropdownItem: CalcDropDownItem,
    changeFCRoute?: () => void
): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedCalcState, vehicle, calcOrigin } = getState().fastCalculator
        if (!selectedCalcState || !vehicle) {
            return
        }

        batch(() => {
            dispatch(
                callService(
                    Repositories.submitDropdownItemClick,
                    createSubmitDropdownItemClickRequest(calcInput, dropdownItem, selectedCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin)
                )
            )
            dispatch({ type: "SKIP_RELOAD", payload: { skipReload: true } })
        })
        changeFCRoute?.()
    }
}

function handleAddPartFromArticleList(article: ArticleData, searchType: ESearchLink): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedCalcState, vehicle, calcOrigin } = getState().fastCalculator
        if (!selectedCalcState || !vehicle) {
            return
        }

        dispatch(
            callService(
                Repositories.addPartFromArticleList,
                createAddPartFromArticleListRequest(article, searchType, selectedCalcState, vehicle.id, vehicle.tecDocTypeId, calcOrigin)
            )
        )
    }
}

function handleAddCustomPartToConsumable(
    id: string,
    label: string,
    price: number,
    articleNumber?: string,
    fastCalculatorConsumableId?: string
): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedCalcState } = getState().fastCalculator
        if (!selectedCalcState) {
            return
        }

        dispatch(
            callService(
                Repositories.addCustomPartToConsumable,
                createAddCustomPartToConsumableRequest(
                    id,
                    label,
                    price,
                    selectedCalcState.calcId,
                    selectedCalcState.calcETag,
                    fastCalculatorConsumableId,
                    articleNumber
                )
            )
        )
    }
}

function queueStart(requestQueueId?: string, checkStatusUrl?: string): MainActionType {
    return { type: "QUEUE_START", payload: { requestQueueId, checkStatusUrl } }
}

function queueFinished(): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedCalcState } = getState().fastCalculator
        dispatch({ type: "QUEUE_FINISHED" })
        dispatch(initFastCalculator(false, selectedCalcState?.calcId, false))
    }
}

function cancelQueue(): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { requestQueueId } = getState().fastCalculator
        dispatch({ type: "QUEUE_CANCELLED", payload: { requestQueueId } })
    }
}

function checkQueuedCalculation(): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const { isQueued, checkStatusUrl } = getState().fastCalculator

        if (isQueued && checkStatusUrl) {
            Repositories.checkQueuedCalculations(checkStatusUrl).then((data) => {
                if (data && data.done) {
                    dispatch(queueFinished())
                }
            })
        }
    }
}

function callService<T>(
    repositoryFunction: (request: T) => Promise<MappedFastCalculatorData>,
    request: T,
    tecDocTypeId?: number,
    noRefresh?: boolean,
    changeFCRoute?: (calcStateType?: number) => void
): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        dispatch({ type: "FASTCALCULATOR_LOADING", payload: noRefresh ? undefined : tecDocTypeId })

        return repositoryFunction(request).then(
            (response) => {
                if (response.isQueued) {
                    dispatch(queueStart(response.requestQueueId, response.checkStatusUrl))
                    changeFCRoute?.(response?.selectedCalcState?.type)
                    return
                }

                if (response?.selectedCalcState?.type === ECalcState.CalculationNext) {
                    const context = response.selectedCalcState.context as CalculationContext | undefined

                    // Refresh additional article data such as order history or vehicle records
                    dispatch(loadAdditionalArticleData())

                    if (context?.articles?.genArts) {
                        const state = getState().fastCalculator
                        const articlesToLoad: Array<ArticleIdentifierExt> = []

                        // Gather articles which have to be loaded
                        context.articles.genArts.forEach((genArt) => {
                            if (!genArt.articles) {
                                return
                            }

                            genArt.articles.forEach((article) => {
                                // If it's an IAM article and the data is not already loaded, add it to the request
                                if (
                                    (article.type == ECalcArticle.Article || article.type == ECalcArticle.AlternativeArticle) &&
                                    !state.additionalData?.articles.some(createArticleComparer(article, genArt))
                                ) {
                                    articlesToLoad.push(
                                        mapCalcArticleToArticleIdentifier(article, genArt.genArtNr, tecDocTypeId ?? state.vehicle?.tecDocTypeId)
                                    )
                                }

                                // Load articles of the oe groups (to be able to show the vehicle records count for this oe group)
                                article.oeGroups?.forEach((oeGroup) => {
                                    const alternativeArticle = oeGroup.value2
                                    // If this oe group has a second value which is an article,
                                    // the type of the attached article is IAM (no oe article or product group)
                                    // and the data is not already loaded, add it to the request
                                    if (
                                        alternativeArticle &&
                                        alternativeArticle.supplierId &&
                                        alternativeArticle.supplierArtNr &&
                                        oeGroup.value2Type == ECalcInputValue.Article &&
                                        (alternativeArticle.type == ECalcArticle.Article ||
                                            alternativeArticle.type == ECalcArticle.AlternativeArticle) &&
                                        !state.additionalData?.articles.some(createArticleComparer(alternativeArticle, genArt))
                                    ) {
                                        articlesToLoad.push(
                                            mapCalcArticleToArticleIdentifier(
                                                alternativeArticle,
                                                genArt.genArtNr,
                                                tecDocTypeId ?? state.vehicle?.tecDocTypeId
                                            )
                                        )
                                    }
                                })
                            })
                        })

                        // Load article data (to use it for the parts item micro)
                        dispatch(loadArticles(articlesToLoad))
                    }
                } else if (response?.selectedCalcState?.type == ECalcState.InitialData) {
                    dispatch({ type: "RESET_ADDITIONAL_DATA" })
                }

                changeFCRoute?.(response?.selectedCalcState?.type)

                dispatch({ type: "FASTCALCULATOR_LOADED", payload: response })

                return response
            },
            (e) => {
                dispatch({ type: "FASTCALCULATOR_ERROR" })
                throw e
            }
        )
    }
}

function loadArticles(articlesToLoad: Array<ArticleIdentifierExt>): AsyncAction<MainActionType, MainState> {
    return (dispatch) => {
        if (!articlesToLoad.length) {
            return
        }

        dispatch({ type: "ARTICLES_LOADING" })

        Container.getInstance<Array<Article>>(RegisteredModels.Articles_ByArticleNumbersWithOptionalVehicle)
            .subscribe(articlesToLoad)
            .load()
            .then((articles) => {
                if (!articles?.length) {
                    dispatch({ type: "ARTICLES_EMPTY_RESPONSE" })
                    return
                }

                // Copy some data from the fast calculator response to the articles
                articles.forEach((x) => {
                    const relatedRequestItem = articlesToLoad.find(
                        (y) =>
                            y.productGroupId == x.productGroup.id &&
                            y.supplierId == x.supplier.id &&
                            y.supplierArticleNo == x.supplierArticleNo &&
                            y.internalId == x.internalId
                    )
                    if (relatedRequestItem) {
                        x.fittingSide = x.fittingSide ?? relatedRequestItem.fittingPosition
                    }
                })

                dispatch(loadAdditionalArticleData(articles)) // Load article related data necessary for newly loaded articles
                dispatch({ type: "ARTICLES_LOADED", payload: articles })
            })
            .catch(() => dispatch({ type: "ARTICLES_LOADED", payload: [] }))
    }
}

function loadAdditionalArticleData(articles?: Array<Article>): AsyncAction<MainActionType, MainState> {
    return (dispatch, getState) => {
        const state = getState().fastCalculator

        // If articles were supplied load additonal data for them
        // and append it to the existing data at the state (appendData = true)
        // Otherwise load additional data for all and replace it (appendData = false)
        const appendData = !!articles
        articles = articles ?? state.additionalData?.articles

        if (!state.vehicle || !articles || !articles.length) {
            return
        }

        // Load order history
        const orderInfoRequest: OrderVouchersShowSupplierArticleByVehicleRequest = {
            vehicleId: state.vehicle.id,
            productGroupFilterIds: articles.map((x) => x.productGroup.id).join(","),
        }
        Container.getInstance<OrderVouchersShowSupplierArticleByVehicleResponse>(RegisteredModels.Vouchers_ShowSupplierArticleByVehicle)
            .subscribe(orderInfoRequest)
            .load()
            .then((response) => {
                if (response?.orderedSupplierArticles?.length) {
                    dispatch({
                        type: "ORDER_HISTORY_LOADED",
                        payload: {
                            orderHistory: response.orderedSupplierArticles,
                            appendData,
                        },
                    })
                }
            })
    }
}

function setVehicle(veh: Vehicle): BundleActionTypes {
    return { type: "VEHICLE_SET", payload: veh }
}

function closeSelectionDialog(overlayCalcStateType: ECalcState): MainActionType {
    return { type: "SELECTION_DIALOG_CLOSE", payload: { overlayCalcStateType } }
}

function skipReload(skipReload: boolean): MainActionType {
    return { type: "SKIP_RELOAD", payload: { skipReload } }
}

export type IBundleActions = typeof BundleActions

export const BundleActions = {
    setConfigProps,
    initFastCalculator,
    handleButtonClick,
    handleInputSubmit,
    handleSelectionItemClick,
    loadCarModel,
    handleDropdownItemClick,
    handleAddPartFromArticleList,
    handleAddCustomPartToConsumable,
    initialData,
    setVehicle,
    closeSelectionDialog,
    startCalculationWithProductGroups,
    skipReload,
    queueStart,
    checkQueuedCalculation,
    cancelQueue,
    setCalcOrigin,
}
