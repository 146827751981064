import { RouteComponentProps, withRouter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Text, Tooltip, Icon } from "@tm/controls"

type Props = RouteComponentProps<void> & {
    url: string
    title?: string
    tooltip?: string
    icon: string
    className?: string
}

function HeaderButton({ url, history, title, icon, className, tooltip }: Props) {
    const { translateText } = useLocalization()

    const isSelected = history.location.pathname === url

    return (
        <Tooltip content={(tooltip && translateText(tooltip)) || translateText(1251)}>
            <div
                className={`tab tab--worktask header-button tab--center ${isSelected ? "is-selected" : ""}`}
                onClick={() => {
                    isSelected ? history.goBack() : history.push(url)
                }}
            >
                <div className="tab__content">
                    <div className="header-button__icon">
                        <Icon name={icon} size="l" className={className} />
                    </div>
                    {!!title && (
                        <Text size="m" className="startpage-button__title">
                            {/^\d+/.test(title) ? translateText(title) : title}
                        </Text>
                    )}
                </div>
            </div>
        </Tooltip>
    );
}

export default withRouter(HeaderButton)
