import { useRecoilState } from "recoil"
import { voucherListFilterAtom } from "../../../../../data/states/listFilters"
import VehicleSelector, { VehicleSelectItem } from "../../../../_shared/VehicleSelector"

type Props = {
    workTaskId?: string
    onRedirectToVoucherList(): void
}

export default function VehicleFilter({ workTaskId, onRedirectToVoucherList }: Props) {
    const [listFilters, setListFilter] = useRecoilState(voucherListFilterAtom(workTaskId || ""))

    function handleVehicleFilterSelection(value: VehicleSelectItem) {
        setListFilter((prev) => ({
            ...prev,
            selectedVehicleId: value.vehicleId,
        }))
        onRedirectToVoucherList()
    }

    return (
        <VehicleSelector
            active
            autoWidth
            selectedVehicleId={listFilters.selectedVehicleId}
            size="medium"
            variant="outlined"
            onVehicleChanged={handleVehicleFilterSelection}
        />
    )
}
