import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"

export const importNote = (noteBlob: string): Promise<void> => {
    const url = `${getBundleParams().noteUploadServiceUrl}/ImportNotes`
    const authorization = getStoredAuthorization()
    const body = { file: noteBlob, from: 1 }

    return ajax<void>({ url, body, authorization, method: "POST" })
}
