import { Tooltip, ImageViewer, styled, tooltipClasses } from "@tm/components"
import { Image, Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import * as React from "react"
import { useState } from "react"
import { Models, Repositories } from "../../data"

export type Props = {
    thumbnailClassName: string
    thumbnailUrl: string
    thumbnailDescription?: string
    thumbnailFallbackUrl?: string
    internalArticleId: number
    vehicleLinkageId?: number
    images?: Array<Models.ArticleImage>
    enableLargeView: boolean
    customThumbnailComponent?: React.ReactNode
    startIndex?: number
    onClick?(): void
}

const StyledTooltip = styled(Tooltip)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "60vw",
    },
}))

const ArticleImageComponent: React.FC<Props> = ({
    images,
    internalArticleId,
    vehicleLinkageId,
    startIndex,
    thumbnailClassName,
    thumbnailUrl,
    thumbnailFallbackUrl,
    enableLargeView,
    customThumbnailComponent,
    thumbnailDescription,
    onClick,
}) => {
    const [imagesLoading, setImagesLoading] = useState(false)
    const [articleImages, setImages] = useState(images || [])
    const [useFallbackThumbnail, setUseFallbackThumbnail] = useState(false)
    const [open, setOpen] = useState(false)
    const { translate } = useLocalization()

    const handleLoadImages = () => {
        setImagesLoading(true)

        Repositories.getArticleImages(internalArticleId, vehicleLinkageId)
            .then(
                (response) => {
                    setImages(response)
                    setImagesLoading(false)
                },
                () => setImagesLoading(false)
            )
            .finally(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("resize"))
                }, 150)
            })
    }

    const handleThumbnailLoadError = () => {
        if (thumbnailFallbackUrl) {
            setUseFallbackThumbnail(true)
        }
    }

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const handleTooltipOpen = () => {
        handleLoadImages()
        setOpen(true)
    }

    const renderImages = () => {
        if (imagesLoading) {
            return (
                <div>
                    <Loader />
                </div>
            )
        }

        if (!articleImages.length) {
            return <div className="article__no-images-message">{translate(375)}</div>
        }

        return <ImageViewer images={articleImages} onClose={handleTooltipClose} startIndex={startIndex} isLoading={false} />
    }

    let content = (
        <div
            style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignSelf: "stretch",
                flex: 1,
            }}
        >
            <Image
                type="article"
                className={thumbnailClassName}
                url={!useFallbackThumbnail ? thumbnailUrl : thumbnailFallbackUrl ?? ""}
                title={thumbnailDescription}
                onClick={onClick}
                onError={handleThumbnailLoadError}
            />
            {customThumbnailComponent}
        </div>
    )

    if (enableLargeView) {
        content = (
            <StyledTooltip
                color="primary"
                variant="light"
                open={open}
                placement="right"
                title={renderImages()}
                disableHoverListener
                onClick={handleTooltipOpen}
                onClickAway={handleTooltipClose}
            >
                {content}
            </StyledTooltip>
        )
    }

    return content
}

export default ArticleImageComponent
