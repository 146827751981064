import { Suspense } from "react"
import { IMicros } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Tooltip, Icon } from "@tm/controls"
import { Box } from "@tm/components"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-additional-prices"]

function ErpAdditionalPricesComponent({ data, isDetails, erpType, foundBySearchTerm }: Props) {
    const { currency } = useLocalization()
    const { loading, erpInfo } = useErpInfo(data, erpType || "list", undefined, undefined, foundBySearchTerm)

    if (loading || !erpInfo?.prices?.length) {
        return null
    }

    const tooltipContent = (
        <table>
            <tbody>
                {erpInfo.prices.map((price) => (
                    <tr key={price.type}>
                        <td>{price.description} </td>
                        <td>{currency(price.value, price.currencySymbol || "")}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )

    return (
        <Tooltip content={tooltipContent}>
            <Icon name="additional-prices" size={isDetails ? "xl" : "l"} />
        </Tooltip>
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <Box className="erp-additional-prices" sx={{ padding: "0 0.4rem", marginTop: "0.2rem" }}>
                <Suspense fallback={null}>
                    <ErpAdditionalPricesComponent {...props} />
                </Suspense>
            </Box>
        </ErpWrapper>
    )
}
