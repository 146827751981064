import { OrderOptionsBufferdResponse, OrderOptionsInternalRequest, ShowOptionsByWorktaskRequest } from "@tm/models"
import { showOptionsByWorktask } from "../.."

type BufferedRequest = {
    request: OrderOptionsInternalRequest
    resolve: (items: OrderOptionsBufferdResponse) => void
    reject: (error: string) => void
}

const bufferTimespanMs = 25 // defines the timespan (in ms) in which the requests will be buffered
// const maxQueueLength: number | undefined = undefined // defines the maximum number of items in a single service call

const queue: {
    [key: string]: {
        telesalesCustomerNo: string | undefined
        distributorId: number | undefined
        requests: Array<BufferedRequest>
        bufferTimeout?: number
    }
} = {}

function getQueueKey(request: OrderOptionsInternalRequest) {
    return `${request.distributorId}~#~${request.telesalesCustomerNo}`
}

/**
 * Get erp information with buffering so multiple nearly simultaneous requests would only result in one single service call
 */
export function getBufferedOrderOptions(request: OrderOptionsInternalRequest) {
    return new Promise<OrderOptionsBufferdResponse>((resolve, reject) => {
        const nexusRequest: BufferedRequest = { request, resolve, reject }
        const queueKey = getQueueKey(request)

        if (!queue[queueKey]) {
            queue[queueKey] = { telesalesCustomerNo: request.telesalesCustomerNo, distributorId: request.distributorId, requests: [] }
        }

        queue[queueKey].requests.push(nexusRequest)

        clearTimeout(queue[queueKey].bufferTimeout)

        // if (maxQueueLength && queue[distributorId].requests.length >= maxQueueLength) {
        //     requestErpInformationNexus(distributorId)
        //     return
        // }

        queue[queueKey].bufferTimeout = window.setTimeout(requestOrderOptions, bufferTimespanMs, queueKey)
    })
}

function requestOrderOptions(queueKey: string) {
    const requestItems = queue[queueKey].requests.splice(0) // splice to remove request items from queue...

    const warehouseIds: Array<string> = []
    const workTaskIds: Array<string> = []
    let favoriteOrderOptions

    requestItems.forEach((item) => {
        if (item.request.warehouseId && !warehouseIds.includes(item.request.warehouseId)) {
            warehouseIds.push(item.request.warehouseId)
        }

        if (!workTaskIds.includes(item.request.workTaskId)) {
            workTaskIds.push(item.request.workTaskId)
        }

        favoriteOrderOptions = item.request.favoriteOrderOptions
    })

    const body: ShowOptionsByWorktaskRequest = {
        distributorId: queue[queueKey].distributorId,
        telesalesCustomerNo: queue[queueKey].telesalesCustomerNo,
        warehouseIds,
        workTaskIds,
        favoriteOrderOptions,
    }

    const resolveError = (item: BufferedRequest) => item.resolve("reject")

    showOptionsByWorktask(body).then(
        (response) => {
            if (response?.hasErrors) {
                requestItems.forEach((item) => {
                    resolveError(item)
                })
            } else {
                requestItems.forEach((item) => {
                    let orderOptions
                    const workTaskOptions = response?.optionsByWorkTasks?.find((w) => w.workTaskId === item.request.workTaskId)
                    if (workTaskOptions) {
                        if (item.request.warehouseId) {
                            orderOptions = workTaskOptions.warehouseOptions?.find((w) => w.warehouseId === item.request.warehouseId)
                        } else {
                            orderOptions = workTaskOptions.generalOptions
                        }
                    }

                    if (orderOptions) {
                        item.resolve(orderOptions)
                    } else {
                        resolveError(item)
                    }
                })
            }
        },
        () => {
            requestItems.forEach((item) => {
                resolveError(item)
            })
        }
    )
}
