import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { QueryFunctionContext, useQuery } from "react-query"
import { isEqual, sortBy } from "lodash"
import { useRecoilState } from "recoil"
import { ListFilter, Vehicle } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import * as PartsRepository from "../../../../data/repositories/parts"
import { GetProductGroupAndSupplierFiltersRequest, GetProductGroupAndSupplierFiltersResponse } from "../../../../data/model"
import { ProductGroupsStartParams, FilterActions, FiltersData, ListParams } from "../../models"
import { isPartOf, mapDataSupplierFilterAndShowOnTop, mapProductGroupFilter } from "../../helpers"
import { AttributeFiltersAtom, SelectedAttributeFilterQueriesAtom, SelectedProductGroupIdsAtom, SelectedSupplierIdsAtom } from "../../states"
import { useFilterStorageKey } from "../../hooks/useFilterStorageKey"

const QUERY_KEY = "PARTS_REPLACEMENTS_FILTER_QUERY"
const CHECK_DELAY = 500

type QueryKey = [string, GetProductGroupAndSupplierFiltersRequest | undefined]

export function useFilters(
    vehicle: Vehicle | undefined,
    params: ListParams<ProductGroupsStartParams>,
    isEnabled: boolean,
    setDefaultSuppliers?: boolean
) {
    const requestTimerRef = useRef<number>()
    const attributeTimerRef = useRef<number>()
    const requestDelay = useRef(0) // First request should be without any delay

    const { startParams, fittingPosition, setFittingPosition } = params
    const storageKey = useFilterStorageKey(startParams)

    const [attributeFilters, setAttributeFilters] = useRecoilState(AttributeFiltersAtom(storageKey))
    const [extendedAssortmentEnabledByUser, setExtendedAssortmentEnabledByUser] = useState(!!params.extendedAssortment)
    const [showOnlyAvailable, setShowOnlyAvailable] = useState(!!params.showAvailable)
    const [showOnlyAvailableSecondary, setShowOnlyAvailableSecondary] = useState(!!params.showAvailableSecondary)
    const [selectedProductGroupIds, setSelectedProductGroupIds] = useRecoilState(SelectedProductGroupIdsAtom(storageKey))
    const [selectedDataSupplierIds, setSelectedSupplierIds] = useRecoilState(SelectedSupplierIdsAtom(storageKey))
    const [selectedAttributeFilterQueries, setSelectedAttributeQueries] = useRecoilState(SelectedAttributeFilterQueriesAtom(storageKey))
    const [request, setRequest] = useState<GetProductGroupAndSupplierFiltersRequest>()

    const {
        data: loadedFilters,
        isLoading,
        isSuccess,
        isRefetching,
        remove: clearLoadedFilters,
    } = useQuery({
        enabled: isEnabled && !!request,
        queryKey: [QUERY_KEY, request],
        queryFn: getFilters,
        keepPreviousData: true, // after the user has changed filter a new request will be triggered, but we want to keep showing the previous data while loading
        notifyOnChangeProps: "tracked", // only update when properties of the useQuery return value changed which are really used - enabled by default in v4
    })

    const extendedAssortmentForced = useMemo(() => {
        const anyExtendedProductGroupsIsSelected = loadedFilters?.productGroupFilters.some(
            (x) => !x.hasTopPrioritySuppliers && selectedProductGroupIds.includes(x.id)
        )
        const anyExtendedSupplierIsSelected = loadedFilters?.dataSupplierFilters.some(
            (x) => !x.isTopPriority && selectedDataSupplierIds.includes(x.id)
        )
        const noResult = isSuccess && !loadedFilters?.productGroupFilters.length && !loadedFilters?.dataSupplierFilters.length // TODO: clarify if extended assortment should really be enabled automatically in this case (example: no filter results for current search query)
        const noPriorityProductGroupFilter =
            isSuccess && !!loadedFilters?.productGroupFilters.length && loadedFilters.productGroupFilters.every((x) => !x.hasTopPrioritySuppliers)

        return anyExtendedProductGroupsIsSelected || anyExtendedSupplierIsSelected || noResult || noPriorityProductGroupFilter
    }, [loadedFilters?.productGroupFilters, selectedProductGroupIds, loadedFilters?.dataSupplierFilters, selectedDataSupplierIds, isSuccess])

    useEffect(
        function extendedAssortmentBecauseNoResult() {
            if (!!params.attributes.length && params.noResult && !params.extendedAssortment) {
                setExtendedAssortmentEnabledByUser(true)
            }
        },
        [params.noResult, params.extendedAssortment, params.attributes]
    )

    useEffect(
        function createRequest() {
            window.clearTimeout(requestTimerRef.current)

            const { productGroupIds, fittingSideFilter } = params.startParams

            let selectedProdGroups = productGroupIds
            if (selectedProductGroupIds.length) {
                selectedProdGroups = selectedProductGroupIds
            }

            const newRequest: GetProductGroupAndSupplierFiltersRequest = {
                selectedDataSupplierIds,
                selectedProductGroupIds: selectedProdGroups,
                productGroupIds,
                fittingSideFilter,
                extendedAssortment: false,
                vehicleType: vehicle?.vehicleType,
                modelId: vehicle?.tecDocTypeId,
            }

            // Zeitverzögerter Request
            requestTimerRef.current = window.setTimeout(() => {
                setRequest(newRequest)
            }, requestDelay.current)

            // Any further request will be delayed (to prevent multiple requests when state changes quickly)
            requestDelay.current = CHECK_DELAY
        },
        [params.startParams, selectedDataSupplierIds, selectedProductGroupIds]
    )

    // Reset if the search params have changed
    useEffect(
        function reset() {
            clearLoadedFilters()
            setRequest(undefined)
            setSelectedAttributeQueries((prev) => (prev.length ? [] : prev))
            setSelectedProductGroupIds((prev) => (prev.length ? [] : prev))
            setSelectedSupplierIds((prev) => (prev.length ? [] : prev))
            setAttributeFilters((prev) => (prev.length ? [] : prev))
        },
        [params.startParams, clearLoadedFilters]
    )

    const extendedAssortmentEnabled = extendedAssortmentEnabledByUser || extendedAssortmentForced
    useEffect(
        function transferSelectedFiltersToParams() {
            if (!loadedFilters) {
                return
            }

            if (!selectedProductGroupIds.length && params.productGroups.length) {
                params.setProductGroups([])
            } else if (!isEqual(sortBy(selectedProductGroupIds), sortBy(params.productGroups.map((x) => x.id)))) {
                params.setProductGroups(
                    loadedFilters.productGroupFilters
                        .filter((filter) => selectedProductGroupIds.some((id) => id === filter.id))
                        .map(mapProductGroupFilter)
                )
            }

            if (!selectedDataSupplierIds.length && params.suppliers.length) {
                params.setSuppliers([])
            } else if (!isEqual(sortBy(selectedDataSupplierIds), sortBy(params.suppliers.map((x) => x.id)))) {
                params.setSuppliers(
                    loadedFilters.dataSupplierFilters
                        .filter((filter) => selectedDataSupplierIds.some((id) => id === filter.id))
                        .map(mapDataSupplierFilterAndShowOnTop)
                )
            }

            if (setDefaultSuppliers && !selectedDataSupplierIds.length) {
                params.setSuppliers(loadedFilters.dataSupplierFilters.map(mapDataSupplierFilterAndShowOnTop))
            }

            params.setExtendedAssortment(extendedAssortmentEnabled)
        },
        [loadedFilters, extendedAssortmentEnabled]
    )

    useEffect(
        function transferAvailabilityToParams() {
            params.setAvailability(showOnlyAvailable)
        },
        [showOnlyAvailable]
    )

    useEffect(
        function transferAvailabilityToParams() {
            params.setAvailabilitySecondary(showOnlyAvailableSecondary)
        },
        [showOnlyAvailableSecondary]
    )

    useEffect(
        function transferIsLoadingToParams() {
            params.setIsFiltersLoading(isLoading)
        },
        [isLoading]
    )

    useEffect(
        function checkAndSetAttributes() {
            if (params.attributes.length && !isPartOf(attributeFilters, params.attributes)) {
                params.attributes.forEach((attr) => {
                    if (!attributeFilters.includes(attr)) {
                        setAttributeFilters((state) => [...state, attr])
                    }
                })
            }
            const paramAttributeQueries = params.attributes.map((x) => x.query).filter(notUndefinedOrNull)
            if (!isEqual(sortBy(selectedAttributeFilterQueries), sortBy(paramAttributeQueries))) {
                setSelectedAttributeQueries(paramAttributeQueries)
            }
        },
        [params.attributes]
    )

    useEffect(
        function checkAttributesEqualityAndSetParams() {
            window.clearTimeout(attributeTimerRef.current)

            // Checked, otherwise "checkAndSetAttributes" will be executed and a loop would be produced
            const paramAttributeQueries = params.attributes.map((x) => x.query).filter(notUndefinedOrNull)
            if (!isEqual(sortBy(selectedAttributeFilterQueries), sortBy(paramAttributeQueries))) {
                attributeTimerRef.current = window.setTimeout(
                    () => params.setAttributes(attributeFilters.filter((x) => !!x.query && selectedAttributeFilterQueries.includes(x.query))),
                    CHECK_DELAY
                )
            }
        },
        [selectedAttributeFilterQueries]
    )

    const toggleAttribute = useCallback((attribute: ListFilter, exclusive?: boolean) => {
        setSelectedAttributeQueries((state) => {
            const { query } = attribute
            if (!query) {
                return state
            }
            if (exclusive) {
                if (state.length === 1 && state.some((x) => x === query)) {
                    return []
                }

                return [query]
            }

            if (state.includes(query)) {
                return state.filter((x) => x !== query)
            }

            return [...state, query]
        })
    }, [])

    const toggleProductGroup = useCallback((id: number, exclusive?: boolean) => {
        setSelectedProductGroupIds((state) => {
            if (exclusive) {
                if (state.length === 1 && state.includes(id)) {
                    return []
                }

                return [id]
            }

            if (state.includes(id)) {
                return state.filter((x) => x !== id)
            }

            return sortBy([...state, id])
        })
    }, [])

    const toggleSupplier = useCallback((id: number, exclusive?: boolean) => {
        setSelectedSupplierIds((state) => {
            if (exclusive) {
                if (state.length === 1 && state.includes(id)) {
                    return []
                }

                return [id]
            }

            if (state.includes(id)) {
                return state.filter((x) => x !== id)
            }

            return sortBy([...state, id])
        })
    }, [])

    const toggleExtendedAssortment = useCallback(() => {
        setExtendedAssortmentEnabledByUser((state) => !state)
    }, [])

    const toggleAvailability = useCallback(() => {
        setShowOnlyAvailable((state) => !state)

        if (showOnlyAvailable === false && showOnlyAvailableSecondary) {
            setShowOnlyAvailableSecondary(false)
        }
    }, [showOnlyAvailable, showOnlyAvailableSecondary])

    const toggleAvailabilitySecondary = useCallback(() => {
        setShowOnlyAvailableSecondary((state) => !state)

        if (showOnlyAvailable && showOnlyAvailableSecondary === false) {
            setShowOnlyAvailable(false)
        }
    }, [showOnlyAvailable, showOnlyAvailableSecondary])

    const productGroupFilters = useMemo<[ListFilter, boolean][]>(() => {
        if (!loadedFilters?.productGroupFilters) {
            return []
        }

        return loadedFilters.productGroupFilters.map((filter) => [mapProductGroupFilter(filter), selectedProductGroupIds.includes(filter.id)])
    }, [selectedProductGroupIds, loadedFilters?.productGroupFilters])

    const dataSupplierFilters = useMemo<[ListFilter, boolean][]>(() => {
        if (!loadedFilters?.dataSupplierFilters) {
            return []
        }

        return loadedFilters.dataSupplierFilters.map((filter) => [
            mapDataSupplierFilterAndShowOnTop(filter),
            selectedDataSupplierIds.includes(filter.id),
        ])
    }, [selectedDataSupplierIds, loadedFilters?.dataSupplierFilters])

    const showFittingPositionsFilter = useMemo(
        () => params.productGroups.some((productGroup) => !!productGroup.hasFittingSideFilters),
        [params.productGroups]
    )

    const resetProductGroups = useCallback(() => setSelectedProductGroupIds((prev) => (prev.length ? [] : prev)), [])
    const resetSuppliers = useCallback(() => setSelectedSupplierIds((prev) => (prev.length ? [] : prev)), [])
    const resetAttributes = useCallback(() => setSelectedAttributeQueries((prev) => (prev.length ? [] : prev)), [])

    return useMemo<FiltersData & FilterActions>(
        () => ({
            extendedAssortment: {
                enabled: extendedAssortmentEnabledByUser,
                forced: extendedAssortmentForced,
            },
            showOnlyAvailable,
            showOnlyAvailableSecondary,
            productGroupFilters,
            dataSupplierFilters,
            attributeFilters: attributeFilters.map((filter) => [filter, !!filter.query && selectedAttributeFilterQueries.includes(filter.query)]),
            groupedAttributeFilters: [],
            fittingPosition,
            isLoading,
            isRefetching,
            showExtendedAssortmentFilter: loadedFilters?.showExtendedAssortmentFilter ?? false,
            showFittingPositionsFilter,
            toggleProductGroup,
            toggleSupplier,
            toggleAttribute,
            toggleExtendedAssortment,
            toggleAvailability,
            toggleAvailabilitySecondary,
            setFittingPosition,
            resetProductGroups,
            resetSuppliers,
            resetAttributes,
        }),
        [
            extendedAssortmentEnabledByUser,
            extendedAssortmentForced,
            showOnlyAvailable,
            showOnlyAvailableSecondary,
            productGroupFilters,
            dataSupplierFilters,
            attributeFilters,
            selectedAttributeFilterQueries,
            fittingPosition,
            isLoading,
            isRefetching,
            loadedFilters?.showExtendedAssortmentFilter,
            showFittingPositionsFilter,
            toggleProductGroup,
            toggleSupplier,
            toggleAttribute,
            toggleExtendedAssortment,
            toggleAvailability,
            toggleAvailabilitySecondary,
            setFittingPosition,
            resetProductGroups,
            resetSuppliers,
            resetAttributes,
        ]
    )
}

function getFilters({
    queryKey: [, requestFromKey],
}: QueryFunctionContext<QueryKey>): Promise<GetProductGroupAndSupplierFiltersResponse> | undefined {
    if (requestFromKey) {
        return PartsRepository.getFilters(requestFromKey)
    }
}
