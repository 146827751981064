import { Icon, ListItemButton, ListItemIcon, ListItemText } from "@tm/components"
import { CustomArticle } from "@tm/models"
import { StyledListItem } from "./shared"

type Props = {
    item: CustomArticle
    handleConsumableSelect: (selected: CustomArticle) => void
    isSelected?: boolean
}

export default function ConsumableItem({ item, handleConsumableSelect, isSelected }: Props) {
    return (
        <StyledListItem disablePadding isSelected={isSelected}>
            <ListItemButton
                sx={{ display: "grid", gridTemplateColumns: "1fr auto 1fr" }}
                role={undefined}
                onClick={() => {
                    handleConsumableSelect(item)
                }}
                dense
            >
                <ListItemText id={item.description} primary={item.description} />
                <ListItemIcon>
                    <Icon name={item.isDefaultForCategory ? "filled-star" : "vote-star"} width="1.5em" height="1.5em" />
                </ListItemIcon>
                <ListItemText sx={{ display: "flex", justifyContent: "flex-end" }} id={item.id} primary={item.garagePrice} />
            </ListItemButton>
        </StyledListItem>
    )
}
