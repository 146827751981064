import { useRef, useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Icon, Tooltip } from "@tm/controls"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { TmaHelper, convertBase64ImageToByteArray, encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { ECounterType, UserModuleType, WorkTaskDetailsReadModel } from "@tm/models"
import { useHistory } from "react-router"
import { sendFeedback } from "../../data"

import { getBundleParams } from "../../utils"
import { Thanks } from "../../components/thanks"
import { FeedbackFormular } from "../../components/feedbackFormular"
import { SimpleFeedbackFormular } from "../../components/simpleFeedbackFormular"
import { UserSnapFeedbackFormular } from "../../components/userSnapFeedbackFormular"
import { Rating } from "../../components/rating"

export type Formular = {
    file?: {
        fileName: string
        fileData: string
        fileMimeType: string
    }
    rating?: Rating
    name?: string
    email?: string
    phone?: string
    text?: string
    feedbackEmailAdress?: any
    catalogueId?: number
    workTaskReadModel?: WorkTaskDetailsReadModel
}

export function ToolbarFormComponent() {
    const tooltipRef = useRef<Tooltip>(null)
    const [showThanks, setShowThanks] = useState<boolean>(false)
    const [preset, setPreset] = useState<Formular>({})
    const { translateText } = useLocalization()
    const { userSettings, userContext } = useUser() || {}
    const { layout, iconName, iconSize, enableMVCFeedback } = getBundleParams()
    const workTask = useWorkTask()?.workTask
    const feedbackModule = userContext.modules?.find((x) => x.type === UserModuleType.Feedback)
    const { workTaskId } = useWorkTask() ?? {}
    const history = useHistory()

    useEffect(() => {
        if (userSettings?.repairShop) {
            const { repairShop } = userSettings
            const lastname = repairShop?.contactPerson?.lastName
            const firstname = repairShop?.contactPerson?.firstName
            const company = repairShop?.companyName
            setPreset({
                name: `${company ? `${company} ` : ""}${firstname ? `${firstname} ` : ""}${lastname || ""}`,
                email: repairShop?.contactInfo?.email,
                phone: repairShop?.contactInfo?.phone,
                feedbackEmailAdress: userContext?.parameter?.eMailFeedback ?? "",
                catalogueId: userContext?.modules?.[0]?.id,
                workTaskReadModel: workTask?.workTaskReadModel,
            })
        }
    }, [userSettings, userContext, workTask])

    const rootClassName = `tk-feedback toolbar-form${showThanks ? " showThanks" : ""}`

    const hideToolTip = () => {
        if (tooltipRef.current) {
            tooltipRef.current.hide()
        }
    }

    const handleChangeVisibility = (open: boolean) => {
        if (!open && showThanks) {
            setShowThanks(false)
        }
    }

    const onSubmit = (formular: Formular) => {
        if (formular.email && formular.name) {
            sendFeedback({
                id: uniqueId(),
                email: formular.email,
                personname: formular.name,
                phone: formular.phone || "",
                description: formular.text || "",
                rating: formular.rating?.value || 0,
                image: formular.file ? convertBase64ImageToByteArray(formular.file?.fileData) : undefined,
            })
            setShowThanks(true)
        }
    }

    function openMvcFeedback() {
        const newWorkTaskId = workTaskId || uniqueId()
        if (newWorkTaskId) {
            const url = renderRoute("/:workTaskId/mvc-feedback", { workTaskId: encodeUniqueId(newWorkTaskId) })
            history.push(url)
        }
    }

    if (feedbackModule && enableMVCFeedback) {
        return (
            <Tooltip content={translateText(41)}>
                <div className="global-navigation tab tab--worktask tk-feedback" onClick={openMvcFeedback}>
                    <div className="tab__content animation">
                        <Icon className="animation" name={iconName || "feedbacksmiley"} size={iconSize || "l"} />
                    </div>
                </div>
            </Tooltip>
        )
    }

    const getContent = () => {
        if (showThanks) {
            return <Thanks onClick={hideToolTip} />
        }
        if (layout === "extended") {
            return <FeedbackFormular preset={preset} onSubmit={onSubmit} onDecline={hideToolTip} />
        }
        if (layout === "usersnap") {
            return <UserSnapFeedbackFormular preset={preset} onSubmit={onSubmit} onDecline={hideToolTip} />
        }
        return <SimpleFeedbackFormular preset={preset} onSubmit={onSubmit} onDecline={hideToolTip} />
    }

    return (
        <Tooltip
            content={getContent()}
            ref={tooltipRef}
            event="click"
            // eslint-disable-next-line react/style-prop-object
            style="highlight"
            className={rootClassName}
            position="bottom"
            onChangeVisibility={handleChangeVisibility}
            preventCloseOnScroll
            forcePosition
        >
            <Tooltip content={translateText(41)}>
                <div
                    className="global-navigation tab tab--worktask tk-feedback"
                    onClick={() => {
                        TmaHelper.GeneralCountEvent.Call(ECounterType.Feedback)
                    }}
                >
                    <div className="tab__content animation">
                        <Icon className="animation" name={iconName || "feedbacksmiley"} size={iconSize || "l"} />
                    </div>
                </div>
            </Tooltip>
        </Tooltip>
    )
}

export default ToolbarFormComponent
