import { useUser } from "@tm/context-distribution"
import { Toolbar, Switch } from "@tm/controls"
import { useLocalization } from "@tm/localization"

const PurchasePriceSwitch = () => {
    const user = useUser()
    const { translateText } = useLocalization()

    if (user?.userContext?.parameter.purchasePricesDisabled)
        return null

    const handleChange = (showPurchasePrice: boolean) => {
        if (user?.userSettings?.articleListSettings)
            user.setUserSetting("SHOW_PURCHASE_PRICE", showPurchasePrice)
    }

    const canShowSwitch = (): boolean => {
        const externalModules = user?.userContext?.externalModules

        let isInfoEnabled = false
        if (externalModules) {
            externalModules.map(externalModule => {
                if (externalModule.isInfoEnabled) {
                    isInfoEnabled = true
                    return
                }
            })
        }
        return isInfoEnabled
    }

    return (
        <>
            {canShowSwitch() && <Toolbar className="tk-parts switch" title={translateText(1132)}>
                <Switch
                    status={user?.userSettings?.showPurchasePrice}
                    onChange={handleChange}
                    alignLabel="left"
                />
            </Toolbar>}
        </>
    )
}

export default PurchasePriceSwitch