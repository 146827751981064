import { atomFamily } from "recoil"
import { sub } from "date-fns"

export type VoucherListFilters = {
    startDate: Date
    endDate: Date
    selectedVehicleId?: string
}

// 3 last months
export const DISPLAY_RANGE_IN_DAYS = 90

export const voucherListFilterAtom = atomFamily<VoucherListFilters, string>({
    key: "voucher_lists_filter",
    default: {
        startDate: sub(new Date(), { days: DISPLAY_RANGE_IN_DAYS }),
        endDate: new Date(),
    },
})
