import { Button, Headline, Icon, Loader, MessageInline, PanelSection, Table, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RegistrationNoDetails, RegistrationNoType, Vehicle } from "@tm/models"
import { styled } from "@tm/components"
import { useState, useEffect } from "react"

import { isVin } from "@tm/utils"
import { getBundleParams } from "../../utils"
import Dictionary, { DictionaryItem } from "./Dictionary"
import { mapVrmDetailsToDictionary as mapSecondaryVrmDetailsToDictionary } from "./VrmLookupDetailsComponentHelper"
import RequestVinComponent from "../request-vin/component"

type EquipmentInfo = {
    description: string
    manufacturerCode: string
}

type ColorInfo = {
    description: string
    colorCode: string
    colorId: string
}

type Props = {
    dataProvider: RegistrationNoType
    vehicle?: Vehicle
    errorMessage?: string
    showRequestVinComponent?: boolean
    vrmData: RegistrationNoDetails | undefined
    onRequestVrmData?: (provider: RegistrationNoType) => void
    lastLookupDate?: Date
    classNames?: {
        wrapper?: string
        panel?: string
    }
}
const StyledButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.margin?.xl,
    marginRight: theme.margin?.m,
}))

const StyledRequestVinComponent = styled(RequestVinComponent)(({ theme }) => ({
    margin: theme.spacing(theme.margin?.xl || 0, 0, 0),
}))

export default function VrmLookupDetailsComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const [additionalInfoFilter, setAdditionalInfoFilter] = useState<string>()
    const [requestingData, setRequestingData] = useState<boolean>(false)

    const { classNames, vehicle, errorMessage, dataProvider, vrmData, showRequestVinComponent, lastLookupDate } = props

    useEffect(() => {
        if (requestingData && (!isVin(vehicle?.vin) || errorMessage)) {
            setRequestingData(false)
        }
    }, [requestingData, vehicle, errorMessage])

    function getGeneralInfo(vrmInfo: RegistrationNoDetails): Array<DictionaryItem> {
        const items: Array<DictionaryItem> = []

        vrmInfo.manufacturer && items.push({ key: translateText(1062), value: vrmInfo.manufacturer })
        vrmInfo.modelSeries && items.push({ key: translateText(1064), value: vrmInfo.modelSeries })
        vrmInfo.model && items.push({ key: translateText(610), value: vrmInfo.model })

        return items
    }

    function getColorInfo(vrmInfo: RegistrationNoDetails): Array<ColorInfo> {
        const items: Array<ColorInfo> = []

        vrmInfo.colors.forEach((x) => {
            if (!x) {
                return
            }

            const { description } = x
            const colorCode = x.code
            const colorId = x.id

            const colorInfo: ColorInfo = {
                description: description || undefined || "",
                colorCode: colorCode || undefined || "",
                colorId: colorId || undefined || "",
            }

            if (colorInfo.description || colorInfo.colorCode || colorInfo.colorId) {
                items.push(colorInfo)
            }
        })

        return items
    }

    function getEquipmentInfo(vrmInfo: RegistrationNoDetails): Array<EquipmentInfo> {
        const items: Array<EquipmentInfo> = []

        vrmInfo.equipments.forEach((x) => {
            if (!x) {
                return
            }

            const descriptionInfo = x.label
            const manufacturerCodeInfo = x.manufacturerCode

            const description = descriptionInfo || undefined || ""
            const manufacturerCode = manufacturerCodeInfo || undefined || ""

            // Filter empty infos
            if (!description && !manufacturerCode) {
                return
            }

            // Filter infos by filter value (if set)
            if (additionalInfoFilter) {
                const filter = additionalInfoFilter.toLowerCase()

                if (!description.toLowerCase().includes(filter) && !manufacturerCode.toLowerCase().includes(filter)) {
                    return
                }
            }

            items.push({ description, manufacturerCode })
        })

        return items
    }

    function handleChangeAdditionalInfoFilter(value: string) {
        setAdditionalInfoFilter(value)
    }

    function handleRefreshData() {
        setRequestingData(true)
        props.onRequestVrmData?.(dataProvider)
    }

    function renderNoDataMessage() {
        if (dataProvider === RegistrationNoType.DatVin) {
            const disabled = (vehicle && !isVin(vehicle.vin)) || requestingData

            return (
                <div className="data-requestable--centered">
                    <Text>{translateText(1264).replace("{0}", getBundleParams().datPricePerRequest || "2€")}</Text>
                    <span title={disabled ? translateText(1541) : undefined}>
                        <StyledButton onClick={handleRefreshData} disabled={disabled}>
                            {translate(1265)}
                        </StyledButton>
                    </span>
                    {requestingData && <Loader />}
                </div>
            )
        }
    }

    function renderRegistrationNoDetails() {
        if (!vrmData || (vehicle && vehicle.registrationTypeId != dataProvider)) {
            let requestVin
            if (showRequestVinComponent && vehicle?.vin) {
                requestVin = <StyledRequestVinComponent query={vehicle.vin} vehicleData={vehicle} />
            }

            return (
                <>
                    {!!errorMessage && <MessageInline message={errorMessage} description={requestVin} skin="warning" />}
                    {renderNoDataMessage()}
                </>
            )
        }

        const generalItems: Array<DictionaryItem> = [...getGeneralInfo(vrmData), ...mapSecondaryVrmDetailsToDictionary(vrmData, translateText)]

        return (
            <>
                {!!generalItems.length && (
                    <div className="vrm-lookup-details__group vrm-lookup-details__group--general">
                        <Dictionary items={generalItems} />
                    </div>
                )}

                {vrmData.additionalDetails && !!vrmData.additionalDetails.length && (
                    <div className="vrm-lookup-details__group vrm-lookup-details__group--additional">
                        <Dictionary
                            items={vrmData.additionalDetails.map((item) => {
                                // Converting Date value to string // https://jira.dvse.de/browse/NEXT-25566
                                const itemAsUnknown = item.value as unknown
                                if (itemAsUnknown && itemAsUnknown instanceof Date) {
                                    return {
                                        key: item.description,
                                        value: itemAsUnknown.toLocaleDateString(),
                                    }
                                }

                                return {
                                    key: item.description,
                                    value: item.value,
                                }
                            })}
                        />
                    </div>
                )}

                {vrmData.colors && !!vrmData.colors.length && (
                    <div className="vrm-lookup-details__group vrm-lookup-details__group--color">
                        <Headline size="xs">{translate(944)}</Headline>
                        <Table
                            data={getColorInfo(vrmData)}
                            columns={[
                                <Table.Column className="large" renderItemContent={(data: ColorInfo) => <Table.Cell>{data.description}</Table.Cell>}>
                                    {translate(25)}
                                </Table.Column>,
                                <Table.Column className="small" renderItemContent={(data: ColorInfo) => <Table.Cell>{data.colorCode}</Table.Cell>}>
                                    {translate(1268)}
                                </Table.Column>,
                                <Table.Column className="small" renderItemContent={(data: ColorInfo) => <Table.Cell>{data.colorId}</Table.Cell>}>
                                    {translate(1269)}
                                </Table.Column>,
                            ]}
                        />
                    </div>
                )}

                {vrmData.equipments && !!vrmData.equipments.length && (
                    <div className="vrm-lookup-details__group vrm-lookup-details__group--equipment">
                        <Headline size="xs">
                            {translate(1038)}
                            <TextField
                                value={additionalInfoFilter}
                                onChange={handleChangeAdditionalInfoFilter}
                                placeholder={translateText(1356)}
                                showClear
                            />
                        </Headline>
                        <Table
                            data={getEquipmentInfo(vrmData)}
                            columns={[
                                <Table.Column
                                    className="large"
                                    renderItemContent={(data: EquipmentInfo) => <Table.Cell>{data.description}</Table.Cell>}
                                >
                                    {translate(25)}
                                </Table.Column>,
                                <Table.Column
                                    className="small"
                                    renderItemContent={(data: EquipmentInfo) => <Table.Cell>{data.manufacturerCode}</Table.Cell>}
                                >
                                    {translate(1270)}
                                </Table.Column>,
                            ]}
                        />
                    </div>
                )}
            </>
        )
    }

    function renderLastLookupDate() {
        if (!lastLookupDate) {
            return
        }

        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Text modifiers={["sub"]}>
                    {translate(496)}: {lastLookupDate.toLocaleString()}
                </Text>
                &nbsp;&nbsp;
                <StyledButton icon="refresh" title={translateText(276)} onClick={handleRefreshData} />
            </div>
        )
    }

    function renderTitle() {
        switch (dataProvider) {
            case RegistrationNoType.DatVin:
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {translate(1267)}
                        &nbsp;&nbsp;
                        <Icon name="dat-vin-data" size="l" />
                    </div>
                )
            default:
                return <div>{translate(1397)}</div>
        }
    }

    const details = renderRegistrationNoDetails()

    // Only render the whole panel if it has content
    if (!details) {
        return null
    }

    return (
        <div className={`${classNames?.wrapper || ""} vrm-lookup-details`}>
            <PanelSection
                className={classNames?.panel}
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
                        {renderTitle()}
                        {renderLastLookupDate()}
                    </div>
                }
            >
                {details}
            </PanelSection>
        </div>
    )
}
