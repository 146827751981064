import { Box, ErpPriceAdditionalInfo, Stack, Theme, Typography } from "@tm/components"
import { ErpPrice, MemoType, Quantity } from "@tm/models"
import { useLocalization } from "@tm/localization"
import PriceMemo from "./PriceMemo"

type Props = {
    quantity?: Quantity
    calculatedPrice: ErpPrice
    erpPrice?: ErpPrice
    priceLabel: string
    primaryColor?: boolean
    theme: Theme
    showPricePerUnitLabel?: boolean
}

export default function SmallScreenPrice({ calculatedPrice, erpPrice, priceLabel, primaryColor, theme, quantity, showPricePerUnitLabel }: Props) {
    const fontFamilyOverwrite = theme?.overwrites?.components?.basketPrices?.fontFamily?.replaceAll("'", "") || theme.typography.fontFamily

    const { translate, currency } = useLocalization()

    return (
        <>
            {/* first row */}
            <Typography variant="label" textAlign="end" noWrap>
                {priceLabel}
            </Typography>
            <Box />

            {/* second row */}
            <Typography
                color={primaryColor ? "primary" : undefined}
                fontFamily={fontFamilyOverwrite || theme.typography.fontFamily}
                fontWeight="bold"
                noWrap
                textAlign="end"
            >
                {currency(calculatedPrice.value, calculatedPrice.currencySymbol || calculatedPrice.currencyCode_Iso_4217)}
            </Typography>
            <Stack ml={1} justifyContent="center" alignItems="center">
                {erpPrice && <ErpPriceAdditionalInfo price={erpPrice} />}
                {calculatedPrice.memos
                    ?.filter((memo) => memo.type === MemoType.AdditionalDescription)
                    .map((memo, index) => (
                        <PriceMemo memo={memo} key={index} />
                    ))}
            </Stack>

            {/* third row */}
            <Box>
                {!!calculatedPrice.priceUnit && showPricePerUnitLabel && (calculatedPrice.priceUnit > 1 || (quantity && !!quantity.quantityUnit)) && (
                    <Typography variant="label" textAlign="end" display="block">
                        {translate(1312)} {calculatedPrice.priceUnit} {quantity && quantity.quantityUnit}
                    </Typography>
                )}
                {calculatedPrice.memos
                    ?.filter((memo) => memo.type === MemoType.Note)
                    .map((memo, index) => (
                        <PriceMemo memo={memo} key={index} alignLabelEnd />
                    ))}
            </Box>
            <Box />
        </>
    )
}
