import * as React from "react"
import { Text } from "@tm/controls"
import { Link } from "react-router-dom"
import { style } from "typestyle"

export type TextLinkProps = {
    className?: string
    size?: "l"
    to?: string
    onClick?: () => void
    closeMenu: () => void
}

export default function TextLink(props: TextLinkProps & { children: React.ReactNode }) {
    const handleClick = () => {
        props.onClick?.()
        props.closeMenu()
    }

    const contentClassName = style({ display: "flex", alignItems: "center" })
    let content = (
        <div className={contentClassName} onClick={handleClick}>
            {props.children}
        </div>
    )
    if (!props.onClick && props.to) {
        content = (
            <Link className={contentClassName} onClick={handleClick} to={props.to}>
                {props.children}
            </Link>
        )
    }

    const className = `text--link ${props.className || ""}`

    return (
        <Text className={className} modifiers="block" size={props.size}>
            {content}
        </Text>
    )
}
