import { useUser, useWorkTaskId } from "@tm/context-distribution"
import { Article, RepairTimeProvider } from "@tm/models"
import { encodeUniqueId, getRepairTimesProvider, getRepairTimesProviderStringByEnum, renderRoute, uniqueId } from "@tm/utils"
import { useCallback } from "react"
import { useRouteMatch } from "react-router"
import { getBundleParams } from "../../../utils"
import { usePartsModuleState } from "../PartsModuleState"
import { useVehicle } from "./useVehicle"

export function useGetRepairTimesUrl() {
    const vehicle = useVehicle()
    const { userSettings } = useUser()
    const match = useRouteMatch()
    const workTaskId = useWorkTaskId()
    const { partsRoutes } = getBundleParams()
    const activeVehicleDataProviders = usePartsModuleState((x) => x.activeVehicleDataProviders)

    return useCallback((article: Article, rtProviders: RepairTimeProvider | RepairTimeProvider[]) => {
        if (article.productGroup && vehicle && getBundleParams().awProviders) {
            let provider
            if (Array.isArray(rtProviders)) {
                let activeRepairTimesProvider = activeVehicleDataProviders?.repairTimes
                if (!activeRepairTimesProvider && userSettings) {
                    activeRepairTimesProvider = userSettings.activeVehicleDataProviders.repairTimes
                }
                provider = getRepairTimesProvider(rtProviders, getBundleParams().awProviders, activeRepairTimesProvider)
            } else {
                provider = getRepairTimesProviderStringByEnum(rtProviders)
            }
            if (!provider || !partsRoutes) {
                return
            }
            return decodeURIComponent(
                renderRoute(partsRoutes.repairTimesRoute, {
                    ...match.params,
                    workTaskId: encodeUniqueId(workTaskId ?? uniqueId()),
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo.replace(/\//g, "%252F"),
                    position: article.fittingSide,
                })
            )
        }
    }, [])
}
