import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { DateUnit } from "@tm/utils"
import { TableCellData, TableColumnData, TableRowData } from "@tm/components"
import { OrderIdSource, OrderVoucherList, VoucherType } from "@tm/models"
import { useRecoilValue } from "recoil"
import { useParams } from "react-router"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import { voucherListFilterAtom } from "../../../../../data/states/listFilters"
import { StyledTable } from "../../../../_shared/StyledComponents"
import { VoucherRouteParams } from "../../../../../business"
import { useOpenDetails } from "../../../../../hooks/useOpenDetails"
import VehicleInfo from "../../../../_shared/orders/OrderVehicleInfo"

type Props = {
    orders: Array<OrderVoucherList>
    shortCountryCode: string
}

export default function CompactOrdersList({ orders, shortCountryCode }: Props) {
    const { translateText, date } = useLocalization()
    const params = useParams<VoucherRouteParams>()

    const selectedVoucherId = params.id && decodeURIComponent(params.id)

    const listFilters = useRecoilValue(voucherListFilterAtom(params.workTaskId || ""))
    const { openDetails } = useOpenDetails()

    const filteredData = useMemo(() => {
        return orders
            .filter(
                (x) =>
                    (x.orderDate.isAfter(listFilters.startDate, DateUnit.Day) || x.orderDate.isSame(listFilters.startDate, DateUnit.Day)) &&
                    (x.orderDate.isBefore(listFilters.endDate, DateUnit.Day) || x.orderDate.isSame(listFilters.endDate, DateUnit.Day))
            )
            .orderBy((x) => x.orderDate, true)
    }, [listFilters, orders])

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(700) }, // orderNumbers
            { header: translateText(98) }, // orderDate
            { header: translateText(147) }, // VehicleInfo
        ]
        return columns
    }

    function getCells(order: OrderVoucherList) {
        const data: TableCellData[] = [
            { displayValue: order.orderNumbers?.find((orderNumber) => orderNumber.type === OrderIdSource.Wholesaler)?.number, id: "1" },
            { displayValue: date(order.orderDate, "d"), id: "2" },
            { displayValue: <VehicleInfo order={order} licensePlateOnTop shortCountryCode={shortCountryCode} />, id: "3" },
        ]
        return data
    }

    function onRowClick(row: TableRowData) {
        openDetails(row.id, VoucherType.Order)
    }

    const displayData = filteredData.map((order) => ({
        cells: getCells(order),
        id: `${order.orderVoucherId}`,
        customRow: false,
        active: selectedVoucherId === order.orderVoucherId,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable variant="small" columns={getColumns()} rows={displayData} rowCap={4} onRowClick={onRowClick} />
}
