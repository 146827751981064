import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { useExternalCatalogUrl } from "@tm/utils"
import { useMemo } from "react"
import { ArticleListType } from "@tm/models"
import { useArticleListConfiguration } from "../ArticleListConfiguration"
import { ListOptions } from "../models"
import { getBundleParams } from "../../../utils"
import { usePartsViewOptions } from "./usePartsViewOptions"

/** @todo Merge somehow with `ArticleListConfiguration` */
export function useListOptions(type: ArticleListType): ListOptions {
    const { addToBasketExternalSystemId } = Morpheus.getParams("basket")
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId: addToBasketExternalSystemId,
        telesalesCustomerNo,
    })
    const { hideCostEstimationButton } = getBundleParams()

    const { showDataSupplierLogos, highlightCostEstimationButton, hasWatchList } = useArticleListConfiguration()
    const {
        showPurchasePrice,
        partsViewSettings: { showArticleImages },
    } = usePartsViewOptions()

    return useMemo(() => {
        switch (type) {
            default:
                return {
                    showDocumentsInline: false,
                    showDocumentsInCompact: false,
                    openDocumentsAsModal: true,
                    showReferenceLinksInCompact: false,
                    showDataSupplierLogos,
                    highlightCostEstimationButton,
                    externalCatalogUrl,
                    hasWatchList,
                    hideCostEstimationButton,
                    showPurchasePrice,
                    showArticleImages,
                }
        }
    }, [
        externalCatalogUrl,
        highlightCostEstimationButton,
        showDataSupplierLogos,
        hasWatchList,
        type,
        hideCostEstimationButton,
        showPurchasePrice,
        showArticleImages,
    ])
}
