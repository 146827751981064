import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useMemo, useRef, useState } from "react"
import EnlargeImage from "../../modal/enlargeImage"

type Props = {
    image: string
    resetImage(id: string, image: string): void
    updateImage(id: string, image: string): void
    imageId: string
}

export default function ShowPicture(props: Props) {
    const style = useMemo(() => getStyle(), [])
    const { translateText } = useLocalization()
    const imageRef = useRef<HTMLImageElement>(null)

    const [show, setShow] = useState(false)

    const rotateImage = () => {
        if (imageRef.current) {
            const c = document.createElement("canvas")
            c.width = imageRef.current.naturalHeight
            c.height = imageRef.current.naturalWidth

            const context = c.getContext("2d")
            if (context) {
                const x = c.width / 2
                const y = c.height / 2
                const width = imageRef.current.naturalWidth
                const height = imageRef.current.naturalHeight

                const angleInDegrees = 90
                const angleInRadians = (angleInDegrees * Math.PI) / 180

                context.translate(x, y)
                context.rotate(angleInRadians)
                context.drawImage(imageRef.current, -width / 2, -height / 2, width, height)
                const data = c.toDataURL("image/jpg")
                props.updateImage(props.imageId, data)
            }
        }
    }

    const handleResetImage = () => {
        props.resetImage(props.imageId, props.image)
    }

    return (
        <div className={style.pictureDisplay}>
            <div className={style.buttons}>
                <Button icon="rotate" onClick={rotateImage} size="s">
                    {translateText(12687)}
                </Button>
                <Button icon="delete" onClick={handleResetImage} size="s">
                    {translateText(69)}
                </Button>
            </div>
            <div className={style.wrapper} onClick={() => setShow(true)}>
                <img src={props.image} className={style.image} ref={imageRef} />
                <div className={style.preview}>
                    <Icon name="search" className={style.searchIcon} />
                </div>
            </div>
            <EnlargeImage open={show} onHide={setShow} content={<img src={props.image} className={style.image} />} />
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            position: "relative",
            marginTop: theme.margin.m,
            textAlign: "center",
        },
        image: {
            maxHeight: "25.5em",
            maxWidth: "100%",
        },
        pictureDisplay: {
            flex: "1 1 18%",
        },
        buttons: {
            display: "flex",
        },
        preview: {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0,
            backgroundColor: "rgba(0,0,0,0.6)",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 10,
            height: "100%",
            width: "100%",
            $nest: {
                "&:hover": {
                    opacity: 1,
                },
            },
        },
        searchIcon: {
            fill: "#fff",
        },
    })(ShowPicture)
}
