import { styled, Typography, Loader } from "@tm/components";
import { useWorkTask } from "@tm/context-distribution";
import { Button, Table, Tooltip } from "@tm/controls";
import { useLocalization } from "@tm/localization";
import { AddCustomWorkListRequest,  channel, CustomWork, RepairTimeProvider, WorkCategory } from "@tm/models";
import { ServiceType } from "../../../data/models";
import { useServicesStore } from "../../../data/state";
import { useBasketWorks } from "../../../../../basket/src/hooks/basketState/useBasketWorks"

const { Cell, Column } = Table

export default function ServicesList() {
    const { loadingServices, services } = useServicesStore()
    const workTaskId = useWorkTask()?.workTask?.id || ""
    const { translateText, languageId } = useLocalization()
    const {addCustomWorkList} = useBasketWorks()

    const handleCostEstimationButtonClick = (service: ServiceType) => {

        const cw: CustomWork = {
            description: service.bez,
            customWorkNumber: service.nr,
            categoryOfWork: WorkCategory.NotCategorized,
            time: service.praemienAW || 0,
            fixedPriceValue: !service.praemienAW ? 0 : undefined
        }

        const request: AddCustomWorkListRequest = {
            workTaskId,
            customWorks: [cw],
            repairTimeProvider: RepairTimeProvider.ATU,// add atu
        }

        addCustomWorkList(request).then(
            res => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12998),
                    icon: "check",
                    skin: "success",
                    closeDelay: 3000
                })
            },
            _ => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12997),
                    icon: "error",
                    skin: "warning",
                    closeDelay: 3000
                })
            })
    }

    const renderKVA = (service: ServiceType) => {
        return <Tooltip content={translateText(12994)} >
            <Button
                // disabled={isInKVA}
                onClick={() => handleCostEstimationButtonClick(service)}
                id="voucher-kva"
                icon={languageId === "1" ? "voucher-kva" : "voucher-kva-international"}
            >
            </Button>
        </Tooltip>
    }

    const renderTable = (_services: ServiceType[]) => {
        return <StyledTable
            data={_services}
            columns={[
                <Column renderItemContent={(x: ServiceType) => <Cell>{x.bezKey}</Cell>}>
                </Column>,
                <Column className="fancy-list__block--description" renderItemContent={(x: ServiceType) => <Cell>{x.bez}</Cell>}>
                </Column>,
                <Column renderItemContent={(x: ServiceType) => <Cell>{x.praemienAW}</Cell>}>
                </Column>,
                <Column className="fancy-list__block--actions" renderItemContent={(x: ServiceType) => <Cell>
                    {renderKVA(x)}
                </Cell>} />,
            ]} />
    }

    return <>
        {loadingServices && <Loader />}
        {!loadingServices && !!services?.length &&
            <>{
                services.map(svc => <>
                    <Typography sx={{ marginTop: "1em" }} variant="h4" gutterBottom component="div">
                        {svc?.first()?.bezK}
                    </Typography>
                    {renderTable(svc)}
                </>)
            }
            </>
        }</>
}

const StyledTable = styled(Table)({
    ".fancy-list__block": {
        flexGrow: 1,
        flexBasis: "3em",
        justifyContent: "center",
        "&--description": {
            flexGrow: 7
        },
        "&--actions": {
            display: "flex",
            justifyContent: "flex-end"
        }
    }
})