import { Article, DefaultArticleItemState, Note } from "@tm/models"
import { useCallback, useEffect, useState } from "react"
import { isEqual } from "lodash"
import { usePartsModuleState } from "../../PartsModuleState"
import { useVehicle } from "../useVehicle"
import { getArticleNoteIdentifier } from "../../../_shared/article-item/note/helper/mapper"

export function useArticleNotes(article: Article): DefaultArticleItemState["notes"] {
    const vehicle = useVehicle()
    const listType = usePartsModuleState((x) => x.type)
    const { notes: allNotes, deleteNote, saveNote } = usePartsModuleState((x) => x.notes)

    const [notes, setNotes] = useState<Note[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setNotes((prev) => {
            const queryIds = getArticleNoteIdentifier(article, listType === "vehicle" ? vehicle : undefined)
            const filtered = allNotes.filter((x) => queryIds.includes(x.noteId))
            if (!isEqual(filtered, prev)) {
                return filtered
            }
            return prev
        })
    }, [allNotes, article, listType, vehicle?.id])

    const handleSave = useCallback(async (note: Note) => {
        setIsLoading(true)
        await saveNote(note)
        setIsLoading(false)
    }, [])

    const handleDelete = useCallback(async (noteId: string) => {
        setIsLoading(true)
        await deleteNote(noteId)
        setIsLoading(false)
    }, [])

    const toggle = useCallback(() => {
        setVisible((prev) => !prev)
    }, [])

    const hide = useCallback(() => {
        setVisible(false)
    }, [])

    return { notes, handleSave, handleDelete, isLoading, visible, toggle, hide }
}
