import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { notUndefinedOrNull, useErpConfig } from "@tm/utils"
import { Article, ArticleErpInfo, ErpInformation, ErpInformationResponse, ErpSystemConfig, GetErpInfosRequest } from "@tm/models"
import { chunk as createChunks, uniqBy } from "lodash"
import { useCallback, useEffect, useMemo } from "react"
import { useRecoilState } from "recoil"
import { getErpInfos } from "../../../../../../erp/src/data"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { DirectSearchStartParams, ErpSystemLoadingGroup, StartParams } from "../../models"
import { ErpInfosState } from "../../states"
import { useDefaultErpSystem } from "../useDefaultErpSystem"
import { ArticleTradeReferences } from "../useTradeReferences"
import { useVehicle } from "../useVehicle"
import { ArticleErpInfoFactory, createGetErpInfosRequest } from "./factories"

type ErrorResponses = Omit<ErpInformationResponse, "items"> & { request?: GetErpInfosRequest }

export function useErpInfos(articles: Article[], isEnabled: boolean, startParams: StartParams, tradeReferences: ArticleTradeReferences[]) {
    const searchQuery: string | undefined = (startParams as DirectSearchStartParams)?.query
    const { erpPageSize } = useArticleListConfiguration()
    const vehicle = useVehicle()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const [erpInfo, setErpInfo] = useRecoilState(ErpInfosState)
    const defaultErpSystem = useDefaultErpSystem()
    const { erpSystemConfigs, useOrderByDistributor } = useErpConfig()

    useEffect(
        function prepareRequests() {
            if (!isEnabled || !defaultErpSystem || !articles.length) {
                return
            }

            setErpInfo((prev) => {
                let mapped = prev

                // uniqBy is needed because we might have duplicate articles with the same internal id
                const itemsToLoad = uniqBy(
                    articles
                        .filter((x) => x.requestErpInfo)
                        .map<ArticleErpInfo>((article) => ArticleErpInfoFactory.createQueued(article, defaultErpSystem))
                        .filter((item) => !mapped.some((prevItem) => item.key.id === prevItem.key.id)),
                    (x) => x.key.id
                )

                if (itemsToLoad.length) {
                    mapped = [...mapped, ...itemsToLoad]
                }

                return mapped
            })
        },
        [isEnabled, defaultErpSystem, articles, setErpInfo]
    )

    // creates the erp request for the alternative erp system, when MRE is active
    const createArticleErpInfo = useCallback(
        (references: ArticleTradeReferences): ArticleErpInfo | undefined => {
            const alternativeErpSystem = erpSystemConfigs?.[1]
            if (!alternativeErpSystem) {
                return
            }

            const [supplierArticleWithId, tradeReferenceNumbers] = references
            const article = articles.find(
                (x) => x.supplier.id === supplierArticleWithId.supplierId && x.supplierArticleNo === supplierArticleWithId.supplierArticleNo
            )
            const traderReferenceNumber = tradeReferenceNumbers?.find((x) => x.erpSystemId === alternativeErpSystem.id)
            if (traderReferenceNumber && article) {
                const articleErpInfo = ArticleErpInfoFactory.createQueued(article, alternativeErpSystem)
                articleErpInfo.request.wholesalerArticleNumber = traderReferenceNumber.traderArticleNumber
                return articleErpInfo
            }
        },
        [articles, erpSystemConfigs]
    )

    // add extra erp request for the alternative erp system, when MRE is active. MRE is active when useOrderByDistributor is true.
    useEffect(
        function addAdditionalRequests() {
            if (!useOrderByDistributor || !tradeReferences.length || !erpSystemConfigs || erpSystemConfigs.length < 2) {
                return
            }

            const alternativeErpSystem = erpSystemConfigs[1]

            const erpsToLoad = tradeReferences
                .filter(([, tradeReferenceNumbers]) => tradeReferenceNumbers?.some((refNumber) => refNumber.erpSystemId === alternativeErpSystem.id))
                .map(createArticleErpInfo)
                .filter(notUndefinedOrNull)

            if (erpsToLoad.length) {
                setErpInfo((prev) => {
                    let newList = prev
                    erpsToLoad.forEach((erpToLoad) => {
                        if (!newList.some(({ key }) => key.id === erpToLoad.key.id)) {
                            newList = [...newList, erpToLoad]
                        }
                    })
                    return newList
                })
            }
        },
        [tradeReferences, useOrderByDistributor, articles, erpSystemConfigs, createArticleErpInfo, setErpInfo]
    )

    useEffect(
        function loadErpInformation() {
            async function fetchErpInformation() {
                if (!defaultErpSystem) {
                    return
                }

                const itemsToLoad = erpInfo.filter((x) => x.state === "queued")

                if (!itemsToLoad.length) {
                    return
                }

                setErpInfo((prev) => {
                    let changed = false

                    const mapped = prev.map<ArticleErpInfo>((prevItem) => {
                        const itemToLoad = itemsToLoad.find((x) => x.key.id === prevItem.key.id)

                        if (!itemToLoad) {
                            return prevItem
                        }

                        changed = true

                        return ArticleErpInfoFactory.createLoading(prevItem)
                    })

                    return changed ? mapped : prev
                })

                const erpSystemChunks = createErpSystemChunks(itemsToLoad, erpPageSize, erpSystemConfigs)

                const responses = await Promise.allSettled(
                    erpSystemChunks.map(({ chunk, erpSystemId }) =>
                        getErpInfos(createGetErpInfosRequest(chunk, erpSystemId, searchQuery, telesalesCustomerNo, vehicle))
                    )
                )

                const errorResponses: ErrorResponses[] = []
                const responseItems: ErpInformation[] = []

                responses.forEach((response) => {
                    if (response.status === "rejected") {
                        errorResponses.push({
                            hasErrors: true,
                            errorText: typeof response.reason === "string" ? response.reason : undefined,
                            request: response.reason.data,
                        })
                    } else if (response.value.hasErrors || !response.value.items?.length) {
                        errorResponses.push({ ...response.value, request: response.value.request })
                    } else {
                        responseItems.push(...response.value.items)
                    }
                })

                if (!responseItems.length && !errorResponses.length) {
                    return
                }

                setErpInfo((prev) => {
                    let changed = false
                    const mapped: ArticleErpInfo[] = [...prev]

                    responseItems.forEach((item) => {
                        const index = mapped.findIndex((prevItem) => prevItem.request.itemId === item.itemId)

                        if (index === -1) {
                            return
                        }

                        changed = true

                        mapped[index] = ArticleErpInfoFactory.createFromResponse(mapped[index], item)

                        // // Store the erp response under the division quantity to prevent a second request
                        const { division = 0, requestedValue = 0 } = item.quantity ?? {}
                        if (division > 1 && requestedValue !== requestedValue.ceil(division)) {
                            mapped.push(ArticleErpInfoFactory.createWithDifferentQuantity(mapped[index], division))
                        }
                    })

                    errorResponses.forEach((errorResponse) => {
                        errorResponse.request?.items.forEach((requestItem) => {
                            const index = mapped.findIndex((prevItem) => prevItem.request.itemId === requestItem.itemId)

                            if (index === -1) {
                                return
                            }

                            changed = true

                            mapped[index] = ArticleErpInfoFactory.createFromError(mapped[index], errorResponse)
                        })
                    })

                    return changed ? mapped : prev
                })
            }
            fetchErpInformation()
        },
        [defaultErpSystem, erpInfo, searchQuery, telesalesCustomerNo, vehicle?.id, setErpInfo, erpPageSize, erpSystemConfigs, vehicle]
    )

    useEffect(
        function resetErpInfos() {
            setErpInfo([])
            return () => setErpInfo([])
        },
        [startParams, setErpInfo]
    )

    return useMemo(
        () => !erpSystemConfigs?.length || (!!articles.length && !!erpInfo.some((x) => x.state === "success" || x.state === "error")),
        [articles, erpInfo, erpSystemConfigs]
    )
}

// Group by erpSystemId and create chunks for every group using the articleCount which is defined for the related erpSystem
function createErpSystemChunks(itemsToLoad: ArticleErpInfo[], defaultPageSize: number, erpSystemConfigs?: ErpSystemConfig[]) {
    return itemsToLoad
        .reduce<ErpSystemLoadingGroup[]>((list, item) => {
            let group = list.find((x) => x.erpSystemId === item.key.erpSystemId)
            if (!group) {
                group = {
                    erpSystemId: item.key.erpSystemId,
                    chunkSize: erpSystemConfigs?.find((config) => config.id === item.key.erpSystemId)?.erpRequestArticleCount ?? defaultPageSize,
                    items: [],
                }
                list.push(group)
            }
            group.items.push(item.request)
            return list
        }, [])
        .flatMap(({ chunkSize, erpSystemId, items }) =>
            createChunks(items, chunkSize).map((chunk) => ({
                erpSystemId,
                chunk,
            }))
        )
}
