import { Dictionary, uniqueId } from "@tm/utils"
import { StatusCodes } from "http-status-codes"

import { Stocks } from "../../business/gateway"
import { messageChannel } from "../../business/messaging"

const bufferTimespanMs = 25 // defines the timespan (in ms) in which the requests will be buffered
const maxQueueLength: number | undefined = undefined // defines the maximum number of items in a single service call

type LookupRequest = {
    vehicleReferenceId?: string
    items: Array<Stocks.LookupItem>
}

type BufferedRequest<Request, Response> = {
    data: Request
    resolve: (response: Response) => void
    reject: (error: string) => void
}

const queue: Array<BufferedRequest<LookupRequest, Array<Stocks.StockItem>>> = []
let bufferTimeoutId: number

export function showAvailabilityBuffered(request: LookupRequest): Promise<Array<Stocks.StockItem>> {
    function get() {
        const requests = queue.splice(0, queue.length) // Splice to remove request items from queue...

        // Group all requests by vehicleReferenceId
        const lookupItemsByVehicleReferenceId: Dictionary<Array<Stocks.LookupItem>> = {}
        requests.forEach((x) => {
            const { vehicleReferenceId = "" } = x.data

            if (!lookupItemsByVehicleReferenceId[vehicleReferenceId]) {
                lookupItemsByVehicleReferenceId[vehicleReferenceId] = []
            }

            lookupItemsByVehicleReferenceId[vehicleReferenceId].push(...x.data.items)
        })

        let processIds: Array<string> = [] // Used to track open requests

        const unsub = messageChannel().subscribe("DMS_RESPONSE_RECEIVED", (dmsResponse) => {
            // Ignore other response types and check if "processId" is from this call
            if (dmsResponse.type !== "showAvailability" || !processIds.includes(dmsResponse.processId)) {
                return
            }

            // Remove the current "processId" from the array of "open requests"
            processIds = processIds.filter((x) => x !== dmsResponse.processId)

            // Get all "requests" for this "vehicleReferenceId"
            const requestsForVehicleReferenceId = requests.filter(
                // The rule "eqeqeq" is disabled on purpose because `null == undefined` should be `true` in this case
                // eslint-disable-next-line eqeqeq
                (requestForVehicleReferenceId) => requestForVehicleReferenceId.data.vehicleReferenceId == dmsResponse.request.vehicleReferenceId
            )

            if (dmsResponse.status === StatusCodes.OK && dmsResponse.response) {
                const stockItems = dmsResponse.response.items

                // Iterate over all "requests" ...
                requestsForVehicleReferenceId.forEach((requestForVehicleReferenceId) => {
                    // ... and get all according "items"
                    const items = stockItems.filter((x) => requestForVehicleReferenceId.data.items.some((y) => x.id === y.id))

                    if (items.length) {
                        requestForVehicleReferenceId.resolve(items)
                    } else {
                        requestForVehicleReferenceId.reject("No corresponding item in response")
                    }
                })
            } else {
                // Response failed: reject all "requestsForVehicleReferenceId"
                requestsForVehicleReferenceId.forEach((requestForVehicleReferenceId) =>
                    requestForVehicleReferenceId.reject("Request failed or no (valid) response")
                )
            }

            // If there are no more open processes, unsubscribe
            if (!processIds.length) {
                unsub?.()
            }
        })

        // Send "showAvailability" request for each requested "vehicleReferenceId" and the according aggregated "lookupItems"
        Object.entries(lookupItemsByVehicleReferenceId).forEach(([vehicleReferenceId, lookupItems]) => {
            const processId = uniqueId() // Generate a unique "processId" for each request ...
            processIds.push(processId) // ... and push it to the "processIds" array

            messageChannel().publish("SEND_DMS_REQUEST", {
                processId,
                type: "showAvailability",
                request: { vehicleReferenceId: vehicleReferenceId || undefined, items: lookupItems },
            })
        })
    }

    return new Promise<Array<Stocks.StockItem>>((resolve, reject) => {
        queue.push({
            data: request,
            resolve,
            reject,
        })

        window.clearTimeout(bufferTimeoutId)

        if (maxQueueLength && queue.length >= maxQueueLength) {
            get()
            return
        }

        bufferTimeoutId = window.setTimeout(get, bufferTimespanMs)
    })
}

export function mapStockItemsFromOldGsiConnectService(
    stockItem: Stocks.StockItem | undefined,
    gsiConnectVersion: string | undefined
): Stocks.StockItem | undefined {
    if (stockItem && !gsiConnectVersion) {
        if (stockItem.availability === Stocks.Availability.NotAvailable) {
            stockItem.availability = Stocks.Availability.Undefined
        }
    }
    return stockItem
}
