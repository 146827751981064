import { useUser } from "@tm/context-distribution"
import { Switch } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box } from "@tm/components"
import { FC, useEffect, useState } from "react"

const OptionsSwitch: FC = () => {
    const user = useUser()
    const { translateText } = useLocalization()
    const externalModules = user?.userContext?.externalModules
    
    const [switchStates, setSwitchStates] = useState<{
        showPurchasePrice: boolean | undefined;
        hideWheelsAvailability: boolean | undefined;
    }>({
        showPurchasePrice: user?.userSettings?.showPurchasePrice,
        hideWheelsAvailability: user?.userSettings?.hideWheelsAvailability,
    });

    useEffect(() => {
        if (switchStates.hideWheelsAvailability) {
            user.setUserSetting("SHOW_PURCHASE_PRICE", false);
            setSwitchStates((prevState) => ({
                ...prevState,
                showPurchasePrice: false,
            }));
        }
    }, [switchStates.showPurchasePrice, switchStates.hideWheelsAvailability])

    useEffect(() => {
        setSwitchStates((prevState) => ({
            ...prevState,
            showPurchasePrice: user?.userSettings?.showPurchasePrice,
            hideWheelsAvailability: user?.userSettings?.hideWheelsAvailability
        }));
    }, [user?.userSettings?.showPurchasePrice, user?.userSettings?.hideWheelsAvailability]);

    const handleChangeShowPurchasePrice = (showPurchasePrice: boolean) => {
        if (user?.userSettings){
            user.setUserSetting("SHOW_PURCHASE_PRICE", showPurchasePrice)
            setSwitchStates((prevState) => ({
                ...prevState,
                showPurchasePrice: showPurchasePrice,
            }));
        }
    }

    const handleChangeShowAvailability = (availability: boolean) => {
        if(user?.userSettings){
            user.setUserSetting("HIDE_WHEELS_AVAILABILITY", !availability)
            setSwitchStates((prevState) => ({
                ...prevState,
                hideWheelsAvailability: !availability,
            }));
        }
    }

    return (
        <>
            {!!externalModules && externalModules.some(externalModule => externalModule.isInfoEnabled) &&
                <Box display={"flex"} flexDirection={"column"} p={.5}>
                    <Switch
                        label={translateText(13669)}
                        status={!switchStates.hideWheelsAvailability}
                        onChange={handleChangeShowAvailability}
                        alignLabel="right"
                    />
                    {!user?.userContext?.parameter.purchasePricesDisabled &&
                        <Box pt={.5}>
                            <Switch
                                label={translateText(1132)}
                                status={switchStates.showPurchasePrice}
                                onChange={handleChangeShowPurchasePrice}
                                alignLabel="right"
                            />
                        </Box>
                    }
                </Box>
            }
        </>
    )
}

export default OptionsSwitch