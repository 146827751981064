import { IAttributesItem } from "../../../../data/models"

export * from "./createArticleRequest"
export * from "./createAttributeFiltersRequest"
export * from "./createNextArticlesRequest"
export * from "./filterUsedCriteria"
export * from "./getSelectedCriterias"
export * from "./getProductGroupsIdsFromArticles"


export const getAttributeString = (attr: IAttributesItem) => `${attr.id}|${attr.value}`