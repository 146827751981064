import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import color from "tinycolor2"

function merge(getStylesFn: string, baseStyles: NestedCSSProperties): NestedCSSProperties {
    const { name, pageNavigation } = getStyleTheme()
    let getStyles: any
    try {
        // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
        const pack = require(`./themes/${name}`)
        getStyles = pack ? pack[getStylesFn] : undefined
    } catch {}

    if (pageNavigation?.layout) {
        let getLayouts: any
        try {
            // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
            const pack = require(`./layouts/${pageNavigation.layout}`)
            getLayouts = pack ? pack[getStylesFn] : undefined
        } catch {}
        return getLayouts ? getLayouts(baseStyles) : baseStyles
    }
    return getStyles ? getStyles(baseStyles) : baseStyles
}

export function getItemStyles(): NestedCSSProperties {
    const { colors, margin, opacity, box, pageNavigation } = getStyleTheme()
    const component = {
        back: "transparent",
        fore: pageNavigation && pageNavigation.itemColor ? pageNavigation.itemColor : color(colors.light).setAlpha(opacity.secondary).toRgbString(),
        hover: {
            back: color(colors.light).setAlpha(0.15).toRgbString(),
            fore:
                pageNavigation && pageNavigation.itemHoverColor
                    ? pageNavigation.itemHoverColor
                    : color(colors.light).setAlpha(opacity.primary).toRgbString(),
        },
        active: {
            back: color(colors.dark).lighten(84).toString(),
            fore: colors.dark,
        },
        more: {
            fore: colors.dark,
        },
        border: {
            color:
                pageNavigation && pageNavigation.itemBorderLeftColor
                    ? pageNavigation.itemBorderLeftColor
                    : color(colors.light).setAlpha(opacity.secondary).toRgbString(),
        },
    }

    const styles: NestedCSSProperties = {
        backgroundColor: component.back,
        color: component.fore,
        borderLeftColor: component.border.color,
        borderRadius: box.radius,
        padding: `${margin.s} ${margin.m}`,
        margin: `0 ${margin.s}`,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        whiteSpace: "nowrap",
        border: "2px solid transparent",
        // transition: "all 0.2s ease",
        cursor: "pointer",
        maxWidth: "12em",
        height: "3rem",
        position: "relative",
        $nest: {
            ".plate": {
                opacity: opacity.secondary,
            },
            "&__content": {
                paddingLeft: "2px",
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "flex-start",
                overflow: "hidden",
                lineHeight: 1.3,
            },
            "&__title": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
            },
            "&__info": {
                fontSize: "0.6875rem",
                whiteSpace: "nowrap",
                $nest: {
                    "&:not(:empty)": {
                        marginTop: "0.25rem",
                    },
                },
            },
            "&__close": {
                display: "flex",
                marginLeft: ".3em",
                padding: 0,
                background: "none",
                $nest: {
                    ".icon": {
                        opacity: "1 !important",
                        height: "1.5em",
                        width: "1.5em",
                        fill: component.fore,
                    },
                    "&:hover, &:active": {
                        background: "none",
                    },
                    "&:hover .icon": {
                        fill: `${color(component.fore).setAlpha(0.54).toRgbString()} !important`,
                    },
                },
            },
            "&--selected, &:active": {
                backgroundColor: component.active.back,
                color: component.active.fore,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderLeftColor: "transparent",
            },
            "&--selected": {
                transform: "translateY(0.65rem)",
                $nest: {
                    ".icon:hover": {
                        fill: `${color(component.active.fore).setAlpha(0.54).toRgbString()} !important`,
                    },
                },
            },
            "&--selected .icon, &:active .icon": {
                fill: component.active.fore,
            },
            "&--selected .plate, &:active .plate": {
                opacity: opacity.primary,
            },
            "&:not(&--selected)": {
                borderLeftColor: component.border.color,
            },
            "&:hover:not(&--selected)": {
                backgroundColor: component.hover.back,
                borderLeftColor: component.border.color,
                color: component.hover.fore,
                $nest: {
                    ".icon": {
                        fill: component.fore,
                    },
                    ".plate": {
                        opacity: opacity.primary,
                    },
                },
            },
            "&--more": {
                display: "flex",
                padding: margin.m,
                color: component.more.fore,
                opacity: opacity.secondary,
            },
            "&--more:hover:not(&--selected)": {
                color: component.more.fore,
                opacity: 1,
                background: component.hover.back,
                $nest: {
                    ".icon": {
                        fill: `${component.more.fore} !important`,
                    },
                },
            },
            "&--more &__close .icon": {
                fill: component.more.fore,
                opacity: 1,
            },
            "&--more &__content": {
                flex: 1,
            },
            "&:last-child": {
                borderRight: `2px solid ${component.border.color}`,
            },
        },
    }
    return merge("getItemStyles", styles)
}

export function getMoreItemStyles(): NestedCSSProperties {
    const { colors, margin, opacity, box } = getStyleTheme()
    const component = {
        back: "transparent",
        fore: color(colors.dark).setAlpha(opacity.secondary).toRgbString(),
        hover: {
            back: color(colors.highlight).setAlpha(0.15).toRgbString(),
            fore: color(colors.dark).setAlpha(opacity.primary).toRgbString(),
        },
    }

    const styles: NestedCSSProperties = {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        padding: margin.m,
        height: "3em",
        maxWidth: "20em",
        $nest: {
            "&__content": {
                flex: 1,
                lineHeight: 1.3,
                minWidth: "10em",
            },
            "&__title": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
            },
            "&__info": {
                fontSize: "0.6875rem",
                whiteSpace: "nowrap",
                $nest: {
                    "&:not(:empty)": {
                        marginTop: "0.25rem",
                    },
                },
            },
            "&__close": {
                display: "flex",
                padding: 0,
                background: "none",
                marginLeft: margin.m,
                $nest: {
                    ".icon": {
                        opacity: "1 !important",
                        height: "1.5em",
                        width: "1.5em",
                        fill: component.fore,
                    },
                    "&:hover, &:active": {
                        background: "none",
                    },
                    ".icon:hover": {
                        fill: `${colors.dark} !important`,
                    },
                },
            },
            "&:hover": {
                background: component.hover.back,
            },
        },
    }

    return merge("getMoreItemStyles", styles)
}

export function getPlateIdStyle(): NestedCSSProperties {
    const { colors, opacity } = getStyleTheme()

    const styles: NestedCSSProperties = {
        display: "flex",
        width: "9.25em",
        borderRadius: "2px",
        border: "1px solid transparent",
        position: "relative",
        whiteSpace: "nowrap",
        // paddingRight: "0.2em",
        fontSize: "0.8rem",
        background: colors.light,
        borderColor: color(colors.dark).lighten(50).toString(),
        color: colors.dark,
        $nest: {
            "&__fx": {
                borderRadius: "1px 0 0 1px",
                width: "2.3em",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                color: color(colors.light)
                    .setAlpha(opacity?.primary || 0.87)
                    .toString(),
                paddingBottom: "2px",
                backgroundImage: "url(/styles/default/images/eu-stars.svg)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center 3px",
                backgroundColor: "#003bb2",
            },
            "&__value": {
                fontSize: "1.2em",
                padding: "0.2em 0.3em",
                opacity: opacity?.primary || 0.87,
                lineHeight: 1,
            },
            "&--s &__fx": {
                fontSize: 0,
                minWidth: ".8rem",
            },
            "&--nl": {
                background: "#fdd504",
            },
        },
    }
    return merge("getPlateIdStyle", styles)
}

export function getOverflowMenuStyles(): NestedCSSProperties {
    const { colors, opacity, pageNavigation } = getStyleTheme()

    const moreComponent = {
        back: "transparent",
        fore: pageNavigation && pageNavigation.itemColor ? pageNavigation.itemColor : color(colors.light).setAlpha(opacity.secondary).toRgbString(),
        hover: {
            back: color(colors.light).setAlpha(0.15).toRgbString(),
            fore:
                pageNavigation && pageNavigation.itemHoverColor
                    ? pageNavigation.itemHoverColor
                    : color(colors.light).setAlpha(opacity.primary).toRgbString(),
        },
        border: {
            color:
                pageNavigation && pageNavigation.itemBorderLeftColor
                    ? pageNavigation.itemBorderLeftColor
                    : color(colors.light).setAlpha(opacity.secondary).toRgbString(),
        },
    }

    const styles: NestedCSSProperties = {
        position: "relative",
        flex: 1,
        display: "flex",
        alignSelf: "stretch",
        height: "4em",
        marginRight: "0.5em",
        $nest: {
            "&__main": {
                width: "100%",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                alignItems: "center",
                $nest: {
                    "&__content": {
                        position: "absolute",
                        display: "flex",
                        height: "100%",
                        top: 0,
                        alignItems: "center",
                    },
                },
            },
            "&__more": {
                // borderLeft: "2px solid transparent",
                // borderLeftColor: "#dedfe0",
                display: "flex",
                position: "absolute",
                height: "100%",
                alignItems: "center",
                $nest: {
                    "&__content": {
                        padding: 0,
                        overflow: "auto",
                        maxHeight: "25em",
                    },
                    ".close-all": {
                        width: "100%",
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                    },
                },
            },
            "&__btn": {
                height: "3rem",
                background: "none",
                $nest: {
                    ".icon": {
                        opacity: 1,
                        fill: `${moreComponent.fore} !important`,
                    },
                    "&:hover": {
                        background: moreComponent.hover.back,
                        $nest: {
                            ".icon": {
                                fill: `${moreComponent.hover.fore} !important`,
                            },
                        },
                    },
                    "&:focus": {
                        background: moreComponent.hover.back,
                        color: "inherit",
                    },
                },
            },
            "&__popover": {
                $nest: {
                    ".popover__inner": {
                        padding: 0,
                    },
                },
            },
        },
    }
    return merge("getOverflowMenuStyles", styles)
}
