import { useState, useMemo, useEffect } from "react"
import { MenuItemWidget, Select, SelectProps } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"
import { plateCountryCodeMapper } from "@tm/utils"
import { useCustomerVehicles } from "../../data/hooks/useCustomerVehicles"
import { LoaderSmall } from "./StyledComponents"
import VehicleSelectorView from "./VehicleSelectorView"

export type VehicleSelectItem = {
    vehicleId?: string
    vehicleDescription: string
    vehiclePlate?: string
    shortCountryCode: string
}

type Props = {
    active: boolean
    disabled?: boolean
    selectedVehicleId?: string
    size?: "small" | "medium"
    fullWidth?: boolean
    autoWidth?: boolean
    variant?: "filled" | "outlined"
    color?: SelectProps["color"]
    onVehicleChanged(item: VehicleSelectItem): void
}

export default function VehicleSelectorComponent(props: Props) {
    const { active, disabled, selectedVehicleId, size, color, fullWidth, autoWidth, variant, onVehicleChanged } = props
    const { workTask } = useWorkTask() ?? {}

    const { translateText } = useLocalization()
    const { customerVehicles, customerVehiclesLoading } = useCustomerVehicles(workTask?.customer?.id, active)
    const { plateCode: defaultCountryCode } = useCountryCodeToLicensePlate()
    const textId = workTask && workTask.customer ? 1271 : 1272

    const [selectedItem, setSelectedItem] = useState<VehicleSelectItem>()
    const items: Array<VehicleSelectItem> = useMemo(() => {
        return customerVehicles?.length
            ? [
                  { vehicleId: undefined, vehicleDescription: translateText(textId), shortCountryCode: defaultCountryCode },
                  ...customerVehicles.map((vehicle) => {
                      const shortCountryCode = plateCountryCodeMapper(vehicle.countryCode || defaultCountryCode)
                      return {
                          vehicleId: vehicle.id,
                          vehiclePlate: vehicle.plateId,
                          vehicleDescription: `${vehicle.manufacturer} ${vehicle.modelSeries} ${vehicle.model}`,
                          shortCountryCode,
                      }
                  }),
              ]
            : []
    }, [customerVehicles, textId, defaultCountryCode])

    useEffect(() => {
        if (items.length) {
            setSelectedItem(items.find((x) => x.vehicleId === selectedVehicleId) || items[0])
        }
    }, [items])

    if (customerVehiclesLoading) {
        return <LoaderSmall />
    }

    if (!items?.length || !selectedItem) {
        return null
    }

    function handleChangeVehicle(item: VehicleSelectItem) {
        onVehicleChanged(item)
        setSelectedItem(item)
    }

    return (
        <Select
            value={selectedItem}
            onChange={(e) => handleChangeVehicle(e.target.value as VehicleSelectItem)}
            disabled={disabled}
            renderValue={(val) => {
                return <VehicleSelectorView vehicleSelectItem={val as VehicleSelectItem} />
            }}
            size={size}
            fullWidth={fullWidth}
            autoWidth={autoWidth}
            variant={variant}
            color={color}
        >
            {items.map((item, index) => {
                return (
                    <MenuItemWidget key={item.vehicleId || index.toString()} value={item}>
                        <VehicleSelectorView vehicleSelectItem={item} />
                    </MenuItemWidget>
                )
            })}
        </Select>
    )
}
