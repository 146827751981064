import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { DateUnit } from "@tm/utils"
import { TableCellData, CellContentPosition, TableColumnData } from "@tm/components"
import { VoucherType } from "@tm/models"
import { useRecoilValue } from "recoil"
import { useParams } from "react-router"
import { ReturnVoucherList } from "../../../../../data/model"
import { VoucherRouteParams } from "../../../../../business"
import { voucherListFilterAtom } from "../../../../../data/states/listFilters"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import { StyledTable } from "../../../../_shared/StyledComponents"
import VoucherDetailsButton from "../../../../_shared/VoucherDetailsButton"

type Props = {
    returns: Array<ReturnVoucherList>
}

export default function ReturnsList({ returns }: Props) {
    const { translateText, number, date } = useLocalization()
    const params = useParams<VoucherRouteParams>()
    const listFilters = useRecoilValue(voucherListFilterAtom(params.workTaskId || ""))

    const filteredData = useMemo(() => {
        return returns
            .filter(
                (x) =>
                    (x.returnDate.isAfter(listFilters.startDate, DateUnit.Day) || x.returnDate.isSame(listFilters.startDate, DateUnit.Day)) &&
                    (x.returnDate.isBefore(listFilters.endDate, DateUnit.Day) || x.returnDate.isSame(listFilters.endDate, DateUnit.Day))
            )
            .orderBy((x) => x.returnDate, true)
    }, [listFilters, returns])

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(470) }, // voucherNumber
            { header: translateText(98) }, // returnDate day
            { header: translateText(335) }, // returnDate time
            { header: `${translateText(479)}`, alignContent: CellContentPosition.center }, // numberOfPositions
            { header: `${translateText(89)}`, alignContent: CellContentPosition.center }, // numberOfParts
            { alignContent: CellContentPosition.right }, // Details
        ]

        return columns
    }

    function getCells(returnOrder: ReturnVoucherList): TableCellData[] {
        return [
            { displayValue: returnOrder.voucherNumber, id: "1" },
            { displayValue: date(returnOrder.returnDate, "d"), id: "2" },
            { displayValue: date(returnOrder.returnDate, "t"), id: "3" },
            { displayValue: returnOrder.totals?.numberOfPositions ? number(returnOrder.totals.numberOfPositions, 0) : "-", id: "4" },
            { displayValue: returnOrder.totals?.numberOfParts ? number(returnOrder.totals.numberOfParts, 0) : "-", id: "5" },
            {
                displayValue: <VoucherDetailsButton variant="normal" voucherId={returnOrder.returnVoucherId} voucherType={VoucherType.Return} />,
                id: "6",
            },
        ]
    }

    const displayData = filteredData.map((returnOrder) => ({
        cells: getCells(returnOrder),
        id: `${returnOrder.returnVoucherId}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} rowCap={4} />
}
