import { Component } from "react"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { bindSpecialReactMethods, withRouter, RouteComponentProps, isCentralOrder } from "@tm/utils"
import { Button, Toolbar, Headline } from "@tm/controls"
import Morpheus from "@tm/morpheus"
import { getBundleParams } from "../../utils"

type Props = LocalizationProps & RouteComponentProps<RouteParams>

type RouteParams = {
    workTaskId: string
    view?: string
}

type State = {
    listUrl?: string
    detailsUrl?: string
    repairTimesUrl?: string
}

class NavigationComponent extends Component<Props, State> {
    private isModalNavigation: boolean

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
        this.state = {}
    }

    componentDidMount() {
        this.setUrlsInState()
    }

    componentDidUpdate() {
        this.setUrlsInState()
    }

    setUrlsInState() {
        const { match, location } = this.props
        if (match.params.view) {
            const url = location.pathname + location.search

            if (match.params.view === "list" && this.state.listUrl !== url) {
                this.setState({ listUrl: url })
            } else if (match.params.view === "details" && this.state.detailsUrl !== url) {
                this.setState({ detailsUrl: url })
            } else if (match.params.view === "repair-times" && this.state.repairTimesUrl !== url) {
                this.setState({ repairTimesUrl: url })
            }
        }
    }

    renderPageButtons() {
        const {
            match,
            localization: { translate },
        } = this.props
        const { listUrl, detailsUrl, repairTimesUrl } = this.state

        let listIsActive = false
        let detailsIsActive = false
        let repairTimesIsActive = false

        if (match.params.view) {
            listIsActive = match.params.view === "list"
            detailsIsActive = match.params.view === "details"
            repairTimesIsActive = match.params.view === "repair-times"
        }

        return (
            <div className="page-buttons">
                <Button
                    linkTo={listUrl}
                    disabled={!listUrl}
                    className={listIsActive ? "is-active" : ""}
                    skin={listIsActive ? "primary" : undefined}
                    icon="menu"
                >
                    {translate(6)}
                </Button>
                <Button
                    linkTo={detailsUrl}
                    disabled={!detailsUrl}
                    className={detailsIsActive ? "is-active" : ""}
                    skin={detailsIsActive ? "primary" : undefined}
                    icon="details"
                >
                    {translate(43)}
                </Button>
                {!isCentralOrder() && !getBundleParams().nextLight && (
                    <Button
                        linkTo={repairTimesUrl}
                        disabled={!repairTimesUrl}
                        className={repairTimesIsActive ? "is-active" : ""}
                        skin={repairTimesIsActive ? "primary" : undefined}
                        icon="repairtimes"
                    >
                        {translate(83)}
                    </Button>
                )}
            </div>
        )
    }

    render() {
        const {
            localization: { translate, translateText },
            location,
        } = this.props

        const className = "tk-parts navigation navigation--dropdown navigation--modal"

        // NEXT-9810 if the parts modal is used inside fastCalculator module(which is already in a modal) we have to add another button to go back to previous modal
        const isModal = location.search.includes("isModal") || location.search.includes("isFastCalculator")
        if (isModal) {
            this.isModalNavigation = true
        }

        return (
            <div className={className}>
                {this.isModalNavigation && <Button icon="arrow-left" layout={["ghost"]} onClick={Morpheus.getHistory().goBack} />}
                <Headline size="s">{translate(993)}</Headline>
                <Toolbar title={translateText(176)}>{this.renderPageButtons()}</Toolbar>
            </div>
        )
    }
}

export default withLocalization(withRouter(NavigationComponent))
