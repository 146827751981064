import { Button, Icon, Box, styled, Typography } from "@tm/components"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { renderRoute, usePrevious } from "@tm/utils"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router"
import { useCallback, useEffect } from "react"
import { DatSelectionSteps } from "../../data/enums"
import { managerSelector, navigationSelector } from "../../selectors"
import { MainActions } from "../main/business"
import { CalculationActions, SummaryActions } from "./components"

type RouteProps = {
    workTaskId: string
    view: DatSelectionSteps
    contractId: string
    status: string
}

type Props = {
    defaultRoute: string
    detailsTabRoute: string
    showPrices?: boolean
}

export default function Navigation({ defaultRoute, detailsTabRoute, showPrices }: Props) {
    const history = useHistory()
    const match = useRouteMatch<RouteProps>()
    const { translateText } = useLocalization()
    const { active, initialized, params, externVisited, calcVisited, detailsVisited } = useSelector(navigationSelector)
    const { userIsRegistered } = useSelector(managerSelector)
    const previousActive = usePrevious(active)

    const actions = useActions(MainActions, "changeStep", "generalReset", "initModule")

    useEffect(() => {
        if (!initialized) {
            actions.initModule(match.params)
        }
    }, [])

    useEffect(() => {
        if (!match.params.view) {
            // onWidgetClick
            handleChangeStep(DatSelectionSteps.SUMARRY)
        }

        if (!!active && previousActive !== active) {
            handleChangeStep(active)
        }
    }, [active])

    const handleChangeStep = (newStep: DatSelectionSteps) => {
        const routeParams = {
            workTaskId: match.params.workTaskId,
            view: newStep,
            ...params[newStep],
            ...(newStep === DatSelectionSteps.EXTERN && {
                status: params[newStep]?.status,
            }),
        }

        const route = getRouteByActiveStep(newStep)
        let url = renderRoute(route, routeParams)

        // clear url on refresh from MVC DAT Registration page
        if (!location.search.includes("dat-registration")) {
            url += location.search
        }

        if (match.params.view === DatSelectionSteps.NONE || match.params.view === newStep) {
            history.replace(url)
        } else {
            history.push(url)
        }
    }

    const getRouteByActiveStep = (step: DatSelectionSteps) => {
        switch (step) {
            case DatSelectionSteps.DETAILS:
                return detailsTabRoute
            default:
                return defaultRoute
        }
    }

    const handleGeneralReset = () => {
        history.push(renderRoute(match.path, { ...match.params, view: DatSelectionSteps.SUMARRY, status: undefined, contractId: undefined }))
        actions.generalReset()
    }

    const renderModuleTabs = useCallback(
        () => (
            <StyledToolbar title={translateText(176)}>
                <Button
                    startIcon={<Icon name="summary" />}
                    variant="text"
                    color={active === DatSelectionSteps.SUMARRY ? "primary" : undefined}
                    onClick={() => actions.changeStep(DatSelectionSteps.SUMARRY)}
                >
                    {translateText(409)}
                </Button>
                <Button
                    startIcon={<Icon name="damage-calculation" />}
                    variant="text"
                    color={active === DatSelectionSteps.EXTERN ? "primary" : undefined}
                    disabled={!params[DatSelectionSteps.EXTERN]}
                    onClick={() => actions.changeStep(DatSelectionSteps.EXTERN)}
                >
                    {translateText(1613)}
                </Button>
                <Button
                    startIcon={<Icon name="calculation" />}
                    variant="text"
                    color={active === DatSelectionSteps.CALCULATION ? "primary" : undefined}
                    disabled={!params[DatSelectionSteps.CALCULATION]}
                    onClick={() => actions.changeStep(DatSelectionSteps.CALCULATION)}
                >
                    {translateText(613)}
                </Button>
                <Button
                    startIcon={<Icon name="details" />}
                    variant="text"
                    color={active === DatSelectionSteps.DETAILS ? "primary" : undefined}
                    disabled={!params[DatSelectionSteps.DETAILS]}
                    onClick={() => actions.changeStep(DatSelectionSteps.DETAILS)}
                >
                    {translateText(43)}
                </Button>
            </StyledToolbar>
        ),
        [active, externVisited, calcVisited, detailsVisited]
    )

    const renderOptionsTabs = () => {
        const resetButtonEnabled = [externVisited, calcVisited, detailsVisited].includes(true)

        return (
            <StyledToolbar title={translateText(177)}>
                <Button
                    variant="text"
                    color={active === DatSelectionSteps.DATSETTINGS ? "primary" : undefined}
                    disabled={!userIsRegistered}
                    onClick={() => actions.changeStep(DatSelectionSteps.DATSETTINGS)}
                >
                    {`DAT ${translateText(141)}`}
                </Button>

                <Button
                    variant="text"
                    color="error"
                    startIcon={<Icon name="synchronize" />}
                    disabled={!resetButtonEnabled}
                    onClick={handleGeneralReset}
                >
                    {translateText(48)}
                </Button>
            </StyledToolbar>
        )
    }

    return (
        <StyledNavigation>
            <Typography variant="h2" marginRight="1rem" alignSelf="center">
                {translateText(1464)}
            </Typography>

            {renderModuleTabs()}
            {renderOptionsTabs()}

            {match.params.view === DatSelectionSteps.SUMARRY && <SummaryActions />}
            {match.params.view === DatSelectionSteps.CALCULATION && <CalculationActions showPrices={showPrices} />}
        </StyledNavigation>
    )
}

const StyledNavigation = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(0.5, 1.5),
    paddingBottom: ".5em",
    justifyContent: "flex-start",
    borderBottom: "2px solid #e2e2e2",
}))

const StyledToolbar = styled(Toolbar)(() => ({
    flex: "auto",
    ".toolbar__content": {
        flexWrap: "wrap",
    },
}))
