import { withWorkTask, WithWorkTaskProps } from "@tm/context-distribution"
import { Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, ModulePropsMultiWidget, renderRoute, RouteComponentProps, uniqueId, useMultiWidgetState, withRouter } from "@tm/utils"
import { Box, SearchtreeTip, Typography } from "@tm/components"
import SearchField from "../_shared/search-field"
import { getBundleParams } from "../../utils"
import { Repositories } from "../../data"
import SearchTreeWidget from "../widget/components/SearchTreeWidget"

type Props = RouteComponentProps<{ workTaskId?: string }> &
    WithWorkTaskProps & {
        className?: string
        showComponentKey?: string
        vehicleSearchRoute: string
        moduleProps?: ModulePropsMultiWidget
    }

function VehicleSearchWidgetComponent(props: Props) {
    const { showComponentKey, vehicleSearchRoute, history, match } = props
    const nodeVariant = getBundleParams().searchtreeNodeVariant
    const [selectedMultiWidgetTab] = useMultiWidgetState(props.moduleProps)
    const { translateText } = useLocalization()

    const vehicle = props.workTask?.vehicle

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    function handleSearch(query: string) {
        const url = renderRoute(vehicleSearchRoute, {
            ...match.params,
            workTaskId: match.params.workTaskId || encodeUniqueId(uniqueId()),
        })
        history.push(`${url}?query=${query}`)
    }

    const className = `tk-parts vehicle-search-widget ${props.className || ""}`

    return (
        <Widget id="parts__vehicle-search-widget" className={className} iconName="catalog" title={translateText(993)} size="6x3" active>
            <Typography className="label">{translateText(1094)}</Typography>

            <Box>
                <SearchField
                    placeholder={translateText(998)}
                    minLengthForSearch={getBundleParams().minimumSearchLength.vehicleSearch}
                    getSuggestions={vehicle ? (query) => Repositories.getProductGroupSuggestions(query, vehicle.tecDocTypeId) : undefined}
                    onSearch={handleSearch}
                />

                {nodeVariant !== "fastClick" && (
                    <Box sx={{ position: "absolute", right: 20, display: "flex" }}>
                        <SearchtreeTip />
                    </Box>
                )}
            </Box>

            <SearchTreeWidget searchTreeType="vehicleParts" />
        </Widget>
    )
}

export default withRouter(withWorkTask(VehicleSearchWidgetComponent))
