import { Tooltip } from "@tm/components"
import { ReactElement } from "react"

type Props = {
    tooltip: string | undefined
    children: ReactElement
}

export function TooltipWrapper({ tooltip, children }: Props) {
    if (tooltip) {
        return <Tooltip title={tooltip}>{children}</Tooltip>
    }

    return children
}
