import { useStyle } from '@tm/context-distribution'
import { Button, Headline, Toolbar } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import { ConfigParams, TmaEModule } from '@tm/models'
import Morpheus, { useActions } from '@tm/morpheus'
import { TmaHelper, classes, createQueryString, parseQueryString, renderRoute, withRouter } from '@tm/utils'
import * as React from 'react'
import { batch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { bundleChannel } from '../../data/channels'
import { decodeAllParams } from '../../data/helpers'
import { EncodedMatchParams, NavigationSteps } from '../../data/models'
import { TopFiltersComponent } from '../list/components'
import { MainState } from '../main'
import { MainActions } from '../main/business'
import { LinkTab, Tabs } from '@tm/components'
import { Link } from 'react-router-dom'
import SummaryHeader from "../summary/components/header"
import { getBundleParams } from '../../utils'
import { useMemo } from 'react'

type Props = RouteComponentProps<EncodedMatchParams> & {
	route: string
}

const NavigationComponent: React.FC<Props> = ({ route, history, match: { params: matchParams } }) => {
	const state = useSelector((s: MainState) => s.navigation)
	const { translateText } = useLocalization()
	const { view } = matchParams
	const actions = useActions(MainActions, "changeStep", "initNav", "loadTiresList", "updateSearchValue", "updateSummaryFilters")
	let blockChange = false
	const { useNewNavbar } = getBundleParams()
	const style = useMemo(() => getStyle(view), [view])

	const [size, carType, season] = decodeURIComponent(matchParams.search || "").split("&") ?? []

	React.useEffect(() => {
		if (matchParams.view && matchParams.view != state.active && !blockChange && state.initialized) {
			actions.changeStep(matchParams.view)
			blockChange = true
			setTimeout(() => {
				blockChange = false
			}, 1000)
		}


	}, [matchParams.view])

	React.useEffect(() => {
		const { active, params } = state

		if (active && !blockChange) {
			const rrparams: any = { ...matchParams, ...params, view: active }
			const newSearch = [rrparams.size, rrparams.carType, rrparams.season].filter(Boolean).join("&")
			const routeParams = { ...rrparams, ...newSearch && { search: newSearch }, workTaskId: matchParams.workTaskId }

			const url = renderRoute(route, routeParams)

			let queryParams = parseQueryString(location.search)
			queryParams["initialQuantity"] = params?.quantity

			if (matchParams.view == active)
				history.replace(url + createQueryString(queryParams))
			else
				history.push(url + createQueryString(queryParams))


			blockChange = true
			setTimeout(() => {
				blockChange = false
			}, 1000)
		}

	}, [state.active, state.params])

	React.useEffect(() => batch(() => {
		const decodedParams = decodeAllParams(matchParams)
		// load from url
		if (!state.initialized || decodedParams.workTaskId !== state.params.workTaskId) {
			actions.initNav(decodedParams)
			if (matchParams.search) {
				actions.loadTiresList(!season || !carType)
			}
			return
		}

		//load dashboard widget
		const loadRequest = bundleChannel().last(1, "LOAD_LIST")?.[0]?.content
		if (loadRequest?.matchCode) {
			actions.initNav(decodedParams)
			actions.loadTiresList(!season || !carType)
			bundleChannel().clear("LOAD_LIST")
		}
	}), [])

	const isListDisabled = () => (!state.params?.size) && view != 'list'
	const isDetailsDisabled = () => (!state.params?.productGroupId || !state.params.supplierArticleNo || !state.params.supplierId) && view != 'details'

	const displayDefaultTabLayout = () => {
		return <>
			<Button
				onClick={() => actions.changeStep("summary")}
				isActive={view == 'summary'}>
				{translateText(409)}
			</Button>
			<Button
				disabled={isListDisabled()}
				onClick={() => actions.changeStep("list")}
				isActive={view == 'list'}>
				{translateText(6)}
			</Button>
			{!Morpheus.getParams<ConfigParams>().articleDetailsInModal &&
				<Button
					disabled={isDetailsDisabled()}
					onClick={() => actions.changeStep("details")}
					isActive={view == 'details'}>
					{translateText(43)}
				</Button>
			}
		</>
	}

	const displayNewTabLayout = () => {
		return <>
			<Tabs value={view} sx={{ marginRight: matchParams.view === "summary" ? "10em !important" : 0 }}>
				<LinkTab
					component={Link}
					value="summary"
					label={translateText(409)}
					onClick={() => actions.changeStep("summary")}
					disableRipple
					sx={{ color: "black" }}

				/>
				<LinkTab
					disabled={isListDisabled()}
					component={Link}
					value="list"
					label={translateText(6)}
					onClick={() => actions.changeStep("list")}
					disableRipple
					sx={{ color: "black" }}
				/>
				{!Morpheus.getParams<ConfigParams>().articleDetailsInModal &&
					<LinkTab
						disabled={isDetailsDisabled()}
						onClick={() => actions.changeStep("details")}
						value="details"
						label={translateText(43)}
						disableRipple
						sx={{ color: "black" }}
					/>
				}
			</Tabs>
			{matchParams.view == "summary" && <SummaryHeader />}
		</>
	}

	return (
		<div id="nav-wrapper" className={classes( "module", useNewNavbar ? style.newWrapper : classes(style.oldWrapper, "toolbar"))}>
			<Headline size="s" className={style.headline}>{translateText(240)}</Headline>
			<Toolbar title={translateText(176)}>
				{useNewNavbar ? displayNewTabLayout() : displayDefaultTabLayout()}
			</Toolbar>
			{matchParams.view == "list" && <TopFiltersComponent />}
		</div>
	)
}

function getStyle(view: NavigationSteps | undefined) {
	return useStyle({
		oldWrapper: {
			flex: 0,
			display: "flex",
		},
		newWrapper: {
			flex: 0,
			display: "flex",
			backgroundColor: "white",
			padding: view === "summary" ? "1rem 1.5rem .6rem 1.5rem" : "1rem 1.5rem",
			margin: "-.6rem 1rem .4rem 1rem",
			$nest: {
				".toolbar .toolbar__inner .subtitle": {
					marginBottom: view === "summary" ? "-.3rem" : "initial"
				}
			}
		},
		headline: {
			display: "flex",
			alignItems: "center",
			paddingRight: "2rem",
			paddingTop: view === "list" ? ".3em" : "initial"
		}
	})(NavigationComponent)
}

export default withRouter(NavigationComponent)