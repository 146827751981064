import { Tooltip, styled } from "@tm/components"
import { Icon as IconControl, Image } from "@tm/controls"
import { ErpIcon } from "@tm/models"
import { classes } from "@tm/utils"
import { memo } from "react"
import { getComponentStyles } from "./styles"

type Props = {
    icons?: Array<ErpIcon & { onClick?(): void }>
    size?: "s"
    className?: string
}

const classNames = getComponentStyles()

const StyledImage = styled(Image)(({ theme }) => {
    const imageStyles = {
        maxHeight: "1.5rem",
        maxWidth: theme.overwrites?.toolkits?.erp?.specialIconsImageMaxWidth || "2rem",
    }
    return {
        ...imageStyles,
        "&.error-image svg": imageStyles,
    }
})

const SpecialIcons = memo((props: Props) => {
    if (!props.icons?.length) {
        return null
    }

    const renderIcon = (icon: (ErpIcon & { onClick?(): void }) | undefined, id: number) => {
        if (!icon) {
            return null
        }

        let content
        if (icon.useIconFromUrl) {
            if (!icon.url) {
                return null
            }
            content = <StyledImage url={icon.url} />
        } else if (icon.topmotiveId) {
            content = <IconControl name={icon.topmotiveId} size={props.size || "l"} />
        } else {
            content = <IconControl name="safety-data-sheet" size={props.size || "l"} />
        }

        if (icon.linkUrl) {
            content = (
                <a className={classNames.link} href={icon.linkUrl} target="_blank" rel="noopener noreferrer">
                    {content}
                </a>
            )
        }

        if (icon.description) {
            content = (
                <Tooltip title={icon.description}>
                    <div>{content}</div>
                </Tooltip>
            )
        }

        return (
            <div
                key={id}
                className={`iconWrapper ${props.size === "s" ? classNames.iconWrapperS : classNames.iconWrapper}`}
                onClick={!icon.linkUrl ? icon.onClick : undefined}
            >
                {content}
            </div>
        )
    }

    return <div className={classes(classNames.main, props.className)}>{props.icons.map(renderIcon)}</div>
})

export default SpecialIcons
