import * as React from "react"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { getStyleTheme, useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Button, LicensePlateField, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"

import { Vehicles } from "../../../business/gateway"
import { SeparatorList } from "../../_shared/separator-list"
import { getVehicleName } from "../helpers"

function getComponentStyles(): NestedCSSProperties {
    const { margin } = getStyleTheme()

    return {
        display: "flex",
        alignItems: "center",
        margin: `${margin.l} 0`,
        $nest: {
            "&__label": {
                marginRight: margin.l,
            },
            "&__license-plate": {
                margin: "-2px 0" /** @todo improve this */,
            },
            "&__actions": {
                marginLeft: "auto",
            },
        },
    }
}

type Props = {
    vehicle: Vehicles.Vehicle
    onDeselect(): void
}

export const SelectedVehicle: React.FC<Props> = ({ vehicle, onDeselect }) => {
    const className = style(getComponentStyles())
    const { translate } = useLocalization()
    const { plateCode } = useCountryCodeToLicensePlate(vehicle.countryCode)
    const deselect = React.useCallback(() => onDeselect(), [onDeselect])

    const renderName = () => {
        return <Text>{getVehicleName(vehicle)}</Text>
    }

    const renderLicensePlate = () => {
        if (!vehicle.registrationInformation?.plateId) {
            return
        }

        return (
            <LicensePlateField
                readonly
                value={vehicle.registrationInformation.plateId}
                shortCountryCode={plateCode}
                size="l"
                className={`${className}__license-plate`}
            />
        )
    }

    return (
        <div className={className}>
            <Text size="l" className={`${className}__label`}>
                {translate(99)}
            </Text>

            <SeparatorList>
                {renderName()}
                {renderLicensePlate()}
            </SeparatorList>

            <div className={`${className}__actions`}>
                <Button icon="cancel-x" layout={["ghost"]} onClick={deselect} />
            </div>
        </div>
    )
}
