import { Stack, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ModuleHeaderSlot } from "./ModuleHeaderSlot"
import { Navigation } from "./Navigation"
import { Options } from "./Options"
import { Search } from "./Search"

const StyledModuleHeader = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "flex-end",
    paddingBlock: theme.spacing(0.75),
    paddingInline: theme.spacing(2),
    backgroundColor: theme.overwrites?.components?.partListV2?.backgroundColor,
}))

export function ModuleHeader() {
    const { translateText } = useLocalization()

    return (
        <StyledModuleHeader>
            <Typography variant="h1" pr={2} pb={1}>
                {translateText(993)}
            </Typography>
            <ModuleHeaderSlot title={translateText(176)}>
                <Navigation />
            </ModuleHeaderSlot>
            <ModuleHeaderSlot title={translateText(135)}>
                <Search />
            </ModuleHeaderSlot>
            <ModuleHeaderSlot title={translateText(361)}>
                <Options />
            </ModuleHeaderSlot>
        </StyledModuleHeader>
    )
}
