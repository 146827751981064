import Morpheus, { IPlugin, PluginProps, ModuleConfig } from "@tm/morpheus"
import { channel } from "@tm/models"
import { withRouter, RouteComponentProps, encodeUniqueId, uniqueId } from "@tm/utils"
import { Location } from "history"

const plugin: IPlugin<ModuleConfig> = {
    name: "ForceWorktask",
    type: "MODULE/MOUNT",
    plugin: ForceWorktask,
}

export default plugin

type ForceWorktaskProps = PluginProps<ModuleConfig<{ forceWorktask: boolean }>>

let unregister: any

function ForceWorktask(props: ForceWorktaskProps) {
    if (!props.module.props?.forceWorktask || unregister) {
        return
    }

    const history = Morpheus.getHistory()

    function listener(location: Location) {
        const hasFragment = location.pathname.length > 1
        if (!hasFragment) {
            const pageNav = window.__NEXT_PAGE_NAVIGATION__ || []
            if (pageNav.length) {
                setTimeout(() => history.replace(pageNav[0]), 0)
                return
            }
            setTimeout(() => channel("GLOBAL").publish("WORKTASK/OPEN_NEW", { openExisting: true }), 0)
        }
    }

    unregister = history.listen(listener)
    listener(history.location)
}
