import { useLocalization } from "@tm/localization"
import { Icon, Tooltip } from "@tm/controls"
import { TelematicsDataSummaryResponse } from "../../../data"
import { getBatteryVoltageIconName, getBatteryVoltageIconSkin, VOLTAGE_THRESHOLD_WARNING } from "../../../data/helper"

type Props = {
    summary: TelematicsDataSummaryResponse
}

export default function Slide1Component(props: Props) {
    const { translateText, date, language } = useLocalization()
    const { mileage, nextBrakeFluidChangeDate, batteryVoltage } = props.summary

    if (mileage || nextBrakeFluidChangeDate || batteryVoltage) {
        return (
            <div className="slider__content slide-1">
                <div className="def-list">
                    {mileage && (
                        <div className="def-list__item">
                            <Icon name="cylinder-raceflag" size="xl" />
                            <div className="def-list__term">{mileage.labelTextMap[language.toLowerCase()]}</div>
                            <div className="def-list__text">{`${mileage.value} ${mileage.unit}`}</div>
                        </div>
                    )}
                    {nextBrakeFluidChangeDate && (
                        <div className="def-list__item">
                            <Icon name="oil-can" size="xl" />
                            <div className="def-list__term">{nextBrakeFluidChangeDate.labelTextMap[language.toLowerCase()]}</div>
                            <div className="def-list__text">{date(nextBrakeFluidChangeDate.value, "d")}</div>
                        </div>
                    )}
                    {batteryVoltage && (
                        <div className="def-list__item">
                            <Tooltip
                                style="primary"
                                position="top"
                                content={batteryVoltage.value < VOLTAGE_THRESHOLD_WARNING ? translateText(934) : ""}
                            >
                                <div>
                                    <Icon name="battery" className="battery-icon" size="xl" />
                                    <Icon
                                        size="xl"
                                        name={getBatteryVoltageIconName(batteryVoltage.value)}
                                        skin={getBatteryVoltageIconSkin(batteryVoltage.value)}
                                    />
                                </div>
                            </Tooltip>
                            <div className="def-list__term">{batteryVoltage.labelTextMap[language.toLowerCase()]}</div>
                            <div className="def-list__text">{`${batteryVoltage.value.format(2)} ${batteryVoltage.unit}`}</div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
    return null
}
