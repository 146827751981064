import { useLocalization } from "@tm/localization"
import { Grid } from "@tm/components"
import { HighlightContrastTypography } from "../../StyledComponents"

type Props = {
    label: string
    value?: number
    currencyCode: string
}

export default function Totals({ label, value, currencyCode }: Props) {
    const { translateText, currency } = useLocalization()

    return (
        <>
            <Grid item xs={6}>
                <HighlightContrastTypography>
                    {translateText(62)}
                    <HighlightContrastTypography variant="label">{` ${label}`}</HighlightContrastTypography>
                </HighlightContrastTypography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5} textAlign="right">
                <HighlightContrastTypography>{currency(value ?? 0, currencyCode)}</HighlightContrastTypography>
            </Grid>
        </>
    )
}
