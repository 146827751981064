import * as React from "react"
import { SelectionList, SelectionListProps, SelectionListItem, SelectionListItemGroup, SearchField, InputGroup, Button, Tooltip } from "@tm/controls"
import { bindSpecialReactMethods, TmaHelper } from "@tm/utils"
import { LocalizationProps } from "@tm/localization"
import Filter, { Props as FilterProps } from "./filter"
import { adaptFilterGroupHeights } from "../../../helper"

type Props = FilterProps &
    SelectionListProps &
    LocalizationProps & {
        selectionListRef?: React.RefObject<SelectionList>
        extendedAssortment: boolean
        onToggleSearchField: () => void
        onFilterChange?: (query: string) => void
    }

type State = {
    filteredGroups: Array<SelectionListItemGroup>
    selected: Array<SelectionListItem>
    filterValue: string
    filterVisible: boolean
}

export default class SelectionListFilter extends React.Component<Props, State> {
    private filterInputRef: React.RefObject<SearchField> = React.createRef()

    private changeTimeout: number

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            filteredGroups: this.props.groups,
            selected: this.props.selected,
            filterValue: "",
            filterVisible: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState((prevState) => {
            return {
                filteredGroups: this.getFilteredGroups(nextProps.groups, prevState.filterValue),
                selected: nextProps.selected,
            }
        })
    }

    componentDidUpdate(_prevProps: Props, prevState: State) {
        adaptFilterGroupHeights()
        if (!prevState.filterVisible && this.state.filterVisible) {
            this.focusSearchField()
        }
    }

    focusSearchField() {
        this.filterInputRef.current && this.filterInputRef.current.focus()
    }

    getFilteredGroups(groups: Array<SelectionListItemGroup>, filterValue: string) {
        if (!filterValue) {
            return groups
        }

        filterValue = filterValue.toLowerCase()

        const containsFilter = (value?: string) => value && value.toLowerCase().indexOf(filterValue) != -1

        return groups
            .map((x) => {
                if (containsFilter(x.name)) {
                    return x
                }

                return {
                    ...x,
                    items: x.items.filter(
                        (y) => containsFilter(y.name) || containsFilter(y.description)
                        // || this.isSelected(y)
                    ),
                }
            })
            .filter((x) => x.items.length)
    }

    isSelected(item: SelectionListItem): boolean {
        return this.state.selected.some((y) => y.query == item.query)
    }

    handleChange(selected: Array<SelectionListItem>) {
        this.setState({ selected })

        // only fire on change event after the user made no selection for a short time
        // to prevent selection differs bewteen client and service
        clearTimeout(this.changeTimeout)

        this.changeTimeout = window.setTimeout(() => {
            TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
            this.props.onChange(selected)
        }, 1000)
    }

    handleChangeFilterValue(filterValue: string) {
        const filteredGroups = this.getFilteredGroups(this.props.groups, filterValue)
        this.setState({
            filterValue,
            filteredGroups,
        })
        this.props.onFilterChange?.(filterValue)
    }

    handleActivateFilteredItems() {
        let selected: Array<SelectionListItem> = []
        selected = selected.concat(...this.state.filteredGroups.map((x) => x.items))

        // If the extended assortment is not selected ...
        if (!this.props.extendedAssortment) {
            // only activate priority items ...
            const onlyPrio = selected.filter((x) => x.priority === "high" || x.priority === "high-normal")

            // ... but only if there are priority items otherwise activate not priority too
            if (onlyPrio.length) {
                selected = onlyPrio
            }
        }

        this.setState({ selected })
        this.props.onChange(selected)
        this.handleToggleSearchField(undefined)
    }

    handleToggleSearchField(e: React.MouseEvent<HTMLElement> | undefined) {
        e?.stopPropagation()

        this.props.onToggleSearchField()
        this.handleChangeFilterValue("")

        this.setState((prevState) => ({ filterVisible: !prevState.filterVisible }))
    }

    handleCollapsibleChangeOpen(opened: boolean) {
        this.props.onChangeOpen?.(opened)
        opened && this.focusSearchField()
    }

    renderSearchField() {
        if (!this.state.filterVisible) {
            return
        }

        return (
            <InputGroup className="filter__search-field" size="s">
                <SearchField
                    value={this.state.filterValue}
                    onChange={this.handleChangeFilterValue}
                    onChangeConfirm={this.handleActivateFilteredItems}
                    showClear
                    ref={this.filterInputRef}
                    size="s"
                />
                <Button icon="search" size="s" onClick={this.handleActivateFilteredItems} />
            </InputGroup>
        )
    }

    renderSearchIcon() {
        return (
            <Tooltip content={this.props.localization.translateText(135)}>
                <Button
                    icon="search"
                    layout={["ghost"]}
                    className={`multi-btn__icon ${this.state.filterValue ? "icon--primary" : ""}`}
                    onClick={this.handleToggleSearchField}
                />
            </Tooltip>
        )
    }

    render() {
        let className = "filter--selection-list "
        className += this.props.className || ""

        return (
            <Filter
                {...this.props}
                className={className}
                headerAppendix={this.renderSearchIcon()}
                onChangeOpen={this.handleCollapsibleChangeOpen}
                initiallyOpened={this.props.selected?.length > 0 || this.props.initiallyOpened}
            >
                {this.renderSearchField()}
                {!!this.props.groups.length && (
                    <SelectionList
                        {...this.props}
                        ref={this.props.selectionListRef}
                        groups={this.state.filteredGroups}
                        selected={this.state.selected}
                        onChange={this.handleChange}
                        initiallyShowOthers={this.props.extendedAssortment}
                    />
                )}
            </Filter>
        )
    }
}
