import * as React from "react"
import { useDispatch } from "react-redux"
import { SuggestionTextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { CarModelDetails } from "@tm/models"
import { CalcInput } from "../../../data/model"
import { MainActions } from "../../main/business"
import { getCalcStateLabel, getCurrencyFromUserContext } from "../../../data/helpers"
import { ECalcInputType } from "../../../data/enums"

type Props = {
    item: CalcInput
    onChangeCallBack?: (data: any) => void
    modelDetails?: CarModelDetails
}

const CalcInputText: React.FC<Props> = ({ item, onChangeCallBack, modelDetails }) => {
    const dispatch = useDispatch()
    const userContext = useUser()?.userContext
    const { translateText } = useLocalization()

    const label = getCalcStateLabel(item.type, getCurrencyFromUserContext(userContext), translateText)
    const suggestions = (item.type == ECalcInputType.EngineCode && modelDetails?.engineCodes) || []

    const handleChange = (value: string) => {
        if (value != item.value) {
            dispatch(MainActions.handleInputSubmit({ ...item, value }))
            onChangeCallBack?.(value)
        }
    }

    return (
        <SuggestionTextField
            floatingLabel
            label={item.label || label}
            value={item.value}
            disabled={item.isDisabled}
            preventConfirmOnBlur={!!suggestions?.length}
            getSuggestionData={() => suggestions}
            onChangeConfirm={handleChange}
        />
    )
}

export default CalcInputText
