import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBasketServiceUrl } from "../.."
import {
    CalculateWorkTaskBasketRequest,
    CalculateWorkTaskBasketResponse,
    ShowWorkTaskBasketRequest,
    ShowWorkTaskBasketResponse,
    ShowWorkTaskOrderPdfRequest,
} from "../../model"
import { CalculateBonusPointsRequest, CalculateBonusPointsResponse } from "../../model/CalculateBonusPoints"

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Basket`
}

export function showWorkTaskBasket(body: ShowWorkTaskBasketRequest) {
    const url = `${getServiceUrl()}/ShowWorkTaskBasket`
    const authorization = getStoredAuthorization()
    return ajax<ShowWorkTaskBasketResponse>({ url, body, authorization }, undefined, undefined, true)
}

export function calculateWorkTaskBasket(body: CalculateWorkTaskBasketRequest) {
    const url = `${getServiceUrl()}/CalculateWorkTaskBasket`
    const authorization = getStoredAuthorization()
    return ajax<CalculateWorkTaskBasketResponse>({ url, body, authorization, method: "POST" })
}

export function calculateBonusPoints(body: CalculateBonusPointsRequest) {
    const url = `${getServiceUrl()}/CalculateBonusPoints`
    const authorization = getStoredAuthorization()
    return ajax<CalculateBonusPointsResponse>({ url, body, authorization, method: "POST" })
}

export function showWorkTaskOrderPdf(request: ShowWorkTaskOrderPdfRequest) {
    const url = `${getServiceUrl()}/ShowWorkTaskOrderPdf`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax<Array<number>>({ url, body, authorization, responseType: "blob", method: "POST" })
}
