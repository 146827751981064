import { useCallback, useEffect, useRef, useState } from "react"
import { Tooltip } from "@tm/components"
import { Button } from "@tm/controls"
import { ECounterType, ModuleTab } from "@tm/models"
import { TmaHelper, getUIA } from "@tm/utils"
import { useInitModuleNavigation } from "@tm/context-distribution"
import { StylingFromConfig } from "../../data"
import { getNavigationSplitPosition } from "../../helpers"
import { getStyleFromConfig } from "./helpers"
import { TabItem } from "./components/TabItem"
import { DashboardItem } from "./components/DashboardItem"

export type ModuleNavigationProps = StylingFromConfig & {
    worktaskDashboardTitle?: string
    activateDefaultTabs?: boolean
    defaultTabs?: { [key: string]: ModuleTab }
    workTaskRoute?: string
}

function ModuleNavigationComponent(props: ModuleNavigationProps) {
    const { activateDefaultTabs, defaultTabs } = props
    const { visibleTabs, moreTabs, visibleTabCount, setVisibleTabCount, resetVisibleTabCount } = useInitModuleNavigation(
        activateDefaultTabs,
        defaultTabs
    )
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const navigationElement = useRef<HTMLDivElement>(null)
    const wrapperElement = useRef<HTMLDivElement>(null)
    const moreElement = useRef<HTMLDivElement>(null)

    const resizeTimer = useRef<number>()

    useEffect(
        function rearangeTabs() {
            if (visibleTabCount === -1 && navigationElement?.current) {
                const splitPos = getNavigationSplitPosition(navigationElement.current, moreElement.current)
                setVisibleTabCount(splitPos.pos)
            }
        },
        [visibleTabs.length, moreTabs.length, visibleTabCount, navigationElement.current, moreElement.current]
    )

    const handleResize = useCallback(() => {
        clearTimeout(resizeTimer.current)
        resizeTimer.current = window.setTimeout(() => {
            setShowTooltip(false)
            resetVisibleTabCount()
        }, 200)
    }, [])

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [handleResize])

    const handleTooltipShow = useCallback(() => {
        setShowTooltip(true)
    }, [setShowTooltip])

    const handleTooltipHide = useCallback(() => {
        setShowTooltip(false)
    }, [setShowTooltip])

    // sometimes the wrapper width is changed without window resize.
    // For example when basket summay is reloaded and that's why the resizeObserver was added
    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(wrapperElement.current as Element)

        const currentWrapperElement = wrapperElement.current
        return () => {
            if (currentWrapperElement) {
                resizeObserver.unobserve(currentWrapperElement as Element)
            }
        }
    }, [handleResize])

    const tabClassNameExtensions = getStyleFromConfig("tab", props.style, { layout: "module" })
    const btnClassName = `more__btn tab ${moreTabs.length ? "is-visible" : ""}${tabClassNameExtensions}`
    const isStartSelected = !visibleTabs.some((x) => x.isSelected)

    return (
        <>
            <div className="worktask-modules__wrapper" ref={wrapperElement} {...getUIA("Modulenavigation")}>
                <DashboardItem
                    {...props}
                    clickEvent={() => {
                        TmaHelper.GeneralCountEvent.Call(ECounterType.StartPageWorktask)
                    }}
                    isSelected={isStartSelected}
                />
                <div className="worktask-modules tab-control">
                    <div className="worktask-modules__inner" ref={navigationElement}>
                        {visibleTabs.map((item) => (
                            <TabItem key={item.url} item={item} style={props.style} onClose={handleTooltipHide} />
                        ))}
                    </div>
                    <div className="more__wrapper" ref={moreElement}>
                        <Tooltip
                            className="more__list"
                            onClose={handleTooltipHide}
                            onClickAway={handleTooltipHide}
                            open={showTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <div>
                                    {moreTabs.map((item) => (
                                        <TabItem key={item.url} item={item} isMoreItem style={props.style} onClose={handleTooltipHide} />
                                    ))}
                                </div>
                            }
                            variant="light"
                        >
                            <div>
                                <Button className={btnClassName} onClick={handleTooltipShow}>
                                    ...
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModuleNavigationComponent
