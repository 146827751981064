import { IBundle } from "@tm/morpheus"
import Widget from "./components/widget"
import Compilations from "./components/compilations"
import HeaderButton from "./components/header-button"
import AddToCompilation from "./micros/add-to-compilation"
import AddToCompilationBasket from "./micros/add-to-compilation-basket"
import { initCompilationsBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [Compilations, Widget, HeaderButton],
    micros: [AddToCompilation, AddToCompilationBasket],
    init: initCompilationsBundle,
}

export default bundle
