import { useUser } from "@tm/context-distribution"
import { Image, Loader, PanelSection, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, IMicros, RepairTimeProvider, SystemType, Vehicle, getCurrentWorkTaskId } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { encodeUniqueId, getRepairTimesProvider, getRepairTimesProviderStringByEnum, renderRoute, RouteComponentProps, TmaHelper, uniqueId, withRouter } from "@tm/utils"
import * as React from "react"
import { getBundleParams } from "../../../utils"
import { useSelector } from "react-redux"
import { MainState } from "../../main"

type Props =  RouteComponentProps & { 
    items: Article[]
    configuratorImage?: string
    vehicle?: Vehicle
    repairTimesRoute: string
    loading?: boolean
}

const VehiclePanel: React.FC<Props> = ({ configuratorImage, vehicle, items, loading, match, repairTimesRoute }) => {

    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()
    const { userSettings, userContext } = useUser() ?? {}

    const getRepairTimesUrl = (article: any, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => {
        if (article.productGroup && getBundleParams().awProviders.length && userSettings) {

            let provider
            if (Array.isArray(rtProviders)) {
                let activeRTProvider = userSettings.activeVehicleDataProviders.repairTimes

                provider = getRepairTimesProvider(rtProviders, getBundleParams().awProviders, activeRTProvider)
            }
            else {
                provider = getRepairTimesProviderStringByEnum(rtProviders)
            }

            if (!provider)
                return

            return decodeURIComponent(
                renderRoute(repairTimesRoute, {
                    workTaskId: encodeUniqueId(uniqueId()),
                    ...match.params,
                    provider,
                    productGroupId: article.productGroup.id,
                    supplierId: article.supplier.id,
                    supplierArticleNo: article.supplierArticleNo,
                    position: article.fittingSide
                })
            )
        }
    }

    const renderStandAloneButtons = () => {
        const systemType = userContext?.system.systemType

        return (
            <>
                {systemType == SystemType.Redesign &&
                    renderMicro!("standalone", "rd-add-articles-to-basket", {
                        items,
                        buttonText: translateText(133),
                        sourceId: "TM_WHEELS",
                    })}

                {systemType == SystemType.Smartclient &&
                    renderMicro!("standalone", "sc-add-articles-to-basket", {
                        items,
                        buttonText: translateText(133),
                        sourceId: "TM_WHEELS",
                    })}

            </>
        )
    }

    const providers = useSelector((s: MainState) => items.flatMap((item) => s.tyresList.repairTimeAvailabilities?.[item.productGroup.id]))
    const renderNextActionsButtons = () => {
        TmaHelper.Shared.ByArticleAndUniParts.SetSearchContext("wheels", "", getCurrentWorkTaskId());
        const awProviders = getBundleParams().awProviders

        return (
            <>
                {renderMicro!("basket", "add-to-basket", {
                    data: items,
                    buttonText: translateText(133),
                    disabled: !items.length,
                    vehicleImageBase64: configuratorImage,
                })}
                {awProviders?.length > 0 && renderMicro!("basket", "add-to-cost-estimate", {
                    data: items,
                    awProviders: awProviders,
                    getRepairTimesUrl: getRepairTimesUrl,
                    vehicleImageBase64: configuratorImage,
                })}
            </>
        )
    }

        if (!vehicle)
            return null

        return (
            <PanelSection className="vehicle_info">
                <div className="flex-wrapper">
                    <div className="car_info">
                        <Text modifiers="strong">{vehicle.manufacturer + ' ' + vehicle.modelSeries + ' ' + vehicle.model}</Text>
                    </div>
                    {configuratorImage && <div className="car_logo">
                        <Image url={configuratorImage} />
                    </div>}
                    
                    <div className="basket-elements">
                        {loading && <Loader/>}
                        {!loading && !!items.length && (userContext?.system.systemType != SystemType.Next ? renderStandAloneButtons() : renderNextActionsButtons())}
                    </div>
                </div>
            </PanelSection>
        )
}

export default withRouter(VehiclePanel)