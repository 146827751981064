import { Customer, Vehicle } from "@tm/models"
import { uniqueId } from "@tm/utils"

import { Customers, Vehicles } from "./gateway"

export function mapDmsToWorkTaskCustomer(customer: Customers.Customer): Customer {
    return {
        id: customer.customerId,
        academicTitle: customer.academicTitle,
        birthDate: customer.birthDate,
        companyName: customer.companyName,
        created: customer.creationDate,
        email: customer.email,
        fax: customer.fax,
        firstName: customer.firstName,
        lastName: customer.lastName,
        memo: customer.memos?.join("\n"),
        modified: customer.modifiedDate,
        phone: customer.phone,
        mobile: customer.mobile,
        refId: customer.referenceId,
        repairTimesDiscount: 0,
        sparePartsDiscount: 0,
        taxId: customer.taxId,
        titleType: customer.title,
        addressAddition: customer.generalAddress?.addressAddition,
        city: customer.generalAddress?.city,
        country: customer.generalAddress?.country,
        state: customer.generalAddress?.state,
        street: customer.generalAddress?.street,
        zip: customer.generalAddress?.zip,
    }
}

export function mapDmsToWorkTaskVehicle(vehicle: Vehicles.Vehicle, customerId?: string): Vehicle {
    return {
        id: uniqueId(),
        manufacturer: vehicle.manufacturer,
        model: vehicle.type,
        modelSeries: vehicle.model,
        tecDocManufacturerId: 0,
        tecDocModelId: 0,
        tecDocTypeId: vehicle.vehicleType.id,
        vehicleType: vehicle.vehicleType.type as number,
        attachedOn: new Date(),
        countryCode: vehicle.registrationInformation?.countryCode,
        created: vehicle.creationDate,
        customerId,
        engineCode: vehicle.engineCode,
        initialRegistration: vehicle.registrationInformation?.registrationDate,
        lastGeneralInspection: vehicle.lastWorkshopAppointment,
        mileAge: vehicle.mileage,
        mileType: vehicle.mileageType,
        modified: vehicle.modifiedDate,
        nextGeneralInspection: vehicle.nextGeneralInspection,
        nextServiceDate: vehicle.nextServiceDate,
        plateId: vehicle.registrationInformation?.plateId,
        refId: vehicle.referenceId,
        registrationNo: vehicle.registrationInformation?.registrationNo,
        vin: vehicle.vin,
    }
}

export function getDateFormat(languageId: string): string {
    return languageId === "1" ? "dd.MM.yyyy" : "MM/dd/yyyy" /** @todo improve and generalize */
}
