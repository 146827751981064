import { Article, RegistrationNoType } from "@tm/models"
import { PrintArticleDetailsRequest } from "../../../data/model"

export function createPrintArticleDetailsRequest(
    article: Article,
    modelId?: number,
    vehicleType?: number,
    registrationNo?: string,
    registrationTypeId?: RegistrationNoType
): PrintArticleDetailsRequest {
    const date: Date = new Date()

    return {
        productGroupId: article.productGroup.id,
        supplierId: article.supplier.id,
        supplierArticleNo: article.supplierArticleNo,
        vehicleLinkageId: article.vehicleLinkageId,
        currentDate: `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(-2)}.${date.getFullYear()}`,
        ...(modelId && { modelId, vehicleType, registrationNo, registrationTypeId }),
    }
}
