import { useUser } from "@tm/context-distribution"
import { Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ErpPrice, Memo, MemoType, PriceType } from "@tm/models"
import { getBundleParams } from "../../../utils"
import { getPrice } from "../helper"
import { InfoIcon } from "./StyledComponents"

type Props = {
    prices: Array<ErpPrice>
    memos?: Array<Memo>
    showVkAndRebate?: boolean
}

export default function EkTooltip({ prices, memos, showVkAndRebate }: Props) {
    const { translateText, currency } = useLocalization()
    const { userContext } = useUser() ?? {}

    function renderVkAndRebate() {
        const vkOrUvp = getPrice(prices, PriceType.Retail) || getPrice(prices, PriceType.RecommendedRetail)

        return (
            <>
                {vkOrUvp && (
                    <tr>
                        <th className="ek-tooltip-table__description">
                            {vkOrUvp.description || translateText(vkOrUvp.type === PriceType.Retail ? 1620 : 1144)}
                        </th>
                        <th className="ek-tooltip-table__price">{currency(vkOrUvp.value, vkOrUvp.currencySymbol || "")}</th>
                    </tr>
                )}
                {!!vkOrUvp?.rebate && (
                    <tr>
                        <th className="ek-tooltip-table__description">{translateText(54)}</th>
                        <th className="ek-tooltip-table__price">{vkOrUvp.rebate > 1 ? vkOrUvp.rebate : (vkOrUvp.rebate * 100).toFixed(0)} %</th>
                    </tr>
                )}
            </>
        )
    }

    if (getBundleParams().hideEkTooltip || !userContext || userContext.parameter.purchasePricesDisabled) {
        return null
    }

    const content =
        memos?.filter((x) => x.type === MemoType.AdditionalDescription).map((memo, idx) => <div key={`memo_${idx}`}>{memo.text}</div>) ?? []

    const ek = getPrice(prices, PriceType.Purchase)

    if (ek) {
        if (content.length) {
            content.push(<br key="br" />)
        }

        content.push(
            <table className="ek-tooltip-table" key="ek-tooltip-table">
                <tbody>
                    {showVkAndRebate && renderVkAndRebate()}
                    {ek && (
                        <tr>
                            <th className="ek-tooltip-table__description">{ek.description || translateText(55)}</th>
                            <th className="ek-tooltip-table__price">{currency(ek.value, ek.currencySymbol || "")}</th>
                        </tr>
                    )}
                    {!!ek?.rebate && (
                        <tr>
                            <th className="ek-tooltip-table__description">{translateText(54)}</th>
                            <th className="ek-tooltip-table__price">{ek.rebate > 1 ? ek.rebate : (ek.rebate * 100).toFixed(0)} %</th>
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    return (
        <Tooltip content={<>{content}</>}>
            <InfoIcon />
        </Tooltip>
    )
}
