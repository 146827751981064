import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, RouteComponentProps, uniqueId, withRouter } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { useVrcLookupModulesLoadable } from "../../data/hooks/vrc-lookup"

type Props = RouteComponentProps<{ workTaskId?: string }>

function VrcScanButton({ match, history }: Props) {
    const { translateText } = useLocalization()
    const modules = useVrcLookupModulesLoadable()

    if (!modules.length) {
        return null
    }

    const handleButtonClick = () => {
        history.push(
            renderRoute(getBundleParams().vrcLookupRoute, { ...match.params, workTaskId: match.params.workTaskId || encodeUniqueId(uniqueId()) })
        )
    }

    return <Button layout={["ghost"]} icon="scan" onClick={handleButtonClick} title={translateText(12617)} />
}

export default withRouter(VrcScanButton)
