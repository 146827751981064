import { uniqueId } from "@tm/utils"
import { Article, ArticleAttributes, ArticleAttribute, ArticlePrice, AdditionalReferenceArticleInformation, ArticleInfoGroup } from "@tm/models"
import * as Models from "../model"

export function mapCriterionFilterGroup(data: any): Models.UniParts.CriterionFilterGroup | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        criterionFilters: data.criterionFilters || [],
    }
}

export function mapArticle(data: any): Article | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        id: uniqueId(),
        internalId: data.id,
        information: data.information || [],
        attributes: (data.attributes || []).map(mapArticleAttributes),
        references: data.references || [],
        prices: (data.prices || []).map(mapArticlePrice),
        vehicleInformation: data.vehicleInformation || [],
        additionalReferenceArticleInformation: mapAdditionalReferenceArticleInformation(data.additionalReferenceArticleInformation),
        quantity: data.quantity || 1,
        initialQuantity: data.initialQuantity || data.quantity || 1,
        lastUpdate: data.lastUpdate ? new Date(data.lastUpdate) : undefined,
        isReplacementArticle: data.isReplacementArticle || false,
    }
}

function mapArticleAttributes(data: any): ArticleAttributes | undefined {
    if (!data) {
        return
    }

    const attributes: ArticleAttributes = {
        topAttributes: data.topAttributes || [],
        articleAttributes: data.articleAttributes || [],
        vehicleAttributes: data.vehicleAttributes || [],
        partsListAttributes: data.partsListAttributes || [],
    }

    // Identify duplicated attributes, e.g. multiple engine codes
    const identifyDuplicateAttributes = (attributes: Array<ArticleAttribute>) => {
        let prevAttributeId: number | undefined

        attributes.forEach((x) => {
            if (prevAttributeId == x.id) {
                x.isDuplicatedAttribute = true
            }
            prevAttributeId = x.id
        })
    }

    identifyDuplicateAttributes(attributes.topAttributes)
    identifyDuplicateAttributes(attributes.articleAttributes)
    identifyDuplicateAttributes(attributes.vehicleAttributes)
    identifyDuplicateAttributes(attributes.partsListAttributes)

    return attributes
}

function mapArticleInfoGroup(data: any): ArticleInfoGroup | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        member: data.member || [],
    }
}

function mapAdditionalReferenceArticleInformation(data: any): AdditionalReferenceArticleInformation | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        headInformation: data.headInformation || [],
        links: (data.links || []).map(mapArticleInfoGroup),
        textInformation: (data.textInformation || []).map(mapArticleInfoGroup),
    }
}

function mapArticlePrice(data: any): ArticlePrice | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
        validTo: data.validTo ? new Date(data.validTo) : undefined,
    }
}

export function mapPartsListResponse(data: any): Models.GetPartsListResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        articles: data.articles ? data.articles.map(mapArticle) : [],
        imageCoordinates: data.imageCoordinates || [],
    }
}

export function mapGetRelatedArticleResponse(data: any): Models.GetRelatedArticlesResponse | undefined {
    if (!data) {
        return
    }
    return {
        crossSellingArticles: data && data.crossSellingArticles ? data.crossSellingArticles.map(mapArticle) : [],
        universalArticles: data && data.universalArticles ? data.universalArticles.map(mapArticle) : [],
        recommendedArticles: data && data.recommendedArticles ? data.recommendedArticles.map(mapArticle) : [],
        toolKits: data && data.toolKits ? data.toolKits.map(mapArticle) : [],
    }
}
