import { useCallback, useState, MouseEvent, useMemo } from "react"
import { concat, encodeUniqueId, uniqueId } from "@tm/utils"
import { Icon, Text } from "@tm/controls"
import { Menu, MenuItem, Tooltip } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useHistory, useRouteMatch } from "react-router"
import { useLocalization } from "@tm/localization"
import { Container } from "@tm/nexus"
import { PortalContainer } from "@tm/models"
import { MenuItems } from "./components/MenuItems"
import { AdditionalMenuItem, AdditionalMenuItems } from "./components/AdditionalMenuItems"
import { AdditionalMdmMenuItems } from "./components/AdditionalMdmMenuItems"

type Props = {
    url: string
    hideDefaultMenu: boolean
    additionalItems: AdditionalMenuItem[]
    showUserName?: boolean
}

export function UserSettingsMenuButton(props: Props) {
    const { url, additionalItems, hideDefaultMenu } = props
    const { userContext } = useUser()
    const { translateText } = useLocalization()
    const history = useHistory()
    const match = useRouteMatch<{ url: string; workTaskId?: string }>()

    const [anchorEl, setAnchorEl] = useState<HTMLElement>()

    function handleButtonClick(event: MouseEvent<HTMLElement>) {
        if (match.url === url) {
            history.goBack()
        } else {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = useCallback(() => {
        setAnchorEl(undefined)
    }, [])

    const handleLogout = useCallback(() => {
        const container = Container.getInstance("Portal") as PortalContainer
        container.action("logout")(true, true, false)

        handleClose()
    }, [handleClose])

    return (
        <>
            <Tooltip title={translateText(141)}>
                {/* TODO: change this div and the content when the whole right tab navigation is refactored */}
                <div
                    id="user-settings-tab"
                    className={concat(" ", "global-navigation", "tab", "tab--worktask", match.url === props.url && "is-selected")}
                    onClick={handleButtonClick}
                >
                    <div className="tab__content">
                        {props.showUserName ? (
                            <Text modifiers={["block"]}> {userContext.account?.username ?? "-"}</Text>
                        ) : (
                            <Icon size="l" className="user-info" name="user-settings" />
                        )}
                    </div>
                </div>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {!hideDefaultMenu && <MenuItems onClick={handleClose} />}
                {/* entrys over uiconfig file */}
                {additionalItems?.length && <AdditionalMenuItems items={additionalItems} onClick={handleClose} />}
                {/* entrys over the displaysettings at externalModules =>  https://jira.dvse.de/browse/NEXT-25423 and https://jira.dvse.de/browse/MDM-5124 */}
                <AdditionalMdmMenuItems onClick={handleClose} />
                <MenuItem onClick={handleLogout}>{translateText(367)}</MenuItem>
            </Menu>
        </>
    )
}
