import { channel, ErpSystemConfig, OrderResponse } from "@tm/models"
import { useState, useEffect } from "react"

export type InternalOrderResult = {
    result: OrderResponse
    erpSystemConfig: ErpSystemConfig
}

export type InternalOrderError = {
    technicalErrorText?: string
    erpSystemConfig: ErpSystemConfig
}

type State = {
    results: Array<InternalOrderResult>
    technicalErrors: Array<InternalOrderError>
}

export function useOrderResult(workTaskId: string) {
    const [state, setState] = useState<State>({ results: [], technicalErrors: [] })

    useEffect(() => {
        const initializeOrderSent = () => {
            setState({ results: [], technicalErrors: [] })
        }

        const unsubscriptions: Array<() => void> = []
        unsubscriptions.push(channel("WORKTASK", workTaskId).subscribe("BASKET/CLEAR_ORDER_SENT_CONTAINER", initializeOrderSent))
        unsubscriptions.push(channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENDING", initializeOrderSent))
        unsubscriptions.push(
            channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENT", (result) => {
                setState((prev) => ({ ...prev, results: prev.results.concat(result) }))
            })
        )
        unsubscriptions.push(
            channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENT_ERROR", (technicalError) => {
                setState((prev) => ({ ...prev, technicalErrors: prev.technicalErrors.concat(technicalError) }))
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [workTaskId])

    return state
}
