import { Image, Tooltip } from "@tm/controls"
import { CisConceptCustomer } from "@tm/models"
import { useCisCustomer } from "@tm/context-distribution"

export default function ConceptLogoComponent() {
    const { cisCustomer } = useCisCustomer()

    function renderImage(data: CisConceptCustomer) {
        let content = <Image className="concept-logo_image" url={data.iconUrl} />

        if (data.redirectUrl) {
            content = (
                <a className="concept-logo_link" href={data.redirectUrl} target="_blank" rel="noopener noreferrer">
                    {content}
                </a>
            )
        }

        return (
            <Tooltip key={data.id} content={data.description}>
                {content}
            </Tooltip>
        )
    }

    if (!cisCustomer?.conceptCustomers?.length) {
        return null
    }

    return <div className="tk-cis concept-logo">{cisCustomer.conceptCustomers.map(renderImage)}</div>
}
