import { Widget, WidgetProps } from "@tm/controls"
import { ExternalModule } from "@tm/models"
import { Box, Icon } from "@tm/components"
import { ExternalCatalogComponentProps } from "../component"
import ItemCover from "./ItemCover"

type WidgetItemComponentProps = Pick<ExternalCatalogComponentProps, "size" | "itemClassName" | "hideCover"> & {
    item: ExternalModule
    onClick: (item: ExternalModule) => void
}

function WidgetItemComponent(props: WidgetItemComponentProps) {
    const { item, size, itemClassName, hideCover, onClick } = props
    const description = item.catalogDescription || item.description
    const logoParam = item.parameter.find((param) => param.key === "URL_Logo")
    const isUrl = logoParam?.value.includes("/") // logoParam?.value could be either a URL (absolute or relative) or an icon name (which cannot contain a forward slash)
    const className = `item external-catalog ${isUrl ? "image" : "text"} ${!hideCover ? "cover" : ""} ${itemClassName || ""}`

    async function handleClick() {
        onClick(item)
    }

    const widgetProps: WidgetProps = {
        size: size || "1x1",
        active: false,
        className,
        onClick: handleClick,
        title: !hideCover ? (
            <ItemCover {...props} title={description} logo={isUrl ? logoParam?.value : undefined} icon={isUrl ? undefined : logoParam?.value} />
        ) : (
            ""
        ),
        cover: hideCover ? (
            <Widget.Cover>
                {isUrl ? (
                    <Box className="widget__cover-image">
                        <img src={logoParam?.value} alt={description} />
                    </Box>
                ) : (
                    <Icon name={logoParam?.value} height="38px" width="38px" />
                )}
                <Box className="widget__cover-text">{description}</Box>
            </Widget.Cover>
        ) : undefined,
    }

    return <Widget key={`${item.description}-${item.type}`} {...widgetProps} />
}

export default WidgetItemComponent
