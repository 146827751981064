import { Link, useParams } from "react-router-dom"
import { renderRoute, encodeUniqueId, uniqueId, TmaHelper } from "@tm/utils"
import { Icon, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { ECounterType } from "@tm/models"
import { getBundleParams, isWM } from "../../utils"

export default function HeaderButton() {
    const { translateText } = useLocalization()
    const matchParams = useParams()
    const { headerButton, compilationsRoute } = getBundleParams()
    const workTaskId = useWorkTask()?.workTaskId

    if (!headerButton) {
        return null
    }

    const url = renderRoute(compilationsRoute, {
        ...matchParams,
        workTaskId: encodeUniqueId(workTaskId || uniqueId()),
    })

    return (
        <Tooltip content={translateText(1240)}>
            <Link
                onClick={() => {
                    TmaHelper.GeneralCountEvent.Call(ECounterType.MyAssortment)
                }}
                to={url}
                className="global-navigation tab tab--worktask"
            >
                <div className="tab__content">
                    {isWM() ? <Icon className="user-info" name="own-lists" size="l" /> : <Icon className="user-info" name="vote-star" size="l" />}
                </div>
            </Link>
        </Tooltip>
    )
}
