import { withRouter, RouteComponentProps, TmaHelper } from "@tm/utils"
import { Icon, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ECounterType } from "@tm/models"

type Props = RouteComponentProps<void> & {
    url: string
}

const UserSettingsButton = (props: Props) => {
    const { translateText } = useLocalization()

    const isSelected = props.match.url === props.url
    const handleClick = () => {
        if (isSelected) {
            props.history.goBack()
        } else {
            TmaHelper.GeneralCountEvent.Call(ECounterType.SettingsOpen)
            props.history.push(props.url)
        }
    }

    return (
        <Tooltip content={translateText(141)}>
            <div
                id="user-settings-tab"
                className={`global-navigation user-settings tab tab--worktask ${isSelected ? "is-selected" : ""}`}
                onClick={handleClick}
            >
                <div className="tab__content">
                    <Icon className="user-info" name="user-settings" size="l" />
                </div>
            </div>
        </Tooltip>
    )
}

export default withRouter(UserSettingsButton)
