import { useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import {
    CatalogContext,
    CustomWork,
    SelectShipmentRequest,
    VatRate,
    WorkCategory,
    channel,
    TmaEModule,
    AvailabilityLog,
    AvailabilityStatus,
} from "@tm/models"
import { notUndefinedOrNull, uniqueId, getCatalogOrigin } from "@tm/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router"
import { useSendWorkTaskOrder } from "../../components/_shared/order-button/hooks/useSendWorkTaskOrder"
import { EditWorkMode } from "../../components/cost-estimation/business"
import { useCatalogArticles } from "../../data/hooks/workTaskBasket/parts/useCatalogArticles"
import { useChangeOrderOptions } from "../../data/hooks/useOrderOptions"
import { useOrderSending } from "../../data/hooks/useOrderSending"
import { useBasketMutations } from "../../data/hooks/workTaskBasket/mutations/useBasketMutations"
import { useBasketUpdateWorkflow } from "../../data/hooks/workTaskBasket/workflow/useBasketUpdateWorkflow"
import { useCalculateWorkTaskBasketData } from "../../data/hooks/workTaskBasket/queries/useCalculateWorkTaskBasketData"
import { useCostEstimationsMutations } from "../../data/hooks/workTaskBasket/mutations/useCostEstimationsMutations"
import { useErpInfosBasketData } from "../../data/hooks/workTaskBasket/queries/useErpInfosBasketData"
import { useHasRepairTimesData } from "../../data/hooks/workTaskBasket/works/useHasRepairTimesData"
import { useUpdateOrderData } from "../../data/hooks/workTaskBasket/queries/useUpdateOrderData"
import { useWorkEstimationData } from "../../data/hooks/workTaskBasket/queries/useWorkEstimationData"
import { useWorkTaskBasketData } from "../../data/hooks/workTaskBasket/queries/useWorkTaskBasketData"
import { useWorkTaskBasketStore } from "../../data/hooks/workTaskBasket/workflow/useWorkTaskBasketStore"
import {
    mapCalculateBonusItemRequest,
    mapCalculatePartRequest,
    mapCalculateWorkRequest,
    mapErpInformationRequestItem,
    mapUpdateOrderPartRequest,
} from "../../data/mapper"
import {
    CalculateWorkTaskBasketRequest,
    CustomerDefaults,
    GetErpInformationBasketRequest,
    SelectedOrderOptions,
    ShowWorkEstimationRequest,
    UpdateOrderRequest,
    CalculateBonusPointsRequest,
    WorkRequest,
} from "../../data/model"
import { getErpInfoGroupOrderOptions, getErpInfoGroupWarehouseOrderOptions } from "../../helpers"
import { BasketErp, BasketErpIndicator, BasketErpInfoGroup, BasketPart, BasketWork } from "../../models"
import { useBasket } from "./useBasket"
import { useBasketOrderOptions } from "./useBasketOrderOptions"
import { useCostEstimation } from "./useCostEstimation"
import { useCostEstimationVisible } from "./useCostEstimationVisible"
import { useCalculateBonusPointsData } from "../../data/hooks/workTaskBasket/queries/useCalculateBonusPointsData"
import { getBundleParams } from "../../utils"

export function useWorkTaskBasketState(workTaskId: string) {
    const [invalidateUpdateOrder] = useWorkTaskBasketStore((store) => [store.invalidateUpdateOrder])
    const { hideBasket } = getBundleParams()
    const { workTask } = useWorkTask() ?? {}
    const { costEstimationVisible } = useCostEstimationVisible(workTaskId)
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const location = useLocation()
    const matchParams = useParams<{ workTaskId?: string }>()
    const isCentralOrder = !matchParams.workTaskId // Added true to always trigger the query for basket loading (NEXT-26867)
    const costEstimationActive = (costEstimationVisible && !isCentralOrder) || false
    const isShowWorkTaskBasketEnabled = costEstimationVisible !== undefined && (!!isCentralOrder || !!workTask)

    const { workTaskBasket, workTaskBasketLoading, workTaskBasketQueryKey, workTaskBasketWithError } = useWorkTaskBasketData(
        workTaskId,
        costEstimationActive,
        isShowWorkTaskBasketEnabled // Load the basketdata when the worktask is loaded. Caused by NEXT-26777
    )
    const { userSettings } = useUser() ?? {}
    const { orderOptionsGroups, orderOptionsLoading, orderOptionsLoaded, orderOptionsWithError } = useBasketOrderOptions(
        workTaskId,
        workTaskBasket?.orderGroups,
        telesalesCustomerNo
    )
    const updatePartOrderOptionsFunctions = useChangeOrderOptions(telesalesCustomerNo)

    const catalogArticles = useCatalogArticles(
        workTaskBasket?.parts ?? [],
        isCentralOrder || location.pathname.includes("/basket") || location.pathname.includes("/cost-estimation")
    )

    const productGroupIds = useMemo(() => {
        return workTaskBasket?.parts
            ?.map((part) => {
                return part.articleInformation.productGroupId
            })
            .filter(notUndefinedOrNull)
            .distinct()
    }, [workTaskBasket])

    const { availableRepairTimeProviders, hasRepairTimesLoading, hasRepairTimesWithError } = useHasRepairTimesData(
        productGroupIds,
        workTask?.vehicle?.tecDocTypeId
    )

    const workEstimationItemsRequest = useMemo<WorkRequest[] | undefined>(() => {
        return workTaskBasket?.works?.map((workItem) => {
            return {
                id: workItem.id,
                vehicleId: workItem.vehicleId,
                provider: workItem.provider,
                providerWorkId: workItem.providerWorkId,
                displayNumber: workItem.displayNumber,
                description: workItem.description,
                note: workItem.note,
                includedWorks: workItem.includedWorks,
                isIncluded: workItem.isIncluded,
                timeInMinutes: workItem.timeInMinutes,
                isCalculable: workItem.isCalculable,
            }
        })
    }, [workTaskBasket?.works])

    const useManufacturerRepairTimeDivision = userSettings?.repairTimeOptions?.useManufacturerRepairTimeDivision ?? false
    const { useRepairTimeCalculation, defaultRepairTimeDivision, costEstimationOptions } = workTaskBasket?.costEstimation ?? {}

    const showWorkEstimationRequest = useMemo<ShowWorkEstimationRequest>(() => {
        return {
            works: workEstimationItemsRequest,
            defaultRepairTimeDivision,
            useRepairTimeCalculation,
            useManufacturerRepairTimeDivision,
        }
    }, [workEstimationItemsRequest, defaultRepairTimeDivision, useRepairTimeCalculation, useManufacturerRepairTimeDivision])

    const { workEstimation, workEstimationLoading, workEstimationQueryKey, workEstimationWithError } = useWorkEstimationData(
        workTaskId,
        showWorkEstimationRequest
    )

    // TODO: Basket V2 remove BasketErpInfoGroup and use only GetErpInformationBasketRequest
    const erpInfoGroups = useMemo<Array<BasketErpInfoGroup>>(() => {
        return (
            workTaskBasket?.erpInfoGroups?.map((erpInfoGroup) => {
                const parts = workTaskBasket.parts?.filter((part) => erpInfoGroup.partIds.some((id) => id === part.id))
                return {
                    distributorId: erpInfoGroup.distributorId,
                    parts: parts?.map(mapErpInformationRequestItem) || [],
                }
            }) || []
        )
    }, [workTaskBasket?.erpInfoGroups, workTaskBasket?.parts])

    const getErpInformationBasketRequest = useMemo<GetErpInformationBasketRequest[]>(() => {
        if (!orderOptionsLoaded || !erpInfoGroups.length) {
            return []
        }
        return erpInfoGroups.map((erpInfoGroup) => ({
            distributorId: erpInfoGroup.distributorId,
            catalogContext: CatalogContext.Basket,
            telesalesCustomerNo,
            items: erpInfoGroup.parts,
            orderOptions: getErpInfoGroupOrderOptions(erpInfoGroup, orderOptionsGroups),
            warehouseOrderOptions: getErpInfoGroupWarehouseOrderOptions(erpInfoGroup, orderOptionsGroups),
        }))
    }, [erpInfoGroups, orderOptionsGroups, telesalesCustomerNo, orderOptionsLoaded])

    const { basketErpInformation, erpInfoBeingLoadedPartIds, updatePartErpInfo, updateShipmentModeForPart, mergeTecComInfo } = useErpInfosBasketData(
        workTaskId,
        getErpInformationBasketRequest
    )
    const basketErpInfosIndicator = useMemo(() => {
        const costEstimationPartsCount = workTaskBasket?.costEstimation?.partIds?.length

        const state: BasketErpIndicator = {
            hasErrors: false,
            hasOrderOptions: false,
            isLoaded: erpInfoGroups.some((group) => !!group.parts.length) ? !!basketErpInformation.length : costEstimationPartsCount !== 0, // making sure there is data to load
            isLoading: false,
            showAlternatives: false,
            showBonusInformation: false,
            showGraduatedPrices: false,
            showAdditionalCostsConfirmation: false,
        }

        basketErpInformation.forEach((erpInfo) => {
            const basketErpInformationItemsLength = basketErpInformation.flatMap((erp) => erp.erpInfos?.items).length
            const erpInfoGroupsPartsLength = erpInfoGroups.flatMap((erp) => erp.parts).length

            state.isLoading ||=
                erpInfo.erpInfosLoading ||
                (!erpInfo.erpInfosWithError && basketErpInformationItemsLength !== erpInfoGroupsPartsLength) ||
                (!erpInfo.erpInfos && !erpInfo.erpInfosWithError)

            state.isLoaded &&= (!!erpInfo.erpInfos && basketErpInformationItemsLength === erpInfoGroupsPartsLength) || erpInfo.erpInfosWithError
            state.showAlternatives ||= erpInfo.erpInfos?.showAlternatives ?? false
            state.showGraduatedPrices ||= erpInfo.erpInfos?.showGraduatedPrices ?? false
            state.showAdditionalCostsConfirmation ||= erpInfo.erpInfos?.showAdditionalCostsConfirmation ?? false
            state.showBonusInformation ||= erpInfo.erpInfos?.showBonusInformation ?? false
            state.hasOrderOptions ||=
                erpInfo.erpInfos?.items?.some(
                    (item) =>
                        item.orderOptions &&
                        ((item.orderOptions.billingAddresses && !item.orderOptions.billingAddresses.isNotVisible) ||
                            (item.orderOptions.shipmentModes && !item.orderOptions.shipmentModes.isNotVisible))
                ) ?? false
            state.hasErrors ||= (erpInfo.erpInfos?.hasErrors || erpInfo.erpInfosWithError) ?? false
        })
        return state
    }, [basketErpInformation, erpInfoGroups, workTaskBasket?.costEstimation?.partIds?.length])

    const erp: BasketErp = {
        basketErpInformation,
        basketErpInfosIndicator,
    }

    const calculateWorkTaskBasketRequest = useMemo<CalculateWorkTaskBasketRequest>(() => {
        return {
            workTaskId,
            repairTimeDivision: workEstimation?.repairTimeDivision ?? defaultRepairTimeDivision,
            parts: workTaskBasket?.parts?.map((partItem) => mapCalculatePartRequest(partItem, basketErpInformation)),
            works: workTaskBasket?.works?.map((workItem) => mapCalculateWorkRequest(workItem, workEstimation?.works)),
            useCostEstimation: !!workTaskBasket?.costEstimation,
            costEstimationOptions,
        }
    }, [
        workTaskId,
        workEstimation?.repairTimeDivision,
        workEstimation?.works,
        defaultRepairTimeDivision,
        workTaskBasket?.parts,
        workTaskBasket?.works,
        costEstimationOptions,
        workTaskBasket?.costEstimation,
        basketErpInformation,
    ])

    const { workTaskBasketCalculation, workTaskBasketCalculationLoading, workTaskBasketCalculationWithError } = useCalculateWorkTaskBasketData(
        workTaskId,
        calculateWorkTaskBasketRequest,
        !!workEstimation || workEstimationWithError,
        basketErpInfosIndicator.isLoaded
    )

    const calculateBonusPointsRequest = useMemo<CalculateBonusPointsRequest>(() => {
        return {
            workTaskId,
            bonusPointItemsToCalculate: workTaskBasket?.parts
                ?.map((partItem) => mapCalculateBonusItemRequest(partItem, catalogArticles))
                .filter(notUndefinedOrNull),
        }
    }, [workTaskId, workTaskBasket?.parts, catalogArticles])

    const { bonusPointsCalculation, bonusPointsLoading, bonusPointsWithErrors } = useCalculateBonusPointsData(workTaskId, calculateBonusPointsRequest)

    const updateOrderRequest = useMemo<UpdateOrderRequest>(() => {
        return {
            erpGroups: erpInfoGroups.map((erpInfoGroup) => ({
                distributorId: erpInfoGroup.distributorId,
                items:
                    workTaskBasket?.parts
                        ?.filter((partItem) => erpInfoGroup.parts.some((part) => part.itemId === partItem.id))
                        .map((partItem) => mapUpdateOrderPartRequest(partItem, basketErpInformation)) ?? [],
                orderOptions: getErpInfoGroupOrderOptions(erpInfoGroup, orderOptionsGroups),
                warehouseOrderOptions: getErpInfoGroupWarehouseOrderOptions(erpInfoGroup, orderOptionsGroups),
            })),
        }
    }, [basketErpInformation, erpInfoGroups, orderOptionsGroups, workTaskBasket?.parts])

    const { updatedOrderData, updateOrderDataLoading, updateOrderDataWithError } = useUpdateOrderData(workTaskId, updateOrderRequest)

    const handleBasketUpdateWorkflow = useBasketUpdateWorkflow(updatePartErpInfo)

    const basketMutations = useBasketMutations(workTaskId, workTaskBasketQueryKey, workTaskBasket?.parts, handleBasketUpdateWorkflow)
    const costEstimationMutations = useCostEstimationsMutations(
        workTaskId,
        workTaskBasketQueryKey,
        workEstimationQueryKey,
        handleBasketUpdateWorkflow,
        workTaskBasket?.costEstimation
    )

    const [beingUpdatedPartIds, setBeingUpdatedPartIds] = useState<string[]>([])
    const [orderOptionsBeingUpdatedPartIds, setOrderOptionsBeingUpdatedPartIds] = useState<string[]>([])
    const [beingUpdatedWorkIds, setBeingUpdatedWorkIds] = useState<string[]>([])

    const { basket } = useBasket(
        catalogArticles,
        workTaskBasket,
        erpInfoGroups,
        basketErpInformation,
        workTaskBasketCalculation,
        beingUpdatedPartIds,
        orderOptionsBeingUpdatedPartIds,
        orderOptionsGroups,
        erpInfoBeingLoadedPartIds,
        bonusPointsCalculation,
        updatedOrderData
    )

    const { costEstimation } = useCostEstimation(
        workTaskId,
        catalogArticles,
        beingUpdatedWorkIds,
        beingUpdatedPartIds,
        workTaskBasket,
        erpInfoGroups,
        basketErpInformation,
        workTaskBasketCalculation,
        workEstimation,
        availableRepairTimeProviders,
        erpInfoBeingLoadedPartIds,
        workEstimationWithError
    )

    const { sendWorkTaskOrders } = useSendWorkTaskOrder(workTaskId, telesalesCustomerNo, basket.state.basketOrderGroups)
    const { orderSending, setOrderSending } = useOrderSending(workTaskId)

    const basketLoading = useMemo(() => {
        return (
            workTaskBasketLoading ||
            basketErpInfosIndicator.isLoading ||
            workTaskBasketCalculationLoading ||
            orderOptionsLoading ||
            orderSending ||
            updateOrderDataLoading ||
            bonusPointsLoading
        )
    }, [
        workTaskBasketLoading,
        basketErpInfosIndicator.isLoading,
        workTaskBasketCalculationLoading,
        orderOptionsLoading,
        orderSending,
        updateOrderDataLoading,
        bonusPointsLoading,
    ])

    const costEstimationLoading = useMemo(() => {
        return (
            workTaskBasketLoading ||
            hasRepairTimesLoading ||
            workEstimationLoading ||
            basketErpInfosIndicator.isLoading ||
            workTaskBasketCalculationLoading
        )
    }, [workTaskBasketLoading, workEstimationLoading, basketErpInfosIndicator.isLoading, workTaskBasketCalculationLoading, hasRepairTimesLoading])

    const basketWithErrors = useMemo(() => {
        return (
            workTaskBasketWithError ||
            orderOptionsWithError ||
            basketErpInfosIndicator.hasErrors ||
            workTaskBasketCalculationWithError ||
            updateOrderDataWithError
        )
    }, [
        workTaskBasketWithError,
        orderOptionsWithError,
        basketErpInfosIndicator.hasErrors,
        workTaskBasketCalculationWithError,
        updateOrderDataWithError,
    ])

    const costEstimationWithErrors = useMemo(() => {
        return workTaskBasketWithError || (basketErpInfosIndicator.hasErrors && hideBasket) || workTaskBasketCalculationWithError
    }, [workTaskBasketWithError, basketErpInfosIndicator.hasErrors, hideBasket, workTaskBasketCalculationWithError])

    useEffect(() => {
        return channel("WORKTASK", workTaskId).subscribe("WORKTASK/RESET", () => {
            costEstimationMutations.removeVehicleImage()
        })
    }, [workTaskId, costEstimationMutations])

    const changePartOrderOptions = useCallback(
        async (part: BasketPart, selectedOrderOptions: SelectedOrderOptions) => {
            if (selectedOrderOptions.selectedShipmentMode) {
                const { partItem, erpInfoResponse } = part
                const request: SelectShipmentRequest = {
                    optionId: selectedOrderOptions.selectedShipmentMode?.id,
                    optionDescription: selectedOrderOptions.selectedShipmentMode?.description,
                    workTaskId,
                    warehouseId: partItem.orderItem?.warehouseId,
                    distributorId: partItem.orderItem?.distributorId,
                    selectedOptionsItem: erpInfoResponse?.orderOptions?.selectedOptionsItem,
                    itemId: partItem.id,
                }
                setOrderOptionsBeingUpdatedPartIds((prev) => [...prev, part.partItem.id])
                await updatePartOrderOptionsFunctions
                    .selectShipment(request)
                    .then(async (response) => {
                        // updateOrderOptionsOnChange should not be supported at a part level, therefore it is not handle in this reponse, only updateErpInformationOnChange.
                        if (erpInfoResponse?.orderOptions?.shipmentModes?.updateErpInformationOnChange) {
                            await updatePartErpInfo(partItem.id)
                        } else if (response?.selectedOptionsItem && selectedOrderOptions.selectedShipmentMode?.id) {
                            await updateShipmentModeForPart(partItem.id, response.selectedOptionsItem, selectedOrderOptions.selectedShipmentMode.id)
                        }
                        if (erpInfoResponse?.orderOptions?.shipmentModes?.updateOrderOnChange) {
                            invalidateUpdateOrder()
                        }
                    })
                    .finally(() => {
                        setOrderOptionsBeingUpdatedPartIds((prev) => prev.filter((partId) => partId !== part.partItem.id))
                    })
            }
        },
        [invalidateUpdateOrder, updatePartErpInfo, updatePartOrderOptionsFunctions, updateShipmentModeForPart, workTaskId]
    )

    const changeBasketPartQuantityHelper = useCallback(
        async (part: BasketPart, quantityValue: number) => {
            const availability = part.erpInfoResponse?.availability
            const availabilityLog: AvailabilityLog = {
                status: availability?.type || AvailabilityStatus.Unknown,
                description: availability?.shortDescription,
            }
            setBeingUpdatedPartIds((prev) => [...prev, part.partItem.id])
            await basketMutations.changeBasketPartQuantity(part, quantityValue, availabilityLog)
            setBeingUpdatedPartIds((prev) => prev.filter((partId) => partId !== part.partItem.id))
        },
        [basketMutations]
    )

    const toggleIncludeBasketPartsHelper = useCallback(
        async (parts: BasketPart[]) => {
            const partIds = parts.map((part) => part.partItem.id)
            setBeingUpdatedPartIds((prev) => [...prev, ...partIds])
            await basketMutations.toggleIncludeBasketParts(parts)
            setBeingUpdatedPartIds((prev) => prev.filter((partId) => !partIds.includes(partId)))
        },
        [basketMutations]
    )

    const removeItemListsHelper = useCallback(
        async (partIds: string[], isExternalCall?: boolean) => {
            const origin = getCatalogOrigin()
            setBeingUpdatedPartIds((prev) => [...prev, ...partIds])
            await basketMutations.removePartList({
                workTaskId,
                partIds,
                isExternalCall,
                log: { eventId: uniqueId(), origin: origin === TmaEModule.ARTICLE_LIST ? origin : TmaEModule.SHOPPING_CART },
            })
            setBeingUpdatedPartIds((prev) => prev.filter((partId) => !partIds.includes(partId)))
        },
        [basketMutations, workTaskId]
    )

    const removePartNoteHelper = useCallback(
        async (part: BasketPart) => {
            setBeingUpdatedPartIds((prev) => [...prev, part.partItem.id])
            await basketMutations.removePartNote(part)
            setBeingUpdatedPartIds((prev) => prev.filter((partId) => partId !== part.partItem.id))
        },
        [basketMutations]
    )

    const savePartNoteHelper = useCallback(
        async (part: BasketPart, note: string, isNewNote: boolean) => {
            setBeingUpdatedPartIds((prev) => [...prev, part.partItem.id])
            await basketMutations.savePartNote(part, note, isNewNote)
            setBeingUpdatedPartIds((prev) => prev.filter((partId) => partId !== part.partItem.id))
        },
        [basketMutations]
    )

    const changeCostEstimationPartQuantityHelper = useCallback(
        async (part: BasketPart, quantityValue: number) => {
            setBeingUpdatedPartIds((prev) => [...prev, part.partItem.id])
            await costEstimationMutations.changeCostEstimationPartQuantity(part, quantityValue)
            setBeingUpdatedPartIds((prev) => prev.filter((partId) => partId !== part.partItem.id))
        },
        [costEstimationMutations]
    )

    const toggleIncludeCostEstimationPartsHelper = useCallback(
        async (parts: BasketPart[]) => {
            const partIds = parts.map((part) => part.partItem.id)
            setBeingUpdatedPartIds((prev) => [...prev, ...partIds])
            await costEstimationMutations.toggleIncludeCostEstimationParts(parts, workTaskBasket?.parts)
            setBeingUpdatedPartIds((prev) => prev.filter((partId) => !partIds.includes(partId)))
        },
        [costEstimationMutations, workTaskBasket?.parts]
    )

    const removePartListsHelper = useCallback(
        async (partIds: string[]) => {
            setBeingUpdatedPartIds((prev) => [...prev, ...partIds])
            await costEstimationMutations.removePartList(partIds)
            setBeingUpdatedPartIds((prev) => prev.filter((partId) => !partIds.includes(partId)))
        },
        [costEstimationMutations, workTaskId]
    )

    const editCostEstimationPartHelper = useCallback(
        async (
            part: BasketPart,
            rebate?: number,
            surcharge?: number,
            regularPrice?: number,
            customPartNumber?: string,
            customPartDescription?: string,
            vatRate?: VatRate
        ) => {
            setBeingUpdatedPartIds((prev) => [...prev, part.partItem.id])
            await costEstimationMutations.editCostEstimationPart(
                part,
                rebate,
                surcharge,
                regularPrice,
                customPartNumber,
                customPartDescription,
                vatRate
            )
            setBeingUpdatedPartIds((prev) => prev.filter((partId) => partId !== part.partItem.id))
        },
        [costEstimationMutations]
    )

    const toggleIncludeWorksHelper = useCallback(
        async (works: BasketWork[]) => {
            const workIds = works.map((work) => work.workItem.id)
            setBeingUpdatedWorkIds((prev) => [...prev, ...workIds])
            await costEstimationMutations.toggleIncludeWorks(works)
            setBeingUpdatedWorkIds((prev) => prev.filter((workId) => !workIds.includes(workId)))
        },
        [costEstimationMutations]
    )

    const removeWorkListsHelper = useCallback(
        async (workIds: string[], isExternalCall?: boolean) => {
            setBeingUpdatedWorkIds((prev) => [...prev, ...workIds])
            await costEstimationMutations.removeWorkList({ workIds, isExternalCall })
            setBeingUpdatedWorkIds((prev) => prev.filter((partId) => !workIds.includes(partId)))
        },
        [costEstimationMutations]
    )

    const resetRepairTimesListHelper = useCallback(
        async (works: BasketWork[], customerDefaults: CustomerDefaults) => {
            const workIds = works.map((work) => work.workItem.id)
            setBeingUpdatedWorkIds((prev) => [...prev, ...workIds])
            await costEstimationMutations.resetRepairTimes(works, customerDefaults)
            setBeingUpdatedWorkIds((prev) => prev.filter((workId) => !workIds.includes(workId)))
        },
        [costEstimationMutations]
    )

    const editCostEstimationWorkHelper = useCallback(
        async (
            work: BasketWork,
            repairTimeDivision: number,
            categoryOfWork: WorkCategory,
            description: string,
            editWorkMode: EditWorkMode,
            customerDefaults?: CustomerDefaults,
            workNumber?: string,
            time?: number,
            hourlyRate?: number,
            fixedPriceValue?: number,
            rebate?: number,
            surcharge?: number,
            vatRate?: VatRate
        ) => {
            setBeingUpdatedWorkIds((prev) => [...prev, work.workItem.id])
            await costEstimationMutations.editCostEstimationWork(
                work,
                repairTimeDivision,
                categoryOfWork,
                description,
                editWorkMode,
                customerDefaults,
                workNumber,
                time,
                hourlyRate,
                fixedPriceValue,
                rebate,
                surcharge,
                vatRate
            )
            setBeingUpdatedWorkIds((prev) => prev.filter((workId) => workId !== work.workItem.id))
        },
        [costEstimationMutations]
    )

    const replaceWorkWithCustomWorkHelper = useCallback(
        async (workToReplace: BasketWork, repairTimeDivision: number, customWork: CustomWork, vehicleId?: string) => {
            const workToReplaceId = workToReplace.workItem.id
            setBeingUpdatedWorkIds((prev) => [...prev, workToReplaceId])
            await costEstimationMutations.replaceWorkWithCustomWork(workToReplaceId, repairTimeDivision, customWork, vehicleId)
            setBeingUpdatedWorkIds((prev) => prev.filter((workId) => workId !== workToReplaceId))
        },
        [costEstimationMutations]
    )

    return {
        basket: {
            ...basket,
            state: {
                ...basket.state,
                basketLoading,
                beingUpdatedPartIds,
                basketWithErrors,
                orderSending,
                basketErpInfosIndicator,
                bonusPointsLoading,
                bonusPointsCalculation,
                bonusPointsWithErrors,
            },
            actions: {
                ...basket.actions,
                addCatalogParts: basketMutations.addCatalogParts,
                addCustomParts: basketMutations.addCustomParts,
                addOeParts: basketMutations.addOePartList,
                addWholesalerParts: basketMutations.addWholesalerParts,
                changeItemDistributor: basketMutations.changeItemDistributor,
                changePartOrderOptions,
                changePartQuantity: changeBasketPartQuantityHelper,
                mergeTecComInfo,
                removeAllParts: basketMutations.removeAllWorkTaskItems,
                removePartNote: removePartNoteHelper,
                removeParts: removeItemListsHelper,
                replacePartWithCatalogPart: basketMutations.replacePartWithCatalogPart,
                savePartNote: savePartNoteHelper,
                sendWorkTaskOrders,
                setOrderSending,
                toggleIncludeBasketParts: toggleIncludeBasketPartsHelper,
            },
        },
        costEstimation: {
            ...costEstimation,
            state: {
                ...costEstimation.state,
                costEstimationLoading,
                costEstimationWithErrors,
                hasRepairTimesWithError,
                workEstimationWithError,
            },
            actions: {
                ...costEstimation.actions,
                addCustomWorks: costEstimationMutations.addCustomWorks,
                addRepairTimes: costEstimationMutations.addRepairTimes,
                attachVehicleImage: costEstimationMutations.attachVehicleImg,
                calculateWorks: costEstimationMutations.calculateWorks,
                changePriceType: costEstimationMutations.changePriceType,
                changePriceVat: costEstimationMutations.changePriceVat,
                changeCustomerGroup: costEstimationMutations.changeCustomerGroup,
                changePartQuantity: changeCostEstimationPartQuantityHelper,
                editPart: editCostEstimationPartHelper,
                editWork: editCostEstimationWorkHelper,
                resetCostEstimation: costEstimationMutations.resetCostEstimation,
                removeParts: removePartListsHelper,
                toggleIncludeCostEstimationParts: toggleIncludeCostEstimationPartsHelper,
                toggleIncludeWorks: toggleIncludeWorksHelper,
                removeWorks: removeWorkListsHelper,
                resetRepairTimes: resetRepairTimesListHelper,
                replaceWorkWithCustomWork: replaceWorkWithCustomWorkHelper,
            },
        },
        erp,
        useRepairTimeCalculation,
        workTaskBasket,
        workEstimationLoading,
        workEstimation,
        workTaskBasketLoading,
        workTaskBasketWithError,
        workTaskBasketCalculationLoading,
        workTaskBasketCalculation,
        workTaskBasketCalculationWithError,
    }
}
