import { PartsBundleParams, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import {
    getArticles,
    getArticleImages,
    getArticlesByArticleNoWithOptVehicle,
    getArticlesByArticleNoWithOptVehicleBuffered,
    getArticlesBySupplierArticleNos,
    getArticlesBySupplierArticleNumber,
    getArticlesByWholesalerArticleNos,
    getArticlesByWholesalerArticleNumber,
    getMultiWholesalerArticleNosByTraderId,
} from "../data/repositories"
import { deleteNote, findNotesBuffered, saveNote } from "../data/repositories/articleNotes"

export const version = {
    name: "parts",
    version: "1.0.0",
}

export const advertisementCategoryDivider = "~"
export const DefaultArticleListPageSize = 25

let bundleParams: PartsBundleParams

export function initPartsBundle(params: any) {
    bundleParams = {
        ...params,
        awProviders: Object.keys(params.awProviders || {})
            .map((key) => ({ ...params.awProviders[key] }))
            .filter((x) => !!x && !!x.id),
        dataOptimizationList: parseInt(params.dataOptimizationList) || undefined,
        maximumPartsToCompare: params.maximumPartsToCompare ?? 25,
        articleListPageSize: parseInt(params.articleListPageSize) || DefaultArticleListPageSize,

        // Load oe information parameters
        oeInformation: params.oeInformation
            ? {
                  ...params.oeInformation,
                  displayVehicleOeCriteria: !!+params.oeInformation.displayVehicleOeCriteria,
                  showReplacementChain: !!+params.oeInformation.showReplacementChain,
                  hideManufacturerLogo: !!+params.oeInformation.hideManufacturerLogo,
                  showOePrice: !!+params.oeInformation.showOePrice,
                  transferToShoppingCart: !!+params.oeInformation.transferToShoppingCart,
              }
            : undefined,

        // Set default minimum search lengths
        minimumSearchLength: {
            ...params.minimumSearchLength,
            // Set defaults as fallback
            directSearch: params.minimumSearchLength?.directSearch ?? 2,
            vehicleSearch: params.minimumSearchLength?.vehicleSearch ?? 3,
            universalSearch: params.minimumSearchLength?.universalSearch ?? 1,
            tiresSearch: params.minimumSearchLength?.tiresSearch ?? 4,
        },

        // The parameters "availabilityFilterItemsToShow" or "availabilityFilterItemsToShowSecondary" could be overwritten to "false"
        // in the config so we only accept it here if it is a valid array, otherwise we treat it if it wasn't set at all.
        availabilityFilterItemsToShow: Array.isArray(params.availabilityFilterItemsToShow) ? params.availabilityFilterItemsToShow : undefined,
        availabilityFilterItemsToShowSecondary: Array.isArray(params.availabilityFilterItemsToShowSecondary)
            ? params.availabilityFilterItemsToShowSecondary
            : undefined,
    }

    Container.register({
        name: RegisteredModels.Articles,
        modelActions: {
            load: getArticles,
        },
        containerActions: {
            getArticles,
            getArticlesByArticleNoWithOptVehicle,
            getArticlesByArticleNoWithOptVehicleBuffered,
            getArticlesByWholesalerArticleNumber,
            getArticlesByWholesalerArticleNos,
            getArticlesBySupplierArticleNos,
            getArticlesBySupplierArticleNumber,
            getMultiWholesalerArticleNosByTraderId,
        },
    })

    Container.register({
        name: RegisteredModels.Article_Notes,
        containerActions: {
            loadArticleNoteByNoteIds: findNotesBuffered,
            saveArticleNote: saveNote,
            deleteArticleNote: deleteNote,
        },
    })

    Container.register({
        name: RegisteredModels.Articles_ByArticleNumbersWithOptionalVehicle,
        modelActions: {
            load: getArticlesByArticleNoWithOptVehicle,
        },
    })
    Container.register({
        name: RegisteredModels.Articles_ByArticleNumbersWithOptionalVehicleBuffered,
        modelActions: {
            load: getArticlesByArticleNoWithOptVehicleBuffered,
        },
    })
    Container.register({
        name: RegisteredModels.Articles_ByWholesalerArticleNumber,
        modelActions: {
            load: getArticlesByWholesalerArticleNumber,
        },
    })
    Container.register({
        name: RegisteredModels.Articles_ByWholesalerArticleNumbers,
        modelActions: {
            load: getArticlesByWholesalerArticleNos,
        },
    })
    Container.register({
        name: RegisteredModels.Articles_BySupplierArticleNumbers,
        modelActions: {
            load: getArticlesBySupplierArticleNos,
        },
    })
    Container.register({
        name: RegisteredModels.Articles_GetMultiWholesalerArticleNosByTraderId,
        modelActions: {
            load: getMultiWholesalerArticleNosByTraderId,
        },
    })
    Container.register({
        name: RegisteredModels.Articles_GetArticleImageByInternalArticleId,
        modelActions: {
            load: getArticleImages,
        },
    })

    loadSearchTreeIcons()

    return bundleParams
}

export function getBundleParams(): PartsBundleParams {
    if (!bundleParams) {
        throw new Error(`${version.name}: The bundle params have to be set in order to use this bundle`)
    }

    return bundleParams
}

function loadSearchTreeIcons() {
    const bundleParams = getBundleParams()

    if (!bundleParams.treeIconsBaseUrl) {
        console.warn(
            `${version.name}: The parameter 'treeIconsBaseUrl' has to be set in the configuration, otherwise the search tree icons will not be shown.`
        )
        return
    }

    if (bundleParams.vehicleTreeId) {
        loadSprite(`${bundleParams.treeIconsBaseUrl}/TNode/${bundleParams.vehicleTreeId}.svg?${Date.now()}`)
    }

    if (bundleParams.universalTreeId) {
        loadSprite(`${bundleParams.treeIconsBaseUrl}/UniTNode/${bundleParams.universalTreeId}.svg?${Date.now()}`)
    }

    // quick jumps from Universalparst used in compo => uni-search-top-products
    if (bundleParams.universalBestsellerTreeId) {
        loadSprite(`${bundleParams.treeIconsBaseUrl}/UniTNode/${bundleParams.universalBestsellerTreeId}.svg`)
    }
}

function loadSprite(url: string) {
    const xhr = new XMLHttpRequest()
    xhr.open("GET", url)
    xhr.responseType = "text"

    xhr.addEventListener("load", () => {
        if (xhr.status < 200 || xhr.status > 300) {
            console.warn(`${version.name}: The sprite map '${url}' could not be loaded. Error: ${xhr.status} - ${xhr.statusText}`)
            return
        }

        const spriteContainer = document.querySelector("#sprites")

        if (spriteContainer) {
            spriteContainer.innerHTML += xhr.response
        }
    })

    xhr.addEventListener("error", () => {
        console.warn(`${version.name}: The sprite map '${url}' could not be loaded. Error: `, xhr.statusText)
    })

    xhr.send()
}
