import { useTelesalesCustomerNumber, useUser } from '@tm/context-distribution'
import { Dialog, Headline, Image, Loader, Scrollbar } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import { useActions } from '@tm/morpheus'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { RimItem, SizeInfo } from '../../../data/model'
import { Actions } from '../business'
import { RimArticleItem } from '.'
import { MainState } from '../../main'
import { ContactWheels } from '../../_shared'
import { filterRimList } from '../business/helpers/filterRimList'
import { AvailabilityFilterType } from '../../../business'
import { styled } from '@tm/components'
import { getBundleParams } from '../../../utils'

var db = require('just-debounce')

export default function RimArticles() {
    const user = useUser()
    const { translateText } = useLocalization()
    const isHostettler = getBundleParams()?.isHostettler
    const { articles, initialized, availabilityFilter, selectedRimItem, selectedSize } = useSelector((s: MainState) => ({
        articles: s.wheelsList.base.articles,
        initialized: s.wheelsList.base.initialized,
        availabilityFilter: s.wheelsList.base.availabilityFilter,
        selectedRimItem: s.wheelsList.base.selectedRimItem,
        selectedSize: s.wheelsList.base.selectedSize
    }))
    const { loading, error, data, loadingNextItems, hasMoreItems } = articles
    const actions = useActions(Actions, "loadNextRimArticles", "selectRimItem", "saveSelectedRimSize", "changeAvailabilityFilter")
    let scrollTop: number = 0

    const items = filterRimList(data, availabilityFilter)
    const showAvailability = getBundleParams()?.priceAvailabilityInformation ? !user?.userSettings?.hideWheelsAvailability : false
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()

    React.useEffect(() => {
        if (items.length > 0) {
            const prevSelectedRim = items.find(x => selectedRimItem && (x.idRimDesign == selectedRimItem.idRimDesign && x.sizeInfo?.some(y => y.size == selectedSize)))
            const shouldSelectFirstRim = !prevSelectedRim
            
            if (shouldSelectFirstRim) {
                handleSizeClick(items[0])
            } else {
                handleSizeClick(prevSelectedRim, prevSelectedRim.sizeInfo?.find(size => size.size == selectedSize))
            }
        }
    }, [items.length, availabilityFilter])
    
    React.useEffect(() => {
        if (!!user.userSettings?.hideWheelsAvailability) 
            actions.changeAvailabilityFilter(AvailabilityFilterType.None)
    }, [user.userSettings?.hideWheelsAvailability])

    React.useEffect(() => {
        if (!loading && !loadingNextItems && hasMoreItems && items.length < getBundleParams()?.tyresPageSize) {
            actions.loadNextRimArticles(showAvailability, telesalesCustomerNo)
        }
    }, [loading, loadingNextItems, hasMoreItems, items.length])
    
    const handleSizeClick = (rimItem: RimItem, sizeInfo?: SizeInfo) => {
        if (!sizeInfo) {
            const diameterSizes = availabilityFilter ? rimItem.availableDiameterSizes : rimItem.sizeInfo 
            sizeInfo = diameterSizes?.last()
        }
        actions.selectRimItem(rimItem, sizeInfo?.size!)

        const info = rimItem.rimErpInformations?.filter(rimInfo => rimInfo.diameterSize == sizeInfo?.size).first() 
        if (info) {
            actions.saveSelectedRimSize(info)
        }
    }

    const handleScroll = (e: React.UIEvent<HTMLElement> | UIEvent) => {
        if (!hasMoreItems) return

        const el = e.target as HTMLElement
        if ((el.scrollHeight - el.scrollTop) <= (el.clientHeight + 100) && el.scrollTop > scrollTop)
            actions.loadNextRimArticles(showAvailability, telesalesCustomerNo)
        scrollTop = el.scrollTop

    }

    const wrapContent = (children: React.ReactNode) => <StyledWrapper>{children}</StyledWrapper>

    if (loading)
        return wrapContent(
            <div className="article-list__panel article-list__status">
                <Loader />
            </div>
        )

    if (!items.length && initialized)
        return wrapContent(
            <>
                <div className="article-list__panel article-list__status">
                    <Headline size="xs">{translateText(323)}</Headline>
                </div>
            </>
        )
    if (error) {
        return wrapContent(
            <>
                <div className="article-list__panel article-list__status">
                    <Headline size="xs">{translateText(323)}</Headline>
                </div>
                {isHostettler && <ContactWheels />}
            </>
        )
    }

    return wrapContent(
        <Scrollbar onScroll={db(handleScroll, 200)}>
            <StyledCard>
                {items.map((x: RimItem, index: number) => (
                    <RimArticle
                        key={index}
                        item={x}
                        handleClick={handleSizeClick}
                    />
                ))}
            </StyledCard>
            {loadingNextItems && <div className="article-list__panel article-list__status">	<Loader /></div>}
        </Scrollbar>
    )
}

const StyledWrapper = styled("div")(() => ({
    flex: 1
}))

const StyledCard = styled("div")(() => ({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(15em, 1fr))"
}))

type RimArticleProps = { item: RimItem, handleClick: (item: RimItem, sizeInfo?: SizeInfo) => void }

function RimArticle({ item, handleClick }: RimArticleProps) {
    const dialogRef = React.useRef<Dialog>(null)

    const handleZoom = () => {
        dialogRef.current?.show()
    }

    return (
        <>
            <Dialog className="wheels-image-zoom" ref={dialogRef}>
                <Image url={item.perspectivePicture} />
            </Dialog>

            <RimArticleItem
                onZoom={handleZoom}
                onSelect={handleClick}
                item={item} />
        </>
    )
}