import { useEffect, useCallback, useMemo, useRef, useState } from "react"
import { useUser, useWorkTask, useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Button, LicensePlateField, SuggestionFieldButtonGroup, Widget, WidgetSizes, WidgetProps } from "@tm/controls"
import {
    VehicleImage,
    Button as MuiButton,
    styled,
    VehicleHistory,
    Typography,
    Icon,
    Select,
    SelectItem,
    SelectChangeEvent,
    LinkButton,
    Stack,
    Loader,
} from "@tm/components"
import { useLocalization } from "@tm/localization"
import {
    BikeModel,
    CarManufacturer,
    CarModel,
    CarModelDetails,
    channel,
    ECounterType,
    RegistrationNoType,
    TruckModel,
    UserModuleType,
    Vehicle,
    VehicleLookupConfig,
    VehicleShortInfo,
    VehicleType,
} from "@tm/models"
import {
    concat,
    encodeUniqueId,
    getVehicleRegistrationNoLabelTextIds,
    TmaHelper,
    useMultiWidgetState,
    renderRoute,
    useAvailableVehicleSearchOptions,
    useCheckHasMemo,
} from "@tm/utils"
import { style } from "typestyle"
import Morpheus from "@tm/morpheus"
import { useHistory, useParams } from "react-router"
import TopManufacturerCards from "../_shared/top-manufacturer-cards"
import { VehicleDataProgress } from "../_shared/vehicle-data-progress"
import VehicleSearchField from "../_shared/VehicleSearchField"
import { getBundleParams } from "../../utils"
import { useModelDetailsLoadable, useResetFilters, useSelectedVehicleLookup } from "../../data/hooks"
import { constructionYearToString } from "../../helpers/construction-year"
import { isTruckModuleType } from "../../helpers/modules"
import {
    getTruckModuleUrl,
    getVehicleDetailsUrl,
    getVehicleIdentificationUrl,
    getVehicleIdentificationUrlWithLookup,
    getVehicleSearchUrl,
    vehicleDetailsSubPageMemoInfo,
} from "../../helpers/routing"
import { handleUniqueVehicleFound } from "../../helpers/vrm"
import VehicleEditor from "./components/editor"
import SpecialLicensePlatesComponent from "./components/special-license-plates/component"
import { TabNavigation, VehicleTabsDisplayStyle } from "./components/tab-navigation"
import { useRegNoAndType } from "../details/business/hooks"
import { Detail } from "./components/Detail"
import { CountrySelectorOrFlag } from "./components/CountrySelectorOrFlag"
import { VinInputField } from "../_shared/VinInput"

type RouteParams = {
    workTaskId?: string
    vehicleId?: string
    manufacturerId: any
    mainModelSeriesId?: string
    query?: string
    selectedLookupType: string
    countryCode: string
}

type Props = {
    storeId?: string
    showComponentKey?: string
    className?: string
    collapsible?: boolean
    highlight?: boolean
    indicator?: boolean
    height?: number
    size?: WidgetSizes
    hideCover?: boolean
    hideChangeButton?: boolean
    defaultVehicleType?: VehicleType
    vehicleTypeSelectionStyle?: VehicleTabsDisplayStyle
    switchVehicleButtonAction?: "showSearchContent"
    // params added for quick tranform renderMicros
    showSpecialLicensePlateMicro?: boolean
    showVrmLicensePlateMicro?: boolean
    specialLicenseProps?: Record<string, any>
    hideFooterButtons?: boolean
    alternativeView?: boolean // the widget is shown differently in toolkits
    fullHeight?: boolean
}

const emptyEngineCode = "--"

// This is workaround for ATU memo tool small icon, do not remove!
const StyledButtonIcon = styled(MuiButton)({
    marginLeft: "0.2em !important", // TODO fix this
    marginRight: "0.2em !important", // TODO fix this
    padding: "0.6em !important", // TODO fix this
})

const StyledIcon = styled(Icon)({
    width: "4em",
    marginRight: "0.2em",
    opacity: 1,
})

const StyledSelect = styled(Select)({
    ".MuiSelect-select": {
        padding: "4px 4px 3px 18px !important",
        fontSize: "14px !important",
    },
    ".MuiSelect-icon": {
        top: "calc(50% - 7px) !important",
        left: 4,
    },
})

export default function WidgetComponent(props: Props) {
    const {
        storeId,
        hideCover,
        hideChangeButton,
        hideFooterButtons,
        defaultVehicleType,
        vehicleTypeSelectionStyle,
        className,
        showSpecialLicensePlateMicro,
        specialLicenseProps,
        alternativeView,
        switchVehicleButtonAction,
        showComponentKey,
        height,
        size,
        collapsible,
        indicator,
        highlight,
    } = props

    const { translate, translateText, date, number } = useLocalization()
    const history = useHistory()
    const matchParams = useParams<RouteParams>()
    const searchParams = new URLSearchParams(history.location.search)
    const initialVin = searchParams.get("vin") ?? undefined

    const { userContext } = useUser() ?? {}
    const { workTask, workTaskId, attachToWorkTask } = useWorkTask() ?? {}
    const { vehicle } = workTask ?? {}

    const [selectedMultiWidgetTab] = useMultiWidgetState({ storeId })

    const [vehicleType, setVehicleType] = useState(defaultVehicleType ?? VehicleType.PassengerCar)
    const [showCurrentVehicleContent, setShowCurrentVehicleContent] = useState(true)

    const { selectedVehicleLookup } = useSelectedVehicleLookup((showCurrentVehicleContent ? vehicle?.vehicleType : undefined) ?? vehicleType)

    const { response: modelDetails } = useModelDetailsLoadable({
        vehicleType: vehicle?.vehicleType,
        modelId: vehicle?.tecDocTypeId,
        registrationNoTypeId: vehicle?.registrationTypeId,
        selectedLookupType: selectedVehicleLookup.lookupTypeId,
    })

    const { checkHasMemo } = useCheckHasMemo(!!getBundleParams().memoToolEnabled, vehicle?.manufacturer, vehicle?.tecDocTypeId, vehicle?.engineCode)
    const hasMemoInfo = !!checkHasMemo?.hasMemoInfo

    const searchFieldRef = useRef<SuggestionFieldButtonGroup<any>>(null)
    const [active, setActive] = useState(!!hideCover)
    const [editing, setEditing] = useState(false)
    const [navIdentifier, setNavIdentifier] = useState("plateId")
    const resetFilters = useResetFilters(vehicleType)
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const feedbackModule = userContext.modules?.find((x) => x.type === UserModuleType.Feedback)

    const byLookupTypeId = useCallback(
        (lookup: VehicleLookupConfig) => lookup.lookupTypeId === selectedVehicleLookup.lookupTypeId,
        [selectedVehicleLookup]
    )

    const alreadySelectedLookup = availableVehicleSearches?.configuredVehicleLookups.find(byLookupTypeId)
    const { regNoType, regNo } = useRegNoAndType(
        vehicleType,
        vehicle,
        searchParams.toString(),
        !alreadySelectedLookup ? availableVehicleSearches.defaultVehicleLookup : alreadySelectedLookup
    )

    const handleSelectEngineCode = useCallback(
        (e: SelectChangeEvent<unknown>) => {
            if (!vehicle) {
                return
            }
            const code = e.target.value as string
            attachToWorkTask?.({ vehicle: { ...vehicle, engineCode: code !== emptyEngineCode ? code : undefined } })
        },
        [vehicle]
    )

    const selectedSearchConfig = useMemo(() => {
        if (!!vehicle && !vehicle.countryCode) {
            return
        }

        if (availableVehicleSearches) {
            const { configuredVehicleLookups } = availableVehicleSearches
            const currentLookupConfig = configuredVehicleLookups.find(
                (lookup) => lookup.isSelectable && lookup.lookupTypeId === selectedVehicleLookup.lookupTypeId
            )
            return currentLookupConfig ?? availableVehicleSearches?.defaultVehicleLookup
        }
    }, [availableVehicleSearches, selectedVehicleLookup, vehicle])

    const specializedSearchConfig = useMemo(() => {
        // this searches for a lookup config which is not used in the default search and has the same countrycode as the selected one to show the special licenseplate field
        const { configuredVehicleLookups } = availableVehicleSearches // if there is an selectedSearchConfig, availableVehicleSearches is maybe set
        if (availableVehicleSearches?.configuredVehicleLookups && selectedSearchConfig) {
            const vrmLookupConfig = configuredVehicleLookups.find(
                (lookup) => !lookup.isUsedInDefaultSearch && lookup.isSelectable && selectedSearchConfig.countryCode === lookup.countryCode
            )
            return vrmLookupConfig
        }
    }, [selectedSearchConfig, availableVehicleSearches])

    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)

    useEffect(() => {
        return channel("TELESALES").subscribe("PARTNERNOTES/HIDE", () => {
            searchFieldRef?.current?.focus(false)
        })
    }, [searchFieldRef])

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            searchFieldRef.current?.focus()
        }, 25)

        channel("APP").subscribe("SHORTCUTS", (result) => {
            if (["PKW", "NKW"].includes(result.key)) {
                switch (result.key) {
                    case "PKW":
                        setVehicleType(VehicleType.PassengerCar)
                        break
                    case "NKW":
                        setVehicleType(VehicleType.CommercialVehicle)
                        break
                    default:
                        break
                }
                setTimeout(() => searchFieldRef.current?.focus(), 225)
            }
        })

        return () => {
            channel("APP").clearSubscriptions("SHORTCUTS")
            window.clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        setEditing(false)
        handleVehicleAttached()
    }, [vehicle])

    function showPartsIndicator() {
        channel("WORKTASK", workTaskId).publish("PARTS/SHOW_PARTS_INDICATOR", undefined)
    }

    function handleWidgetClick() {
        setActive(true)
    }

    function handleToggleVehicleEditor(navIdentifier: keyof Vehicle = "plateId", byEditButton?: boolean) {
        if (byEditButton || !vehicle || !vehicle[navIdentifier]) {
            setEditing((prev) => !prev)
            setNavIdentifier(navIdentifier)
        }
    }

    function handleShowArticleFeedback() {
        const { vehicleFeedbackPath } = getBundleParams()

        if (!vehicleFeedbackPath) {
            return
        }

        const url = renderRoute(vehicleFeedbackPath, { ...matchParams })

        if (getBundleParams().enableMVCFeedback) {
            history.push(url)
            return
        }

        Morpheus.showView("1", url)
    }

    function handleVehicleUpdate(vehicle: Vehicle) {
        return attachToWorkTask?.({ vehicle }) ?? Promise.resolve()
    }

    function handleTopManufacturerSelect(manufacturer: CarManufacturer) {
        TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarGraphicSearch)
        history.push(
            getVehicleIdentificationUrlWithLookup(matchParams, {
                vehicleType,
                manufacturerId: manufacturer.id,
                mainModelSeriesId: getBundleParams().hideMainModelSeries ? 0 : undefined,
            })
        )
    }

    function handleStartSearch(query: string, regNoType: RegistrationNoType) {
        resetFilters() // Reset filters before opening search page
        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionSearchSymbol)

        history.push(
            getVehicleSearchUrl(matchParams, {
                vehicleType,
                query,
                registrationNoType: regNoType,
            })
        )
    }

    function handleUniqueVehicle(model: CarModel | BikeModel | TruckModel | VehicleShortInfo, query: string) {
        if (!attachToWorkTask) {
            return
        }

        handleUniqueVehicleFound(
            model,
            vehicleType,
            query,
            attachToWorkTask,
            undefined,
            selectedSearchConfig?.countryCode?.toLowerCase(),
            selectedVehicleLookup.lookupTypeId
        ).then(handleVehicleAttached)
    }

    function handleAttachVehicleId(vehicleId: string, query: string) {
        if (!attachToWorkTask) {
            return
        }

        TmaHelper.VehicleSelection.Search.FromWidget({ dataSourceId: RegistrationNoType.VehicleBase, query })

        attachToWorkTask({ vehicle: vehicleId }).then(handleVehicleAttached)
    }

    const handleSetVehicleTypeForTab = (identifier: string, counterType: ECounterType) => {
        TmaHelper.GeneralCountEvent.Call(counterType)
        const vehicleType = parseInt(identifier)
        if (!isNaN(vehicleType)) {
            setVehicleType(vehicleType)
        }
    }

    function handleTabSelect(identifier: string) {
        switch (identifier) {
            case VehicleType.PassengerCar.toString():
                handleSetVehicleTypeForTab(identifier, ECounterType.PassengerCar)
                break
            case VehicleType.Motorcycle.toString():
                handleSetVehicleTypeForTab(identifier, ECounterType.Bike)
                break
            case VehicleType.CommercialVehicle.toString():
                handleSetVehicleTypeForTab(identifier, ECounterType.TecDocTruck)
                break
            default:
                if (isTruckModuleType(identifier)) {
                    const url = getTruckModuleUrl(matchParams, identifier)
                    history.push(url)
                    TmaHelper.GeneralCountEvent.CallModule(url)
                }
                break
        }
    }

    /** Is also called when the conflict dialog is opened. Keep that in mind when adding code in this function. */
    function handleVehicleAttached() {
        if (!showCurrentVehicleContent) {
            setShowCurrentVehicleContent(true)
        }
    }

    function handleOpenMemoInfo() {
        if (!vehicle) {
            return
        }

        const url = renderRoute(getBundleParams().vehicleDetailsUrl, {
            ...matchParams,
            vehicleType: vehicle.vehicleType,
            vehicleId: encodeUniqueId(vehicle.id),
            vehicleDetailsSubPage: vehicleDetailsSubPageMemoInfo,
        })

        history.push(url)
    }

    function renderSearchContent() {
        return (
            <>
                <TabNavigation
                    currentVehicleType={vehicleType}
                    moduleAvailabilities={getBundleParams().moduleAvailabilities ?? {}}
                    displayStyle={vehicleTypeSelectionStyle}
                    onTabSelect={handleTabSelect}
                />

                <SpecialLicensePlatesComponent
                    className="vehicle-widget__special-license-plates-micro"
                    vehicleType={vehicleType}
                    onVehicleAttached={handleVehicleAttached}
                    showSpecialLicensePlateMicro={showSpecialLicensePlateMicro}
                    {...specialLicenseProps}
                    registrationNoType={
                        showCurrentVehicleContent
                            ? vehicle?.registrationTypeId || specializedSearchConfig?.lookupTypeId
                            : specializedSearchConfig?.lookupTypeId
                    }
                />

                <VehicleSearchField
                    {...props}
                    initialValue={initialVin}
                    searchFieldRef={searchFieldRef}
                    vehicleType={vehicleType}
                    disable={userContext?.parameter.catalogLight}
                    onStartSearch={handleStartSearch}
                    onUniqueVehicleFound={handleUniqueVehicle}
                    onAttachVehicleId={handleAttachVehicleId}
                    showVrcScanButton={!vehicle}
                    selectedSearchConfig={selectedSearchConfig}
                />
            </>
        )
    }

    function renderDATVinDataBtn(vehicle: Vehicle) {
        if (!getBundleParams().enableDATVinSearch) {
            return
        }

        let title: string
        const disabled = !vehicle.vin

        if (disabled) {
            title = translateText(1541)
        } else if (vehicle.registrationTypeId === RegistrationNoType.DatVin) {
            title = translateText(1542)
        } else {
            title = translateText(1543)
        }

        // The "title" has to be set on an outer div if the button is disabled (button shows no title then)
        return (
            <div title={disabled ? title : undefined} style={{ display: "inline-flex" }}>
                <Button
                    linkTo={getVehicleDetailsUrl(matchParams, {
                        vehicleType: vehicle.vehicleType,
                        vehicleId: encodeUniqueId(vehicle.id),
                    })}
                    className="is-clickable btn--dat-vin-data"
                    icon="dat-vin-data"
                    layout={["ghost"]}
                    size="xl"
                    disabled={disabled}
                    title={title}
                />
            </div>
        )
    }
    function renderVehicleContent() {
        if (!vehicle) {
            return
        }

        const shortenedModelThumbnail = vehicle.modelThumbnail ? vehicle.modelThumbnail.replace(/https?:/, "") : ""
        const shortenedModelSeriesThumbnail = vehicle.modelSeriesThumbnail ? vehicle.modelSeriesThumbnail.replace(/https?:/, "") : ""

        return (
            <div className="card__content">
                <div className="content__head">
                    <div className="description">
                        <Typography variant="body3">{translate(453)}</Typography>
                        <Typography variant="h3">{vehicle.manufacturer || "-"}</Typography>

                        <div className="details">
                            <Detail subtitle={translate(73)} size="large">
                                <Typography variant="body2" className="value">
                                    {removeBrackets(vehicle.modelSeries) || "-"}
                                </Typography>
                            </Detail>

                            {/* //TODO https://zpl.io/aMgwAz2 add missing column */}

                            <Detail subtitle={translate(74)}>
                                <Typography variant="body2" className="value">
                                    {removeBrackets(vehicle.model) || "-"}
                                </Typography>
                            </Detail>

                            <Detail size="fill" bemModifier="btns">
                                {renderDATVinDataBtn(vehicle)}
                            </Detail>

                            {alternativeView && (
                                <Button
                                    onClick={handleToggleVehicleEditor}
                                    title={translateText(1544)}
                                    className="is-clickable btn--top-right"
                                    layout={["ghost"]}
                                    size="xl"
                                >
                                    <Icon name="pencil" />
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="vehicle-image">
                        <VehicleImage
                            modelImage={shortenedModelThumbnail}
                            modelSeriesImage={shortenedModelSeriesThumbnail}
                            vehicleType={vehicle.vehicleType}
                        />
                    </div>
                </div>
                <div className="content__body">
                    {renderWorkTaskVehicleInfo()}
                    {renderVehicleDetails()}
                </div>
                {editing && (
                    <VehicleEditor
                        defaultItem={navIdentifier}
                        vehicle={vehicle}
                        modelDetails={modelDetails?.modelDetails}
                        regNoType={regNoType}
                        onChange={handleVehicleUpdate}
                        onAbort={handleToggleVehicleEditor}
                        onShowPartsIndicator={showPartsIndicator}
                    />
                )}
            </div>
        )
    }

    function renderWorkTaskVehicleInfo() {
        if (!vehicle) {
            return
        }

        const getClassName = (value: any) => `value ${value !== undefined ? "filled" : "unfilled"}`

        return (
            <div className="details">
                <Detail
                    clickable={!vehicle.plateId}
                    size="large"
                    bemModifier="license-plate"
                    title={!vehicle.plateId ? translateText(1545) : ""}
                    onClick={() => handleToggleVehicleEditor("plateId")}
                >
                    <LicensePlateField
                        readonly
                        shortCountryCode={plateCode || ""}
                        className={getClassName(vehicle.plateId)}
                        value={vehicle.plateId}
                    />
                </Detail>

                <Detail
                    subtitle={translate(124)}
                    clickable={!vehicle.initialRegistration}
                    title={!vehicle.initialRegistration ? translateText(1545) : ""}
                    onClick={() => handleToggleVehicleEditor("initialRegistration")}
                >
                    <Typography variant="body2" className={getClassName(vehicle.initialRegistration)}>
                        {vehicle.initialRegistration ? date(vehicle.initialRegistration, "d") : "___.___.______"}
                    </Typography>
                </Detail>

                <Detail
                    subtitle={translate(125)}
                    clickable={!vehicle.mileAge}
                    title={!vehicle.mileAge ? translateText(1545) : ""}
                    onClick={() => handleToggleVehicleEditor("mileAge")}
                >
                    <Typography variant="body2" className={getClassName(vehicle.mileAge)}>
                        {vehicle.mileAge !== undefined ? number(vehicle.mileAge, 0) : "____.____"}
                    </Typography>
                </Detail>

                <Detail
                    subtitle={translate(101)}
                    size="fill"
                    bemModifier="vin"
                    clickable={!vehicle.vin}
                    title={!vehicle.plateId ? translateText(1545) : ""}
                    onClick={() => handleToggleVehicleEditor("vin")}
                >
                    <VinInputField
                        vehicleData={vehicle}
                        autoAttach
                        textFieldProps={{
                            floatingLabel: true,
                            label: translateText(101),
                        }}
                        buttonWrapperProps={{
                            sx: {
                                marginY: "-8px",
                                transform: "translateY(-8px)",
                            },
                        }}
                    />
                </Detail>
            </div>
        )
    }

    function renderVehicleDetails() {
        if (!modelDetails?.modelDetails) {
            return (
                <div className="details">
                    <Loader />
                </div>
            )
        }

        const { enginePowerKw, enginePowerHp, constructionYearFrom, constructionYearTo, engineCapacityCcm } =
            modelDetails.modelDetails as CarModelDetails

        let regNoLabel
        // TODO: configuredLookupConfig if no vehicle is set
        if (vehicle?.registrationNo) {
            const itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(regNoType)
            regNoLabel = itemLabelTextIds?.labelShort ?? itemLabelTextIds?.label
        }

        return (
            <div className="details">
                <Detail subtitle={translate(127)} size="large">
                    <Typography variant="body2">
                        {constructionYearToString(constructionYearFrom)} - {constructionYearToString(constructionYearTo)}
                    </Typography>
                </Detail>

                <Detail
                    subtitle={
                        <>
                            {translate(329)}/{translate(330)}
                        </>
                    }
                >
                    <Typography variant="body2">
                        {enginePowerKw}/{enginePowerHp}
                    </Typography>
                </Detail>

                <Detail subtitle={engineCapacityCcm ? translate(331) : ""}>
                    <Typography variant="body2">{engineCapacityCcm}</Typography>
                </Detail>

                <Detail subtitle={translate(105)} size="fill" bemModifier="engine-codes">
                    {renderEngineCodes()}
                </Detail>

                {regNoLabel && regNo && (
                    <Detail subtitle={translate(regNoLabel)} size="fill" bemModifier="engine-codes">
                        <Typography variant="body2">{vehicle?.registrationNo || regNo}</Typography>
                    </Detail>
                )}
            </div>
        )
    }

    function renderEngineCodes() {
        if (!modelDetails || !modelDetails.modelDetails || !vehicle) {
            return
        }

        const { engineCodes = [] } = modelDetails.modelDetails
        const items = [emptyEngineCode, ...engineCodes]

        if (engineCodes.length === 0) {
            return <Typography variant="body2">{vehicle.engineCode || emptyEngineCode}</Typography>
        }

        const className = `engine-codes ${!vehicle.engineCode ? "not-selected" : ""}`

        return (
            <div className={className}>
                <StyledSelect size="small" variant="outlined" value={vehicle.engineCode ?? emptyEngineCode} onChange={handleSelectEngineCode}>
                    {items.map((x) => (
                        <SelectItem value={x} key={x}>
                            {x === emptyEngineCode ? <>&ndash;</> : x}
                        </SelectItem>
                    ))}
                </StyledSelect>
            </div>
        )
    }

    function handleSelectHistoryItem(id: string) {
        attachToWorkTask && attachToWorkTask({ vehicle: id })
    }

    function handleSwitchVehicleClick() {
        setShowCurrentVehicleContent(false)
    }

    function renderButtons(htmlId: string) {
        if (switchVehicleButtonAction === "showSearchContent") {
            return (
                <>
                    {!hideChangeButton && (
                        <MuiButton onClick={handleSwitchVehicleClick} id={htmlId}>
                            {translate(347)}
                        </MuiButton>
                    )}
                    <VehicleHistory
                        vehicle={{ description: "", id: workTask?.vehicle?.id ?? "", plateId: "" }}
                        vehicleHistory={workTask?.vehicleHistory ?? []}
                        handleSelect={(v: string) => handleSelectHistoryItem(v)}
                        userContext={userContext}
                        displayCurrentVehicle={false}
                    />
                </>
            )
        }

        return (
            <>
                {!hideChangeButton && (
                    <LinkButton
                        onClick={() => TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarChange)}
                        to={getVehicleIdentificationUrl(matchParams, { vehicleType })}
                        id={htmlId}
                    >
                        {translate(347)}
                    </LinkButton>
                )}
                <VehicleHistory
                    vehicle={{ description: "", id: workTask?.vehicle?.id ?? "", plateId: "" }}
                    vehicleHistory={workTask?.vehicleHistory ?? []}
                    handleSelect={(v: string) => handleSelectHistoryItem(v)}
                    userContext={userContext}
                    displayCurrentVehicle={false}
                />
            </>
        )
    }

    function getFeedbackButtonText() {
        const { isElekat, enableMVCFeedback } = getBundleParams()
        let feedbackButtonText = translateText(13039)

        if (isElekat) {
            feedbackButtonText = translateText(13128)
        }

        if (enableMVCFeedback) {
            feedbackButtonText = translateText(41)
        }

        return feedbackButtonText
    }

    const handleToggleVehicleEditorAndSendTma = () => {
        handleToggleVehicleEditor("plateId", true)
        TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarEdit)
    }

    function renderFooter() {
        if (vehicle && showCurrentVehicleContent) {
            const { id, vehicleType } = vehicle
            const { nextLight, vehicleFeedbackPath } = getBundleParams()
            const htmlId = "vehicle__widget__switch-vehicle-button"
            return hideFooterButtons ? undefined : (
                <>
                    <Stack spacing={0.5} direction="row">
                        <LinkButton
                            onClick={() => TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarDetails)}
                            to={getVehicleDetailsUrl(matchParams, { vehicleType, vehicleId: encodeUniqueId(id) })}
                            id="vehicle__widget__vehicle-details-button"
                        >
                            {translate(116)}
                        </LinkButton>
                        {!nextLight && renderButtons(htmlId)}
                        {!alternativeView && (
                            <MuiButton onClick={handleToggleVehicleEditorAndSendTma} id={htmlId}>
                                {translateText(122)}
                            </MuiButton>
                        )}
                        {vehicleFeedbackPath && feedbackModule && (
                            <MuiButton startIcon={<Icon name="feedback" />} onClick={handleShowArticleFeedback} id={htmlId}>
                                {getFeedbackButtonText()}
                            </MuiButton>
                        )}

                        {hasMemoInfo && (
                            <StyledButtonIcon
                                onClick={handleOpenMemoInfo}
                                size="small"
                                startIcon={<StyledIcon name="atu-logo-filled" />}
                                id="vehicle__widget__vehicle-memo-info-button"
                            >
                                {translateText(465)}
                            </StyledButtonIcon>
                        )}
                    </Stack>
                    <VehicleDataProgress
                        vehicle={vehicle}
                        onClick={showPartsIndicator}
                        hasEngineCodes={(modelDetails?.modelDetails?.engineCodes || []).length > 0}
                    />
                </>
            )
        }

        return (
            <>
                <div className="fav-manufactureres-subtitle subtitle subtitle--xs">{translateText(1078)}</div>
                <div className="manufacturers">
                    <TopManufacturerCards vehicleType={vehicleType} onSelect={handleTopManufacturerSelect} />
                    <Button linkTo={getVehicleIdentificationUrl(matchParams, { vehicleType })} id="vehicle__widget__more-manufacturers-button">
                        {translate(869)}
                    </Button>
                </div>
            </>
        )
    }

    function renderHeader() {
        return (
            <>
                {vehicle && !showCurrentVehicleContent && (
                    <Button
                        icon="arrow-left"
                        fakeButton
                        onClick={() => setShowCurrentVehicleContent(true)}
                        className={style({
                            position: "relative",
                            marginLeft: "4px",
                        })}
                    >
                        {translate(817)}
                    </Button>
                )}
                <CountrySelectorOrFlag
                    vehicleType={vehicleType}
                    selectedVehicle={showCurrentVehicleContent ? vehicle : undefined}
                    onSelectSearchConfig={handleSearchConfigSelect}
                    selectedSearchConfig={selectedSearchConfig}
                    vehicleLookupConfigs={availableVehicleSearches?.configuredVehicleLookups}
                />
            </>
        )
    }

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    const id = "vehicle__widget"

    const newClassName = concat(
        " ",
        "vehicle-widget",
        userContext?.parameter.catalogLight && "is-disabled",
        vehicle && showCurrentVehicleContent && "has-vehicle",
        className
    )

    if (showComponentKey) {
        return (
            <div id={id} className={newClassName} style={{ height: `${height}em` }}>
                {renderHeader()}
                <div className="widget__inner">
                    <div className="widget__content">
                        {editing && <div className="card__curtain" />}
                        {vehicle && showCurrentVehicleContent ? renderVehicleContent() : renderSearchContent()}
                    </div>
                    <div className="widget__footer">{renderFooter()}</div>
                </div>
            </div>
        )
    }

    function handleSearchConfigSelect(vehicleSearchConfig: VehicleLookupConfig) {
        if (vehicle && showCurrentVehicleContent) {
            handleVehicleUpdate({
                ...vehicle,
                countryCode: vehicleSearchConfig?.countryCode,
                registrationTypeId: vehicleSearchConfig.lookupTypeId,
            })
        }
    }

    const propsWidget: WidgetProps = {
        size: size || "4x2",
        id,
        height,
        iconName: "vehiclesearch",
        title: translate(99),
        footer: renderFooter(),
        highlight: !!highlight,
        indicator: !!indicator,
        active: active || !!vehicle,
        onClick: handleWidgetClick,
        collapsible,
        className: newClassName,
        appendToHeader: renderHeader(),
    }

    return (
        <Widget {...propsWidget}>
            {editing && <div className="card__curtain" />}
            {vehicle && showCurrentVehicleContent ? renderVehicleContent() : renderSearchContent()}
        </Widget>
    )
}

// Helpers
function removeBrackets(value?: string): string | undefined {
    if (!value) {
        return
    }

    const regex = /^(.*?)(?:\(.*?\))?$/
    const match = regex.exec(value)

    if (!match) {
        return value
    }

    return match[1]
}
