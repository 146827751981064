import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Button, LicensePlateField, RealTable, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { concat, plateCountryCodeMapper } from "@tm/utils"
import * as React from "react"
import { style } from "typestyle"

import { Customers } from "../../../../business/gateway"
import { getCustomerNameAndCompany, getVehicleName } from "../../helpers"
import { getComponentStyles, Selection } from "."

type Props = {
    customers: Array<Customers.Customer>
    onCustomerSelect?(customer: Customers.Customer): void
    loading?: boolean
    label?: string
    noItemsMessage?: string
    onCustomerAttach?(customer: Customers.Customer): void
    onLoadNextCustomers?(): void
}

export const CustomerSelection: React.FC<Props> = (props) => {
    const className = style(getComponentStyles())
    const { translate } = useLocalization()
    const { plateCode } = useCountryCodeToLicensePlate()

    const renderNameAndCompany = (customer: Customers.Customer) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text>{getCustomerNameAndCompany(customer)}</Text>
            </RealTable.Cell>
        )
    }

    const renderStreet = (customer: Customers.Customer) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{customer.generalAddress?.street}</Text>
            </RealTable.Cell>
        )
    }

    const renderCity = (customer: Customers.Customer) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{customer.generalAddress && concat(" ", customer.generalAddress.zip, customer.generalAddress.city)}</Text>
            </RealTable.Cell>
        )
    }

    const renderVehicleName = (customer: Customers.Customer) => {
        const { vehicles } = customer

        return (
            <RealTable.Cell className={`${className}__cell`}>{vehicles?.[0] && <Text size="s">{getVehicleName(vehicles[0])}</Text>}</RealTable.Cell>
        )
    }

    const renderLicensePlate = (customer: Customers.Customer) => {
        const { vehicles } = customer

        return (
            <RealTable.Cell className={`${className}__cell`}>
                {vehicles?.[0]?.registrationInformation?.plateId && (
                    <LicensePlateField
                        readonly
                        value={vehicles[0].registrationInformation.plateId}
                        shortCountryCode={plateCountryCodeMapper(vehicles[0].countryCode || plateCode)}
                    />
                )}
            </RealTable.Cell>
        )
    }

    const renderActions = (customer: Customers.Customer) => {
        const attach = (e: React.MouseEvent) => {
            e.stopPropagation()
            props.onCustomerAttach?.(customer)
        }

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <div className={`${className}__actions`}>
                    {props.onCustomerAttach && (
                        <Button icon="paperclip" onClick={attach}>
                            {translate(1041)}
                        </Button>
                    )}
                </div>
            </RealTable.Cell>
        )
    }

    const getTableColumns = () => {
        return [
            <RealTable.Column key="name" renderItemContent={renderNameAndCompany} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(155)} / {translate(108)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="street" renderItemContent={renderStreet} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(111)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="city" renderItemContent={renderCity} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(112)}, {translate(113)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="vehicle" renderItemContent={renderVehicleName} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(99)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="plate" renderItemContent={renderLicensePlate} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(21)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column
                key="actions"
                renderItemContent={renderActions}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            />,
        ]
    }

    return (
        <Selection
            className={className}
            items={props.customers}
            columns={getTableColumns()}
            onSelect={props.onCustomerSelect}
            loading={props.loading}
            label={props.label}
            noItemsMessage={props.noItemsMessage}
            onLoadNextItems={props.onLoadNextCustomers}
        />
    )
}
