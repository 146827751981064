import { ajax, getStoredAuthorization } from "@tm/utils"

import { getBundleParams } from "../../utils"

function getSendEmailUrl() {
    return getBundleParams().sendEmailUrl
}

export function sendEmail(emailRecipient: string, mailBody: string, subject?: string) {
    return ajax<Array<string>>({
        method: "POST",
        url: `${getSendEmailUrl()}/SendMail`,
        authorization: getStoredAuthorization(),
        body: {
            email: emailRecipient,
            body: mailBody,
            subject,
        },
    })
}
