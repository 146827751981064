import { styled, Typography, Icon } from "@tm/components"
import { AttributeFilterModel, RegistrationNoType } from "@tm/models"

const FilterValue = styled(Typography)(({ theme }) => ({
    fontFamily: theme.font?.fontFamily?.condensed ?? "Roboto Condensed",
    color: "unset",
}))

const DatIcon = styled(Icon)(({ theme }) => ({
    verticalAlign: "middle",
    margin: 0,
    marginLeft: theme.spacing(),
}))

const SmallDatIcon = styled(DatIcon)(({ theme }) => ({
    minWidth: "auto",
    minHeight: "auto",
    height: theme.typography.body2.fontSize,
    width: theme.typography.body2.fontSize,
}))

type FilterItemProps = {
    filter: AttributeFilterModel | undefined
    isDisplay?: boolean
}

export default function FilterItem({ filter, isDisplay }: FilterItemProps) {
    const { text, unit } = filter || { text: "", unit: undefined }

    if (!filter) {
        return null
    }

    return (
        <FilterValue variant="body2">
            {text + (unit ? ` ${unit}` : "")}
            {filter.registrationType === RegistrationNoType.DatVin &&
                (isDisplay ? <SmallDatIcon name="dat-vin-data" /> : <DatIcon name="dat-vin-data" />)}
        </FilterValue>
    )
}
