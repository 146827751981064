import { useEffect, useMemo } from "react"
import { ArticlesByQueryRequest } from "../../../../../data/model/uni-parts"
import * as PartsRepository from "../../../../../data/repositories/uni-parts"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { fetchUniversalPartsArticles } from "../../../data/fetchUniversalPartsArticles"
import { useArticlesBase } from "../../../hooks/useArticlesBase"
import { ArticleData, UniversalPartsListParams } from "../../../models"

const QUERY_KEY = "UniversalParts.useArticlesByQuery"

export function useArticlesByQuery(params: UniversalPartsListParams, isEnabled: boolean): ArticleData {
    const { productGroups, suppliers, attributes, startParams, extendedAssortment, isFiltersLoading } = params

    const { pageSize } = useArticleListConfiguration()

    const request = useMemo<ArticlesByQueryRequest | null>(() => {
        if (!isEnabled || isFiltersLoading || startParams.type !== "unisearch" || !startParams.query || !productGroups.length) {
            return null
        }

        return {
            pageSize,
            query: startParams.query,
            selectedProductGroups: productGroups,
            selectedSuppliers: suppliers,
            selectedCriteria: attributes,
            extendedAssortment,
            productGroupOrdering: productGroups.map((x) => x.id),
        }
    }, [isEnabled, isFiltersLoading, pageSize, productGroups, suppliers, startParams, attributes, extendedAssortment])

    useEffect(
        function resetNoResult() {
            params.setNoResult(false)
        },
        [request]
    )

    return useArticlesBase(request, (context) => fetchUniversalPartsArticles(PartsRepository.getArticlesByQuery, context), QUERY_KEY)
}
