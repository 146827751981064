import { sub } from "date-fns"
import { useLocalization } from "@tm/localization"
import { DateRange } from "@tm/components"
import { useRecoilState } from "recoil"
import { DISPLAY_RANGE_IN_DAYS, voucherListFilterAtom } from "../../../../../data/states/listFilters"

type Props = {
    workTaskId?: string
    onRedirectToVoucherList(): void
}

export default function DateFilters({ workTaskId, onRedirectToVoucherList }: Props) {
    const { translateText, language } = useLocalization()
    const [listFilters, setListFilter] = useRecoilState(voucherListFilterAtom(workTaskId || ""))

    const onRangeChange = (startDate: Date, endDate: Date) => {
        setListFilter((prev) => ({
            ...prev,
            startDate,
            endDate,
        }))
        onRedirectToVoucherList()
    }

    const initialStartDate = sub(listFilters.endDate, { days: DISPLAY_RANGE_IN_DAYS })

    return (
        <DateRange
            initialDate={{ startDate: initialStartDate, endDate: listFilters.endDate }}
            localisation={{ locale: language, region: language == "en" ? "GB" : "" }}
            rangeInDays={DISPLAY_RANGE_IN_DAYS}
            onRangeChange={onRangeChange}
            startLabel={translateText(13161)}
            endLabel={translateText(13162)}
            size="extralarge"
        />
    )
}
