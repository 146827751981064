import { useLocalization } from "@tm/localization"
import { DispatchCostsType, ErpIcon, ErpInformation, Memo, PriceType, SpecialProcurementErpInformation } from "@tm/models"
import { memo, useMemo } from "react"
import { style } from "typestyle"
import MemoTooltipIcon from "../../../../_shared/memo-tooltip-icon"
import SpecialIcons from "../../../../_shared/special-icons"
import { getPriceFromWarehouses } from "../../../../business"
import { getBundleParams } from "../../../../utils"
import { parseUrlToGetFileNameWithoutPrefix } from "../../../../utils/url-parser"

type Props = {
    erpInfo: ErpInformation | undefined
    specialProcurementErpInformation?: SpecialProcurementErpInformation
    onStgSafetyDataSheetClick?(): void
    onSpecialIconClick?(iconName: string): void

    additionalSpecialIcons?: Array<ErpIcon>
    additionalMemos?: Array<Memo>
}

const specialIconsClassName = style({ cursor: "pointer" })

const Icons = memo<Props>(
    ({ erpInfo, onStgSafetyDataSheetClick, onSpecialIconClick, additionalSpecialIcons, additionalMemos, specialProcurementErpInformation }) => {
        const { translateText, currency } = useLocalization()

        const icons = useMemo(() => {
            const specialIcons: Array<ErpIcon & { onClick?(): void }> =
                erpInfo?.specialIcons?.map((icon) => {
                    if (onSpecialIconClick) {
                        try {
                            return {
                                ...icon,
                                onClick: () => onSpecialIconClick(parseUrlToGetFileNameWithoutPrefix(icon?.url || "")),
                            }
                        } catch (_) {
                            return icon
                        }
                    }

                    return icon
                }) || []

            if (getBundleParams().showProcurementPriceAsIcon) {
                const price =
                    erpInfo?.prices?.find((p) => p?.type === PriceType.ProcurementCosts) ||
                    getPriceFromWarehouses(PriceType.ProcurementCosts, erpInfo?.warehouses) ||
                    specialProcurementErpInformation?.orderOptions?.shipmentModes?.shipmentModes?.find(
                        (c) => c.costs?.type === DispatchCostsType.Express && c.costs.value > 0
                    )?.costs
                if (price) {
                    specialIcons.push({
                        topmotiveId: "purchasing-costs",
                        useIconFromUrl: false,
                        url: "",
                        description: `${translateText(1576)} ${currency(price.value, price.currencySymbol)}`,
                    })
                }
            }

            if (erpInfo && erpInfo.showSafetyDataSheets) {
                specialIcons.push({
                    topmotiveId: "attention-dark",
                    useIconFromUrl: false,
                    url: "",
                    description: translateText(1616),
                    onClick: onStgSafetyDataSheetClick,
                })
            }

            if (erpInfo?.isPromotional) {
                specialIcons.push({
                    topmotiveId: "sale",
                    useIconFromUrl: false,
                    url: "",
                    description: erpInfo.promoText,
                })
            }

            if (additionalSpecialIcons?.length) {
                specialIcons.push(...additionalSpecialIcons)
            }

            return specialIcons
        }, [erpInfo, additionalSpecialIcons, onSpecialIconClick, translateText, currency, onStgSafetyDataSheetClick])

        const memos = useMemo(() => {
            if (erpInfo?.memos?.length && additionalMemos?.length) {
                return [...erpInfo.memos, ...additionalMemos]
            }
            if (erpInfo?.memos?.length) {
                return erpInfo.memos
            }
            return additionalMemos
        }, [erpInfo?.memos, additionalMemos])

        return (
            <>
                <SpecialIcons className={specialIconsClassName} icons={icons} />
                <MemoTooltipIcon memos={memos} />
            </>
        )
    }
)

export default Icons
