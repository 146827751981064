import { IBundle } from "@tm/morpheus"

import FastCalcMain from "./components/main"
import Widget from "./components/widget"
import { initFastCalculatorBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    // stylesUrl: "~/styles.css",
    components: [Widget, FastCalcMain],
    init: initFastCalculatorBundle,
}

export default bundle
