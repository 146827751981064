import { useCallback, useEffect, useMemo } from "react"
import { atom, useRecoilState } from "recoil"
import { VehicleLookupConfig, VehicleType } from "@tm/models"
import { useAvailableVehicleSearchOptions } from "@tm/utils"

const STARTPAGE = "startpage"

type SelectedLookupType = {
    lookupTypeId?: number
    countryCode?: string
}

const EmptyLookupType = {
    lookupTypeId: undefined,
    countryCode: undefined,
}

const defaultLookupType = atom<SelectedLookupType>({
    key: "lookupType",
    default: EmptyLookupType,
})

export const preselectedLookupTypeAtom = atom<SelectedLookupType | undefined>({
    key: "vehicle__preselectedLookupType",
    default: undefined,
})

export function useSelectedVehicleLookup(vehicleType: VehicleType): {
    selectedVehicleLookup: SelectedLookupType
    setSelectedVehicleLookup(selectedLookupType: SelectedLookupType): void
    selectableWithoutDuplicates: Array<VehicleLookupConfig>
} {
    // eslint-disable-next-line no-underscore-dangle
    const workTaskId = window.__NEXT_WORKTASKID__ || STARTPAGE
    const [preselectedLookupType, setPreselectedLookupType] = useRecoilState(preselectedLookupTypeAtom)
    const [selectedLookupType, setSelectedLookupType] = useRecoilState(defaultLookupType) // maybe use atom family for worktasks

    const { sessionStorageData, setSessionStorageData } = useSessionStorage<{ [workTaskId: string]: SelectedLookupType }>("useSelectedVehicleLookup")
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()

    const { defaultVehicleLookup } = availableVehicleSearches ?? {}
    const defaultLookup: SelectedLookupType = defaultVehicleLookup?.supportedVehicleTypes.includes(vehicleType)
        ? { lookupTypeId: defaultVehicleLookup.lookupTypeId, countryCode: defaultVehicleLookup?.countryCode }
        : {}

    useEffect(() => {
        const storedLookupType =
            (workTaskId !== STARTPAGE || selectedLookupType.lookupTypeId === undefined) && !!sessionStorageData?.[workTaskId]
                ? sessionStorageData[workTaskId]
                : selectedLookupType

        // previous lookupselection from startpage if no lookuptype is bound to the worktask id
        const startpageValue = sessionStorageData?.[STARTPAGE]
        if (startpageValue && storedLookupType.lookupTypeId === undefined) {
            // setze aktuellen wert von der startpage auf den aktuellen worktask und schreibe den default, wenn vorhanden, für die startseite
            setSessionStorageData({
                ...sessionStorageData,
                [workTaskId]: startpageValue,
                [STARTPAGE]: defaultLookup ?? preselectedLookupType,
            })
            setSelectedLookupType(startpageValue)

            return
        }

        // new worktask doesnt have any data in sessionStorage, reset values
        const currentSessionStorageData = sessionStorageData?.[workTaskId] ?? EmptyLookupType
        if (sessionStorageData && !sessionStorageData[workTaskId]) {
            setSelectedLookupType(storedLookupType)
            setSessionStorageData({
                ...sessionStorageData,
                [workTaskId]: storedLookupType,
                [STARTPAGE]: defaultLookup ?? preselectedLookupType,
            })

            return
        }

        // switchted worktask, has a different sessionStorage as selectedLokkupType from recoil, take value from sessionstorage
        if (
            currentSessionStorageData.countryCode !== selectedLookupType.countryCode &&
            currentSessionStorageData.lookupTypeId !== selectedLookupType.lookupTypeId
        ) {
            setSelectedLookupType(currentSessionStorageData.lookupTypeId === undefined ? EmptyLookupType : currentSessionStorageData)
        }

        // still no lookuptype selected, use the default one
        if (
            defaultLookup.countryCode !== undefined &&
            defaultLookup.lookupTypeId !== undefined &&
            selectedLookupType.countryCode === undefined &&
            selectedLookupType.lookupTypeId === undefined &&
            selectedLookupType.countryCode !== defaultLookup.countryCode &&
            selectedLookupType.lookupTypeId !== defaultLookup.lookupTypeId
        ) {
            const lookup = preselectedLookupType ?? defaultLookup
            setSelectedLookupType(lookup)
            setSessionStorageData({
                ...sessionStorageData,
                [workTaskId]: lookup,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLookupType, workTaskId, defaultLookup, setSessionStorageData, setSelectedLookupType])

    const selectableWithoutDuplicates = useMemo(() => {
        const alreadyAddedCountries: string[] = []

        return (
            availableVehicleSearches?.configuredVehicleLookups.filter((lookup) => {
                if (!lookup.isSelectable || !lookup.countryCode || !lookup.supportedVehicleTypes.includes(vehicleType)) {
                    return false
                }

                if (lookup.isUsedInDefaultSearch) {
                    alreadyAddedCountries.push(lookup.countryCode)
                    return true
                }

                if (!alreadyAddedCountries.includes(lookup.countryCode)) {
                    alreadyAddedCountries.push(lookup.countryCode)
                    return true
                }

                return false
            }) ?? []
        )
    }, [availableVehicleSearches, vehicleType])

    const setSelectedVehicleLookup = useCallback(
        (lookupType: SelectedLookupType) => {
            setPreselectedLookupType(lookupType)
            setSelectedLookupType(lookupType)

            const storedLookupType = sessionStorageData?.[workTaskId]
            if (
                !storedLookupType ||
                lookupType.lookupTypeId !== storedLookupType.lookupTypeId ||
                lookupType.countryCode !== storedLookupType.countryCode
            ) {
                setSessionStorageData({
                    ...sessionStorageData,
                    [workTaskId]: lookupType,
                })
            }
        },
        [sessionStorageData, setPreselectedLookupType, setSelectedLookupType, setSessionStorageData, workTaskId]
    )

    return {
        selectedVehicleLookup: selectableWithoutDuplicates.length <= 1 ? EmptyLookupType : selectedLookupType,
        selectableWithoutDuplicates,
        setSelectedVehicleLookup,
    }
}

/**
 * Store data in the sessionstorage
 * @param key sessionStorage[key] location where the data is stored
 * @param data [T] data will be stringified
 * @returns T
 */
export function useSessionStorage<T>(key: string, data?: T): { sessionStorageData: T | undefined; setSessionStorageData(data: T): void } {
    function setSessionStorageData(storageData: T) {
        storageData && sessionStorage.setItem(key, JSON.stringify(storageData))
    }

    if (!sessionStorage[key] && data) {
        setSessionStorageData(data)
    }

    const sessionStorageData: T | undefined = sessionStorage[key] ? JSON.parse(sessionStorage[key]) : undefined

    return { sessionStorageData, setSessionStorageData }
}
