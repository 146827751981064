import { Dialog } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import {
    ActivatableModule,
    ActivateModuleProps,
    ActivateModuleRef,
    channel,
    ModuleGroupId,
    ModuleOption,
    ModuleOptionType,
    ModulePackage,
    SessionStorageKeys,
    SetModuleActivationRequest,
} from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, getModuleGroupTextId, renderRoute, useActivateModule } from "@tm/utils"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { StyledDialog, StyledInstantDialog } from "./styledComponents"

const ActivateModule = forwardRef<ActivateModuleRef, ActivateModuleProps>(({ routes, demoActivationInstanstly, workTaskId }, ref) => {
    const { translateText } = useLocalization()
    const [activateDemoModuleRequest, setActivateDemoModuleRequest] = useState<SetModuleActivationRequest>()
    const [moduleRoute, setModuleRoute] = useState<string>()

    const { activateModule } = useActivateModule()

    const dialogRef = useRef<Dialog>(null)
    const instantDialogRef = useRef<Dialog>(null)

    useImperativeHandle(ref, () => ({
        handleActivateModule,
    }))

    function removeMvcSessions() {
        Object.keys(sessionStorage)
            .filter((x) => x.indexOf(SessionStorageKeys.mvcSessionId) > -1 || x.indexOf(SessionStorageKeys.invalidSessionMvc) > -1)
            .forEach((x) => sessionStorage.removeItem(x))
    }

    function handleActivateModule(
        moduleGroupId: ModuleGroupId,
        module: ActivatableModule,
        moduleOption?: ModuleOption,
        modulePackage?: ModulePackage
    ) {
        if (!moduleOption?.activatable) {
            return
        }

        if (routes) {
            const route = routes?.[module.id.toLowerCase()]
            setModuleRoute(route)
        }

        const request: SetModuleActivationRequest = {
            moduleGroupId,
            moduleId: module.id,
            externalModuleId: module.externalId,
            vehicleType: module.vehicleType,
            moduleOption,
            modulePackage,
        }

        switch (moduleOption.type) {
            case ModuleOptionType.Demo: {
                if (!demoActivationInstanstly) {
                    setActivateDemoModuleRequest(request)
                    dialogRef.current?.show()
                    return
                }

                activateModule(request).then(() => {
                    removeMvcSessions()
                    instantDialogRef.current?.show()
                })

                break
            }
            case ModuleOptionType.Full: {
                const moduleGroupTextId = getModuleGroupTextId(moduleGroupId, translateText)
                const fullRequestMessage = module.externalId ? module.name : `${moduleGroupTextId} ${module.id}`

                activateModule(request)
                    .then(() => {
                        channel("APP").publish("TOAST_MESSAGE/SHOW", {
                            message: translateText(12792).replace("{0}", fullRequestMessage),
                            skin: "success",
                        })
                    })
                    .catch((ex) => {
                        console.log("activateModule error", ex)
                        channel("APP").publish("TOAST_MESSAGE/SHOW", {
                            message: translateText(12821),
                            closeDelay: 5000,
                            skin: "danger",
                        })
                    })

                break
            }
            default:
                break
        }
    }

    function handleConfirmLeave() {
        if (!activateDemoModuleRequest) {
            return
        }

        activateModule(activateDemoModuleRequest)
            .then(() => {
                if (moduleRoute && workTaskId) {
                    removeMvcSessions()
                    location.href = renderRoute(moduleRoute, { workTaskId: encodeUniqueId(workTaskId) })
                    return
                }

                Morpheus.closeView("1")
                removeMvcSessions()
                setTimeout(() => location.reload(), 20)
            })
            .catch((ex) => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(12821),
                    closeDelay: 5000,
                    skin: "danger",
                })
            })
    }

    return (
        <>
            <StyledDialog
                skin="warning"
                ref={dialogRef}
                preText={translateText(12817)}
                text={translateText(12818)}
                confirmButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                iconName="warning-dark"
                onConfirm={handleConfirmLeave}
            />
            <StyledInstantDialog skin="warning" ref={instantDialogRef} text={translateText(12835)} iconName="warning-dark" />
        </>
    )
})

export default ActivateModule
