import { useUser, useWorkTask } from "@tm/context-distribution"
import { Badge, Icon, Image, Tooltip } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { concat, encodeUniqueId, renderRoute, uniqueId, validateDmsApiUrl } from "@tm/utils"
import { useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"

import { getBundleParams } from "../../utils"
import { Settings } from "../../business/gateway"
import { connectionChannel } from "../../business/messaging"
import { useDmsInfo, useDmsPermissions } from "../hooks"
import { getComponentStyles } from "./styles"

export default function StatusButton() {
    const className = useMemo(() => getComponentStyles(), [])
    const params = useParams()
    const { translateText } = useLocalization()
    const { apiUrl } = useUser().userSettings?.dmsSettings ?? {}

    const { workTaskId } = useWorkTask() ?? {}

    const { permittedOperations } = useDmsPermissions()
    const { name: dmsName, icon: dmsIcon, version: dmsVersion } = useDmsInfo()

    const [connectionStatus, setConnectionStatus] = useState<Settings.ShowConnectionStatusResponse | undefined>(
        () => connectionChannel().last(1, "CONNECTION_STATUS_RECEIVED")[0]?.content
    )

    useEffect(() => {
        return connectionChannel().subscribe(
            "CONNECTION_STATUS_RECEIVED",
            (response) => {
                setConnectionStatus(response)
            },
            true
        )
    }, [connectionStatus?.isConnected])

    function getTooltip() {
        if (!apiUrl) {
            return dmsName
        }

        let tooltip

        if (connectionStatus?.isConnected) {
            tooltip = translateText(1980).replace("{0}", dmsName)
        } else {
            tooltip = concat(
                "\n\n",
                translateText(1981).replace("{0}", dmsName),
                connectionStatus?.error && `${translateText(371)}: ${connectionStatus.error}`,
                !validateDmsApiUrl(apiUrl)
                    ? translateText(13407)
                    : apiUrl.toLowerCase().startsWith("https") && `${translateText(1982).replace("{0}", dmsName)}`
            )
        }

        return concat("\n\n", tooltip, dmsVersion && `${translateText(2001)}: ${dmsVersion}`)
    }

    function getSettingsUrl() {
        return renderRoute(getBundleParams().settingsModuleUrl, params)
    }

    function getModuleUrl() {
        return renderRoute(getBundleParams().dmsModuleUrl, {
            ...params,
            workTaskId: encodeUniqueId(workTaskId ?? uniqueId()),
        })
    }

    function renderIcon() {
        const isUrl = dmsIcon.includes(".") // No icons with a dot in name supported - does these exist?

        if (isUrl) {
            return <Image url={dmsIcon} className={`${className}__icon icon icon--xl`} />
        }

        return <Icon name={dmsIcon} size="xl" className={`${className}__icon`} />
    }

    return (
        <Tooltip content={getTooltip()} className={`${className}__tooltip`}>
            <Link
                to={
                    !permittedOperations.includes("findCustomers") && !permittedOperations.includes("findVouchers")
                        ? getSettingsUrl()
                        : getModuleUrl()
                }
                className={`global-navigation tab tab--worktask tab--center ${className}__button`}
            >
                <div className="tab__content">{renderIcon()}</div>

                {!!apiUrl && <Badge value=" " skin={!connectionStatus?.isConnected ? "danger" : "success"} className={`${className}__badge`} />}
            </Link>
        </Tooltip>
    )
}
