import { PropsWithChildren, useCallback, useMemo, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Headline, Icon, PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { uniqueId } from "@tm/utils"
import { Camera, FileUpload, ShowPicture } from ".."
import { useFastServiceStore } from "../../../data"
import { PrintImage } from "../../../data/models"
import { getStepLocationName } from "../../../helpers"

type Props = {
    onSavePicture(img: PrintImage, replaceImage?: boolean): void
    path: string
    collapsibleName?: string
}

export default function Upload({ path, onSavePicture, collapsibleName }: Props) {
    const style = useMemo(() => getStyle(), [])

    const { translateText } = useLocalization()
    const selectedStep = useFastServiceStore((state) => state.navigation.active)
    const selectedItems = useFastServiceStore((state) => state.worksState.selectedItems)
    const selectedWork = getStepLocationName(selectedStep || "")
    const selectedImagesForPath = useFastServiceStore((s) =>
        collapsibleName
            ? s.tyresWheels.selectedTab.collapsibleItems.find((x) => x.name === collapsibleName)?.selectedImages || []
            : selectedItems[selectedWork]?.[path]?.selectedImages || []
    )
    const [tempImages, setTempImages] = useState<PrintImage[]>(selectedImagesForPath)

    const saveImage = (image: PrintImage, replaceImage?: boolean) => {
        onSavePicture(image, replaceImage)
    }

    const handleResetImage = useCallback((id: string, image: string) => {
        saveImage({ id, image })
        setTempImages((prev) =>
            prev.filter((x) => {
                return x.id !== id
            })
        )
    }, [])

    const handleImageUpload = useCallback((newImage: string) => {
        const uId = uniqueId()
        saveImage({ id: uId, image: newImage })
        setTempImages((prev) => [...prev, { id: uId, image: newImage }])
    }, [])

    const changeImage = useCallback((id: string, updatedImage: string) => {
        saveImage({ id, image: updatedImage }, true)
        setTempImages((prev) =>
            prev.map((x) => {
                if (x.id === id) {
                    return { ...x, image: updatedImage }
                }
                return x
            })
        )
    }, [])

    const renderPicture = (picture: PrintImage, idx: number) => {
        return <ShowPicture key={idx} imageId={picture.id} image={picture.image} resetImage={handleResetImage} updateImage={changeImage} />
    }

    return (
        <div className={style.wrapper}>
            <UploadPanel icon="camera" headline={translateText(12620)}>
                <Camera disabled={selectedImagesForPath.length >= 10} onImageUpload={handleImageUpload} />
            </UploadPanel>
            <div className={style.picturesWrapper}>
                {tempImages?.map(renderPicture)}
                <FileUpload disabled={selectedImagesForPath.length >= 10} onImageUpload={handleImageUpload} />
            </div>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            marginTop: theme.margin.xl,
            display: "flex",
            gap: "1%",
        },
        panel: {
            maxWidth: "35rem",
            minHeight: "25rem",
            maxHeight: "25rem",
            $nest: {
                ".panel__content": {
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                },
            },
        },
        panelHeaderIcon: {
            marginRight: theme.margin.m,
        },
        panelHeader: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.margin.xl,
        },
        panelContent: {
            flex: 1,
            display: "flex",
        },
        upLoadPic: {
            position: "relative",
        },
        picturesWrapper: {
            display: "flex",
            gap: "1%",
            flexWrap: "wrap",
            alignItems: "flex-start",
        },
    })(Upload)
}

function UploadPanel({ icon, headline, children }: PropsWithChildren<{ icon: string; headline: string }>) {
    const style = useMemo(() => getStyle(), [])

    return (
        <PanelSection className={style.panel}>
            <div className={style.panelHeader}>
                <Icon name={icon} className={style.panelHeaderIcon} size="l" />
                <Headline size="xs">{headline}</Headline>
            </div>
            <div className={style.panelContent}>{children}</div>
        </PanelSection>
    )
}
