import { Suspense } from "react"
import { WorkTaskInfo, useTelesalesCustomerNumber, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import Morpheus, { connectComponent } from "@tm/morpheus"
import {
    ModulePropsMultiWidget,
    renderRoute,
    showWarehouseDataMissingError,
    useDefaultErpSystem,
    useDefaultOrderWarehouse,
    useMultiWidgetState,
} from "@tm/utils"

import { useBasketMemo } from "../../hooks/useBasketMemo"
import { Actions, AddingToolsState } from "./business"
import DirectBuy from "./components/direct-buy"
import { getBundleParams } from "../../utils"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"

type Props = ConfigProps & {
    state: AddingToolsState
    actions: typeof Actions
}

type ConfigProps = {
    useTraderAndSupplierFilter?: boolean
    showComponentKey?: string
    showHints?: boolean
    moduleProps?: ModulePropsMultiWidget
}

function AddingToolsComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { showComponentKey, state, actions, showHints, useTraderAndSupplierFilter, workTask } = props
    const { partsDetailsModalUrl } = getBundleParams()

    const { translateText } = useLocalization()
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { erpSystemConfig } = useDefaultErpSystem()
    const { warehouseData, refetchWarehouseData } = useDefaultOrderWarehouse(
        { telesalesCustomerNo, distributorId: erpSystemConfig?.id },
        enableServiceCalls
    )
    const { basket } = useWorkTaskBasketState(workTask.id)
    const [selectedMultiwidgtTab] = useMultiWidgetState(props.moduleProps)
    const basketMemo = useBasketMemo(workTask, 1 /** DIRECT search */)

    function handleOpenArticleDetailsPartsList(productGroupId?: number, supplierArticleNumber?: string, supplierId?: number) {
        const detailUrlParams = {
            workTaskId: workTask.id,
            productGroupId,
            supplierId,
            supplierArticleNo: supplierArticleNumber ? encodeURIComponent(supplierArticleNumber) : undefined,
            partsDetailsSubPage: "partslist",
        }
        Morpheus.showView("1", renderRoute(partsDetailsModalUrl, detailUrlParams))
    }

    const addArticleToBasket = (wholesalerArticleNo: string, quantity: number, useEqualArticlesFilter: boolean, eanNumberSearch: boolean) => {
        if (!warehouseData || warehouseData.hasErrors) {
            showWarehouseDataMissingError(translateText)
            refetchWarehouseData()
            return
        }

        let action = actions.addArticleFromTraderToBasket

        if (useTraderAndSupplierFilter) {
            action = actions.addArticleFromTraderAndSupplierToBasket
        }

        action(
            wholesalerArticleNo,
            quantity,
            workTask.id,
            `${wholesalerArticleNo} ${translateText(325)}`,
            translateText(1216),
            useEqualArticlesFilter,
            eanNumberSearch,
            basketMemo.position,
            workTask?.vehicle?.id,
            workTask?.customer?.id,
            warehouseData.defaultWarehouse,
            erpSystemConfig?.id,
            erpSystemConfig?.description,
            basket.actions.addCatalogParts
        )
    }

    if (showComponentKey && selectedMultiwidgtTab !== showComponentKey) {
        return null
    }

    return (
        <DirectBuy
            {...state.directBuyState}
            showHints={showHints}
            addArticleToBasket={addArticleToBasket}
            disableInputs={basket.state.orderSending}
            onCloseSelectionModal={actions.selectionModalClosed}
            onOpenArticlePartsList={handleOpenArticleDetailsPartsList}
        />
    )
}

function Wrapper(props: Props) {
    const workTask = useWorkTask()?.workTask
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <AddingToolsComponent {...props} workTask={workTask} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
