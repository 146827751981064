import { useLocalization } from "@tm/localization"
import { Tooltip } from "@tm/controls"
import { CisVoucherType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { Box, Stack, Button, Icon, Grid } from "@tm/components"
import { OrderDetails } from "../../../../../data/model"
import { OrderDetailsField } from "../../../../_shared/OrderDetailsField"
import { OrderDetailsWarehouseField } from "../../../../_shared/OrderDetailsWarehouseField"

type Props = {
    orderDetails: OrderDetails
    onPrintButtonClick(orderNumber: string, orderSubNumber?: string, voucherId?: string, warehouseId?: string, voucherTypeId?: CisVoucherType): void
}

export default function StgOrderDetailsHeaderComponent({ orderDetails, onPrintButtonClick }: Props) {
    const {
        orderNumber,
        orderSubNumber,
        voucherId,
        warehouseId,
        orderDate,
        deliveryType,
        orderTypeDescription,
        deliveryNote,
        customerOrderNumber,
        invoice,
        warehouseName1,
        warehouseStreet,
        warehousePostalCode,
        warehouseCity,
        orderTexts,
        orderValueNet,
        currencyCode,
        orderValueTotal,
        vatOldPartValue,
    } = orderDetails
    const { date, currency, translateText } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false

    function handlePrintButtonClick() {
        if (orderNumber) {
            onPrintButtonClick(orderNumber, orderSubNumber, voucherId, warehouseId)
        }
    }

    return (
        <Stack direction="column" alignItems="flex-end">
            <Box>
                <Button startIcon={<Icon name="print" />} color="primary" onClick={handlePrintButtonClick} title={translateText(13164)} />
            </Box>
            <Grid container rowSpacing={1} columnSpacing={2} my={1} columns={4}>
                {/* First Row */}
                <Grid item xs={1}>
                    <OrderDetailsField value={orderNumber + (orderSubNumber ? ` - ${orderSubNumber}` : "")} label={translateText(700)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={orderTypeDescription} label={translateText(777)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={deliveryType} label={translateText(40)} />
                </Grid>
                <Grid item xs={1}>
                    {!hidePurchasePrice && <OrderDetailsField value={currency(orderValueNet, currencyCode)} label={translateText(474)} />}
                </Grid>
                {/* Second Row */}
                <Grid item xs={1}>
                    <OrderDetailsField
                        value={orderDate && `${date(orderDate, "d")} - ${date(orderDate, "t")} ${translateText(12808)}`}
                        label={translateText(776)}
                    />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={deliveryNote} label={translateText(778)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={invoice} label={translateText(779)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={vatOldPartValue} label={translateText(12810)} />
                </Grid>
                {/* Third Row */}
                <Grid item xs={1}>
                    <OrderDetailsWarehouseField
                        warehouseName={warehouseName1}
                        warehouseStreet={warehouseStreet}
                        warehousePostalCode={warehousePostalCode}
                        warehouseCity={warehouseCity}
                    />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={customerOrderNumber} label={translateText(1249)} />
                </Grid>
                <Grid item xs={1}>
                    {orderTexts && orderTexts[0].length > 40 ? (
                        <Tooltip content={orderTexts[0]}>
                            <OrderDetailsField value={orderTexts && orderTexts[0]} label={translateText(178)} />
                        </Tooltip>
                    ) : (
                        <OrderDetailsField value={orderTexts && orderTexts[0]} label={translateText(178)} />
                    )}
                </Grid>
                <Grid item xs={1}>
                    {!hidePurchasePrice && <OrderDetailsField value={currency(orderValueTotal, currencyCode)} label={translateText(472)} />}
                </Grid>
            </Grid>
        </Stack>
    )
}
