import { styled, Typography, Accordion, MuiAccordionSummary, AccordionDetails, Box, Button, Icon } from "@tm/components"
import { XorSwitch } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useEffect, useMemo, useState } from "react"
import { GeneralData, TiresAndWheelsDefekt } from "../../_shared"
import CollapsibleContent from "./collapsibleContent"
import { tyresWheelsActions, useFastServiceStore } from "../../../data"
import { CollapsibleData, CollapsibleInfo, WheelsAndTyresTab } from "../../../data/state/tyres-wheels"

type Props = {
    tab: WheelsAndTyresTab
    showBonus: boolean
}

export default function WheelCheckTab({ tab, showBonus }: Props) {
    const { translateText } = useLocalization()

    const { selectedFilters, name } = useFastServiceStore((state) => state.tyresWheels.selectedTab)
    const spareWheel = useFastServiceStore((state) => state.tyresWheels.spareWheel)
    const tabs = useFastServiceStore((state) => state.tyresWheels.tabs)
    const completedTabsCount: number = tabs.filter((x) => x.isComplete === true).length
    const isBrakeSystemNext: boolean = completedTabsCount === tabs.length - 1

    const [showDefektModal, setShowDefektModal] = useState<boolean>(false)
    const [showGeneralModal, setShowGeneralModal] = useState(false)
    const [brakeSystem, setBrakeSystem] = useState(false)
    const [selectedCollapsible, setSelectedCollapsible] = useState("")
    const completedAmount = useMemo(() => tab.collapsibleItems.filter((x) => x.completed).length, [tab.collapsibleItems])

    useEffect(() => {
        // select the next item in list and if at the end of the list select the first available
        const index = tab.collapsibleItems.findIndex((x) => x.name === selectedCollapsible && x.completed)
        if (index !== -1 && index + 1 < tab.collapsibleItems.length && !tab.collapsibleItems[index + 1].completed) {
            setSelectedCollapsible(tab.collapsibleItems[index + 1].name)
        } else if (tab.collapsibleItems.some((x) => !x.completed) && tab.collapsibleItems.some((x) => x.completed)) {
            setSelectedCollapsible(tab.collapsibleItems.find((x) => !x.completed)!.name)
        }
    }, [completedAmount, brakeSystem])

    useEffect(() => {
        if (selectedCollapsible === "general") {
            setShowGeneralModal(true)
        }

        if (isBrakeSystemNext && !tab.isComplete) {
            handleBrakeSystemCollapsible(spareWheel ? "left" : "right")
        }
    }, [selectedCollapsible, isBrakeSystemNext])

    const handleShowDefektModal = () => {
        setShowDefektModal(true)
    }

    const handleHideDefektModal = () => {
        setShowDefektModal(false)
    }

    const updateSpareWheel = () => {
        tyresWheelsActions.updateSpareWheel(!spareWheel)
        tyresWheelsActions.completeCollapsible(tab.name, tab.type, tab.collapsibleItems.first?.name)
    }

    const handleCollapsibleClick = (collapsibleName: string) => {
        setSelectedCollapsible(collapsibleName === selectedCollapsible ? "" : collapsibleName)
    }

    const handleCompleteCollapsible = (collapsibleName: string) => {
        tyresWheelsActions.completeCollapsible(tab.name, tab.type, collapsibleName)
        if (collapsibleName === "general") {
            setShowGeneralModal(false)
        }
    }

    const handleCloseGeneralModal = () => {
        setShowGeneralModal(false)
        // un-select first collapsible
        setSelectedCollapsible("")
    }

    const handleSizeBuild = () => {
        return `${selectedFilters.width}/${selectedFilters.height} R${selectedFilters.inch} ${selectedFilters.loadIndex}${selectedFilters.speedIndex}`
    }

    const renderButtonWrapper = (collapsibleName: string, info: CollapsibleData) => {
        const handleClick = () => {
            handleCompleteCollapsible(collapsibleName)
        }

        let disabled = true

        if (collapsibleName === "tyreDot") {
            disabled = !(!!info.profileDepth && !!info.dot)
        } else {
            disabled = !Object.values(info).length
        }

        if (collapsibleName === "brakeSystem") {
            disabled = !info.brakeSystem
        }

        return (
            <ButtonWrapper>
                <Button size="large" onClick={handleShowDefektModal}>
                    {translateText(12950)}
                </Button>
                <PaddingButton disabled={disabled} size="large" onClick={handleClick} color="success" startIcon={<Icon name="check" />} />
            </ButtonWrapper>
        )
    }

    const renderAppendix = (collapsibleName: string) => {
        if (collapsibleName === "general") {
            return (
                <Typography className="appendix">
                    {selectedFilters.manufacturer &&
                        `${selectedFilters.size.length > 4 ? selectedFilters.size : handleSizeBuild()} | ${selectedFilters.manufacturer ?? ""} | ${
                            selectedFilters.season ?? ""
                        }`}
                </Typography>
            )
        }
    }

    const renderCollapsible = (data: CollapsibleInfo, idx: number) => (
        <CustomAccordion expanded={selectedCollapsible === data.name} className={data.name} key={idx}>
            <AccordionSummaryStyled onClick={() => handleCollapsibleClick(data.name)} name={data.name}>
                {data.completed ? <Icon name="check" /> : <div className="notCheckedInsidePoint" />}
                <Typography>{translateText(data.translateTextId)}</Typography>
                {renderAppendix(data.name)}
            </AccordionSummaryStyled>
            <AccordionContent>
                <CenteredWrapper>
                    <CenteredItems>
                        <MainContent>
                            <CollapsibleContent
                                key={idx}
                                collapsibleName={data.name}
                                tabName={name}
                                info={data.infoData}
                                isExpanded={selectedCollapsible === data.name}
                                handleCompleteCollapsible={handleCompleteCollapsible}
                            />
                        </MainContent>
                    </CenteredItems>
                    {renderButtonWrapper(data.name, data.infoData)}
                </CenteredWrapper>
            </AccordionContent>
        </CustomAccordion>
    )

    const handleBrakeSystemCollapsible = (position: "left" | "right") => {
        const isBrakeSystemCompleted: boolean = tab.collapsibleItems.some((x) => x.name === "brakeSystem" && x.completed)
        if (tab.name === "spareWheel" && isBrakeSystemNext) {
            if (position === "right" && !isBrakeSystemCompleted) {
                setSelectedCollapsible("brakeSystem")
            } else {
                setBrakeSystem(!brakeSystem)
            }
        }
    }

    const handleFittingPositionChange = (position: "left" | "right") => {
        handleBrakeSystemCollapsible(position)
        updateSpareWheel()
    }

    const brakeSwitch = () => (
        <SwitchWrapper>
            <Typography>{translateText(13087)}</Typography>
            <XorSwitch status={spareWheel ? "left" : "right"} onChange={handleFittingPositionChange} />
            <Typography>{translateText(13088)}</Typography>
        </SwitchWrapper>
    )

    const renderContent = () => {
        if (tab.type === "spareWheel") {
            if (spareWheel) {
                return tab.collapsibleItems.filter((collapsible) => collapsible.name !== "brakeSystem").map(renderCollapsible)
            }

            return tab.collapsibleItems.filter((collapsible) => collapsible.name === "brakeSystem").map(renderCollapsible)
        }

        return tab.collapsibleItems.map(renderCollapsible)
    }

    return (
        <>
            {tab.type === "spareWheel" && brakeSwitch()}
            {renderContent()}
            {showDefektModal && (
                <TiresAndWheelsDefekt
                    open={showDefektModal}
                    handleCloseModal={handleHideDefektModal}
                    collapsibleName={selectedCollapsible}
                    tabType={tab.name}
                />
            )}
            {showGeneralModal && (
                <GeneralData
                    open={showGeneralModal}
                    onComplete={handleCompleteCollapsible}
                    handleCloseModal={handleCloseGeneralModal}
                    isFront={tab.type === "front"}
                    selectedTabName={tab.name}
                    tabType={tab.type}
                />
            )}
        </>
    )
}

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    marginTop: theme.margin?.xs ?? "0.1em",
    backgroundColor: "#efefef",
    boxShadow: "none",
    border: "1px solid #efefef",
    borderRadius: "3px",
    "&.general": {
        margin: 0,
        ".MuiCollapse-root": {
            display: "none",
        },
    },
    "&::before": {
        height: "0px",
        backgroundColor: "transparent",
    },
    position: "static",
}))

// TODO: Check if it can be migrated to the existing customized `AccordionSummary` from @tm/components
const AccordionSummaryStyled = styled((props: any) => (
    <MuiAccordionSummary expandIcon={<Icon name={props.name === "general" ? "next" : "down"} />} {...props} />
))(({ theme }) => ({
    margin: theme.margin?.xs ?? "0.1em",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)",
    },
    "& .MuiAccordionSummary-content": {
        alignItems: "center",
        ".MuiTypography-root": {
            marginLeft: theme.spacing(1),
        },
        ".appendix": {
            marginLeft: theme.spacing(2),
        },
        ".icon": {
            color: theme.colors?.success,
        },
    },
    ".notCheckedInsidePoint": {
        width: "1em",
        height: "1em",
        borderRadius: "100%",
        border: "1px solid #8c8c8c",
    },
}))

const PaddingButton = styled(Button)({
    marginLeft: ".5em",
})

const AccordionContent = styled(AccordionDetails)({
    backgroundColor: "white",
})

const CenteredWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
})

const CenteredItems = styled(Box)({
    display: "flex",
    alignItems: "center",
})

const MainContent = styled(Box)({
    display: "flex",
    flexDirection: "row",
    marginLeft: "1em",
    alignItems: "center",
})

const ButtonWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifySelf: "flex-end",
})

const SwitchWrapper = styled(Box)({
    padding: "0 2em",
    display: "flex",
    alignItems: "center",
})
