import { Link, useParams } from "react-router-dom"
import { renderRoute, encodeUniqueId, uniqueId } from "@tm/utils"
import { Box, Icon, Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useShowCisOptions, useWorkTask } from "@tm/context-distribution"
import { DisplayBehaviour } from "@tm/models"
import { getBundleParams } from "../../utils"

export default function CisButtonCopmonent() {
    const { translateText } = useLocalization()
    const matchParams = useParams()
    const { cisOptions } = useShowCisOptions()
    const workTaskId = useWorkTask()?.workTaskId

    if (cisOptions?.displayBehaviour !== DisplayBehaviour.Header && cisOptions?.displayBehaviour !== DisplayBehaviour.DashboardAndHeader) {
        return null
    }

    const url = renderRoute(getBundleParams().summaryUrl, { ...matchParams, workTaskId: encodeUniqueId(workTaskId || uniqueId()) })

    return (
        <Tooltip title={translateText(491)}>
            <Box height="100%">
                <Link to={url} className="global-navigation tab tab--worktask">
                    <div className="tab__content">
                        <Icon name="users" />
                    </div>
                </Link>
            </Box>
        </Tooltip>
    )
}
