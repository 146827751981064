import { useModuleNavigation, useStyle, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { bem, RouteComponentProps, useAvailableVehicleSearchOptionsRegNoTypes, withRouter } from "@tm/utils"
import { percent } from "csx"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ManagerComponent from "../moduleManager"
import NavigationComponent from "../navigation/component"
import LoadingScreen from "../loading-screen/component"
import VehicleSelection from "../vehicle-selection/component"
import WheelsList from "../wheels-list/component"
import WheelsDetails from "../wheels-details/component"
import RdksList from "../rdks-list/component"
import TyresList from "../tyres-list/component"
import Overview from "../overview/component"
import { MainState } from "."
import { Headline, Icon } from "@tm/controls"
import { BundleActions } from "../../business"
import { Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { vehicleSelector } from "../../data/helpers"
import { SystemType, getCurrentWorkTaskId } from "@tm/models"
import { getBundleParams } from "../../utils"

type Props = RouteComponentProps<{ view: string, workTaskId: string }> & {
    vehicleSelectionRoute: string
    wheelsRoute: string
    repairTimesRoute: string
    articleAlternativesRoute: string
    detailsModalRoute: string
}

const WrappedModule: React.FC<any> = ({ children }) => {
    const { showTab } = useModuleNavigation()

    useEffect(() => {
        showTab({
            icon: "wheel-and-car",
            title: "{{608}}",
        })
    }, [showTab])

    return <div className={style.content}>{children}</div>
}

const Main: React.FC<Props> = ({ vehicleSelectionRoute, wheelsRoute, repairTimesRoute, articleAlternativesRoute, detailsModalRoute, match }) => {

    const dispatch = useDispatch()
    const { translateText } = useLocalization()

    const userContext = useUser()?.userContext
    const vehicle = useSelector(vehicleSelector)
    const isHostettler = getBundleParams()?.isHostettler
    const workTaskVehicle = useWorkTask()?.workTask?.vehicle
    const showAvailability = getBundleParams().priceAvailabilityInformation ? !useUser()?.userSettings?.hideWheelsAvailability : false
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    
    const { keyValuesLoading, registrationNos, showLinearProgress, historyInit } = useSelector((s: MainState) => ({
        keyValuesLoading: s.manager.keyValueDataLoading,
        registrationNos: s.vehicleSelection.registrationNos,
        showLinearProgress: s.manager.showLinearProgress,
        historyInit: s.manager.historyInit
    }))

    React.useEffect(() => {
        if (vehicle && (userContext.system.systemType == SystemType.Redesign || vehicle.id == workTaskVehicle?.id)) {
            dispatch(BundleActions.initWheelsLogic(isHostettler, vehicle))
        }
    }, [vehicle?.id, workTaskVehicle?.id])

    React.useEffect(() => {
        if (historyInit) return
        //Hostettler case
        if (isHostettler && !!registrationNos) {
            dispatch(BundleActions.loadData(showAvailability, vehicle, getCurrentWorkTaskId(), telesalesCustomerNo))
        }
        //other cases
        else if (!isHostettler && vehicle?.id) {
            dispatch(BundleActions.loadData(showAvailability, vehicle, getCurrentWorkTaskId(), telesalesCustomerNo))
        }
    }, [isHostettler, registrationNos, vehicle?.id])

    if (keyValuesLoading) {
        return (
            <div className={bem(style.wrapper, "init-something")}>
                <ManagerComponent matchParamsWorkTask={match.params.workTaskId} />
                <div className="loading-screen">
                    <Icon name="wheel-and-car" />
                    <Headline>{translateText(608)}</Headline>
                    {!showLinearProgress && <Loader style={{ flex: 0 }} />}
                    <div id="step-loader"></div>
                </div>
            </div>
        )
    }

    return (
        <div className={bem(style.wrapper, match.params.view)}>
            <ManagerComponent matchParamsWorkTask={match.params.workTaskId} />
            <NavigationComponent wheelsRoute={wheelsRoute} />

            {!match.params.view &&
                <WrappedModule>
                    <LoadingScreen vehicleSelectionRoute={vehicleSelectionRoute} />
                </WrappedModule>
            }
            {match.params.view == "vehicle-selection" &&
                <WrappedModule>
                    <VehicleSelection />
                </WrappedModule>
            }
            {match.params.view == "wheels-list" &&
                <WrappedModule>
                    <WheelsList />
                </WrappedModule>
            }
            {match.params.view == "wheels-details" &&
                <WrappedModule>
                    <WheelsDetails repairTimesRoute={repairTimesRoute} />
                </WrappedModule>
            }
            {match.params.view == "rdks-list" &&
                <WrappedModule>
                    <RdksList articleAlternativesRoute={articleAlternativesRoute} repairTimesRoute={repairTimesRoute} detailsModalRoute={detailsModalRoute} />
                </WrappedModule>
            }
            {match.params.view == "tyres-list" &&
                <WrappedModule>
                    <TyresList detailsModalRoute={detailsModalRoute} repairTimesRoute={repairTimesRoute} />
                </WrappedModule>
            }
            {match.params.view == "overview" &&
                <WrappedModule>
                    <Overview repairTimesRoute={repairTimesRoute} detailsModalRoute={detailsModalRoute} />
                </WrappedModule>
            }
        </div>
    )
}

export default withRouter(Main)

const style = useStyle({
    wrapper: {
        flexBasis: percent(100),
        alignItems: "stretch",
        display: "flex",
        flexDirection: "column",
        $nest: {
            "&--details": {
                flexBasis: "auto"
            }
        }
    },
    content: {
        flex: 1,
        display: "flex"
    }
})(Main)