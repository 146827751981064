import { GetArticlesRequest, Vehicle } from "@tm/models"
import { useEffect, useMemo } from "react"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { fetchDirectSearchOrVehiclePartsArticles } from "../../data/fetchDirectSearchOrVehiclePartsArticles"
import { getArticleGroupsWithExactMatches } from "../../helpers"
import { useArticlesBase } from "../../hooks/useArticlesBase"
import { useDisplayFilter } from "../../hooks/useDisplayFilter"
import { DirectSearchModuleState, ListParams, ProductGroupsStartParams } from "../../models"

const QUERY_KEY = "PartsReplacements.useArticles"

/**
 * Get information about the current article data of the direct search module.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useArticles(
    vehicle: Vehicle | undefined,
    params: ListParams<ProductGroupsStartParams>,
    isEnabled: boolean
): DirectSearchModuleState["articles"] {
    const {
        productGroups,
        suppliers,
        attributes,
        startParams,
        extendedAssortment,
        sorting: { activateDefault: activateDefaultSorting },
    } = params

    const { pageSize } = useArticleListConfiguration()

    const request = useMemo<GetArticlesRequest | null>(() => {
        if (!isEnabled || !suppliers.length) {
            return null
        }

        let productGroupIds = productGroups.map((x) => x.id)
        if (!productGroupIds.length) {
            productGroupIds = startParams.productGroupIds
        }

        return {
            pageSize,
            fittingSideFilter: startParams.fittingSideFilter,
            productGroupIds,
            supplierIds: suppliers.map((x) => x.id),
            attributeFilter: attributes.map((attr) => `${attr.id}|${attr.key ?? attr.value ?? ""}`).join("|"),
            extendedAssortment,
            vehicleType: vehicle?.vehicleType,
            modelId: vehicle?.tecDocTypeId,
        }
    }, [isEnabled, pageSize, startParams, suppliers, productGroups, attributes, extendedAssortment, vehicle?.vehicleType, vehicle?.tecDocTypeId])

    const articlesBase = useArticlesBase(request, fetchDirectSearchOrVehiclePartsArticles, QUERY_KEY)
    const { isSuccess, articles, articleCount, pageCount, hasNextPage } = articlesBase

    const articleGroups = useMemo(() => getArticleGroupsWithExactMatches(articles, hasNextPage), [articles, hasNextPage])
    const articleGroupsCombined = useMemo(
        () => [...articleGroups.exactMatch, ...articleGroups.other],
        [articleGroups.exactMatch, articleGroups.other]
    )

    useEffect(
        function resetNoResult() {
            params.setNoResult(false)
        },
        [request]
    )

    useEffect(
        function setNoResult() {
            if (isSuccess && !articleCount && pageCount === 1) {
                params.setNoResult(true)
            }
        },
        [isSuccess]
    )

    useEffect(() => {
        if (isSuccess) {
            activateDefaultSorting(articleCount, pageCount, articleGroupsCombined)
        }
    }, [activateDefaultSorting, articleCount, articleGroupsCombined, isSuccess, pageCount])

    const exactMatchDisplayFilter = useDisplayFilter(articleGroups.exactMatch, params)
    const displayFilter = useDisplayFilter(articleGroups.other, params)

    return {
        ...articlesBase,
        isStalling: (exactMatchDisplayFilter.stalling || displayFilter.stalling) && pageCount === 1,
        isFrontendFiltered: displayFilter.isFrontendFiltered,
        articleGroups: displayFilter.articleGroups,
        exactMatchArticleGroups: exactMatchDisplayFilter.articleGroups,
    }
}
