import { Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ArticleAttribute, OrderItem, RepairTimeProvider, RequestArticleDetailsPayload, SearchFilters } from "@tm/models"
import { decodeUniqueId } from "@tm/utils"
import * as React from "react"
import { match as reactRouterMatch } from "react-router-dom"
import { getBundleParams } from "../../../utils"
import { IActions, ListState } from "../business"

import { ArticleGroupHeader } from "./ArticleGroupHeader/ArticleGroupHeader"
import { getSearchTypeDependentArticleTableProps, getTmaInfos } from "./utils"
import { RouteParams } from "../wrapper"
import { ArticleTable } from "./article-table"

type Props = {
    articles: Article[]
    state: ListState
    actions: IActions
    partToReplaceId?: string
    partToReplaceData?: { orderItem: OrderItem; article?: Article }
    showAdditionalPrices?: boolean
    showDocumentsInline?: boolean
    showDocumentsInCompact?: boolean
    showReferenceLinksInCompact?: boolean
    openDocumentsAsModal?: boolean
    showErpPawnItems?: boolean
    onShowArticleFeedback?(article: Article): void
    onArticleAddToBasket?(article: Article): void
    getRepairTimesUrl?(article: Article, rtProviders: Array<RepairTimeProvider> | RepairTimeProvider): string | undefined
    onArticleAttributeSelect: (article: Article, attribute: ArticleAttribute) => void
    onRequestArticleDetails: (request: RequestArticleDetailsPayload) => void
    onRequestArticleDirectSearch?(query: string, searchFilter?: SearchFilters): void
    isCentralOrder: () => boolean
    match: reactRouterMatch<RouteParams>
    articleAlternativesRoute?: string
    handleRequestArticleAlternatives: (article: Article) => void
}

export const FastCalculatorAlternatives: React.FC<Props> = (props) => {
    const { state, actions, articleAlternativesRoute, handleRequestArticleAlternatives, match, isCentralOrder, articles } = props
    const { translateText } = useLocalization()

    if (!state.fastCalculator?.alternatives?.request || !articles.length) {
        return null
    }

    if (state.fastCalculator.alternatives.loading) {
        return (
            <div className="article-list__panel article-list__status">
                <Loader visible delay={0} />
            </div>
        )
    }
    const { vehicleId, customerId, foundBySearchTerm } = getTmaInfos(state.usedFilters, state.searchType, state.customer)
    const { canFilterArticleAttributes, ignoreAttributeKey, selectedArticleAttributes, showMissingVehicleConnection, hasInlineDataSupplierFilter } =
        getSearchTypeDependentArticleTableProps(vehicleId, state.usedFilters, state.searchType)
    return (
        <>
            <ArticleGroupHeader title={translateText(13104)} />
            <ArticleTable
                searchType={state.searchType}
                selectedArticleIds={state.result.selected}
                selectedArticleAttributes={selectedArticleAttributes}
                ignoreAttributeKey={ignoreAttributeKey}
                onArticleSelect={actions.selectPart}
                onRequestArticleAlternatives={articleAlternativesRoute ? handleRequestArticleAlternatives : undefined}
                compactView={!!state.compactView}
                showArticleImages={!!state.showArticleImages}
                canFilterArticleAttributes={canFilterArticleAttributes}
                productGroupAsDescription
                vehicleEngineCode={state.usedFilters.vehicle ? state.usedFilters.vehicle.engineCode : undefined}
                workTaskId={match.params.workTaskId && decodeUniqueId(match.params.workTaskId)}
                vehicleId={vehicleId}
                customerId={customerId}
                foundBySearchTerm={foundBySearchTerm}
                showMissingVehicleConnection={showMissingVehicleConnection}
                repairTimeAvailabilities={state.repairTimeAvailabilities}
                hitInfos={state.result.hitInfo}
                getArticleSearchHitInfo={actions.getArticleSearchHitInfo}
                showActions
                previouslyOrderedArticles={state.result.previouslyOrderedArticles}
                vehicleRecordsComparisons={state.result.vehicleRecordsComparisons}
                activeDataSupplierFilter={state.usedFilters.supplierIds}
                onDataSupplierFilterToggle={hasInlineDataSupplierFilter ? actions.toggleDataSupplierFilter : undefined}
                hideCostEstimationButton={!!state.fastCalculator || isCentralOrder() || !getBundleParams().awProviders.length}
                advertisementCategoryId={state.usedFilters.offers?.advertisementCategory.id}
                replaceButtonBundle={state.initialFilters?.replaceButtonBundle}
                {...props}
            />
            <ArticleGroupHeader title={translateText(13105)} />
        </>
    )
}
