import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import { initDrivemotiveBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    components: [Main],
    init: initDrivemotiveBundle,
}

export default bundle
