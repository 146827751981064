import { useLocalization } from "@tm/localization"
import { Grid, Icon, Typography, lighten, styled } from "@tm/components"
import { SelectedPriceVatMode } from "@tm/models"
import { CostEstimationCalculation } from "../../../../data/model"

type Props = {
    priceVatMode?: SelectedPriceVatMode
    costEstimationCalculation?: CostEstimationCalculation
    currencyCode: string
    showRepairTimesInHours: boolean
}

const LightContrastTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.getContrastText(lighten(theme.palette.common.black, 0.3)),
    opacity: 1,
}))

const LightColoredIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.getContrastText(lighten(theme.palette.common.black, 0.3)),
    opacity: 1,
}))

export default function CostEstimationDetails({ costEstimationCalculation, showRepairTimesInHours, currencyCode, priceVatMode }: Props) {
    const { translateText, currency, number } = useLocalization()
    const { totalsB2B, totalsB2C } = costEstimationCalculation || {}
    const partPositionsInTotal = totalsB2B?.partPositionsInTotal ?? totalsB2C?.partPositionsInTotal ?? 0
    const partCosts = totalsB2B?.partCosts ?? totalsB2C?.partCosts ?? 0
    const repairTimeTotal = totalsB2B?.repairTimeTotal ?? totalsB2C?.repairTimeTotal
    const workCosts = totalsB2B?.workCosts ?? totalsB2C?.workCosts ?? 0

    return (
        <Grid container spacing={1} alignItems="center" sx={{ rowGap: 1 }}>
            {/* Column headers */}
            <Grid item xs={5.5} />
            <Grid item xs={2} textAlign="right">
                <LightContrastTypography variant="label">{translateText(89)}</LightContrastTypography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={3.5} textAlign="right">
                <LightContrastTypography variant="label">{translateText(priceVatMode ? 13668 : 694)}</LightContrastTypography>
            </Grid>
            <Grid item xs={1}>
                <LightColoredIcon name="cart-summary" />
            </Grid>

            {/* Parts Rows */}
            <Grid item xs={4.5}>
                <LightContrastTypography>{translateText(90)}</LightContrastTypography>
            </Grid>
            <Grid item xs={2} textAlign="right">
                <LightContrastTypography>{partPositionsInTotal ? number(partPositionsInTotal, 2) : undefined}</LightContrastTypography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={3.5} textAlign="right">
                <LightContrastTypography>{currency(partCosts, currencyCode)}</LightContrastTypography>
            </Grid>
            <Grid item xs={1}>
                <LightColoredIcon name="repairtimes-summary" />
            </Grid>

            {/* Works Rows */}
            <Grid item xs={4.5}>
                <LightContrastTypography>{translateText(83)}</LightContrastTypography>
            </Grid>
            <Grid item xs={2} textAlign="right">
                <LightContrastTypography>{!!repairTimeTotal && number(repairTimeTotal, 2)}</LightContrastTypography>
            </Grid>
            <Grid item xs={1}>
                <LightContrastTypography>{!!repairTimeTotal && showRepairTimesInHours && translateText(60)}</LightContrastTypography>
            </Grid>
            <Grid item xs={3.5} textAlign="right">
                <LightContrastTypography>{currency(workCosts, currencyCode)}</LightContrastTypography>
            </Grid>
        </Grid>
    )
}
