import { useLocalization } from "@tm/localization"
import { renderRoute, RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { Tooltip, Icon } from "@tm/controls"
import { ECounterType } from "@tm/models"

type Props = RouteComponentProps & {
    orderRoute: string
}

const ShowAllOrdersButtonComponent = (props: Props) => {
    const { translateText } = useLocalization()
    const { match, orderRoute } = props

    const handleOrderTabClick = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.MyAccount)
        props.history.push(renderRoute(orderRoute, match.params))
    }

    const isSelected = match.url === orderRoute

    return (
        <div className="bundle-orders">
            <Tooltip content={translateText(1991)}>
                <div className={`global-navigation tab tab--worktask${isSelected ? " is-selected" : ""}`} onClick={handleOrderTabClick}>
                    <div className="tab__content">
                        <Icon name="orders" size="l" />
                    </div>
                </div>
            </Tooltip>
        </div>
    )
}

export default withRouter(ShowAllOrdersButtonComponent)
