import { Size, TextModifiers } from "@tm/controls"
import { ErpBundleParams, PriceType, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import {
    getAdvertisement,
    getAdvertisementCategories,
    getBonusInformation,
    getCompleteDeliveryTour,
    getErpConfig,
    getNotification,
    getPromotedItems,
    selectBackorder,
    selectBillingAddress,
    selectBillingMode,
    selectDeliveryAddress,
    selectDeliveryDistribution,
    selectOrderProcessing,
    selectPayment,
    selectShipment,
    selectTour,
    setCustomOrderInformation,
    setCustomOrderNumber,
    setDeliveryDate,
    setOrderComment,
    showDefaultOptions,
    showDefaultWarehouse,
    showGlobalOptions,
} from "../data"
import { getErpInfo, getErpInfoDetails, getErpInfos, showOrderStatus } from "../data/repositories/erp-connect"
import { getBufferedOrderOptions } from "../data/repositories/order-options/buffered-order-options"

export const version = {
    name: "erp",
    version: "1.0",
}
type PriceStyle = {
    modifiers?: Array<TextModifiers>
    size?: Size
    /** true or optionally a (fallback) textId can be defined here */
    descriptionInText?: boolean | number
}

let bundleParams: ErpBundleParams<PriceStyle>

export function initErpBundle(params: ErpBundleParams<PriceStyle>) {
    const environmentalPriceTextId = params.environmentalPriceTextId || 13585

    bundleParams = {
        ...params,
        priceOrder: params.priceOrder || [[12, 4, 5, 1, 9, 3, "EK_PRICEUNIT", "GRADUATED_PRICES"]],
        pricesToHideWithShowPurchasePriceSwitch: params.pricesToHideWithShowPurchasePriceSwitch || [PriceType.Purchase, PriceType.Strikethrough],
        quantityExpectedDeliveryProperty: params.quantityExpectedDeliveryProperty || "expectedDeliveryTime",
        expectedDeliveryDateFormat: params.expectedDeliveryDateFormat || "g",
        environmentalPriceTextId,
        additionalInformationIcon: params.additionalInformationIcon || "info",
        oneColumnPriceStyles: {
            default: {
                modifiers: ["strong"],
            },
            [PriceType.Purchase]: {
                modifiers: ["strong", "highlight"],
                size: "s",
            },
            [PriceType.Strikethrough]: {
                modifiers: ["strong", "strikethrough", "danger"],
                size: "xs",
            },
            [PriceType.Deposit]: {
                size: "xs",
                descriptionInText: 1099,
            },
            [PriceType.UsedPart]: {
                size: "xs",
                descriptionInText: 1537,
            },
            [PriceType.Environmental]: {
                size: "xs",
                descriptionInText: environmentalPriceTextId,
            },
            ...params.oneColumnPriceStyles,
        },
        twoColumnPriceStyles: {
            default: {
                modifiers: ["strong"],
            },
            [PriceType.Purchase]: {
                modifiers: ["strong", "highlight"],
            },
            [PriceType.Strikethrough]: {
                modifiers: ["strong", "strikethrough", "danger"],
                size: "s",
            },
            [PriceType.Deposit]: {
                modifiers: ["sub"],
                size: "xs",
                descriptionInText: 1099,
            },
            [PriceType.UsedPart]: {
                modifiers: ["sub"],
                size: "xs",
                descriptionInText: 1537,
            },
            [PriceType.Environmental]: {
                modifiers: ["sub"],
                size: "xs",
                descriptionInText: environmentalPriceTextId,
            },
            [PriceType.ProcurementCosts]: {
                modifiers: ["sub"],
                size: "xs",
                descriptionInText: 1576,
            },
            ...params.twoColumnPriceStyles,
        },
    }

    Container.register({
        name: RegisteredModels.ERP,
        containerActions: {
            getErpInfo,
            getErpInfos,
            getErpInfoDetails,
        },
    })

    Container.register({
        name: RegisteredModels.ERP_DefaultOrderOptions,
        modelActions: {
            load: showDefaultOptions,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_GlobalOrderOptions,
        modelActions: {
            load: showGlobalOptions,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_OrderOptions,
        modelActions: {
            load: getBufferedOrderOptions,
        },
        containerActions: {
            selectBackorder,
            selectBillingAddress,
            selectBillingMode,
            selectDeliveryAddress,
            selectDeliveryDistribution,
            selectPayment,
            selectShipment,
            selectTour,
            setCustomOrderNumber,
            setCustomOrderInformation,
            setDeliveryDate,
            setOrderComment,
            selectOrderProcessing,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_GetPromotedItems,
        modelActions: {
            load: getPromotedItems,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_ShowDefaultWarehouse,
        modelActions: {
            load: showDefaultWarehouse,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_GetCompleteDeliveryTour,
        modelActions: {
            load: getCompleteDeliveryTour,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_GetBonusInformation,
        modelActions: {
            load: getBonusInformation,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_GetAdvertisementCategories,
        modelActions: {
            load: getAdvertisementCategories,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_GetAdvertisement,
        modelActions: {
            load: getAdvertisement,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_GetNotification,
        modelActions: {
            load: getNotification,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_GetErpConfig,
        modelActions: {
            load: getErpConfig,
        },
    })
    Container.register({
        name: RegisteredModels.ERP_OrderStatus,
        modelActions: {
            load: showOrderStatus,
        },
    })

    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}
