import { ModuleConfig, IPlugin, PluginProps } from "@tm/morpheus"
import * as jp from "jsonpath"
import getValue from "get-value"

enum IntegrationAreas {
    STARTPAGE = "147001",
    DASHBOARD = "147002",
    STARTANDDASHBOARD = "147003",
}

type MessengerProps = PluginProps<ModuleConfig, { propertyPath: string; contextQuery: string }>

// controled by mdm module parameter "integration"/"einbindung"
function ShouldRenderWidget(data: MessengerProps) {
    if (!(data as any).component?.props || !(data as any).component.props[data.pluginProps.propertyPath]) {
        return true
    }

    const moduleSubId = getValue((data as any).component.props, data.pluginProps.propertyPath)
    const module = jp.query(window.userContext, `$..modules[?(@.moduleSubId==${moduleSubId})]`)
    if (!module) {
        return false
    }

    const params = jp.query(module, `$..parameters[?(@.key==\"Integration\")].value`)
    if (params.length > 0) {
        const value = params[0]
        const currentPathname = document.location.pathname

        if (value === IntegrationAreas.DASHBOARD) {
            if (currentPathname !== "/") {
                return true
            }
        }

        if (value === IntegrationAreas.STARTPAGE) {
            if (currentPathname === "/") {
                return true
            }
        }

        if (value === IntegrationAreas.STARTANDDASHBOARD) {
            return true
        }

        return false
    }
    return true
}

const plugin: IPlugin<ModuleConfig> = {
    name: "ShouldRenderByMdmModuleIntegration",
    type: "COMPONENT/SHOULD_RENDER",
    plugin: ShouldRenderWidget,
}

export default plugin
