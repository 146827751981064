import { Box, Icon, IconButton, ScrollContainer, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CreateCustomArticleRequest, CustomArticle, IMicros } from "@tm/models"
import { useState } from "react"
import ConsumableItem from "./ConsumableItem"
import ConsumableItemAdd from "./ConsumableItemAdd"
import { saveCustomArticle } from "../../data/repositories"

type Props = IMicros["parts"]["consumable-replace"]

export default function ConsumableReplace({ title, consumables, onConsumableSelect, onConsumableSaved }: Props) {
    const { translateText } = useLocalization()
    const [selectedConsumable, setSelectedConsumable] = useState<CustomArticle | undefined>(undefined)
    const [addNewCustomArticle, setAddNewCustomArticle] = useState(false)
    const [disableAddNewButton, setDisableSaveButton] = useState(false)

    const handleConsumableSelect = (item: CustomArticle) => {
        onConsumableSelect?.(item)
        setSelectedConsumable(item)
    }

    async function handleConsumableSave(newConsumable: CreateCustomArticleRequest | undefined) {
        if (!newConsumable) {
            return
        }

        setDisableSaveButton(true)
        const { description, garagePrice, isDefaultForCategory } = newConsumable
        const firstConsumable = consumables.first()
        const category = firstConsumable?.category
        const vatRate = firstConsumable?.vatRate

        const customArticleId = await saveCustomArticle({ description, garagePrice, category, vatRate, isDefaultForCategory })
        if (customArticleId) {
            onConsumableSaved?.()
            toggleShowAddCustomArticle()
        }

        setDisableSaveButton(false)
    }

    function toggleShowAddCustomArticle() {
        setAddNewCustomArticle((prev) => !prev)
    }

    return (
        <ScrollContainer display="flex" flexDirection="column" flex="1">
            <Typography variant="h2" gutterBottom>
                {title}
            </Typography>
            <StyledBox>
                <Typography variant="body3" gutterBottom>
                    {translateText(25)}
                </Typography>
                <Typography variant="body3" gutterBottom>
                    {translateText(53)}
                </Typography>
            </StyledBox>

            {consumables.map((item, idx) => {
                return (
                    <ConsumableItem key={idx} item={item} handleConsumableSelect={handleConsumableSelect} isSelected={selectedConsumable === item} />
                )
            })}
            {addNewCustomArticle && (
                <ConsumableItemAdd
                    saveDisabled={disableAddNewButton}
                    handleConsumableDiscard={toggleShowAddCustomArticle}
                    handleConsumableSave={handleConsumableSave}
                />
            )}
            <StyledAddButton size="large" onClick={toggleShowAddCustomArticle}>
                <Icon name="plus" />
            </StyledAddButton>
        </ScrollContainer>
    )
}

const StyledBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
})

const StyledAddButton = styled(IconButton)(({ theme }) => ({
    alignSelf: "center",
    backgroundColor: theme.palette.grey[300],
    "&:hover": {
        backgroundColor: `${theme.colors?.primary} !important`,
    },
}))
