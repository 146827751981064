import { useLocalization } from "@tm/localization"
import { useRef } from "react"

import { FuelTypeFilter } from "../../../data/model/filters"
import Filter from "./_filter"

type Props = {
    items: Array<FuelTypeFilter>
    clipped: boolean
    opened: boolean
    onToggleOpen(): void
    onToggleClip(): void
    onChangeFilter(item?: string): void
}

function FuelTypesFilter(props: Props) {
    const { translateText } = useLocalization()
    const filterRef = useRef<React.ElementRef<typeof Filter>>(null)

    const handleClick = (item: FuelTypeFilter) => {
        props.onChangeFilter(item.isSelected ? undefined : item.fuel)
        !props.clipped && filterRef.current?.collapse()
    }

    const renderItem = (item: FuelTypeFilter) => {
        const className = `filter__item ${item.isSelected ? "filter__item--selected" : ""}`
        return (
            <div className={className} key={item.fuel} onClick={() => handleClick(item)}>
                {item.fuel}
            </div>
        )
    }

    const { items, opened } = props
    const selectedItem = items.find((x) => !!x.isSelected)

    return (
        <Filter
            {...props}
            name={translateText(299)}
            ref={filterRef}
            className="filters__filter"
            disabled={!(items && items.length)}
            value={!opened && selectedItem ? selectedItem.fuel : undefined}
        >
            <div className="filter">{items.map(renderItem)}</div>
        </Filter>
    )
}

export default FuelTypesFilter
