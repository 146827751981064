import { useRef, useEffect, Suspense } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, TmaHelper } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"
import {
    RegisteredModels,
    ChangeCostEstimationDetailsRequest,
    ShowCostEstimationDetailsResponse,
    RepairTimeProviderConfig,
    CisVoucherType,
    ECounterType,
} from "@tm/models"
import { useWorkTask, WorkTaskInfo, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Container } from "@tm/nexus"
import { Typography, Loader, Stack, Divider } from "@tm/components"
import { useLocation, useParams } from "react-router"
import { OfferState } from "./business"
import { IActions, Actions } from "./business/actions"
import Note from "./components/note"
import Details from "./components/details"
import { PostCreateActionType } from "../../business"
import { useTransferCostEstimation } from "../../data/hooks/useTransferCostEstimation"
import TransferCompleted from "../_shared/transfer-completed"
import { ESendCostEstimationMode } from "../../data/model"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { useCreateCostEstimationVoucher } from "../../data/hooks/useCreateCostEstimationVoucher"
import ErrorAlert from "../_shared/ErrorAlert"
import { CostEstimationNavigation } from "./components/navigation"
import CustomerOverview from "./components/CustomerOverview"
import { CostEstimationVehicleInfo } from "./components/vehicle-info"
import { useRepairTimeProviders } from "../../hooks/basketState/useRepairTimeProviders"

type Props = {
    state: OfferState
    actions: IActions
    // TODO basket V2: https://jira.dvse.de/browse/NEXT-27820
    // awProviders should be renamed to an english term like rtProviders.
    // And it should be a general param in the configs and can be removed as a bundle param of basket, parts and settings.
    awProviders?: { [key: string]: RepairTimeProviderConfig }
    cisVoucherUrl?: string
    partsDirectListRoute: string
    partsVehicleListRoute: string
    partRepairTimesRoute: string
    showAllPrices?: boolean
    voucherTypeId?: CisVoucherType
    partsDetailBasketRoute?: string
    costEstimationRoute: string
    orderRoute: string
    orderButtonTextId: string
    orderButtonIcon: string
    orderRoutePreActionExternalSystemId?: number
    exportTarget?: string
    showExportVoucherButton?: boolean
    showDmsExportButton?: boolean
    customerListRoute: string
    customerDetailsRoute: string
}

function CostEstimation(props: Props & { workTask: WorkTaskInfo }) {
    const {
        actions,
        state,
        partRepairTimesRoute,
        partsDirectListRoute,
        partsVehicleListRoute,
        showAllPrices,
        cisVoucherUrl,
        workTask,
        voucherTypeId,
        partsDetailBasketRoute,
        costEstimationRoute,
        orderRoute,
        orderButtonTextId,
        orderButtonIcon,
        orderRoutePreActionExternalSystemId,
        exportTarget,
        showDmsExportButton,
        showExportVoucherButton,
        customerDetailsRoute,
        customerListRoute,
        awProviders,
    } = props
    const { setRepairTimeProviders } = useRepairTimeProviders()
    const { translateText } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const location = useLocation()
    const matchParams = useParams<{ workTaskId?: string; view?: string }>()
    const costEstimationUrl = renderRoute(costEstimationRoute, matchParams)
    const costEstimationIsActive = location.pathname === costEstimationUrl
    const detailsIsActive = matchParams.view === partsDetailBasketRoute
    const { response, resetState } = useTransferCostEstimation(workTask.id, telesalesCustomerNo, ESendCostEstimationMode.costEstitmation)
    const savingDetails = useRef<boolean>(false)
    const { createCostEstimationVoucher, creatingCostEstimationVoucher } = useCreateCostEstimationVoucher(workTask, state)

    const { workTaskBasketWithError } = useWorkTaskBasketState(workTask.id)

    useEffect(() => {
        if (awProviders) {
            setRepairTimeProviders(awProviders)
        }
    }, [awProviders])

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []

        actions.setWorkTask(workTask)

        // the worktask should exist in the DB
        if (workTask.no) {
            const nexusSub = Container.getInstance<ShowCostEstimationDetailsResponse>(RegisteredModels.Worktask_CostEstimationDetails).subscribe({
                workTaskId: workTask.id,
            })

            unsubscriptions.push(
                nexusSub.addListener("loaded", (response) => {
                    actions.setCostEstimationDetails(response)
                    savingDetails.current = false
                }),
                nexusSub.addListener("updated", () => {
                    nexusSub.load()
                    savingDetails.current = false
                })
            )

            nexusSub.load()
        }

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [workTask])

    function saveCostEstimationDetails(workTaskId: string, request: ChangeCostEstimationDetailsRequest) {
        const costEstimationDetialsSubscription = Container.getInstance<ShowCostEstimationDetailsResponse>(
            RegisteredModels.Worktask_CostEstimationDetails
        ).subscribe({ workTaskId })
        if (costEstimationDetialsSubscription.save && !savingDetails.current) {
            savingDetails.current = true
            costEstimationDetialsSubscription.save(request)
        }
    }

    function updateClientAdvisor(clientAdvisor: string) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || clientAdvisor === offerDetails.clientAdvisor) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function handleModifyCostEstimationNote(memo: string) {
        actions.setCostEstimationNote(memo)
    }

    function handleUpdateCostEstimationNote(memo: string) {
        const { workTask, offerDetails } = state
        if (!workTask || !offerDetails.version) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateDueDate(dueDate: Date) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || dueDate === offerDetails.dueDate) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateEndDate(endDate: Date) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || endDate === offerDetails.endDate) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateMechanic(mechanic: string) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || mechanic === offerDetails.mechanic) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateReferenceNumber(referenceNumber: string) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || referenceNumber === offerDetails.referenceNumber) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate: offerDetails.startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function updateStartDate(startDate: Date) {
        const { workTask, offerDetails, note } = state
        if (!workTask || !offerDetails.version || startDate === offerDetails.startDate) {
            return
        }

        const request: ChangeCostEstimationDetailsRequest = {
            workTaskId: workTask.id,
            referenceNumber: offerDetails.referenceNumber,
            clientAdvisorName: offerDetails.clientAdvisor,
            mechanicName: offerDetails.mechanic,
            startDate,
            endDate: offerDetails.endDate,
            dueDate: offerDetails.dueDate,
            memo: note,
            version: offerDetails.version,
        }
        saveCostEstimationDetails(workTask.id, request)
    }

    function handlePrintCostEstimation() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimatePrint)
        createCostEstimationVoucher(PostCreateActionType.ShowPdf)
    }

    return (
        <Stack alignSelf="stretch" flex={1} gap={1}>
            <CostEstimationNavigation
                state={state}
                workTaskId={workTask.id}
                costEstimationIsActive={costEstimationIsActive}
                costEstimationUrl={costEstimationUrl}
                creatingCostEstimation={creatingCostEstimationVoucher}
                customerEmail={workTask.customer?.email}
                detailsIsActive={detailsIsActive}
                partsDetailRoute={partsDetailBasketRoute}
                orderRoute={orderRoute}
                orderButtonTextId={orderButtonTextId}
                orderButtonIcon={orderButtonIcon}
                orderRoutePreActionExternalSystemId={orderRoutePreActionExternalSystemId}
                exportTarget={exportTarget}
                showDmsExportButton={showDmsExportButton}
                showExportVoucherButton={showExportVoucherButton}
                onGenerateCostEstimationLink={() => createCostEstimationVoucher(PostCreateActionType.PublishLink)}
                onPrintCostEstimation={handlePrintCostEstimation}
                onSendCostEstimation={() => createCostEstimationVoucher(PostCreateActionType.SendChatMessage)}
            />
            <Stack px={2} pb={1} gap={1} divider={<Divider light />} overflow="auto">
                <CustomerOverview customerDetailsRoute={customerDetailsRoute} customerListRoute={customerListRoute} />
                {response && (
                    <TransferCompleted
                        workTaskId={workTask.id}
                        telesalesCustomerNo={telesalesCustomerNo}
                        mode={ESendCostEstimationMode.costEstitmation}
                        onClearCostEstimationSent={() => resetState()}
                    />
                )}
                {workTaskBasketWithError ? (
                    <ErrorAlert />
                ) : (
                    <CostEstimationVehicleInfo
                        cisVoucherUrl={cisVoucherUrl}
                        creatingCostEstimationVoucher={creatingCostEstimationVoucher}
                        partRepairTimesRoute={partRepairTimesRoute}
                        partsDirectListRoute={partsDirectListRoute}
                        partsVehicleListRoute={partsVehicleListRoute}
                        showAllPrices={showAllPrices}
                        voucherTypeId={voucherTypeId}
                        workTask={workTask}
                    />
                )}
                <Details
                    details={state.offerDetails}
                    hasWorktask={!!workTask.no}
                    disabled={workTask.no ? savingDetails.current : true}
                    onUpdateClientAdvisor={updateClientAdvisor}
                    onUpdateDueDate={updateDueDate}
                    onUpdateEndDate={updateEndDate}
                    onUpdateMechanic={updateMechanic}
                    onUpdateReferenceNumber={updateReferenceNumber}
                    onUpdateStartDate={updateStartDate}
                />
                {workTask.no && (
                    <Stack>
                        <Typography variant="h3">{translateText(1700)}</Typography>
                        <Note
                            disabled={workTask.no ? savingDetails.current : true}
                            note={state.note}
                            onUpdateNote={handleUpdateCostEstimationNote}
                            onModifyNote={handleModifyCostEstimationNote}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

function Wrapper(props: Props) {
    const workTask = useWorkTask()?.workTask
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={<Loader />}>
            <CostEstimation {...props} workTask={workTask} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
