import { ReactElement, useCallback, useEffect, useRef, useState } from "react"
import { Dropdown, TabControl } from "@tm/controls"
import { Typography, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { VehicleType, ModuleAvailabilities } from "@tm/models"
import { style } from "typestyle"

import {
    getAvailableTruckModules,
    getAvailableVehicleTypes,
    getTruckTypeName,
    getVehicleTypeName,
    isTruckModuleType,
    VehicleTypeTabIdentifier,
} from "../../../helpers/modules"
import useResizeObserver from "../../../helpers"

type Props = {
    currentVehicleType: VehicleType
    moduleAvailabilities: ModuleAvailabilities
    displayStyle?: VehicleTabsDisplayStyle
    onTabSelect(identifier: string): void
}

export type VehicleTabsDisplayStyle = "dropdown" | "headerTabs"

export function TabNavigation(props: Props): ReactElement | null {
    const { currentVehicleType, moduleAvailabilities } = props
    const { translate, translateText } = useLocalization()
    const [displayStyle, setDisplayStyle] = useState(props.displayStyle)

    const tabsRef = useRef<HTMLDivElement>()
    const [scrollWidth, setScrollWidth] = useState<number>()
    const observer = useResizeObserver()

    useEffect(() => {
        if (!observer.width) {
            return
        }
        if (tabsRef.current) {
            if (tabsRef.current.scrollWidth > tabsRef.current.clientWidth) {
                setScrollWidth(tabsRef.current.scrollWidth)
                setDisplayStyle("dropdown")
            }
        } else if (props.displayStyle !== "dropdown" && scrollWidth && scrollWidth <= observer.width) {
            setDisplayStyle(props.displayStyle)
            setScrollWidth(undefined)
        }
    }, [observer.width])

    const handleDropdownItemSelect = useCallback(
        (item: ItemProps) => {
            props.onTabSelect(item.identifier.toString())
        },
        [props.onTabSelect]
    )

    const handleTabSelect = useCallback(
        (identifier: string) => {
            props.onTabSelect(identifier)
        },
        [props.onTabSelect]
    )

    const availableVehicleTypes = getAvailableVehicleTypes(moduleAvailabilities)
    const availableTruckModules = getAvailableTruckModules(moduleAvailabilities.truckModules)
    const availableTabs = [...availableVehicleTypes, ...availableTruckModules]

    if (availableTabs.length <= 1) {
        return null
    }

    function renderDropdown() {
        return (
            <Dropdown
                className={style({ marginBottom: "0.4rem" })}
                items={availableTabs.map((x) => ({ identifier: x }))}
                value={{ identifier: currentVehicleType }}
                itemView={DropdownItemView}
                onChange={handleDropdownItemSelect}
            />
        )
    }

    function renderTabControl(className?: string, withIcons?: boolean) {
        return (
            <TabControl className={className} selectedTabIdentifier={currentVehicleType.toString()} onTabSelect={handleTabSelect} tabsRef={tabsRef}>
                {availableTabs.map((identifier) => {
                    const icon = withIcons ? getTabIcon(identifier) : undefined

                    return (
                        <TabControl.Tab key={identifier} className={getTabClassName(identifier)} identifier={identifier.toString()}>
                            {icon && <Icon name={icon} />}
                            {getTabDescription(identifier, translateText)}
                        </TabControl.Tab>
                    )
                })}
            </TabControl>
        )
    }

    function renderContent() {
        switch (displayStyle) {
            case "dropdown":
                return renderDropdown()
            case "headerTabs":
                return renderTabControl("vehicle-header-tabs", true)
            default:
                return renderTabControl()
        }
    }

    return (
        <div className="category-selection" ref={observer.ref}>
            <Typography variant="body3" component="div">
                {translate(176)}
            </Typography>
            {renderContent()}
        </div>
    )
}

type ItemProps = {
    identifier: VehicleTypeTabIdentifier
}

function DropdownItemView(props: ItemProps): ReactElement | null {
    const { translateText } = useLocalization()
    return <>{getTabDescription(props.identifier, translateText)}</>
}

function getTabDescription(identifier: VehicleTypeTabIdentifier, translateFn: (id: number) => string): string | undefined {
    return isTruckModuleType(identifier) ? getTruckTypeName(identifier) : getVehicleTypeName(identifier, translateFn)
}

function getTabClassName(identifier: VehicleTypeTabIdentifier): string | undefined {
    switch (identifier) {
        case VehicleType.PassengerCar:
            return "tab--pkw"
        case VehicleType.Motorcycle:
            return "tab--motorcycle"
        case VehicleType.CommercialVehicle:
            return "tab--commercialvehicle"
        case "truckGarage":
            return "tab--truckgarage"
        case "myTruck":
            return "tab--mytruck"
        case "myTruckPlus":
            return "tab--mytruckplus"
        case "tmTruck":
            return "tab--tmtruck"
        case "tmTrailer":
            return "tab--tmtrailer"
        default:
            break
    }
}

function getTabIcon(identifier: VehicleTypeTabIdentifier): string | undefined {
    switch (identifier) {
        case VehicleType.PassengerCar:
            return "car"
        case VehicleType.Motorcycle:
            return "bike"
        case VehicleType.CommercialVehicle:
        case "truckGarage":
        case "myTruck":
        case "myTruckPlus":
        case "tmTruck":
        case "tmTrailer":
            return "truck"
        default:
            break
    }
}
