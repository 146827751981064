import { useMutation, UseMutateAsyncFunction } from "react-query"
import { importNote } from "../repositories/note-upload"

export const useImportNote = (): {
    importFile: UseMutateAsyncFunction<void, Error, string>
    importInProgress: boolean
    isSuccess: boolean
    importError: Error | null
} => {
    const mutation = useMutation<void, Error, string>(importNote, {})

    return { importFile: mutation.mutateAsync, importInProgress: mutation.isLoading, importError: mutation.error, isSuccess: mutation.isSuccess }
}
