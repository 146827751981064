import { Memo, Paged } from "."
import { Vehicle } from "./vehicles"

export type FindCustomersRequest = Paged & {
    query?: string
    orderBy?: string
    orderByDescending?: boolean
}

export type FindCustomersResponse = Paged & {
    customers: Array<Customer>
}

export type SaveCustomerRequest = {
    customer: Customer
}

export type SaveCustomerResponse = {
    referenceId: string
}

export type ShowCustomerRequest = {
    referenceId: string
    vehicleReferenceId?: string
}

export type ShowCustomerResponse = {
    customer?: Customer
    vehicle?: Vehicle
}

export type Customer = {
    referenceId: string
    customerId: string
    taxId: string

    matchCode: string

    title: TitleType
    academicTitle: string

    firstName: string
    lastName: string
    companyName: string

    generalAddress?: PostalAddress

    phone: string
    mobile: string
    fax: string
    email: string

    birthDate?: Date

    vehicles?: Array<Vehicle>
    memos?: Array<Memo>

    creationDate: Date
    modifiedDate?: Date
}

export enum TitleType {
    Undefined = 0,
    Mister = 1,
    Miss = 2,
    Company = 3,
}

export type PostalAddress = {
    referenceId: string
    description: string

    street: string
    addressAddition: string
    zip: string
    city: string
    state: string
    country: string
}
