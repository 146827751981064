import { Suspense, useMemo } from "react"
import { Box, Loader } from "@tm/components"
import { useLocation } from "react-router"
import { ArticleListConfigurationProvider } from "../ArticleListConfiguration"
import { DirectSearch } from "../DirectSearch"
import { SearchType } from "../../../business"
import ProductGroups from "../ProductGroups"

export function PartsAlternatives() {
    const location = useLocation()

    const searchParams = new URLSearchParams(location.search)
    const searchTypeString = searchParams.get("searchType") ?? undefined

    const getContent = useMemo(() => {
        if (searchTypeString) {
            const searchType = SearchType[searchTypeString.toUpperCase() as keyof typeof SearchType]
            switch (searchType) {
                case SearchType.DIRECT:
                    return <DirectSearch />
                case SearchType.PRODUCTGROUPS:
                    return <ProductGroups />
                default:
                    break
            }
        }

        return null
    }, [searchTypeString])

    return (
        <Suspense
            fallback={
                <Box alignContent="center">
                    <Loader />
                </Box>
            }
        >
            <ArticleListConfigurationProvider>{getContent}</ArticleListConfigurationProvider>
        </Suspense>
    )
}
